import React from 'react';
import ReactDOM from 'react-dom';
import Component from '~coresrc/component/component.js';
import './OrientationOverlay.scss';

class PortraitMessage extends React.Component {
    constructor(props) {
        super(props);

        this.data = {
            rotate_title: LanguageManager.getString('rotate_device'),
            rotate_message: LanguageManager.getString('rotate_device_message'),
            image: 'assets/images/screen_rotation.png',
        };
    }

    render() {
        return (
            <div>
                <div className="image">
                    <img src={this.data.image} alt={this.data.rotate_title} />
                </div>
                <div className="title">
                    <p>{this.data.rotate_title}</p>
                </div>
                <div className="message">
                    <p>{this.data.rotate_message}</p>
                </div>
            </div>
        );
    }
}

const OrientationOverlay = Component.extend({
    initialize(attributes) {
        attributes = attributes || {};

        this.name = 'OrientationOverlay';
        this.isActive = false;
        this.requireLandscape = attributes.requireLandscape === true;
    },

    setup(container) {
        this.container = container;

        this.listenTo(
            this.container,
            'onLoadComplete',
            this.handleLoadCompleted
        );
    },

    handleLoadCompleted() {
        const isPortrait =
            this.container.orientation === 0 ||
            this.container.orientation === 180;

        if (BrowserDetect.isMobileDevice) {
            if (isPortrait) {
                this.isActive = true;
                this.renderRotateMessage();
            }
            this.listenTo(
                this.container,
                'onOrientationChange',
                this.handleOrientationChange
            );
        }
    },

    render() {
        const component = <PortraitMessage></PortraitMessage>;

        this.$wrapper = $("<div id='orientation-overlay' />");
        this.container.dom.prepend(this.$wrapper);

        ReactDOM.render(component, this.$wrapper[0]);
    },

    handleOrientationChange(e) {
        const isPortrait = e.orientation === 0 || e.orientation === 180;
        this.isActive =
            e.orientation !== null && isPortrait === this.requireLandscape;

        if (isPortrait) {
            if (
                this.container.audioManager.isAudioOn &&
                this.container.audioManager.currentSound !== null &&
                this.container.audioManager.currentSound !== undefined
            ) {
                this.currentPlayingAudioFile =
                    this.container.audioManager.currentSound.url;
                this.container.audioManager.pause(this.currentPlayingAudioFile);
            }
        } else {
            if (this.container.audioManager.currentSound !== undefined) {
                this.currentPlayingAudioFile =
                    this.container.audioManager.currentSound.url;
                this.container.audioManager.play(this.currentPlayingAudioFile);
            }
        }

        this.renderRotateMessage();
    },

    renderRotateMessage() {
        if (this.isActive) {
            this.render();
        } else {
            if (!this.$wrapper) return;
            ReactDOM.unmountComponentAtNode(this.$wrapper[0]);
            this.$wrapper.remove();
        }
    },
});

export default OrientationOverlay;
