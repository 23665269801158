import React, { useEffect, useRef, useState } from 'react';
import ConversationTypeStandard from './ConversationTypeStandard.jsx';
import ConversationTypeOptions from './ConversationTypeOptions.jsx';

const messageTypes = {
    message: ConversationTypeStandard,
    options: ConversationTypeOptions,
};

const shouldShowContinue = (conversationRoute) => {
    const conversationRouteLast = conversationRoute[conversationRoute.length - 1];

    if (conversationRouteLast === undefined) {
        return false;
    }

    if (conversationRouteLast.type !== 'message') {
        return false;
    }

    return !conversationRouteLast?.goto;
};

/**
 * @param props
 * @param opts
 */
export function useConversationBlock(props, opts = {}) {
    const { blockRef } = props;
    const {
        /** @type {import('./conversation-types').ConversationSchema} */
        data,
    } = blockRef;
    const options = { pauseOnOrientationChange: true, ...opts };

    const [conversationRoute, setConversationRoute] = useState([]);
    const showContinue = shouldShowContinue(conversationRoute);
    const messagesEl = useRef();

    const showMessage = (id) => {
        // Don't show messages if they have already been shown.
        if (conversationRoute.find((m) => m.id === id)) {
            return;
        }

        // Get the message from the JSON data path.
        const message = data.path.find((m) => m.id === id);

        const isFinalMessage = [
            !message,
            message?.end,
            message?.type === 'message' && message?.goto === undefined,
            message?.type === 'message' && message?.goto === null,
        ].some((c) => c);

        if (isFinalMessage) {
            blockRef.complete();
        }

        if (message === undefined) {
            return;
        }

        // Add the message from the JSON path to the current conversation route
        // and scroll it into view.
        setConversationRoute([...conversationRoute, message]);
    };

    // Show the first message. Based on the "entry" prop from the JSON data.
    useEffect(() => showMessage(data.entry), []);

    // Get the sender object from JSON data for a specified message.
    const getSender = (message) => data.participants.find((p) => p.id === message.sender);

    // Add extra data to the messages in the conversation route before giving to
    // the renderer. Adds full sender object to each message and decides the
    // component to render with based on the message type.
    const messages = conversationRoute.map((message, index) => {
        // Add the message which is selected after this message to this message
        // to make features like "avatar at most recent message" work.
        let nextMessage = conversationRoute[index + 1];

        if (nextMessage) {
            nextMessage = { ...nextMessage, sender: getSender(nextMessage) };
        }

        let prevMessage = conversationRoute[index - 1];

        if (prevMessage) {
            prevMessage = { ...prevMessage, sender: getSender(prevMessage) };
        }

        const msg = {
            ...message,
            sender: getSender(message),
            nextMessage,
            prevMessage,
        };
        const Type = messageTypes[msg.type];
        return <Type key={msg.id} message={msg} blockRef={blockRef} />;
    });

    useEffect(() => {
        messagesEl.current?.lastChild?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
        });
    }, [messages]);

    const handleBlockContinue = () => {
        // NOTE: block status updating is handled by showMessage().
        blockRef.app.router.next();
    };

    const context = {
        blockRef,
        showMessage,
        messages,
        options,
    };

    return {
        context,
        messagesEl,
        messages,
        showContinue,
        handleBlockContinue,
        messageTypes,
    };
}
