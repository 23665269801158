import ReactDOM from 'react-dom';
import mountComponent from '../../utils/mountComponent';
import Overlay from './components/Overlay';
import { $, BrowserDetect, Component } from '~core';

class OrientationOverlay extends Component {
    initialize(attributes) {
        attributes = attributes || {};

        this.name = 'OrientationOverlay';
        this.isActive = false;
        this.requireLandscape = attributes.requireLandscape === true;
    }

    setup(container) {
        this.container = container;

        this.listenTo(
            this.container,
            'onLoadComplete',
            this.handleLoadCompleted,
        );
    }

    handleLoadCompleted() {
        const isPortrait =
            this.container.orientation === 0
            || this.container.orientation === 180;

        if (BrowserDetect.isMobileDevice) {
            if (isPortrait) {
                this.isActive = true;
                this.renderRotateMessage();
            }
            this.listenTo(
                this.container,
                'onOrientationChange',
                this.handleOrientationChange,
            );
        }
    }

    mount() {
        this.$wrapper = $('<div id=\'orientation-overlay\' />');
        this.container.dom.prepend(this.$wrapper);
        mountComponent(Overlay, this.$wrapper[0]);
    }

    handleOrientationChange(e) {
        const isPortrait = e.orientation === 0 || e.orientation === 180;
        this.isActive =
            e.orientation !== null && isPortrait === this.requireLandscape;

        if (isPortrait) {
            if (
                this.container.audioManager.isAudioOn
                && this.container.audioManager.currentSound !== null
                && this.container.audioManager.currentSound !== undefined
            ) {
                this.currentPlayingAudioFile =
                    this.container.audioManager.currentSound.url;
                this.container.audioManager.pause(this.currentPlayingAudioFile);
            }
        } else {
            if (this.container.audioManager.currentSound !== undefined) {
                this.currentPlayingAudioFile =
                    this.container.audioManager.currentSound.url;
                this.container.audioManager.play(this.currentPlayingAudioFile);
            }
        }

        this.renderRotateMessage();
    }

    renderRotateMessage() {
        if (this.isActive) {
            this.mount();
        } else {
            if (!this.$wrapper) return;
            ReactDOM.unmountComponentAtNode(this.$wrapper[0]);
            this.$wrapper.remove();
        }
    }
}

export default OrientationOverlay;
