import screenfull from 'screenfull';

/**
 * @see {@link https://github.com/sindresorhus/screenfull.js} for ScreenFull documentation
 */
class BlockFullscreen {
    constructor(context) {
        this.exitFullscreen = true;
        this.context = context;
    }

    reference = null;

    /**
     * Is there already something fullscreen on this page
     * @return {boolean}
     */
    get isFullscreen() {
        return screenfull.isFullscreen;
    }

    /**
     * Toggle fullscreen for the reference element
     * @return {Promise<void>}
     */
    toggle = () => {
        if (this.context?.container && this.reference === null) {
            this.reference = this.context.container;
        }

        if (!this.context.fullscreen.reference?.current) {
            throw new Error(
                'You need to set a fullscreen reference before being able to toggle fullscreen'
            );
        }

        return screenfull
            .toggle(this.context.fullscreen.reference.current)
            .then(() => {
                this.context.setState({
                    fullscreen: screenfull.isFullscreen,
                });
            });
    };
}

export default BlockFullscreen;
