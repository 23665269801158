import Backbone from 'backbone';

class NavigationCollector {
    constructor(log, container) {
        this.log = log;
        this.type = 'NavigationCollector';

        Backbone.listenTo(container.session, 'onChangeSlide onUpdate', (e) => {
            log.add({
                type: this.type,
                event: 'Session.onChangeSlide|onUpdate',
                data: e.pageData.pageID(),
                time: Date.now(),
            });
        });

        Backbone.listenTo(container.session, 'onGlobalPage', (e) => {
            log.add({
                type: this.type,
                event: 'Session.onGlobalPage',
                data: e.pageData.pageID(),
                time: Date.now(),
            });
        });

        ['onNext', 'onPrevious'].forEach((event) => {
            Backbone.listenTo(container.session, event, (e) => {
                log.add({
                    type: this.type,
                    event: `Session.${event}`,
                    data: e,
                    time: Date.now(),
                });
            });
        });
    }

    print() {
        return this.log.where({ type: this.type });
    }
}

export default NavigationCollector;
