import { useMemo } from 'react';
import { Status } from '../../index.js';
import { t } from '~templates/utils/i18n.js';
import { useBlock } from '~blocks/index';

const getUnitStatusLabel = (index) => {
    switch (index) {
        case 1: return t('not_started');
        case 2: return t('inprogess');
        case 3: return t('completed');
        case 4: return t('failed');
        case 5: return t('passed');
        case 6: return t('optional');
        case 7: return t('locked');
        default: return t('unavailable');
    }
};

/**
 * @returns {import('./CourseMap.types.js').CourseMapHookReturn}
 */
export function useCourseMap() {
    const { block: blockRef, container } = useBlock();
    const units = useMemo(() => {
        const courseData = blockRef?.junctionData
            ? blockRef.junctionData
            : blockRef.app.courseData;

        const router = blockRef.junctionController
            ? blockRef.junctionController
            : blockRef.app.session;

        return Array.from(
            { length: courseData.getTotalModules() },
            (v, i) => {
                const module = courseData.getModule(i);

                return {
                    index: i,
                    number: i + 1,
                    id: module.getModuleID(),
                    title: module.getModuleTitle(),
                    description: module.getModuleDescription(),
                    status: {
                        code: module.getModuleStatus(),
                        label: getUnitStatusLabel(module.getModuleStatus()),
                        cssClass: Status.numberToStatus(module.getModuleStatus()).toLowerCase(),
                    },
                    completion: courseData.getTotalCompletedPagesByModule(i).percentage,
                    original: module,
                    actions: {
                        enter: () => {
                            const status = module.getModuleStatus();

                            if (status === Status.LOCKED || (status === Status.UNAVAILABLE && blockRef.app.isLocked)) {
                                return;
                            }

                            router.startModule(i);
                        },
                    },
                };
            },
        );
    }, []);

    const nextModuleIndex = useMemo(() => {
        const previousModuleIndex = container.session.previousPageData
            ? container.session.previousPageData.get('moduleNumber')
            : 0;

        const courseData = blockRef?.junctionData
            ? blockRef.junctionData
            : blockRef.app.courseData;

        const modules = courseData.modules.models;

        const previousModule = modules[previousModuleIndex];

        if (previousModuleIndex === modules.length - 1) {
            return previousModuleIndex;
        }

        if (previousModule.getModuleStatus() >= 3) {
            return previousModuleIndex + 1;
        }

        return previousModule;
    }, []);

    return { units, nextModuleIndex };
}
