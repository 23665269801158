import _ from 'underscore';
import { Status } from '../../../index';
import Junction from '../junction';
import JunctionCourseData from '../data/junctioncoursedata';
import JunctionTracking from '../tracking/junctiontracking';
import JunctionAudio from '../audio/junctionaudio';
import QuizJunctionController from './quizjunctioncontroller';

const QuizJunction = Junction.extend({
    initialize(options) {
        Junction.prototype.initialize.call(this, options);
        this.listenTo(this, 'onJunctionDataCreated', this.handleDataCreated);
        this.startPage = 'start';
    },

    getData() {
        this.junctionData = this.blockData.junctionData;
        this.junctionData.score = 1;
        this.junctionData.setPassmark(1);

        this.junctionController = new QuizJunctionController(
            this.app,
            this.junctionData,
            this.$junctionArea,
            this
        );
        this.junctionTracking = new JunctionTracking(
            this.app,
            this.junctionData,
            this.junctionController
        );
        this.junctionAudio = new JunctionAudio(
            this.app,
            this.junctionController.pageRenderer
        );
    },

    handleDataCreated() {
        this.junctionData.questionPages = [];

        this.junctionData.modules.each((module, i) => {
            const { pages } = this.junctionData.getModule(i);
            _.each(
                pages,
                (page) => {
                    this.junctionData.questionPages.push(page);
                },
                this
            );
        }, this);

        this.junctionData.totalQuestions =
            this.junctionData.questionPages.length;
        this.junctionData.correctCount = 0;
        this.junctionData.wrongCount = 0;
        this.junctionData.firstPageID = this.junctionData
            .getModule(0)
            .getPage(0)
            .pageID();

        this.scoreJunctionPages();

        this.junctionData.startPageData = _.find(
            this.junctionData.pages.models,
            (page) => {
                return page.pageID() === this.startPage;
            },
            this
        );
    },

    showStartPage() {
        if (
            this.startPage &&
            typeof this.junctionData.startPageData !== 'undefined'
        ) {
            this.junctionController.switchPage(this.startPage);
        } else {
            this.junctionController.routeToPage(this.junctionData.firstPageID);
        }
    },

    checkCompletion() {
        const status = this.junctionData.getStatus();

        switch (status) {
            case Status.PASSED:
                this.pass(true);
                this.pageData.setStatus(Status.PASSED, true);
                break;
            case Status.FAILED:
                this.fail(true);
                this.pageData.setStatus(Status.FAILED, true);
                break;
            case Status.COMPLETED:
                this.complete(true);
                this.pageData.setStatus(Status.COMPLETED, true);
                break;
        }
    },

    scoreJunctionPages() {
        this.listenTo(
            this.junctionController.pageRenderer,
            'onBlockInitialized',
            function (e) {
                const template = e.view;

                this.listenToOnce(template, 'onSubmitAnswer', (e) => {
                    e.target = template;
                    this.handleSubmit(e.target, e.status);
                });
            }
        );
    },

    handleSubmit(target, status) {
        if (status === Status.PASSED) {
            this.junctionData.correctCount++;
        } else if (status === Status.FAILED) {
            this.junctionData.wrongCount++;
        }

        this.trigger('onQuestionAnswered', {
            pageData: target.pageData,
            status,
        });
    },
});

export default QuizJunction;
