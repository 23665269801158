import Backbone from 'backbone';
/**
 * @class Setting up LMS function name constants based on its version
 * @name LMS
 *
 * @property {string} initialise
 * @property {string} finish
 * @property {string} getValue
 * @property {string} setValue
 * @property {string} commit
 * @property {string} getLastError
 * @property {string} getErrorString
 * @property {string} getDiagnostic
 *
 * @augments Backbone.Model
 *
 * @see Backbone.Model
 */
const LMS = Backbone.Model.extend(
    /** @lends LMS.prototype */ {
        constructor() {
            this.initialise = '';
            this.finish = '';
            this.getValue = '';
            this.setValue = '';
            this.commit = '';
            this.getLastError = '';
            this.getErrorString = '';
            this.getDiagnostic = '';

            Backbone.Model.apply(this, arguments);
        },

        /**
         * Initialize the LMS function name constants based on version
         * @param {string} version
         */
        initialize(version) {
            if (version == '1.2') {
                this.initialise = 'LMSInitialize';
                this.finish = 'LMSFinish';
                this.getValue = 'LMSGetValue';
                this.setValue = 'LMSSetValue';
                this.commit = 'LMSCommit';
                this.getLastError = 'LMSGetLastError';
                this.getErrorString = 'LMSGetErrorString';
                this.getDiagnostic = 'LMSGetDiagnostic';
            } else {
                this.initialise = 'Initialize';
                this.finish = 'Terminate';
                this.getValue = 'GetValue';
                this.setValue = 'SetValue';
                this.commit = 'Commit';
                this.getLastError = 'GetLastError';
                this.getErrorString = 'GetErrorString';
                this.getDiagnostic = 'GetDiagnostic';
            }
        },
    }
);

export { LMS };
