import TimelineMax from 'gsap/TimelineMax';
import { useEffect, useMemo } from 'react';

function useTimelineMemo() {
    const timeline = useMemo(() => new TimelineMax(), []);
    useEffect(() => {
        return () => timeline.kill();
    }, []);
    return timeline;
}

export default useTimelineMemo;
