import Backbone from 'backbone';
import Component from '../../component/component.js';

import AudioCollector from './plugins/AudioCollector.js';
import LmsCollector from './plugins/LmsCollector.js';
import NavigationCollector from './plugins/NavigationCollector.js';
import StatusCollector from './plugins/StatusCollector.js';
import CourseStatusCollector from './plugins/CourseStatusCollector.js';
import ScreenRendererCollector from './plugins/ScreenRendererCollector.js';
import JunctionCollector from './plugins/JunctionCollector.js';
import ContainerCollector from './plugins/ContainerCollector.js';
import ClickCollector from './plugins/ClickCollector.js';

const LogCollector = Component.extend({
    initialize() {
        this.name = 'LogCollector';
        this.win = undefined;

        this.logs = new Backbone.Collection();
        this.logs.on('add', (item) => {
            // if (!this.win || this.win.closed == true) return;
            // if (!this.isLiveLogging) return;
            console.info(
                'LogCollector.',
                item.get('type'),
                item.get('event'),
                item.get('time'),
                item.get('data')
            );

            // this._templatePart( this.win.document.querySelector("table tbody"), item);
        });

        // alert("Collector");

        // this.showReport();

        // document.addEventListener('touchstart', (e) => {
        // 	clearTimeout( this.timer );
        // 	this.timer = setTimeout( () => {
        // 		alert("Bang");
        // 	}, 4000 );

        // 	document.addEventListener('touchend', (e) => {
        // 		clearTimeout( this.timer );
        // 	})
        // });
    },

    setup(container) {
        this.container = container;
        this.collectors = [
            new AudioCollector(this.logs, this.container),
            new LmsCollector(this.logs, this.container),
            new NavigationCollector(this.logs, this.container),
            new StatusCollector(this.logs, this.container),
            new CourseStatusCollector(this.logs, this.container),
            new ScreenRendererCollector(this.logs, this.container),
            new JunctionCollector(this.logs, this.container),
            new ContainerCollector(this.logs, this.container),
            // new ClickCollector( this.logs, this.container )
        ];
    },

    exportData(win) {
        const text = JSON.stringify(this.logs);
        const input = win.document.createElement('textarea');
        win.document.body.appendChild(input);
        input.value = text;
        input.focus();
        input.select();
        const result = win.document.execCommand('copy');
        if (result === 'unsuccessful') {
            return alert('Failed to copy text');
        }

        win.document.body.removeChild(input);
        win.close();
    },

    // showReport: function() {

    //     var win = window.open("#", "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=1000,height=400");
    //         win.document.write(
    //             `<button id="export-button">Export session</button>
    //             <hr />
    //             <button id="import-button">Import session</button>
    //             <input id="load-session"></input>
    //             <hr />
    //             `
    // 		);

    // 	win.opener.addEventListener("unload", (e) => {
    // 		win.close();
    // 	}, true);

    // 	this.win = win;

    //     this.getJavaScript( win );
    //     this.getStyleSheet( win );

    //     const loadButton = win.document.querySelector('#load-session');

    //     loadButton.addEventListener('paste', e => {
    //         const text = e.clipboardData.getData('text/plain');
    //         console.log('Got pasted text: ', text);
    //     });

    //     const exportButton = win.document.querySelector('#export-button');
    //     exportButton.addEventListener('click', (e) => {
    //         console.info("Click.export");
    //         this.exportData(win);
    //     });

    //     const importButton = win.document.querySelector('#import-button');
    //     importButton.addEventListener('click', (e) => {
    //         console.info("Click.import");

    //         this.importData( loadButton.nodeValue, win );
    //     });

    // }
});

// ModuleData.prototype.setModuleStatus = function (value, isForceValue) {
//     if (isForceValue) {
//         this.set({
//             moduleStatus: value
//         });
//         return;
//     }

//     if (value > this.get("moduleStatus")) {
//         this.set({
//             moduleStatus: value
//         });
//         this.trigger("onStatusUpdate", {
//             target: this,
//             status: value
//         })
//     }
// };

// BlockData.prototype.setStatus = function (status, isForced) {

//     // console.info("checking... ", this.pageID(), this.blockID(), this.getStatus(), status, isForced)
//     if (this.getStatus() == status) {
//         var event = {
//             target: this,
//             status: this.getStatus(),
//             changed: false,
//         };

//         // Generic status update event
//         this.trigger("onStatusUpdate", event)
//         return;
//     }

//     if (status < this.getStatus() && !isForced) return;

//     // set the status
//     this.set({
//         blockStatus: status
//     })

//     var event = {
//         target: this,
//         status: this.getStatus(),
//         changed: true
//     };

//     // Generic status update event
//     this.trigger("onStatusUpdate", event)

//     // More specific events for specific block statuses
//     switch (this.getStatus()) {
//         case Status.PASSED:
//             this.trigger("onPassed", event)
//             break;
//         case Status.COMPLETED:
//             this.trigger("onCompleted", event)
//             break;
//         case Status.FAILED:
//             this.trigger("onFailed", event)
//             break;
//         case Status.STARTED:
//             this.trigger("onStarted", event)
//             break;
//         default:
//             'No event to dispath';
//     }

//     return this.blockStatus;
// }

export default LogCollector;
