import { LanguageManager } from '~core';

export const t = (key, { substitution, fallback } = {}) => {
    try {
        const str = LanguageManager.getString(key, substitution);
        return str === '' ? fallback : str;
    } catch (e) {
        return fallback;
    }
};
