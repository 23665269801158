import TimelineMax from 'gsap/TimelineMax';
import { useEffect } from 'react';

function useTimelineEffect(callback, options = {}, deps = []) {
    useEffect(() => {
        const timeline = new TimelineMax(options);
        callback(timeline);
        return () => timeline.kill();
    }, deps);
}

export default useTimelineEffect;
