import $ from 'jquery';

const Utils = {};

Utils.formatInPx = function (n) {
    if (String(n).indexOf('px') > 0) {
        return n;
    }

    return `${n}px`;
};

Utils.stripHTML = function (s) {
    const $div = $('<div />');
    $div.html(s);

    return $.trim($div.text());
};

Utils.processMustaches = function (src) {
    let out = '';
    let indexOpen = src.indexOf('{{');
    let indexClose = src.indexOf('}}');
    let path;

    while (indexOpen > -1 && indexClose > -1) {
        indexClose = src.indexOf('}}');

        path = src.substring(indexOpen + 2, indexClose);

        out += src.substring(0, indexOpen);
        out += `<img class='img-responsive' src='${path}' />`;

        indexClose = src.indexOf('}}');

        src = src.substring(indexClose + 2);

        indexOpen = src.indexOf('{{');
        indexClose = src.indexOf('}}');
    }

    out += src.substring(indexClose, src.length);

    return out;
};

Utils.secondsToMMSS = function (seconds) {
    const format = (val) => `0${Math.floor(val)}`.slice(-2);
    const minutes = (seconds % 3600) / 60;
    if (isNaN(minutes)) return 0;
    return [minutes, seconds % 60].map(format).join(':');
};

// Trim function support for IE8
if (typeof String.prototype.trim !== 'function') {
    String.prototype.trim = function () {
        return this.replace(/^\s+|\s+$/g, '');
    };
}

export default Utils;
