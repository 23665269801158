import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { formatTime } from '~utils/index.js';
import { VideoPlayerContext } from './context.js';

export default function VideoTime(props) {
    const { className } = props;
    const {
        options: { classes },
        time: { currentTime },
        refs: { videoElement },
    } = useContext(VideoPlayerContext);

    const formattedTime = useMemo(() => {
        const input = currentTime;
        return formatTime(input);
    }, [currentTime]);
    const formattedDuration = useMemo(() => {
        const input = videoElement.current?.duration;
        return formatTime(input);
    }, [videoElement.current?.duration]);

    return (
        <div className={className ?? classes?.timer}>
            {videoElement.current?.duration >= 0 && (
                <span>
                    {formattedTime} / {formattedDuration}
                </span>
            )}
        </div>
    );
}

VideoTime.propTypes = {
    className: PropTypes.string,
};

VideoTime.defaultProps = {
    className: undefined,
};
