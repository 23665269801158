import { useEffect, useMemo, useState } from 'react';
import { useBlock } from '~templates/components/blocks';

/**
 * Handle the logic for Accordion blocks
 *
 * TODO: Add support for multiple active items
 *
 * @param {Object} options
 * @param {boolean} [options.defaultItemFirst=false] - Make the first item active by default
 * @param {boolean} [options.createKeys=true] - Create keys that are unique across multiple accordions (Creates a `key` property on each item)
 * @param {boolean} [options.completeWhenAllViewed=true] - Complete this block when all items have been viewed
 * @param {string} [options.propertyName='key'] - Which property on each item to use as the active value
 * @param {string} [options.idPrefix='accordion-'] - Prefix for the unique block ID
 * @returns
 */
function useAccordion(options = {}) {
    const {
        defaultItemFirst = false,
        createKeys = true,
        propertyName = 'key',
        idPrefix = 'accordion',
        completeWhenAllViewed = true,
    } = options;
    const { data, block } = useBlock();

    const items = useMemo(() => data.items.map((item, index) => {
        if (createKeys) {
            item.key = [idPrefix, block.id, item.id ?? index]
                .filter(v => v !== undefined && v !== '').join('-');
        }
        return item;
    }), [data.items]);

    const getKey = item => item?.[propertyName];
    const defaultItem = defaultItemFirst ? items.at(0) : undefined;
    const defaultKey = getKey(defaultItem);
    const [active, setActive] = useState(defaultKey);
    const [viewed, setViewed] = useState(() => new Set(defaultKey === undefined ? [] : [defaultKey]));
    const viewedAllItems = useMemo(() => viewed.size === data.items.length, [viewed.size, data.items]);
    const isItemActive = item => active === getKey(item);
    const isItemViewed = item => viewed.has(getKey(item));

    const changeActive = (item) => {
        const key = typeof item === 'object' ? getKey(item) : item;
        const target = items.find(item => getKey(item) === key);
        if (!target && key !== undefined) return;
        setActive(active === key ? undefined : key);
        setViewed(viewed.add(key));
    };

    useEffect(() => {
        if (!viewedAllItems) return;
        if (!completeWhenAllViewed) return;
        block.complete();
    }, [viewedAllItems]);

    return {
        data,
        items,
        active,
        viewed,
        viewedAllItems,
        setActive,
        setViewed,
        changeActive,
        getKey,
        isItemActive,
        isItemViewed,
    };
}

export default useAccordion;
