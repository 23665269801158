import Backbone from 'backbone';
import { Logger } from '../../../index';
import { LMS } from './lms.js';

/**
 * @class CmiDataItem object to hold key value pairs of cmi data
 * @name ScormInterface
 *
 * @property {string} version
 * @property {Window} win
 * @property {LMS} lms
 * @property {boolean} api
 * @property {string} VERSION_1_2
 * @property {string} VERSION_2004
 *
 * @augments Backbone.Model
 *
 * @see Backbone.Model
 */
const ScormInterface = Backbone.Model.extend(
    /** @lends ScormInterface.prototype */ {
        constructor() {
            this.version = null;
            this.win = window;
            this.api = false;
            this.VERSION_1_2 = '1.2';
            this.VERSION_2004 = '2004';
        },

        initialize() {
            // Nothing happens in the initialization because we need to setup listener first
        },

        /**
         * Find the SCORM API and initialize it
         */
        start() {
            if (this.findApi(this.win)) {
                this.lms = new LMS(this.version);
                const success = Boolean(this.api[this.lms.initialise](''));
                const errorCode = this.getLastErrorCode();

                // Leave the error checking for later on when trying to do a GET/SET on the API
                /**
                 * onScormInitSuccess is fired when SCORM API is found
                 *
                 * @event ScormInterface#onScormInitSuccess
                 * @property {string} version
                 */
                this.trigger('onScormInitSuccess', {
                    target: this,
                    version: this.version,
                });
                return true;
            } else {
                // No response has been caught from the LMS
                /**
                 * onScormInitFail is fired when SCORM API is not found
                 *
                 * @event ScormInterface#onScormInitFail
                 * @property {string} version
                 */
                this.trigger('onScormInitFail', {
                    target: this,
                    version: this.version,
                });
            }
        },

        /**
         * Get an item being stored in LMS
         *
         * @param {string} val
         */
        get(val) {
            let errorCode = 0;
            let value = '';

            if (this.api) {
                value = this.api[this.lms.getValue](val);
                errorCode = this.getLastErrorCode();

                if (errorCode === 0) {
                    return String(value);
                } else {
                    Logger.error(
                        `ScormInterface: ${errorCode} : ${value} : ${val} : ${this.getInfo(
                            errorCode
                        )}`
                    );
                    if (errorCode == 404) {
                        return '';
                    }
                }
            } else {
                Logger.error('ScormInterface: no API available');
            }

            return String(value);
        },

        /**
         * Set an item to store in LMS
         *
         * @param {string} key
         * @param {string} value
         */
        set(key, value) {
            Logger.trace(`ScormInterface.set: ${key} : ${value}`);

            const errorCode = 0;
            let success = false;

            if (this.api) {
                success = this.api[this.lms.setValue](key, value);

                if (success) {
                    Logger.trace(
                        `ScormInterface.set success: ${key} : ${value}`
                    );
                    // TODO Returning error codes
                } else {
                    Logger.error(
                        'ScormInterface.set: error setting the value to the API '
                    );
                }
            } else {
                Logger.error('ScormInterface.set: no API available');
            }
        },

        /**
         * Commit the changes made on the LMS
         */
        commit() {
            if (this.api) {
                const success = Boolean(this.api[this.lms.commit](''));
                const errorCode = this.getLastErrorCode();

                if (errorCode !== null && errorCode === 0) {
                    Logger.info(
                        `ScormInterface: Data saved to the LMS ${success}`
                    );
                    // TODO :: Error checking
                    return true;
                } else {
                    Logger.error(
                        'ScormInterface: Error: data could not be saved to the LMS'
                    );
                    // TODO :: Error checking
                    return false;
                }
            } else {
                Logger.error('ScormInterface: no API available');
            }
        },

        /**
         * Finish the LMS session and get disconnected
         */
        finish() {
            let success;

            if (this.api) {
                success = this.api[this.lms.finish]('');

                if (success) {
                    Logger.trace('ScormInterface: LMS Finish is executed');
                    // TODO :: Error checking
                } else {
                    Logger.error('ScormInterface: LMS finish is not working.');
                    // TODO :: Error checking
                }
            } else {
                Logger.error('ScormInterface: no API available');
            }
        },

        /**
         * Find the SCORM API to communicate with LMS
         *
         * @param {Window} win
         */
        findApi(win) {
            let findAttempts = 0;
            const findAttemptLimit = 500;
            const traceMsgPrefix = 'ScormInterface.findApi: ';

            try {
                while (
                    win.API == undefined &&
                    win.API_1484_11 == undefined &&
                    win.parent != undefined &&
                    findAttempts <= findAttemptLimit
                ) {
                    Logger.trace(
                        `${traceMsgPrefix}Moving up a window level ${win}`
                    );
                    findAttempts++;

                    if (win.parent == win) {
                        win = win.opener;
                    } else {
                        win = win.parent;
                    }
                }
            } catch (e) {
                this.version = '';
                this.api = null;
                Logger.trace(`${traceMsgPrefix}scorm not Found! `);
                return false;
            }

            if (win.API_1484_11) {
                Logger.trace(`${traceMsgPrefix}Found a scorm 2004 API `);
                this.version = '2004';
                this.api = win.API_1484_11;
                return true;
            } else if (win.API) {
                Logger.trace(`${traceMsgPrefix}Found a scorm 1.2 API `);
                this.version = '1.2';
                this.api = win.API;
                return true;
            } else {
                this.version = '';
                this.api = null;
                Logger.trace(`${traceMsgPrefix}scorm not Found! `);
                return false;
            }
        },

        /**
         * Get the information based on errorCode parameter
         *
         * @param {string} errorCode
         */
        getInfo(errorCode) {
            let info;

            if (this.api) {
                info = this.api[this.lms.getErrorString](String(errorCode));
            } else {
                Logger.error('ScormInterface: no API available');
            }

            return info;
        },

        /**
         * Get the last error code if any
         */
        getLastErrorCode() {
            let code;

            if (this.api) {
                code = parseInt(this.api[this.lms.getLastError](), 10);
            } else {
                Logger.error('ScormInterface: no API available');
            }

            return code;
        },

        /**
         * Format the time based on SCORM API version
         *
         * @param {number} milliseconds
         * @returns {string} formattedTime
         */
        formatTime(milliseconds) {
            let formattedTime = '00:00:00.0';
            const elapsedSeconds = milliseconds / 1000;
            switch (this.version) {
                case '1.2':
                    formattedTime = this.convertTime(elapsedSeconds);
                    break;
                case '2004':
                    formattedTime = this.convertTime2004(milliseconds);
                    break;
            }
            return formattedTime;
        },

        /**
         * Convert the format to match with SCORM 1.2 version
         *
         * @param {number} ts
         */
        convertTime(ts) {
            let hours = '00';
            let minutes = '00';
            let seconds = ts % 60;
            ts -= seconds;
            const tmp = ts % 3600;
            ts -= tmp;

            if (ts % 3600 == 0) {
                hours = `${ts / 3600}`;
            }
            if (tmp % 60 == 0) {
                minutes = `${tmp / 60}`;
            }
            seconds = `${seconds}`;

            seconds = seconds.substring(0, seconds.indexOf('.'));

            if (hours.length < 2) hours = `0${hours}`;
            if (minutes.length < 2) minutes = `0${minutes}`;
            if (seconds.length < 2) seconds = `0${seconds}`;

            return `${hours}:${minutes}:${seconds}`;
        },

        /**
         * Convert the format to match with SCORM 2004 version
         *
         * @param {number} ts
         */
        convertTime2004(intTotalMilliseconds) {
            let ScormTime = '';

            let HundredthsOfASecond; // decrementing counter - work at the hundreths of a second level because that is all the precision that is required

            let Seconds; // 100 hundreths of a seconds
            let Minutes; // 60 seconds
            let Hours; // 60 minutes
            let Days; // 24 hours
            let Months; // assumed to be an "average" month (figures a leap year every 4 years) = ((365*4) + 1) / 48 days - 30.4375 days per month
            let Years; // assumed to be 12 "average" months

            const HUNDREDTHS_PER_SECOND = 100;
            const HUNDREDTHS_PER_MINUTE = HUNDREDTHS_PER_SECOND * 60;
            const HUNDREDTHS_PER_HOUR = HUNDREDTHS_PER_MINUTE * 60;
            const HUNDREDTHS_PER_DAY = HUNDREDTHS_PER_HOUR * 24;
            const HUNDREDTHS_PER_MONTH =
                HUNDREDTHS_PER_DAY * ((365 * 4 + 1) / 48);
            const HUNDREDTHS_PER_YEAR = HUNDREDTHS_PER_MONTH * 12;

            HundredthsOfASecond = Math.floor(intTotalMilliseconds / 10);

            Years = Math.floor(HundredthsOfASecond / HUNDREDTHS_PER_YEAR);
            HundredthsOfASecond -= Years * HUNDREDTHS_PER_YEAR;

            Months = Math.floor(HundredthsOfASecond / HUNDREDTHS_PER_MONTH);
            HundredthsOfASecond -= Months * HUNDREDTHS_PER_MONTH;

            Days = Math.floor(HundredthsOfASecond / HUNDREDTHS_PER_DAY);
            HundredthsOfASecond -= Days * HUNDREDTHS_PER_DAY;

            Hours = Math.floor(HundredthsOfASecond / HUNDREDTHS_PER_HOUR);
            HundredthsOfASecond -= Hours * HUNDREDTHS_PER_HOUR;

            Minutes = Math.floor(HundredthsOfASecond / HUNDREDTHS_PER_MINUTE);
            HundredthsOfASecond -= Minutes * HUNDREDTHS_PER_MINUTE;

            Seconds = Math.floor(HundredthsOfASecond / HUNDREDTHS_PER_SECOND);
            HundredthsOfASecond -= Seconds * HUNDREDTHS_PER_SECOND;

            if (Years > 0) {
                ScormTime += `${Years}Y`;
            }
            if (Months > 0) {
                ScormTime += `${Months}M`;
            }
            if (Days > 0) {
                ScormTime += `${Days}D`;
            }

            // check to see if we have any time before adding the "T"
            if (HundredthsOfASecond + Seconds + Minutes + Hours > 0) {
                ScormTime += 'T';

                if (Hours > 0) {
                    ScormTime += `${Hours}H`;
                }

                if (Minutes > 0) {
                    ScormTime += `${Minutes}M`;
                }

                if (HundredthsOfASecond + Seconds > 0) {
                    ScormTime += Seconds;

                    if (HundredthsOfASecond > 0) {
                        ScormTime += `.${HundredthsOfASecond}`;
                    }

                    ScormTime += 'S';
                }
            }

            if (ScormTime == '') {
                ScormTime = '0S';
            }

            ScormTime = `P${ScormTime}`;

            return ScormTime;
        },
    },
    {
        /**
         * Format the time stamp
         *
         * @param {string} timestamp
         */
        FORMAT_TIMESTAMP(timestamp) {
            const date = timestamp ? new Date(timestamp) : new Date();
            return date.toTimeString().substring(0, 8);
        },
    }
);

export { ScormInterface };
