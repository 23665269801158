import Backbone from 'backbone';

class CourseStatusCollector {
    constructor(log, container) {
        this.log = log;
        this.type = 'CourseStatusCollector';

        this.events = [
            'onCourseReset',
            'onCourseIncomplete',
            'onCourseCompleted',
            'onCoursePassed',
            'onCourseFailed',
        ];

        this.events.forEach((event) => {
            Backbone.listenTo(container.session, event, (e) => {
                log.add({
                    type: this.type,
                    event: `Session.${event}`,
                    data: e,
                    time: Date.now(),
                });
            });
        });
    }

    print() {
        return this.log.where({ type: this.type });
    }
}

export default CourseStatusCollector;
