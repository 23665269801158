import React from 'react';
import Card from 'react-bootstrap/Card';
import EmailAddressBadge from './EmailAddressBadge';
import EmailAvatar from './EmailAvatar';

/**
 * Renders from, to & cc email adresses. Used in Email and EmailReply
 */
class EmailDetails extends React.Component {
    constructor(props) {
        super(props);

        this.labels = {
            from: LanguageManager.getString('from'),
            to: LanguageManager.getString('to'),
            cc: LanguageManager.getString('cc'),
        };
    }

    render() {
        const email = this.props.data;
        return (
            <div className="email-info card-body">
                {this.props.type === 'email' ? (
                    <div className="email-from d-flex align-items-center">
                        {email.from.avatar ? (
                            <img
                                className="avatar mw-100 h-auto mr-2 rounded-circle"
                                src={email.from.avatar}
                                alt={email.from}
                            />
                        ) : (
                            <EmailAvatar name={email.from.name} />
                        )}

                        <Card.Title className="mb-0 name">
                            {email.from.name}
                        </Card.Title>
                    </div>
                ) : (
                    <div className="email-from">
                        <Card.Text className="w-50 mb-0">
                            <small className="text-muted">
                                {this.labels.from}:{' '}
                            </small>
                            <EmailAddressBadge email={email.from} />
                        </Card.Text>
                    </div>
                )}

                <div className="email-to d-flex">
                    <Card.Text className="w-50 mb-0">
                        <small className="text-muted">{this.labels.to}: </small>
                        {email.to.items.map((item, i) => {
                            return (
                                <EmailAddressBadge
                                    key={i}
                                    email={item.emailAddress}
                                />
                            );
                        })}
                    </Card.Text>
                    {email.cc.items.length > 0 && (
                        <Card.Text className="w-50 mb-0">
                            <small className="text-muted">
                                {this.labels.cc}:{' '}
                            </small>
                            {email.cc.items.map((item, i) => {
                                return (
                                    <EmailAddressBadge
                                        key={i}
                                        email={item.emailAddress}
                                    />
                                );
                            })}
                        </Card.Text>
                    )}
                </div>
            </div>
        );
    }
}

export default EmailDetails;
