import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import Button from '../../../components/Button';
import Scarf from '../../../components/Scarf';
import useBinding from '../../../hooks/useBinding';
import { getFunctionUrl } from '../../../utils/remote';
import styles from './LaunchForInstructor.module.css';
import { useRoomSync } from '~m/RoomSync';
import { useBlock } from '~templates/components/blocks';

const Errors = {
    LMS_CREDENTIALS_INCORRECT: 'Email and/or password is incorrect.',
    LMS_UNKNOWN_ERROR: 'An error occurred logging into the LMS.',
    LMS_CONNECTION_ERROR: 'An error occurred. Check your connection and try again.',
    ROOM_SYNC_NOT_AN_INSTRUCTOR: 'This LMS user is not a course instructor.',
};

const sendRequest = ({ emailAddress, password }) => {
    const formData = new FormData();
    formData.append('email', emailAddress);
    formData.append('password', password);

    const url = getFunctionUrl('live_training_instructor_login');
    /** @type {RequestInit} */
    const options = {
        method: 'POST',
        // headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: formData,
        redirect: 'follow',
    };

    return fetch(url, options).then(response => response.json());
};

function LaunchForInstructorLogin(props) {
    const { roomSyncError } = props;
    const { container } = useBlock();

    const roomSync = useRoomSync();

    const [emailAddress, , onEmailAddressChange] = useBinding('');
    const [password, , onPasswordChange] = useBinding('');
    const [error, setError] = useState('');
    const [pending, setPending] = useState(false);

    const onSubmit = (e) => {
        e.preventDefault();

        if (pending) return;

        setPending(true);
        setError('');

        container.componentList.Diagnostics.start({ emailAddress });

        sendRequest({ emailAddress, password })
            .then(async (response) => {
                if (response.status === 'fail' && response.data === 'null') {
                    setError(Errors.LMS_CREDENTIALS_INCORRECT);
                    return;
                }

                if (response.status === 'fail' && JSON.parse(response.data) === 'no-permission') {
                    setError(Errors.LMS_CREDENTIALS_INCORRECT);
                    return;
                }

                if (response.status !== 'success') {
                    setError(Errors.LMS_UNKNOWN_ERROR);
                    throw new Error('Something happened');
                }

                const data = JSON.parse(response.data);

                await roomSync.prepareRoomSync(data.email, { lmsUserId: data.id });
            })
            .catch((response) => {
                setError(Errors.LMS_CONNECTION_ERROR);
                console.dir(response);
            })
            .finally(() => {
                // setPending(false);
                console.info('Finally.React.Error');
            });
    };

    const roomSyncErrorText = useMemo(() => {
        if (!roomSyncError) return;
        console.error(roomSyncError);
        switch (roomSyncError) {
            case 'auth/user-not-found': return Errors.ROOM_SYNC_NOT_AN_INSTRUCTOR;
            default: return 'An error occurred.';
        }
    }, [roomSyncError]);

    return (
        <Scarf variant="centred" fullHeight innerClassName={styles.wrapper}>
            <div className={styles.cardLogin}>
                <h1>Instructor Sign In</h1>
                <Form onSubmit={onSubmit}>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="text" value={emailAddress} onChange={onEmailAddressChange} />
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" value={password} onChange={onPasswordChange} />
                    </Form.Group>

                    {error && <p>{error}</p>}

                    {roomSyncErrorText && <p>{roomSyncErrorText}</p>}

                    <Button variant="primary" type="submit" disabled={pending} loading={pending}>
                        Submit
                    </Button>
                </Form>
            </div>
        </Scarf>
    );
}

LaunchForInstructorLogin.propTypes = {
    roomSyncError: PropTypes.bool,
};

LaunchForInstructorLogin.defaultProps = {
    roomSyncError: undefined,
};

export default LaunchForInstructorLogin;
