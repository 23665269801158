import React from 'react';
import PropTypes from 'prop-types';

function MessageIdentifier(props) {
    const { isLocked, id } = props;

    if (isLocked) {
        return null;
    }
    return (
        <small>
            <strong>
                ID == {id}
                <br />
            </strong>
        </small>
    );
}

MessageIdentifier.propTypes = {
    id: PropTypes.string.isRequired,
    isLocked: PropTypes.bool.isRequired,
};

export default MessageIdentifier;
