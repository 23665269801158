import React, { useMemo } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { MdKeyboardArrowDown } from 'react-icons/md';
import createClassName from 'classnames';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import MessageIdentifier from './MessageIdentifier.jsx';
import MessageAvatar from './MessageAvatar.jsx';
import './styles/Message.scss';

/**
 * @param props
 */
function Message(props) {
    const { isLocked, message, onClickSkip } = props;
    const { content, id, sender, nextMessage, prevMessage } = message;

    const hasNextMessage = useMemo(() => nextMessage || !message.goto, [nextMessage]);
    const currSenderId = useMemo(() => message.sender.id, [message]);
    const nextSenderId = useMemo(() => nextMessage?.sender?.id, [nextMessage]);
    const prevSenderId = useMemo(() => prevMessage?.sender?.id, [prevMessage]);
    const showAvatar = useMemo(
        () => currSenderId !== prevSenderId || prevMessage.type === 'options',
        [currSenderId, nextSenderId]
    );
    const side = currSenderId === 'user' ? 'right' : 'left';
    const className = createClassName([
        `message message-${side}`,
        { 'message-with-avatar': showAvatar },
    ]);

    return (
        <div className={className}>
            {showAvatar ? (
                <MessageAvatar sender={sender} padded={!hasNextMessage} />
            ) : (
                <div className='conversation-avatar-placeholder' />
            )}
            <div className='message-content-wrapper'>
                <div className='header'>
                    {showAvatar && (
                        <div className='sender-name font-weight-bold'>{sender.name}</div>
                    )}
                    {/* <MessageIdentifier isLocked={isLocked} id={id} /> */}
                </div>
                <Alert className='message-content'>
                    <div className='text'>{ReactHtmlParser(content)}</div>
                </Alert>
                {!hasNextMessage && (
                    <div className='next-dialogue-button'>
                        <Button
                            size='lg'
                            style={{ textAlign: 'center' }}
                            onClick={onClickSkip}
                            tabIndex='0'
                        >
                            <MdKeyboardArrowDown size={25} />
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
}

Message.propTypes = {
    isLocked: PropTypes.bool.isRequired,
    onClickSkip: PropTypes.func,
    message: PropTypes.shape({
        id: PropTypes.string.isRequired,
        sender: PropTypes.objectOf(PropTypes.any).isRequired,
        content: PropTypes.string.isRequired,
        goto: PropTypes.string,
        nextMessage: PropTypes.shape({
            id: PropTypes.string,
            sender: PropTypes.objectOf(PropTypes.any),
            content: PropTypes.string,
        }),
        prevMessage: PropTypes.shape({
            id: PropTypes.string,
            sender: PropTypes.objectOf(PropTypes.any),
            content: PropTypes.string,
        }),
    }).isRequired,
};

Message.defaultProps = {
    onClickSkip: () => { },
};

export default Message;
