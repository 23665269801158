/** @typedef {import('../../Firebase').default} Firebase */
/** @typedef {import('../RoomSync').default} RoomSync */
/** @typedef {import('~core').Container} Container */
/** @typedef {import('../RoomSync.types').Room} Room */

import { customAlphabet } from 'nanoid';
import { ROOM_ACTIVE, ROOM_ENDED, Routes } from '../RoomSync.contants';
import SaffronError from '../../../utils/SaffronError';
import {
    ALREADY_IN_A_ROOM,
    MUST_BE_INSTRUCTOR,
    NOT_IN_A_ROOM,
    ROOM_CLOSED,
    ROOM_DOES_NOT_EXIST,
    ROOM_ID_MISMATCH,
    UNEXPECTED_ROOM_ID_CHANGED,
    UNEXPECTED_SNAPSHOT_MISSING,
    UNEXPECTED_UNSUBSCRIBE_MISSING,
} from '../RoomSync.errors';
import { Logger } from '~core';
import Model from '~m/ContainerPlugin/Model';

class RoomManager extends Model {
    constructor(roomSync, firebase, container) {
        super();
        /** @type {RoomSync} */
        this.roomSync = roomSync;
        /** @type {Firebase} */
        this.firebase = firebase;
        /** @type {Container} */
        this.container = container;
    }

    #internalId;
    #internalData;
    #internalUnsubscribe;

    async create(name, language, division) {
        if (!this.roomSync.isInstructor) {
            throw new SaffronError(MUST_BE_INSTRUCTOR, 'You must be an instructor to create rooms');
        }

        const roomId = await this.createRoomId();
        const roomGetPath = Routes.rooms.GET(roomId);

        /** @type {Room} */
        const roomData = {
            name,
            language,
            instructorId: null,
            createdAt: new Date().getTime(),
            page: 'coursemap',
            status: ROOM_ACTIVE,
            division,
            endedAt: null,
        };

        await this.firebase.setDoc(roomGetPath, roomData);
    }

    async exists(roomId) {
        const roomGetPath = Routes.rooms.GET(roomId);
        const document = await this.firebase.getDoc(roomGetPath);
        return document.exists();
    }

    /**
     * Closes the specified room
     *
     * This will make the room inaccessible to all users
     *
     * @param {string} roomId
     */
    async close(roomId) {
        if (!this.roomSync.isInstructor) {
            throw new SaffronError(MUST_BE_INSTRUCTOR, 'You must be an instructor to close rooms');
        }

        const roomGetPath = Routes.rooms.GET(roomId);
        await this.firebase.updateDoc(roomGetPath, {
            status: ROOM_ENDED,
        });
    }

    /**
     * Update room data
     *
     * @param {string} roomId
     * @param {Object.<string, any>} data
     */
    async update(roomId, data) {
        const roomGetPath = Routes.rooms.GET(roomId);
        return this.firebase.updateDoc(roomGetPath, data);
    }

    async updateParam(key, value) {
        if (!this.roomSync.isInstructor) {
            throw new SaffronError(MUST_BE_INSTRUCTOR, 'You must be an instructor to update room params');
        }

        if (this.data.params?.[key] === value) {
            Logger.debug('useRoomSyncParams/update: Not updating as value has not changed');
            return;
        }

        this.update(this.roomId, {
            params: { [key]: value },
        });
    }

    async updateMemberData(memberId, data) {
        if (!this.roomId) {
            throw new SaffronError(NOT_IN_A_ROOM, 'You must be in a room to edit your room member details');
        }
        const path = Routes.rooms.members.GET(this.roomId, memberId);
        return this.firebase.updateDoc(path, data);
    }

    get roomId() {
        return this.#internalId;
    }

    get data() {
        return this.#internalData;
    }

    async enter(roomId, options = {}) {
        const {
            prewatchUpdate,
            onRoomUpdate,
            extraChecks,
        } = options;

        if (this.roomId) {
            throw new SaffronError(ALREADY_IN_A_ROOM, `Cannot join "${roomId}" while already in room "${this.roomId}"`);
        }

        const path = Routes.rooms.GET(roomId);
        /** @type {import('firebase/firestore').DocumentSnapshot<import('../RoomSync.types').Room>} */
        // @ts-expect-error types are correct
        const snapshot = await this.firebase.getDoc(path);

        if (!snapshot.exists()) {
            throw new SaffronError(ROOM_DOES_NOT_EXIST, 'This room does not exist');
        }

        if (snapshot.id !== roomId) {
            throw new SaffronError(ROOM_ID_MISMATCH, 'The request ID does not match the ID of the room being joined');
        }

        if (snapshot.data().status === ROOM_ENDED) {
            throw new SaffronError(ROOM_CLOSED, 'This room has closed and cannot be joined');
        }

        // Perform any extra checks that are specific to an account
        // type before allowing entry into this room
        if (typeof extraChecks === 'function') {
            const response = await extraChecks({ snapshot });
            if (!response) return;
        }

        // Update the current room object with the given data object
        // before starting the watcher so that the first response from
        // the watcher contains the new data already.
        if (prewatchUpdate && typeof prewatchUpdate === 'object') {
            await this.update(roomId, prewatchUpdate);
        }

        this.#internalId = roomId;
        this.unsubscribe = this.firebase.subscribeToDoc(path, (snapshot) => {
            if (this.roomId !== snapshot.id) {
                // It shouldn't be possible for the ID to change while watching the room.
                // If you get this error tell CW.
                throw new SaffronError(UNEXPECTED_ROOM_ID_CHANGED, 'At some point the ID of the watched room has changed');
            }

            if (!snapshot.exists()) {
                throw new SaffronError(UNEXPECTED_SNAPSHOT_MISSING, 'At some point the data for this room was removed');
            }

            this.#internalData = snapshot.data();

            onRoomUpdate?.({
                data: snapshot.data(),
            });
        });

        return snapshot.data();
    }

    /**
     * Stops watching the currently watched room
     */
    async leave() {
        if (!this.roomId) {
            Logger.debug('Cannot leave room as there is no currently watched room');
            return;
        }

        if (!this.#internalUnsubscribe) {
            throw new SaffronError(UNEXPECTED_UNSUBSCRIBE_MISSING, `An attempt was made to leave "${this.roomId}" but the unsubscribe method is missing`);
        }

        this.#internalUnsubscribe();
        this.#internalId = undefined;
        this.#internalData = undefined;
    }

    /**
     * Create a new and unique room code
     *
     * This will first check if the room already exists, and
     * if it does it will call itself to try again
     *
     * @returns {Promise<string>} - New room code
     */
    async createRoomId() {
        const roomId = customAlphabet(
            this.roomSync.config.idAlphabet,
            this.roomSync.config.idLength,
        )();

        if (await this.exists(roomId)) {
            return this.createRoomId();
        }

        return roomId;
    }
}

export default RoomManager;
