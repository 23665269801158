// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper__ed1645{--tw-bg-opacity:1;--tw-text-opacity:1;align-items:center;background-color:rgb(0 61 90/var(--tw-bg-opacity));bottom:0;color:rgb(255 255 255/var(--tw-text-opacity));display:flex;flex-direction:column;height:100%;justify-content:center;left:0;padding-left:1.25rem;padding-right:1.25rem;position:fixed;right:0;text-align:center;top:0;width:100%;z-index:9999}.wrapper__ed1645 .title__a61648{font-size:1.3rem;text-transform:uppercase}.wrapper__ed1645 .image__3a123c{margin:1.5em}.wrapper__ed1645 .image__3a123c img{margin-left:auto;margin-right:auto;width:100px}", "",{"version":3,"sources":["webpack://./src/modules/OrientationOverlay/components/OrientationOverlay.module.css"],"names":[],"mappings":"AACI,iBAAA,iBAA6F,CAA7F,mBAA6F,CAA7F,kBAA6F,CAA7F,kDAA6F,CAA7F,QAA6F,CAA7F,6CAA6F,CAA7F,YAA6F,CAA7F,qBAA6F,CAG7F,WAAY,CAHZ,sBAA6F,CAA7F,MAA6F,CAA7F,oBAA6F,CAA7F,qBAA6F,CAA7F,cAA6F,CAA7F,OAA6F,CAK7F,iBAAkB,CALlB,KAA6F,CAE7F,UAAW,CAEX,YAJ6F,CAO7F,gCACI,gBAAiB,CACjB,wBACJ,CAEA,gCACI,YAOJ,CAJQ,oCAAA,gBAAc,CAAd,iBAAc,CAEd,WAFc","sourcesContent":[".wrapper {\n    @apply fixed flex flex-col items-center justify-center inset-0 bg-brandaccent text-white px-5;\n\n    width: 100%;\n    height: 100%;\n    z-index: 9999;\n    text-align: center;\n\n    .title {\n        font-size: 1.3rem;\n        text-transform: uppercase;\n    }\n\n    .image {\n        margin: 1.5em;\n\n        img {\n            @apply mx-auto;\n\n            width: 100px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper__ed1645",
	"title": "title__a61648",
	"image": "image__3a123c"
};
export default ___CSS_LOADER_EXPORT___;
