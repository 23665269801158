import React, { Component } from 'react';
import BlockFluid from './BlockFluid';
import BlockFullscreen from './BlockFullscreen';
import BlockVisibility from './BlockVisibility';

/**
 * @abstract
 */
class Block extends Component {
    block = this.props.blockRef;
    // data = this.props.blockRef.data ? this.props.blockRef?.data : {};
    data = this.props.blockRef?.data;

    container = React.createRef();

    constructor(props) {
        super(props);

        if (!this.data) {
            // debugger;
            // Logger.warn('Block Data missing');
        }

        new BlockVisibility(this);
    }

    componentDidMount() {
        this.setState({
            isFluid: this.data?.fluid ?? false,
        });
    }

    /**
     * Handle the blocks fluid state and related methods
     * @type {BlockFluid}
     */
    fluid = new BlockFluid(this);

    /**
     * Handle setting an element in the block to fullscreen
     * The fullscreen reference is required before being able to change
     * fullscreen states.
     * @type {BlockFullscreen}
     */
    fullscreen = new BlockFullscreen(this);
}

export default Block;
