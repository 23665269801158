import Backbone from 'backbone';
import log4javascript from 'log4javascript';
import BrowserDetect from '../browser/browserdetect';

var Logger = Backbone.Model.extend(
    {},
    {
        LEVEL_ALL: 0,
        LEVEL_TRACE: 1,
        LEVEL_INFO: 2,
        LEVEL_WARN: 3,
        LEVEL_ERROR: 4,

        log: undefined,

        initialize(attributes) {
            this.log = log4javascript.getLogger();

            Logger.setLevel(attributes.level);
            Logger.setEnabled(attributes.enabled === true);

            Logger.addConsole();
            if (attributes.showPopUp) Logger.addPopUp();

            window.onerror = function (errorMsg, url, lineNumber) {
                Logger.error(
                    `Uncaught error ${errorMsg} in ${url}, on line ${lineNumber}`
                );
            };
        },

        addPopUp() {
            const popUpAppender = new log4javascript.PopUpAppender();
            popUpAppender.setThreshold(log4javascript.Level.INFO);
            this.log.addAppender(popUpAppender);
        },

        addConsole() {
            const consoleAppender = new log4javascript.BrowserConsoleAppender();
            consoleAppender.setThreshold(log4javascript.Level.TRACE);
            this.log.addAppender(consoleAppender);
        },

        setLevel(level) {
            let log4jsLevel = log4javascript.Level.ALL;

            switch (level) {
                case Logger.LEVEL_TRACE:
                    log4jsLevel = log4javascript.Level.TRACE;
                    break;
                case Logger.LEVEL_INFO:
                    log4jsLevel = log4javascript.Level.INFO;
                    break;
                case Logger.LEVEL_WARN:
                    log4jsLevel = log4javascript.Level.WARN;
                    break;
                case Logger.LEVEL_ERROR:
                    log4jsLevel = log4javascript.Level.ERROR;
                    break;
            }

            this.log.setLevel(log4jsLevel);
        },

        trace() {
            if (this.log) this.log.trace.apply(this.log, arguments);
        },

        debug() {
            if (this.log) this.log.debug.apply(this.log, arguments);
        },

        info() {
            if (this.log) this.log.info.apply(this.log, arguments);
        },

        warn() {
            if (this.log) this.log.warn.apply(this.log, arguments);
        },

        error() {
            if (this.log) this.log.error.apply(this.log, arguments);
        },

        fatal() {
            if (this.log) this.log.fatal.apply(this.log, arguments);
        },

        table() {
            // Return if BrowserDetect is not found as we can't tell whether this will be supported
            if (!BrowserDetect) return false;

            // TODO: Check whether Log4JS can support table logging
            if (
                String(BrowserDetect.browser).toLowerCase() == 'chrome' ||
                String(BrowserDetect.browser).toLowerCase() == 'firefox'
            ) {
                console.table(arguments);
            } else {
                const message = `console.table() is not supported in your browser: ${BrowserDetect.browser} ${BrowserDetect.version}`;

                Logger.warn(message);
            }
        },

        isEnabled() {
            log4javascript.isEnabled();
        },

        setEnabled(enabled) {
            log4javascript.setEnabled(enabled);
        },
    }
);

export default Logger;
