import $ from 'jquery';
import Component from '../component/component.js';
import { LanguageManager } from '../../index';

const Watermark = Component.extend({
    initialize() {
        this.name = 'Watermark';
    },

    setup(container) {
        this.container = container;

        this.listenTo(
            this.container,
            'onLoadComplete',
            this.handleLoadCompleted
        );
    },

    handleLoadCompleted() {
        this.render();
    },

    render() {
        const content = this.getContent(this.getData());
        const $wrapper = this.container.dom.append(content);
    },

    getContent(data) {
        return `<div id="watermark">
                    <span>${data.label}</span>
                    <img src="${data.logo}" alt="Saffron Interactive">
                </div>`;
    },

    getData() {
        const data = {};

        data.label = LanguageManager.getString('developed_by');
        data.logo = LanguageManager.getString('saffron_logo');

        return data;
    },
});

export default Watermark;
