import React, { useMemo, useRef, useState } from 'react';
import { MdPlayCircleFilled, MdSkipNext } from 'react-icons/md';
import InterludeAction from '../interludeAction/InterludeAction';
import slidePropType from './slideProps';

function InterludeVideo(props) {
    const {
        active,
        slide,
        onCompleted,
        onTimeUpdate,
        onLoadingChange,
        onError,
    } = props;
    const [displayPlayButton, setDisplayPlayButton] = useState(false);
    const [displaySkipButton, setDisplaySkipButton] = useState(false);
    const [started, setStarted] = useState(false);
    const element = useRef();

    useMemo(() => {
        const video = element.current;

        if (video === undefined) {
            return;
        }

        if (!active) {
            video.pause();
        } else {
            video.currentTime = 0;
            video.play();
        }
    }, [active]);

    const start = (e) => {
        if (started) {
            return;
        }

        setStarted(true);
        setDisplayPlayButton(false);
        setDisplaySkipButton(false);
        onLoadingChange(false);

        if (!active) {
            return;
        }

        e.currentTarget.play().catch(() => setDisplayPlayButton(true));
    };

    return (
        <>
            <video
                ref={element}
                controls={false}
                onCanPlayThrough={start}
                src={slide.content}
                onEnded={() => {
                    onCompleted();
                }}
                onError={() => {
                    onError();
                    setDisplaySkipButton(true);
                }}
                onLoadStart={start}
                onTimeUpdate={(e) => {
                    const { duration, currentTime } = e.currentTarget;
                    const percentage = (currentTime / duration) * 100;
                    onTimeUpdate(percentage);
                }}
            />
            <div className="interlude-block-action-wrapper">
                {displayPlayButton && (
                    <InterludeAction
                        msg="Click below to play"
                        icon={MdPlayCircleFilled}
                        onClick={() => {
                            element.current.play();
                            setDisplayPlayButton(false);
                        }}
                    />
                )}
                {displaySkipButton && (
                    <InterludeAction
                        msg="Unable to load video"
                        icon={MdSkipNext}
                        onClick={() => {
                            onCompleted();
                        }}
                    />
                )}
            </div>
        </>
    );
}

InterludeVideo.propTypes = slidePropType;

export default InterludeVideo;
