import { Button } from 'react-bootstrap';
import React from 'react';
import './HotspotPlus.scss';
import classNames from 'classnames';
import { FiCheckCircle, FiPlusCircle } from 'react-icons/fi';
import { VIEWED_CLASS, hotspotButtonProps } from '../HotspotContants';

function HotspotPlus(props) {
    const { viewed } = props;
    const classes = classNames([
        'hotspot-marker-plus',
        { [VIEWED_CLASS]: viewed },
    ]);

    return (
        <div className={classes}>
            <Button variant={viewed ? 'success' : 'primary'}>
                {viewed ? <FiCheckCircle /> : <FiPlusCircle />}
            </Button>
        </div>
    );
}

HotspotPlus.propTypes = hotspotButtonProps;

export default {
    iconSize: [50, 50],
    popupAnchor: [0, -17],
    component: HotspotPlus,
};
