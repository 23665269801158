import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FaGraduationCap } from 'react-icons/fa';
import { BsFillDoorOpenFill } from 'react-icons/bs';
import Avatar from '../Avatar';
import styles from './Navigation.module.css';
import useAccount from '~m/RoomSync/hooks/useAccount';
import { useRoom } from '~m/RoomSync';
import usePermitted from '~m/RoomSync/hooks/usePermitted';

function Navigation(props) {
    const { blockRef: { container } } = props;
    const { isInstructor } = usePermitted();

    const [pageTitle, setPageTitle] = useState('');

    const handleChangeSlide = () => {
        const { blocks, attributes } = container.session.currentPageData;
        setPageTitle(
            blocks.models[0].attributes.template === 'SectionTitle'
                ? ''
                : attributes.pageTitle,
        );
    };

    useEffect(() => {
        container.session.on('onChangeSlide onChangeGlobalSlide', handleChangeSlide);
        handleChangeSlide();

        return () => {
            container.session.off('onChangeSlide onChangeGlobalSlide', handleChangeSlide);
        };
    }, []);

    const [account, { signedIn }] = useAccount();

    const { room } = useRoom();

    return (
        <div className={styles.wrapper}>
            <div className={styles.contain}>
                <div className={styles.navLeft}>
                    <div className={styles.text} role="text" >
                        {pageTitle}
                    </div>
                </div>
                <div className={styles.navRight}>
                    {signedIn && (
                        <>
                            <div className={styles.pill}>
                                <BsFillDoorOpenFill />
                                <span>{room?.name}</span>
                            </div>
                            <div className={styles.pill}>
                                {!isInstructor && account.avatar !== undefined && (
                                    <Avatar wrapperClassName={styles.avatarWrapper} className={styles.avatar} member={account} />
                                )}
                                {isInstructor && (
                                    <FaGraduationCap />
                                )}
                                <span>{account.displayName}</span>
                            </div>
                        </>
                    )}
                    {/* <DropdownMenu.Root modal={false}>
                        <DropdownMenu.Trigger className={styles.button} aria-label={t('menu')} title={t('menu')}><MdMenu /></DropdownMenu.Trigger>

                        <DropdownMenu.Content className={styles.dropdownContent}>
                            <DropdownMenu.Item
                                asChild
                                className={styles.dropdownItem}
                                aria-label={t('audio')}
                                title={t('audio')}
                            >
                                <a href="#audio/toggle">{isAudioOn ? <MdVolumeUp /> : <MdVolumeMute />}</a>
                            </DropdownMenu.Item>
                            <DropdownMenu.Item
                                asChild
                                className={styles.dropdownItem}
                                aria-label={t('resources')}
                                title={t('resources')}
                            >
                                <a href="#global/resources"><MdFolderOpen/></a>
                            </DropdownMenu.Item>
                            <DropdownMenu.Item
                                asChild
                                className={styles.dropdownItem}
                                aria-label={t('help')}
                                title={t('help')}
                            >
                                <a href="#global/help"><MdHelpOutline/></a>
                            </DropdownMenu.Item>
                        </DropdownMenu.Content>
                    </DropdownMenu.Root> */}
                </div>
            </div>
        </div>
    );
}

Navigation.propTypes = {
    blockRef: PropTypes.any.isRequired,
};

export default Navigation;
