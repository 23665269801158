import { UAParser } from 'ua-parser-js';

const BrowserDetect = {
    init() {
        this.parser = new UAParser();
        const result = this.parser.getResult();

        this.userAgent = result.ua;
        this.browser = result.browser.name || 'unknown browser';
        this.version = parseInt(result.browser.version, 10) || 0;
        this.OS = result.os.name || 'unknown OS';
        this.engine = result.engine.name || 'unknown engine';
        this.device = result.device.type || 'desktop';

        this.isMobileDevice = this.device !== 'desktop';
        this.isiOS =
            /iPad|iPhone|iPod/.test(navigator.platform) ||
            (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1); // Some newer iPad are detected as desktop by default
    },
};

export default BrowserDetect;
