import React, { useEffect, useMemo, useState } from 'react';
import { Card, Form, ProgressBar } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import { useDocument } from 'react-firebase-hooks/firestore';
import Button from '../../components/Button';
import Scarf from '../../components/Scarf';
import { getFunctionUrl } from '../../utils/remote';
import styles from './Acknowledgement.module.css';
// import styles from './YourComponent.module.css'; // Adjust the import path based on your project structure.
import { useBlock } from '~templates/components/blocks';
import { t } from '~templates/utils/i18n';
import { useDocumentReference, useFirebase } from '~m/Firebase';
import { Routes } from '~m/RoomSync/RoomSync.contants';
import { useRoomSync } from '~m/RoomSync';
import 'bootstrap/dist/css/bootstrap.min.css'; // Make sure to import the Bootstrap CSS file or add it in your project.

/**
 *
 * @param {{
 *  roomSync: import('~m/RoomSync').default,
 *  firebase: import('~m/Firebase').default,
 *  container: any,
 * }} data
 */
async function sendAcknowledgement(data) {
    const { roomSync, firebase, container } = data;

    const userSnapshot = await firebase.getDoc(roomSync.userManager.accountDetailsPath);
    const { firstName, lastName, region, email } = userSnapshot.data();
    const displayName = [firstName, lastName].filter(Boolean).join(' ');

    const roomCode = roomSync.roomManager.roomId.toString();
    const userId = firebase.user.uid;

    const bodyData = {
        name: displayName,
        firstname: firstName,
        lastname: lastName,
        region,
        user_identifier: `${userId}:${roomCode}`,
        email,
        instructorid: roomSync.roomManager.data.instructorLmsId,
        course_identifier: container.courseData.getCourseTitle(),
        room_code: roomCode,
        timestarted: Math.floor(roomSync.roomManager.data.createdAt / 1000),
        timecompleted: Math.floor(new Date().getTime() / 1000),
    };

    const formdata = new FormData();
    for (const [key, value] of Object.entries(bodyData)) {
        formdata.append(key, value.toString());
    }

    const url = getFunctionUrl('live_training_log_completion');

    /** @type {RequestInit} */
    const requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow',
    };

    return fetch(url, requestOptions)
        .then(response => response.json())
        .then((result) => {
            if (result.status === 'duplicate') {
                throw new Error(t('ack_duplicate_error'));
            }

            if (result.exception || result.errorcode) {
                throw new Error(t('ack_unknown_error'));
            }

            if (result.status !== 'saved') {
                throw new Error(t('ack_not_saved_error'));
            }
        });
}

function AcknowledgementForLearners() {
    const { data, block, container } = useBlock();
    const { text: ackText } = data.columns[0];
    const { text: thankYouText } = data.columns[1];
    const checkBoxRef = React.createRef();
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [ackError, setAckError] = useState(false);
    const [progress, setProgress] = useState(0);

    const roomSync = useRoomSync();
    const firebase = useFirebase();
    const roomId = useMemo(() => roomSync.roomId, [roomSync.roomId]);

    const memberPath = useMemo(() => Routes.rooms.members.GET(roomId, firebase.user.uid), []);

    const [roomMemberSnapshot, loading, error] = useDocument(
        useDocumentReference(memberPath),
    );

    const roomMember = useMemo(() => {
        return roomMemberSnapshot?.data();
    }, [roomMemberSnapshot]);

    const [signedOut, setSignedOut] = useState(false);
    const progressBarFunction = function () {
        const startTime = Date.now();
        const endTime = (startTime + 29000); // 30 seconds
        let animationFrameId;

        const updateProgress = () => {
            const currentTime = Date.now();
            const elapsedTime = currentTime - startTime;
            const percentage = ((elapsedTime / 29000) * 100);
            setProgress(Math.min(percentage, 100));
            if (currentTime < endTime) {
                animationFrameId = requestAnimationFrame(updateProgress);
            } else {
                setProgress(100);
            }
        };

        updateProgress();

        return () => cancelAnimationFrame(animationFrameId);
    //
    };
    const confirm = async () => {
        block.complete();
        setAckError(false);
        try {
            await sendAcknowledgement({ roomSync, firebase, container });

            await firebase.updateDoc(Routes.rooms.members.GET(roomId, firebase.user.uid), {
                acknowledged: true,
            }).catch((error) => {
                console.error(error);
                throw new Error(t('ack_sync_error'));
            });

            try {
                await firebase.signOut();
            } catch (err) {
                console.error('Attempted signout error', err);
            }
            progressBarFunction();
            setSignedOut(true);

            setTimeout(() => {
                window.location.reload();
            }, 1000 * 30);
        } catch (error) {
            setAckError(error.message);
        }
    };

    const hasConfirmed = useMemo(() => {
        return roomMember?.acknowledged || signedOut;
    }, [roomMember, signedOut]);

    return (
        <Scarf variant="centred" >
            {error && !signedOut && (
                <Card className={styles.wrapper}>
                    {t('error_loading_content')}
                </Card>
            )}
            {!loading && hasConfirmed && (
                <>
                    <Card className={styles.wrapper}>
                        <div className="progress" style={{
                            height: '4px',
                            margin: '-40px -40px 40px -40px',
                            borderRadius: '2.5px 2.5px 0px 0px',
                        }}>
                            <div
                                className="progress-bar"
                                role="progressbar"
                                style={{
                                    width: `${progress}%`,
                                    backgroundColor: 'rgb(0, 61, 90)',
                                }}
                                aria-valuenow={progress}
                                aria-valuemin="0"
                                aria-valuemax="100"
                            ></div>
                        </div>

                        {ReactHtmlParser(thankYouText)}
                        {/* <div>{t('ack_disconnect')}</div> */}
                    </Card></>

            )}
            {!loading && !hasConfirmed && (
                <Card className={styles.wrapper}>
                    {ReactHtmlParser(ackText)}
                    {ackError && <p>{ackError}</p>}
                    <div className={styles.formWrap}>
                        <Form.Check
                            inline
                            ref={checkBoxRef}
                            label={t('i-agree')}
                            name="group1"
                        >
                            <Form.Check.Label className={styles.formLabel}>
                                <Form.Check.Input
                                    className={styles.formInput}
                                    onChange={(e) => {
                                        setButtonDisabled(!e.target.checked);
                                    }}
                                />
                                {t('i-agree')}
                            </Form.Check.Label>
                        </Form.Check>
                        <Button disabled={buttonDisabled} onClick={confirm}>{t('confirm')}</Button>
                    </div>
                </Card>
            )}
        </Scarf>
    );
}

export default AcknowledgementForLearners;
