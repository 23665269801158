import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

function InterludeOverlay(props) {
    const { overlay, index } = props;

    const presets = overlay.presets?.map((p) => `overlay-preset-${p}`) || [];

    return (
        <div
            key={index}
            style={overlay.position}
            className={classNames(['overlay-preset', ...presets])}
        >
            {overlay.content}
        </div>
    );
}

InterludeOverlay.propTypes = {
    index: PropTypes.number.isRequired,
    overlay: PropTypes.shape({
        type: PropTypes.string,
        content: PropTypes.string,
        presets: PropTypes.arrayOf(PropTypes.string),
        position: PropTypes.shape({
            top: PropTypes.string,
            right: PropTypes.string,
            bottom: PropTypes.string,
            left: PropTypes.string,
            width: PropTypes.string,
            height: PropTypes.string,
        }),
    }).isRequired,
};

export default InterludeOverlay;
