/** @typedef {import('../RoomSync.types').IManager} IManager */

import RoomManagementPanel from '../components/RoomManagementPanel';
import { EVENT_AFTER_ROOM_LEAVE, EVENT_BEFORE_ROOM_LEAVE, FirebasePaths, Routes } from '../RoomSync.contants';
import mountComponent from '../../../utils/mountComponent';
import SaffronError from '../../../utils/SaffronError';
import AbstractManager from './AbstractManager';
import styles from './InstructorSyncManager.module.css';

const PASSWORD = '6d804c88-f426-4f38-9cf5-d5eb2ab6d54d';

/**
 * @implements {IManager}
 */
class InstructorSyncManager extends AbstractManager {
    get accountDetailsPath() {
        return Routes.learners.GET(this.firebase.user.uid);
    }

    async login(email, extraData = {}) {
        try {
            return await this.firebase.signInWithEmailAndPassword(email, `${PASSWORD}:${extraData.lmsUserId}`);
        } catch (error) {
            console.error(error);

            if (error.code === 'auth/user-not-found') {
                await this.firebase.createUserWithEmailAndPassword(email, `${PASSWORD}:${extraData.lmsUserId}`);
                await this.firebase.write(`users/${this.firebase.user.uid}/role`, 'instructor');
            }
        }
    }

    async updateUserData(values, force = false) {
        if (force === false && !this.roomSync.isLearner) {
            throw new SaffronError('instructor-sync-manager/must-be-instructor', 'Must be an instructor to update data');
        }

        await this.firebase.setDoc(`learners/${this.firebase.user.uid}`, values);
    }

    async enterRoom(roomId) {
        const instructorSnapshot = await this.firebase.getDoc(`learners/${this.firebase.user.uid}`);
        const instructorData = await instructorSnapshot.data() ?? {};

        if (!instructorData.lmsUserId) {
            throw new Error('Instructors cannot enter rooms without their LMS user ID set.');
        }

        const room = await this.roomManager.enter(roomId, {
            prewatchUpdate: {
                instructorId: this.firebase.user.uid,
                instructorLmsId: instructorData.lmsUserId,
            },
        });

        this.container.session.on(
            'onChangeSlide onChangeGlobalSlide onJunctionUpdate',
            event => this.handleChangeSlide(event, roomId),
        );

        this.mountRoomManagementPanel();

        return room;
    }

    leaveRoom() {
        if (!this._currentRoomRef) {
            throw new SaffronError('instructor-sync-manager/not-in-a-room', 'Cannot leave room as there is not a current room to leave');
        }

        this.roomSync.trigger(EVENT_BEFORE_ROOM_LEAVE);

        this._currentRoomRef();

        this._currentRoomRef = null;
        this._currentRoomData = null;

        this.roomSync.trigger(EVENT_AFTER_ROOM_LEAVE);
    }

    mountRoomManagementPanel() {
        const wrapper = document.createElement('div');
        wrapper.classList.add(styles.wrapper);

        const body = document.getElementsByTagName('body')[0];
        body.classList.add(styles.body);
        body.prepend(wrapper);

        mountComponent(RoomManagementPanel, wrapper, {
            container: this.container,
        });
    }

    async handleChangeSlide(event, roomId) {
        const page = event.pageData.attributes.pageID;
        if (page === 'home') return;
        await this.roomManager.update(roomId, {
            page: event.pageData.attributes.pageID,
            params: {},
        });
    }
}

export default InstructorSyncManager;
