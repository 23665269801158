import $ from 'jquery';
import Backbone from 'backbone';
import { Logger } from 'log4javascript';
import _ from 'underscore';
import { BrowserDetect, Junction, Status } from '../../../index';
import Component from '../component';
import { ScoringPages } from './scoringpage';

/**
 * @classdesc Scoring is setup to work as a plugin based system.
 *
 * Pages with scoring are defined in the XML file parsed during the
 * initialisation of this component. The different possible outcomes are defined
 * for each page and scores are associated with each outcome.
 *
 * Plugins extend {@link ScoringPlugin}. Their role is to link up the result of
 * a page with the outcomes defined in the XML.
 *
 * A plugin is initilised onChangeslide for a page if it's inluded the in the XML
 * and then destroyed on the next onChangeSlide event. The result for a page is
 * updated before the plugin is destroyed. Once a page has been scored and the
 * associated plugin does not get initilised again.
 *
 * The maxiumum score is calculated as the sum of all outcomes maked with isMax.
 *
 * Current includes functionality to work for junctions and subpages.
 *
 * @class
 * @property {Container} container Reference to the container
 * @property {string} xmlPath Path to XML file of the scoring data
 * @property {ScoringPages} pages A collection of scoring pages
 * @property {Storage} storage Storage object
 */
const Scoring = Component.extend(
    {
        /**
         * Fired when the score for any page changes.
         *
         * @event Scoring~onScoreChange
         */

        /**
         * Initialise component.
         *
         * @memberof Scoring#
         * @param  {string} name    Name for the component
         * @param  {string} jsonPath Path to the scoring XML file
         */
        initialize(jsonPath) {
            if (!jsonPath) throw `${this.name}: no JSON path specified`;

            this.name = 'Scoring';
            this.json = jsonPath;
            this.storage = undefined;

            this.pages = new ScoringPages();
        },

        /**
         * Setup component.
         *
         * @memberof Scoring#
         * @param  {import('react-dom').Container} container
         */
        setup(container) {
            this.container = container;
            this.storage = container.storage;

            this.listenTo(
                this.container,
                'onLoadComplete',
                () => {
                    this.loadJSON();
                },
                this
            );
        },

        /**
         * Load the scoring JSON file.
         *
         * @memberof Scoring#
         * @private
         */
        loadJSON() {
            $.getJSON(this.json, (data) => {
                this.handleDataLoaded(data.pages);
            }).fail(() => {
                Logger.error(`${this.name}: failed to load JSON`);
            });
        },

        /**
         * Extract scoring data from laoded JSON and build scoring pages.
         * Load score from storage.
         *
         * @memberof Scoring#
         * @private
         * @param  {object} data score pages data
         */
        handleDataLoaded(data) {
            this.data = data;
            this.createData();
            this.setupListeners();
            this.loadScore();
        },

        /**
         * Create models of ScoringPages and Score
         *
         * @memberof Scoring#
         * @private
         */
        createData() {
            this.data.forEach((pageData) => {
                const { plugin } = pageData;

                const options = [];
                pageData.options.forEach((option) => {
                    options.push({
                        id: option.id,
                        score: this.extractScore(option.labels),
                        isMax: option.isMax,
                    });
                });

                this.pages.add({
                    id: pageData.id,
                    pluginType: plugin || '',
                    options,
                });
            });
        },

        /**
         * Create {@link Score} object from scores JSON.
         *
         * @memberof Scoring#
         * @private
         * @param  {object} optionScores Scores array
         * @returns {Score} Score object.
         */
        extractScore(optionScores) {
            const score = {};
            optionScores.forEach((optionScore) => {
                const label = optionScore.key;
                const value = optionScore.value || 0;

                score[label] = value;
            });

            return new Score(score);
        },

        /**
         * Setup listeners.
         *
         * @memberof Scoring#
         * @private
         */
        setupListeners() {
            this.listenTo(
                this.container.session,
                'onChangeSlide',
                this.handleChangeSlide
            );
            this.listenTo(
                this.container.screenManager,
                'onBlockInitialized',
                this.handleBlockInitialized
            );

            this.listenTo(this.container.tracking, 'onClear', this.reset);
            this.listenTo(
                this.storage,
                'onResetPageCompletion',
                this.handleResetPage
            );
        },

        /**
         * Handle Session~onChangeSlide event.
         *
         * @memberof Scoring#
         * @private
         * @listens Session~onChangeSlide
         * @param  {object} e Event object for onChangeSlide event
         */
        handleChangeSlide(e) {
            if (e.previousPageData) this.handlePreviousPage(e.previousPageData);
            if (e.pageData) this.handleNextPage(e.pageData);
        },

        /**
         * Setup socring for new page.
         * Do nothing if the page has been scored already.
         *
         * @memberof Scoring#
         * @private
         * @param  {import('../../data/pagedata').default} pageData Page data object of new page
         */
        handleNextPage(pageData) {
            const page = this.pages.get(pageData.pageID());
            if (
                !page ||
                pageData.getStatus() >= Status.COMPLETED ||
                !pageData.getCompletionRequired()
            )
                return false;

            const pluginType =
                window[page.getPluginType() + Scoring.PLUGIN_SUFFIX];
            if (pluginType) {
                const plugin = new pluginType(
                    this.container,
                    pageData,
                    page.getOptions()
                );
                page.setPlugin(plugin);
                this.listenTo(
                    plugin,
                    'onResultUpdated',
                    this.handleResultUpdated
                );
                this.listenTo(
                    pageData,
                    'onPassed',
                    this.handlePageStatusUpdate
                );
                this.listenTo(
                    pageData,
                    'onFailed',
                    this.handlePageStatusUpdate
                );
                this.listenTo(
                    pageData,
                    'onCompleted',
                    this.handlePageStatusUpdate
                );
            }
        },

        /**
         * Score the previous page and perform an necessarily clean-up.
         * Do nothing if the page has been scored already.
         *
         * @memberof Scoring#
         * @private
         * @param  {PageData} pageData Page data object of previous page
         */
        handlePreviousPage(pageData) {
            const page = this.pages.get(pageData.pageID());
            if (!page || !pageData.getCompletionRequired()) return false;

            if (pageData.getStatus() < Status.COMPLETED) {
                page.setResult(null); // Clear result if not completed.
            }

            const plugin = page.getPlugin();
            if (plugin) {
                this.stopListening(pageData);
                this.stopListening(plugin);
                plugin.destroy();
                page.setPlugin(null);
            }
        },

        /**
         * Save the socre for a completed page.
         *
         * @memberof Scoring#
         * @param e
         * @private
         * @param {PageData} pageData Page data object of previous page
         */
        handlePageStatusUpdate(e) {
            const pageData = e.target;
            if (pageData.getStatus() < Status.COMPLETED) return false;

            this.saveScore();
            this.print();
        },

        /**
         * Notiy scoring plauin for the current page that a block has been initilised,
         * giving the plugin an oportunity to attach any required listenrs for the block.
         *
         * This currently handles Junction by setting up listens for changes of sub-pages.
         *
         * @memberof Scoring#
         * @private
         * @listens ScreenManager~onBlockInitialized
         * @param  {object} e Event Object for onBlockInitialized event
         */
        handleBlockInitialized(e) {
            if (e.blockData.view instanceof Junction)
                this.handleJunction(e.blockData.view);

            const page = this.pages.get(e.blockData.pageID());
            if (!page) return false;

            const plugin = page.getPlugin();
            if (plugin && plugin.pageData.getStatus() < Status.COMPLETED) {
                plugin.blockInitialize(e.blockData);
            }
        },

        /**
         * Assign listeners to handle Junction.
         *
         * @memberof Scoring#
         * @private
         * @param  {Junction} junction Junction instance
         */
        handleJunction(junction) {
            this.listenToOnce(junction, 'onJunctionDataCreated', function () {
                this.listenTo(
                    junction.junctionController,
                    'onSwitchChange',
                    this.handleChangeSlide
                );
                this.listenTo(
                    junction.junctionController.pageRenderer,
                    'onBlockInitialized',
                    this.handleBlockInitialized
                );
            });

            this.listenToOnce(junction, 'onDestroy', function () {
                this.stopListening(
                    junction.junctionController,
                    'onSwitchChange'
                );
                this.stopListening(
                    junction.junctionController.pageRenderer,
                    'onBlockInitialized'
                );
            });
        },

        /**
         * Handles plugin result update by updating the page result.
         *
         * @memberof Scoring#
         * @private
         * @param  {object} e Event object
         */
        handleResultUpdated(e) {
            const plugin = e.target;
            const pageId = plugin.pageData.pageID();
            const page = this.pages.get(pageId);

            if (!page) {
                Logger.warn('Page not found with ID: ', pageId);
                return false;
            }

            page.updateResult();
            this.trigger('onScoreChanged', { score: this.getScore() });
        },

        handleResetPage(e) {
            const page = this.pages.get(e.pageId);
            if (!page) return;

            page.setResult([]);
            this.saveScore();
            this.trigger('onScoreChanged', { score: this.getScore() });
        },

        /**
         * Reset the score for all pages.
         *
         * @memberof Scoring#
         */
        reset() {
            this.pages.each(function (page) {
                page.setResult([]);
            });
        },

        /**
         * Get the current score for the course.
         *
         * @memberof Scoring#
         * @returns {Score} Current score
         */
        getScore() {
            const score = Score.ZERO();

            this.pages.each(function (page) {
                score.add(page.getScore());
            });

            return score;
        },

        /**
         * Get teh maximum avaiable score for the course.
         *
         * @memberof Scoring#
         * @returns {Score} Maximum score
         */
        getMaxScore() {
            const maxScore = Score.ZERO();

            this.pages.each(function (page) {
                maxScore.add(page.getMaxScore());
            });

            return maxScore;
        },

        /**
         * Get the current score as a percentage of the maximum avaiable score.
         *
         * @memberof Scoring#
         * @returns {Score} Score percentage
         */
        getScorePercent() {
            const score = this.getScore();
            const maxScore = this.getMaxScore();

            return score.getPercentageOf(maxScore);
        },

        /**
         * Save scoring page data.
         *
         * @memberof Scoring#
         * @private
         */
        saveScore() {
            const data = {};
            this.pages.each(function (page) {
                const result = page.getResult();

                if (result && result.length) data[page.getId()] = result;
            }, this);

            this.storage.setItem(this.name, data, true);
        },

        /**
         * Load scoring page data.
         *
         * @memberof Scoring#
         * @private
         */
        loadScore() {
            const data = this.storage.getItem(this.name);

            _.each(
                data,
                function (result, pageId) {
                    const page = this.pages.get(pageId);

                    if (page) page.setResult(result);
                },
                this
            );

            this.trigger('onScoreLoaded', { score: this.getScore() });
        },

        /**
         * Print scoring page data.
         * Does not work in IE due to use of console.table()
         *
         * @TODO Update to use Logger!!!
         *
         * @memberof Scoring#
         */
        print() {
            if (
                !SiConfig.loggerEnabled ||
                !this.container.isShowScore ||
                BrowserDetect.browser === 'IE'
            )
                return false;

            console.clear();

            const score = this.getScore();
            const maxScore = this.getMaxScore();

            this.pages.each(function (page) {
                const pageTable = [];

                _.each(page.getOptions(), function (option) {
                    const pageRow = {
                        Option: option.id,
                        BestAnswer: option.isMax,
                    };

                    _.each(maxScore.attributes, function (value, label) {
                        pageRow[label] = option.score.get(label) | 0;
                    });

                    pageTable.push(pageRow);
                });

                console.group();
                console.log('Scoring page:', page.getId());
                console.log('Outcome:', page.getResult());
                if (BrowserDetect.browser != 'IE') console.table(pageTable);
                console.groupEnd();
            });

            console.group();

            const totalTable = [{ name: 'Total' }, { name: 'Available' }];
            _.each(maxScore.attributes, function (value, label) {
                totalTable[0][label] = score.get(label) | 0;
            });
            _.each(maxScore.attributes, function (value, label) {
                totalTable[1][label] = maxScore.get(label) | 0;
            });

            if (BrowserDetect.browser != 'IE') console.table(totalTable);
            console.groupEnd();
        },
    },
    {
        /** Scoring plugin suffix.
         *
         * @memberof Scoring
         * @constant
         */
        PLUGIN_SUFFIX: 'ScoringPlugin',
    }
);

/**
 * @classdesc Score object made of one or more scoring elements.
 * Example: new Score({ firstElement: 5, secondElement: 10, thirdElement: 20 })
 *
 * @class
 * @property {object} score Object containing a score for each scoring element.
 */
var Score = Backbone.Model.extend(
    {
        /**
         * Add on a given score.
         *
         * @memberof Score#
         * @param  {Score} score Score to be added
         * @returns {Score}       Reference to to itself
         */
        add(score) {
            if (!score) return this;

            _.each(
                score.attributes,
                function (value, label) {
                    const current = this.get(label);
                    this.set(label, current ? current + value : value);
                },
                this
            );

            return this;
        },

        /**
         * Get the percentage of this score compared with a given score.
         * A percentage is returned for each scoring element.
         *
         * @memberof Score#
         * @param  {Score} score [description]
         * @returns {Score}       [description]
         */
        getPercentageOf(score) {
            const percent = Score.ZERO();

            _.each(
                score.attributes,
                function (value, label) {
                    if (!value) percent.set(label, 100);
                    // 100% if no max
                    else if (!this.get(label)) percent.set(label, 0);
                    // 0% if no score
                    else
                        percent.set(
                            label,
                            Math.round((this.get(label) / value) * 100)
                        ); // otherwise work out percentage
                },
                this
            );

            return percent;
        },

        /**
         * Iterates over each scoring element, executing the iteratee function for each one.
         * The iteratee is bound to the context object, if one is passed.
         * Each invocation of iteratee is called with arguments: (value, key).
         *
         * @memberof Score#
         * @param  {Function} iteratee Iteratee function to be executed for each scoring element.
         * @param  {object} context  Optional context for iteratee
         */
        each(iteratee, context) {
            _.each(this.attributes, iteratee, context);
        },

        /**
         * Get a string representaion of the Score.
         *
         * @memberof Score#
         * @returns {string} A string representaion of the Score
         */
        toString() {
            let string = '';

            _.each(
                this.attributes,
                function (value, label) {
                    string += `${label}:${value},`;
                },
                this
            );
            // Remove trailing comma
            if (string) string = string.substring(0, string.length - 1);

            return string;
        },
    },
    {
        /**
         * New Score instance
         *
         * @memberof Score
         * @returns {Score} Empty score
         */
        ZERO() {
            return new Score();
        },
    }
);

export { Scoring, Score };
