import PropTypes from 'prop-types';
import React from 'react';
import { Badge, ListGroup } from 'react-bootstrap';
import { Utils } from '~core';

/**
 * @param props
 */
function ReviewListItems(props) {
    const { labels, listItems, onListItemClick } = props;

    const handleListItemClick = (e) => {
        onListItemClick(e);
    };

    return (
        <ListGroup>
            {listItems.map((item, i) => {
                let statusClass = labels.status.filter(
                    (status) => status.label === item.Status
                );
                if (statusClass.length > 0) {
                    statusClass = statusClass[0].class;
                }
                return (
                    <ListGroup.Item
                        key={i.toString()}
                        id={i.toString()}
                        action
                        onClick={handleListItemClick}
                    >
                        <h2 className="title">
                            {item.Title}
                            <Badge pill variant={statusClass}>
                                {item.Status}
                            </Badge>
                        </h2>
                        <div className="desc">
                            {Utils.stripHTML(item.Description)}
                        </div>
                    </ListGroup.Item>
                );
            })}
        </ListGroup>
    );
}

ReviewListItems.propTypes = {
    labels: PropTypes.objectOf(PropTypes.any).isRequired,
    listItems: PropTypes.arrayOf(PropTypes.object).isRequired,
    onListItemClick: PropTypes.func.isRequired,
};

export default ReviewListItems;
