import TimelineMax from 'gsap/TimelineMax';
import React, { useEffect, useRef, useState } from 'react';
import Scarf from '../../components/Scarf';
import useRefIndexedArray from '../../hooks/useRefIndexedArray';
import { injectStylesIntoSvg } from '../../utils';
import Point from './components/Point';
import styles from './WorldMap.module.css';
import { useBlock } from '~templates/components/blocks';

function WorldMap() {
    const context = useBlock({ instantComplete: true });
    /** @type {import('./WorldMap.types').WorldMapSchema}  */
    const data = context.data;

    /** @type {import('react').MutableRefObject<SVGSVGElement>}  */
    const [pointRefs, pointRefProp] = useRefIndexedArray();

    const onMapLoaded = () => {
        const points = pointRefs.current.map(p => p.element);
        new TimelineMax()
            .staggerFromTo(
                points,
                0.5,
                { y: 10, opacity: 0, scale: 0 },
                { y: 0, opacity: 1, scale: 1 },
                0.15,
            );
    };

    return (
        <Scarf variant="centred" className={styles.outer}>
            <div className={styles.block}>
                <div className={styles.map}>
                    <img src="assets/images/misc/world_map_robinson_plain.png" alt="" onLoad={onMapLoaded} />
                    <div className={styles.points}>
                        {data.points.map((point, index) => (
                            <Point key={index} point={point} ref={pointRefProp(index)} />
                        ))}
                    </div>
                </div>
            </div>
        </Scarf>
    );
}

export default WorldMap;
