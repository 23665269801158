import Backbone from 'backbone';
import $ from 'jquery';
import Spinner from 'spin';
import { Power2, Power4, TimelineLite, TweenMax } from 'gsap/TweenMax';
import kebabCase from 'lodash/kebabCase';
import _ from 'underscore';
import Logger from '../utils/log/logger.js';
import AssetLoader from '../loader/assetloader.js';
import { ContentReplacer } from '../loader/contentreplacer.js';
import PageData from '../data/pagedata.js';
import { Status } from '../../index';

/**
 * @class Rendering the blocks of a page
 * @name ScreenRenderer
 * @property {Container} container
 * @property {Session} session
 * @property {ref} $wrapper
 * @property {PageData} currentPageData
 * @property {PageData} previousPageData
 * @property {PageData} suspendedPageData
 * @property {string} visibleBlockId
 * @property {number} suspendedScrollTop
 *
 * @augments Backbone.Model
 *
 * @see Backbone.Model
 */
const ScreenRenderer = Backbone.Model.extend(
    /** @lends ScreenRenderer.prototype */ {
        constructor() {
            this.container;
            this.session;
            this.$wrapper;

            this.currentPageData;
            this.previousPageData;
            this.suspendedPageData;

            this.visibleBlockId;
            this.suspendedScrollTop;
            Backbone.Model.apply(this, arguments);
        },
        /**
         * Initialize the screen renderer
         *
         * @param {Container} container
         * @param {Session} session
         * @param {Ref} $wrapper
         */
        initialize(container, session, $wrapper) {
            this.container = container;
            this.session = session;
            this.$wrapper = $wrapper;

            this.listenTo(
                this.session,
                'onChangeSlide',
                this.handleChangeSlide
            );

            this.listenTo(
                this.session,
                'onChangeGlobalSlide',
                this.handleChangeGlobalSlide
            );

            this.listenTo(this.session, 'onBlockCompleted', function (e) {
                this.blockCompleted(e);
            });

            this.listenTo(this.container, 'onScroll', function (e) {
                this.handleScroll(e);
            });
        },

        /**
         * Handle the scroll even of the page
         *
         * @param {object} e
         */
        handleScroll(e) {
            if (!this.currentPageData) return;

            const $page = this.currentPageData.pageDom();
            const min = $page.offset().top - $page.height();
            const max = $page.offset().top;
            const blocks = this.currentPageData.getTotalBlocks();

            for (let i = 0; i < blocks; i++) {
                const $item = this.currentPageData.getBlock(i).$blockDom;

                if (!this.currentPageData.getBlock(i).isRendered()) continue;

                const fractions = { visible: 1 }; // $item.fracs();
                const block = this.currentPageData.getBlock(i);

                if (fractions.visible > block.visiblePercentage()) {
                    block.isVisible(true);
                    block.setStatus(Status.STARTED, false);

                    this.visibleBlockId = block.order();
                } else {
                    block.isVisible(false);
                }
            }
        },

        /**
         * Return to the suspended page from global pages like resources, toolkit, help etc
         */
        returnToCourse() {
            if (!this.isSuspended()) return;

            this.session.goChangeSlide(this.suspendedPageData.pageID());
        },

        /**
         * Handle the change slide event for normal pages
         *
         * @param {object} e
         */
        handleChangeSlide(e) {
            if (e.pageData === e.previousPageData) {
                Logger.warn(
                    'ScreenRenderer.handleChangeSlide',
                    'already on page:',
                    e.pageData.pageID(),
                    'reloading...'
                );
            }

            this.removePage(e.previousPageData);

            if (!this.isSuspended()) {
                this.addPage(e.pageData);
                this.container.$scroller.scrollTop(0);
            } else if (
                this.suspendedPageData.pageID() === e.pageData.pageID()
            ) {
                this.restorePage();
            } else {
                this.removePage(this.suspendedPageData);
                this.addPage(e.pageData);
                this.suspendedPageData = null;
            }
        },

        /**
         * Handle the change slide event for global pages
         *
         * @param {object} e
         */
        handleChangeGlobalSlide(e) {
            if (e.pageData === e.previousPageData) {
                Logger.warn(
                    'ScreenRenderer.handleChangeGlobalSlide',
                    'already on page:',
                    e.pageData.pageID(),
                    'reloading...'
                );
            }

            if (this.isSuspended()) {
                this.removePage(e.previousPageData);
            } else {
                this.suspendPage(e.previousPageData);
            }

            this.addPage(e.pageData);
        },

        /**
         * Check if there is any suspended page
         */
        isSuspended() {
            return this.suspendedPageData != null;
        },

        /**
         * Suspend a page
         *
         * @param {PageData} pageData
         */
        suspendPage(pageData) {
            if (!pageData) return;

            this.suspendedPageData = pageData;
            this.suspendedScrollTop = this.container.$scroller.scrollTop();
            this.suspendedPageData.pageDom().hide();

            this.trigger('onPageSuspended', {
                target: this,
                pageData,
                scrollTop: this.suspendedScrollTop,
            });
        },

        /**
         * Restore a suspended page
         */
        restorePage() {
            if (!this.isSuspended()) return;

            this.previousPageData = this.currentPageData;
            this.currentPageData = this.suspendedPageData;
            this.suspendedPageData = null;
            this.currentPageData.pageDom().show();
            this.container.$scroller.scrollTop(this.suspendedScrollTop);

            this.trigger('onPageRestored', {
                target: this,
                pageData: this.currentPageData,
            });
        },

        /**
         * Add a page to render based on passed parameter
         * @param {PageData} pageData
         */
        addPage(pageData) {
            if (!pageData) return;

            this.loadPage(pageData);

            this.trigger('onPageAdded', {
                target: this,
                pageData,
            });
        },

        /**
         * Remove a page based on passed parameter
         * @param {PageData} pageData
         */
        removePage(pageData) {
            if (!pageData) return;

            this.unloadPage(pageData);
            pageData.pageDom().remove();

            this.trigger('onPageRemoved', {
                target: this,
                pageData,
            });
        },

        /**
         * Load a page based on passed parameter
         * @param {PageData} pageData
         */
        loadPage(pageData) {
            this.previousPageData = this.currentPageData;
            this.currentPageData = pageData;
            const courseTitle = this.container.courseData.getCourseTitle();
            this.setPageTitle(courseTitle, pageData.pageID());
            const $pageDom = $(
                `<div id='page-${pageData.pageID()}' class='page pt-page'></div>`
            );
            this.$wrapper.append($pageDom);
            this.currentPageData.pageDom($pageDom);

            const deferreds = [];
            const totalBlocks = pageData.getTotalBlocks();

            // [MS]: setting blocksRendered to zero before loading the blocks
            this.blocksRendered = 0;
            this.container.removePreloader();
            this.container.attachPreloader();

            for (let i = 0; i < totalBlocks; i++) {
                const block = pageData.getBlock(i);
                block.reset();

                if (block.isRendered() === false) {
                    const deferred = new $.Deferred();
                    deferreds.push(deferred);

                    const classes = `template template-${kebabCase(
                        block.template()
                    )}-block block-holder`;

                    // [MS]: creating the empty block holder beforehand
                    const $blockHolder = $(
                        `<div class='${classes}' id='block-holder-${block.id}'></div>`
                    );

                    $pageDom.append($blockHolder);

                    if (block.isVisibleFromStart() === true || i == 0) {
                        this.loadBlock(block, pageData, false, deferred);
                    }
                }
            }
        },

        /**
         * Fetching the course title from course file and setting the browser title dynamically for each pages. pageID will amend with the course title if the course is unlocked.
         *
         * @param {PageID} pageData.pageID()
         */
        setPageTitle(courseTitle, pageID) {
            if (this.container.isLocked) {
                document.title = courseTitle;
            } else {
                document.title = `${pageID}_${courseTitle}`;
            }
        },

        /**
         * Unload a page based on passed parameter
         *
         * @param {PageData} pageData
         */
        unloadPage(pageData) {
            if (!pageData.blocks) return;

            const self = this;
            pageData.blocks.each(function (blockData) {
                if (blockData.isRendered() && blockData.view) {
                    blockData.view.destroy();
                    blockData.view = null;
                }

                blockData.isDestroyed(true);
                blockData.isVisible(false);

                self.stopListening(blockData);
            }, this);
        },

        /**
         * Load a block of a page
         *
         * @param {BlockData} block
         * @param {PageData} page
         * @param {boolean} isFocus
         * @param {boolean} deferred
         */
        loadBlock(block, page, isFocus, deferred) {
            const blockID = block.blockID();
            const blockXmlPath = block.blockXML();

            this.listenTo(block, 'onStatusUpdate', this.handleStatusUpdate);

            const parameter = new Object();
            parameter.isFocus = isFocus;
            parameter.block = block;
            parameter.page = page;

            const self = this;

            if (blockXmlPath.includes('.json')) {
                $.getJSON(blockXmlPath, function (data) {
                    if (deferred) deferred.resolve();
                    data = JSON.parse(
                        ContentReplacer.execute(JSON.stringify(data))
                    );
                    self.initiateBlock(parameter, data);
                });
            } else {
                const blockLoader = new AssetLoader({
                    name: blockID,
                });
                blockLoader.addXML(blockXmlPath, `blockXml_${blockID}`);

                this.listenTo(
                    this.container.session,
                    'onBeforeChangeSlide',
                    function (e) {
                        self.stopListening(blockLoader, 'onAssetLoaded');
                    }
                );

                this.listenToOnce(blockLoader, 'onAssetLoaded', function (e) {
                    if (deferred) deferred.resolve();
                    if (blockXmlPath.length > 0) {
                        this.initiateBlock(parameter, e.resource.getXML());
                    }
                });

                blockLoader.start();
            }
        },

        /**
         * Checks if block is completed and do the needful
         *
         * @param {object} e
         */
        blockCompleted(e) {
            this.currentPageData.checkCompletion();

            if (
                e.nextBlock &&
                !e.nextBlock.isRendered() &&
                !e.nextBlock.isVisibleFromStart()
            ) {
                this.loadBlock(e.nextBlock, this.currentPageData, false);
            } else if (
                e.thisBlock.getStatus() < Status.COMPLETED ||
                e.thisBlock.getStatus() == Status.FAILED
            ) {
                // Not a completed screen
            } else if (e.thisBlock.order() == e.totalBlocks) {
                this.currentPageData.checkCompletion();
            } else {
                this.currentPageData.checkCompletion();
            }
        },

        /**
         * Handles the status update of a block
         *
         * @param {object} e
         */
        handleStatusUpdate(e) {
            const event = new Object();
            (event.target = this), (event.thisBlock = e.target);
            event.previousBlock = this.currentPageData.getBlock(
                e.target.get('id') - 1
            );
            event.nextBlock = this.currentPageData.getBlock(
                e.target.get('id') + 1
            );
            event.totalBlocks = this.currentPageData.getTotalBlocks() - 1;

            if (e.status >= Status.COMPLETED) {
                this.session.blockCompleted(event);
            }
        },

        /**
         * Initialize a block
         *
         * @param {object} params
         * @param {object} xml
         */
        initiateBlock(params, xml) {
            const self = this;
            params.block.view = this.createTemplate(
                params.block,
                params.page,
                xml
            );

            const $page = this.currentPageData.pageDom();
            params.block.$blockDom = params.block.view.$el;

            // [MS]: appending the block to corresponding holder to avoid randomly getting appended to the page.
            $page
                .find(`#block-holder-${params.block.id}`)
                .append(params.block.view.el);

            const event = {
                target: this,
                blockData: params.block,
                view: params.block.view,
            };

            // callling the setup first to call the data ready so that get resources can loop through data and send the assets required to load
            event.view.setup();

            const resources = event.view.getResources();

            if (resources.length > 0) {
                this.loadAssets(event, resources);
            } else {
                this.handleBlockInitializeCompleted(event);
            }
        },

        /**
         * Create a template of a block
         *
         * @param {BlockData} blockData
         * @param {PageData} pageData
         * @param {object} xml
         */
        createTemplate(blockData, pageData, xml) {
            let template = window[blockData.template()];
            if (!template) {
                template = window.Placeholder;
            }
            const instance = new template({
                id: `block-${blockData.blockID()}`,
                app: this.container,
                data: blockData,
                page: pageData,
                xml,
            });

            return instance;
        },

        /**
         * Preload the resources of a block
         *
         * @param {object} event
         * @param {Array} resources
         */
        loadAssets(event, resources) {
            event.blockData.preloader = new AssetLoader({
                name: 'BlockResoursesLoader',
                useCache: this.container.isCached,
            });
            this.listenTo(
                event.blockData.preloader,
                'onAllAssetsLoaded',
                function () {
                    this.handleBlockInitializeCompleted(event);
                },
                this
            );
            _.each(
                resources,
                function (item) {
                    if (item.includes('.mp3')) {
                        event.blockData.preloader.addSound(item);
                    } else if (
                        item.includes('.jpg') ||
                        item.includes('.png') ||
                        item.includes('.gif') ||
                        item.includes('.svg')
                    ) {
                        event.blockData.preloader.addImage(item);
                    }
                },
                this
            );
            event.blockData.preloader.start();
        },

        /**
         * Handle the event when block is initialized
         *
         * @param {object} event
         */
        handleBlockInitializeCompleted(event) {
            if (this.blockResoursesLoader !== undefined) {
                delete this.blockResoursesLoader;
            }

            if (event.blockData.isDestroyed()) {
                Logger.trace(
                    'ScreenRenderer.handleBlockInitializeCompleted',
                    'Not rendering, block already destroyed:',
                    `${event.blockData.pageID()} ${event.blockData.blockID()}`
                );
                return;
            }

            this.trigger('onBlockInitialized', event);

            event.view.setupRenderer();
            event.blockData.isRendered(true);
            this.trigger('onBlockRendered', event);

            // [MS]: incrementing on each block rendered
            this.blocksRendered++;

            const totalBlocks = this.currentPageData.getTotalBlocks();
            if (totalBlocks == this.blocksRendered) {
                TweenMax.staggerTo(
                    this.currentPageData.pageDom().find('.block-holder'),
                    0,
                    {
                        opacity: 1,
                        // ease: Power4.easeOut
                    },
                    0
                );
                // [MS]: this is to make block visible if viewport has space available below.
                this.handleScroll();
            }
            this.container.removePreloader();
        },

        /**
         * Get the visible block based on value set in visibleBlockId
         */
        getVisibleBlock() {
            return this.currentPageData.getBlock(this.visibleBlockId);
        },

        /**
         * Get the current pageData
         */
        getCurrentPageData() {
            return this.suspendedPageData
                ? this.suspendedPageData
                : this.currentPageData;
        },

        /**
         * Destroy the current page and suspended page
         */
        destroy() {
            this.destroyPage(this.currentPageData);
            this.destroyPage(this.suspendedPageData);
        },

        /**
         * Destroy all the blocks inside a page
         *
         * @param {PageData} pageData
         */
        destroyPage(pageData) {
            if (!pageData) return;

            pageData.blocks.each(function (block) {
                if (block.view) block.view.destroy();
            });
        },
    }
);

export default ScreenRenderer;
