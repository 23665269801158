import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import ReactHtmlParser from 'react-html-parser';
import styles from './PagesPanel.module.css';
import { useBlock } from '~templates/components/blocks';

function PagesPanel() {
    const { container } = useBlock();

    const [currentPageId, setCurrentPageId] = useState();

    const handleChangeSlide = () => {
        const pageID = container.session?.currentPageData?.attributes?.pageID;
        setCurrentPageId(pageID);
    };

    useEffect(() => {
        container.session.on('onChangeSlide onChangeGlobalSlide', handleChangeSlide);
        handleChangeSlide();

        return () => {
            container.session.off('onChangeSlide onChangeGlobalSlide', handleChangeSlide);
        };
    }, []);

    const modules = container.courseData.modules.models
        .map(module => ({
            ...module.attributes,
            pages: module.pages.map(page => page.attributes),
        }));

    const modulelessPages = container.courseData.pages.models
        .filter(page => !page.attributes.moduleID)
        .map(page => page.attributes);

    return (
        <div className={styles.wrapper}>
            <div>
                <div className="mt-1 space-y-1">
                    {modulelessPages.map(page => (
                        <a key={page.id}
                            className={styles.anchor}
                            href={`#page/${page.pageID}`}
                        >
                            <span className={styles.anchorText}>{page.pageTitle || page.pageID}</span>
                        </a>
                    ))}
                </div>
            </div>
            {modules.map(module => (
                <div key={module.id}>
                    <h3 className={styles.header}>
                        {ReactHtmlParser(module.moduleTitle)}
                    </h3>
                    <div className={styles.list}>
                        {module.pages.map(page => (
                            <a
                                key={page.id}
                                className={classNames(styles.anchor, {
                                    [styles.active]: currentPageId === page.pageID,
                                })}
                                href={`#page/${page.pageID}`}
                            >
                                <span className={styles.anchorText}>{page.pageTitle}</span>
                                {!container.isLocked && <span className={styles.pageId}>{page.pageID}</span>}
                            </a>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default PagesPanel;
