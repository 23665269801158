import React, { useEffect, useMemo, useRef, useState } from 'react';
import { MdSkipNext } from 'react-icons/md';
import { useTimer } from '../utils';
import InterludeAction from '../interludeAction/InterludeAction';
import slidePropType from './slideProps';

function InterludeImage(props) {
    const {
        active,
        slide,
        onCompleted,
        onTimeUpdate,
        onLoadingChange,
        onError,
    } = props;
    const element = useRef();
    const [duration] = useState(slide.duration);
    const [displaySkipButton, setDisplaySkipButton] = useState(false);
    const [loaded, setLoaded] = useState(false);

    const {
        timer: currentTime,
        handleStart,
        handlePause,
        handleReset,
    } = useTimer(0);

    const percentage = useMemo(
        () => (currentTime / (duration / 1000)) * 100,
        [currentTime, duration]
    );

    useEffect(() => {
        if (!loaded) {
            return;
        }

        handleReset();

        if (!active) {
            handlePause();
        } else {
            handleStart();
        }
    }, [active, loaded]);

    useEffect(() => {
        onTimeUpdate(percentage);

        if (percentage >= 100) {
            onCompleted();
        }
    }, [percentage]);

    return (
        <>
            <div className="interlude-block-action-wrapper">
                {displaySkipButton && (
                    <InterludeAction
                        msg="Unable to load image"
                        icon={MdSkipNext}
                        onClick={() => onCompleted()}
                    />
                )}
            </div>
            <img
                ref={element}
                src={slide.content}
                onLoad={() => {
                    setLoaded(true);
                    onLoadingChange(false);
                }}
                onError={() => {
                    onError();
                    setDisplaySkipButton(true);
                }}
                alt=""
            />
        </>
    );
}

InterludeImage.propTypes = slidePropType;

export default InterludeImage;
