import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import ListGroup from 'react-bootstrap/ListGroup';
import './Listbox.scss';
import classNames from 'classnames';

class Listbox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: this.props.items,
            selectedItems: [],
            activeDescendant: undefined,
        };
        this.listId = _.uniqueId('list_');
    }

    render() {
        const { instruction, isAnswered, isSingleSelect } = this.props;
        const { items, activeDescendant } = this.state;

        return (
            <div className="listbox">
                {instruction && (
                    <p className="instruction" id={this.listId} role="label">
                        {ReactHtmlParser(instruction)}
                    </p>
                )}

                <ListGroup
                    as="ul"
                    className={`list ${isAnswered ? 'disabled' : ''}`}
                    role="listbox"
                    tabIndex={isAnswered ? -1 : 0}
                    onKeyDown={this.handleOnKeyDown.bind(this)}
                    onFocus={this.handleOnFocus.bind(this)}
                    aria-multiselectable={!isSingleSelect}
                    aria-activedescendant={`${this.listId}_${activeDescendant}`}
                    aria-labelledby={this.listId}
                >
                    {items.map((item, i) => {
                        const isDescendant = i === activeDescendant;
                        const isActive = item.isSelected && !isAnswered;
                        const isCorrect =
                            isAnswered && item.isSelected && item.isCorrect;
                        const isIncorrect =
                            isAnswered && item.isSelected && !item.isCorrect;
                        const showHighlight =
                            !container.isLocked && item.isCorrect;
                        const showFeedback =
                            (item.feedback.title.length > 0 ||
                                item.feedback.content.length > 0) &&
                            this.props.isAnswered &&
                            item.isSelected;
                        return (
                            <li
                                className="list-unstyled my-1 px-0 py-0 border-0"
                                key={i.toString()}
                            >
                                <div
                                    role="option"
                                    id={`${this.listId}_${i}`}
                                    aria-selected={item.isSelected}
                                    className={classNames({
                                        'option px-3 py-2': true,
                                        activedescendant: isDescendant,
                                        disabled: isAnswered,
                                        active: isActive,
                                        correct: isCorrect,
                                        incorrect: isIncorrect,
                                        'correct-highlight': showHighlight,
                                    })}
                                    onClick={this.handleItemClick.bind(this, i)}
                                >
                                    <div className="title">
                                        {ReactHtmlParser(item.title)}
                                    </div>
                                </div>
                            </li>
                        );
                    })}
                </ListGroup>
            </div>
        );
    }

    /**
     * If there was previous item selected, the focus will be on that item. Otherwise, the first item will be selected.
     *
     * @param {object} e
     */
    handleOnFocus(e) {
        const $list = $(e.currentTarget);
        if (!$list.find('[aria-selected=true]').length) {
            this.setState({ activeDescendant: 0 });
        } else {
            $list.find('[aria-selected=true]').focus();
        }
    }

    /**
     * Updates the active descendant on arrow keys and triggers selection Enter/Space.
     *
     * @param {object} e
     */
    handleOnKeyDown(e) {
        switch (e.key) {
            case 'Up': // IE/Edge specific value
            case 'ArrowUp':
                this.setActiveDescendant(this.state.activeDescendant - 1);
                return false;
            case 'Down': // IE/Edge specific value
            case 'ArrowDown':
                this.setActiveDescendant(this.state.activeDescendant + 1);
                return false;
            case 'Left': // IE/Edge specific value
            case 'ArrowLeft':
                this.setActiveDescendant(this.state.activeDescendant - 1);
                return false;
            case 'Right': // IE/Edge specific value
            case 'ArrowRight':
                this.setActiveDescendant(this.state.activeDescendant + 1);
                return false;
            case ' ':
            case 'Enter':
                if (this.props.isSingleSelect) {
                    this.selectOption(this.state.activeDescendant);
                } else {
                    this.toggleOption(this.state.activeDescendant);
                }
                return false;
            default:
                return true; // Allow event to propagate
        }
    }

    /**
     * Updates activeDescendant state with the index of selected item.
     *
     * @param {number} index activedescendant index
     */
    setActiveDescendant(index) {
        if (index < 0 || index >= this.state.items.length) return false;
        this.setState({ activeDescendant: index });
    }

    /**
     * Sets state of selectedItems, function passed from template will be called.
     */
    updateSelectedItems() {
        const selectedItems = [];
        this.state.items.forEach((item, i) => {
            if (item.isSelected) selectedItems.push(i);
        });
        this.setState({ selectedItems });
        this.props.onClick(selectedItems.length > 0);
    }

    /**
     * Option's selected state is set to true but will be false if it was already selected.
     *
     * @param {number} index clicked option's index
     */
    toggleOption(index) {
        this.setState((prevState) => {
            const { items } = prevState;
            items[index].isSelected = !items[index].isSelected;
            return items;
        }, this.updateSelectedItems);
    }

    /**
     * If an item was already selected it will be deselcted and clicked option will get the selected state.
     *
     * @param {number} index clicked option's index
     */
    selectOption(index) {
        this.setState((prevState) => {
            const { items } = prevState;
            items.forEach((item, i) => {
                i === index
                    ? (item.isSelected = true)
                    : (item.isSelected = false);
            }, this);
            return items;
        }, this.updateSelectedItems);
    }

    /**
     * Sets activeDescendant of listbox, next action is based on if the question multiselectable
     *
     * @param {number} index clicked option's index
     */
    handleItemClick(index) {
        this.setState({ activeDescendant: index });

        if (this.props.isSingleSelect) {
            this.selectOption(index);
        } else {
            this.toggleOption(index);
        }
    }
}

export default Listbox;
