import { Button } from 'react-bootstrap';
import React from 'react';
import './HotspotButton.scss';
import classNames from 'classnames';
import { VIEWED_CLASS, hotspotButtonProps } from '../HotspotContants';

function HotspotButton(props) {
    const { viewed, area } = props;
    const classes = classNames([
        'hotspot-marker-button',
        { [VIEWED_CLASS]: viewed },
    ]);

    return (
        <div className={classes}>
            <Button
                variant={viewed ? 'warning' : 'primary'}
                aria-label={viewed ? area.content.value : ' '}
            >
                {area.button.text}
            </Button>
        </div>
    );
}

HotspotButton.propTypes = hotspotButtonProps;

export default {
    iconSize: [36, 36],
    component: HotspotButton,
};
