import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { Alert, Card, CardDeck, Col, ListGroup, Row } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import FeedbackModal from './FeedbackModal.jsx';
import MessageAvatar from './MessageAvatar.jsx';
import './styles/ConversationTypeOptions.scss';
import { ConversationContext } from './context.js';
import { t } from '~utils/i18n.js';
import { useAnimatedElement } from '~utils/helpers';

/**
 * @param props
 */
function ConversationTypeOptions(props) {
    const { message } = props;
    const { sender } = message;
    const { element } = useAnimatedElement();
    const context = useContext(ConversationContext);
    const { blockRef } = context;

    const [showModal, setShowModal] = useState(false);
    const [selectedOption, setSelectedOption] = useState(false);
    const [showOptionChoices, setShowOptionChoices] = useState(true);

    const getOptions = () => {
        if (blockRef.app.isLocked) {
            return message.options;
        }

        const scoring = blockRef.app.getComponent('Scoring');

        if (!scoring) {
            return message.options;
        }

        const pageId = blockRef.app.router.getCurrentPageData().pageID();
        const pageScores = scoring.data.find((d) => d.id === pageId);
        return message.options.map((option) => {
            const { isMax, labels } = pageScores.options.find(
                (o) => o.id === option.id
            );
            const values = labels
                .map(({ key, value }) => `${key}: ${value}`)
                .join('; ');
            const prefix = `${isMax ? '* MAX; ' : ''}${values}`;
            return {
                ...option,
                prompt: `<strong>[${prefix}]</strong> ${option.prompt}`,
            };
        });
    };

    const handleSelect = (option) => {
        const { feedback } = option;

        const hasFeedback =
            feedback &&
            !isEmpty(feedback) &&
            Object.values(feedback).some((value) => !!value.trim());

        if (hasFeedback) {
            setSelectedOption(option);
            setShowModal(true);
        } else {
            context.showMessage(option.goto);
            setShowOptionChoices(false);
        }
    };

    const options = getOptions().map((option, i) => {
        return (
            <Card
                variant="secondary-rounded"
                key={i.toString()}
                slimmer="true"
                onClick={() => handleSelect(option)}
            >
                <Card.Body>{ReactHtmlParser(option.prompt)}</Card.Body>
            </Card>
        );
    });

    const handleModalClose = () => {
        setShowModal(false);
        context.showMessage(selectedOption.goto);
        setShowOptionChoices(false);
    };

    const handleModalShow = () => {
        blockRef.trigger('onSubmitAnswer', { option: selectedOption });
    };

    const onPageSuspended = () => {
        setShowModal(false);
    };

    blockRef.app.screenManager.on('onPageSuspended', onPageSuspended);

    return (
        <>
            <FeedbackModal
                show={showModal}
                selectedOption={selectedOption}
                onHide={handleModalClose}
                onShow={handleModalShow}
            />
            {showOptionChoices && (
                <div ref={element} className="conversation-options-wrapper">
                    <div className="conversation-options-header">
                        <span>{t('conversation_instruction_title')}</span>
                        <span>{t('conversation_instruction_subtitle')}</span>
                    </div>
                    <CardDeck>{options}</CardDeck>
                </div>
            )}
        </>
    );
}

ConversationTypeOptions.propTypes = {
    message: PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.oneOf(['options']),
        sender: PropTypes.objectOf(PropTypes.any).isRequired,
        options: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                prompt: PropTypes.string,
                audio: PropTypes.string,
                goto: PropTypes.string,
                feedback: PropTypes.shape({
                    title: PropTypes.string,
                    content: PropTypes.string,
                    button: PropTypes.string,
                }),
            })
        ),
    }).isRequired,
};

export default ConversationTypeOptions;
