import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';

/**
 * @param props
 */
function FilterListItems(props) {
    const { labels, filters, onSubmitClick, onCancelClick } = props;

    const global = useRef();
    const status = useRef();
    const priority = useRef();

    const handleSubmitClick = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const item = Object.fromEntries(formData.entries());
        item.Status = [].slice
            .call(status.current.selectedOptions)
            .map((option) => option.value);
        item.Priority = [].slice
            .call(priority.current.selectedOptions)
            .map((option) => option.value);
        onSubmitClick(item);
    };

    const handleClearClick = () => {
        global.current.checked = false;
        status.current.selectedIndex = -1;
        priority.current.selectedIndex = -1;
    };

    const handleCancelClick = (event) => {
        onCancelClick(event);
    };

    return (
        <Card className="mb-2 w-100">
            <Card.Body>
                <Card.Title>Filters</Card.Title>
                <Form onSubmit={handleSubmitClick}>
                    <Form.Group as={Row} controlId="filterForm.showglobal">
                        <Col>
                            <Form.Check
                                ref={global}
                                name="showGlobal"
                                type="checkbox"
                                className="form-control-sm"
                                label="Show all global"
                                defaultChecked={filters.showGlobal === 'on'}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="filterForm.status">
                        <Col>
                            <Form.Label>Status</Form.Label>
                            <Form.Control
                                ref={status}
                                multiple
                                name="Status"
                                as="select"
                                size="sm"
                                defaultValue={filters.Status}
                            >
                                {labels.status.map((item, i) => (
                                    <option key={i.toString()}>
                                        {item.label}
                                    </option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="filterForm.priority">
                        <Col>
                            <Form.Label>Priority</Form.Label>
                            <Form.Control
                                ref={priority}
                                multiple
                                name="Priority"
                                as="select"
                                size="sm"
                                defaultValue={filters.Priority}
                            >
                                {labels.priority.map((item, i) => (
                                    <option key={i.toString()}>{item}</option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>
                    <Button
                        className="submit-button"
                        variant="primary"
                        type="submit"
                    >
                        Filter
                    </Button>{' '}
                    <Button
                        className="clear-button"
                        variant="secondary"
                        onClick={handleClearClick}
                    >
                        Clear
                    </Button>{' '}
                    <Button
                        className="back-button"
                        variant="light"
                        onClick={handleCancelClick}
                    >
                        Close
                    </Button>
                </Form>
            </Card.Body>
        </Card>
    );
}

FilterListItems.propTypes = {
    labels: PropTypes.objectOf(PropTypes.any).isRequired,
    filters: PropTypes.objectOf(PropTypes.any).isRequired,
    onSubmitClick: PropTypes.func.isRequired,
    onCancelClick: PropTypes.func.isRequired,
};

export default FilterListItems;
