import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Button from '../../../components/Button';
import { EVENT_LEARNER_KICKED } from '../RoomSync.contants';
import { t } from '~templates/utils/i18n';

function LearnerKickModal(props) {
    const { roomSync } = props;
    const [show, setShow] = useState(false);

    useEffect(() => {
        const handleShow = () => setShow(true);

        roomSync.on(EVENT_LEARNER_KICKED, handleShow);

        return () => {
            roomSync.off(EVENT_LEARNER_KICKED, handleShow);
        };
    }, []);

    return (

        <Modal
            show={show}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Body>{t('you_have_been_kicked')}</Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => window.location.reload()}>{t('okay')}</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default LearnerKickModal;
