import React from 'react';
import generalStyles from '../General.module.css';

function ColorBackground({ data }) {
    return (
        <div
            className={generalStyles.fill}
            style={{ backgroundColor: data.value }}
        />
    );
}

export default ColorBackground;
