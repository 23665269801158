import Backbone from 'backbone';

class LmsCollector {
    constructor(log, container) {
        this.log = log;
        this.type = 'LmsCollector';

        const eventsCourseProperties = [
            'onSetSuspendDataUpdated',
            'onSetSuspendDataAdded',
            'onDeleteSuspendData',
            'onClearCmiData',
            'onClearSuspendData',
        ];

        eventsCourseProperties.forEach((event) => {
            Backbone.listenTo(
                container.tracking.courseProperties,
                event,
                (e) => {
                    log.add({
                        type: this.type,
                        event: `CourseProperties.${event}`,
                        data: e,
                        time: Date.now(),
                    });
                }
            );
        });

        const trackingEvents = [
            'onSave',
            'onInitComplete',
            'onTrackingStart',
            'onBeforeSave',
            'onSaveSuccess',
            'onSaveFail',
            'onSaveFail',
            'onTrackingFinish',
            'onClear',
        ];

        trackingEvents.forEach((event) => {
            Backbone.listenTo(container.tracking, event, (e) => {
                log.add({
                    type: this.type,
                    event: `Tracking.${event}`,
                    data: e,
                    time: Date.now(),
                });
            });
        });
    }

    print() {
        return this.log.where({ type: this.type });
    }
}

export default LmsCollector;
