import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { Accordion, Button, Card, Col, Form, Row } from 'react-bootstrap';

function AddListItem(props) {
    const { labels, location, users, data, onSubmitClick, onCancelClick } =
        props;

    const { clients, type } = data;

    const isInternal = type === 'internal';

    const reportedOn = new Date().toISOString().substring(0, 10);

    const [validated, setValidated] = useState(false);

    const assignedTo = useRef();
    const environment = useRef();

    const handleSubmitClick = (event) => {
        event.preventDefault();
        if (event.currentTarget.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            const formData = new FormData(event.target);
            const item = Object.fromEntries(formData.entries());
            // @ts-expect-error
            item.Location = [item.Location];

            if (isInternal) {
                item.AssignedtoId = [].slice
                    .call(assignedTo.current.selectedOptions)
                    .map((option) => option.value);

                item.AssignedtoStringId = [].slice
                    .call(assignedTo.current.selectedOptions)
                    .map((option) => `${option.value}`);
            }

            item.Affected_x0020_environment = [].slice
                .call(environment.current.selectedOptions)
                .map((option) => option.value);

            if (item.IssueloggedbyId)
                item.IssueloggedbyStringId = String(item.IssueloggedbyId);

            onSubmitClick(item);
        }
    };

    const handleCancelClick = (e) => {
        onCancelClick(e);
    };

    return (
        <Accordion defaultActiveKey="0">
            <Card className="w-100">
                <Accordion.Toggle as={Card.Header} className="p-2" eventKey="0">
                    Add
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                    <Card.Body className="p-2">
                        <Form
                            noValidate
                            validated={validated}
                            onSubmit={handleSubmitClick}
                        >
                            <Form.Group controlId="addForm.title">
                                <Form.Label>Summary *</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    name="Title"
                                    size="sm"
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide title/summary of the issue.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="addForm.description">
                                <Form.Label>Description *</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    name="Description"
                                    as="textarea"
                                    rows={3}
                                    size="sm"
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide detailed description of the
                                    issue.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Row} controlId="addForm.location">
                                <Form.Label column sm={4}>
                                    Location *
                                </Form.Label>
                                <Col sm={8}>
                                    <Form.Control
                                        required
                                        name="Location"
                                        as="select"
                                        size="sm"
                                        defaultValue={location}
                                    >
                                        <option value={location}>
                                            {location}
                                        </option>
                                        <option value="global">global</option>
                                    </Form.Control>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="addForm.tyle">
                                <Form.Label column sm={4}>
                                    Type *
                                </Form.Label>
                                <Col sm={8}>
                                    <Form.Control
                                        required
                                        name="IssueType"
                                        as="select"
                                        size="sm"
                                    >
                                        <option value="">Select</option>
                                        {labels.issueType.map((item, i) => (
                                            <option key={i.toString()}>
                                                {item}
                                            </option>
                                        ))}
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Please specify issue type.
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="addForm.priority">
                                <Form.Label column sm={4}>
                                    Priority *
                                </Form.Label>
                                <Col sm={8}>
                                    <Form.Control
                                        required
                                        name="Priority"
                                        as="select"
                                        size="sm"
                                    >
                                        <option value="">Select</option>
                                        {labels.priority.map((item, i) => (
                                            <option key={i.toString()}>
                                                {item}
                                            </option>
                                        ))}
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Please specify priority.
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="addForm.status">
                                <Form.Label column sm={4}>
                                    Status *
                                </Form.Label>
                                <Col sm={8}>
                                    <Form.Control
                                        required
                                        name="Status"
                                        as="select"
                                        size="sm"
                                    >
                                        <option value="">Select</option>
                                        {labels.status.map((item, i) => (
                                            <option key={i.toString()}>
                                                {item.label}
                                            </option>
                                        ))}
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Please specify status.
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="addForm.loggedby">
                                <Form.Label column sm={4}>
                                    Raised By *
                                </Form.Label>
                                <Col sm={8}>
                                    {isInternal ? (
                                        <Form.Control
                                            required
                                            name="IssueloggedbyId"
                                            as="select"
                                            size="sm"
                                        >
                                            <option value="">Select</option>
                                            {users.map((item) => (
                                                <option
                                                    key={item.Id}
                                                    value={item.Id}
                                                >
                                                    {item.Title}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    ) : (
                                        <Form.Control
                                            required
                                            name="Issueloggedbyclient"
                                            as="select"
                                            size="sm"
                                        >
                                            <option value="">Select</option>
                                            {clients.map((item, i) => (
                                                <option
                                                    key={i.toString()}
                                                    value={item}
                                                >
                                                    {item}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    )}
                                    <Form.Control.Feedback type="invalid">
                                        Please specify logged by.
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group
                                as={Row}
                                controlId="addForm.dateReported"
                            >
                                <Form.Label column sm={4}>
                                    Raised On
                                </Form.Label>
                                <Col sm={8}>
                                    <Form.Control
                                        type="date"
                                        name="DateReported"
                                        className="pr-0"
                                        size="sm"
                                        max={new Date()
                                            .toISOString()
                                            .substring(0, 10)}
                                        defaultValue={reportedOn}
                                    />
                                </Col>
                            </Form.Group>
                            {isInternal && (
                                <Form.Group
                                    as={Row}
                                    controlId="updateForm.assignedTo"
                                >
                                    <Form.Label column sm={4}>
                                        Assigned To
                                    </Form.Label>
                                    <Col sm={8}>
                                        <Form.Control
                                            ref={assignedTo}
                                            multiple
                                            name="AssignedtoId"
                                            as="select"
                                            size="sm"
                                        >
                                            {users.map((item) => (
                                                <option
                                                    key={item.Id}
                                                    value={item.Id}
                                                >
                                                    {item.Title}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Col>
                                </Form.Group>
                            )}
                            <Form.Group
                                as={Row}
                                controlId="updateForm.environment"
                            >
                                <Col sm={12}>
                                    <Form.Label>
                                        Environment * (device/os/browser)
                                    </Form.Label>
                                    <Form.Control
                                        ref={environment}
                                        required
                                        multiple
                                        name="Affected_x0020_environment"
                                        className="environment-select"
                                        as="select"
                                        size="sm"
                                    >
                                        {labels.environment.map((item, i) => (
                                            <option
                                                key={i.toString()}
                                                value={item}
                                            >
                                                {item}
                                            </option>
                                        ))}
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Please specify the environment.
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Button
                                className="submit-button"
                                variant="primary"
                                type="submit"
                            >
                                Add
                            </Button>{' '}
                            <Button
                                className="back-button"
                                variant="secondary"
                                onClick={handleCancelClick}
                            >
                                Cancel
                            </Button>
                        </Form>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    );
}

AddListItem.propTypes = {
    labels: PropTypes.objectOf(PropTypes.any).isRequired,
    location: PropTypes.string.isRequired,
    users: PropTypes.arrayOf(PropTypes.any).isRequired,
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    onSubmitClick: PropTypes.func.isRequired,
    onCancelClick: PropTypes.func.isRequired,
};

export default AddListItem;
