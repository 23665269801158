import { Button, Card, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import React, { useMemo } from 'react';
import { t } from '~utils/i18n.js';

function FeedbackModal(props) {
    const { selectedOption, onHide, onShow, show } = props;
    const feedback = useMemo(() => {
        return selectedOption?.feedback ?? {};
    }, [selectedOption]);

    return (
        <Modal show={show} onHide={onHide} onShow={onShow}>
            <Modal.Header>
                <Modal.Title as="h1">{feedback.title}</Modal.Title>
            </Modal.Header>

            <Modal.Body>{ReactHtmlParser(feedback.content)}</Modal.Body>

            <Modal.Footer>
                <Button variant="primary" onClick={onHide}>
                    {feedback.button ?? t('continue')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

FeedbackModal.propTypes = {
    selectedOption: PropTypes.oneOfType([
        PropTypes.objectOf(PropTypes.any),
        PropTypes.bool,
    ]),
    onHide: PropTypes.func.isRequired,
    onShow: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
};

FeedbackModal.defaultProps = {
    selectedOption: undefined,
};

export default FeedbackModal;
