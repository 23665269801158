import _ from 'underscore';
import Backbone from 'backbone';
import React from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { MdClear, MdDone } from 'react-icons/md';
import { LanguageManager, Status } from '~core';
import Block from '~blocks/base/Block';
import './QuizProgress.scss';
import classNames from 'classnames';

class QuizProgress extends Block {
    constructor(props) {
        super(props);
        this.type =
            props.type === 'dots' || props.type === 'bar' ? props.type : 'dots';
        this.junctionData = this.block.junctionData;
        this.junctionController = this.block.junctionController;
        this.container = this.block.app;
        this.quizStatus = this.junctionData.getStatus();
        const initialQuestionData =
            this.junctionController.pageRenderer.currentPageData.getBlock(0)
                .view.data;

        this.state = {
            pages: this.junctionData.questionPages.map((page) => ({
                pageID: page.pageID(),
                status: page.getStatus(),
                isVisited:
                    page.getStatus() > Status.STARTED &&
                    this.quizStatus < Status.COMPLETED,
            })),
            currentPageID: this.junctionController.currentPageData.pageID(),
            currentIndex: 0,
            questionData: initialQuestionData,
        };

        this.labels = {
            questionLabel: LanguageManager.getString('question_label'),
            quizTitle: this.junctionData.attributes.courseTitle,
            quizDescription: this.junctionData.attributes.courseDescription,
        };

        this.backboneEvents = _.extend({}, Backbone.Events);
    }

    componentDidMount() {
        this.addListeners();
        this.updateIndex();
    }

    componentWillUnmount() {
        this.backboneEvents.stopListening();
    }

    addListeners() {
        this.backboneEvents.listenTo(
            this.junctionController,
            'onPageStatusUpdate',
            (e) => {
                this.handleStatusChange(e);
            }
        );

        this.backboneEvents.listenTo(
            this.container.session,
            'onJunctionUpdate',
            () => {
                this.backboneEvents.listenTo(
                    this.junctionController.pageRenderer,
                    'onBlockInitialized',
                    (e) => {
                        this.handleBlockInitialized(e);
                    }
                );
            }
        );

        this.backboneEvents.listenTo(this.block, 'onQuestionAnswered', (e) => {
            this.handleQuestionAnswered(e);
        });
    }

    handleStatusChange(e) {
        if (this.quizStatus >= Status.COMPLETED) return;

        this.setState((prevState) => ({
            pages: prevState.pages.map((page) =>
                page.pageID === e.pageData.pageID()
                    ? {
                          ...page,
                          status: e.pageData.getStatus(),
                      }
                    : page
            ),
        }));
    }

    handleBlockInitialized(e) {
        const template = e.view;
        this.setState(
            {
                questionData: template.data,
                currentPageID: template.pageData.pageID(),
            },
            () => {
                this.updateIndex();
            }
        );
    }

    handleQuestionAnswered(e) {
        this.setState((prevState) => ({
            pages: prevState.pages.map((page) =>
                page.pageID === e.pageData.pageID()
                    ? {
                          ...page,
                          status:
                              this.quizStatus < Status.COMPLETED
                                  ? page.status
                                  : e.status, // if quiz was already completed, status shown will be based on the question outcome
                          isVisited: true,
                      }
                    : page
            ),
        }));
    }

    updateIndex() {
        const index =
            _.findIndex(
                this.junctionData.questionPages,
                (page) => page.pageID() === this.state.currentPageID
            ) + 1;

        this.setState({ currentIndex: index });
    }

    render() {
        const { pages, currentPageID, currentIndex } = this.state;
        // const { question, context, instruction } = questionData;
        const { totalQuestions } = this.junctionData;
        const { questionLabel, quizTitle, quizDescription } = this.labels;

        return (
            <div className="container">
                <div className="quiz-info">
                    {quizTitle && <h2 className="quiz-title">{quizTitle}</h2>}
                    {quizDescription && (
                        <p className="quiz-description">{quizDescription}</p>
                    )}
                </div>

                {this.type === 'bar' && (
                    <div className="progress-bar-wrapper">
                        <div className="progress-meta">
                            {questionLabel && (
                                <div className="question-label">
                                    {questionLabel}
                                </div>
                            )}
                            <div className="count">
                                <span className="current">{currentIndex}</span>/
                                <span className="total">{totalQuestions}</span>
                            </div>
                        </div>
                        <ProgressBar now={currentIndex} max={totalQuestions} />
                    </div>
                )}

                {this.type === 'dots' && (
                    <div
                        className="progress-dots"
                        aria-label="progress"
                        role="group"
                    >
                        <ol>
                            {pages.map((page, i) => {
                                const isPassed =
                                    page.isVisited &&
                                    page.status === Status.PASSED;
                                const isFailed =
                                    page.isVisited &&
                                    page.status === Status.FAILED;

                                return (
                                    <li
                                        key={i}
                                        className={classNames({
                                            dot: true,
                                            active:
                                                page.pageID === currentPageID,
                                            correct: isPassed,
                                            wrong: isFailed,
                                        })}
                                        aria-current={
                                            page.pageID === currentPageID
                                                ? 'true'
                                                : 'false'
                                        }
                                    >
                                        <span className="sr-only">
                                            {page.isVisited
                                                ? Status.numberToStatus(
                                                      page.status
                                                  )
                                                : Status.numberToStatus(
                                                      Status.AVAILABLE
                                                  )}
                                        </span>
                                        {isPassed && (
                                            <MdDone fill="#fff" size="1.2em" />
                                        )}
                                        {isFailed && (
                                            <MdClear fill="#fff" size="1.2em" />
                                        )}
                                    </li>
                                );
                            })}
                        </ol>
                    </div>
                )}
            </div>
        );
    }
}

export default QuizProgress;
