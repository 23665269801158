import React, { useMemo } from 'react';
import { Card } from 'react-bootstrap';
import { useCollection } from 'react-firebase-hooks/firestore';
import ReactHtmlParser from 'react-html-parser';
import { BiTimeFive } from 'react-icons/bi';
import { MdOutlineCheckCircleOutline } from 'react-icons/md';
import Scarf from '../../components/Scarf';
import AvatarPillGroup from '../../components/AvatarPillGroup';
import styles from './Acknowledgement.module.css';
import { useCollectionReference } from '~m/Firebase';
import { useRoomSync } from '~m/RoomSync';
import { Routes } from '~m/RoomSync/RoomSync.contants';
import { useBlock } from '~templates/components/blocks';

function AcknowledgementForInstructors() {
    const { data } = useBlock({ instantComplete: true });
    const { text: ackText } = data.columns[0];

    const roomSync = useRoomSync();
    const roomId = useMemo(() => roomSync.roomId, [roomSync.roomId]);

    const roomMembersPath = useMemo(() => {
        if (!roomId) return;
        return Routes.rooms.members.INDEX(roomId);
    }, []);

    const [roomMembersSnapshot] = useCollection(
        useCollectionReference(roomMembersPath),
    );

    const membersAcknowledged = useMemo(() => {
        return roomMembersSnapshot?.docs.filter(member => member.data()?.acknowledged);
    }, [roomMembersSnapshot]);

    const membersNotAcknowledged = useMemo(() => {
        return roomMembersSnapshot?.docs.filter(member => !member.data()?.acknowledged);
    }, [roomMembersSnapshot]);

    const total = useMemo(() => roomMembersSnapshot?.size ?? 0, [roomMembersSnapshot]);

    return (
        <Scarf variant="centred">
            <Card className={styles.wrapper}>
                {ReactHtmlParser(ackText)}
                <AvatarPillGroup
                    className="bg-gray-200 self-baseline"
                    total={total}
                    collection={membersNotAcknowledged}
                    icon={<BiTimeFive className="text-2xl"/>}
                />
                <AvatarPillGroup
                    className="bg-gray-200 self-baseline"
                    total={total}
                    collection={membersAcknowledged}
                    icon={<MdOutlineCheckCircleOutline className="text-2xl"/>}
                />
            </Card>
        </Scarf>
    );
}

export default AcknowledgementForInstructors;
