import Backbone from 'backbone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
    Accordion,
    Badge,
    Button,
    Card,
    Spinner,
    Toast,
} from 'react-bootstrap';
import {
    MdArrowBack,
    MdClose,
    MdFilterList,
    MdRateReview,
} from 'react-icons/md';
import Reports from './Reports';
import AddListItem from './AddListItem';
import FilterListItems from './FilterListItems';
import ReviewListItems from './ReviewListItems';
import UpdateListItem from './UpdateListItem';
import { Logger } from '~core';
import './ReviewTool.scss';

/**
 * @param props
 */
function ReviewTool(props) {
    const { blockRef, data } = props;

    const { type } = data;
    const isInternal = type === 'internal';

    const labels = {
        title: 'Review Tool',
        backButton: 'Back',
        filterButton: 'Filter (open/close)',
        reviewButton: 'Review (open/close)',
        addButton: 'Add new',
        noRecordFound: 'No records found!',
        issueType: [
            'Content',
            'Code Improvement',
            'Design',
            'Functionality',
            'Internal Change Request',
            'Client Change Request',
            'Suggestion',
        ],
        priority: ['Low', 'Normal', 'High', 'Critical'],
        status: [
            { label: 'Open', class: 'danger' },
            { label: 'Re-open', class: 'danger' },
            { label: 'Query', class: 'info' },
            { label: 'In Progress', class: 'warning' },
            { label: 'Change Made', class: 'success' },
            { label: 'Dev closed', class: 'secondary' },
            { label: 'QA Closed', class: 'secondary' },
            { label: 'PL Closed', class: 'secondary' },
            { label: 'Duplicate', class: 'secondary' },
            { label: 'Deferred', class: 'primary' },
            { label: 'NA', class: 'primary' },
            { label: 'Non-Reproducible', class: 'primary' },
        ],
        environment: [
            'Desktop/Windows10/ChromeStable',
            'Desktop/Windows10/EdgeStable',
            'Desktop/Windows10/FirefoxStable',
            'iPhoneXS/iOS14/Safari14',
            'iPhoneXS/iOS14/ChromeStable',
            'iPhone8/iOS14/Safari14',
            'iPhone8/iOS14/ChromeStable',
            'Andriod/Google Pixel (8)',
            'Andriod/Samsung Galaxy S8 (7.0)',
            'Apple iPad 6th (Tablet 14.0)',
            'Andriod (Google Nexus 7 (Tablet 6.0)',
            'Mac',
        ],
    };

    const [refreshTime, setRefreshTime] = useState(0);
    const [error, setError] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [filters, setFilters] = useState({
        showGlobal: 'off',
        Status: [],
        Priority: [],
    });
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [location, setLocation] = useState('launch');
    const [authToken, setAuthToken] = useState(undefined);
    const [users, setUsers] = useState([]);
    const [isAddingNew, setIsAddingNew] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [allListItems, setAllListItems] = useState([]);
    const [listItems, setListItems] = useState([]);
    const [selectedListItemIndex, setSelectedListItemIndex] =
        useState(undefined);
    const [selectedListItemComments, setSelectedListItemComments] = useState(
        []
    );
    const selectedListItem = selectedListItemIndex
        ? listItems[selectedListItemIndex]
        : undefined;

    const siContainer = blockRef.container.dom.parent().find('.si-container');

    const handleReviewClick = () => {
        if (!isOpen) {
            siContainer.addClass('open');
        } else {
            siContainer.removeClass('open');
        }
        setIsOpen(!isOpen);
    };

    const handleAddClick = () => {
        setIsEditing(false);
        setSelectedListItemIndex(undefined);
        setIsAddingNew(true);
    };

    const handleFilterClick = () => {
        setIsFilterOpen(!isFilterOpen);
    };

    const handleBackClick = () => {
        setIsAddingNew(false);
        setIsEditing(false);
        setSelectedListItemIndex(undefined);
    };

    const handleFilterItemsSubmit = (item) => {
        setFilters(item);
        setIsFilterOpen(false);
    };

    const handleFilterItemsCancel = () => {
        setIsFilterOpen(false);
    };

    const handleListItemClick = (e) => {
        setIsEditing(true);
        setSelectedListItemIndex($(e.currentTarget).attr('id'));
    };

    const handleAddItemSubmit = (item) => {
        setIsLoading(true);
        // eslint-disable-next-line no-use-before-define
        addListItem(item);
        setIsAddingNew(false);
    };

    const handleAddItemCancel = () => {
        setIsAddingNew(false);
    };

    const handleUpdateItemSubmit = (item) => {
        // eslint-disable-next-line no-use-before-define
        updateListItem(selectedListItem.ID, item);
        setSelectedListItemIndex(undefined);
    };

    const handleUpdateItemCancel = () => {
        setIsEditing(false);
        setSelectedListItemIndex(undefined);
    };

    const handleChangeSlide = (e) => {
        setIsEditing(false);
        setIsAddingNew(false);
        setSelectedListItemIndex(undefined);
        setLocation(e.pageData.pageID());
    };

    const handleJunctionUpdate = (e) => {
        const pageID = e.pageData.pageID();
        const currentPageID =
            blockRef.container.session.currentPageData.pageID();
        setIsEditing(false);
        setIsAddingNew(false);
        setSelectedListItemIndex(undefined);

        if (pageID !== 'start') {
            setLocation(`${currentPageID}|${pageID}`);
        } else {
            setLocation(`${currentPageID}`);
        }
    };

    const handleErrors = (e) => {
        Logger.info('name:', e.name);
        Logger.info('description:', e.description);
        Logger.info('error:', e.error);
        setError(e);
    };

    const getUsers = () => {
        const myHeaders = new Headers();
        myHeaders.append('Accept', 'application/json;odata=nometadata');
        myHeaders.append('Authorization', `Bearer ${authToken}`);

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };

        fetch(
            `${data.rootURL}${data.team}/_api/web/SiteUsers?$filter=(Email ne '') and (PrincipalType eq 1)`,
            requestOptions
        )
            .then((response) => response.text())
            .then((result) => {
                setUsers(JSON.parse(result).value);
            })
            .catch((e) => {
                handleErrors({
                    name: 'GetUsers',
                    description:
                        'The fetch call to get the list of users failed!',
                    error: e,
                });
            });
    };

    const login = () => {
        const authURL = data.proxyURL + data.authURL;
        const resource =
            '00000003-0000-0ff1-ce00-000000000000/saffroninteractive.sharepoint.com@753bec95-f35d-4d06-a9ef-32f826122f65';
        const clientID =
            'ece1e35b-92df-486a-8edd-6c53355a69e2@753bec95-f35d-4d06-a9ef-32f826122f65';
        const clientSecret = 'B55ZHli0PFG29z1L0LufkbIUL4GOWghAQHjL1IgRrXI=';

        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
        myHeaders.append(
            'Cookie',
            'fpc=AsAri5FFAFBIl2jlpNch2w4g36C0AQAAAA1neNkOAAAA'
        );

        const urlencoded = new URLSearchParams();
        urlencoded.append('grant_type', 'client_credentials');
        urlencoded.append('resource', resource);
        urlencoded.append('client_id', clientID);
        urlencoded.append('client_secret', clientSecret);

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow',
        };

        // @ts-expect-error
        fetch(authURL, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                setAuthToken(JSON.parse(result).access_token);
            })
            .catch((e) => {
                handleErrors({
                    name: 'Login',
                    description: 'The login to sharepoint failed!',
                    error: e,
                });
            });
    };

    const getFilters = (property, values) => {
        let query = '';
        if (values.length > 0) {
            query = `and (${property} eq '${values.join(
                `' or ${property} eq '`
            )}')`;
        }
        return query;
    };

    const filtersCount =
        (filters.showGlobal === 'on' ? 1 : 0) +
        (filters.Status ? filters.Status.length : 0) +
        (filters.Priority ? filters.Priority.length : 0);

    const getListItems = () => {
        if (isAddingNew || isEditing) return;

        setIsLoading(true);
        setError(undefined);

        const myHeaders = new Headers();
        myHeaders.append('Accept', 'application/json;odata=nometadata');
        myHeaders.append('Authorization', `Bearer ${authToken}`);

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };
        let URL = `${data.rootURL}${data.team}/_api/web/lists/GetByTitle('${data.list}')/items`;

        if (filters.showGlobal === 'on') {
            URL += `?$filter=(Location eq 'global')`;
        } else {
            URL += `?$filter=(Location eq '${location}')`;
        }

        if (!isInternal) {
            URL += ' and (Issueloggedbyclient ne null)';
        }

        URL += getFilters('Status', filters.Status);

        URL += getFilters('Priority', filters.Priority);

        fetch(URL, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                setListItems(JSON.parse(result).value);
                setSelectedListItemIndex(undefined);
                setIsLoading(false);
            })
            .catch((e) => {
                handleErrors({
                    name: 'GetListItems',
                    description:
                        'The fetch call to get all the filtered list items failed!',
                    error: e,
                });
            });
    };

    const getAllListItems = () => {
        const myHeaders = new Headers();
        myHeaders.append('Accept', 'application/json;odata=nometadata');
        myHeaders.append('Authorization', `Bearer ${authToken}`);

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };
        const URL = `${data.rootURL}${data.team}/_api/web/lists/GetByTitle('${data.list}')/items?$top=1000`;

        fetch(URL, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                setAllListItems(JSON.parse(result).value);
            })
            .catch((e) => {
                handleErrors({
                    name: 'GetAllListItems',
                    description:
                        'The fetch call to get all the list items failed!',
                    error: e,
                });
            });
    };

    const addListItem = (item) => {
        const myHeaders = new Headers();
        myHeaders.append('Accept', 'application/json;odata=nometadata');
        myHeaders.append('Content-Type', 'application/json;odata=nometadata');
        myHeaders.append('Authorization', `Bearer ${authToken}`);

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(item),
            redirect: 'follow',
        };

        fetch(
            `${data.rootURL}${data.team}/_api/web/lists/GetByTitle('${data.list}')/items`,
            requestOptions
        )
            .then((response) => response.text())
            .then(() => {
                setIsAddingNew(false);
                setRefreshTime((t) => t + 1);
            })
            .catch((e) => {
                handleErrors({
                    name: 'AddListItem',
                    description:
                        'The fetch call to add a new list item failed!',
                    error: e,
                });
            });
    };

    const updateListItem = (ID, item) => {
        setIsLoading(true);

        const myHeaders = new Headers();
        myHeaders.append('Accept', 'application/json;odata=nometadata');
        myHeaders.append('Content-Type', 'application/json;odata=nometadata');
        myHeaders.append('Authorization', `Bearer ${authToken}`);
        myHeaders.append('IF-MATCH', '*');
        myHeaders.append('X-HTTP-METHOD', 'MERGE');

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(item),
            redirect: 'follow',
        };

        fetch(
            `${data.rootURL}${data.team}/_api/web/lists/GetByTitle('${data.list}')/items(${ID})`,
            requestOptions
        )
            .then((response) => response.text())
            .then(() => {
                setIsEditing(false);
                setRefreshTime((t) => t + 1);
            })
            .catch((e) => {
                handleErrors({
                    name: 'UpdateListItem',
                    description:
                        'The fetch call to update the selected list item failed!',
                    error: e,
                });
            });
    };

    const getListItemComments = () => {
        const myHeaders = new Headers();
        myHeaders.append('Accept', 'application/json;odata=nometadata');
        myHeaders.append('Authorization', `Bearer ${authToken}`);

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };

        const URL = `${data.rootURL}${data.team}/_api/web/lists/GetByTitle('${data.list}')/items('${selectedListItem.ID}')/GetComments()`;

        fetch(URL, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                setSelectedListItemComments(JSON.parse(result).value);
            })
            .catch((e) => {
                handleErrors({
                    name: 'GetListItemComments',
                    description:
                        'The fetch call to get comments of selected list item failed!',
                    error: e,
                });
            });
    };

    const addListItemComment = (item) => {
        const myHeaders = new Headers();
        myHeaders.append('Accept', 'application/json;odata=nometadata');
        myHeaders.append('Content-Type', 'application/json;odata=nometadata');
        myHeaders.append('Authorization', `Bearer ${authToken}`);

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(item),
            redirect: 'follow',
        };

        const URL = `${data.rootURL}${data.team}/_api/web/lists/GetByTitle('${data.list}')/items('${selectedListItem.ID}')/Comments()`;

        fetch(URL, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                getListItemComments();
            })
            .catch((e) => {
                handleErrors({
                    name: 'addListItemComment',
                    description:
                        'The fetch call to add a comment on selected list item failed!',
                    error: e,
                });
            });
    };

    const addListItemImage = (name, content) => {
        console.info('addListItemImage');
        const myHeaders = new Headers();
        myHeaders.append('Accept', 'application/json;odata=nometadata');
        myHeaders.append('Content-Type', 'application/json;odata=nometadata');
        myHeaders.append('binaryStringRequestBody', 'true');
        myHeaders.append('Authorization', `Bearer ${authToken}`);

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: content,
            redirect: 'follow',
        };

        const URL = `${data.rootURL}${data.team}/_api/web/GetFolderByServerRelativeUrl('sites/${data.list}/SiteAssets/Lists/')/Files/Add(url="${name}",overwrite=true)`;
        fetch(URL, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                console.info('uploaded successfully!');
            })
            .catch((e) => {
                handleErrors({
                    name: 'addListItemImage',
                    description:
                        'The fetch call to add an image to the site is failed!',
                    error: e,
                });
            });
    };

    useEffect(() => {
        if (selectedListItem) getListItemComments();
    }, [selectedListItem]);

    useEffect(() => {
        if (authToken) {
            getListItems();
            getAllListItems();
        }
    }, [authToken, location, refreshTime, filters]);

    useEffect(() => {
        if (authToken) {
            getUsers();
        }
    }, [authToken]);

    useEffect(() => {
        login();

        // @ts-expect-error
        Backbone.listenTo(
            blockRef.container.session,
            'onChangeSlide onChangeGlobalSlide',
            handleChangeSlide
        );

        // @ts-expect-error
        Backbone.listenTo(
            blockRef.container.session,
            'onJunctionUpdate',
            handleJunctionUpdate
        );

        const intervalId = setInterval(() => {
            setRefreshTime((t) => t + 1);
        }, 60 * 1000);

        return () => {
            // @ts-expect-error
            Backbone.stopListening(
                blockRef.container.session,
                'onChangeSlide onChangeGlobalSlide',
                handleChangeSlide
            );

            // @ts-expect-error
            Backbone.stopListening(
                blockRef.container.session,
                'onJunctionUpdate',
                handleChangeSlide
            );

            clearInterval(intervalId);
        };
    }, []);

    return (
        <div
            className={
                isOpen ? 'review-tool-wrapper open' : 'review-tool-wrapper'
            }
        >
            <Card className="review-tool-card">
                <Card.Body className="p-2">
                    <Card.Title>
                        {!isAddingNew && !isEditing && (
                            <Button
                                title={labels.filterButton}
                                className="filter-button"
                                variant="light"
                                onClick={handleFilterClick}
                            >
                                {isFilterOpen ? <MdClose /> : <MdFilterList />}
                                {filtersCount ? (
                                    <div className="filter-indicator badge-danger" />
                                ) : (
                                    ''
                                )}
                            </Button>
                        )}
                        {(isAddingNew || isEditing) && (
                            <Button
                                title={labels.backButton}
                                className="filter-button"
                                variant="light"
                                onClick={handleBackClick}
                            >
                                <MdArrowBack />
                            </Button>
                        )}
                        {labels.title}{' '}
                        {isLoading && (
                            <Spinner
                                animation="border"
                                variant="info"
                                size="sm"
                            />
                        )}
                        <Badge pill variant="info">
                            {location}
                        </Badge>
                        {error && (
                            <Toast className="bg-danger">
                                <Toast.Header>
                                    <strong>Error: {error.name}</strong>
                                </Toast.Header>
                                <Toast.Body>{error.description}</Toast.Body>
                            </Toast>
                        )}
                    </Card.Title>
                    {!isLoading &&
                        !isAddingNew &&
                        !isEditing &&
                        isFilterOpen && (
                            <FilterListItems
                                labels={labels}
                                filters={filters}
                                onSubmitClick={handleFilterItemsSubmit}
                                onCancelClick={handleFilterItemsCancel}
                            />
                        )}
                    {!isAddingNew && !isEditing && (
                        <>
                            <Button
                                title={labels.addButton}
                                className="add-button w-100 mb-2"
                                onClick={handleAddClick}
                                disabled={isAddingNew}
                            >
                                {labels.addButton}
                            </Button>
                            <Accordion defaultActiveKey="0">
                                <Card className="w-100">
                                    <Accordion.Toggle
                                        as={Card.Header}
                                        className="p-2"
                                        eventKey="0"
                                    >
                                        Issues
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body className="p-2">
                                            {listItems.length < 1 && (
                                                <Card className="w-100">
                                                    <Card.Body>
                                                        <Card.Title className="text-center">
                                                            {
                                                                labels.noRecordFound
                                                            }
                                                        </Card.Title>
                                                    </Card.Body>
                                                </Card>
                                            )}
                                            {!isLoading && (
                                                <ReviewListItems
                                                    labels={labels}
                                                    listItems={listItems}
                                                    onListItemClick={
                                                        handleListItemClick
                                                    }
                                                />
                                            )}
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                {isInternal && (
                                    <Card className="w-100">
                                        <Accordion.Toggle
                                            as={Card.Header}
                                            className="p-2"
                                            eventKey="1"
                                        >
                                            Reports
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="1">
                                            <Reports
                                                labels={labels}
                                                listItems={allListItems}
                                            />
                                        </Accordion.Collapse>
                                    </Card>
                                )}
                            </Accordion>
                        </>
                    )}
                    {!isLoading && !isAddingNew && isEditing && (
                        <UpdateListItem
                            labels={labels}
                            location={location}
                            listItem={selectedListItem}
                            comments={selectedListItemComments}
                            users={users}
                            data={data}
                            onSubmitClick={handleUpdateItemSubmit}
                            onCancelClick={handleUpdateItemCancel}
                            onAddImage={addListItemImage}
                            onAddComment={addListItemComment}
                        />
                    )}
                    {!isLoading && isAddingNew && !isEditing && (
                        <AddListItem
                            labels={labels}
                            location={location}
                            users={users}
                            data={data}
                            onSubmitClick={handleAddItemSubmit}
                            onCancelClick={handleAddItemCancel}
                        />
                    )}
                </Card.Body>
            </Card>
            <Button
                title={labels.reviewButton}
                className="review-button"
                onClick={handleReviewClick}
            >
                {isOpen ? <MdArrowBack /> : <MdRateReview />}
            </Button>
        </div>
    );
}

ReviewTool.propTypes = {
    blockRef: PropTypes.objectOf(PropTypes.any).isRequired,
    data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ReviewTool;
