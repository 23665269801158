import { omit } from 'lodash';
import { BrowserDetect } from '../../index';
import { getFunctionUrl } from '../../utils/remote';
import ContainerPlugin from '~m/ContainerPlugin/ContainerPlugin';

class Diagnostics extends ContainerPlugin {
    name = 'Diagnostics';

    defaultConfig = {
        domains: [],
        timeout: 5000,
    };

    async checkDomain(url) {
        const { timeout } = this.config;
        const hasTimeout = typeof timeout === 'number';

        return new Promise((resolve) => {
            const controller = new AbortController();

            const request = fetch(url, {
                method: 'GET',
                mode: 'no-cors',
                signal: controller.signal,
            });

            const timeoutId = setTimeout(() => {
                if (!hasTimeout) return;

                controller.abort();
                resolve(false);
            }, timeout);

            request
                .then(() => resolve(true))
                .catch(() => resolve(false))
                .finally(() => clearTimeout(timeoutId));
        });
    }

    async start(details = {}) {
        // Run a check on each domain and return as entries
        const checks = this.config.domains.map(async domain => [
            domain,
            await this.checkDomain(domain),
        ]);

        // Wait for all the requests for finish in parallel
        const resultEntries = await Promise.all(checks);

        // Convert the entries to an object
        const results = Object.fromEntries(resultEntries);

        // Get the browser info
        const browser = omit(BrowserDetect, ['parser', 'init']);

        const output = {
            details,
            results,
            browser,
        };

        const formData = new FormData();
        formData.append('data', JSON.stringify(output));
        formData.append('email', details?.emailAddress ?? details?.email ?? '');

        const url = getFunctionUrl('live_training_diagnostic');
        /** @type {RequestInit} */
        const options = {
            method: 'POST',
            // headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: formData,
            redirect: 'follow',
        };

        const response = await fetch(url, options).then(response => response.json());

        return { response, output };
    }
}

export default Diagnostics;
