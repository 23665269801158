import React from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import { MdClear, MdDone } from 'react-icons/md';
import { AiOutlineDrag } from 'react-icons/ai';

/**
 * @memberOf ReactBlocks
 * @Param {String} classNames using classNames to show tick or close icon based on class name.
 * @Param {Number} tabIndexValue getting and setting tabindex as per useDragHandle value to focus option or drag handle
 * and keyboard support. If useDragHandle is false setting -1 otherwise 0.
 * @returns {ReactNode}
 */
const DragHandle = SortableHandle(({ classNames, tabIndexValue }) => {
    return (
        <span
            className={`d-flex align-items-center justify-content-center drag-icon btn btn-primary ${classNames}`}
            tabIndex={tabIndexValue}
        >
            {classNames === '' ? <AiOutlineDrag className="icon" /> : ''}
            {classNames === 'correct' ? <MdDone className="icon" /> : ''}
            {classNames === 'incorrect' ? <MdClear className="icon" /> : ''}
        </span>
    );
});

export default DragHandle;
