// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".searchWrapper__c46eed{position:relative}.searchIcon__b97096{color:#777;height:1.2em;left:16px;pointer-events:none;position:absolute;top:14px;width:1.2em}.searchInput__ddadcf{background:transparent;border:none;font-size:1.2em;outline:none;padding:8px 16px 8px 48px;width:100%}.options__452c98{list-style:none;margin-bottom:0;max-height:300px;overflow-y:auto;padding:0}.option__5c2335{display:flex;justify-content:space-between;padding:4px 16px}.secondaryText__060f47{color:#4b5563}.optionActive__93b67b{background:#f3f4f6;color:#111827}", "",{"version":3,"sources":["webpack://./externals/templates/components/blocks/admin/adminGoto.module.css"],"names":[],"mappings":"AAAA,uBACI,iBACJ,CAEA,oBAGI,UAAW,CACX,YAAa,CAEb,SAAU,CALV,mBAAoB,CACpB,iBAAkB,CAKlB,QAAS,CAFT,WAGJ,CAEA,qBAMI,sBAAuB,CAFvB,WAAY,CAHZ,eAAgB,CAMhB,YAAa,CAFb,yBAAkB,CAFlB,UAKJ,CAEA,iBAEI,eAAgB,CAIhB,eAAgB,CADhB,gBAAiB,CADjB,eAAgB,CADhB,SAIJ,CAEA,gBAEI,YAAa,CACb,6BAA8B,CAF9B,gBAGJ,CAEA,uBACI,aACJ,CAEA,sBACI,kBAAmB,CACnB,aACJ","sourcesContent":[".searchWrapper {\n    position: relative;\n}\n\n.searchIcon {\n    pointer-events: none;\n    position: absolute;\n    color: #777;\n    height: 1.2em;\n    width: 1.2em;\n    left: 16px;\n    top: 14px;\n}\n\n.searchInput {\n    font-size: 1.2em;\n    padding: 8px 16px;\n    width: 100%;\n    border: none;\n    padding-left: 48px;\n    background: transparent;\n    outline: none;\n}\n\n.options {\n    /*max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800*/\n    list-style: none;\n    padding: 0;\n    overflow-y: auto;\n    max-height: 300px;\n    margin-bottom: 0;\n}\n\n.option {\n    padding: 4px 16px;\n    display: flex;\n    justify-content: space-between;\n}\n\n.secondaryText {\n    color: #4b5563;\n}\n\n.optionActive {\n    background: #f3f4f6;\n    color: #111827;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchWrapper": "searchWrapper__c46eed",
	"searchIcon": "searchIcon__b97096",
	"searchInput": "searchInput__ddadcf",
	"options": "options__452c98",
	"option": "option__5c2335",
	"secondaryText": "secondaryText__060f47",
	"optionActive": "optionActive__93b67b"
};
export default ___CSS_LOADER_EXPORT___;
