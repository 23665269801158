import { Logger } from '../../../../index';
import ScoringPlugin from './scoringplugin';

/**
 * @classdesc Adds result in score based on selected option's id.
 *
 * @class
 */
const GlobalScoringPlugin = ScoringPlugin.extend({
    blockInitialize(blockData) {
        ScoringPlugin.prototype.blockInitialize.call(this, blockData);
        this.listenTo(
            blockData.view,
            'onSubmitAnswer',
            this.handleQuestionAnswered
        );
    },

    handleQuestionAnswered(e) {
        Logger.info('GlobalScoringPlugin.handleQuestionAnswered', e);
        if (Array.isArray(e.selectedOptions)) {
            e.selectedOptions.forEach((option) => {
                const result = option.toString();
                if (result !== null) this.addResult(result);
            });
        }
    },
});

export default GlobalScoringPlugin;
