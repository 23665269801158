import TweenMax from 'gsap';
import React from 'react';
import VideoControls from './ui/VideoControls';
import Block from '~blocks/base/Block';
import { LanguageManager, Status } from '~core';
// eslint-disable-next-line import/no-cycle
import './VideoBlock.scss';
import { Container } from 'react-bootstrap';
import BuildAnimation from '~components/animations/BuildAnimation';
import classNames from 'classnames';

export const STATUSES = {
    Playing: 'playing',
    Paused: 'paused',
};

/**
 * @param e
 * @augments Block
 * @memberOf ReactBlocks
 */
class VideoBlock extends Block {
    /**
     * @param props
     */
    constructor(props) {
        super(props);

        /** @type {import('./video-types').VideoSchema} */
        this.schema = props.blockRef.data;

        /**
         * The line to scroll to when entering theatre mode
         *
         * @type {React.RefObject}
         */
        this.scrollTo = React.createRef();

        /**
         * The video container
         *
         * @type {React.RefObject}
         */
        this.container = React.createRef();
        this.fullscreen.reference = this.container;

        /**
         * The native HTML video element
         *
         * @type {React.RefObject}
         */
        this.player = React.createRef();

        /**
         * The user action reaction pulse element
         *
         * @type {React.RefObject}
         */
        this.pulse = React.createRef();

        this.labels = {
            play: LanguageManager.getString('play'),
            pause: LanguageManager.getString('pause'),
            exitFullScreen: LanguageManager.getString('exit_full_screen'),
            enterFullScreen: LanguageManager.getString('enter_full_screen'),
            caption: LanguageManager.getString('caption'),
            audioOn: LanguageManager.getString('audio_on'),
            audioOff: LanguageManager.getString('audio_off'),
            progress: LanguageManager.getString('progress'),
        };

        this.state = {
            status: null,
            completed: false,
            muted: this.schema?.options?.muted || true,
            duration: 0,
            fullscreen: false,
            fluid: this.schema.fluid,
            visibleControls: false,
            paused: false,
            isCursorPointer: false,
        };

        /**
         * Match the video mute to the global mute
         */
        this.block.listenTo(
            this.block.app.audioManager,
            'onAudioToggle',
            ({ isAudioOn }) => this.handleMute(!isAudioOn)
        );

        /**
         * Could see in IE video playing when navigating away while video is loading.
         */
        this.block.listenTo(this.block, 'onDestroy', () => this.pause());

        /**
         * Pause the video when suspending the page
         */
        this.block.listenTo(
            this.block.app.screenManager,
            'onPageSuspended',
            () => this.pause()
        );

        /**
         * Play the video when restoring the page
         */
        this.block.listenTo(
            this.block.app.screenManager,
            'onPageRestored',
            () => this.play()
        );

        /**
         * Pause the video when changing active browser tab, play when returning
         */
        this.block.listenTo(
            this.block,
            'onPageVisibilityChange',
            ({ hidden }) => (hidden ? this.pause() : this.play())
        );

        this.block.listenTo(this.block.app, 'onOrientationChange', (e) =>
            this.handleOrientationChange(e)
        );

        this.element = React.createRef();
    }

    componentDidMount() {
        BuildAnimation.animateIn(this.element.current);
    }

    handleOrientationChange(e) {
        if (e.orientation === 0 || e.orientation === 180) {
            this.pause();
        } else {
            const isSuspended = this.block.app.screenManager.isSuspended();
            if (isSuspended) return;
            this.play();
        }
    }

    /**
     * @returns {{volume: *, loop: *, autoPlay: *, muted: *}}
     */
    get options() {
        const { autoPlay, loop, volume } = this.schema?.options || {};
        return {
            autoPlay,
            loop,
            volume,
            muted: this.state.muted,
        };
    }

    /**
     * Should the controls be shown right now
     *
     * @returns {boolean}
     */
    get showControls() {
        return (
            this.schema?.options.controls &&
            (this.state.visibleControls ||
                this.state.status === STATUSES.Paused)
        );
    }

    /**
     * Does the current video have any captions available
     *
     * @returns {boolean}
     */
    get hasCaptions() {
        return this.player.current?.textTracks.length > 0;
    }

    /**
     * @returns {import('react').ReactNode}
     */
    render() {
        const tracks = this.schema?.options?.tracks?.map((track, index) => {
            return (
                <track
                    key={index.toString()}
                    default={track.default}
                    kind={track.kind}
                    label={track.label}
                    src={track.source}
                    srcLang={track.lang}
                    id={track.lang}
                />
            );
        });

        const events = {
            video: {
                onPlaying: () => {
                    this.setState({
                        status: STATUSES.Playing,
                        duration: this.player.current.duration,
                    });
                },
                onTimeUpdate: () => {
                    this.setState({
                        currentTime: this.player.current.currentTime,
                    });
                },
                onPause: () => {
                    this.setState({ status: STATUSES.Paused });
                },
                onEnded: () => {
                    this.setState({
                        completed: true,
                        isCursorPointer: true,
                    });
                    this.block.complete();
                },
            },
            container: {
                onMouseMove: this.resetTimeout,
                onClick: () => {
                    this.resetTimeout();
                    this.container.current.focus();
                },
                onMouseLeave: () => {
                    this.setState({ visibleControls: false });
                },
                onKeyDown: this.handleKeypress,
            },
        };

        return (
            <Container ref={this.element}>
                <div ref={this.scrollTo} />
                <div
                    ref={this.container}
                    style={{ height: '100%' }}
                    // @ts-expect-error
                    className={classNames({
                        'shadow-lg': this.state.fluid,
                        'bg-dark': this.state.fluid,
                    })}
                >
                    <div
                        style={{ height: '100%' }}
                        // @ts-expect-error
                        className={classNames({
                            'position-relative': true,
                            'video-block': true,
                            'no-cursor': !this.state.visibleControls,
                        })}
                        tabIndex="0"
                        {...events.container}
                    >
                        <video
                            ref={this.player}
                            {...this.options}
                            {...events.video}
                        >
                            <source src={this.schema.source} />
                            {tracks}
                        </video>
                        <div
                            className="video-overlay d-flex text-right justify-content-center"
                            onClick={this.handlePlayPause}
                        />
                        {this.showControls && this.state.status != null && (
                            <VideoControls video={this} labels={this.labels} />
                        )}
                    </div>
                </div>
            </Container>
        );
    }

    /**
     * @param prevProps
     * @param prevState
     * @param snapshot
     * @see https://reactjs.org/docs/react-component.html#componentdidupdate
     */
    componentDidUpdate = (prevProps, prevState, snapshot) => {
        if (prevState.track !== this.state.track) {
            this.videoCaptions(this.state.track);
        }
    };

    /**
     * Toggles playing or pausing the block's video
     */
    handlePlayPause = () => {
        const callback = {
            [STATUSES.Playing]: (video) => video.pause(),
            [STATUSES.Paused]: (video) => video.play(),
        };

        callback[this.state.status](this.player.current);
    };

    /**
     * Only start playing if the video was playing before
     * If the video was paused by the user before navigating to the resource
     * page or before the active browser tab was changed, the video should not
     * start playing again when returning to the video. This is not a regular
     * play function and should not be called in response to a user clicking the
     * play/pause button
     */
    play = () => {
        if (this.state.paused) {
            return;
        }

        this.player.current?.play();
    };

    /**
     * Pause the video and set the blocks paused state
     * This is intended for when the video needs to be paused to resume it later
     * automatically. Eg when the tab loses focus and regains it or when
     * suspending and restoring a page. This is not a regular pause function and
     * should not be called in response to a user clicking the play/pause button
     */
    pause = () => {
        if (this.player.current) {
            this.setState({ paused: this.player.current.paused });
            this.player.current.pause();
        }
    };

    /**
     * Skip video when video ended or revisit
     *
     * @param e
     */
    handleProgressClick = (e) => {
        const status =
            this.block.app.session.currentPageData.attributes.pageStatus;
        if (status < Status.COMPLETED) return;
        this.setState({ isCursorPointer: true });
        const progressHolder = document.getElementById('progress-bar');
        this.setPlayProgress(e.pageX, progressHolder);
    };

    handleOnChange = (e) => {
        const status =
            this.block.app.session.currentPageData.attributes.pageStatus;
        if (status < Status.COMPLETED) return;
        this.setState({ currentTime: e.target.value });
        this.player.current.currentTime = e.target.value;
    };

    /**
     * Set video play progression on skip
     *
     * @param clickX
     * @param progressHolder
     */
    setPlayProgress = (clickX, progressHolder) => {
        const video = this.player.current;
        const newPercent = Math.max(
            0,
            Math.min(
                1,
                (clickX - this.findPosX(progressHolder)) /
                    progressHolder.offsetWidth
            )
        );
        video.currentTime = newPercent * video.duration;
    };

    /**
     * Find X position of mouse click when skip video
     *
     * @param progressHolder
     */
    findPosX = (progressHolder) => {
        let curleft = progressHolder.offsetLeft;
        while ((progressHolder = progressHolder.offsetParent)) {
            curleft += progressHolder.offsetLeft;
        }
        return curleft;
    };

    /**
     * Toggles the mute state
     *
     * @param newState
     */
    handleMute = (newState) => {
        if (typeof newState !== 'boolean') {
            newState = !this.state.muted;
        }

        this.setState({
            muted: newState ?? !this.state.muted,
        });
    };

    /**
     * Change the captions displayed on the video
     *
     * @param {null|VideoSchemaTextTrack|BCP47} track
     */
    videoCaptions = (track) => {
        const video = this.player.current;
        for (const tt of video.textTracks) {
            tt.mode =
                (!!track && tt.language === track.lang) || tt.language === track
                    ? 'showing'
                    : 'hidden';
        }
    };

    /**
     * Resets the timeout for hiding the controls
     */
    resetTimeout = () => {
        this.setState({ visibleControls: true });
        if (this.timeout) {
            this.timeout.kill();
        }

        this.timeout = TweenMax.delayedCall(1, () => {
            this.setState({ visibleControls: false });
        });
    };

    /**
     * Handle keypress when the video block is in focus
     *
     * @param event
     */
    handleKeypress = (event) => {
        if (this.block.app.isLocked) return;
        const video = this.player.current;

        const actions = {
            ' ': this.handlePlayPause,
            k: this.handlePlayPause,
            Home: () => {
                video.currentTime = 0;
            },
            End: () => {
                video.currentTime = video.duration - 1;
            },
            ArrowRight: () => {
                video.currentTime += 5;
            },
            ArrowLeft: () => {
                video.currentTime -= 5;
            },
            j: () => {
                video.currentTime -= 10;
            },
            l: () => {
                video.currentTime += 10;
            },
            f: this.videoFullscreen,
        };

        const method = actions[event.key];

        if (!method) {
            return;
        }

        method();
    };
}

export default VideoBlock;
