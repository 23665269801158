import React from 'react';
import ReactDOM from 'react-dom';
import Backbone from 'backbone';
import ReviewTool from './ReviewTool';
import { Logger } from '~core';

const ReviewPage = Backbone.View.extend({
    initialize(properties) {
        this.data = {};
        this.name = 'ReviewPage';
        this.jsonPath = properties.jsonPath;
    },

    setup(container) {
        this.container = container;
        // this.listenTo(this.container, 'onSetupComplete', this.start);
        this.start();
    },

    start() {
        const self = this;
        $.getJSON(this.jsonPath, (data) => {
            self.onDataLoad(data);
        });
    },

    onDataLoad(e) {
        const self = this;
        e.items.forEach((item) => {
            self.addConfig(item.key, item.value);
        });
        this.render();
    },

    addConfig(key, value) {
        if (this.data.hasOwnProperty.call(key)) {
            Logger.warn(
                `ReviewPage: failed to add key [${key}], it already exists.`
            );
            return false;
        }

        this.data[key] = value;
        return true;
    },

    render() {
        // eslint-disable-next-line react/jsx-filename-extension
        const component = <ReviewTool blockRef={this} data={this.data} />;
        this.$el = $("<div class='ReviewTool' />");
        this.container.dom.parent().append(this.$el);
        ReactDOM.render(component, this.$el[0]);
    },
    destroy() {
        this.stopListening();
        this.remove();
    },
});

export default ReviewPage;
