import Backbone from 'backbone';
import _ from 'underscore';
import Status from '../data/status.js';
import PageData from '../data/pagedata.js';
import BlockData from '../data/blockdata.js';
import ModuleData from '../data/moduledata.js';
import CourseData from './coursedata.js';

/**
 * @class Parse the JSON course file and update course data
 * @name JSONParser
 * @augments Backbone.Model
 *
 * @see Backbone.Model
 */
const JSONParser = Backbone.Model.extend(
    /** @lends JSONParser.prototype */ {
        /**
         * Initialize the parser
         *
         * @param {ref} reference
         * @param {json} data
         */
        initialize(reference, data, container) {
            this.courseData = reference;
            this.container = container;
            this.data = data;

            this.setCourseTitle();
            this.setCourseDescription();
            this.setupScreens();
            this.setupIntroduction();
            this.setupModules();
            this.setPassmark();
        },

        /**
         * Set the course title
         */
        setCourseTitle() {
            this.courseData.setCourseTitle(this.data.title);
        },

        /**
         * Set the course description
         */
        setCourseDescription() {
            this.courseData.setCourseDescription(this.data.description);
        },

        /**
         * Set the course passmark
         */
        setPassmark() {
            this.courseData.setPassmark(this.data.passmark);
        },

        /**
         * Setup the global screens like coursemap, resources etc.
         */
        setupScreens() {
            const moduleID = undefined;
            this.createModule(
                this.data.globalPages,
                moduleID,
                Status.AVAILABLE,
                0
            );

            _.each(
                this.data.globalPages,
                function (page) {
                    var page = this.createPage(
                        page,
                        page.pageID,
                        moduleID,
                        Status.UNAVAILABLE
                    );
                    this.courseData.addPage(page);
                },
                this
            );
        },

        /**
         * Setup introduction module of a course
         */
        setupIntroduction() {
            _.each(
                this.data.introUnits,
                function (unit, i) {
                    const moduleID = unit.unitID;
                    const moduleStatus = Status.statusToNumber(unit.status);

                    const module = this.createModule(
                        unit,
                        moduleID,
                        moduleStatus,
                        i
                    );

                    _.each(
                        unit.pages,
                        function (page, j) {
                            let pageStatus = page.status;

                            if (
                                moduleStatus >= Status.COMPLETED &&
                                moduleStatus !== Status.OPTIONAL
                            ) {
                                pageStatus = moduleStatus;
                            }

                            var page = this.createPage(
                                page,
                                page.pageID,
                                moduleID,
                                pageStatus
                            );
                            page.moduleNumber(-1);
                            page.pageNumber(j);

                            module.addPage(page);
                            this.courseData.addPage(page);
                        },
                        this
                    );

                    this.courseData.addIntroductionModule(module);
                },
                this
            );
        },

        /**
         * Setup modules of a course
         */
        setupModules() {
            // [MS]: need to write code for filtering later after catching up with AM

            _.each(
                this.data.units,
                function (unit, i) {
                    const moduleID = unit.unitID;
                    const moduleStatus = Status.statusToNumber(unit.status);

                    const module = this.createModule(
                        unit,
                        moduleID,
                        moduleStatus,
                        i
                    );

                    _.each(
                        unit.pages,
                        function (page, j) {
                            let pageStatus = page.status;

                            if (
                                moduleStatus >= Status.COMPLETED &&
                                moduleStatus !== Status.OPTIONAL
                            ) {
                                pageStatus = moduleStatus;
                            }
                            var page = this.createPage(
                                page,
                                page.pageID,
                                moduleID,
                                pageStatus
                            );
                            page.moduleNumber(i);
                            page.pageNumber(j);

                            module.addPage(page);
                            this.courseData.addPage(page);
                        },
                        this
                    );

                    this.courseData.addModule(module);
                },
                this
            );
        },

        /**
         * Create a module data
         *
         * @param {ref} module
         * @param {string} moduleID
         * @param {number} status This could be from 0 to 7 refer status.js for more information
         * @param {number} index
         * @returns {BlockData}
         */
        createModule(module, moduleID, status, index) {
            const title = module.title;
            const description = module.description;
            const args = module.arguments || '';
            const totalCompletionRequired =
                Number(module.totalCompletionRequired) || 0;

            const moduleData = new ModuleData({
                id: index,
            });
            moduleData.setModuleID(moduleID);
            moduleData.setModuleTitle(title);
            moduleData.setModuleDescription(description);
            moduleData.setModuleStatus(status);
            moduleData.setArgs(args);
            moduleData.setTotalCompletionRequired(totalCompletionRequired);

            return moduleData;
        },

        /**
         * Create a page data
         *
         * @param {ref} page
         * @param {string} pageID
         * @param {string} moduleID
         * @param {number} status This could be from 0 to 7 refer status.js for more information
         * @returns {PageData}
         */
        createPage(page, pageID, moduleID, status) {
            const title = page.title;
            const description = page.description;
            const args = page.arguments || '';
            const isCompletionRequired = page.isCompletionRequired;

            const found = this.courseData.pages.findWhere({ pageID });

            if (found) {
                pageData = found;
            } else {
                var pageData = new PageData({
                    id: this.courseData.getTotalPages(),
                });
                pageData.moduleID(moduleID);
                pageData.pageID(pageID);
                pageData.title(title);
                pageData.description(description);
                pageData.isComplete(
                    Status.statusToNumber(status) >= Status.COMPLETED
                );
                pageData.setStatus(Status.statusToNumber(status));

                if (
                    this.container?.tracking &&
                    Number(this.container.tracking.getItem(pageID)) > -1
                ) {
                    pageData.setStatus(
                        Number(this.container.tracking.getItem(pageID))
                    );
                }

                pageData.setArgs(args);
                pageData.setCompletionRequired(isCompletionRequired);

                _.each(
                    page.blocks,
                    function (block, i) {
                        pageData.addBlock(
                            this.createBlock(block, i, pageID, moduleID, status)
                        );
                    },
                    this
                );
            }

            return pageData;
        },

        /**
         * Create a block data
         *
         * @param {ref} block
         * @param {string} blockID
         * @param {string} pageID
         * @param {string} moduleID
         * @param {number} pageStatus This could be from 0 to 7 refer status.js for more information
         * @returns {BlockData}
         */
        createBlock(block, blockID, pageID, moduleID, pageStatus) {
            const JSONPath = block.jsonPath;
            const title = block.title;
            const template = block.template;
            const args = block.arguments || '';
            const isVisible = block.isVisible;
            const status = Status.statusToNumber(block.status);

            const isComplete =
                status >= Status.COMPLETED || pageStatus >= Status.COMPLETED;

            const blockData = new BlockData({
                id: blockID,
            });
            blockData.order(blockID);
            blockData.moduleID(moduleID);
            blockData.pageID(pageID);
            blockData.blockID(`${blockID}`);
            blockData.setStatus(status);
            blockData.blockXML(JSONPath);
            blockData.title(title);
            blockData.template(template);
            blockData.args(args);
            blockData.isVisibleFromStart(isVisible);
            blockData.isComplete(isComplete);

            return blockData;
        },
    }
);

export default JSONParser;
