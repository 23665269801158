import $ from 'jquery';
import { Status } from '../../index';
import Block from '../templates/block.js';
import AssetLoader from '../loader/assetloader.js';
import JunctionCourseData from '../junction/data/junctioncoursedata.js';
import JunctionController from '../junction/controller/junctioncontroller.js';
import JunctionTracking from '../junction/tracking/junctiontracking.js';
import JunctionAudio from '../junction/audio/junctionaudio.js';

/**
 * Junction screens should be used to link through to more complex modules in a course
 * @param  {type} {                        $stage:undefined [description]
 * @param  {CourseData} junctionData       The main data object controlling the Junction, it's an extension of the CourseData
 * @param  {Object} junctionController the main controller for navigation between the pages in the Junction data
 * @param  {String} path           The path for the junction course XML file
 * @param  {AssetLoader} loader         [description]
 * @param  {JQuery} $junctionArea      The main stage for the container
 * @return {Void}                  Description
 */
const Junction = Block.extend(
    {
        // @TODO put in a contructor.
        $stage: undefined,
        junctionData: undefined,
        junctionController: undefined,
        path: undefined,
        loader: undefined,
        $junctionArea: undefined,
        startPage: undefined,
        navigation: undefined,

        name: 'Junction',

        initialize(options) {
            Block.prototype.initialize.call(this, options);

            this.$junctionArea = $("<div class='junction-area' />");
            this.navigation = this.app.getComponent('NavigationManager');

            this.listenTo(
                this.app.screenManager,
                'onPageSuspended',
                this.handlePageSuspended
            );
            this.listenTo(
                this.app.screenManager,
                'onPageRestored',
                this.handlePageRestored
            );
        },

        render() {
            this.path = this.blockData.blockXML();
            // [MS]: Need to come back here to optimize the below code at some point
            if (this.path.includes('.json')) {
                const self = this;
                this.listenTo(
                    this.app.session,
                    'onJunctionUpdate',
                    this.handleJunctionUpdate
                );
                $.getJSON(this.path, function (data) {
                    // self.junctionData.createCourseData(data, 'json');
                    self.trigger('onJunctionDataCreated', {
                        target: self,
                        junctionData: self.junctionData,
                    });
                    self.junctionTracking.setup();
                    self.showStartPage();
                    self.createListeners();
                    const content = self.getContent();
                    self.$el.append(content);
                });
            } else {
                this.loader = new AssetLoader();
                this.loader.addXML(this.path);
                this.listenTo(
                    this.app.session,
                    'onJunctionUpdate',
                    this.handleJunctionUpdate
                );
                this.listenTo(this.loader, 'onAllAssetsLoaded', function (e) {
                    this.junctionData.createCourseData(
                        this.loader.get(this.path),
                        'xml'
                    );
                    this.trigger('onJunctionDataCreated', {
                        target: this,
                        junctionData: this.junctionData,
                    });
                    this.junctionTracking.setup();
                    this.showStartPage();
                    this.createListeners();
                    const content = this.getContent();
                    this.$el.append(content);
                    this.loader = null;
                });
                this.loader.start();
            }
        },

        showStartPage() {
            if (this.startPage) {
                this.junctionController.switchPage(this.startPage);
            } else {
                this.junctionController.routeToHome();
            }
        },

        getData() {
            this.junctionData = this.blockData.junctionData;
            this.junctionData.score = 1;
            this.junctionData.passmark = 1;

            this.junctionController = new JunctionController(
                this.app,
                this.junctionData,
                this.$junctionArea,
                this
            );
            this.junctionTracking = new JunctionTracking(
                this.app,
                this.junctionData,
                this.junctionController
            );
            this.junctionAudio = new JunctionAudio(
                this.app,
                this.junctionController.pageRenderer
            );
        },

        getContent(data) {
            return this.$junctionArea;
        },

        destroy() {
            if (this.loader) this.loader.destroy();

            // TODO:: Destroy listeners in renderer, controller etc.
            this.junctionData.destroy();
            this.junctionController.destroy();
            this.junctionTracking.destroy();

            this.stopListening(this.junctionData, 'onStatusUpdated');

            Block.prototype.destroy.call(this);
        },

        createListeners() {
            this.listenTo(
                this.junctionData,
                'onStatusUpdated',
                this.checkCompletion
            );
        },

        checkCompletion() {
            const status = this.junctionData.getStatus();

            switch (status) {
                case Status.PASSED:
                    this.pass();
                    break;
                case Status.FAILED:
                    this.fail(true);
                    break;
                case Status.COMPLETED:
                    this.complete();
                    break;
            }
        },

        handleJunctionUpdate(pageData) {
            const status = this.junctionData.getStatus();
            if (status >= Status.COMPLETED) {
                this.checkCompletion();
            }
        },

        handlePageSuspended(e) {
            if (!this.junctionController) return;

            const pageData = this.junctionController.currentPageData;
            this.junctionController.pageRenderer.suspendPage(pageData);
        },

        handlePageRestored(e) {
            if (!this.junctionController) return;

            const pageData = this.junctionController.currentPageData;
            this.junctionController.pageRenderer.restorePage();
        },
    },
    {
        JUNCTION_CLASS: 'Junction',
    }
);

export default Junction;
