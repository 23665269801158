import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import generalStyles from '../General.module.css';
import styles from './BoxBackground.module.css';
import { convertRange } from '~templates/utils';

function BoxBackground({ data }) {
    const boxRef = useRef();

    useEffect(() => {
        const wrapper = document.getElementById('wrapper');
        const callback = () => {
            const height = convertRange(wrapper.scrollTop, [0, 150], [1, 0]);
            boxRef.current.style.setProperty('transform', `scaleY(${height})`);

            // Keeps box moving with the scroll. Doesn't work well with pages taller than viewport
            // boxRef.current.style.setProperty('transform', `translateY(-${wrapper.scrollTop}px)`);
        };

        wrapper.addEventListener('scroll', callback);

        return () => {
            wrapper.removeEventListener('scroll', callback);
        };
    });

    return (
        <div className={classNames(generalStyles.fill, styles.background)}>
            <div ref={boxRef} className={styles.box}/>
        </div>
    );
}

export default BoxBackground;
