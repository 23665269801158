import React, { Component } from 'react';
import { Badge, Button } from 'react-bootstrap';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { LanguageManager } from '~core';

/**
 * @augments Component
 * @memberOf ReactComponent
 */
class CarouselControls extends Component {
    /**
     * @param props
     */
    constructor(props) {
        super(props);
        this.labels = {
            nextButton: LanguageManager.getString('next'),
            previousButton: LanguageManager.getString('previous'),
        };
    }

    /**
     * @returns {ReactNode}
     */
    render() {
        return (
            <div className="carousel-controls d-flex justify-content-between mb-2">
                <Button
                    className="previous"
                    aria-label={this.labels.previousButton}
                    title={this.labels.previousButton}
                    onClick={this.props.slidePrevious}
                    disabled={this.props.reference.state.active == 0}
                >
                    <MdChevronLeft />
                </Button>
                <div className="carousel-progress-dots d-flex align-items-center">
                    {this.props.reference.data.items.map((item, key) => (
                        <Badge
                            key={key}
                            pill
                            as="button"
                            tabIndex="0"
                            className={`dot mx-1 ${
                                this.props.reference.isSlideViewed(key)
                                    ? 'viewed'
                                    : ''
                            } ${
                                this.props.reference.state.active === key
                                    ? 'active'
                                    : ''
                            }`}
                            onClick={() => this.props.reference.goto(key)}
                        >
                            &nbsp;
                        </Badge>
                    ))}
                </div>
                <Button
                    className="next"
                    onClick={this.props.slideNext}
                    aria-label={this.labels.nextButton}
                    title={this.labels.nextButton}
                    disabled={
                        this.props.reference.state.active ==
                        this.props.reference.data.items.length - 1
                    }
                >
                    <MdChevronRight />
                </Button>
            </div>
        );
    }
}

export default CarouselControls;
