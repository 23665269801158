import React from 'react';
import ReactDOM from 'react-dom';
import { Button, Modal } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import Component from '~coresrc/component/component.js';

class ModalBox extends React.Component {
    constructor(props) {
        super(props);
        this.session = container.session;
        this.state = {
            isShow: true,
            title: LanguageManager.getString('warning_title'),
            message: LanguageManager.getString('warning_message'),
            exitButton: LanguageManager.getString('warning_exit_button'),
        };

        this.message = this.state.message
            .split('[[browser]]')
            .join(`<font color='#FF0000'>${this.props.browser}`);
        this.message = this.message
            .split('[[version]]')
            .join(`${this.props.version}</font>`);
        this.message +=
            "<img class='w-100' src='assets/images/supported_browsers.jpg'>";

        this.handleClose = this.handleClose.bind(this);
        this.handleCloseWindow = this.handleCloseWindow.bind(this);
    }

    async handleClose() {
        await this.setState({ isShow: false });
    }

    handleCloseWindow() {
        window.top.close();
    }

    render() {
        return (
            <div>
                <Modal show={this.state.isShow} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {ReactHtmlParser(this.state.title)}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{ReactHtmlParser(this.message)}</Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="primary"
                            onClick={this.handleCloseWindow}
                        >
                            {this.state.exitButton}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

const WarningNotification = Component.extend({
    initialize() {
        this.name = 'WarningNotification';
    },

    setup(container) {
        this.container = container;
        this.container.on('onLoadComplete', this.start, this);
    },

    start() {
        const browser = BrowserDetect.browser;
        const version = BrowserDetect.version;

        const component = (
            <ModalBox browser={browser} version={version}></ModalBox>
        );

        this.$notification = $("<div id='warning-notification' />");
        this.container.dom.prepend(this.$notification);

        if ((browser == 'Explorer' || browser == 'IE') && version <= 10) {
            ReactDOM.render(component, this.$notification[0]);
        }
        // ReactDOM.render(component, this.$notification[0]);
    },
});

export default WarningNotification;
