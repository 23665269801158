import React from 'react';
import Backbone from 'backbone';
import { MdSort } from 'react-icons/md';
import { Table } from 'react-bootstrap';
import AdminBase from './AdminBase';
import AdminNavBar from './AdminNavBar';

/**
 * @memberOf ReactBlocks
 * @schema json schema
 */

class AdminTemplates extends AdminBase {
    /**
     * @param props
     */
    constructor(props) {
        super(props);
        this.data = this.props.blockRef.data;
        this.app = this.props.blockRef.app;
        this.state = this.getCourseData();
        this.isTemplateAscending = false;
        this.isInstancesAscending = false;

        this.app.courseData.modules.each((module) => {
            Backbone.listenTo(
                module,
                'onStatusUpdate',
                this.handleUpdate.bind(this)
            );
            Backbone.listenTo(module, 'onReset', this.handleUpdate.bind(this));
        });
    }

    handleUpdate() {
        this.setState(this.getCourseData());
    }

    /**
     * Completes the screen.
     */
    componentDidMount() {
        this.props.blockRef.complete();
        // this.sortByTemplate();
        // this.sortByTemplate();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    sortByTemplate() {
        if (this.state.sortedByAscending === true) {
            // Sort descending
            this.isTemplateAscending = false;

            const sortedDescending =
                this.state.course.templates.pageTemplateCounts.sort((a, b) =>
                    a.name.localeCompare(b.name)
                );

            // debugger;

            this.setState({ templates: sortedDescending });
        } else {
            // Sort ascending
            this.isTemplateAscending = true;
            const sortedAscending =
                this.state.course.templates.pageTemplateCounts.sort((a, b) =>
                    b.name.localeCompare(a.name)
                );

            this.setState({ templates: sortedAscending });
        }
        this.state.sortedByAscending = !this.state.sortedByAscending;
    }

    sortByInstance() {
        if (this.state.sortedByAscending === true) {
            // Sort descending
            this.isInstancesAscending = false;
            const sortedDescending =
                this.state.course.templates.pageTemplateCounts.sort(function (
                    a,
                    b
                ) {
                    return parseFloat(b.quantity) - parseFloat(a.quantity);
                });

            this.setState({ templates: sortedDescending });
        } else {
            // Sort ascending
            this.isInstancesAscending = true;
            const sortedAscending =
                this.state.course.templates.pageTemplateCounts.sort(function (
                    a,
                    b
                ) {
                    return parseFloat(a.quantity) - parseFloat(b.quantity);
                });

            this.setState({ templates: sortedAscending });
        }
        this.state.sortedByAscending = !this.state.sortedByAscending;
    }

    /**
     * @returns {ReactNode}
     */
    render() {
        const templateTableHead = (
            <tr className="text-light bg-dark">
                <th
                    className={`text-center border-0 ${
                        this.isTemplateAscending ? 'ascending' : 'descending'
                    }`}
                >
                    TEMPLATE{' '}
                    <MdSort
                        className="mx-2"
                        onClick={() => {
                            this.sortByTemplate();
                        }}
                    />
                </th>
                <th
                    className={`text-center border-0 ${
                        this.isInstancesAscending ? 'ascending' : 'descending'
                    }`}
                >
                    INSTANCES{' '}
                    <MdSort
                        className="mx-2"
                        onClick={() => {
                            this.sortByInstance();
                        }}
                    />
                </th>
                <th className="text-center border-0">
                    PAGE ID(S)
                    {/* <MdSort
                        className='mx-2'
                        onClick={() => {
                            this.sortByInstance();
                        }}
                    /> */}
                </th>
            </tr>
        );

        const templateTableRows =
            this.state.course.templates.pageTemplateCounts.map(
                (template, i) => {
                    // console.log("🐸🐸🐸");
                    // console.log(template);
                    const pageIDs = [];
                    template.pageID.forEach((pageID) => {
                        const link = `#page/${pageID}`;
                        if (template.pageID.length == 1) {
                            pageIDs.push([<a href={link}>{`${pageID} `}</a>]);
                        } else {
                            pageIDs.push([<a href={link}>{`${pageID}, `}</a>]);
                        }
                    });

                    return (
                        <tr key={i.toString()}>
                            <td className="text-center">{template.name}</td>
                            <td className="text-center">{template.quantity}</td>
                            <td className="text-center">{pageIDs}</td>
                        </tr>
                    );
                }
            );

        return (
            <div className="admin">
                <AdminNavBar />
                <div className="m-5">
                    <Table responsive="sm">
                        <thead>{templateTableHead}</thead>
                        <tbody>{templateTableRows}</tbody>
                    </Table>
                </div>
            </div>
        );
    }
}

export default AdminTemplates;
