import * as ToggleGroup from '@radix-ui/react-toggle-group';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useDebugValue, useEffect, useMemo, useRef, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import TweenMax from 'gsap/TweenMaxBase';
import TimelineMax from 'gsap/TimelineMax';
import BaselineRaiseText from '../../components/BaselineRaiseText';
import Button from '../../components/Button';
import Scarf from '../../components/Scarf';
import AvatarPillGroup from '../../components/AvatarPillGroup';
import useRefArray from '../../hooks/useRefArray';
import { getData } from './Quiz.helpers';
import styles from './Quiz.module.css';
import { t } from '~templates/utils/i18n';
import useRoomQuizSync from '~m/RoomSync/hooks/useRoomQuizSync';
import { Status } from '~core';
import { useBlock } from '~blocks/index';
import { useRoomSync } from '~m/RoomSync';
import useRoomParamMirror from '~m/RoomSync/hooks/useRoomParamMirror';
import useRoomMembers from '~m/RoomSync/hooks/useRoomMembers';
import usePermitted from '~m/RoomSync/hooks/usePermitted';

/** @template T @typedef { import("./Quiz.types").UseState<T> } UseState */
/** @typedef { import("./Quiz.types").QuizOption } QuizOption */

function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
}

function useTrueForInstructor(value) {
    const roomSync = useRoomSync();
    return useMemo(() => {
        if (roomSync.isInstructor) return true;
        return value;
    }, [roomSync.isInstructor, value]);
}

function Quiz() {
    const [optionsRef, optionRefsProp] = useRefArray();
    const buttonRef = useRef();

    // useEffect(() => {
    // //    itemsRef.current = itemsRef.current.slice(0, props.items.length);

    //     const tl = new TimelineMax({ delay: 1 });

    //     tl.staggerFromTo(
    //         optionsRef.current,
    //         0.7,
    //         {
    //             y: 30,
    //             opacity: 0,
    //         },
    //         {
    //             y: '0',
    //             opacity: 1,
    //         },
    //         0.5,
    //     );
    // }, []);

    const [value, setValue] = useState([]);
    const { block: blockRef } = useBlock({ instantComplete: true });
    const { data, initialOptions } = getData(blockRef);
    const roomSync = useRoomSync();
    const [isAnswered, setIsAnswered] = useState(false);
    const [resultsViewable, setResultsViewable] = useState(false);

    const { isInstructor } = usePermitted();

    useRoomParamMirror('resultsViewable', resultsViewable, setResultsViewable);

    const selectedOption = useMemo(() => {
        if (!isAnswered) return;
        return value;
    }, [isAnswered, value]);

    const {
        results: remoteResults,
        answeredCount: remoteAnsweredCount,
        commitResults,
        committedResults,
        updateInClassValue,
        inClassValues,
        inClassTotal,
    } = useRoomQuizSync(selectedOption, {
        convertToSingleAnswer: true,
    });

    const results = useMemo(() => {
        if (isInstructor) return remoteResults;
        return committedResults ?? remoteResults;
    }, [remoteResults, committedResults]);

    const answeredCount = useMemo(() => {
        if (committedResults) {
            return Object.entries(committedResults).reduce((partialSum, [, a]) => partialSum + a, 0);
        }
        return remoteAnsweredCount;
    }, [remoteAnsweredCount, committedResults]);

    const showingResultsInstructor = useTrueForInstructor(resultsViewable);
    const showingResults = useMemo(() => {
        if (showingResultsInstructor) return true;
        if (committedResults !== undefined) return true;
        return false;
    }, [showingResultsInstructor, committedResults]);

    const selectionDisabledInstructor = useTrueForInstructor(isAnswered);
    const selectionDisabled = useMemo(() => {
        if (selectionDisabledInstructor) return true;
        if (showingResults) return true;
        return false;
    }, [selectionDisabledInstructor, showingResults]);

    useDebugValue('showingResults', () => showingResults);

    /** @type {UseState<QuizOption[]>} */
    const [options, setOptions] = useState(initialOptions);

    const { question, instruction, isSingleSelect } = data;

    const toggleGroupValue = useMemo(() => isSingleSelect ? value[0] : value, [value]);
    const questionType = useMemo(() => isSingleSelect ? 'single' : 'multiple', [isSingleSelect]);

    const getResult = () => {
        const totalCorrectCount = data.correctOptions.length;

        if (totalCorrectCount === 0) return 'partial';

        const selectedOptions = options.filter(item => item.isSelected);
        const correctCount = selectedOptions.filter(o => o.isCorrect).length;

        const correct = correctCount === totalCorrectCount && correctCount === selectedOptions.length;

        if (correct) {
            return 'correct';
        } else if (correctCount === 0) {
            return 'incorrect';
        } else {
            return 'partial';
        }
    };

    const handleConfirmClick = () => {
        if (committedResults) return;

        const result = getResult();
        const feedback = data.feedback[result];

        setIsAnswered(true);
        setOptions(options);

        blockRef.trigger('onSubmitAnswer', {
            status: result === 'correct' ? Status.PASSED : Status.FAILED,
            selectedOptions: options.filter(o => o.isSelected).map((v, i) => i),
        });

        blockRef.setStatus(
            result === 'correct' ? Status.PASSED : Status.FAILED,
        );
    };

    const updateSelection = (newValue) => {
        if (committedResults) return;
        setValue(Array.isArray(newValue) ? newValue : [newValue]);
    };

    const imageRef = useRef();

    useEffect(() => {
        new TimelineMax()
            .from(imageRef.current, 0.5, { y: 25, opacity: 0 })
            .delay(0.1)
            .staggerFrom([...optionsRef.current, buttonRef.current], 0.5, { y: 50, opacity: 0 }, 0.2);
    }, []);

    return (
        <Scarf variant="centred" background="ring">
            <div className={styles.content}>
                {/* <div className={styles.header}>
                    <BaselineRaiseText>How much do you know?</BaselineRaiseText>
                    <p>Select an answer on your device then select &apos;Submit&apos;. If you&apos;re not using your own device, follow the facilitator&apos;s instructions to indicate your response.</p>
                </div> */}
                <div className={styles.choiceBox}>
                    <img ref={imageRef} className={styles.image} src="assets/images/misc/question_bubble.png" />
                    <BaselineRaiseText
                        as="h1"
                        className={styles.question}
                        duration={0.7}
                        stagger={0.02}
                    >
                        {question}
                    </BaselineRaiseText>
                    {
                        isInstructor
                            ? (
                                <div className={styles.waitingResponse} ref={optionRefsProp}>
                                    <div>{t('awaiting_response')}</div>
                                    <AvatarPillGroup collection={results?.NO_RESPONSE} />
                                </div>
                            )
                            : (
                                <p
                                    ref={optionRefsProp}
                                    className={classNames(styles.instruction, {
                                        [styles.showingResults]: showingResults,
                                    })}
                                >{instruction}</p>
                            )
                    }
                    <ToggleGroup.Root
                        className={styles.choices}
                        type={questionType}
                        value={toggleGroupValue}
                        onValueChange={updateSelection}
                        disabled={selectionDisabled}
                    >
                        {options.map((option, index) => {
                            const members = results[option.id] ?? 0;
                            const number = (typeof members === 'number' ? members : members.length) + (inClassValues?.[option.id] ?? 0);
                            const percentage = Math.round(number / (answeredCount + inClassTotal) * 100 || 0);
                            return (
                                <ToggleGroup.Item
                                    ref={optionRefsProp}
                                    key={option.id}
                                    value={option.id.toString()}
                                    className={classNames(styles.choice, {
                                        [option.isCorrect ? styles.correct : styles.incorrect]: showingResults,
                                        [styles.showingResults]: showingResults,
                                    })}
                                >
                                    <div className={showingResults ? styles.titleContainer : ''}>{option.title}</div>
                                    <div className={styles.resultPercentage} style={{ width: showingResults ? `${percentage ?? 0}%` : 0 }} />
                                    {isInstructor && (
                                        <div className={styles.pillWrapper}>
                                            <AvatarPillGroup total={answeredCount} collection={members} className={styles.avatarWrapper} percentage={percentage}/>
                                            <div className={styles.inClassInputWrapper}>
                                                <label>{t('instructor_input')}</label>
                                                <input
                                                    className={classNames(styles.inClassInput, {
                                                        [styles.inClassInputDisabled]: committedResults !== undefined,
                                                    })}
                                                    type="number"
                                                    value={inClassValues?.[option.id] ?? 0}
                                                    onChange={(event) => {
                                                        if (committedResults !== undefined) return;
                                                        updateInClassValue(option.id, parseInt(event.target.value || '0'));
                                                    }}
                                                    readOnly={committedResults !== undefined}
                                                    min={0}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {showingResults && !isInstructor && (
                                        <AvatarPillGroup total={answeredCount} percentage={percentage} className={styles.avatarWrapper}/>
                                    )}
                                </ToggleGroup.Item>
                            );
                        })}
                    </ToggleGroup.Root>

                    <div ref={buttonRef} className={styles.buttonGroup}>
                        {
                            roomSync.isInstructor
                                ? (
                                    <Button
                                        size="lg"
                                        disabled={committedResults !== undefined}
                                        onClick={commitResults}
                                    >
                                        {t('show_results')}
                                    </Button>
                                )
                                : (
                                    <Button
                                        size="lg"
                                        disabled={value.length === 0 || isAnswered}
                                        onClick={handleConfirmClick}
                                    >
                                        {t('confirm')}
                                    </Button>
                                )
                        }
                    </div>
                </div>
            </div>
        </Scarf>
    );
}

Quiz.propTypes = {
    property: PropTypes.string,
    blockRef: PropTypes.any.isRequired,
};

Quiz.defaultProps = {
    property: undefined,
};

export default Quiz;
