import useContainerPlugin from '../../../hooks/useContainerPlugin';

/**
 * @returns {import('../RoomSync').default}
 */
function useRoomSync() {
    return useContainerPlugin('RoomSync');
}

export default useRoomSync;
