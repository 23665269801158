import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDocument } from 'react-firebase-hooks/firestore';
import Button from '../../../components/Button';
import Scarf from '../../../components/Scarf';
import useBinding from '../../../hooks/useBinding';
import { useRoomSync } from '../../../modules/RoomSync';
import params from '../../../utils/params';
import Slider from '../../../blocks/CourseMap/components/Slider';
import useAsyncMemo from '../../../hooks/useAsyncMemo';
import styles from './LaunchForLearner.module.css';
import { Routes } from '~m/RoomSync/RoomSync.contants';
import { useDocumentReference, useFirebase } from '~m/Firebase';
import { ErrorText } from '~m/RoomSync/RoomSync.errors';
import { useBlock } from '~templates/components/blocks';
import { convertRange } from '~templates/utils';

/**
 * @param panels
 */
function transformPanels(panels) {
    panels.forEach((panel) => {
        const scale = convertRange(panel.progress, [0, 1], [1, 0.8]);
        panel.element.style.setProperty('transform', `scale(${scale})`);
        const opacity = convertRange(panel.progress, [0, 1], [1, 0.4]);
        panel.element.style.setProperty('opacity', opacity);

        if (Math.abs(panel.progress) < 0.5) {
            const shadow = convertRange(panel.progress, [0, 0.5], [4, 0]);
            panel.element.firstChild.style.boxShadow = `0 0 0 ${shadow / 2}px #fff, 0 0 0 ${shadow}px rgb(0 103 155)`;
        } else {
            panel.element.firstChild.style.boxShadow = '';
        }
    });
}

function LaunchForLearner(props) {
    const { onLaunch } = props;
    /** @type {{data: import('../LaunchPage.types').LaunchSchema}} */
    const { data, container } = useBlock();
    /** @type {{ languages: Array<{ code: string, text: string }> }} */
    const { languages } = data;
    // const { container } = useBlock();
    /**
     * @type {import('react').MutableRefObject<{
     *   flicking: import('react').MutableRefObject<import('@egjs/flicking').default>,
     *   setCurrentIndex: (index: number, duration?: number) => void
     * }>}
     */
    const flickingRef = useRef();
    const roomSync = useRoomSync();
    const firebase = useFirebase();

    const [userSnapshot, loading] = useDocument(
        useDocumentReference(Routes.learners.GET(roomSync.firebase.user.uid)),
    );

    const [language, setLanguage] = useState(languages[0].code);

    const languageUrl = useMemo(() => `assets/data/course_${language}/global/language.json`, [language]);

    const [languageData] = useAsyncMemo(async () => fetch(languageUrl).then(r => r.json()), [languageUrl]);

    const t = useCallback(key => languageData?.items.find(item => item.key === key)?.value, [languageData]);

    const [firstName, setFirstName, onFirstNameChange] = useBinding('');
    const [lastName, setLastName, onLastNameChange] = useBinding('');
    const [region, setRegion, onRegionChange] = useBinding('');
    const [email, setEmail, onEmailChange] = useBinding('');
    const [internalRoomCode, , onRoomCodeChange] = useBinding(params.code ?? '');
    const roomCode = useMemo(() => internalRoomCode.trim(), [internalRoomCode]);

    useEffect(() => {
        if (loading) return;

        const {
            firstName = '',
            lastName = '',
            region = '',
            email = '',
            avatar = 0,
        } = userSnapshot?.data() ?? {};

        setFirstName(firstName);
        setLastName(lastName);
        setRegion(region);
        setEmail(email);
        flickingRef.current?.setCurrentIndex(avatar, 0);
    }, [loading]);

    const [error, setError] = useState('');
    const [pending, setPending] = useState(false);

    const handleJoinSession = async (event) => {
        event.preventDefault();

        setError('');

        if (!firstName || !lastName) {
            setError(t('required_first_last_name'));
            return;
        }

        if (!roomCode) {
            setError(t('required_room_code'));
            return;
        }

        if (!region) {
            setError(t('required_region'));
            return;
        }

        if (!email) {
            setError(t('required_email'));
            return;
        }

        if (pending) return;

        setPending(true);

        const userData = {
            firstName,
            lastName,
            region,
            email,
            avatar: flickingRef.current.flicking.index,
        };

        container.componentList.Diagnostics.start({ emailAddress: email });

        await roomSync.learnerUpdateData(userData);

        try {
            const room = await roomSync.learnerJoinRoom(roomCode);
            await roomSync.roomManager.updateMemberData(firebase.user.uid, userData);
            const data = { language: room.language, page: room.page, division: room.division };
            onLaunch(data);
        } catch (error) {
            if (error.code === 'permission-denied') {
                setError(t('room_join_error'));
            } else {
                setError(t(error.code));
            }

            setPending(false);
        }
    };

    const roomSnapshotPath = useMemo(() => roomCode ? Routes.rooms.GET(roomCode) : undefined, [roomCode]);

    const [roomSnapshot] = useDocument(
        useDocumentReference(roomSnapshotPath),
    );

    const roomExists = useMemo(() => roomSnapshot?.exists(), [roomSnapshot]);
    const roomLanguageCode = useMemo(() => roomSnapshot?.data()?.language ?? 'en', [roomSnapshot]);
    const roomLanguage = useMemo(() => languages?.find(l => l.code === roomLanguageCode), [roomLanguageCode]);

    useEffect(() => setLanguage(roomLanguageCode), [roomLanguageCode]);

    return (
        <Scarf variant="centred" fullHeight>
            <form className={styles.card} onSubmit={handleJoinSession}>
                <div className={styles.header}>
                    <h1>{t('course_title')}</h1>
                </div>
                <div className={classNames(styles.cardContent, { [styles.loading]: loading })}>
                    <div className={styles.roomCodeWrapper}>
                        <input
                            value={roomCode}
                            onChange={onRoomCodeChange}
                            type="text"
                            placeholder={roomCode ? t('room_code') : 'Room code / Code de salle/ Código de sala'}
                            required
                            disabled={pending}
                        />
                        {roomExists && (
                            <div className={styles.langPill}>
                                <span>{roomLanguage.text}</span>
                            </div>
                        )}
                    </div>

                    <div className={styles.inputs}>
                        <input
                            value={firstName}
                            onChange={onFirstNameChange}
                            type="text"
                            placeholder={t('first_name')}
                            required
                            disabled={pending}
                        />
                        <input
                            value={lastName}
                            onChange={onLastNameChange}
                            type="text"
                            placeholder={t('last_name')}
                            required
                            disabled={pending}
                        />
                        <select
                            value={region}
                            onChange={onRegionChange}
                            required
                            disabled={pending}
                        >
                            <option value="" disabled>{t('select_region')}</option>
                            {data.regions.map((region) => {
                                return <option key={region.code} value={region.text}>{region.text}</option>;
                            })}
                        </select>
                    </div>

                    <div className={styles.inputs}>
                        <input
                            value={email}
                            onChange={onEmailChange}
                            type="email"
                            placeholder={t('email_address')}
                            required
                            disabled={pending}
                        />
                    </div>

                    <h2>{t('choose_avatar')}</h2>
                    <div className={styles.avatarSelectionWrapper}>
                        <Slider ref={flickingRef} panelTransformer={transformPanels} showControlButtons disabled={pending}>
                            {[...Array(20)].map((_, index) => (
                                <div key={index} className={styles.avatarWrapper} onClick={() => {
                                    flickingRef.current.setCurrentIndex(index);
                                }}>
                                    <img className={styles.avatar} src={`assets/images/avatars/${index}.png`} />
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
                <div>
                    {error}
                </div>
                <div className={styles.buttonWrapper}>
                    <Button size="xl" type="submit" disabled={pending} loading={pending}>{t('join_session')}</Button>
                </div>
            </form>
        </Scarf>
    );
}

LaunchForLearner.propTypes = {
    onLaunch: PropTypes.func.isRequired,
};

export default LaunchForLearner;
