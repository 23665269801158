import React from 'react';
import Backbone from 'backbone';
import { Button, Table } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import {
    ClockHistory,
    DashCircleFill,
    ExclamationSquareFill,
    PersonCheckFill,
    PlusCircleFill,
} from 'react-bootstrap-icons';
import AdminNavBar from './AdminNavBar';
import AdminBase from './AdminBase';
import StringToColor from '~coresrc/utils/string/StringToColor';
import AssetLoader from '~coresrc/loader/assetloader';

// Get a new color for each string

/**
 * @memberOf ReactBlocks
 * @schema json schema
 */

class AdminPages extends AdminBase {
    /**
     * @param props
     */
    constructor(props) {
        super(props);
        this.data = this.props.blockRef.data;
        this.app = this.props.blockRef.app;

        this.currentPage = React.createRef();

        this.state = {
            expandedJunctions: [container.session.previousPageData.pageID()],
            fileLoadErrors: [],
            ...this.getCourseData(),
        };

        this.app.courseData.modules.each((module) => {
            Backbone.listenTo(
                module,
                'onStatusUpdate',
                this.handleUpdate.bind(this)
            );
            Backbone.listenTo(module, 'onReset', this.handleUpdate.bind(this));
        });
    }

    handleUpdate() {
        this.setState(this.getCourseData());
    }

    handleReset(e) {
        // debugger;
        // var $resetInput = $(document).find('#reset-page');
        const pageID = e.currentTarget.value;
        if (pageID === '') {
            alert('Enter a page number');
        } else {
            container.storage.clearProgress(pageID);
        }
    }

    /**
     * Completes the screen.
     */
    componentDidMount() {
        this.props.blockRef.complete();
        this.currentPage.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });

        const xmlLoader = new AssetLoader({ name: 'fileloader' });

        xmlLoader.bind('onAssetLoaded', (e) => {
            if (e.loader.error) {
                // console.warn('AssetLoader.XML failed: ', e.progress, e.resource.url);

                // Add to array
                this.setState({
                    fileLoadErrors: [
                        ...this.state.fileLoadErrors,
                        e.resource.url,
                    ],
                });
            } else {
                // console.info('AssetLoader.XML loaded: ', e.progress, e.resource.url);
            }
        });

        // xmlLoader.bind('onAllAssetsLoaded', (e) => {
        //     console.info('AssetLoader.XML DONE: ', e);
        // });

        // xmlLoader.bind('onAssetError', (e) => {
        //     console.info('AssetLoader.XML error: ', e);
        // });

        // xmlLoader.bind('onAssetTimeout', (e) => {
        //     console.info('AssetLoader.XML error: ', e);
        // });

        this.state.course.pages.forEach((page) => {
            // console.info('looping.pages', page);

            page.pageBlocks.forEach((block) => {
                const url = block.blockXML();
                xmlLoader.addXML(url);
            });
        });

        // console.info('AssetLoader.XML length: ', xmlLoader.assetList.length);
        xmlLoader.start();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    /**
     * @returns {ReactNode}
     */
    render() {
        Logger.info('Errors', this.state.fileLoadErrors);
        const pageTableHead = (
            <tr className="text-light bg-dark">
                <th className="text-center border-0">ID</th>
                <th className="text-center border-0">STATUS</th>
                <th className="text-center border-0">MODULE NO.</th>
                <th className="text-center border-0">PAGE ID</th>
                <th className="text-center border-0">TITLE</th>
                <th className="text-center border-0">BLOCKS ON PAGE</th>
                <th className="text-center border-0">TEMPLATE(S)</th>
                <th className="text-center border-0">COMPLETION REQUIRED</th>
                <th className="text-center border-0">ARGUMENTS</th>
                <th className="text-center border-0">RESET</th>
            </tr>
        );

        const pageTableRows = this.state.course.pages.map((page, i) => {
            const classes = [page.isInJunction ? 'junction-page' : ''];
            if (page.pageId === container.session.previousPageData.pageID())
                classes.push('current');

            const pageType = getPageCssClass(page);

            // eslint-disable-next-line jsdoc/require-returns
            /**
             * @param page
             */
            function getPageCssClass(page) {
                if (page.isJunction === true) return 'page-junction';
                if (page.isInJunction === true) return 'page-in-junction';

                return 'page-top';
            }
            const completionRequired =
                page.pageCompletionRequired === true ? (
                    <PersonCheckFill size="25" className="text-success" />
                ) : (
                    <PersonCheckFill size="25" style={{ opacity: 0.2 }} />
                );
            const completionRequiredTitle =
                page.pageCompletionRequired === true
                    ? 'Completion required'
                    : 'Completion not required';

            const showRow =
                page.isInJunction === false ||
                (page.isInJunction &&
                    this.state.expandedJunctions.includes(page.parentJunction))
                    ? ''
                    : 'none';

            return (
                <tr
                    title={`row ${i}`}
                    key={`row_${i}`}
                    className={classes.join(' ')}
                    ref={
                        page.pageId ===
                        container.session.previousPageData.pageID()
                            ? this.currentPage
                            : null
                    }
                    style={{
                        display: showRow,
                    }}
                >
                    <td className={pageType}>
                        {page.isJunction === true && (
                            <Button
                                onClick={() => {
                                    if (
                                        this.state.expandedJunctions.includes(
                                            page.pageId
                                        )
                                    ) {
                                        // Remove from array
                                        this.setState({
                                            expandedJunctions:
                                                this.state.expandedJunctions.filter(
                                                    (id) => id !== page.pageId
                                                ),
                                        });
                                    } else {
                                        // Add to array
                                        this.setState({
                                            expandedJunctions: [
                                                ...this.state.expandedJunctions,
                                                page.pageId,
                                            ],
                                        });
                                    }
                                }}
                            >
                                {this.state.expandedJunctions.includes(
                                    page.pageId
                                ) ? (
                                    <DashCircleFill />
                                ) : (
                                    <PlusCircleFill />
                                )}
                            </Button>
                        )}
                    </td>
                    <td className={`_text-center ${page.pageStatusClass}`}>
                        {page.pageStatus}
                    </td>
                    <td className="_text-center">{page.pageModuleNumber}</td>
                    <td className="_text-center">
                        <Button
                            bsPrefix="none"
                            as="a"
                            variant="link"
                            className="bg-transparent text-primary"
                            style={{
                                cursor: 'pointer',
                            }}
                            onClick={(e) => {
                                if (e.ctrlKey) {
                                    window.open(`?startPage=${page.pageId}`);
                                } else {
                                    container.router.navigate(
                                        `#page/${page.pageId}`,
                                        {
                                            trigger: true,
                                            replace: true,
                                        }
                                    );
                                }
                            }}
                        >
                            {page.pageId}
                        </Button>
                        <br />
                        <small
                            style={{
                                color: 'white',
                                padding: '0.1em 0.2em',
                                backgroundColor: StringToColor.next(
                                    page.pageModuleNumber
                                ),
                            }}
                        >
                            {page.pageModuleNumber}
                        </small>
                    </td>
                    <td className="_text-center">{page.pageTitle}</td>
                    <td className="text-center">{page.pageBlocks.length}</td>
                    <td className="text-center">
                        {page.pageBlocks.map((block, n) => (
                            <div key={`block_${i}_${n}`}>
                                <OverlayTrigger
                                    trigger={['hover', 'focus']}
                                    placement="top"
                                    key={block.get('id')}
                                    overlay={
                                        <Tooltip
                                            id={`popover-positioned-${block.get(
                                                'id'
                                            )}`}
                                            style={{
                                                // width: '800px',
                                                maxWidth: 'none',
                                            }}
                                        >
                                            {block.get('blockXML')}
                                        </Tooltip>
                                    }
                                >
                                    {this.state.fileLoadErrors.includes(
                                        block.get('blockXML')
                                    ) ? (
                                        <Button
                                            size="sm"
                                            className="m-1"
                                            variant="danger"
                                            onClick={() => {
                                                window.open(
                                                    block.get('blockXML')
                                                );
                                            }}
                                        >
                                            <ExclamationSquareFill /> file not
                                            found / {block.get('template')}
                                        </Button>
                                    ) : (
                                        <Button
                                            size="sm"
                                            className="m-1"
                                            variant="light"
                                            onClick={() => {
                                                window.open(
                                                    block.get('blockXML')
                                                );
                                            }}
                                        >
                                            {block.get('template')}
                                        </Button>
                                    )}
                                </OverlayTrigger>
                                <br />
                            </div>
                        ))}
                    </td>
                    <td className="text-center" title={completionRequiredTitle}>
                        {completionRequired}
                    </td>
                    <td className="text-center">{page.pageArguments}</td>
                    <td className="text-center">
                        <Button
                            style={{ textAlign: 'center' }}
                            onClick={() => {
                                this.handleReset();
                            }}
                            title="Reset"
                            value={module.id}
                        >
                            <ClockHistory size={20} />
                        </Button>
                    </td>
                </tr>
            );
        });

        return (
            <div className="admin">
                <AdminNavBar />
                <div className="">
                    <Table responsive="sm">
                        <thead>{pageTableHead}</thead>
                        <tbody>{pageTableRows}</tbody>
                    </Table>
                </div>
            </div>
        );
    }
}

export default AdminPages;
