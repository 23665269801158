import $ from 'jquery';
import { PxLoader } from './PxLoader';

function PxLoaderXML(url, tags, priority) {
    const self = this;
    let loader = null;

    this.tags = tags;
    this.priority = priority;
    this.url = url;

    this.start = function (pxLoader) {
        loader = pxLoader;

        $.ajax({
            url,
            dataType: 'text',
            // cache:(container != undefined) ? container.isCached : false,
            success(data) {
                let s = data;

                // Wrap content of certain tags with CDATA so that HTML is preserved
                const htmlTags = ['text', 'feedback', 'dragLabel', 'label'];
                for (let i = 0; i < htmlTags.length; i++) {
                    s = s.replace(
                        new RegExp(
                            `(<${htmlTags[i]} type=['"]XHTML['"]>)([\\s\\S]*?)(</${htmlTags[i]}>)`,
                            'g'
                        ),
                        '$1<![CDATA[$2]]>$3'
                    );
                }

                // Repalce div tags with p tags
                s = s.split('<div').join('<p').split('div>').join('p>');

                // Remove empty spans
                s = s.replace(/<span\b[^>]*>(\s|&nbsp;|\xA0)*<\/span>/g, '');
                // Remove empty paragraphs spaces
                s = s.replace(/<p\b[^>]*>(\s|&nbsp;|\xA0)*<\/p>/g, '');
                // Remove paragraph namespaces
                s = s.replace(
                    /(<p\b[^>]*)(xmlns=["']http:\/\/www.w3.org\/1999\/xhtml["'])/g,
                    '$1'
                );
                // Remove paragraph styles
                s = s.replace(
                    /(<[a-z]+\b[^>]*)(\sstyle\s?=\s?["'][^["']*["'])([^>]*>)/g,
                    '$1$3'
                );

                // Fix local links
                s = s.replace(/(href=["'\s]+)http:\/\/(#|assets\/)/g, '$1$2');

                let xmlDoc;

                if (window.ActiveXObject || 'ActiveXObject' in window) {
                    xmlDoc = new ActiveXObject('Microsoft.XMLDOM');
                    xmlDoc.async = false;
                    xmlDoc.loadXML(s);
                } else {
                    const parser = new DOMParser();
                    xmlDoc = parser.parseFromString(s, 'text/xml');
                }

                self.xml = xmlDoc;

                loader.onLoad(self);
            },
            error() {
                loader.onError(self);
            },
            timeout() {
                loader.onTimeout(self);
            },
        });
    };

    this.getName = function () {
        return this.url;
    };

    this.getXML = function () {
        return self.xml;
    };

    this.parseText = function () {
        let s = this.xmlToString(this.xml);

        if (!s) return;

        s = s.split('<text type="XHTML">').join('<text type="XHTML"><![CDATA[');
        s = s.split('</text>').join(']]></text>');

        s = s
            .split('<feedback type="XHTML">')
            .join('<feedback type="XHTML"><![CDATA[');
        s = s.split('</feedback>').join(']]></feedback>');

        s = s
            .split('<dragLabel type="XHTML">')
            .join('<dragLabel type="XHTML"><![CDATA[');
        s = s.split('</dragLabel>').join(']]></dragLabel>');

        s = s
            .split('<label type="XHTML">')
            .join('<label type="XHTML"><![CDATA[');
        s = s.split('</label>').join(']]></label>');

        s = s.split('<p>&nbsp;</p>').join('');
        s = s.split('<p> </p>').join('');

        if (window.ActiveXObject || 'ActiveXObject' in window) {
            var xmlDoc = new ActiveXObject('Microsoft.XMLDOM');
            xmlDoc.async = false;
            xmlDoc.loadXML(s);
        } else {
            const parser = new DOMParser();
            var xmlDoc = parser.parseFromString(s, 'text/xml');
        }

        this.xml = xmlDoc;
    };

    this.checkStatus = function () {};

    this.onTimeout = function () {};

    this.getName = function () {
        return url;
    };

    this.getXML = function () {
        return self.xml;
    };

    // this.xmlToString = function(xmlData)
    // {
    // 	var xmlString;
    // 	//IE
    // 	if (window.ActiveXObject)
    // 	{
    // 		xmlString = xmlData.xml;
    // 	}
    // 	// code for Mozilla, Firefox, Opera, etc.
    // 	else
    // 	{
    // 		xmlString = (new XMLSerializer()).serializeToString(xmlData);
    // 	}
    //
    // 	$.trim( xmlString )
    //
    // 	// Trace.info("XML.string." + xmlString)
    // 	return xmlString;
    // };
}

PxLoader.prototype.addXML = function (url, tags, priority) {
    const xmlLoader = new PxLoaderXML(url, tags, priority);
    this.add(xmlLoader);

    return xmlLoader;
};

function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export { PxLoaderXML };
