import React, { useContext } from 'react';
import { VideoPlayerContext } from './context.js';
import PropTypes from 'prop-types';

export default function VideoControlSpacer(props) {
    const { className } = props;
    const {
        options: { classes },
    } = useContext(VideoPlayerContext);

    return <div className={className ?? classes?.spacer} />;
}

VideoControlSpacer.propTypes = {
    className: PropTypes.string,
};

VideoControlSpacer.defaultProps = {
    className: undefined,
};
