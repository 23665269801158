import { Power1 } from 'gsap/TweenLite';
import TweenMax from 'gsap/TweenMax';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import usePrevious from '../../../../hooks/usePrevious';
import useTimelineEffect from '../../../../hooks/useTimelineEffect';
import useTimelineMemo from '../../../../hooks/useTimelineMemo';
import generalStyles from '../General.module.css';
import styles from './RingBackground.module.css';

const locations = {
    topRight: { top: '-40vh', left: '60vw' },
    bottomRight: { top: '60vh', left: '60vw' },
    bottomLeft: { top: '60vh', left: '-40vw' },
    topLeft: { top: '-40vh', left: '-40vw' },
};

function RingBackground(props) {
    const {
        data: { location = 'bottomRight' },
    } = props;

    const ringRef = useRef();
    const previousLocation = usePrevious(location);
    const transitionTimeline = useTimelineMemo();

    useEffect(() => {
        TweenMax.set(ringRef.current, locations[location]);
    }, []);

    useEffect(() => {
        // Don't run the fade animation if the target location
        // is the same as the previous location
        if (previousLocation === location) return;

        transitionTimeline.clear()
            // fade out the ring
            .to(ringRef.current, 0.5, { opacity: 0, scale: 0.85 })
            // set the ring to the next location
            .set(ringRef.current, locations[location] ?? locations.bottomRight)
            // fade in the ring
            .to(ringRef.current, 0.5, { opacity: 0.2, scale: 1 });
    }, [location]);

    useTimelineEffect((timeline) => {
        // slowly bounce the ring. Done with two + and
        // one - to maintain the center point of the ring

        timeline
            .to(ringRef.current, 2, { y: '+=25', ease: Power1.easeOut })
            .to(ringRef.current, 4, { y: '-=50', ease: Power1.easeInOut })
            .to(ringRef.current, 2, { y: '+=25', ease: Power1.easeIn });
    }, { repeat: -1 });

    return (
        <div className={generalStyles.fill}>
            <svg
                ref={ringRef}
                viewBox="0 0 100 100"
                xmlns="http://www.w3.org/2000/svg"
                className={styles.ring}
            >
                <circle
                    cx={50}
                    cy={50}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={28.2}
                    r={35.9}
                />
            </svg>
        </div>
    );
}

RingBackground.propTypes = {
    data: PropTypes.shape({
        location: PropTypes.oneOf([
            'topRight',
            'bottomRight',
            'bottomLeft',
            'topLeft',
        ]),
    }),
};

export default RingBackground;
