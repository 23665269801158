import { useMemo } from 'react';
import useRoomSyncParams from './useRoomSyncParams';

/**
 *
 * @param {string} key - Param key name
 * @returns {[any, (newValue: any) => void]}
 */
function useRoomParam(key) {
    const { params, update } = useRoomSyncParams();
    const value = useMemo(() => params?.[key], [params]);
    const setValue = val => update(key, val);
    return [value, setValue];
}

export default useRoomParam;
