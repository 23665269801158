import Backbone from 'backbone';
import { Status } from '~core';

const ModuleSequentialUnlocker = Backbone.Model.extend({
    initialize() {
        this.name = 'ModuleSequentialUnlocker';
    },

    setup(container) {
        this.container = container;
        this.listenTo(this.container, 'onLoadComplete', this.start);

        this.courseData = this.container.courseData;
    },

    start() {
        this.completedModules = [];

        this.courseData.modules.each(function (module) {
            if (module.getModuleStatus() >= Status.COMPLETED) {
                this.completedModules.push(module.id);
            }
        }, this);

        this.unlockNextModule();

        this.listenTo(
            this.container.session,
            'onModuleCompleted',
            this.handleModuleCompleted
        );
    },

    handleModuleCompleted(e) {
        const moduleId = e.target.currentPageData.moduleNumber();
        this.unlockNextModule(moduleId + 1);
    },

    unlockNextModule(moduleId) {
        if (moduleId) {
            this.unlockModule(moduleId);
        } else {
            this.unlockModule(this.completedModules.length);
        }
    },

    unlockModule(moduleId) {
        if (!this.courseData.getModule(moduleId)) return;
        this.courseData.getModule(moduleId).setModuleStatus(Status.AVAILABLE);
    },
});

export default ModuleSequentialUnlocker;
