import React, { forwardRef, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Spinner } from 'react-bootstrap';
import styles from './Button.module.css';

const variants = [
    'primary',
    'light',
    'danger',
];

const sizes = [
    'xs',
    'sm',
    'md',
    'lg',
    'xl',
];

/**
 * @type {import('react').ForwardRefExoticComponent<import('./Button.types').ButtonProps>}
 */
const Button = forwardRef((props, ref) => {
    const {
        children,
        className,
        onClick,
        title,
        variant,
        size,
        icon,
        disabled,
        href,
        clickable,
        loading,
        ...rest
    } = props;

    const classes = useMemo(() => classNames(
        className,
        styles.wrapper,
        styles[`variant-${variant}`],
        styles[`size-${size}`],
        {
            [styles.icon]: icon,
            [styles.disabled]: disabled,
            [styles.notClickable]: !clickable,
            [styles.loading]: loading,
        },
    ), [className, variant, size, icon, disabled, loading]);

    const handleClick = useCallback((event) => {
        if (disabled) {
            event.preventDefault();
            return;
        }
        onClick(event);
    }, [disabled, onClick]);

    const Component = useMemo(() => {
        if (clickable === false) {
            return 'div';
        }
        return href ? 'a' : 'button';
    }, [href]);

    return (
        <Component
            title={title}
            className={classes}
            onClick={handleClick}
            disabled={disabled}
            href={href}
            ref={ref}
            {...rest}
        >
            <span>{children}</span>
            {loading && (
                <div className={styles.spinnerWrapper}>
                    <Spinner animation="border" className={styles.spinner} />
                </div>
            )}
        </Component>
    );
});

Button.displayName = 'Button';

Button.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    onClick: PropTypes.func,
    title: PropTypes.string,
    variant: PropTypes.oneOf(variants),
    size: PropTypes.oneOf(sizes),
    icon: PropTypes.bool,
    disabled: PropTypes.bool,
    clickable: PropTypes.bool,
    loading: PropTypes.bool,
    href: PropTypes.string,
};

Button.defaultProps = {
    children: undefined,
    className: undefined,
    onClick: () => { },
    variant: 'primary',
    size: 'md',
    icon: false,
    disabled: false,
    clickable: true,
    loading: false,
    href: undefined,
};

export default Button;
