import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Resizable } from 're-resizable';

function ResizableSidebar(props) {
    const { className, children, collapsed } = props;

    const values = useMemo(() => {
        return collapsed
            ? {
                size: { width: 'var(--nav-height)' },
                minWidth: 'var(--nav-height)',
                maxWidth: 'var(--nav-height)',
            }
            : {
                size: undefined,
                minWidth: 300,
                maxWidth: '40%',
            };
    }, [collapsed]);

    return (
        <Resizable
            className={className}
            size={values.size}
            minWidth={values.minWidth}
            maxWidth={values.maxWidth}
            defaultSize={{
                width: 500,
                height: '100%',
            }}
            enable={{
                top: false,
                right: true,
                bottom: false,
                left: false,
                topRight: false,
                bottomRight: false,
                bottomLeft: false,
                topLeft: false,
            }}
        >
            {children}
        </Resizable>
    );
}

ResizableSidebar.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    collapsed: PropTypes.bool,
};

ResizableSidebar.defaultProps = {
    className: undefined,
    children: undefined,
    collapsed: false,
};

export default ResizableSidebar;
