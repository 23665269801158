import $ from 'jquery';
import React from 'react';
import {
    MdCode,
    MdDescription,
    MdFontDownload,
    MdImage,
    MdOndemandVideo,
    MdSort,
    MdWarning,
} from 'react-icons/md';
import { Card, Table } from 'react-bootstrap';
import AdminBase from './AdminBase';
import AdminNavBar from './AdminNavBar';
import AssetLoader from '~coresrc/loader/assetloader';

/**
 * @memberOf ReactBlocks
 * @schema json schema
 */

class AdminSearchAssets extends AdminBase {
    /**
     * @param props
     */
    constructor(props) {
        super(props);
        this.data = this.props.blockRef.data;
        this.app = this.props.blockRef.app;
        this.state = {
            originalFiles: [],
            filteredFiles: [],
            sortedByAscending: false,
            totalFileSize: 0,
            assetsLoaded: false,
        };

        this.state.filteredFiles = this.state.originalFiles;
    }

    componentDidMount() {
        this.loadAssetData();
    }

    loadAssetData() {
        const xmlLoader = new AssetLoader({ name: 'typeahead' });
        const otherAssetsLoader = new AssetLoader({ name: 'allAssets' });
        const imageLoader = new AssetLoader({ name: 'test' });

        xmlLoader.addXML('./imsmanifest.xml');

        this.handleXmlSingleLoad(xmlLoader, imageLoader, otherAssetsLoader);

        xmlLoader.start();
    }

    handleXmlSingleLoad(xmlLoader, imageLoader, otherAssetsLoader) {
        const assetData = [];

        xmlLoader.bind('onAssetLoaded', (e) => {
            // alert('XML LOADED');
            console.info('XML loaded: ', e);
            const xmlDoc = e.loader.resource.getXML();
            const $files = $(xmlDoc).find('file');
            console.info('XML files', $files);
            const $filePaths = [];

            $files.each(function () {
                $filePaths.push($(this).attr('href'));
                const file = $(this).attr('href');
                if (
                    file.includes('.png') ||
                    file.includes('.jpg') ||
                    file.includes('.svg')
                ) {
                    imageLoader.addImage(file); // Loader for images
                } else {
                    otherAssetsLoader.addXML(file); // Loader for all other assets
                }
            });

            console.info('TypeAhead.files.filePath', $filePaths);

            const fileInBytes = this.getSizeInBytes(e.loader.resource.url);
            const fileInKiloBytes = this.convertBytes(fileInBytes, 'KB', 2);

            for (let i = 0; i < $filePaths.length; i++) {
                const name = $filePaths[i];
                const extension = name.slice(
                    name.lastIndexOf('.'),
                    name.length
                );
                const icon = '';
                assetData.push({
                    name,
                    extension,
                    icon,
                    size: 0, // set as default - if an asset still has this value at final render it implies a load issue
                    format: fileInKiloBytes.format,
                });
            }
        });

        this.handleXmlAllLoaded(
            xmlLoader,
            imageLoader,
            otherAssetsLoader,
            assetData
        );
    }

    handleXmlAllLoaded(xmlLoader, imageLoader, otherAssetsLoader, assetData) {
        const loadedAssetData = [];
        const loadedImageData = [];

        xmlLoader.bind('onAllAssetsLoaded', () => {
            console.info('assetData', assetData);

            this.handleOtherAssetsLoad(
                otherAssetsLoader,
                loadedAssetData,
                imageLoader,
                loadedImageData,
                assetData
            );

            otherAssetsLoader.start();
        });
    }

    handleOtherAssetsLoad(
        otherAssetsLoader,
        loadedAssetData,
        imageLoader,
        loadedImageData,
        assetData
    ) {
        let assetsTotalSize = 0;

        // debugger;

        const self = this;

        otherAssetsLoader.bind('onAssetLoaded', (e) => {
            // debugger;
            const fileInBytes = self.getSizeInBytes(e.loader.resource.url);
            const fileInKiloBytes = self.convertBytes(fileInBytes, 'KB', 2);
            loadedAssetData.push({
                name: e.loader.resource.url,
                size: fileInKiloBytes.size,
                format: fileInKiloBytes.format,
            });
            assetsTotalSize += fileInBytes;
            // debugger;
        });

        otherAssetsLoader.bind('onAllAssetsLoaded', () => {
            console.info('otherASSETSLOADER: ', otherAssetsLoader);

            this.handleImageAssetsLoad(
                imageLoader,
                loadedImageData,
                assetsTotalSize,
                loadedAssetData
            );
        });

        this.setState({ filteredFiles: assetData });
        this.setState({ originalFiles: assetData });
        this.applyIcons();
    }

    handleImageAssetsLoad(
        imageLoader,
        loadedImageData,
        assetsTotalSize,
        loadedAssetData
    ) {
        imageLoader.bind('onAssetLoaded', (e) => {
            const fileInBytes = this.getSizeInBytes(e.loader.resource.url);
            const fileInKiloBytes = this.convertBytes(fileInBytes, 'KB', 2);
            loadedImageData.push({
                name: e.loader.resource.url,
                size: fileInKiloBytes.size,
                format: fileInKiloBytes.format,
            });
            assetsTotalSize += fileInBytes;
        });
        // debugger;

        imageLoader.bind('onAllAssetsLoaded', () => {
            this.addSizeFromLoadedAssets(loadedAssetData);

            this.addSizeFromLoadedImages(loadedImageData);

            assetsTotalSize = Number(
                this.convertBytes(assetsTotalSize, 'MB', 2).size
            ).toFixed(2);

            const arrangedByDescending = _.sortBy(
                this.state.filteredFiles,
                'size'
            ).reverse();

            this.setState({ filteredFiles: arrangedByDescending });
            this.setState({ filteredFiles: this.state.filteredFiles });
            this.setState({ totalFileSize: assetsTotalSize });
            this.setState({ assetsLoaded: true });
        });

        imageLoader.start();
    }

    addSizeFromLoadedAssets(loadedAssetData) {
        console.info('LOADEDASSETDATA: ', loadedAssetData);

        this.state.filteredFiles.map((item) => {
            for (let i = 0; i < loadedAssetData.length; i++) {
                if (item.name == loadedAssetData[i].name) {
                    item.size = Number(loadedAssetData[i].size);
                }
            }
        });
    }

    addSizeFromLoadedImages(loadedImageData) {
        console.info('LOADEDIMAGEDATA: ', loadedImageData);
        this.state.filteredFiles.map((item) => {
            for (let i = 0; i < loadedImageData.length; i++) {
                if (item.name == loadedImageData[i].name) {
                    item.size = Number(loadedImageData[i].size);
                }
            }
        });
    }

    applyIcons() {
        // alert('APPLYICONS');

        const iconsAdded = [];

        this.state.filteredFiles.map((item) => {
            // Check
            if (
                (item.extension == '.jpg' ||
                    item.extension == '.png' ||
                    item.extension == '.xml' ||
                    item.extension == '.json' ||
                    item.extension == '.mp4' ||
                    item.extension == '.vtt' ||
                    item.extension == '.woff' ||
                    item.extension == '.woff2' ||
                    item.extension == '.ttf' ||
                    item.extension == '.otf' ||
                    item.extension == '.svg' ||
                    item.extension == '.eot' ||
                    item.extension == '.gif' ||
                    item.extension == '.html' ||
                    item.extension == '.swf' ||
                    item.extension == '.js' ||
                    item.extension == '.css') == false
            ) {
                console.info(
                    'Unaccounted for item extension: ',
                    item.extension
                );
            }

            if (
                item.extension == '.jpg' ||
                item.extension == '.png' ||
                item.extension == '.svg' ||
                item.extension == '.gif' ||
                item.extension == '.swf'
            ) {
                item.icon = <MdImage />;
                iconsAdded.push(item);
            } else if (item.extension == '.xml' || item.extension == '.json') {
                item.icon = <MdDescription />;
                iconsAdded.push(item);
            } else if (
                item.extension == '.woff' ||
                item.extension == '.woff2' ||
                item.extension == '.ttf' ||
                item.extension == '.otf' ||
                item.extension == '.eot'
            ) {
                item.icon = <MdFontDownload />;
                iconsAdded.push(item);
            } else if (
                item.extension == '.css' ||
                item.extension == '.html' ||
                item.extension == '.js'
            ) {
                item.icon = <MdCode />;
                iconsAdded.push(item);
            } else if (item.extension == '.mp4' || item.extension == '.vtt') {
                item.icon = <MdOndemandVideo />;
                iconsAdded.push(item);
            } else {
                item.icon = <MdWarning />;
                iconsAdded.push(item);
            }
        });

        console.info('iconsAdded: ', iconsAdded);

        this.setState({ filteredFiles: iconsAdded });
    }

    filterList(event) {
        console.info('User input: ', event.target.value);

        const searchWord = event.target.value.toLowerCase();

        console.info('SearchWord: ', searchWord);

        const filteredArray = this.state.originalFiles.filter((item) =>
            item.name.includes(searchWord)
        );

        console.info('filteredArrayBySearchWord: ', filteredArray);

        this.setState({ filteredFiles: filteredArray });

        // this.highlightMatches(filteredArray, searchWord); // [JM] : Need to fix

        this.state.filteredFiles = this.state.originalFiles;
    }

    // [JM] : TOFIX - highlighting of user input within td doesn't work
    highlightMatches(filteredArray, searchWord) {
        // alert('highlight matches');
        filteredArray.map((item) => {
            const splitName = item.name.split(searchWord);

            const spanSearchWord = (
                <span className="bg-warning" key="item">
                    {searchWord}
                </span>
            );

            const spanItemName = [];

            splitName.forEach((item, i) => {
                if (i == splitName.length - 1) {
                    spanItemName.push(item);
                } else {
                    spanItemName.push(item);
                    spanItemName.push(spanSearchWord);
                }
            });

            item.name = spanItemName;
        });

        this.setState({ filteredFiles: filteredArray });
    }

    getSizeInBytes(URL) {
        const request = new XMLHttpRequest();
        request.open('HEAD', URL, false);
        request.send(null);
        const headerText = request.getAllResponseHeaders();
        const re = /Content\-Length\s*:\s*(\d+)/i;
        re.exec(headerText);
        return parseInt(RegExp.$1);
    }

    // formatToKiloBytes(bytes, decimals) {
    //     return {
    //         size: Number(bytes / 1000).toFixed(decimals),
    //         format: 'KB',
    //     };
    // }

    convertBytes(value, format, decimals) {
        if (format == 'KB') {
            return {
                size: Number(value / 1000).toFixed(decimals),
                format: 'KB',
            };
        }
        if (format == 'MB') {
            return {
                size: Number(value / 1000000).toFixed(decimals),
                format: 'MB',
            };
        }
    }

    sortByExtension() {
        if (this.state.sortedByAscending === true) {
            // Sort descending
            const sortedDescending = this.state.filteredFiles.sort((a, b) =>
                a.extension.localeCompare(b.extension)
            );

            this.setState({ filteredFiles: sortedDescending });
        } else {
            // Sort ascending
            const sortedAscending = this.state.filteredFiles.sort((a, b) =>
                b.extension.localeCompare(a.extension)
            );

            this.setState({ filteredFiles: sortedAscending });
        }

        this.state.sortedByAscending = !this.state.sortedByAscending;
    }

    sortBySize() {
        if (this.state.sortedByAscending === true) {
            // Sort descending
            const sortedDescending = this.state.filteredFiles.sort(function (
                a,
                b
            ) {
                return parseFloat(b.size) - parseFloat(a.size);
            });

            this.setState({ filteredFiles: sortedDescending });
        } else {
            // Sort ascending
            const sortedAscending = this.state.filteredFiles.sort(function (
                a,
                b
            ) {
                return parseFloat(a.size) - parseFloat(b.size);
            });

            this.setState({ filteredFiles: sortedAscending });
        }

        this.state.sortedByAscending = !this.state.sortedByAscending;
    }

    render() {
        const searchAssetsHead = (
            <tr className="text-light bg-dark">
                <th className="border-0">NAME</th>
                <th className="text-center border-0">
                    EXTENSION
                    <MdSort
                        className="mx-2"
                        onClick={() => {
                            this.sortByExtension();
                        }}
                    />
                </th>
                <th className="text-center border-0">
                    SIZE(KB)
                    <MdSort
                        className="mx-2"
                        onClick={() => {
                            this.sortBySize();
                        }}
                    />
                </th>
            </tr>
        );

        const searchAssetsBody = this.state.filteredFiles.map((file) => {
            return (
                <tr key={file.name}>
                    <td>
                        {file.icon}
                        <a href={file.name} target="_blank" rel="noreferrer">
                            {file.name}
                        </a>
                    </td>
                    <td className="text-center">{file.extension}</td>
                    <td className="text-center">{file.size}</td>
                </tr>
            );
        });

        return (
            <div className="admin">
                <div id="search-assets" role="tabpanel" className="tab-pane">
                    <div className="typeahead-wrapper">
                        <AdminNavBar />
                        <Card className="m-5">
                            <Card.Body>
                                <Card.Title>
                                    <small>TOTAL SIZE OF ASSETS</small>
                                    <br />
                                    {`${this.state.totalFileSize} MB`}
                                    <hr />
                                    <input
                                        onChange={this.filterList.bind(this)}
                                        className="typeahead"
                                        id="userInput"
                                        placeholder="Enter asset name"
                                    />
                                </Card.Title>
                            </Card.Body>
                        </Card>
                        {this.state.assetsLoaded == false && (
                            <Card className="p-5 loading-message">
                                <Card.Body>
                                    <Card.Title>
                                        <h2>
                                            Please wait for all asset data to
                                            load...
                                        </h2>
                                    </Card.Title>
                                </Card.Body>
                            </Card>
                        )}{' '}
                        <div className="m-5">
                            <Table>
                                <thead>{searchAssetsHead}</thead>
                                <tbody>{searchAssetsBody}</tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AdminSearchAssets;
