import Backbone from 'backbone';

class ContainerCollector {
    constructor(log, container) {
        this.log = log;
        this.type = 'ContainerCollector';

        this.events = [
            'onSetupComplete',
            'onDestroy',
            'onCourseDataCreated',
            'onLoadComplete',
            'onResize',
            'onResizeComplete',
            'onOrientationChange',
            'onEnterFullScreen',
            'onExitFullScreen',
        ];

        this.events.forEach((event) => {
            Backbone.listenTo(container, event, (e) => {
                log.add({
                    type: this.type,
                    event: `Container.${event}`,
                    data: e,
                    time: Date.now(),
                });
            });
        });
    }

    print() {
        return this.log.where({ type: this.type });
    }
}

export default ContainerCollector;
