import { useEffect } from 'react';

/**
 * Attach an event to a react ref
 *
 * @param {import('react').MutableRefObject} ref
 * @param {string} eventName
 * @param {() => void} callback
 * @param {any[]} dependencies
 */
export function useRefEvent(ref, eventName, callback, dependencies = []) {
    useEffect(() => {
        if (!ref.current) {
            return;
        }

        // Remove event listener if it already exists
        ref.current?.removeEventListener(eventName, callback);
        ref.current?.addEventListener(eventName, callback);

        return () => {
            ref.current?.removeEventListener(eventName, callback);
        };
    }, dependencies);
}
