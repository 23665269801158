/** @typedef {import('../../Firebase').default} Firebase */
/** @typedef {import('../RoomSync').default} RoomSync */
/** @typedef {import('~core').Container} Container */
/** @typedef {import('./RoomManager').default} RoomManager */

import Model from '~m/ContainerPlugin/Model';

/**
 * @abstract
 */
class AbstractManager extends Model {
    constructor(roomSync, firebase, container, roomManager) {
        if (new.target === AbstractManager) {
            throw new TypeError('Cannot construct AbstractManager instances directly');
        }
        super();
        /** @type {RoomSync} */
        this.roomSync = roomSync;
        /** @type {Firebase} */
        this.firebase = firebase;
        /** @type {Container} */
        this.container = container;
        /** @type {RoomManager} */
        this.roomManager = roomManager;
    }
}

export default AbstractManager;
