import React from 'react';
import { Modal } from 'react-bootstrap';
import $ from 'jquery';
import ReactDOM from 'react-dom';
import AdminGoto from './AdminGoto';
import AdminGreensock from './AdminGreensock';

/**
 * @memberOf ReactBlocks
 * @schema json schema
 */

class ReviewMode {
    /**
     * @param
     */
    constructor() {
        this.name = 'ReviewMode';
        this.formElements = [
            'textarea',
            'input',
            'option',
            'button',
            'fieldset',
            'select',
            'datalist',
            'checkbox',
        ];

        this.combinations = [];
    }

    addCombination(combo, name, description, callback) {
        return this.combinations.push({
            combo,
            name,
            description,
            callback,
        });
    }

    setup(container) {
        this.container = container;

        // Check if container is locked
        if (this.container.isLocked === true) return;

        container.mount({
            id: 'admin-goto-palette',
            component: AdminGoto,
        });

        container.mount({
            id: 'admin-greensock-palette',
            component: AdminGreensock,
        });

        this.addCombination(
            'ctrl+alt+KeyJ',
            'Go to page',
            'Quickly go to any page in the course',
            () => this.container.trigger('admin:admin:palette-toggle:goto')
        );
        this.addCombination(
            'ctrl+alt+KeyA',
            'Admin page',
            'Navigate to admin page',
            () => {
                this.container.router.navigate('#global/admin', {
                    trigger: true,
                    replace: true,
                });
            }
        );
        this.addCombination(
            'ctrl+alt+KeyM',
            'Modules page',
            'Navigate to Modules',
            () => {
                this.container.router.navigate('#global/admin-modules', {
                    trigger: true,
                    replace: true,
                });
            }
        );
        this.addCombination(
            'ctrl+alt+KeyP',
            'Pages page',
            'Navigate to Pages',
            () => {
                this.container.router.navigate('#global/admin-pages', {
                    trigger: true,
                    replace: true,
                });
            }
        );
        this.addCombination(
            'ctrl+alt+KeyT',
            'Templates page',
            'Navigate to Templates',
            () => {
                this.container.router.navigate('#global/admin-templates', {
                    trigger: true,
                    replace: true,
                });
            }
        );
        this.addCombination(
            'ctrl+alt+KeyI',
            'Image Review page',
            'Navigate to Image Review',
            () => {
                this.container.router.navigate('#global/admin-image-review', {
                    trigger: true,
                    replace: true,
                });
            }
        );
        this.addCombination(
            'ctrl+alt+KeyS',
            'Search assets page',
            'Navigate to search assets',
            () => {
                this.container.router.navigate('#global/admin-search-assets', {
                    trigger: true,
                    replace: true,
                });
            }
        );
        this.addCombination(
            'ctrl+alt+Period',
            'Next page',
            'Navigate to next page',
            this.nextPage.bind(this)
        );
        this.addCombination(
            'ctrl+alt+Comma',
            'Previous page',
            'Navigate to previous page',
            this.previousPage.bind(this)
        );
        this.addCombination(
            'ctrl+alt+Digit3',
            'Complete page',
            'Complete current blocks on the page',
            this.completePage.bind(this)
        );
        this.addCombination(
            'ctrl+alt+Digit4',
            'Fail page',
            'Fail current blocks on the page',
            this.failPage.bind(this)
        );
        this.addCombination(
            'ctrl+alt+Digit5',
            'Pass page',
            'Pass current blocks on the page',
            this.passPage.bind(this)
        );

        this.addCombination(
            'ctrl+alt+KeyU',
            'Unlock modules',
            'Unlocks all modules',
            this.unlockModules.bind(this)
        );
        this.addCombination(
            'ctrl+alt+KeyR',
            'Reload from current page',
            'Reload from current page',
            this.reloadFromCurrentPage.bind(this)
        );
        this.addCombination(
            'ctrl+alt+KeyD',
            'Toggle DOM review styles',
            'Toggle the current review style',
            this.toggleDomReviewStyles.bind(this)
        );
        this.addCombination(
            'ctrl+alt+KeyG',
            'Change animation speed',
            'Changes animation speed of all blocks on the page',
            () => this.container.trigger('admin:palette-toggle:greensock')
        );

        document.addEventListener('keyup', this.onKeyPress.bind(this), false);
        document.addEventListener('keydown', this.onKeyDown.bind(this), false);
    }

    onKeyDown(e) {
        // console.info('Key.Down: ', e.key, e.code, e.ctrlKey, e.altKey, e.shiftKey);

        this.ctrlKey = e.ctrlKey;
        this.altKey = e.altKey;
        this.shiftKey = e.shiftKey;
    }

    onKeyPress(e) {
        // Check that the focus is not inside a form element
        if (this.formElements.includes(e.target.localName)) {
            return true;
        }

        // console.info('Key.Press: ', e.key, e.code, e.ctrlKey, e.altKey, e.shiftKey);

        // const actions = {
        //     'ctrl+alt+190': () => this.nextPage(),
        //     'ctrl+alt+188': () => this.previousPage(),
        //     'ctrl+alt+51': () => this.completePage(),
        //     'ctrl+alt+52': () => this.failPage(),
        //     'ctrl+alt+53': () => this.passPage(),
        //     'ctrl+alt+85': () => this.unlockModules(),

        //     'ctrl+alt+82': () => this.reloadFromCurrentPage(),
        //     'ctrl+alt+68': () => this.toggleDomReviewStyles(),
        //     'ctrl+alt+71': () => this.changeAnimationSpeed(),
        // };

        const path = [
            ['ctrl', this.ctrlKey],
            ['alt', this.altKey],
            ['shift', this.shiftKey],
            [e.code, true],
        ]
            .map(([key, pressed]) => (pressed ? key : null))
            .filter(Boolean)
            .join('+');

        // console.info('path == ', path);

        const found = _.find(this.combinations, { combo: path });

        if (found) {
            found.callback();
            this.ctrlKey = false; // e.ctrlKey;
            this.altKey = false; // e.altKey;
            this.shiftKey = false; // e.shiftKey;
            return false;
        }
    }

    toggleDomReviewStyles() {
        const $dom = $(this.container.dom);
        $dom.toggleClass('review');

        let isReviewModeOn = false;
        if ($dom.hasClass('review')) {
            isReviewModeOn = true;
        }
        const $images = $dom.find('img');
        _.each($images, function (image) {
            const $image = $(image);
            const path = $image.attr('src');
            if (isReviewModeOn) {
                $image.attr('src', `${path}$$DEBUG$$`);
            } else {
                $image.attr('src', path.split('$$DEBUG$$').join(''));
            }
        });
    }

    nextPage() {
        this.container.router.next();
    }

    previousPage() {
        this.container.router.previous();
    }

    passPage() {
        this.completeCurrentBlocks(Status.PASSED);
    }

    failPage() {
        this.completeCurrentBlocks(Status.FAILED);
    }

    completePage() {
        this.completeCurrentBlocks(Status.COMPLETED);
    }

    completeCurrentBlocks(status) {
        const page = this.container.session.currentPageData;

        page.blocks.each(function (block) {
            block.setStatus(status, true);
            block.isComplete(true);
        });
    }

    reloadFromCurrentPage() {
        const url = `${
            window.location.origin + window.location.pathname
        }?startPage=${this.container.router.getCurrentPageId()}`;

        window.location = url;
    }

    unlockModules() {
        const courseData = this.container.courseData;
        for (let i = 0; i < courseData.getTotalModules(); i++) {
            const module = courseData.getModule(i);
            module.setModuleStatus(Status.AVAILABLE);
        }

        this.container.router.changePage(
            this.container.router.getCurrentPageId()
        );

        Logger.info('ReviewMode', 'Modules unlocked');
    }
}

export default ReviewMode;
