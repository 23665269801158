import Backbone from 'backbone';

class ScreenRendererCollector {
    constructor(log, container) {
        this.log = log;
        this.type = 'ScreenRendererCollector';

        this.pageEvents = [
            'onPageSuspended',
            'onPageRestored',
            'onPageAdded',
            'onPageRemoved',
        ];

        this.blockEvents = ['onBlockInitialized', 'onBlockRendered'];

        this.pageEvents.forEach((event) => {
            Backbone.listenTo(container.screenManager, event, (e) => {
                log.add({
                    type: this.type,
                    event: `ScreenRenderer.${event}`,
                    data: e.pageData.pageID(),
                    time: Date.now(),
                });
            });
        });

        this.blockEvents.forEach((event) => {
            Backbone.listenTo(container.screenManager, event, (e) => {
                log.add({
                    type: this.type,
                    event: `ScreenRenderer.${event}`,
                    data: `${e.blockData.pageID()}_${e.blockData.blockID()}`,
                    time: Date.now(),
                });
            });
        });
    }

    print() {
        return this.log.where({ type: this.type });
    }
}

export default ScreenRendererCollector;
