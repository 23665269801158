import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Scarf.module.css';
import { useBlock } from '~templates/components/blocks';

/** @type {import('./Scarf.types').VariantClasses} */
const variants = {
    centred: styles.centred,
    contained: styles.contained,
};

/**
 * @type {import('react').ForwardRefExoticComponent<import('./Scarf.types').ScarfProps, import('./Scarf.types').ScarfRef>}
 */
const Scarf = forwardRef((props, ref) => {
    const {
        variant,
        children,
        className,
        innerClassName,
        noPadding,
        fullHeight,
        before,
        after,
        background,
        overflowX,
    } = props;

    const { container } = useBlock();

    const outerRef = useRef();
    const innerRef = useRef();

    const outerClasses = useMemo(() => classNames(
        className,
        styles.scarf,
        {
            [variants[variant]]: variant,
            [styles.padded]: !noPadding,
            [styles.fullHeight]: fullHeight,
            [styles.overflowX]: overflowX,
        },
    ), [variant, noPadding, fullHeight]);

    const innerClasses = useMemo(() => classNames(
        innerClassName,
        styles.inner,
    ), [innerClassName]);

    useEffect(() => {
        container.componentList.BackgroundManager.setBackground(background);
    }, [background]);

    useImperativeHandle(ref, () => ({
        get outer() {
            return outerRef.current;
        },
        get inner() {
            return innerRef.current;
        },
    }));

    return (
        <div ref={outerRef} className={outerClasses}>
            {before}
            <div ref={innerRef} className={innerClasses}>{children}</div>
            {after}
        </div>
    );
});

Scarf.displayName = 'Scarf';

Scarf.propTypes = {
    variant: PropTypes.oneOf(Object.keys(variants)),
    children: PropTypes.node,
    className: PropTypes.string,
    innerClassName: PropTypes.string,
    noPadding: PropTypes.bool,
    fullHeight: PropTypes.bool,
    before: PropTypes.node,
    after: PropTypes.node,
    background: PropTypes.any,
    overflowX: PropTypes.bool,
};

Scarf.defaultProps = {
    variant: undefined,
    children: undefined,
    className: undefined,
    innerClassName: undefined,
    noPadding: false,
    fullHeight: false,
    background: undefined,
    overflowX: false,
};

export default Scarf;
