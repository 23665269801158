import TimelineMax from 'gsap/TimelineMax';
import { groupBy } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Button from '../../components/Button';
import Scarf from '../../components/Scarf';
import useRefIndexedArray from '../../hooks/useRefIndexedArray';
import { injectStylesIntoSvg } from '../../utils';
import Point from '../WorldMap/components/Point';
import Slider from './components/Slider';
import styles from './WorldMapSlider.module.css';
import { t } from '~templates/utils/i18n';
import { useBlock } from '~templates/components/blocks';

const colours = [
    '#ffed00',
    'rgb(242, 202, 69)',
    'rgb(237, 174, 61)',
    'rgb(233, 146, 53)',
    'rgb(229, 118, 46)',
    'rgb(227, 97, 43)',
    'rgb(224, 69, 43)',
    'rgb(210, 55, 43)',
    'rgb(162, 41, 34)',
    '#86170b',
];

function WorldMapSlider() {
    const context = useBlock({ instantComplete: true });

    /** @type {import('./WorldMapSlider.types').WorldMapSchema}  */
    const data = context.data;

    const [pointRefs, pointRefProp] = useRefIndexedArray();
    const [animating, setAnimating] = useState(false);
    const [pointsShowing, setPointsShowing] = useState(false);
    const [mapLoaded, setMapLoaded] = useState(false);

    const onMapLoaded = () => {
        setMapLoaded(true);
    };

    const [sliderValue, setSliderValue] = useState(95);
    const selectedGroup = useMemo(() => Math.floor(sliderValue / 10), [sliderValue]);

    useEffect(() => {
        // Don't allow if currently animating
        if (animating) return;

        const currentState = pointRefs.current
            .some(point => point.element.style.opacity === '1');

        // Prevent the current animation is the current state already matches
        // the expected state (prevents a jumpy animation on page load)
        if (currentState === pointsShowing) return;

        const points = pointRefs.current.map(p => p.element);
        setAnimating(true);
        const timeline = new TimelineMax({ onComplete: () => setAnimating(false) });

        if (pointsShowing) {
            // Show all of the map points
            timeline.staggerFromTo(
                points,
                0.5,
                { y: 10, opacity: 0, scale: 0 },
                { y: 0, opacity: 1, scale: 1 },
                0.15,
            );
        } else {
            // First hide any open tooltips then hide all of the map points
            for (const point of pointRefs.current) {
                point.hideTooltip();
            }
            timeline.staggerFromTo(
                points,
                0.5,
                { y: 0, opacity: 1, scale: 1 },
                { y: 10, opacity: 0, scale: 0 },
                0.15,
            );
        }
    }, [pointsShowing]);

    return (
        <Scarf variant="centred" className={styles.outer}>
            <div className={styles.block}>
                <div className={styles.map}>
                    <div className="relative">
                        <img style={{ opacity: selectedGroup >= 9 ? 1 : 0 }} className="" src="assets/images/worldmap/heat_10.png" alt="" onLoad={onMapLoaded} />
                        <img style={{ opacity: selectedGroup === 8 ? 1 : 0 }} className="absolute inset-0" src="assets/images/worldmap/heat_9.png" alt="" />
                        <img style={{ opacity: selectedGroup === 7 ? 1 : 0 }} className="absolute inset-0" src="assets/images/worldmap/heat_8.png" alt="" />
                        <img style={{ opacity: selectedGroup === 6 ? 1 : 0 }} className="absolute inset-0" src="assets/images/worldmap/heat_7.png" alt="" />
                        <img style={{ opacity: selectedGroup === 5 ? 1 : 0 }} className="absolute inset-0" src="assets/images/worldmap/heat_6.png" alt="" />
                        <img style={{ opacity: selectedGroup === 4 ? 1 : 0 }} className="absolute inset-0" src="assets/images/worldmap/heat_5.png" alt="" />
                        <img style={{ opacity: selectedGroup === 3 ? 1 : 0 }} className="absolute inset-0" src="assets/images/worldmap/heat_4.png" alt="" />
                        <img style={{ opacity: selectedGroup === 2 ? 1 : 0 }} className="absolute inset-0" src="assets/images/worldmap/heat_3.png" alt="" />
                        <img style={{ opacity: selectedGroup === 1 ? 1 : 0 }} className="absolute inset-0" src="assets/images/worldmap/heat_2.png" alt="" />
                        <img style={{ opacity: selectedGroup === 0 ? 1 : 0 }} className="absolute inset-0" src="assets/images/worldmap/heat_1.png" alt="" />
                    </div>
                    <div className={styles.points}>
                        {data.points.map((point, index) => (
                            <Point key={index} point={point} ref={pointRefProp(index)} disabled={!pointsShowing} />
                        ))}
                    </div>
                </div>
                <div className={styles.sliderWrapper}>
                    {mapLoaded && (
                        <>
                            <div className={styles.labels}>
                                <span className={styles.key}>{t('least_corrupt')}</span>
                                <span className={styles.key}>{t('most_corrupt')}</span>
                            </div>
                            <div className={styles.slider}>
                                <Slider
                                    value={[sliderValue]}
                                    max={100}
                                    step={0.5}
                                    // showThumb={!reducedInteractionMode}
                                    onValueChange={setSliderValue}
                                    onThumbClick={() => {
                                        const v = selectedGroup * 10 + 5;
                                        setSliderValue(v > 95 ? 95 : v);
                                    }}
                                />
                            </div>
                            <div className={styles.buttons}>
                                <Button disabled={animating} onClick={() => setPointsShowing(!pointsShowing)}>
                                    {t('view-locations')}
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </Scarf>
    );
}

export default WorldMapSlider;
