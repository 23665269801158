import React, { useEffect, useRef } from 'react';
import ReactHtmlParser from 'react-html-parser';
import Table from 'react-bootstrap/Table';
import { Alert, Container } from 'react-bootstrap';
import { AiFillInfoCircle } from 'react-icons/ai';
import TweenMax from 'gsap/TweenMax';
import Scarf from '../../components/Scarf';
import styles from './Rolodex.module.css';
import { useBlock } from '~templates/components/blocks';
import { t } from '~templates/utils/i18n';

function Rolodex() {
    /** @type {import('../blocks').BlockContext<import('./Rolodex.types').RolodexSchema>} */
    const { data } = useBlock({ instantComplete: true });
    const { header, text, items } = data;
    const tableRef = useRef();

    useEffect(() => {
        TweenMax.staggerFromTo(
            tableRef.current.querySelectorAll('tr'),
            0.2,
            { y: 25, opacity: 0 },
            { y: 0, opacity: 1, clearProps: 'all' },
            0.1,
        );
    }, []);

    return (
        <Scarf className={styles.wrapper} innerClassName={styles.inner}>
            <div className={styles.container}>
                {header && (<h2>{header}</h2>)}
                <div className={styles.tableWrapper}>
                    <Table className={styles.table} striped hover ref={tableRef}>
                        <tr>
                            <th><span className={styles.cell}>{t('region-level')}</span></th>
                            <th><span className={styles.cell}>{t('title')}</span></th>
                            <th><span className={styles.cell}>{t('name')}</span></th>
                            <th><span className={styles.cell}>{t('business-phone')}</span></th>
                            <th><span className={styles.cell}>{t('email-address')}</span></th>
                        </tr>
                        {items.map((row, i) => (
                            <tr key={i}>
                                <td><span className={styles.cell}>{row.country}</span></td>
                                <td><span className={styles.cell}>{row.title}</span></td>
                                <td><span className={styles.cell}>{row.name}</span></td>
                                <td><span className={styles.cell}>{row.phone}</span></td>
                                <td><span className={styles.cell}>{row.email}</span></td>
                            </tr>

                        ))}
                    </Table>
                </div>
                <Alert variant="warning" className={styles.note}>
                    <div className={styles.icon}>
                        <AiFillInfoCircle size="1.4em" />
                    </div>
                    <div className={styles.content}>
                        <h3>{t('note')}</h3>
                        {ReactHtmlParser(text)}
                    </div>
                </Alert>
            </div>
        </Scarf>
    );
}

export default Rolodex;
