import copy from 'copy-text-to-clipboard';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { formatDateTime } from '../../../utils';
import useRoomDetails from '../hooks/useRoomDetails';
import useRoomMembers from '../hooks/useRoomMembers';
import { ATTENDEE_LIMIT, ConnectionStatus } from '../RoomSync.contants';
import { useRoomSync } from '../hooks';
import styles from './DetailsPanel.module.css';
import { t } from '~templates/utils/i18n';

function DetailRow(props) {
    const { name, value } = props;

    const copyValue = () => copy(value);

    return (
        <tr className={styles.row} onClick={copyValue} title={t('copy')}>
            <td className={styles.name}>{name}</td>
            <td className={styles.value} title={value}><span>{value}</span></td>
        </tr>
    );
}

DetailRow.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    copyable: PropTypes.bool,
};

DetailRow.defaultProps = {
    value: undefined,
    copyable: false,
};

function DetailsPanel() {
    const roomSync = useRoomSync();
    const room = useRoomDetails();
    const members = useRoomMembers();

    const connectedMembers = useMemo(() => {
        // return members.length;

        return members.filter((member) => {
            return member.connectionStatus === ConnectionStatus.CONNECTED && !member.kicked;
        }).length;
    }, [members]);

    useEffect(() => {
        roomSync.roomManager.update(roomSync.roomId, { attendeeCount: connectedMembers });
    }, [connectedMembers]);

    if (!room) return null;

    return (
        <div className={styles.wrapper}>
            <table className={styles.table}>
                <DetailRow name={t('name')} value={room.name} />
                <DetailRow name={t('created-at')} value={formatDateTime(room.createdAt)} />
                <DetailRow name={t('direct-link')} value={room.directLink} copyable />
                <DetailRow name={t('code')} value={room.id} copyable />
                <DetailRow name={t('connected')} value={`${connectedMembers}/${ATTENDEE_LIMIT}`} copyable />
            </table>
        </div>
    );
}

export default DetailsPanel;
