import _ from 'underscore';
import Backbone from 'backbone';
import { Logger } from '../../../index';
import { Score } from './scoring';

/**
 * @classdesc Represents a course page with scoring.
 *
 * @class
 * @property {string} id Page ID
 * @property {ScoringPlugin} plugin Scoring plugin instance
 * @property {string} pluginType Type of plugin for page
 * @property {array} options Scoring options/outcomes
 * @property {array} result IDs of resulting options
 */
const ScoringPage = Backbone.Model.extend({
    defaults() {
        return {
            id: undefined,
            plugin: undefined,
            pluginType: undefined,
            options: [],
            result: [],
        };
    },

    /**
     * Get page ID.
     *
     * @memberof ScoringPage#
     * @return {string} Page ID
     */
    getId() {
        return this.get('id');
    },

    /**
     * Get scoring plugin instance. This should only be set whilst the page is active.
     *
     * @memberof ScoringPage#
     * @return {ScoringPlugin} Plugin instance
     */
    getPlugin() {
        return this.get('plugin');
    },

    /**
     * Get plugin type. Used to instantiate the appropriate scoring plugin.
     *
     * @memberof ScoringPage#
     * @return {string} Scoring plugin type
     */
    getPluginType() {
        return this.get('pluginType');
    },

    /**
     * Get array of possible scoring options/outcomes.
     *
     * @memberof ScoringPage#
     * @return {array} Array of scoring options/outcomes
     */
    getOptions() {
        return this.get('options');
    },

    /**
     * Get array of options/outcomes representing the final result.
     *
     * @memberof ScoringPage#
     * @return {array} Array of resulting options/outcomes
     */
    getResult() {
        return this.get('result');
    },

    /**
     * Set the scoring plugin instance. This should be set when the page becomes active
     * and unset when it's no longer active.
     *
     * @memberof ScoringPage#
     * @param {ScoringPlugin} plugin Plugin instance
     */
    setPlugin(plugin) {
        this.set('plugin', plugin);
    },

    /**
     * set array of options/outcomes representing the final result. This signifies
     * that the scoring is complete.
     *
     * @memberof ScoringPage#
     * @param {array} result Array of resulting options/outcomes
     */
    setResult(result) {
        this.set('result', result);
    },

    /**
     * Check if scoring for the page is complete?
     *
     * @memberof ScoringPage#
     * @return {boolean} Is scoring for the page complete?
     */
    // isComplete: function() {
    // 	return this.get("result").length > 0;
    // },

    /**
     * Get the score for this page.
     *
     * @memberof ScoringPage#
     * @return {Score} Page score
     */
    getScore() {
        const score = Score.ZERO();

        _.each(
            this.get('result'),
            function (optionId) {
                const option = _.findWhere(this.get('options'), {
                    id: optionId,
                });
                if (!option) Logger.error('Invalid result: ', optionId);

                score.add(option.score);
            },
            this
        );

        return score;
    },

    /**
     * Get the maximum available score for this page.
     *
     * @memberof ScoringPage#
     * @return {Score} Maximum available score
     */
    getMaxScore() {
        const score = Score.ZERO();

        _.each(
            this.get('options'),
            function (option) {
                if (option.isMax) score.add(option.score);
            },
            this
        );

        return score;
    },

    /**
     * Update the result array.
     *
     * @memberof ScoringPage#
     */
    updateResult() {
        const plugin = this.getPlugin();
        if (!plugin) return false;

        const result = plugin.getResult();
        this.setResult(result);

        Logger.info('Result', this.id, result);
    },
});

/**
 * @classdesc Collection of scoring pages.
 *
 * @class
 */
const ScoringPages = Backbone.Collection.extend({
    model: ScoringPage,
});

export { ScoringPage, ScoringPages };
