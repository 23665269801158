import { Logger } from '../../index';
import Component from '../component/component.js';

var ViewportManager = Component.extend(
    {
        initialize(attributes) {
            attributes = attributes || {};

            this.name = 'ViewportManager';
            this.runOnLoad = attributes.setOnLoad === true;
            this.enableScale = attributes.enableScale === true;
            this.forceParent = attributes.forceParent === true;
            this.heightThreshold =
                parseInt(attributes.heightThreshold) ||
                ViewportManager.SCREEN_HEIGHT_THRESHOLD;
            this.isViewportSet = false;
        },

        setup(container) {
            this.container = container;

            this.listenTo(
                this.container,
                'onLoadComplete',
                this.handleLoadCompleted
            );
        },

        handleLoadCompleted() {
            if (this.runOnLoad) this.setViewport();
        },

        setViewport() {
            if (this.isViewportSet) return; // already set

            if (this.enableScale) this.scaleViewport();
            if (this.forceParent) this.setParentViewport();

            this.isViewportSet = true;

            this.trigger('onViewportSet', {
                target: this,
            });
        },

        scaleViewport() {
            const screenHeight = window.innerHeight;

            if (screenHeight < this.heightThreshold) {
                const scale =
                    Math.round((screenHeight / this.heightThreshold) * 100) /
                    100;
                const mvp = document.getElementById('myViewport');
                mvp.setAttribute(
                    'content',
                    `width=device-width, initial-scale=${scale}`
                );

                Logger.info('Viewport scale:', scale);
            }
        },

        setParentViewport() {
            if (self == top) return; // no parent

            const viewport = self.document.querySelector('meta[name=viewport]');
            const parentViewport = top.document.querySelector(
                'meta[name=viewport]'
            );

            if (parentViewport) {
                parentViewport.setAttribute(
                    'content',
                    viewport.getAttribute('content')
                );
            } else {
                const meta = top.document.createElement('meta');
                meta.name = 'viewport';
                meta.content = viewport.getAttribute('content');
                top.document.getElementsByTagName('head')[0].appendChild(meta);
            }
        },
    },
    {
        SCREEN_HEIGHT_THRESHOLD: 720,
    }
);

export default ViewportManager;
