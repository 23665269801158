import React from 'react';
import './EmailAvatar.scss';
import PropTypes from 'prop-types';

/**
 * Renders simple avatar with intial/s of provided name.
 */
const EmailAvatar = ({ name }) => {
    const initials = name.match(/\b(\w)/g).join('');

    return (
        <span className="email-avatar">
            <span className="initials">{initials}</span>
        </span>
    );
};

EmailAvatar.propTypes = {
    name: PropTypes.string.isRequired,
};

export default EmailAvatar;
