import { useState } from 'react';

function useBinding(initialValue) {
    const [value, setValue] = useState(initialValue);
    const onChange = e => setValue(e.target.value);
    const resetValue = () => setValue(initialValue);
    return [value, setValue, onChange, resetValue];
}

export default useBinding;
