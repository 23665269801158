import Backbone from 'backbone';
import $ from 'jquery';
import { TweenMax } from 'gsap/TweenMax';
import { Status } from '../../index';

const Block = Backbone.View.extend({
    /**
     * Fired when the block's interaction has completed.
     * @event Block~onInteractionComplete
     * @type {Object}
     * @property {Block} target - Reference to the block
     * @property {String} title - Interaction title
     * @property {Array} responses - User responses to the interaction
     * @property {Array} correctResponses - Possible correct responses for the interaction
     * @property {String} result - Correctness of the user response
     * @property {Number} score - Score for the interaction
     *
     */

    name: 'Block screen',

    initialize(options) {
        this.app = options.app;

        this.blockData = options.data;
        this.pageData = options.page;
        this.language = this.app.getLanguage();

        if (options.xml.schema !== undefined) {
            this.json = options.xml;
        } else {
            this.$xml = $(options.xml);
        }

        this.junctionData = options.junctionData;
        this.junctionController = options.junctionController;

        this.id = `${this.blockData.pageID()}_${this.blockData.blockID()}`;
        this.hasAudio = false;

        this.initializeAudio();
    },

    className() {
        let className = '';
        let prototype = Object.getPrototypeOf(this); // get instance prototype
        while (prototype != null) {
            className = prototype.hasOwnProperty('name')
                ? `${className} ${prototype.name}`
                : className;
            prototype = Object.getPrototypeOf(prototype); // traverese the prototype chain
        }

        return className;
    },

    // super: function(func) {
    // 	this._superData = this._superData || {};
    //
    // 	var prototype = this._superData[func] || Object.getPrototypeOf(this);
    // 	this._superData[func] = Object.getPrototypeOf(prototype); // get parent prototype
    // 	if(typeof this._superData[func][func] == "function") {
    // 		try {
    // 			this._superData[func][func].apply(this, Array.prototype.slice.call(arguments, 1));
    // 		} finally { // make sure to clear data
    // 			this._superData[func] = null;
    // 		}
    // 	}
    // },

    /**
     * The templates extending block overriding this function will return an array of assets to be loaded
     * Load background image if present.
     * @memberof Block#
     * @private
     * @return {array} array of assets to be loaded
     */
    getResources() {
        const resources = [];
        if (!this.pageData) return resources;

        const background = this.pageData.getArg('background');
        if (background) {
            resources.push(background);
        }

        return resources;
    },

    getId() {
        return this.id;
    },

    setup() {
        this.setupListeners();

        this.data = this.getData(this.$xml, this.language);
        this.trigger('onDataCreated', {
            target: this,
        });

        this.checkCompletion();
    },

    setupRenderer() {
        // @TODO can refactor?
        this.start();
        this.app.exitFullScreen(); // @TODO remove?
        this.render();

        if (this.blockData.isFirst()) {
            this.blockData.isVisible(true);
        }

        this.trigger('onRendered', {
            target: this,
        });
    },

    setupListeners() {
        this.listenTo(
            this.blockData,
            'onVisibilityChanged',
            this.handleVisibilityChanged
        );
        this.listenTo(
            this.app.screenManager,
            'onPageSuspended',
            this.handleSuspend
        );
        this.listenTo(
            this.app.screenManager,
            'onPageRestored',
            this.handleRestore
        );
    },

    render() {
        const content = this.getContent(this.data);
        this.$el.html(
            `<div class='screen-wrapper'><div class='content'>${content}</div></div>`
        );

        if (this.app.showScreenIds) this.showDebugInformation();
    },

    getContent() {
        return '';
    },

    start(isForced) {
        this.setStatus(Status.STARTED, isForced);
    },

    complete(isForced) {
        this.setStatus(Status.COMPLETED, isForced);
    },

    pass(isForced) {
        this.setStatus(Status.PASSED, isForced);
    },

    fail(isForced) {
        this.setStatus(Status.FAILED, isForced);
    },

    setStatus(status, isForced) {
        this.blockData.setStatus(status, isForced);

        this.blockData.isComplete(status >= Status.COMPLETED);
    },

    checkCompletion() {
        // this.app.session.checkPageCompletion( this.blockData.moduleID() + "_" + this.blockData.pageID() );
    },

    handleVisibilityChanged(e) {
        if (e.isVisible) {
            this.$el.addClass('view-visible');
        } else {
            this.$el.removeClass('view-visible');
        }
    },

    initializeAudio() {
        const audioUrl = this.app.audioManager.formatAudioUrl(this.id);
        this.hasAudio = this.app.audioManager.add(this.id, audioUrl);

        if (this.blockData.isFirst()) {
            this.startAudio();
        }
    },

    startAudio() {
        if (!this.hasAudio) return;

        this.app.audioManager.play(this.id);
    },

    stopAudio() {
        if (!this.hasAudio) return;

        this.app.audioManager.stop(this.id);
    },

    pauseAudio() {
        if (!this.hasAudio) return;

        this.app.audioManager.pause(this.id);
    },

    resumeAudio() {
        if (!this.hasAudio) return;

        this.app.audioManager.resume(this.id);
    },

    showDebugInformation() {
        this.$el.prepend(`<div class='block-id'><p>ID: ${this.id}</a></div>`);
    },

    /**
     * Handle page suspended event. Pause anything that shouldn't run in the background.
     *
     * @param  {Object} e Page suspended event object
     */
    handleSuspend(e) {
        this.pauseAudio();
    },

    /**
     *  Handle page restored event. Resume what was paused for page suspension.
     *
     * @param  {Object} e Page restored event object
     */
    handleRestore(e) {
        this.resumeAudio();
    },

    destroy() {
        if (this.blockData.preloader) {
            delete this.blockData.preloader;
        }

        this.trigger('onDestroy', {
            target: this,
        });
        this.stopListening();
        this.off();

        TweenMax.killChildTweensOf(this.blockData.$blockDom);
    },
});

export default Block;
