import { ConnectionStatus } from './RoomSync.contants';
import { t } from '~templates/utils/i18n';

const StatusText = {
    [ConnectionStatus.CONNECTED]: t('connected'),
    [ConnectionStatus.DISCONNECTED]: t('disconnected'),
    [ConnectionStatus.UNKNOWN]: t('connection-unknown'),
};

export function statusToText(key) {
    return StatusText[key];
}
