import React from 'react';
import ReactDOM from 'react-dom';
import { Badge } from 'react-bootstrap';
import Backbone from 'backbone';
import BlockWrapper from '~blocks/base/BlockWrapper';
import { BlockProvider } from '~blocks/index';
// import Block from '~coresrc/templates/block';
// import { templates } from '';

const ReactBlock = Backbone.View.extend({
    initialize(options) {
        this.app = options.app;
        this.blockData = options.data;
        this.pageData = options.page;
        this.language = this.app.getLanguage();

        if (options.xml.schema !== undefined) {
            this.json = options.xml;
        } else {
            this.$xml = $(options.xml);
        }

        this.junctionData = options.junctionData;
        this.junctionController = options.junctionController;

        this.id = `${this.blockData.pageID()}_${this.blockData.blockID()}`;
        this.hasAudio = false;

        this.initializeAudio();
    },

    // className() {
    //     let className = '';
    //     let prototype = Object.getPrototypeOf(this); // get instance prototype
    //     while (prototype != null) {
    //         className = prototype.hasOwnProperty('name')
    //             ? `${className} ${prototype.name}`
    //             : className;
    //         prototype = Object.getPrototypeOf(prototype); // traverese the prototype chain
    //     }

    //     return className;
    // },

    getClassNames() {
        return '';
        // let classes = `screen template template-${kebabCase(this.blockData.template())}-block`;
        // return classes;
    },

    setup() {
        this.setupListeners();

        this.data = this.getData(this.$xml, this.language);
        this.trigger('onDataCreated', {
            target: this,
        });

        this.checkCompletion();
    },

    getData() {
        return this.json;
    },

    render() {
        const ComponentClass = this.reactComponent;
        // const template =

        if (ComponentClass !== undefined) {
            const context = {
                block: this,
                container: this.app,
                data: this.data,
                options: this.data?.options ?? {},
            };
            const instance = <ComponentClass blockRef={this} />;
            const component = (
                <BlockProvider value={context}>
                    <BlockWrapper
                        ctx={instance}
                        className={this.getClassNames()}
                    >
                        {instance}
                    </BlockWrapper>
                </BlockProvider>
            );

            ReactDOM.render(component, this.$el[0]);
        } else {
            this.$el.html(
                "<div class='screen-wrapper'><div class='content'/></div>"
            );
            this.$el.find('.content').append(this.getContent(this.data));
        }

        if (this.app.showScreenIds) this.showDebugInformation();
    },

    showDebugInformation() {
        const history = [];
        const set = (status) => {
            history.push(status);
            const uniqueHistory = Array.from(new Set(history));

            const statuses = uniqueHistory.map((item, index) => {
                const text = Status.numberToStatus(item);
                const isCurrent = index === uniqueHistory.length - 1;
                const spanStyle = {
                    opacity: isCurrent ? 1 : 0.5,
                    marginRight: isCurrent ? 0 : '4px',
                };
                return (
                    <span style={spanStyle} key={index}>
                        {text}
                    </span>
                );
            });

            const component = (
                <Badge variant="secondary">
                    {this.app.router.isInJunction() &&
                        `${
                            this.app.session.currentPageData.getBlockTemplates()[0]
                        } / `}
                    {this.id} / {this.name} / {statuses}
                </Badge>
            );
            ReactDOM.render(component, this.$el.find('.block-meta')[0]);
        };
        this.$el.prepend("<div class='block-meta'/>");
        set(this.blockData.getStatus());
        this.listenTo(this.blockData, 'onStatusUpdate', (event) =>
            set(event.status)
        );
    },

    destroy() {
        if (this.blockData.preloader) {
            delete this.blockData.preloader;
        }

        this.trigger('onDestroy', { target: this });
        this.stopListening();
        this.off();

        TweenMax.killChildTweensOf(this.blockData.$blockDom);

        const ComponentClass = this.reactComponent;
        if (ComponentClass !== undefined) {
            const result = ReactDOM.unmountComponentAtNode(this.$el[0]);
        }
    },

    start(isForced) {
        this.setStatus(Status.STARTED, isForced);
    },

    complete(isForced) {
        this.setStatus(Status.COMPLETED, isForced);
    },

    pass(isForced) {
        this.setStatus(Status.PASSED, isForced);
    },

    fail(isForced) {
        this.setStatus(Status.FAILED, isForced);
    },

    setStatus(status, isForced) {
        this.blockData.setStatus(status, isForced);

        this.blockData.isComplete(status >= Status.COMPLETED);
    },

    checkCompletion() {
        // this.app.session.checkPageCompletion( this.blockData.moduleID() + "_" + this.blockData.pageID() );
    },

    handleVisibilityChanged(e) {
        if (e.isVisible) {
            this.$el.addClass('view-visible');
        } else {
            this.$el.removeClass('view-visible');
        }
    },

    initializeAudio() {
        const audioUrl = this.app.audioManager.formatAudioUrl(this.id);
        this.hasAudio = this.app.audioManager.add(this.id, audioUrl);

        if (this.blockData.isFirst()) {
            this.startAudio();
        }
    },

    startAudio() {
        if (!this.hasAudio) return;

        this.app.audioManager.play(this.id);
    },

    stopAudio() {
        if (!this.hasAudio) return;

        this.app.audioManager.stop(this.id);
    },

    pauseAudio() {
        if (!this.hasAudio) return;

        this.app.audioManager.pause(this.id);
    },

    resumeAudio() {
        if (!this.hasAudio) return;

        this.app.audioManager.resume(this.id);
    },

    /**
     * Handle page suspended event. Pause anything that shouldn't run in the background.
     *
     * @param  {object} e Page suspended event object
     */
    handleSuspend(e) {
        this.pauseAudio();
    },

    /**
     *  Handle page restored event. Resume what was paused for page suspension.
     *
     * @param  {object} e Page restored event object
     */
    handleRestore(e) {
        this.resumeAudio();
    },

    /**
     * The templates extending block overriding this function will return an array of assets to be loaded
     * Load background image if present.
     *
     * @memberof Block#
     * @private
     * @returns {Array} array of assets to be loaded
     */
    getResources() {
        const resources = [];
        if (!this.pageData) return resources;

        const background = this.pageData.getArg('background');
        if (background) {
            resources.push(background);
        }

        return resources;
    },

    getId() {
        return this.id;
    },

    setupRenderer() {
        // @TODO can refactor?
        this.start();
        // this.app.exitFullScreen(); // @TODO remove?
        this.render();

        if (this.blockData.isFirst()) {
            this.blockData.isVisible(true);
        }

        this.trigger('onRendered', {
            target: this,
        });
    },

    setupListeners() {
        this.listenTo(
            this.blockData,
            'onVisibilityChanged',
            this.handleVisibilityChanged
        );
        this.listenTo(
            this.app.screenManager,
            'onPageSuspended',
            this.handleSuspend
        );
        this.listenTo(
            this.app.screenManager,
            'onPageRestored',
            this.handleRestore
        );
    },
});

export default ReactBlock;
