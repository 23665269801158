import SaffronError from '../../utils/SaffronError';
import Model from './Model';

export default class ContainerPlugin extends Model {
    /**
     * @type {string}
     * @abstract
     */
    name;

    /**
     * Array of required module names
     */
    requires = [];

    /**
     * Default config for this plugin
     */
    defaultConfig = {};

    declaredConfig = {};

    constructor(config) {
        super();
        this.declaredConfig = config;
    }

    /**
     * @returns {Object.<string, any>}
     */
    get config() {
        return {
            ...this.defaultConfig,
            ...this.declaredConfig,
        };
    }

    setup(container) {
        if (this.disabled) {
            return;
        }

        const missingRequirements = this.requires.filter(name => !container.componentList[name]);

        if (missingRequirements.length) {
            throw new SaffronError(
                'container-plugin/missing-requirement',
                `${this.name} is missing required modules: ${missingRequirements.join(', ')}. Required modules must be loaded before this one.`,
            );
        }

        this.container = container;
        this.afterSetup();
    }

    afterSetup() {}

    /**
     * If true, this module will not finish setting up.
     */
    get disabled() {
        return false;
    }
}
