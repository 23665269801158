import Backbone from 'backbone';
import JunctionRenderer from '../renderer/junctionrenderer.js';

const JunctionController = Backbone.Model.extend({
    constructor() {
        this.instance = undefined;
        this.app = undefined;
        this.currentModuleNumber = undefined;
        this.currentPageNumber = undefined;
        this.currentPageData = undefined;
        this.previousPageData = undefined;
        this.isDestroyed = undefined;

        Backbone.Model.apply(this, arguments);
    },

    initialize(app, data, $wrapper, instance) {
        this.app = app;
        this.pageRenderer = new JunctionRenderer(app, this, $wrapper, data);
        this.data = data;
        this.instance = instance;

        this.listenTo(this, 'onChangeSlide', function (e) {
            this.checkPageCompletion();
        });

        this.listenTo(this, 'onBlockCompleted', function (e) {
            this.checkPageCompletion();
        });
        this.listenTo(this, 'onCheckPageCompletion', function (e) {
            this.pageStatusUpdate();
        });
    },

    getInstance() {
        return this.instance;
    },

    destroy() {
        this.isDestroyed = true;

        this.pageRenderer.destroy();

        this.stopListening();
        this.off();
    },

    createListeners() {
        // this.listenTo(this, "onSwitchChange", this.handleCompletionCheck );
        // this.listenTo(this, "onBeforeSwitchChange", this.handleCompletionCheck );
        /*
		this.listenTo(this, "onPageChange", this.handleCompletionCheck );
		this.listenTo(this, "onModuleStarted", this.handleCompletionCheck );
		this.listenTo(this, "onModuleCompleted", this.handleCompletionCheck );
		this.listenTo(this, "onPageChange", this.handleCompletionCheck );
		*/
    },

    /*
	handleCompletionCheck:function( e )
	{

		if( !this.currentPageData ) return;

		if( this.currentPageData ) this.currentPageData.checkCompletion();

		if( this.currentModuleNumber > -1 && this.currentModuleNumber != undefined ) this.data.checkModuleCompletion( this.currentModuleNumber );

		this.data.checkCourseCompletion();

	},
	*/

    /*
	handlePageCompletionCheck:function()
	{
		// this.currentPageData.checkCompletion();

		// this.data.checkPageCompletion();
	},
	*/

    switchPage(pageID) {
        const newPageData = this.data.getPageById(pageID);

        if (!newPageData) {
            // Logger.warn("Sante.Controller.switchPage.fail (", pageID, ") does not exist!" );

            return false;
        }

        var event = new Object();
        event.target = this;
        event.pageData = newPageData;
        event.previousPageData = this.currentPageData;

        this.trigger('onBeforeSwitchChange', event);

        // this.destroyCurrentPage();

        this.previousPageData = this.currentPageData;
        this.currentModuleNumber = newPageData.moduleNumber();
        this.currentPageNumber = newPageData.pageNumber();
        this.currentPageData = newPageData;

        var event = new Object();
        event.pageData = this.currentPageData;
        event.previousPageData = this.previousPageData;

        this.trigger('onSwitchChange', event);

        if (!this.isDestroyed) this.update();
    },

    routeToPage(pageID) {
        this.app.router.navigate(`/page/${pageID}`, {
            trigger: true,
            replace: true,
        });
    },

    routeToHome() {
        if (this.isDestroyed) return false;

        this.app.router.routeToHome();
    },

    startModule(number) {
        if (this.isDestroyed) return false;

        this.currentModuleNumber = Number(number);
        this.currentPageNumber = 0;

        const id = this.data
            .getModule(this.currentModuleNumber)
            .getPage(this.currentPageNumber);

        this.currentPageData = this.data.getPageById(id);

        this.update();

        this.trigger('onModuleStarted', {
            target: this,
            moduleNumber: this.currentModuleNumber,
        });

        this.routeToPage(id.pageID());
    },

    next() {
        if (this.isDestroyed) return false;

        if (!this.currentModuleNumber && this.currentModuleNumber !== 0) {
            return false;
        }

        const module = this.data.getModule(this.currentModuleNumber);

        const totalPages = module.getTotalPages();

        if (this.currentPageNumber + 1 < totalPages) {
            const page = this.data
                .getModule(this.currentModuleNumber)
                .getPage(this.currentPageNumber + 1);
            this.routeToPage(page.pageID());
        } else {
            this.endModule();
        }
    },

    previous() {
        if (this.isDestroyed) return false;

        if (!this.currentModuleNumber && this.currentModuleNumber !== 0) {
            return false;
        }

        if (this.currentPageNumber - 1 >= 0) {
            const page = this.data
                .getModule(this.currentModuleNumber)
                .getPage(this.currentPageNumber - 1);
            this.routeToPage(page.pageID());
        } else {
            this.endModule();
        }
    },

    historyBack() {
        this.app.router.historyBack();
    },

    endModule() {
        const event = new Object();
        event.module = this.currentModuleNumber;
        event.pageData = this.currentPageData;

        this.trigger('onModuleEnded', {
            target: this,
            moduleNumber: this.currentModuleNumber,
            pageData: this.currentPageData,
        });

        this.routeToHome();
    },

    destroyCurrentPage() {
        this.destroyPage(this.currentPageData);
    },

    destroyPage(pageData) {
        if (!pageData) return false;

        pageData.blocks.each(function (blockData) {
            if (blockData.view) blockData.view.destroy();
        });
    },

    // NP: Not sure if this and above commented code is required
    handleBlockStatusUpdated(event) {
        // this.trigger("onBlockCompleted", event);
        // this.checkPageCompletion();
        // this.handleCompletionCheck();
        // this.pageStatusUpdate();
    },

    blockCompleted(event) {
        this.trigger('onBlockCompleted', event);
    },

    checkPageCompletion() {
        if (this.currentPageData == null) {
            return;
        }

        const currentPage = this.currentPageData;

        currentPage.checkCompletion();
        // console.warn("Session.checkPageCompletion.isComplete = " + isComplete)

        if (currentPage.getStatus() >= 3) {
            // this.completeCurrentSlide();
            this.checkModuleCompletion();
        }

        const isComplete = currentPage.isComplete();
        const event = {
            target: this,
            isComplete,
        };

        this.trigger('onCheckPageCompletion', event);

        this.pageStatusUpdate();

        this.data.checkCourseCompletion();
    },

    pageStatusUpdate() {
        const event = new Object();
        event.pageData = this.currentPageData;

        this.trigger('onPageStatusUpdate', event);
    },

    checkModuleCompletion() {
        const progress = this.data.getTotalCompletedPagesByModule(
            this.currentPageData.moduleNumber()
        ).percentage;

        if (progress >= 100) {
            const event = new Object();
            event.pageData = this.currentPageData;

            this.trigger('onModuleCompleted', {
                target: this,
                pageData: this.currentPageData,
            });
            // this.trigger("onModuleExit", event);
        }
    },

    update() {
        if (!this.currentPageData) return;

        const currentModuleNumber = this.currentPageData.moduleNumber();

        const event = new Object();
        event.target = this;
        event.pageData = this.currentPageData;
        event.pageNumber = this.currentPageData.pageNumber();
        event.previousPageData = this.previousPageData;
        event.totalPages = currentModuleNumber
            ? this.data.getModule(currentModuleNumber).getTotalPages()
            : 0;

        this.trigger('onChangeSlide', event);

        // this.pageRenderer.changePage( this.currentPageData );
        // if(this.previousPageData) this.pageRenderer.unloadPage( this.previousPageData );
    },

    playSound(id) {
        this.trigger('onPlaySound', {
            target: this,
            id,
        });
    },
});

export default JunctionController;
