import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ReactHtmlParser from 'react-html-parser';
import './Launch.scss';
import { Container } from 'react-bootstrap';
import BuildAnimation from '~components/animations/BuildAnimation';

/**
 * @memberOf ReactBlocks
 * @schema json schema
 */

class Launch extends Component {
    /**
     * @param props
     */
    constructor(props) {
        super(props);
        this.container = this.props.blockRef.container;
        /** @type {import('./launch-types').LaunchSchema} */
        this.data = this.props.blockRef.data;
        this.isLanguageSelection = this.data.languages.length > 1;
        this.enableOnlyEnglish = this.props.blockRef.enableOnlyEnglish;
        this.cardRef = React.createRef();

        this.state = {
            title: this.data.languages[0].title,
            text: this.data.languages[0].text,
            instruction: this.data.languages[0].instruction,
            confirmButtonLabel: this.data.languages[0].confirmButtonLabel,
            selectedIndex: 0,
            language: 'en',
            isLaunched: false,
        };
        this.loadCourseJSON();
    }

    loadCourseJSON() {
        $.getJSON(this.container.courseXML, (data) => {
            this.container.screenManager.setPageTitle(
                data.title,
                'Launch_page'
            );
        });
    }

    componentDidMount() {
        BuildAnimation.animateIn(this.cardRef.current);
    }

    /**
     * Update which item is selected and will use that language's text.
     *
     * @param {index} index
     */
    handleLanguageClick(index) {
        const selectedItem = this.data.languages[index];
        const { title, text, instruction, confirmButtonLabel, languageCode } =
            selectedItem;
        if (typeof selectedItem === 'undefined') return;
        this.setState({
            selectedIndex: index,
            title,
            text,
            instruction,
            confirmButtonLabel,
            language: languageCode,
        });
    }

    /**
     * Will trigger an event that will start the course.
     */
    handleLaunch() {
        // GG: 24th June 2021: Added this from 0915BGRU course (0891BGRC had a issue on iOS device playing the audio of 000_020)
        this.container.audioManager.add('blank', 'assets/mp3/blank.mp3');
        this.container.audioManager.play('blank');
        this.setState({
            isLaunched: true,
        });
        this.props.blockRef.trigger('onLaunchCourse', {
            language: this.state.language,
        });
        this.props.blockRef.destroy();
    }

    /**
     * @returns {ReactNode}
     */
    render() {
        const {
            title,
            text,
            instruction,
            confirmButtonLabel,
            selectedIndex,
            isLaunched,
        } = this.state;

        return (
            <Container>
                <Card
                    className="launch-block p-4 text-center"
                    ref={this.cardRef}
                >
                    {' '}
                    {title && <h2> {ReactHtmlParser(title)} </h2>}{' '}
                    {text && <p> {ReactHtmlParser(text)} </p>}{' '}
                    {this.isLanguageSelection && instruction && (
                        <p> {ReactHtmlParser(instruction)} </p>
                    )}{' '}
                    {this.isLanguageSelection && (
                        <div className="button-wrapper my-3">
                            <ButtonGroup
                                className="flex-column w-50"
                                role="group"
                            >
                                {' '}
                                {this.data.languages.map(function (item, i) {
                                    const isActive = selectedIndex === i;
                                    return (
                                        <Button
                                            variant="light"
                                            key={i}
                                            className="language rounded-0 m-1"
                                            active={isActive}
                                            disabled={
                                                item.languageCode !== 'en' &&
                                                this.enableOnlyEnglish
                                            }
                                            onClick={() =>
                                                this.handleLanguageClick(i)
                                            }
                                        >
                                            {item.buttonLabel}{' '}
                                        </Button>
                                    );
                                }, this)}{' '}
                            </ButtonGroup>{' '}
                        </div>
                    )}{' '}
                    <div className="button-wrapper">
                        <Button
                            disabled={
                                isLaunched ||
                                typeof selectedIndex === 'undefined'
                            }
                            onClick={() => this.handleLaunch()}
                        >
                            {confirmButtonLabel}{' '}
                        </Button>{' '}
                    </div>{' '}
                </Card>
            </Container>
        );
    }
}

export default Launch;
