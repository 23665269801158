import _ from 'underscore';
import Backbone from 'backbone';
import { Logger } from '../../index';
import Status from './status.js';

/**
 * @class Create a BlockData of a block
 * @name BlockData
 * @property {number} id
 * @property {string} moduleID
 * @property {string} pageID
 * @property {number} order
 * @property {string} blockID
 * @property {string} blockXML
 * @property {ref} $blockDom
 * @property {string} title
 * @property {string} template
 * @property {ref} view
 * @property {boolean} isRendered
 * @property {number} blockStatus
 * @property {number} visiblePercentage
 * @property {boolean} isVisible
 * @property {boolean} isComplete
 * @property {string} args
 * @property {boolean} isVisibleFromStart
 * @property {ref} preloader
 * @property {boolean} isDestroyed
 *
 * @augments Backbone.Model
 *
 * @see Backbone.Model
 */
const BlockData = Backbone.Model.extend(
    /** @lends BlockData.prototype */ {
        defaults: {
            id: 0,
            moduleID: '',
            pageID: '',
            order: 0,
            blockID: '',
            blockXML: '',
            $blockDom: '',
            title: '',
            template: '', // Name of template in course XML
            view: undefined,
            isRendered: false,
            blockStatus: undefined,
            visiblePercentage: 0.5, // Used to determine how much of a block is needed to be treated as visible.
            isVisible: false,
            isComplete: null,
            args: undefined,
            isVisibleFromStart: false,
            preloader: undefined,
            isDestroyed: false,
        },

        /**
         * Set or get moduleID in which the block belongs to.
         *
         * @param {string} value
         * @returns {string} moduleID
         */
        moduleID(value) {
            if (value) {
                this.set({
                    moduleID: value,
                });
            }

            return this.get('moduleID');
        },

        /**
         * Set or get pageID where block is rendered
         *
         * @param {string} value
         * @returns {string} pageID
         */
        pageID(value) {
            if (value) {
                this.set({
                    pageID: value,
                });
            }

            return this.get('pageID');
        },

        /**
         * Set or get order of a block
         *
         * @param {number} value
         * @returns {number} order
         */
        order(value) {
            if (value) {
                this.set({
                    order: value,
                });
            }

            return this.get('order');
        },

        /**
         * Set or get blockID of a block
         *
         * @param {string} value
         * @returns {string} blockID
         */
        blockID(value) {
            if (value) {
                this.set({
                    blockID: value,
                });
            }

            return this.get('blockID');
        },

        /**
         * Set or get XML path of a block
         *
         * @param {string} value
         * @returns {string} XML path
         */
        blockXML(value) {
            if (value) {
                this.set({
                    blockXML: value,
                });
            }

            return this.get('blockXML');
        },

        /**
         * Set or get title of a block
         *
         * @param {string} value
         * @returns {string} title
         */
        title(value) {
            if (value) {
                this.set({
                    title: value,
                });
            }

            return this.get('title');
        },

        /**
         * Set or get template of a block
         *
         * @param {string} value
         * @returns {string} template
         */
        template(value) {
            if (value) {
                this.set({
                    template: value,
                });
            }

            return this.get('template');
        },

        /**
         * Set or get visible percentage of a block
         *
         * @param {number} value
         * @returns {number} visible percentage
         *
         */
        visiblePercentage(value) {
            if (value) {
                this.set({
                    visiblePercentage: value,
                });
            }

            return this.get('visiblePercentage');
        },

        /**
         * Set or get arguments of a block
         *
         * @param {string} value
         * @returns {string} arguments
         */
        args(value) {
            if (value) {
                this.set({
                    args: value,
                });
            }

            return this.get('args');
        },

        /**
         * Get particular argument of a block using its name
         *
         * @param {string} name
         * @returns {string} null if not found | value of particular argument
         */
        getArg(name) {
            let args = this.args();

            // Exit, if nothing is defined
            if (!args) return;

            args = args.split('|');

            let result = null;

            _.each(
                args,
                function (arg) {
                    const parts = arg.split('=');

                    if (parts.length === 2) {
                        if (parts[0] === name) {
                            result = parts[1];
                            return true;
                        }
                    } else {
                        Logger.warn(
                            'Invalid arg for block:',
                            this.pageID(),
                            arg
                        );
                    }
                },
                this
            );

            return result;
        },

        /**
         * Set visibility of a block to true/false
         *
         * @param {boolean} b
         */
        isVisible(b) {
            // Get current visibility
            const isVisible = this.get('isVisible');

            // Check if different to previous
            if (b == isVisible) return isVisible;

            this.set({
                isVisible: b,
            });

            /**
             * onVisibilityChanged is fired when visibility of a block is changed
             *
             * @event BlockData#onVisibilityChanged
             * @type {object}
             * @property {BlockData} target
             * @property {boolean} isVisible
             */
            this.trigger('onVisibilityChanged', {
                target: this,
                isVisible,
            });
        },

        /**
         * Set or get the completion state of a block
         *
         * @param {boolean} val
         * @returns {boolean} completion state of a block
         */
        isComplete(val) {
            if (val) {
                this.set({
                    isComplete: val,
                });
            }

            return this.get('isComplete');
        },

        /**
         * Set or get the render state of a block
         *
         * @param {boolean} val
         * @returns {boolean} render state of a block
         */
        isRendered(val) {
            if (val) {
                this.set({
                    isRendered: Boolean(val),
                });
            }

            return this.get('isRendered');
        },

        /**
         * check if its first block of a page
         *
         * @returns {boolean} true/false based on position of the block
         */
        isFirst() {
            return this.blockID() === '0';
        },

        /**
         * Set or get the visibility state of a block from the start or first time
         *
         * @param {boolean} val
         * @returns {boolean} true/false based on visibility state set for a block from the start
         */
        isVisibleFromStart(val) {
            if (val) {
                this.set({
                    isVisibleFromStart: Boolean(val),
                });
            }

            return this.get('isVisibleFromStart');
        },

        /**
         * Set or get the destroyed state of a block
         *
         * @param {boolean} val
         * @returns {boolean} true/false based on block being destroyed or not
         */
        isDestroyed(val) {
            if (val !== undefined) {
                this.set({
                    isDestroyed: Boolean(val),
                });
            }

            return this.get('isDestroyed') === true;
        },

        /**
         * Reset the block state by resting $blockDom, isRendered and isDestroyed.
         */
        reset() {
            this.$blockDom = '';
            this.set({
                isRendered: false,
            });
            this.set({
                isDestroyed: false,
            });
        },

        /**
         * Reset the completion state of a block and make it available
         *
         *  @returns {boolean} completion state of a block
         */
        resetCompletion() {
            this.isComplete(false);
            this.setStatus(Status.AVAILABLE, true);

            return this.get('isComplete');
        },

        /**
         * Set status of a block
         *
         * @param {number} status - could be any number from 0 to 7, refer status.js for more details
         * @param {boolean} isForced - true/false to decide setting status forcefully or not
         * @returns {number} returns the block status, any number from 0 to 7, refer status.js for more details
         */
        setStatus(status, isForced) {
            if (this.getStatus() == status) {
                // Generic status update event
                this.trigger('onStatusUpdate', {
                    target: this,
                    status: this.getStatus(),
                    changed: false,
                });
                return;
            }

            if (status < this.getStatus() && !isForced) return;

            // set the status
            this.set({
                blockStatus: status,
            });

            const event = {
                target: this,
                status: this.getStatus(),
                changed: true,
            };

            // Generic status update event
            /**
             * onStatusUpdate is fired when status is updated for a block
             *
             * @event BlockData#onStatusUpdate
             * @type {object}
             * @property {BlockData} target
             * @property {number} status
             * @property {boolean} changed
             */
            this.trigger('onStatusUpdate', event);

            // More specific events for specific block statuses
            switch (this.getStatus()) {
                case Status.PASSED:
                    /**
                     * onPassed is fired when status of a block is updated to passed
                     *
                     * @event BlockData#onPassed
                     * @type {object}
                     * @property {BlockData} target
                     * @property {number} status
                     * @property {boolean} changed
                     */
                    this.trigger('onPassed', event);
                    break;
                case Status.COMPLETED:
                    /**
                     * onCompleted is fired when status of a block is updated to completed
                     *
                     * @event BlockData#onCompleted
                     * @type {object}
                     * @property {BlockData} target
                     * @property {number} status
                     * @property {boolean} changed
                     */
                    this.trigger('onCompleted', event);
                    break;
                case Status.FAILED:
                    /**
                     * onFailed is fired when status of a block is updated to failed
                     *
                     * @event BlockData#onFailed
                     * @type {object}
                     * @property {BlockData} target
                     * @property {number} status
                     * @property {boolean} changed
                     */
                    this.trigger('onFailed', event);
                    break;
                case Status.STARTED:
                    /**
                     * onStarted is fired when status of a block is updated to started
                     *
                     * @event BlockData#onStarted
                     * @type {object}
                     * @property {BlockData} target
                     * @property {number} status
                     * @property {boolean} changed
                     */
                    this.trigger('onStarted', event);
                    break;
                default:
                    'No event to dispath';
            }

            return this.blockStatus;
        },
        /**
         * Get status of a block
         *
         * @returns {number} returns the block status, any number from 0 to 7, refer status.js for more details
         */
        getStatus() {
            return this.get('blockStatus');
        },
    }
);

export default BlockData;
