import Backbone from 'backbone';
/**
 * @class Create a BlockData of a block
 * @name Component
 *
 * @property {string} name - name of the component
 * @property {Container} container - reference of container
 * @property {Session} session - reference of session
 *
 * @augments Backbone.Model
 *
 * @see Backbone.Model
 */
const Component = Backbone.Model.extend(
    /** @lends Component.prototype */ {
        constructor() {
            this.name = '';
            this.container = null;
            this.session = undefined;

            Backbone.Model.apply(this, arguments);
        },
    }
);

export default Component;
