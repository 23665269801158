import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import './styles/main.css';
import './modules/ContentReplacer';

import * as BespokeBlocks from './blocks';

import ReactBridge from './modules/ReactBridge';

import Container from './core/container';
import {
    AccordionBlock,
    AdminCourseOverview,
    AdminImageReview,
    AdminModules,
    AdminPages,
    AdminSearchAssets,
    AdminTemplates,
    CarouselBlock,
    ConnectionNotification,
    // Container,
    CoursemapBlock,

    ModuleSequentialUnlocker,
    // NavigationManager,
    Placeholder,
    QuickFireQuestionBlock,
    QuizProgressManager,
    QuizResultBlock,
    ReviewMode,
    TextColumnsBlock,
    WarningNotification,

} from '~templates/index.js';

import ReviewPage from '~components/reviewtool/ReviewPage';

import {
    Accessibility,
    Block,
    BrowserDetect,
    Component,
    GlobalScoringPlugin,
    Junction,
    JunctionRouter,
    LanguageManager,
    LogCollector,
    Logger,
    ManifestHelper,
    ModuleData,
    QuizJunction,
    QuizJunctionController,
    Scoring,
    ScreenRenderer,
    Status,
    Storage,
    Utils,
    ViewportManager,
    WindowHelper,
} from '~core';

const templates = {
    QuizResult: QuizResultBlock,
    Accordion: AccordionBlock,
    Carousel: CarouselBlock,
    Coursemap: CoursemapBlock,
    QuickFireQuestion: QuickFireQuestionBlock,
    TextColumn: TextColumnsBlock,
    TextColumns: TextColumnsBlock,
    Placeholder,

    AdminCourseOverview,
    AdminSearchAssets,
    AdminModules,
    AdminPages,
    AdminTemplates,
    AdminImageReview,
    ...BespokeBlocks,
};

for (const template in templates) {
    const temp = ReactBridge.extend({
        name: template,
        reactComponent: templates[template],
    });
    window[template] = temp;
}

export { default as LaunchPage } from './modules/LaunchPage';
export { default as NavigationManager } from './modules/NavigationManager';
export { default as Firebase } from './modules/Firebase';
export { default as RoomSync } from './modules/RoomSync';
export { default as OrientationOverlay } from './modules/OrientationOverlay';
export { default as BackgroundManager } from './modules/BackgroundManager';
export { default as Diagnostics } from './modules/Diagnostics';

export {
    $,
    _,
    Backbone,
    // Core
    Status,
    BrowserDetect,
    Utils,
    Block,
    Junction,
    QuizJunction,
    QuizJunctionController,
    Component,
    ModuleData,
    ScreenRenderer,
    WindowHelper,
    JunctionRouter,
    LanguageManager,
    ManifestHelper,
    Logger,
    Accessibility,
    ViewportManager,
    LogCollector,
    Storage,
    Scoring,
    Container,
    GlobalScoringPlugin,
    // Components
    // NavigationManager,
    ConnectionNotification,
    WarningNotification,
    ModuleSequentialUnlocker,
    QuizProgressManager,
    ReviewPage,
    ReviewMode,
    // Templates
    templates,
};
