import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import {
    Accordion,
    Button,
    Card,
    Col,
    Form,
    Image,
    ListGroup,
    Row,
} from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import { MdAdd, MdDelete, MdEdit, MdSend } from 'react-icons/md';
import { Utils } from '~core';
/**
 * @param props
 */
function UpdateListItem(props) {
    const {
        labels,
        location,
        listItem,
        comments,
        users,
        data,
        onSubmitClick,
        onCancelClick,
        onAddImage,
        onAddComment,
    } = props;

    const { clients, type } = data;

    const isInternal = type === 'internal';

    const [validated, setValidated] = useState(false);
    const [isSendCommentDisabled, setIsSendCommentDisabled] = useState(true);

    const loggedBy = users.filter(
        (user) => user.Id === listItem.IssueloggedbyId
    );
    const loggedByClient = listItem.Issueloggedbyclient;

    const reportedOn = listItem.DateReported
        ? listItem.DateReported.substring(0, 10)
        : new Date().toISOString().substring(0, 10);

    const imagePath = listItem.Images
        ? JSON.parse(listItem.Images).serverUrl +
          JSON.parse(listItem.Images).serverRelativeUrl
        : '';

    const assignedTo = useRef();
    const environment = useRef();
    const comment = useRef();
    const commentLoggedBy = useRef();

    const arrayBufferToBase64 = (buffer) => {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i += 1) {
            binary += String.fromCharCode(bytes[i]);
        }
        return binary;
    };

    const buildFileSelector = () => {
        const fileSelector = document.createElement('input');
        fileSelector.setAttribute('type', 'file');
        fileSelector.setAttribute('accept', 'image/png, image/jpeg');
        fileSelector.onchange = () => {
            const file = Array.from(fileSelector.files)[0];
            const reader = new FileReader();
            reader.onload = (e) => {
                const byteArray = arrayBufferToBase64(e.target.result);
                onAddImage(file.name, byteArray);
            };
            reader.readAsArrayBuffer(file);
        };

        return fileSelector;
    };
    const fileSelector = buildFileSelector();

    const handleSubmitClick = (event) => {
        event.preventDefault();
        if (event.currentTarget.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            const formData = new FormData(event.target);
            const item = Object.fromEntries(formData.entries());

            // @ts-expect-error
            item.Location = [item.Location];

            if (isInternal) {
                item.AssignedtoId = [].slice
                    .call(assignedTo.current.selectedOptions)
                    .map((option) => option.value);

                item.AssignedtoStringId = [].slice
                    .call(assignedTo.current.selectedOptions)
                    .map((option) => `${option.value}`);
            }

            item.Affected_x0020_environment = [].slice
                .call(environment.current.selectedOptions)
                .map((option) => option.value);

            if (item.IssueloggedbyId)
                item.IssueloggedbyStringId = String(item.IssueloggedbyId);

            onSubmitClick(item);
        }
    };

    const handleCancelClick = (event) => {
        onCancelClick(event);
    };

    const handleAddClick = () => {
        fileSelector.click();
    };

    // const handleEditClick = () => {
    //     console.info('edit the image!');
    // };

    const handleDeleteClick = () => {
        listItem.Images = '';
        onSubmitClick(listItem);
    };

    const formatComments = (mentions, text) => {
        let resultText = text;
        mentions.map((item, i) => {
            resultText = resultText
                .split(`@mention&#123;${i}&#125;`)
                .join(`<b>${item.name}</b>`);
            return resultText;
        });

        resultText = resultText
            .split('&#123;')
            .join(`<b>`)
            .split('&#125;')
            .join('</b>');

        return ReactHtmlParser(resultText);
    };

    const handleAddComment = () => {
        const commentDom = comment.current;
        let text = commentDom.value;
        text = `&#123;${commentLoggedBy.current.value}&#125; ${text}`;
        // @ts-expect-error
        commentDom.value = '';
        setIsSendCommentDisabled(true);
        onAddComment({ text });
    };

    const handleCommentLoggedBy = (event) => {
        setIsSendCommentDisabled(
            event.target.value === '' || comment.current.value === ''
        );
    };

    return (
        <Accordion defaultActiveKey="0">
            <Card className="w-100">
                <Accordion.Toggle as={Card.Header} className="p-2" eventKey="0">
                    Update
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                    <Card.Body className="p-2">
                        <Form
                            noValidate
                            validated={validated}
                            onSubmit={handleSubmitClick}
                        >
                            <Form.Group controlId="updateForm.title">
                                <Form.Label>Summary *</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    name="Title"
                                    size="sm"
                                    defaultValue={listItem.Title}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide title/summary of the issue.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="updateForm.description">
                                <Form.Label>Description *</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    name="Description"
                                    as="textarea"
                                    rows={3}
                                    size="sm"
                                    defaultValue={Utils.stripHTML(
                                        listItem.Description
                                    )}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide detailed description of the
                                    issue.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Card className="w-100 mt-2 mb-2">
                                <Card.Body className="p-2">
                                    <Form.Group as={Row}>
                                        <Form.Label column>Comments</Form.Label>
                                        <Col sm={12} className="mb-1">
                                            {isInternal ? (
                                                <Form.Control
                                                    as="select"
                                                    ref={commentLoggedBy}
                                                    size="sm"
                                                    onChange={
                                                        handleCommentLoggedBy
                                                    }
                                                >
                                                    <option value="">
                                                        Select from
                                                    </option>
                                                    {users.map((item) => (
                                                        <option
                                                            key={item.Id}
                                                            value={item.Title}
                                                        >
                                                            {item.Title}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            ) : (
                                                <Form.Control
                                                    as="select"
                                                    ref={commentLoggedBy}
                                                    size="sm"
                                                    onChange={
                                                        handleCommentLoggedBy
                                                    }
                                                >
                                                    <option value="">
                                                        Select from
                                                    </option>
                                                    {clients.map((item, i) => (
                                                        <option
                                                            key={i.toString()}
                                                            value={item}
                                                        >
                                                            {item}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            )}
                                        </Col>
                                        <Col sm={12} className="mb-1">
                                            <Form.Control
                                                as="textarea"
                                                ref={comment}
                                                size="sm"
                                                onChange={handleCommentLoggedBy}
                                            />
                                        </Col>
                                        <Col sm={12}>
                                            <Button
                                                className="comment-add-button float-right"
                                                variant="light"
                                                onClick={handleAddComment}
                                                disabled={isSendCommentDisabled}
                                            >
                                                Add comment <MdSend />
                                            </Button>
                                        </Col>
                                    </Form.Group>
                                    <ListGroup>
                                        {comments.map((item, i) => {
                                            const created = new Date(
                                                item.createdDate
                                            )
                                                .toString()
                                                .substr(4, 17);
                                            return (
                                                <ListGroup.Item
                                                    key={i.toString()}
                                                    id={i.toString()}
                                                    className="p-2"
                                                >
                                                    <h2 className="comment-title">
                                                        {/* {item.author.name}{' '} */}
                                                        <span>({created})</span>
                                                    </h2>
                                                    <div className="comment-desc">
                                                        {formatComments(
                                                            item.mentions,
                                                            item.text
                                                        )}
                                                    </div>
                                                </ListGroup.Item>
                                            );
                                        })}
                                    </ListGroup>
                                </Card.Body>
                            </Card>
                            <Form.Group
                                as={Row}
                                controlId="updateForm.location"
                            >
                                <Form.Label column sm={4}>
                                    Location *
                                </Form.Label>
                                <Col sm={8}>
                                    <Form.Control
                                        required
                                        name="Location"
                                        as="select"
                                        size="sm"
                                        defaultValue={listItem.Location[0]}
                                    >
                                        <option value={location}>
                                            {location}
                                        </option>
                                        <option value="global">global</option>
                                    </Form.Control>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="updateForm.tyle">
                                <Form.Label column sm={4}>
                                    Type *
                                </Form.Label>
                                <Col sm={8}>
                                    <Form.Control
                                        name="IssueType"
                                        as="select"
                                        size="sm"
                                        defaultValue={listItem.IssueType}
                                    >
                                        {labels.issueType.map((item, i) => (
                                            <option key={i.toString()}>
                                                {item}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Col>
                            </Form.Group>
                            <Form.Group
                                as={Row}
                                controlId="updateForm.priority"
                            >
                                <Form.Label column sm={4}>
                                    Priority *
                                </Form.Label>
                                <Col sm={8}>
                                    <Form.Control
                                        name="Priority"
                                        as="select"
                                        size="sm"
                                        defaultValue={listItem.Priority}
                                    >
                                        {labels.priority.map((item, i) => (
                                            <option key={i.toString()}>
                                                {item}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="updateForm.status">
                                <Form.Label column sm={4}>
                                    Status *
                                </Form.Label>
                                <Col sm={8}>
                                    <Form.Control
                                        name="Status"
                                        as="select"
                                        size="sm"
                                        defaultValue={listItem.Status}
                                    >
                                        {labels.status.map((item, i) => (
                                            <option key={i.toString()}>
                                                {item.label}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Col>
                            </Form.Group>
                            <Form.Group
                                as={Row}
                                controlId="updateForm.loggedby"
                            >
                                <Form.Label column sm={4}>
                                    Raised By *
                                </Form.Label>
                                <Col sm={8}>
                                    {isInternal && loggedByClient === null ? (
                                        <Form.Control
                                            required
                                            name="IssueloggedbyId"
                                            as="select"
                                            size="sm"
                                            defaultValue={
                                                loggedBy.length > 0
                                                    ? loggedBy[0].Id
                                                    : ''
                                            }
                                        >
                                            <option value="">Select</option>
                                            {users.map((item) => (
                                                <option
                                                    key={item.Id}
                                                    value={item.Id}
                                                >
                                                    {item.Title}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    ) : (
                                        <Form.Control
                                            required
                                            name="Issueloggedbyclient"
                                            as="select"
                                            size="sm"
                                            defaultValue={loggedByClient}
                                        >
                                            <option value="">Select</option>
                                            {clients.map((item, i) => (
                                                <option
                                                    key={i.toString()}
                                                    value={item}
                                                >
                                                    {item}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    )}
                                    <Form.Control.Feedback type="invalid">
                                        Please specify logged by.
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group
                                as={Row}
                                controlId="updateForm.dateReported"
                            >
                                <Form.Label column sm={4}>
                                    Raised On
                                </Form.Label>
                                <Col sm={8}>
                                    <Form.Control
                                        type="date"
                                        name="DateReported"
                                        className="pr-0"
                                        size="sm"
                                        max={new Date()
                                            .toISOString()
                                            .substring(0, 10)}
                                        defaultValue={reportedOn}
                                    />
                                </Col>
                            </Form.Group>
                            {isInternal && (
                                <Form.Group
                                    as={Row}
                                    controlId="updateForm.assignedTo"
                                >
                                    <Form.Label column sm={4}>
                                        Assigned To
                                    </Form.Label>
                                    <Col sm={8}>
                                        <Form.Control
                                            ref={assignedTo}
                                            multiple
                                            name="AssignedtoId"
                                            as="select"
                                            size="sm"
                                            defaultValue={listItem.AssignedtoId}
                                        >
                                            {users.map((item) => (
                                                <option
                                                    key={item.Id}
                                                    value={item.Id}
                                                >
                                                    {item.Title}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Col>
                                </Form.Group>
                            )}
                            <Form.Group
                                as={Row}
                                controlId="updateForm.environment"
                            >
                                <Col sm={12}>
                                    <Form.Label>
                                        Environment * (device/os/browser)
                                    </Form.Label>
                                    <Form.Control
                                        ref={environment}
                                        required
                                        multiple
                                        name="Affected_x0020_environment"
                                        className="environment-select"
                                        as="select"
                                        size="sm"
                                        defaultValue={
                                            listItem.Affected_x0020_environment
                                        }
                                    >
                                        {labels.environment.map((item, i) => (
                                            <option
                                                key={i.toString()}
                                                value={item}
                                            >
                                                {item}
                                            </option>
                                        ))}
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Please specify the environment.
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Button
                                className="submit-button"
                                variant="primary"
                                type="submit"
                            >
                                Update
                            </Button>{' '}
                            <Button
                                className="back-button"
                                variant="secondary"
                                onClick={handleCancelClick}
                            >
                                Cancel
                            </Button>
                        </Form>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    );
}

UpdateListItem.propTypes = {
    labels: PropTypes.objectOf(PropTypes.any).isRequired,
    location: PropTypes.string.isRequired,
    listItem: PropTypes.objectOf(PropTypes.any).isRequired,
    comments: PropTypes.arrayOf(PropTypes.any).isRequired,
    users: PropTypes.arrayOf(PropTypes.any).isRequired,
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    onSubmitClick: PropTypes.func.isRequired,
    onCancelClick: PropTypes.func.isRequired,
    onAddImage: PropTypes.func.isRequired,
    onAddComment: PropTypes.func.isRequired,
};

export default UpdateListItem;
