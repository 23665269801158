import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import styles from './StandardSlide.module.css';

function StandardSlide(props) {
    const { item } = props;

    return (
        <div className={styles.row}>
            <div className={styles.contentColumn}>
                {ReactHtmlParser(item.content)}
            </div>
            <div className={styles.imageColumn}>
                <Image
                    className="w-100 p-0 rounded-0 border-0"
                    src={item.image}
                    alt={item.imageDescription}
                    thumbnail
                />
            </div>
        </div>
    );
}

StandardSlide.propTypes = {
    item: PropTypes.shape({
        content: PropTypes.string,
        image: PropTypes.string,
        imageDescription: PropTypes.string,
    }).isRequired,
};

export default StandardSlide;
