export const getFunctionUrl = (func) => {
    const config = container.get('authConfig');
    const url = new URL(config.url);
    url.pathname = 'webservice/rest/server.php';
	url.searchParams.append('wstoken', config.wstoken);
    url.searchParams.append('moodlewsrestformat', 'json');
    url.searchParams.append('wsfunction', func);

    return url;
};
