import React from 'react';
import { Button } from 'react-bootstrap';
import { MdAttachment } from 'react-icons/md';

/**
 * Renders disabled/clickable Attachment list
 */
class EmailAttachment extends React.Component {
    /**
     * Runs funciton passed in from parent.
     * @param index
     */
    handleAttachmentClick(index) {
        this.props.onView(index);
    }

    render() {
        return (
            <div className="email-attachments border-top">
                {this.props.items.map((attachment, i) => {
                    if (attachment.link.length) {
                        return (
                            <Button
                                variant="light"
                                key={i}
                                className={`rounded-0 d-inline-flex align-items-center ${
                                    attachment.isViewed ? 'viewed' : ''
                                }`}
                                href={attachment.link}
                                onClick={() => this.handleAttachmentClick(i)}
                            >
                                <MdAttachment className="mr-2" />
                                {attachment.title}
                            </Button>
                        );
                    }
                    return (
                        <Button variant="light" disabled key={i}>
                            <MdAttachment className="mr-2" />
                            {attachment.title}
                        </Button>
                    );
                }, this)}
            </div>
        );
    }
}

export default EmailAttachment;
