import React from 'react';
import { CardDeck } from 'react-bootstrap';
import './CourseMapCards.scss';
import { useCourseMapData } from '../courseMapHooks';
import { CourseMapCard } from './CourseMapCard';

function CourseMapCards(props) {
    const { blockRef } = props;

    const data = useCourseMapData(blockRef);

    if (data === null) {
        return <div>Loading</div>;
    }

    return (
        <div className="course-map-cards">
            <CardDeck className="course-map-cards-wrapper">
                {data.modules.map((module, i) => (
                    <CourseMapCard
                        key={module.id}
                        blockRef={blockRef}
                        module={module}
                    />
                ))}
            </CardDeck>
        </div>
    );
}

CourseMapCards.propTypes = {};

export default CourseMapCards;
