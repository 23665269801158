import { useEffect, useRef } from 'react';
import BuildAnimation from '~templates/components/animations/BuildAnimation';

/**
 * Animate a ref using the BuildAnimation class
 *
 * @param {string} method - The name of the BuildAnimation method to run
 * @param {React.MutableRefObject} [ref] - The ref holding the element. If omitted, a new ref will be created
 * @param {string} [property] - If useImperativeHandle to alter the ref, this is the property that now hold the dom element
 * @returns
 */
function useBuildAnimation(method, ref = useRef(), property = undefined) {
    useEffect(() => {
        const el = property ? ref.current?.[property] : ref.current;

        if (!el) {
            return;
        }

        BuildAnimation[method](el);
    }, []);

    return ref;
}

export default useBuildAnimation;
