import React from 'react';
import ReactDOM from 'react-dom';
import { Button, Modal } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import Component from '~coresrc/component/component.js';

class ModalBox extends React.Component {
    constructor(props) {
        super(props);
        this.session = container.session;
        this.state = {
            isShow: true,
            title: LanguageManager.getString('bookmark_title'),
            message: LanguageManager.getString('bookmark_message'),
            confirmButton: LanguageManager.getString('bookmark_confirm'),
            cancelButton: LanguageManager.getString('bookmark_cancel'),
        };

        this.handleConfirm = this.handleConfirm.bind(this);
        this.handleCencel = this.handleCencel.bind(this);
    }

    async handleConfirm() {
        container.router.navigate(`#page/${this.props.bookmark}`, {
            trigger: true,
            replace: true,
        });
        await this.setState({ isShow: false });
    }

    async handleCencel() {
        await this.setState({ isShow: false });
    }

    render() {
        return (
            <div>
                <Modal show={this.state.isShow} onHide={this.handleCencel}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {ReactHtmlParser(this.state.title)}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {ReactHtmlParser(this.state.message)}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={this.handleConfirm}>
                            {this.state.confirmButton}
                        </Button>
                        <Button variant="secondary" onClick={this.handleCencel}>
                            {this.state.cancelButton}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

const BookmarkNotification = Component.extend({
    initialize() {
        this.name = 'BookmarkNotification';
    },

    setup(container) {
        this.container = container;
        const session = this.container.getSession();
        this.listenToOnce(session, 'onChangeSlide', this.handleChangeSlide);
    },

    handleChangeSlide(e) {
        const bookmark = this.container.storage.getBookmark();
        if (bookmark === -1) return;

        this.show(bookmark);
    },

    show(bookmark) {
        const component = <ModalBox bookmark={bookmark}></ModalBox>;

        this.$notification = $("<div id='bookmark-notification' />");
        this.container.dom.prepend(this.$notification);

        ReactDOM.render(component, this.$notification[0]);
    },
});

export default BookmarkNotification;
