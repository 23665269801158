import { useEffect, useRef } from 'react';
import BuildAnimation from '../components/animations/BuildAnimation';

export function useAnimatedElement(ref = undefined, method = 'animateIn') {
    const element = ref ?? useRef();

    useEffect(() => {
        if (!element.current) {
            return;
        }

        BuildAnimation[method]([element.current]);
    }, []);

    return {
        element,
    };
}
