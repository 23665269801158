// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".background__eb69c9{background-color:rgb(248 247 243/var(--tw-bg-opacity));position:relative}.background__eb69c9,.box__6ec769{--tw-bg-opacity:1}.box__6ec769{--tw-content:\"\";background-color:rgb(236 183 49/var(--tw-bg-opacity));content:var(--tw-content);height:33.333333%;position:absolute;right:0;top:0;transform-origin:top;width:100%}@media (min-width:1024px){.box__6ec769{bottom:0;top:auto;transform-origin:bottom;width:33.333333%}}.box__6ec769{animation:raise__87bece .5s ease-out}@keyframes raise__87bece{0%{transform:scaleY(0)}to{transform:scaleY(1)}}", "",{"version":3,"sources":["webpack://./src/modules/BackgroundManager/components/BoxBackground/BoxBackground.module.css"],"names":[],"mappings":"AACI,oBAAA,sDAA0B,CAA1B,iBAA0B,CAI1B,iCAJA,iBAiBA,CAbA,aAAA,eAaA,CAbA,qDAaA,CAbA,yBAaA,CAbA,iBAaA,CAbA,iBAaA,CAbA,OAaA,CAbA,KAaA,CAbA,oBAaA,CAbA,UAaA,CAbA,0BAAA,aAAA,QAaA,CAbA,QAaA,CAbA,uBAaA,CAbA,gBAaA,CAAA,CAdJ,aAgBI,oCACJ,CAEA,yBACI,GACI,mBACJ,CAEA,GACI,mBACJ,CACJ","sourcesContent":[".background {\n    @apply bg-brandbg relative;\n}\n\n.box {\n    @apply\n        content-[\"\"]\n        absolute\n        right-0\n        top-0\n        lg:bottom-0\n        lg:top-auto\n        bg-[#ecb731]\n        h-1/3\n        w-full\n        lg:w-1/3\n        origin-top\n        lg:origin-bottom\n    ;\n\n    animation: raise 0.5s ease-out;\n}\n\n@keyframes raise {\n    0% {\n        transform: scaleY(0);\n    }\n\n    100% {\n        transform: scaleY(1);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"background": "background__eb69c9",
	"box": "box__6ec769",
	"raise": "raise__87bece"
};
export default ___CSS_LOADER_EXPORT___;
