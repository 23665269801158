import React from 'react';
import Button from 'react-bootstrap/Button';
import ReactHtmlParser from 'react-html-parser';
import { Container } from 'react-bootstrap';
import Feedback from '../../feedback/Feedback';
import Listbox from '~components/listbox/QuestionListbox';
import Block from '~blocks/base/Block';
import { LanguageManager, Status } from '~core';
import './QuestionBlock.scss';
import BuildAnimation from '~components/animations/BuildAnimation';

/**
 * Question with inline or collective feedback. Listbox is used to handle single/mutli selection
 */
class QuestionBlock extends Block {
    /** @type {import('./quiz-types').QuizSchema} */
    data;

    constructor(props) {
        super(props);
        this.data = this.getData(this.props.blockRef);

        this.state = {
            isOptionSelected: false,
            isAnswered: false,
            options: this.data.options.items.map((option, index) => {
                return { ...option, id: index };
            }),
            showCollectiveFeedback: false,
            feedback: '',
        };
        this.labels = {
            confirm: LanguageManager.getString('confirm'),
            continue: LanguageManager.getString('continue'),
        };

        this.element = React.createRef();
    }

    componentDidMount() {
        BuildAnimation.animateIn(this.element.current);
    }

    getData(blockRef) {
        const { data } = blockRef;
        data.isSingleSelect = true;
        const correctOptions = [];

        _.each(
            data.options.items,
            (item) => {
                item.isSelected = false;
                if (item.isCorrect) {
                    correctOptions.push(item);
                }
            },
            this
        );

        if (correctOptions.length > 1) {
            data.isSingleSelect = false;
        }
        data.correctOptions = correctOptions;
        return data;
    }

    render() {
        const { question, context, instruction, isSingleSelect } = this.data;
        const {
            options,
            isAnswered,
            isOptionSelected,
            showCollectiveFeedback,
            feedback,
        } = this.state;

        return (
            <Container ref={this.element}>
                <div className="question-block">
                    {ReactHtmlParser(question)}
                    {context && ReactHtmlParser(context)}

                    <Listbox
                        items={options}
                        instruction={instruction}
                        isSingleSelect={isSingleSelect}
                        isAnswered={isAnswered}
                        onClick={this.handleOptionClick.bind(this)}
                    />

                    {isAnswered && showCollectiveFeedback && (
                        <Feedback className="mx-4 mt-3" data={feedback} />
                    )}

                    {!isAnswered && (
                        <Button
                            className="mt-3"
                            disabled={!isOptionSelected}
                            onClick={this.handleConfirmClick.bind(this)}
                        >
                            {this.labels.confirm}
                        </Button>
                    )}
                </div>
            </Container>
        );
    }

    /**
     * This function is passed to Listbox as props and will be called on toggle of options.
     *
     * @param {boolean} isSelected isSelected boolean
     */
    handleOptionClick(isSelected) {
        this.setState({ isOptionSelected: isSelected });
    }

    /**
     * Sets page's status to pass or fail, updates state of feedback, isAnswered, options
     */
    handleConfirmClick() {
        const { options } = this.state;
        const result = this.getResult();
        const feedback = this.data.feedback[result];

        if (feedback.title.length > 0 || feedback.content.length > 0) {
            this.setState({ showCollectiveFeedback: true });
        }

        this.setState({
            isAnswered: true,
            options,
            feedback,
        });

        const selectedIndex = [];
        options.forEach((option, i) => {
            if (option.isSelected) selectedIndex.push(i);
        });

        this.props.blockRef.trigger('onSubmitAnswer', {
            status: result === 'correct' ? Status.PASSED : Status.FAILED,
            selectedOptions: selectedIndex,
        });

        if (result === 'correct') {
            this.props.blockRef.pass();
        } else {
            this.props.blockRef.fail();
        }
    }

    /**
     * Calculates the outcome of question.
     *
     * @returns {string} result in string
     */
    getResult() {
        let result;
        let correctCount = 0;
        const totalCorrectCount = this.data.correctOptions.length;
        if (totalCorrectCount === 0) return 'partial';

        const selectedOptions = this.state.options.filter(
            (item) => item.isSelected
        );

        selectedOptions.forEach((option) => {
            if (option.isCorrect) {
                correctCount += 1;
            }
        }, this);

        if (
            correctCount === totalCorrectCount &&
            correctCount === selectedOptions.length
        ) {
            result = 'correct';
        } else if (correctCount === 0) {
            result = 'incorrect';
        } else {
            result = 'partial';
        }

        return result;
    }

    /**
     * Navigates to the next page
     */
    handleContinueClick() {
        this.props.blockRef.app.router.next();
    }
}

export default QuestionBlock;
