import React from 'react';
import PropTypes from 'prop-types';
import Scarf from '../../../components/Scarf';

function LaunchError(props) {
    const { error } = props;

    return (
        <Scarf variant="centred" fullHeight>
            An error occurred
            <pre>{JSON.stringify(error, null, '  ')}</pre>
        </Scarf>
    );
}

LaunchError.propTypes = {
    error: PropTypes.shape({
        code: PropTypes.string,
        message: PropTypes.string,
    }).isRequired,
};

export default LaunchError;
