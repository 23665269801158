import Backbone from 'backbone';

export default class Model extends Backbone.Model {
    /**
     * Cache a the return value of callback
     * instead of running it again.
     * @param {string} key
     * @param {() => T} callback
     * @returns {T}
     * @template T
     */
    cache(key, callback) {
        if (!this[key]) {
            this[key] = callback();
        }
        return this[key];
    }
}
