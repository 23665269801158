import { useEffect, useState } from 'react';

function useAsyncMemo(callback, deps = []) {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {
        setError(undefined);
        setLoading(true);

        callback()
            .then(result => setData(result))
            .catch(error => setError(error))
            .finally(() => setLoading(false));
    }, deps);

    return [data, loading, error];
}

export default useAsyncMemo;
