import React from 'react';
import Backbone from 'backbone';
import {
    Card,
    CardDeck,
    ListGroup,
    ListGroupItem,
    Row,
    Table,
} from 'react-bootstrap';
import AdminBase from './AdminBase';
import './Admin.scss';
import AdminNavBar from './AdminNavBar';
import { MdArrowBack, MdSort } from 'react-icons/md';

/**
 * @memberOf ReactBlocks
 * @schema json schema
 */

class AdminCourseOverview extends AdminBase {
    /**
     * @param props
     */
    constructor(props) {
        super(props);
        this.data = this.props.blockRef.data;
        this.app = this.props.blockRef.app;
        this.state = this.getCourseData();

        this.app.courseData.modules.each((module) => {
            Backbone.listenTo(
                module,
                'onStatusUpdate',
                this.handleUpdate.bind(this)
            );
            Backbone.listenTo(module, 'onReset', this.handleUpdate.bind(this));
        });
    }

    handleUpdate() {
        this.setState(this.getCourseData());
    }

    /**
     * Completes the screen.
     */
    componentDidMount() {
        this.props.blockRef.complete();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleResetCourse() {
        this.app.resetCourse();
        alert('Course has been reset');
        location.reload();
    }

    render() {
        const shortcutTableRows = this.app
            .getComponent('ReviewMode')
            .combinations.map((combination, i) => {
                const splitCombo = combination.combo.split('+');

                const SpanCombo = () =>
                    splitCombo
                        .map((combo) => (
                            <span
                                className="shortcut-button py-1 px-2"
                                key={combo}
                            >
                                {' '}
                                {combo}{' '}
                            </span>
                        ))
                        .reduce((prev, curr) => [prev, ' + ', curr]);

                return (
                    <tr key={i}>
                        <td>{i + 1}</td>
                        <td>
                            <SpanCombo />
                        </td>
                        {/* <td>{combination.name}</td> */}
                        <td>{combination.description}</td>
                    </tr>
                );
            });

        const resetButton = (
            <div className="button-container mb-3">
                <button
                    onClick={() => {
                        this.handleResetCourse();
                    }}
                >
                    Reset full course
                </button>
            </div>
        );

        return (
            <div className="admin">
                <AdminNavBar />

                <div className="mx-5">
                    <CardDeck className="my-4">
                        <Card style={{ width: '70%' }}>
                            <Card.Body>
                                <Card.Title>
                                    <small>TITLE</small>
                                    <br />
                                    {this.state.course.name}
                                </Card.Title>
                                <Card.Text>
                                    <small>DESCRIPTION</small>
                                    <br />
                                    {this.state.course.description}
                                </Card.Text>
                                <Card.Text>{resetButton}</Card.Text>
                            </Card.Body>
                        </Card>

                        <Card style={{ width: '30%' }} className="text-center">
                            <ListGroup className="list-group-flush">
                                <ListGroupItem className="text-center">
                                    Name : {this.state.course.name}
                                </ListGroupItem>
                                <ListGroupItem className="text-center">
                                    Description :{' '}
                                    {this.state.course.description}
                                </ListGroupItem>
                                <ListGroupItem className="text-center">
                                    TotalModules :{' '}
                                    {this.state.course.totalModules}
                                </ListGroupItem>
                                <ListGroupItem className="text-center">
                                    TotalPages : {this.state.course.totalPages}
                                </ListGroupItem>
                                <ListGroupItem className="text-center">
                                    TotalBlocks :{' '}
                                    {this.state.course.totalBlocks}
                                </ListGroupItem>
                                <ListGroupItem className="text-center">
                                    TotalTemplates :{' '}
                                    {
                                        this.state.course.templates
                                            .pageTemplateCounts.length
                                    }
                                </ListGroupItem>
                                <ListGroupItem className="text-center">
                                    Score : {this.state.course.score}
                                </ListGroupItem>
                                <ListGroupItem className="text-center">
                                    Passmark : {this.state.course.passmark}
                                </ListGroupItem>
                                <ListGroupItem className="text-center">
                                    Status : {this.state.course.status}
                                </ListGroupItem>
                            </ListGroup>
                        </Card>
                    </CardDeck>

                    <h2 className="my-4">Shortcuts</h2>

                    <Row className="d-flex justify-content-sm-around">
                        <Table responsive="sm" className="my-4">
                            <thead>
                                <tr className="text-light bg-dark">
                                    <th className="border-0">ID</th>
                                    <th className="border-0">KEY COMBO</th>
                                    <th className="border-0">DESCRIPTION</th>
                                </tr>
                            </thead>
                            <tbody>{shortcutTableRows}</tbody>
                        </Table>
                        <Card
                            className="border-0"
                            style={{
                                width: 'fit-content',
                                padding: '2rem',
                                height: 'fit-content',
                            }}
                        >
                            <Row
                                className="my-3"
                                style={{ backgroundColor: '#d0d0d08a' }}
                            >
                                <div className="col-md-5">
                                    <MdSort
                                        style={{
                                            color: '#007bff',
                                            fontSize: '10rem',
                                        }}
                                    />
                                </div>
                                <div className="col-md-7 d-flex flex-column justify-content-center">
                                    <h2>Filter</h2>
                                    <p>
                                        Click where present to filter
                                        ascending/descending
                                    </p>
                                </div>
                            </Row>
                            <Row
                                className="my-3"
                                style={{ backgroundColor: '#d0d0d08a' }}
                            >
                                <div className="col-md-5">
                                    <MdArrowBack
                                        style={{
                                            color: '#007bff',
                                            fontSize: '10rem',
                                        }}
                                    />
                                </div>
                                <div className="col-md-7 d-flex flex-column justify-content-center">
                                    <h2>Return</h2>
                                    <p>
                                        Click to return to last page of course
                                    </p>
                                </div>
                            </Row>
                        </Card>
                    </Row>
                </div>
            </div>
        );
    }
}

export default AdminCourseOverview;
