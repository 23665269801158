// eslint-disable-next-line max-classes-per-file
import React from 'react';
import ReactDOM from 'react-dom';
import Backbone from 'backbone';
import { Power3, TimelineMax, TweenMax } from 'gsap';

class BackgroundManager extends Backbone.Model {
    constructor(properties) {
        super();
        this.name = 'BackgroundManager';
        this.json = properties.json;
        this.data = undefined;
    }

    setup(container) {
        this.container = container;
        this.listenTo(this.container, 'onLoadComplete', this.start);
    }

    start() {
        const self = this;
        $.getJSON(this.json, function (data) {
            self.onDataLoad(data.items);
            self.trigger('onBackgroundDataLoaded', { data: data.items });
        });
    }

    onDataLoad(e) {
        this.data = e;

        const component = <Background blockRef={this} />;
        this.$el = $("<div class='Background' />");
        this.container.dom.prepend(this.$el);
        ReactDOM.render(component, this.$el[0]);
    }
}

class Background extends React.Component {
    constructor(props) {
        super(props);
        this.component = this.props.blockRef;
        this.container = this.component.container;
        this.data = this.component.data;
        this.addListeners();

        this.state = {
            currentImage: '',
            perviousImage: '',
        };
        // this.currentLayer = React.createRef();
        // this.previousLayer = React.createRef();
    }

    addListeners() {
        this.component.listenTo(
            this.container.session,
            'onChangeSlide onChangeGlobalSlide onJunctionUpdate',
            (e) => this.handleChangeSlide(e)
        );

        this.component.listenTo(
            this.container.screenManager,
            'onPageRemoved',
            (e) => this.handlePageRemoved(e)
        );

        this.component.listenTo(this.component, 'onBackgroundDataLoaded', (e) =>
            this.handleDataLoaded(e)
        );
    }

    handleDataLoaded(e) {
        if (this.container.router.isInJunction()) return;
        // handleChangeSlide seems to happen before background.json is loaded
        // but for junction screens, slide event happens after. Need to double check though.
        const pageID = this.container.session.currentPageData.pageID();
        this.changeBackground(pageID);
    }

    async handleChangeSlide(e) {
        const currentImage = this.state.currentImage;

        await this.setState({
            perviousImage: currentImage,
        });
        this.changeBackground(e.pageData.pageID());
        console.log('background state  =====>', this.state);
    }

    async changeBackground(pageID) {
        if (typeof pageID === 'undefined') return;
        const page = _.find(this.data, (item) => item.page === pageID);

        if (!page) {
            this.removeBackground();
            return;
        }
        const pageBackground = page.image;

        if (pageBackground === this.state.currentImage) {
            Logger.warn('Background - preventing same image change');
            return;
        }

        this.timeline = new TimelineMax({});

        TweenMax.set(this.previousLayer, { opacity: 1 });

        // NP - Strangely some images were not getting changed before so removing before setting
        TweenMax.set(this.currentLayer, { backgroundImage: '' });
        TweenMax.set(this.currentLayer, {
            opacity: 0,
            backgroundImage: `url(${pageBackground})`,
        });

        this.timeline.add(
            TweenMax.to(this.previousLayer, 0.5, { opacity: 0.3 })
        );

        const self = this;
        const perviousImage = this.state.perviousImage;
        this.timeline.add(
            TweenMax.to(this.currentLayer, 0.5, {
                opacity: 1,
                ease: Power3.easeOut,
                onComplete() {
                    TweenMax.set(self.previousLayer, {
                        backgroundImage: `url(${perviousImage})`,
                    });
                },
            }),
            '-=0.4'
        );

        await this.setState({
            currentImage: pageBackground,
        });
    }

    removeBackground() {
        TweenMax.set(this.currentLayer, { backgroundImage: '' });
        TweenMax.set(this.previousLayer, { backgroundImage: '' });
        this.setState({ currentImage: '' });
    }

    handlePageRemoved() {
        if (this.timeline) this.timeline.kill();
    }

    render() {
        return (
            <div className="background-container">
                <div
                    ref={(div) => (this.currentLayer = div)}
                    className="image current"
                ></div>
                <div
                    ref={(div) => (this.previousLayer = div)}
                    className="image previous"
                ></div>
            </div>
        );
    }
}

export default BackgroundManager;

export { Background };
