import Backbone from 'backbone';
import $ from 'jquery';
import { Logger } from '../../../index.js';

const ManifestHelper = Backbone.Model.extend(
    {},
    {
        allowedExtensions: ['mp3'],

        urls: [],
        exists: false,
        regex: undefined,

        initialize(container) {
            ManifestHelper.regex = new RegExp(
                `.[${ManifestHelper.allowedExtensions.join('|')}]$`
            );

            this.container = container;
            this.container.preloader.addXML(
                'imsmanifest.xml',
                null,
                null,
                this,
                'onLoaded'
            );
        },

        onLoaded(e) {
            ManifestHelper.exists = !e.error;
            if (e.error) {
                Logger.warn('[ManifestHelper] imsmanifest.xml not found');
                return false;
            }

            const $xml = $(e.resource.getXML());

            $xml.find('resources file').each(function (index, item) {
                const url = item.getAttribute('href');

                if (url && ManifestHelper.regex.test(url)) {
                    ManifestHelper.urls.push(url);
                }
            });
        },

        contains(url) {
            return $.inArray(url, ManifestHelper.urls) !== -1;
        },
    }
);

export default ManifestHelper;
