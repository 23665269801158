import React, { useContext, useMemo } from 'react';
import { VideoPlayerContext } from './context.js';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function VideoOverlay(props) {
    const { children, className, visible, callbackClassName } = props;
    const context = useContext(VideoPlayerContext);
    const { classes } = context.options;
    const show = useMemo(() => visible(context));
    const outputClasses = classNames(className ?? classes?.overlay, callbackClassName(context));

    return show && <div className={outputClasses}>{children}</div>;
}

VideoOverlay.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    visible: PropTypes.func,
    callbackClassName: PropTypes.func,
};

VideoOverlay.defaultProps = {
    children: undefined,
    className: undefined,
    visible: () => true,
    callbackClassName: () => undefined,
};
