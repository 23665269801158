import React from 'react';
import ReactDOM from 'react-dom';
import { BlockProvider } from '~blocks/index';
import { ReactBlock } from '~templates/index';

const ReactBridge = ReactBlock.extend({
    render() {
        const ComponentClass = this.reactComponent;
        // const template =

        if (ComponentClass === undefined) {
            throw new Error(`No react component when building ReactBridge for ${this.name}`);
        }

        const context = {
            block: this,
            container: this.app,
            data: this.data,
            options: this.data?.options ?? {},
        };

        const component = (
            <BlockProvider value={context}>
                <ComponentClass blockRef={this} />
            </BlockProvider>
        );

        ReactDOM.render(component, this.$el[0]);

        if (this.app.showScreenIds) this.showDebugInformation();
    },
});

export default ReactBridge;
