import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Carousel, Container } from 'react-bootstrap';
import BuildAnimation from '~components/animations/BuildAnimation';
import InterludeProgressBars from './progressBars/InterludeProgressBars';
import InterludeOverlay from './overlay/InterludeOverlay';
import slideTypes from './slides';
import { makeDefaultObj } from './utils';
import './InterludeBlock.scss';

/**
 * @param {object} props
 * @property {object} props.blockRef
 * @return {JSX.Element}
 * @constructor
 */
function InterludeBlock(props) {
    const { blockRef: block } = props;
    const [currentSlide, setCurrentSlide] = useState(0);
    const {
        /** @type {import('./interlude-types').InterludeSchema} */
        data,
    } = block;
    const slides = data.slides;
    const carousel = React.createRef();
    const element = React.createRef();

    useEffect(() => {
        BuildAnimation.animateIn(element.current);
    }, []);

    const [slideProgress, setSlideProgress] = useState(
        makeDefaultObj(slides, 0)
    );
    const [slideLoading, setSlideLoading] = useState(
        makeDefaultObj(slides, true)
    );
    const [slideError, setSlideError] = useState(makeDefaultObj(slides, false));

    const nextSlide = () => {
        // The current slide is the last slide. Mark this block
        // as complete and don't increment slide index
        if (currentSlide === slides.length - 1) {
            block.complete();
            return;
        }

        // Increment the current slide. Bootstrap watches this
        // value and will handle the transition.
        setCurrentSlide(currentSlide + 1);
    };

    const updateSlideProgress = (index, progress) =>
        setSlideProgress({ ...slideProgress, [index]: progress });

    const updateSlideLoading = (index, loading) =>
        setSlideLoading({ ...slideLoading, [index]: loading });

    const updateSlideError = (index, error) =>
        setSlideError({ ...slideError, [index]: error });

    return (
        <Container>
            <div
                className="interlude-block-scope interlude-height-constrained"
                ref={element}
            >
                <InterludeProgressBars
                    slides={slides}
                    slideLoading={slideLoading}
                    slideProgress={slideProgress}
                    slideError={slideError}
                    currentSlide={currentSlide}
                    onBarClick={(index) => setCurrentSlide(index)}
                />
                <Carousel
                    ref={carousel}
                    activeIndex={currentSlide}
                    controls={false}
                    indicators={false}
                    wrap={false}
                >
                    {slides.map((slide, index) => {
                        const {
                            meta: { fit, backgroundColor },
                            overlays = [],
                            type,
                        } = slide;
                        const Component = slideTypes[type];

                        // Build up the overlay components
                        const overlayComponents = overlays.map(
                            (overlay, overlayIndex) => (
                                <InterludeOverlay
                                    overlay={overlay}
                                    index={overlayIndex}
                                    key={`overlay-${overlayIndex.toString()}`}
                                />
                            )
                        );

                        // Decide which object fit class to use on this slide
                        const classes = classNames({
                            'interlude-item-contain': fit === 'contain',
                            'interlude-item-cover': fit === 'cover',
                            'interlude-item-fill': fit === 'fill',
                        });

                        // Change the background color if it was set
                        const styles = backgroundColor
                            ? { backgroundColor }
                            : {};

                        return (
                            <Carousel.Item
                                key={index.toString()}
                                className={classes}
                                style={styles}
                            >
                                <Component
                                    active={currentSlide === index}
                                    slide={slide}
                                    onCompleted={() => nextSlide()}
                                    onTimeUpdate={(percentage) =>
                                        updateSlideProgress(index, percentage)
                                    }
                                    onLoadingChange={(loading) =>
                                        updateSlideLoading(index, loading)
                                    }
                                    onError={() =>
                                        updateSlideError(index, true)
                                    }
                                />
                                {!slideLoading[index] && (
                                    <div className="overlay-wrapper">
                                        {overlayComponents}
                                    </div>
                                )}
                            </Carousel.Item>
                        );
                    })}
                </Carousel>
            </div>
        </Container>
    );
}

InterludeBlock.propTypes = {
    blockRef: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default InterludeBlock;
