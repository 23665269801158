import React from 'react';
import { Card, Container } from 'react-bootstrap';
import Block from '~blocks/base/Block';

/**
 * @extends Block
 * @memberOf ReactBlocks
 */
class Placeholder extends Block {
    /**
     * @param props
     */
    constructor(props) {
        super(props);
    }

    /**
     * Completing the screen.
     */
    componentDidMount() {
        this.props.blockRef.complete();
    }

    /**
     * @returns {ReactNode}
     */
    render() {
        return (
            <Container>
                <Card className="placeholder-block">
                    <Card.Body>
                        <h2>
                            Template {this.props.blockRef.blockData.template()}{' '}
                            not defined!
                        </h2>
                    </Card.Body>
                </Card>
            </Container>
        );
    }
}

export default Placeholder;
