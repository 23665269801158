import classNames from 'classnames';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Overlay, Tooltip } from 'react-bootstrap';
import Button from '../../../components/Button';
import styles from './Point.module.css';

const Point = forwardRef((props, ref) => {
    const { point, disabled } = props;
    const [showTooltip, setShowTooltip] = useState(false);
    const target = useRef(null);
    const [visited, setVisited] = useState(false);

    useImperativeHandle(ref, () => ({
        get element() {
            return target.current;
        },
        get tooltipState() {
            return showTooltip;
        },
        hideTooltip() {
            setShowTooltip(false);
        },
        showTooltip() {
            setShowTooltip(true);
        },
    }), []);

    const handleToggleTooltip = () => {
        if (disabled) return;

        setShowTooltip(true);
        setVisited(true);
    };

    const handleHoverOnTooltip = () => {
        if (disabled) return;

        setShowTooltip(true);
        setVisited(true);
    };

    const handleHoverOffTooltip = () => {
        if (disabled) return;

        setShowTooltip(false);
    };

    const handleOutsideClick = (event) => {
        const isClickInside = target.current.contains(event.target);

        if (!isClickInside) {
            setShowTooltip(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    return (
        <>
            <Button
                ref={target}
                onClick={handleToggleTooltip}
                onMouseOver={handleHoverOnTooltip}
                onMouseLeave={handleHoverOffTooltip}
                style={{
                    'opacity': 0,
                    '--point-top': `${point.top}%`,
                    '--point-left': `${point.left}%`,
                }}
                className={classNames(styles.point, {
                    [styles.open]: showTooltip,
                    [styles.visited]: visited,
                    [styles.disabled]: disabled,
                })}
                icon
            >
                <img src="assets/images/misc/excavator.svg" alt="" />
            </Button>
            <Overlay target={target.current} show={showTooltip} placement="bottom">
                {tooltipProps => (
                    <Tooltip className={styles.tooltip} {...tooltipProps}>
                        <span className={styles.text}>{point.text}</span>
                        {[point.subtitle].flat().map((subtitle, index) => (
                            <span key={index} className={styles.subtitle}>{subtitle}</span>
                        ))}
                    </Tooltip>
                )}
            </Overlay>
        </>
    );
});

Point.displayName = 'Point';

export default Point;
