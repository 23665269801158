import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { ArcElement, Chart, Legend, Title, Tooltip } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import _ from 'underscore';

Chart.register(ArcElement, Legend, Tooltip, Title);

/**
 * @param props
 */
function Reports(props) {
    const { labels, listItems } = props;

    const [chartData, setChartData] = useState(undefined);

    useEffect(() => {
        const total = listItems.length;
        if (total > 0) {
            const percentages = [];
            labels.issueType.map((label) => {
                const count = _.filter(listItems, (item) => {
                    return item.IssueType === label;
                }).length;
                return percentages.push(((count / total) * 100).toFixed(2));
            });
            setChartData({
                title: 'Percentage of issues based on issue type',
                labels: labels.issueType,
                datasets: [
                    {
                        data: percentages,
                        backgroundColor: [
                            '#80A1C1',
                            '#EEE3AB',
                            '#BA3F1D',
                            '#A77E58',
                            '#D9CFC1',
                            '#440381',
                            '#EC368D',
                        ],
                        borderWidth: 0,
                    },
                ],
            });
        }
    }, [listItems]);

    return (
        <>
            {chartData && (
                <>
                    <Pie
                        data={chartData}
                        options={{
                            plugins: {
                                title: {
                                    display: true,
                                    text: chartData.title,
                                },
                                legend: {
                                    display: false,
                                },
                                tooltip: {
                                    enabled: true,
                                    callbacks: {
                                        label(context) {
                                            let tooltip = '';
                                            tooltip += `${context.label} : ${context.parsed}%`;
                                            return tooltip;
                                        },
                                    },
                                },
                            },
                        }}
                    />
                    <Card.Title className="title p-3 m-0">
                        Total issues - {listItems.length}
                    </Card.Title>
                    <ul id="legends">
                        {chartData.labels.map((title) => {
                            const index = chartData.labels.indexOf(title);
                            const bgColor =
                                chartData.datasets[0].backgroundColor[index];
                            const percentage =
                                chartData.datasets[0].data[index];
                            return (
                                <li className="legend" key={title}>
                                    <span
                                        className="legend-color"
                                        style={{
                                            backgroundColor: `${bgColor}`,
                                        }}
                                    />
                                    <span className="legend-title">
                                        {title} ({percentage}%)
                                    </span>
                                </li>
                            );
                        })}
                    </ul>
                </>
            )}
        </>
    );
}

Reports.propTypes = {
    labels: PropTypes.objectOf(PropTypes.any).isRequired,
    listItems: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default Reports;
