import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { baselineRaiseText } from '../../animations/baselineRaiseText';
import BaselineRaiseText from '../../components/BaselineRaiseText';
import Scarf from '../../components/Scarf';
import { onlyUnique } from '../../utils';
import styles from './Quote.module.css';
import Note from './components/Note';
import { useBlock } from '~blocks/index';

/** @template T @typedef { import("../blocks").BlockContext<T> } BlockContext */
/** @typedef { import("./Quote.types").QuoteSchema } QuoteSchema */

function Quote() {
    /** @type {BlockContext<QuoteSchema>} */
    const { data } = useBlock({ instantComplete: true });

    /** @type {React.MutableRefObject<HTMLParagraphElement>} */
    const quote = useRef();

    /** @type {React.MutableRefObject<HTMLUListElement>} */
    const noteStack = useRef();

    const [showNotes, setShowNotes] = useState(false);

    useEffect(() => {
        if (!quote.current) return;

        const regex = /\{([\w-]+)}/g;

        quote.current.innerHTML = quote.current.innerHTML.replaceAll(regex, (match, id) => {
            const replacement = data.items.find(item => item.id === id).text;
            return `<span data-id="${id}" class="${styles.highlight}">${replacement}</span>`;
        });

        baselineRaiseText(quote.current, {
            onComplete: () => {
                Array.from(quote.current.querySelectorAll('span'))
                    .map(el => el.getAttribute('data-id'))
                    .filter(Boolean)
                    .filter(onlyUnique)
                    .forEach((id) => {
                        const [first, ...rest] = Array.from(quote.current.querySelectorAll(`span[data-id=${id}]`));

                        for (const el of rest) {
                            first.innerText = `${first.innerText}${el.innerText}`;
                            el.remove();
                        }

                        first.classList.add(styles.shown);
                    });

                setShowNotes(true);
            },
        });
    }, []);

    useEffect(() => {
        return () => {
            setShowNotes(false);
        };
    }, []);

    return (
        <Scarf variant="centred">
            <BaselineRaiseText className={styles.title} duration={0.7} stagger={0.05}>{data.title}</BaselineRaiseText>
            <p ref={quote} className={styles.quote}>{data.content}</p>
            {showNotes && (
                <ul ref={noteStack} className={styles.noteStack}>
                    {data.items.map(data => (
                        <Note
                            key={data.id}
                            data={data}
                            target={quote.current.querySelector(`span[data-id=${data.id}]`)}
                        />
                    ))}
                </ul>
            )}
        </Scarf>
    );
}

Quote.propTypes = {
    property: PropTypes.string,
};

Quote.defaultProps = {
    property: undefined,
};

export default Quote;
