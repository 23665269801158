import React from 'react';
import ReactDOM from 'react-dom';
import Backbone from 'backbone';
import QuizProgress from '../components/progress/QuizProgress';

/**
 * Mounts and unmounts QuizProgress
 */
class QuizProgressManager extends Backbone.Model {
    constructor() {
        super();
        this.name = 'QuizJunctionProgress';
    }

    setup(container) {
        this.container = container;
        this.listenTo(this.container, 'onLoadComplete', this.start);
    }

    start() {
        this.listenTo(
            this.container.screenManager,
            'onBlockInitialized',
            (e) => {
                const isJunction = this.container.router.isInJunction(
                    e.blockData.pageID()
                );
                if (!isJunction) return;

                const junction = e.view;

                this.listenTo(
                    junction.junctionController,
                    'onQuizStarted',
                    (e) => {
                        this.listenToOnce(
                            junction.junctionController.pageRenderer,
                            'onBlockInitialized',
                            (e) => {
                                const progressUIType =
                                    junction.pageData.getArg('progressUIType');
                                const component = (
                                    <QuizProgress
                                        blockRef={junction}
                                        type={progressUIType}
                                    />
                                );
                                this.$el = $("<div class='quiz-progress' />");
                                this.container.dom
                                    .find('.junction-area')
                                    .prepend(this.$el);
                                ReactDOM.render(component, this.$el[0]);
                            }
                        );
                    }
                );

                this.listenTo(
                    junction.junctionController,
                    'onQuizEnded',
                    (e) => {
                        if (!this.$el) return;
                        ReactDOM.unmountComponentAtNode(this.$el[0]);
                        this.$el.remove();
                    }
                );
            }
        );
    }
}

export default QuizProgressManager;
