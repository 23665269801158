import React from 'react';
import PropTypes from 'prop-types';
import styles from './OrientationOverlay.module.css';
import { t } from '~templates/utils/i18n';

function Overlay(props) {
    const {
        title = t('rotate_device'),
        message = t('rotate_device_message'),
        image,
    } = props;

    return (
        <div className={styles.wrapper}>
            <div className={styles.image}>
                <img src={image} alt={title} />
            </div>
            <div className={styles.title}>
                <p>{title}</p>
            </div>
            <div className={styles.message}>
                <p>{message}</p>
            </div>
        </div>
    );
}

Overlay.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    image: PropTypes.string,
};

Overlay.defaultProps = {
    title: undefined,
    message: undefined,
    image: 'assets/images/saffron/screen_rotation.png',
};

export default Overlay;
