import { initializeApp } from 'firebase/app';
import { connectAuthEmulator, createUserWithEmailAndPassword, getAuth, onAuthStateChanged, signInAnonymously, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { child, connectDatabaseEmulator, getDatabase, off, onDisconnect, onValue, push, ref, set, update } from 'firebase/database';
import { collection, connectFirestoreEmulator, doc, getDoc, getFirestore, onSnapshot, setDoc } from 'firebase/firestore';
import { getAnalytics, logEvent } from 'firebase/analytics';
import ContainerPlugin from '../ContainerPlugin/ContainerPlugin';
import SaffronError from '../../utils/SaffronError';
import { AUTH_ERR_ALREADY_SIGNED_IN } from './Firebase.constants';
import { Logger } from '~core';

const handleAuth = (promise) => {
    return promise
        .then(userCredential => [userCredential.user, null])
        .catch(error => [null, error]);
};

class Firebase extends ContainerPlugin {
    name = 'Firebase';

    /**
     * Stores payloads created for the triggerOnDoc method so
     * that they are available to code if needed after an event
     * is triggered but before the listener is ready
     * @see {Firebase.triggerOnDoc}
     */
    payloads = {};

    /**
     * Array of all subscriptions created. Used to unsubscribe
     * at a later point.
     * @type {Array<{
     *   key: string,
     *   unsubscribe: import('firebase/firestore').Unsubscribe,
     *   createdAt: Date
     * }>}
     */
    subscriptions = [];

    afterSetup() {
        onAuthStateChanged(this.auth, (user) => {
            this._user = user;
        });

        this._analytics = getAnalytics(this.app);
    }

    get user() {
        return this._user;
    }

    get isSignedIn() {
        return !!this._user;
    }

    get isNewUser() {
        return this.user.metadata.creationTime === this.user.metadata.lastSignInTime;
    }

    get app() {
        return this.cache('_firebaseApp', () => initializeApp(this.config));
    }

    get analytics() {
        return this._analytics;
    }

    logEvent(name, params) {
        return logEvent(this._analytics, name, params);
    }

    get db() {
        return this.cache('_firebaseDatabase', () => {
            const db = getDatabase(this.app);
            if (this.config.emulator) {
                connectDatabaseEmulator(db, 'localhost', 9000);
            }
            return db;
        });
    }

    getRef(path) {
        return ref(this.db, path);
    }

    onDisconnect(ref) {
        return onDisconnect(ref);
    }

    /**
     * @param {string} path - Path to resource
     * @param {any} data - Data to store at path
     * @returns {Promise<void>}
     */
    write(path, data) {
        const reference = ref(this.db, path);
        return set(reference, data);
    }

    /**
     * Update multiple values at the same time
     *
     * @param {string} path - Prefix for all update paths
     * @param {Object.<string, any>} updates - Object of values to update
     * @returns {Promise<void>}
     */
    async update(path, updates) {
        if (path) {
            const entries = Object.entries(updates)
                .map(([key, value]) => [`${path}/${key}`, value]);
            updates = Object.fromEntries(entries);
        }

        await update(ref(this.db), updates);
    }

    getNewKey(path) {
        return push(child(ref(this.db), path)).key;
    }

    /**
     * @param {string} path - Path to resource
     * @param {(snapshot: import('firebase/database').DataSnapshot) => unknown} callback - Callback to run when data changes
     * @param {(error: Error) => unknown} [cancelCallback=undefined] - Callback to run when an error occurs
     * @returns {import('firebase/database').DatabaseReference}
     */
    watch(path, callback, cancelCallback) {
        const reference = ref(this.db, path);
        const defaultCancelCallback = () => {};
        onValue(reference, callback, cancelCallback ?? defaultCancelCallback);
        return reference;
    }

    /**
     * @param {import('firebase/database').DatabaseReference} ref
     */
    stopWatching(ref) {
        off(ref);
    }

    /** @returns {import('firebase/firestore').Firestore} */
    get fs() {
        return this.cache('_firebaseFirestore', () => {
            const db = getFirestore(this.app);
            if (this.config.emulator) {
                connectFirestoreEmulator(db, 'localhost', 9050);
            }
            return db;
        });
    }

    getDocRef(path, ...pathSegments) {
        const [first, ...parts] = [path, ...pathSegments].flat(Infinity);
        return doc(this.fs, first, ...parts);
    }

    getCollectionRef(path, ...pathSegments) {
        const [first, ...parts] = [path, ...pathSegments].flat(Infinity);
        return collection(this.fs, first, ...parts);
    }

    setDoc(path, data, options = {}) {
        return setDoc(this.getDocRef(path), data, options);
    }

    getDoc(path) {
        return getDoc(this.getDocRef(path));
    }

    updateDoc(path, data, options = {}) {
        Logger.debug('Firebase.updateDoc', { path, data, options });
        return setDoc(this.getDocRef(path), data, {
            ...options,
            merge: true,
        });
    }

    /**
     * @param {string} path
     * @param {(snapshot: import('firebase/firestore').DocumentSnapshot) => void} callback
     * @returns {import('firebase/firestore').Unsubscribe}
     */
    subscribeToDoc(path, callback, options = {}) {
        const { key = path } = options;
        const unsubscribe = onSnapshot(this.getDocRef(path), callback);
        this.subscriptions.push({ key, unsubscribe, createdAt: new Date() });
        return unsubscribe;
    }

    /**
     * Watch a document and trigger when updated
     *
     * @param {string} path - Firestore path
     * @param {string} event - Event to trigger when the document updates
     * @param {any} [scope=this] - Which scope to trigger the event in. Defaults to this plugin's scope
     * @returns {import('firebase/firestore').Unsubscribe}
     */
    triggerOnDoc(path, event, scope = this) {
        return this.subscribeToDoc(path, (snapshot) => {
            const payload = { id: snapshot.id, value: snapshot.data(), snapshot };
            this.payloads[event] = payload;
            scope.trigger(event, payload);
        }, {
            key: `${event}::${path}`,
        });
    }

    /**
     * @param {string|string[]} path
     * @param {(snapshot: import('firebase/firestore').QuerySnapshot) => void} callback
     * @returns {import('firebase/firestore').Unsubscribe}
     */
    subscribeToCollection(path, callback) {
        return onSnapshot(this.getCollectionRef(path), callback);
    }

    /** @returns {import('firebase/auth').Auth} */
    get auth() {
        return this.cache('_firebaseAuth', () => {
            const auth = getAuth(this.app);
            if (this.config.emulator) {
                connectAuthEmulator(auth, 'http://localhost:9099');
            }
            return auth;
        });
    }

    async signInWithEmailAndPassword(email, password) {
        if (this.user) {
            throw new SaffronError(AUTH_ERR_ALREADY_SIGNED_IN);
        }

        return signInWithEmailAndPassword(this.auth, email, password);
    }

    async createUserWithEmailAndPassword(email, password) {
        if (this.user) {
            throw new SaffronError(AUTH_ERR_ALREADY_SIGNED_IN);
        }

        return createUserWithEmailAndPassword(this.auth, email, password);
    }

    async signInAnonymously() {
        // if (this.user) {
        //     throw new SaffronError(AUTH_ERR_ALREADY_SIGNED_IN);
        // }

        return signInAnonymously(this.auth);
    }

    async signOut() {
        return signOut(this.auth);
    }

    createPresenceMonitor(path, connected, disconnected) {
        const reference = this.getRef(path);
        this.onDisconnect(reference).set(disconnected);
        this.watch('.info/connected', (snapshot) => {
            if (!snapshot.val()) return;
            this.write(path, connected);
            this.onDisconnect(reference).set(disconnected);
        });
    }

    destroy(options) {
        for (const subscription of this.subscriptions) {
            Logger.debug(`Unsubscribing from ${subscription.key}`);
            subscription.unsubscribe();
        }
        return super.destroy(options);
    }
}

export default Firebase;
