import Backbone from 'backbone';
import Junction from '../../../junction/junction';

class JunctionCollector {
    constructor(log, container) {
        this.log = log;
        this.type = 'JunctionCollector';

        this.junctionControllerEvents = [
            'onSwitchChange',
            'onModuleStarted',
            'onModuleEnded',
            'onModuleCompleted',
        ];

        Backbone.listenTo(
            container.screenManager,
            'onBlockInitialized',
            (e) => {
                if (!e.blockData.view instanceof Junction) return;

                Backbone.listenTo(
                    e.blockData.view,
                    'onJunctionDataCreated',
                    (e) => {
                        log.add({
                            type: this.type,
                            event: `Junction.onJunctionDataCreated`,
                            data: e,
                            time: Date.now(),
                        });
                    }
                );

                this.junctionControllerEvents.forEach((event) => {
                    Backbone.listenTo(
                        e.blockData.view.junctionController,
                        event,
                        (e) => {
                            log.add({
                                type: this.type,
                                event: `JunctionController.${event}`,
                                data: e,
                                time: Date.now(),
                            });
                        }
                    );
                });
            }
        );

        Backbone.listenTo(container.session, 'onJunctionUpdate', (e) => {
            log.add({
                type: this.type,
                event: `JunctionRouter.onJunctionUpdate`,
                data: e.pageID,
                time: Date.now(),
            });
        });
    }

    print() {
        return this.log.where({ type: this.type });
    }
}

export default JunctionCollector;
