import { useMemo } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { query } from 'firebase/firestore';
import { Routes } from '../RoomSync.contants';
import useRoomSync from './useRoomSync';
import { useCollectionReference } from '~m/Firebase';

/**
 * @returns {[
 *  import('../RoomSync.types').UseRoomDetailsReturn[],
 *  boolean ,
 *  import('firebase/firestore').FirestoreError
 * ]}
 */
function useRooms(options) {
    const {
        query: queryCallbacks = () => [],
    } = options;

    const path = useCollectionReference(Routes.rooms.INDEX());
    const queryCallbackParams = useMemo(() => {
        const response = queryCallbacks();
        return Array.isArray(response) ? response : [response];
    }, [queryCallbacks]);
    const q = query(path, ...queryCallbackParams);
    /** @type {import('react-firebase-hooks/firestore').CollectionHook<import('../RoomSync.types').Room>} */
    // @ts-expect-error types are correct
    const [snapshot, loading, error] = useCollection(q);
    const roomSync = useRoomSync();

    const rooms = useMemo(
        () => snapshot?.docs.map(doc => ({
            ...doc.data(),
            id: doc.id,
            directLink: roomSync.getRoomLink(doc.id),
        })),
        [snapshot],
    );

    return [rooms, loading, error];
}

export default useRooms;
