import TweenLite, { Power4 } from 'gsap/TweenLite';
import $ from 'jquery';
import _ from 'underscore';
import { Logger } from '../../../index.js';
import ApplicationRouter from '../../router/applicationrouter.js';
import { JunctionPages } from './junctionpages.js';

const JunctionRouter = ApplicationRouter.extend(
    {
        pages: undefined,
        history: undefined,

        initialize(container) {
            ApplicationRouter.prototype.initialize.call(this, container);

            this.pages = new JunctionPages();
            this.history = [];

            this.route('slide/:id(/:query)', 'changePage'); // overide slide route
            this.route('page/:id(/:query)', 'changePage');
            this.route('home', 'routeToHome');
        },

        setContainer(container) {
            this.container = container;
            this.listenToOnce(container, 'onCourseDataCreated', function () {
                this.pages.setup(container);
                if (this.container.storeHistory) {
                    const history = this.container.storage.getItem(
                        JunctionRouter.HISTORY
                    );
                    this.history = history != -1 ? history : [];
                }
            });
            this.listenTo(
                container.screenManager,
                'onPageRestored',
                this.handlePageRestored
            );
        },

        getPages() {
            return this.pages;
        },

        getPage(pageId) {
            const page = this.pages.get(pageId);
            if (!page) return false;

            return page;
        },

        changePage(pageId, query) {
            this.navigate(`/page/${pageId}${query ? `/${query}` : ''}`, {
                trigger: false,
                replace: false,
            });

            switch (pageId) {
                case JunctionRouter.HOMEPAGE_ID:
                    this.routeToHome();
                    break;
                case JunctionRouter.NEXT:
                    this.next();
                    break;
                case JunctionRouter.PREVIOUS:
                    this.previous();
                    break;
                default:
                    this.routeToPage(pageId, query);
            }
        },

        routeToPage(pageId, query) {
            const page = this.getPage(pageId);
            if (!page) {
                Logger.warn(`Page not found: ${pageId}`);
                return;
            }

            this.addHistory(pageId);

            this.query = query;

            const junctionPageId = page.getJunctionId();

            if (junctionPageId) {
                if (junctionPageId === this.getTopPageId()) {
                    this.routeInsideJunction(pageId);
                } else {
                    this.routeToSubpage(pageId, junctionPageId);
                }
            } else {
                // Route to normal page
                this.changeSlide(pageId);
            }
        },

        routeToHome() {
            if (this.isInJunction()) {
                this.routeInsideJunction(JunctionRouter.HOMEPAGE_ID);
                this.addHistory(this.getTopPageId()); // Add top level pageID to history.
                this.navigate(`/page/${this.getTopPageId()}`, {
                    trigger: false,
                    replace: false,
                });
            } else {
                this.changeSlide(JunctionRouter.COURSEMAP_ID);
            }
        },

        routeToSubpage(subpageId, junctionPageId) {
            this.listenToOnce(
                this.container.screenManager,
                'onBlockInitialized',
                function (e) {
                    const view = e.blockData.view;

                    // this.listenToOnce(view, "onDataCreated", function(e) {
                    if (!view || !view.junctionController) {
                        Logger.error('Failed to route to subpage:', subpageId);
                        return;
                    }

                    let subpage;
                    view.junctionController.data.pages.each((page) => {
                        if (page.pageID() === subpageId) subpage = page;
                    });

                    if (!subpage) {
                        Logger.error(
                            `JunctionRouter.routeToSubpage: ${subpageId} not found in junction ${junctionPageId}`
                        );
                        return;
                    }

                    view.startPage = subpageId;

                    this.listenToOnce(
                        view.junctionController,
                        'onChangeSlide',
                        function () {
                            this.update(
                                subpageId,
                                view.junctionController.previousPageData,
                                view.junctionController.currentPageData,
                                'subpage'
                            );
                        }
                    );
                }
            );

            this.changeSlide(junctionPageId);
        },

        routeInsideJunction(subpageId) {
            const blockData = this.getCurrentBlockData();

            blockData.view.junctionController.switchPage(subpageId);

            if (!blockData.view) return;
            this.update(
                subpageId,
                blockData.view.junctionController.previousPageData,
                blockData.view.junctionController.currentPageData,
                'subpage'
            );
        },

        update(pageId, previousPageData, pageData, type) {
            if (this == null) return true;

            this.container.session.trigger('onBeforeJunctionUpdate', {
                target: this,
            });

            const event = {
                target: this,
                previousPageData,
                pageData,
                type,
                pageID: pageId,
            };

            // Hack to force this event to come from the session! Bad,
            this.container.session.trigger('onJunctionUpdate', event);
        },

        previous() {
            const blockData = this.getCurrentBlockData();

            if (this.container.storeHistory) {
                this.historyBack();
            } else {
                if (this.isInJunction() && !this.isStartPage(blockData)) {
                    blockData.view.junctionController.previous();
                    return true;
                }

                ApplicationRouter.prototype.previous.call(this);
            }
        },

        next() {
            const blockData = this.getCurrentBlockData();

            if (this.isInJunction() && !this.isStartPage(blockData)) {
                blockData.view.junctionController.next();
                return true;
            }

            ApplicationRouter.prototype.next.call(this);
        },

        historyBack(pageID) {
            const backPageID = pageID || this.removeHistory();

            this.changePage(backPageID);

            const isCoursemap = backPageID === JunctionRouter.COURSEMAP_ID;
            if (!isCoursemap && this.container.bookmarkJunctions) {
                this.container.storage.setBookmark(backPageID);
            }
        },

        removeHistory() {
            let previousPageId = this.history.pop();
            let currentPageId =
                this.getCurrentPageId() || JunctionRouter.COURSEMAP_ID;
            const junctionId =
                this.getPage(previousPageId).attributes.junctionId;

            if (currentPageId === junctionId && junctionId !== undefined) {
                previousPageId = this.history.pop();
                currentPageId =
                    this.getCurrentPageId() || JunctionRouter.COURSEMAP_ID;
            }
            return currentPageId;
        },

        addHistory(pageId) {
            if (pageId === this.getCurrentPageId()) return; // ignore duplicates

            let isGlobalPage = false;
            const junctionId = this.getPage(pageId).attributes.junctionId;
            if (junctionId !== undefined) {
                const moduleNumber = this.container.courseData
                    .getPageById(junctionId)
                    .moduleNumber();
                if (moduleNumber == null) {
                    isGlobalPage = true;
                }
            }
            const isCoursemap = pageId === JunctionRouter.COURSEMAP_ID;
            if (!isCoursemap && !isGlobalPage) {
                this.history.push(pageId);

                if (this.container.storeHistory) {
                    this.container.storage.setItem(
                        JunctionRouter.HISTORY,
                        this.history,
                        true
                    );
                }
            }

            if (
                !isCoursemap &&
                !isGlobalPage &&
                this.container.bookmarkJunctions
            ) {
                this.container.storage.setBookmark(pageId);
            }
        },

        getTopPageId() {
            const currentPage = this.container.session.currentPageData;

            return currentPage
                ? currentPage.pageID()
                : JunctionRouter.COURSEMAP_ID;
        },

        getCurrentPageId() {
            return this.history[this.history.length - 1];
        },

        getCurrentPageData() {
            const pageId = this.getCurrentPageId();
            if (pageId == null) return;

            const page = this.getPage(pageId);
            const junctionId = page.getJunctionId();

            if (junctionId) {
                const junctionData =
                    this.container.courseData.getPageById(junctionId);
                const junction = junctionData.getBlock(0).view;
                if (!junction || !junction.junctionData) return null;

                return junction.junctionData.getPageById(pageId);
            }

            return this.container.courseData.getPageById(pageId);
        },

        getCurrentBlockData() {
            return this.container.session.currentPageData.getBlock(0);
        },

        isInJunction(id) {
            if (!id) id = this.getTopPageId();
            const junctionPages = this.pages.where({
                junctionId: id,
            });
            return junctionPages.length > 0;
        },

        isStartPage(blockData) {
            const controller = blockData.view.junctionController;
            if (!controller || !controller.currentPageData) return true;

            return (
                controller.currentPageData.pageID() ===
                JunctionRouter.HOMEPAGE_ID
            );
        },

        returnToCurrentPage() {
            this.navigate(`/page/${this.getCurrentPageId()}`, {
                trigger: false,
                replace: false,
            });
        },

        navigate(fragment, options) {
            // console.log('junction router 1--------------------', fragment, options);

            if (options.trigger) options.replace = false; // GG:25/11/2020 made false to add pages in browser history and solved the NESTA navigation issue.
            // console.log('junction router 2--------------------', fragment, options);

            ApplicationRouter.prototype.navigate.call(this, fragment, options);
        },

        // This should to be moved out of here.
        focusBlock(id) {
            const isInJunction = this.isInJunction();
            const blockId = `#block-${id}`;
            let $block, blockData;

            if (isInJunction) {
                $block = $('.Junction').find(blockId);
                const junctionData = this.getCurrentBlockData();
                if (junctionData.view)
                    blockData =
                        junctionData.view.junctionController.currentPageData.getBlock(
                            id
                        );
            } else {
                $block = $(blockId);
                blockData = this.container.session.currentPageData.getBlock(id);
            }

            if ($block.length === 0 || !blockData) return;

            if (blockData.isRendered()) {
                this.scrollTo(blockId);
            } else {
                this.listenToOnce(
                    this.container.screenManager,
                    'onBlockRendered',
                    function (e) {
                        if (
                            e.blockData !== blockData ||
                            $(blockId).length === 0
                        )
                            return;
                        this.scrollTo(blockId);
                    }
                );
            }
        },

        scrollTo(elementId) {
            TweenLite.killTweensOf(this.container.$scroller);
            TweenLite.to(this.container.$scroller, 2, {
                scrollTo: {
                    y: elementId,
                    autoKill: false,
                },
                ease: Power4.easeOut,
            });
        },

        handlePageRestored() {
            if (this.isInJunction()) {
                const blockData = this.getCurrentBlockData();
                const controller = blockData.view.junctionController;
                // Notify of junction update
                _.defer(
                    this.update,
                    controller.currentPageData.pageID(),
                    this.container.session.previousPageData,
                    controller.currentPageData,
                    'subpage'
                );
            }
        },

        defaultRoute() {
            this.changePage(`page/${JunctionRouter.COURSEMAP_ID}`);
        },
    },
    {
        HOMEPAGE_ID: 'home',
        COURSEMAP_ID: 'coursemap',
        NEXT: 'next',
        PREVIOUS: 'prev',
        HISTORY: 'history',
    }
);

export default JunctionRouter;
