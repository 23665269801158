import Backbone from 'backbone';
import BlockData from './blockdata.js';

/**
 * @class Blocks
 * @name Blocks
 * @augments Backbone.Collection
 *
 * @see Backbone.Collection
 */
const Blocks = Backbone.Collection.extend(
    /** @lends Blocks.prototype */ {
        /** @instance */
        model: BlockData,
    }
);

export default Blocks;
