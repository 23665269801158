import React from 'react';
import BaselineRaiseText from '../../components/BaselineRaiseText';
import Scarf from '../../components/Scarf';
import styles from './SectionTitle.module.css';
import { useBlock } from '~blocks/index';

function SectionTitle() {
    const { data } = useBlock({ instantComplete: true });

    return (
        <Scarf
            variant="centred"
            className={styles.outer}
            innerClassName={styles.wrapper}
            before={<div className={styles.background} />}
        >
            <BaselineRaiseText duration={0.7} stagger={0.05} delay={1.5}>{data.title}</BaselineRaiseText>
        </Scarf>
    );
}

export default SectionTitle;
