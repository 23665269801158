import { createContext, useContext, useEffect } from 'react';

export const BlockContext = createContext({});
export const BlockProvider = BlockContext.Provider;

/**
 * Get some helpers for when using blocks. Includes access to the container.
 *
 * @param {object} [options] - Options for handling this block
 * @param {boolean} [options.instantComplete] - Mark this block as complete as soon as possible
 * @returns {{
 *     block: ReactBlock,
 *     container: Container,
 *     data: {
 *         options: {
 *            variant: string,
 *            [key: string]: string,
 *         },
 *         [key: string]: string,
 *     },
 *     options: {
 *         variant: string,
 *         [key: string]: string,
 *     },
 *     wrapStyles: {
 *         constrain: string,
 *         [key: string]: string,
 *     },
 * }}
 */
export function useBlock(options = {}) {
    const { instantComplete = false } = options;
    const context = useContext(BlockContext);

    if (instantComplete) {
        useEffect(() => context?.block?.complete(), []);
    }

    return context;
}
