/**
 *
 */
class BlockFluid {
    constructor(context) {
        this.exitFullscreen = true;
        this.context = context;
    }

    /**
     * Toggle the blocks fluid state
     * @return {Promise<void>}
     */
    toggle = async () => {
        if (this.context.state?.isFluid === undefined) {
            this.context.setState({
                isFluid: this.context.data.fluid || false,
            });
        }

        if (
            this.context.fullscreen.isFullscreen &&
            this.context.fluid.exitFullscreen
        ) {
            await this.context.fullscreen.toggle();
        }

        this.context.container.current
            .closest('.Block')
            .classList.toggle('fluid', !this.context.state.isFluid);

        if (this.context.scrollTo?.current) {
            this.context.scrollTo.current.scrollIntoView();
        }

        this.context.setState({ isFluid: !this.context.state.isFluid });
    };

    /**
     * Is the block currently set to fluid
     * @return {*|boolean}
     */
    get isFluid() {
        return this.context.state.isFluid;
    }
}

export default BlockFluid;
