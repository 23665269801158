import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { CircularProgressbar } from 'react-circular-progressbar';
import Block from '~blocks/base/Block';
import BuildAnimation from '~components/animations/BuildAnimation';
import './CoursemapBlock.scss';
import { Container } from 'react-bootstrap';

/**
 * @extends Component
 * @memberOf ReactComponents
 */
class CoursemapBlock extends Block {
    /**
     * @param props
     */
    constructor(props) {
        super(props);

        const data = this.getData(props.blockRef);
        const items = [];

        // Making references for the animations
        this.buttonRef = [];
        // Holds array of all references
        this.elements = [this.buttonRef];

        data.modules.map(function (module, i) {
            items.push({
                title: module.moduleTitle,
                description: module.moduleDescription,
                status: module.moduleStatus,
                percentage: module.percentage,
                cssClassAsStatus: module.cssClassAsStatus,
            });
        });

        this.state = {
            items,
        };
    }

    componentDidMount() {
        BuildAnimation.animateIn(this.elements);
    }

    getData(blockRef) {
        const data = {
            modules: [],
        };

        const courseData = blockRef?.junctionData
            ? blockRef.junctionData
            : blockRef.app.courseData;
        const totalModules = courseData.getTotalModules();

        for (let i = 0; i < totalModules; i++) {
            const title = courseData.getModule(i).getModuleTitle();
            const description = courseData.getModule(i).getModuleDescription();
            const status = this.getUnitStatusLabel(
                courseData.getModule(i).getModuleStatus()
            );
            const percentage =
                courseData.getTotalCompletedPagesByModule(i).percentage;
            data.modules.push({
                id: i,
                module: i + 1,
                moduleID: courseData.getModule(i).getModuleID(),
                moduleTitle: title,
                moduleDescription: description,
                moduleStatus: status,
                cssClassAsStatus: String(
                    Status.numberToStatus(
                        courseData.getModule(i).getModuleStatus()
                    )
                ).toLowerCase(),
                percentage,
            });
        }
        return data;
    }

    getUnitStatusLabel(index) {
        if (index === 1) return LanguageManager.getString('not_started');
        if (index === 2) return LanguageManager.getString('inprogess');
        if (index === 3) return LanguageManager.getString('completed');
        if (index === 4) return LanguageManager.getString('failed');
        if (index === 5) return LanguageManager.getString('passed');
        if (index === 6) return LanguageManager.getString('optional');
        if (index === 7) return LanguageManager.getString('locked');

        return LanguageManager.getString('unavailable');
    }

    /**
     * @param moduleID
     * @type Number
     * @function Check page complition and route to uncompleted page.
     */
    handleItemClick(moduleID) {
        const blockRef = this.props.blockRef;
        const courseData = blockRef.junctionData
            ? blockRef.junctionData
            : blockRef.app.courseData;
        const router = blockRef.junctionController
            ? blockRef.junctionController
            : blockRef.app.session;
        const module = courseData.getModule(moduleID);

        if (
            module.getModuleStatus() === Status.LOCKED ||
            (module.getModuleStatus() === Status.UNAVAILABLE &&
                blockRef.app.isLocked)
        )
            return;
        if (
            module.getModuleStatus() >= Status.STARTED &&
            module.getModuleStatus() < Status.COMPLETED
        ) {
            const firstIncomplete = _.find(module.pages, function (page) {
                if (page.getStatus() < Status.COMPLETED) return true;
            });

            blockRef.app.router.navigate(`#page/${firstIncomplete.pageID()}`, {
                trigger: true,
                replace: true,
            });
        } else {
            router.startModule(moduleID);
        }
    }

    /**
     * @returns {ReactNode}
     */
    render() {
        return (
            <Container>
                <ListGroup as="ul">
                    {this.state.items.map((item, i) => (
                        <ListGroup.Item
                            as="button"
                            key={i.toString()}
                            data-id={i}
                            className={`d-flex align-items-center mb-0 ${item.cssClassAsStatus}`}
                            onClick={this.handleItemClick.bind(this, i)}
                            ref={(e) => {
                                this.buttonRef[i] = e;
                            }}
                        >
                            <div className="list-content w-100">
                                {item.title ? (
                                    <div className="title">
                                        <h3 className="module-title">
                                            {item.title}
                                        </h3>
                                    </div>
                                ) : (
                                    ''
                                )}
                                {item.status ? (
                                    <div className="status">
                                        <p>{item.status}</p>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                            {item.percentage != undefined ? (
                                <div className="list-progress">
                                    <CircularProgressbar
                                        value={item.percentage}
                                        text={`${item.percentage}%`}
                                    />
                                </div>
                            ) : (
                                ''
                            )}
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </Container>
        );
    }
}

export default CoursemapBlock;
