import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';
import createClassNames from 'classnames';
import './styles/MessageAvatar.scss';

function MessageAvatar(props) {
    const { sender, padded } = props;
    const { avatar, name } = sender;
    const initials = name === 'You' ? name : name.match(/\b(\w)/g).join('');
    const className = createClassNames([
        'conversation-avatar',
        { 'conversation-avatar-padded': padded },
    ]);
    return (
        <span className={className}>
            {avatar ? (
                <Image src={avatar} roundedCircle />
            ) : (
                <span className='initials'>{initials}</span>
            )}
        </span>
    );
}

MessageAvatar.propTypes = {
    padded: PropTypes.bool,
    sender: PropTypes.shape({
        name: PropTypes.string,
        avatar: PropTypes.string,
    }).isRequired,
};

export default MessageAvatar;
