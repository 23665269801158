import React, { useEffect } from 'react';
import classNames from 'classnames';
import { Accordion as BAccordion, Card, Col, Image, Row } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import Scarf from '../../components/Scarf';
import useBuildAnimation from '../../hooks/useBuildAnimation';
import useAccordion from './Accordion.hooks';
import styles from './Accordion.module.css';
import useRoomParamMirror from '~m/RoomSync/hooks/useRoomParamMirror';

function Accordion() {
    const { active, items, changeActive, getKey, isItemActive, isItemViewed } = useAccordion();
    const element = useBuildAnimation('animateIn');

    useRoomParamMirror('activeAccordionIndex', active, changeActive);

    return (
        <Scarf variant="contained">
            <BAccordion
                ref={element}
                className={styles.accordionBlock}
                activeKey={active}
                defaultActiveKey={active}
                onSelect={key => changeActive(key)}
            >
                {items.map(item => (
                    <Card key={getKey(item)}>
                        <BAccordion.Toggle
                            className={classNames(styles.toggle, {
                                active: isItemActive(item),
                                viewed: isItemViewed(item),
                            })}
                            eventKey={getKey(item)}
                        >
                            <h3 className={styles.heading}>{item.heading}</h3>
                            {isItemActive(item)
                                ? <MdKeyboardArrowUp />
                                : <MdKeyboardArrowDown />
                            }
                        </BAccordion.Toggle>
                        <BAccordion.Collapse eventKey={getKey(item)}>
                            <Card.Body>
                                <Row>
                                    <Col md={item.image ? 6 : 12}>
                                        {ReactHtmlParser(item.content)}
                                    </Col>

                                    {item.image && (
                                        <Col md={6}>
                                            <Image
                                                className={styles.image}
                                                src={item.image}
                                                alt={item.imageDescription}
                                            />
                                        </Col>
                                    )}
                                </Row>
                            </Card.Body>
                        </BAccordion.Collapse>
                    </Card>
                ))}
            </BAccordion>
        </Scarf>
    );
}

export default Accordion;
