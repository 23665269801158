import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

/**
 * Renders Email title and time. Shared by Email and EmailReply
 */
class EmailFooter extends React.Component {
    /**
     * @param props
     */
    constructor(props) {
        super(props);
        this.data = props.data;
    }

    /**
     * @param index
     * @function Click event call through props
     */
    handleButtonClick(index) {
        this.props.onClick(index);
    }

    /**
     * @returns {ReactNode}
     */
    render() {
        return (
            this.data.buttons.items.length > 0 && (
                <div className="email-footer">
                    <Card.Footer>
                        <div className="buttons">
                            {this.data.buttons.items.map((button, i) => {
                                return (
                                    <Button
                                        key={i}
                                        className=""
                                        disabled={!this.props.isDisabled}
                                        variant="primary"
                                        onClick={() =>
                                            this.handleButtonClick(i)
                                        }
                                    >
                                        {button.title}
                                    </Button>
                                );
                            }, this)}
                        </div>
                    </Card.Footer>
                </div>
            )
        );
    }
}

export default EmailFooter;
