class SaffronError extends Error {
    constructor(code, message = undefined) {
        if (!message) {
            message = `Error (${code})`;
        }
        super(`Error (${code}): ${message}`);
        this.code = code;
    }
}

export default SaffronError;
