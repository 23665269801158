import Backbone from 'backbone';
import Status from '../../../data/status';

class StatusCollector {
    constructor(log, container) {
        this.log = log;
        this.type = 'StatusCollector';

        Backbone.listenTo(
            container.courseData.modules,
            'onStatusUpdate',
            (e) => {
                log.add({
                    type: this.type,
                    event: 'ModuleData.onStatusUpdate',
                    data: `${e.target.getModuleID()} == ${Status.numberToStatus(
                        e.target.getModuleStatus()
                    )}`,
                    time: Date.now(),
                });
            }
        );
        Backbone.listenTo(container.courseData.pages, 'onStatusUpdate', (e) => {
            log.add({
                type: this.type,
                event: 'PageData.onStatusUpdate',
                data: `${e.target.pageID()} == ${Status.numberToStatus(
                    e.target.getStatus()
                )}`,
                time: Date.now(),
            });

            container.courseData.pages.each((page) => {
                Backbone.listenTo(page.blocks, 'onStatusUpdate', (e) => {
                    if (!e.changed) return;

                    log.add({
                        type: this.type,
                        event: 'BlockData.onStatusUpdate',
                        data: `${e.target.pageID()}_${e.target.blockID()} == ${Status.numberToStatus(
                            e.status
                        )} == ${e.changed}`,
                        time: Date.now(),
                    });
                });
            });
        });
    }

    print() {
        return this.log.where({ type: this.type });
    }
}

export default StatusCollector;
