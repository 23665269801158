export const ALREADY_IN_A_ROOM = 'room-manager/already-in-a-room';
export const MUST_BE_INSTRUCTOR = 'room-manager/must-be-instructor';
export const NOT_IN_A_ROOM = 'room-manager/not-in-a-room';
export const ROOM_CLOSED = 'room-manager/room-closed';
export const ROOM_DOES_NOT_EXIST = 'room-manager/room-does-not-exist';
export const ROOM_FULL = 'room-manager/room-full';
export const ROOM_ID_MISMATCH = 'room-manager/room-id-mismatch';
export const ROOM_KICKED = 'room-manager/room-kicked';
export const UNEXPECTED_ROOM_ID_CHANGED = 'room-manager/unexpected-room-id-changed';
export const UNEXPECTED_SNAPSHOT_MISSING = 'room-manager/unexpected-snapshot-missing';
export const UNEXPECTED_UNSUBSCRIBE_MISSING = 'room-manager/unexpected-unsubscribe-missing';

export const ErrorText = {
    [ALREADY_IN_A_ROOM]: 'You are already in a room',
    [MUST_BE_INSTRUCTOR]: 'You must be an instructor',
    [NOT_IN_A_ROOM]: 'You must be in a room',
    [ROOM_CLOSED]: 'This room has closed',
    [ROOM_DOES_NOT_EXIST]: 'This room does not exist',
    [ROOM_FULL]: 'This room is full',
    [ROOM_ID_MISMATCH]: 'The room ID doesn\'t match the room',
    [ROOM_KICKED]: 'You have been kicked from this room',
    [UNEXPECTED_ROOM_ID_CHANGED]: 'The room ID changed unexpectedly',
    [UNEXPECTED_SNAPSHOT_MISSING]: 'A data snapshot it missing',
    [UNEXPECTED_UNSUBSCRIBE_MISSING]: 'An data unsubscribe handler is missing',
};
