import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import CardGroup from 'react-bootstrap/CardGroup';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Container } from 'react-bootstrap';
import BuildAnimation from '~components/animations/BuildAnimation';
import Block from '~blocks/base/Block';

class QuizResultBlock extends Block {
    constructor(props) {
        super(props);
        this.block = props.blockRef;
        this.data = this.block.data;
        this.block.complete();

        this.state = {
            feedback: { title: '', content: '' },
            showTryAgain: true,
        };

        this.labels = {
            continue: LanguageManager.getString('continue'),
            tryAgain: LanguageManager.getString('try_again'),
        };
        this.element = React.createRef();
    }

    /**
     * Calls function to update feedback content and try again button's state
     */
    componentDidMount() {
        console.info('BA Quiz Result Block:', this.element.current);
        if (this.element.current != null)
            BuildAnimation.animateIn(this.element.current);
        this.setFeedback();
    }

    /**
     * Checks which feedback to show, gives title count of correct and total
     */
    setFeedback() {
        this.correctCount = this.block.junctionData.correctCount;
        this.totalQuestions = this.block.junctionData.totalQuestions;
        const scoreInPercent = Math.round(
            (this.correctCount * 100) / this.totalQuestions
        );

        let feedback = {};
        if (this.correctCount === this.totalQuestions) {
            feedback = this.data.feedback.correct;
            this.setState({ showTryAgain: false });
        } else if (scoreInPercent < 80) {
            feedback = this.data.feedback.incorrect;
        } else {
            feedback = this.data.feedback.partial;
        }

        feedback.title = feedback.title
            .split('[[x]]')
            .join(this.correctCount)
            .split('[[y]]')
            .join(this.totalQuestions);
        this.setState({ feedback });
    }

    render() {
        const { title, content } = this.state.feedback;
        return (
            <Container ref={this.element}>
                <CardGroup className="quiz-result-block">
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <h2 className="title">{title}</h2>
                                <div className="text">
                                    {ReactHtmlParser(content)}
                                </div>

                                <div className="buttons-wrapper my-3">
                                    {this.state.showTryAgain && (
                                        <Button
                                            variant="secondary"
                                            className="mr-3"
                                            aria-label={this.labels.tryAgain}
                                            onClick={() =>
                                                this.handleTryAgain()
                                            }
                                        >
                                            {this.labels.tryAgain}
                                        </Button>
                                    )}
                                    {!this.state.showTryAgain && (
                                        <Button
                                            variant="secondary"
                                            className="mr-3"
                                            aria-label={this.labels.tryAgain}
                                            onClick={() =>
                                                this.handleContinueClick()
                                            }
                                        >
                                            {this.labels.continue}
                                        </Button>
                                    )}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </CardGroup>
            </Container>
        );
    }

    /**
     * Resets all page's status and score count
     */
    handleTryAgain() {
        this.block.junctionData.reset();
        this.block.junctionData.correctCount = 0;
        this.block.junctionData.wrongCount = 0;
        this.block.junctionController.routeToStart();
    }

    handleContinueClick() {
        this.block.app.router.next();
    }
}

export default QuizResultBlock;
