import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Spinner } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import Chart from '../../../components/Chart';
import styles from './ChartSlide.module.css';
import { t } from '~templates/utils/i18n';

function ChartSlide(props) {
    const { item } = props;

    const chart = useRef();
    const [pending, setPending] = useState(true);
    const [showSpinner, setShowSpinner] = useState(false);
    const spinnerTimeout = useRef();
    const [error, setError] = useState('');

    useEffect(() => {
        if (!item.src) {
            throw new Error('Missing src url for carousel chart data');
        }

        setError('');

        spinnerTimeout.current = setTimeout(() => {
            setShowSpinner(true);
        }, 1000);

        // // Fetch replaced with XHR due to issues on client env
        // fetch(item.src)
        //     .then(response => response.json())
        //     .then((data) => {
        //         chart.current = data;
        //         setPending(false);
        //     })
        //     .catch((err) => {
        //         setError('Unable to load chart data for carousel slide');
        //         throw err;
        //     })
        //     .finally(() => {
        //         clearTimeout(spinnerTimeout.current);
        //     });
        function reqListener() {
            const data = JSON.parse(this.responseText);
            chart.current = data;
            clearTimeout(spinnerTimeout.current);
            setPending(false);
            setShowSpinner(false);
        }
        const errorResponse = () => {
            setError('Unable to load chart data for carousel slide');
            clearTimeout(spinnerTimeout.current);
            setShowSpinner(false);
        };

        const req = new XMLHttpRequest();
        req.addEventListener('load', reqListener);
        req.addEventListener('error', errorResponse);
        req.addEventListener('abort', errorResponse);
        req.open('GET', item.src);
        req.send();

        return () => {
            req.removeEventListener('load', reqListener);
            req.removeEventListener('error', errorResponse);
            req.removeEventListener('abort', errorResponse);
        };
    }, []);

    return (
        <div className="row m-0">
            <Col md={12} className={styles.text}>
                {ReactHtmlParser(item.content)}
            </Col>
            <Col md={12} className="px-5 pb-3">
                {showSpinner && (
                    <div className={styles.centred} style={{ height: '200px' }}>
                        <Spinner animation="border" role="status">
                            <span className="sr-only">{t('loading')}</span>
                        </Spinner>
                    </div>
                )}
                {error && (
                    <div className={styles.centred} style={{ height: '200px' }}>
                        <p>{error}</p>
                    </div>
                )}
                {!pending && (
                    <Chart
                        type={chart.current.type}
                        options={chart.current.options}
                        data={chart.current.data}
                    />
                )}
            </Col>
        </div>
    );
}

ChartSlide.propTypes = {
    property: PropTypes.string,
};

ChartSlide.defaultProps = {
    property: undefined,
};

export default ChartSlide;
