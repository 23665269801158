// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".outer__65fb93{position:relative}.wrapper__56cbb9{color:var(--brand-accent);text-align:center;z-index:10}.background__addb44{--idle-position:100% 100%;--midway-position:0% 0%;animation:slide__598569 2s ease-in-out;background:linear-gradient(144deg,#063751,#063751 50%,#ecb731 0,#ecb731);background-position:var(--idle-position);background-size:200% 200%;bottom:0;left:0;position:absolute;right:0;top:0}@keyframes slide__598569{0%{background-position:var(--idle-position)}50%{background-position:var(--midway-position)}to{background-position:var(--idle-position)}}", "",{"version":3,"sources":["webpack://./src/blocks/SectionTitle/SectionTitle.module.css"],"names":[],"mappings":"AACI,eAAA,iBAAe,CAIf,iBAGA,yBAA0B,CAD1B,iBAAkB,CAFlB,UAAW,CAMf,oBACI,yBAA0B,CAC1B,uBAAwB,CAIxB,sCAA+B,CAC/B,wEAOK,CAEL,wCAAyC,CADzC,yBAA0B,CAX1B,QAAuB,CAAvB,MAAuB,CAAvB,iBAAuB,CAAvB,OAAuB,CAAvB,KAaJ,CAEA,yBACI,GAAK,wCAA2C,CAChD,IAAM,0CAA6C,CACnD,GAAO,wCAA2C,CACtD","sourcesContent":[".outer {\n    @apply relative;\n}\n\n.wrapper {\n    @apply z-10;\n\n    text-align: center;\n    color: var(--brand-accent);\n}\n\n.background {\n    --idle-position: 100% 100%;\n    --midway-position: 0% 0%;\n\n    @apply absolute inset-0;\n\n    animation: slide 2s ease-in-out;\n    background:\n        linear-gradient(\n            144deg,\n            rgb(6 55 81 / 100%) 0%,\n            rgb(6 55 81 / 100%) 50%,\n            rgb(236 183 49 / 100%) 50%,\n            rgb(236 183 49 / 100%) 100%\n        );\n    background-size: 200% 200%;\n    background-position: var(--idle-position);\n}\n\n@keyframes slide {\n    0% { background-position: var(--idle-position); }\n    50% { background-position: var(--midway-position); }\n    100% { background-position: var(--idle-position); }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"outer": "outer__65fb93",
	"wrapper": "wrapper__56cbb9",
	"background": "background__addb44",
	"slide": "slide__598569"
};
export default ___CSS_LOADER_EXPORT___;
