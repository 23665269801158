import Backbone from 'backbone';
/**
 * @class CmiDataItem object to hold key value pairs of cmi data
 * @name CmiDataItem
 *
 * @property {string} label
 * @property {string} key
 * @property {string} data
 *
 * @augments Backbone.Model
 *
 * @see Backbone.Model
 */
const CmiDataItem = Backbone.Model.extend(
    /** @lends CmiDataItem.prototype */ {
        constructor() {
            this.label = undefined;
            this.key = undefined;
            this.data = undefined;

            Backbone.Model.apply(this, arguments);
        },
    }
);

/**
 * @class CmiDataItems
 * @name CmiDataItems
 * @augments Backbone.Collection
 *
 * @see Backbone.Collection
 */
const CmiDataItems = Backbone.Collection.extend(
    /** @lends CmiDataItems.prototype */ {
        /** @instance */
        model: CmiDataItem,
    }
);

export { CmiDataItem, CmiDataItems };
