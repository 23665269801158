import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Flicking from '@egjs/react-flicking';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import Button from '../../../components/Button';
import styles from './Slider.module.css';

/**
 * @param value
 * @param min
 * @param max
 */
function clamp(value, min, max) {
    return Math.min(Math.max(value, min), max);
}

/**
 * @param {number} value
 * @param {[number, number]} source
 * @param {[number, number]} target
 */
function convertRange(value, [srcMin, srcMax], [tgtMin, tgtMax]) {
    const clamped = clamp(Math.abs(value), srcMin, srcMax);
    const valReduced = clamped - srcMin;
    const tgtRange = tgtMax - tgtMin;
    const srcRange = srcMax - srcMin;
    return (valReduced * tgtRange) / srcRange + tgtMin;
}

/**
 * @param panels
 */
function transformPanels(panels) {
    panels.forEach((panel) => {
        const scale = 1 - convertRange(panel.progress, [0, 1], [0, 0.1]);
        panel.element.style.setProperty('transform', `scale(${scale})`);
    });
}

/**
 * @type {import('react').ForwardRefExoticComponent<import('./Slider.types').SliderProps>}
 */
const Slider = forwardRef((props, ref) => {
    const {
        children,
        startingIndex,
        className,
        onMove,
        disableOnInit,
        panelTransformer,
        showControlButtons,
        showScrollBar,
        disabled,
        ...rest
    } = props;
    /** @type {React.LegacyRef<HTMLDivElement>} */
    const container = useRef();
    /** @type {React.LegacyRef<Flicking>} */
    const flicking = useRef();
    /** @type {React.LegacyRef<HTMLDivElement>} */
    const scroller = useRef();
    const [moduleToFocus, setModuleToFocus] = useState(startingIndex ?? 0);
    const [ready, setReady] = useState(false);
    const [range, setRange] = useState(null);
    const [flickingDisable, setFlickingDisable] = useState(false);

    useEffect(() => {
        if (!ready) {
            return;
        }

        const targetIndex = startingIndex === -1 ? 0 : startingIndex;

        setModuleToFocus(targetIndex);
        flicking.current.moveTo(targetIndex);
        setRange(flicking.current.camera.range);
    }, [ready]);

    useEffect(() => {
        if (disabled) {
            flicking.current.disableInput();
        } else {
            flicking.current.enableInput();
        }
    }, [disabled]);

    const setScrollerPos = (pos, force = false) => {
        if (!showScrollBar) return;

        if (!force && flickingDisable) {
            return;
        }
        const { min, max } = flicking.current.camera.range;
        const percentRearrange = (pos - min) / (max - min);
        const { clientWidth, scrollWidth } = scroller.current;
        const scrollLeftRearrange =
            percentRearrange * (scrollWidth - clientWidth);
        scroller.current.scrollTo(scrollLeftRearrange, 0);
    };

    const moveTo = (position) => {
        flicking.current.camera.lookAt(position);
        panelTransformer(flicking.current.panels);
    };

    const [changingCurrentIndex, setChangingCurrentIndex] = useState(false);

    const setCurrentIndex = async (targetIndex, duration = 150) => {
        if (changingCurrentIndex) return;

        setChangingCurrentIndex(true);

        await flicking.current.moveTo(targetIndex, duration);

        const newIndex = flicking.current.currentPanel.index;
        setModuleToFocus(newIndex);

        setChangingCurrentIndex(false);
    };

    const changeCurrentIndex = async (delta, duration = 150) => {
        if (changingCurrentIndex) return;

        setChangingCurrentIndex(true);

        const targetIndex = flicking.current.currentPanel.index + delta;

        if (flicking.current.panels[targetIndex] === undefined) {
            setChangingCurrentIndex(false);
            return;
        }

        await flicking.current.moveTo(targetIndex, duration);

        const newIndex = flicking.current.currentPanel.index;
        setModuleToFocus(newIndex);

        setChangingCurrentIndex(false);
    };

    const scrollPrev = () => changeCurrentIndex(-1);

    const scrollNext = () => changeCurrentIndex(1);

    useImperativeHandle(ref, () => ({
        flicking: flicking.current,
        setCurrentIndex,
        changeCurrentIndex,
    }));

    return (
        <div className={classNames(styles.wrapper, className)} ref={container}>
            <div className={styles.flickingParent}>
                {showControlButtons && (
                    <Button disabled={disabled} onClick={scrollPrev} size="xl" icon type="button">
                        <MdChevronLeft />
                    </Button>
                )}
                <Flicking
                    ref={flicking}
                    disableOnInit={disableOnInit}
                    defaultIndex={moduleToFocus}
                    onReady={(e) => {
                        setReady(true);
                        panelTransformer(e.currentTarget.panels);
                    }}
                    align="center"
                    moveType="snap"
                    onMove={(e) => {
                        // The onMove event is called when dragging the
                        // cards *and* when dragging the scroll bar

                        moveTo(e.currentTarget.camera.position);

                        // Only dragging the cards is trusted, so this prop can be
                        // used to decide when to update the scroll bar position
                        if (e.isTrusted) {
                            setScrollerPos(e.currentTarget.camera.position);
                        }

                        onMove(e);
                    }}
                    {...rest}
                >
                    {children}
                </Flicking>
                {showControlButtons && (
                    <Button disabled={disabled} onClick={scrollNext} size="xl" icon type="button">
                        <MdChevronRight />
                    </Button>
                )}
            </div>
            {showScrollBar && range && (
                <div
                    className={styles.scroller}
                    ref={scroller}
                    onMouseDown={(event) => {
                        if (disableOnInit) return;
                        setFlickingDisable(true);
                    }}
                    onMouseUp={async () => {
                        if (disableOnInit) {
                            setScrollerPos(flicking.current.camera.position);
                            return;
                        }
                        setFlickingDisable(false);

                        // Snap to nearest item
                        const pos = flicking.current.camera.position;
                        const nearestAnchor =
                            flicking.current.camera.findNearestAnchor(pos);
                        await flicking.current.control.moveToPosition(pos, 0);
                        setScrollerPos(nearestAnchor.position, true);
                        moveTo(nearestAnchor.position);
                        flicking.current.control.moveToPosition(
                            nearestAnchor.position,
                            200,
                        );
                    }}
                    onScroll={(event) => {
                        if (disableOnInit) {
                            setScrollerPos(flicking.current.camera.position);
                            return;
                        }
                        // TODO: Fix the scroll bar snap back
                        //
                        // If you scroll to a point on the scrollbar too
                        // quickly, the card position will not match.
                        // moveToPosition below is an async function which may
                        // be the cause of it. When scrolling, if the point at
                        // which you let go of the bar is before you have
                        // reached the card the scroll bar lines up with, a
                        // previous call to moveToPosition could still be
                        // processing which will be blocking the final call
                        // made when releasing the scroll bar
                        //
                        // The lower the duration (currently 100ms) the less
                        // apparent the issue.

                        if (!flickingDisable) {
                            return;
                        }

                        const { min, max } = flicking.current.camera.range;
                        const { clientWidth, scrollLeft, scrollWidth } =
                            event.currentTarget;
                        const percent =
                            scrollLeft / (scrollWidth - clientWidth);
                        const targetValue = (max - min) * percent + min;
                        moveTo(targetValue);
                    }}
                >
                    <div />
                </div>
            )}
        </div>
    );
});

Slider.displayName = 'Slider';

Slider.propTypes = {
    startingIndex: PropTypes.number,
    children: PropTypes.node,
    className: PropTypes.string,
    onMove: PropTypes.func,
    disableOnInit: PropTypes.bool,
    panelTransformer: PropTypes.func,
    showControlButtons: PropTypes.bool,
    showScrollBar: PropTypes.bool,
    disabled: PropTypes.bool,
};

Slider.defaultProps = {
    startingIndex: -1,
    children: undefined,
    className: undefined,
    onMove: () => {},
    disableOnInit: false,
    panelTransformer: transformPanels,
    showControlButtons: false,
    showScrollBar: false,
    disabled: false,
};

export default Slider;
