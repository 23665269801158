import $ from 'jquery';
import Component from '../component/component.js';
import WidgetRoles from '../accessibility/aria/widgetrole.js';
import Status from '../data/status.js';
import LanguageManager from '../manager/languagemanager.js';

var Accessibility = Component.extend(
    {
        initialize() {
            this.name = 'Accessibility';
        },

        setup(container) {
            this.container = container;
            this.render();
        },

        render() {
            const accessibilityDom = `<div id="accessibility-container">
                                    <p id='accessibility-title'></p>
                                    <p id='accessibility-status'></p>
                                </div>`;
            // Handlebars.templates["components/accessibility"];
            this.container.dom.prepend(accessibilityDom);
            this.$accessibility = $('#accessibility-container');

            this.$title = this.$accessibility.find('#accessibility-title');
            Accessibility.disable(this.$title);

            this.$status = this.$accessibility.find('#accessibility-status');
            this.$status.attr('role', WidgetRoles.STATUS);

            this.addListeners();
        },

        addListeners() {
            this.listenTo(
                this.container.screenManager,
                'onPageLoaded',
                this.handlePageLoaded
            );
            this.listenTo(
                this.container.screenManager,
                'onPageRemoved',
                this.handlePageRemoved
            );

            const self = this;
            this.$title.blur(function () {
                self.$title.empty();
            });
        },

        handlePageLoaded(e) {
            this.$status.empty();
            this.updateTitle(e.pageData.title());
            this.$title.focus(); // Set focus to top of page.

            this.listenTo(e.pageData, 'onStatusUpdate', function (e) {
                const status = e.target.getStatus();
                if (status >= Status.COMPLETED) {
                    this.$status.text(
                        LanguageManager.getString('accessibility_status')
                    );
                }
            });
        },

        handlePageRemoved(e) {
            if (e.pageData) this.stopListening(e.pageData);
        },

        updateTitle(pageTitle) {
            let title = LanguageManager.getString('accessibility_title');
            if (pageTitle) title += ` ${pageTitle}`;

            this.$title.text(title);
        },

        alert(text) {
            if (this.$alert) this.$alert.remove();

            this.$alert = $("<p id='accessibility-alert'></p>");
            this.$alert.attr('role', WidgetRoles.ALERT);
            this.$alert.text(text);

            Accessibility.disable(this.$alert);

            this.$accessibility.append(this.$alert);
        },

        destroy() {
            this.stopListening();
        },
    },
    {
        TAB_INDEX: '0',

        enable($element) {
            $($element).attr('tabindex', 0);
        },

        disable($element) {
            $($element).attr('tabindex', -1);
        },

        hide($element) {
            $($element.attr('aria-hidden', 'true'));
        },

        show($element) {
            $($element.attr('aria-hidden', 'false'));
        },

        focus($element) {
            $($element).focus();
        },

        blur($element) {
            $($element).blur();
        },
    }
);

export default Accessibility;
