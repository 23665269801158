import React from 'react';
import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import { IoMdPin } from 'react-icons/io';
import { VIEWED_CLASS, hotspotButtonProps } from '../HotspotContants';
import './HotspotPin.scss';

function HotspotPin(props) {
    const { viewed, area } = props;
    const classes = classNames([
        'hotspot-marker-pin',
        { [VIEWED_CLASS]: viewed },
    ]);

    return (
        <div className={classes}>
            <IoMdPin />
        </div>
    );
}

HotspotPin.propTypes = hotspotButtonProps;

export default {
    iconSize: [32, 32],
    popupAnchor: [0, -17],
    component: HotspotPin,
};
