/*
Hotspot Block

Documentation for packages used in this block
react-leaflet@2 https://react-leaflet-v2-docs.netlify.app/en/
leaflet         https://leafletjs.com/reference-1.7.1.html

react-leaflet is limited to v2 until react is updated to v17
TODO: update hotspot block when react is updated to v17
 */

import React, { createRef } from 'react';
import { renderToString } from 'react-dom/server';
import {
    ImageOverlay,
    LeafletConsumer,
    Map,
    Marker,
    Popup,
    Rectangle,
} from 'react-leaflet';
import { CRS, Transformation, divIcon } from 'leaflet';
import { Container, Popover } from 'react-bootstrap';
import HotspotPlus from './hotspotButtons/HotspotPlus';
import HotspotButton from './hotspotButtons/HotspotButton';
import HotspotPin from './hotspotButtons/HotspotPin';
import Block from '~blocks/base/Block';
import BuildAnimation from '~components/animations/BuildAnimation';
import { BrowserDetect } from '~core';
import './leaflet.css';
import './Hotspot.scss';

export const markerComponents = {
    pin: HotspotPin,
    plus: HotspotPlus,
    button: HotspotButton,
};

// Useful for checking things during dev
const showBounds = false;
const showStandardMarker = false;
const showCoordinatesOnClick = false;

// leafletjs has the origin being bottom left by default, this CRS changes the
// origin to top left
const TopLeftCrs = {
    ...CRS.Simple,
    transformation: new Transformation(1, 0, 1, 0),
};

export default class HotspotBlock extends Block {
    constructor(props) {
        super(props);

        this.imageHeight = this.data.options.sourceHeight;
        this.imageWidth = this.data.options.sourceWidth;
        this.options = this.data.options;
        this.element = React.createRef();
        this.markers = [];
    }

    componentDidMount() {
        console.info(
            'Markers : ',
            this.markers,
            document.querySelector('.hotspot-marker-button')
        );
        console.info('BA Hotspot Block:', this.element.current);
        if (this.element.current != null)
            BuildAnimation.animateIn(this.element.current);
    }

    /** @type {import('./hotspot-types').HotspotSchema} */
    data = this.props.blockRef?.data;

    /**
     * @type {object} state
     * @property {Array<undefined|true>} areaCompletion - Track the viewed markers
     */
    state = {
        viewedMarkers: [...Array(this.data.areas.length)],
    };

    mapRef = createRef();

    /**
     * The height of the image
     *
     * @type {number}
     */
    imageHeight;

    /**
     * The width of the image
     *
     * @type {number}
     */
    imageWidth;

    render() {
        const { imageHeight, imageWidth } = this;
        const { minZoom = undefined, maxZoom = undefined } = this.options;

        const quarterHeight = imageHeight / 4;
        const quarterWidth = imageWidth / 4;

        const bounds = [
            [0, 0],
            [imageHeight, imageWidth],
        ];
        const center = [imageHeight / 2, imageWidth / 2];

        const maxBounds = [
            [0 - quarterHeight, 0 - quarterWidth],
            [imageHeight + quarterHeight, imageWidth + quarterWidth],
        ];

        const markers = this.getMarkerComponents();

        const standardMarkers = this.data.areas.map(({ coords }, key) => {
            const x = (coords[0] / 100) * imageWidth;
            const y = (coords[1] / 100) * imageHeight;
            return (
                <Marker
                    key={`${key.toString()}-standard-marker`}
                    position={[y, x]}
                />
            );
        });

        const handleClick = (e) => {
            if (showCoordinatesOnClick) {
                const { lat, lng } = e.latlng;
                console.log(`[${lat}, ${lng}]`);
            }
        };

        return (
            <Container>
                <div className="hotspot-block" ref={this.element}>
                    <Map
                        ref={this.mapRef}
                        crs={TopLeftCrs}
                        center={center}
                        maxZoom={minZoom}
                        minZoom={maxZoom}
                        // zoomSnap={0.005}
                        attributionControl={false}
                        maxBounds={maxBounds}
                        bounds={bounds}
                        onPopupopen={(...attrs) => {
                            // console.log(this.data.areas.map(a=>a.viewed))
                        }}
                        onClick={handleClick}
                    >
                        <LeafletConsumer>
                            {({ map }) => {
                                // console.log('map:', map)
                            }}
                        </LeafletConsumer>
                        <ImageOverlay
                            url={this.block.data.source}
                            bounds={bounds}
                        />
                        {showBounds ? <Rectangle bounds={bounds} /> : null}
                        {markers}

                        {/* Show a standard marker if needed during dev */}
                        {showStandardMarker ? standardMarkers : null}
                    </Map>
                </div>
            </Container>
        );
    }

    componentDidUpdate() {
        this.checkCompletion();
    }

    checkCompletion() {
        const completed = this.state.viewedMarkers.every(
            (completion) => completion === true
        );

        if (!completed) {
            return;
        }

        this.block.complete();
    }

    getMarkerComponents() {
        const { imageHeight, imageWidth } = this;

        return this.data.areas.map((area, key) => {
            // leaflet positions are [Y,X] not [X,Y]
            const [y, x] = area.coords;

            const props = {};

            // Disables the pop-up close cross if we're not on mobile and if the area is hoverable
            if (!BrowserDetect.isMobileDevice && area.trigger === 'hover') {
                document
                    .querySelectorAll('.leaflet-popup-close-button')
                    .forEach((pin) => {
                        pin.style.display = 'none';
                    });
            }

            if (area.trigger === 'hover') {
                props.onMouseover = (e) => e.target.openPopup();
                props.onMouseout = (e) => e.target.closePopup();
            }

            const markerComponent = markerComponents[area.type];

            if (markerComponent) {
                const {
                    iconSize,
                    popupAnchor = [0, 0],
                    component: Component,
                } = markerComponent;

                const element = (
                    <Component
                        viewed={this.state.viewedMarkers[key]}
                        area={area}
                    />
                );

                props.icon = divIcon({
                    iconSize,
                    popupAnchor,
                    html: renderToString(element),
                });
            }

            const popupOpened = () => {
                const areaCompletion = this.state.viewedMarkers;
                areaCompletion[key] = true;
                this.setState({ areaCompletion });
            };

            const popupClosed = () => {};

            this.markers[key] = React.createRef();
            return (
                <Marker
                    key={key}
                    aria-label={area.content.value}
                    // leaflet positions are [Y,X] not [X,Y]
                    position={[y, x]}
                    data={area}
                    {...props}
                    // ref={this.markers[key]}
                    ref={this.element}
                >
                    <Popup
                        onOpen={popupOpened}
                        onClose={popupClosed}
                        aria-label={area.content.value}
                    >
                        <Popover.Title aria-label={area.content.value}>
                            {area.content.title}
                        </Popover.Title>
                        <Popover.Content>{area.content.value}</Popover.Content>
                    </Popup>
                </Marker>
            );
        });
    }
}
