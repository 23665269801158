import { useRef } from 'react';

/**
 * Create a ref for an array of elements
 *
 * @returns {[
 *  import('react').MutableRefObject,
 *  import('react').RefCallback<T>
 * ]}
 * @template T
 */
function useRefArray() {
    const elements = useRef([]);
    const refProp = element => elements.current.push(element);
    return [elements, refProp];
}

export default useRefArray;
