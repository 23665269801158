import React from 'react';
import ReactDOM from 'react-dom';
import Backbone from 'backbone';
import Launch from '~blocks/launch/Launch';

const LaunchPage = Backbone.View.extend({
    /**
     * Initializing the launch page
     *
     * @param {object} properties
     */
    initialize(properties) {
        this.enableOnlyEnglish = !!properties.enableOnlyEnglish;
        this.jsonPath = properties.jsonPath;
    },

    /**
     *  Listen to container setup completion before running functions
     *
     * @param {object} container
     */
    setup(container) {
        if (!container.showLaunchPage) return;
        this.container = container;
        this.container.launchPage = this;
        this.listenTo(this.container, 'onSetupComplete', this.start);
    },

    /**
     * Load JSON passed in initalisation
     */
    start() {
        const self = this;
        $.getJSON(this.jsonPath, function (data) {
            self.onDataLoad(data);
        });
    },

    /**
     * Sets up data and runs render
     *
     * @param {object} e
     */
    onDataLoad(e) {
        this.data = e;
        this.render();
    },

    /**
     * Launch component is appended
     *
     */
    render() {
        const component = <Launch blockRef={this}></Launch>;
        this.$el = $("<div class='Launch' />");
        this.container.dom.append(this.$el);
        ReactDOM.render(component, this.$el[0]);
    },

    /**
     * Launch is removed from DOM when the course is to load
     */
    destroy() {
        this.stopListening();
        this.remove();
    },
});

export default LaunchPage;
