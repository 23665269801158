import _ from 'underscore';
import $ from 'jquery';
import Backbone from 'backbone';
import { soundManager } from 'soundmanager2';
import Session from '../session/session.js';
import Logger from '../utils/log/logger.js';
import { PxLoader } from '../../libraries/pxloader/PxLoader';

const AudioManager = Backbone.Model.extend(
    {
        constructor() {
            // const container = null;
            // const session = Session;
            // // var loader = PxLoader;
            // const assetsLoaded = null;
            // const isAudioOn = null;
            // const initialized = false;
            // const volume = 100;

            Backbone.Model.apply(this, arguments);
        },

        initialize(container) {
            const scope = this;

            this.container = container;

            this.loader = new PxLoader();

            this.assetsLoaded = [];

            this.isAudioOn = true;

            soundManager.setup({
                debugMode: false,
                useConsole: false,
                // consoleOnly: SiConfig.loggerEnabled,
                useHighPerformance: true,
                useHTML5Audio: true,
                onready() {
                    scope.initialized = true;
                    scope.checkAudioPreference();
                    scope.trigger('onAudioManagerReady', { target: scope });
                },
                ontimeout() {},
                defaultOptions: {
                    // set global default volume for all sound objects
                },
            });

            this.session = container.getSession();

            this.listenTo(
                this.container.screenManager,
                'onPageRemoved',
                function (e) {
                    this.destroyPageAudio(e.pageData);
                }
            );

            this.listenTo(this.session, 'onToggleAudio', function (e) {
                this.toggleAudio();
            });

            this.iOSAutoEnable();
        },

        checkAudioPreference() {
            let volume = Number(
                this.container.tracking.getItem(AudioManager.TRACKING_VOLUME)
            );

            // Set to on, if the LMS volume is not a number
            if (isNaN(volume) || volume === -1) volume = 100;

            if (parseInt(volume) > 0) {
                this.setVolume(volume);
            } else {
                this.setVolume(0);
            }

            const isMuted = this.container.tracking.getItem(
                AudioManager.TRACKING_MUTED
            );

            if (isMuted === 'true') {
                soundManager.setVolume(0);
                this.isAudioOn = false;
            } else {
                soundManager.setVolume(this.volume);
                this.isAudioOn = true;
            }

            this.trigger('onAudioToggle', {
                target: this,
                isAudioOn: this.isAudioOn,
            });
        },

        destroyPageAudio(pageData) {
            const result = _.partition(this.assetsLoaded, function (asset) {
                return asset.pageId === pageData.pageID();
            });

            _.each(result[0], function (asset) {
                Logger.trace(
                    'AudioManager',
                    'destroying sound',
                    asset.audioId,
                    pageData.pageID()
                );
                soundManager.destroySound(asset.audioId);
            });

            // update list to exclude destroyed audio
            this.assetsLoaded = result[1];
        },

        add(id, url) {
            // if (ManifestHelper.exists && !ManifestHelper.contains(url)) {
            // Logger.warn('[AudioManager] No such file in imsmanifest.xml: ' + url);

            // this.trigger('onAudioNotInManifest', { target: this });
            // return false;
            // }

            if (!id || !url) return false;

            const currentPageId = this.container.router.getCurrentPageId();

            Logger.trace('AudioManager', 'adding audio:', id, currentPageId);
            this.loader.addSound(id, url);
            this.assetsLoaded.push({
                pageId: currentPageId,
                audioId: id,
            });

            this.trigger('onAudioAdded', { target: this, id, url });
            return true;
        },

        play(id, attributes) {
            Logger.trace('AudioManager', 'playing audio:', id);

            const scope = this;
            let volume = 0;

            if (!attributes) attributes = {};

            if (this.isAudioOn) {
                volume =
                    attributes.volume != undefined
                        ? attributes.volume
                        : this.getVolume();
            }

            this.currentSound = soundManager.getSoundById(id);

            const sound = soundManager.play(id, {
                volume,
                loops: attributes.loops,
                onload(success) {
                    scope.onAudioLoad(this.id, success);
                },
                onplay() {
                    scope.onAudioPlay(this.id);
                },
                onfinish() {
                    scope.onAudioCompleted(this.id);
                },
                /**
                 * See HTML5 MediaError for error codes and descriptions.
                 *
                 * @param code
                 * @param description
                 */
                onerror(code, description) {
                    if (this.loaded) {
                        Logger.error(
                            'AudioManager',
                            `audio playback error: ${this.id}`,
                            code,
                            description
                        );
                        this.stop(); // Reset sound state, to be safe
                    } else {
                        Logger.error(
                            'AudioManager',
                            `audio failed to load: ${this.id}`,
                            code,
                            description
                        );
                    }

                    scope.trigger('onAudioError', {
                        target: this,
                        id: this.id,
                        isLoaded: this.loaded,
                        errorCode: code,
                        errorDescription: description,
                    });
                },
                whileplaying() {
                    scope.onAudioPlaying(this.id, this.position, this.duration);
                },
            });

            return sound;
        },

        onAudioPlay(id) {
            this.trigger('onAudioPlay', {
                target: this,
                id,
            });
        },

        onAudioLoad(id, success) {
            this.trigger('onAudioLoad', {
                target: this,
                id,
                isLoaded: success,
            });
        },

        onAudioCompleted(id) {
            this.trigger('onAudioFinish', {
                target: this,
                id,
            });
        },

        onAudioPlaying(id, position, duration) {
            this.trigger('onAudioPlaying', {
                target: this,
                id,
                position,
                duration,
            });
        },

        stop(id) {
            // Only stop playing a single file
            if (id || id === 0) {
                return soundManager.stop(id).unload(id);
            }
            soundManager.stopAll();
        },

        pause(id) {
            soundManager.pause(id);
        },

        resume(id) {
            soundManager.resume(id);
        },

        togglePause(id) {
            const sound = soundManager.getSoundById(id);
            if (!sound) return false;

            if (sound.playState) {
                soundManager.togglePause(id);
            } else {
                this.play(id);
            }
        },

        toggleAudio() {
            if (this.isAudioOn) {
                soundManager.setVolume(0);
                this.container.tracking.setItem(
                    AudioManager.TRACKING_MUTED,
                    true
                );
            } else {
                soundManager.setVolume(this.volume);
                this.container.tracking.setItem(
                    AudioManager.TRACKING_MUTED,
                    false
                );
            }

            this.isAudioOn = !this.isAudioOn;
            this.trigger('onAudioToggle', {
                target: this,
                isAudioOn: this.isAudioOn,
            });

            this.update();
        },

        update() {
            this.trigger('onUpdate', {
                target: this,
            });
        },

        setVolume(value) {
            this.volume = value;
            soundManager.setVolume(this.volume);

            this.container.tracking.setItem(
                AudioManager.TRACKING_VOLUME,
                value
            );

            this.trigger('onSetVolume', { target: this, volume: this.volume });
        },

        getVolume() {
            return this.volume;
        },

        formatAudioUrl(id) {
            const self = this;
            let path = self.audioPath;
            const audioCodes = [];
            audioCodes.push({
                code: '%language%',
                val: self.container.getLanguage(),
            });
            // audioCodes.push({ code:"%id%",	 		val:id })
            audioCodes.push({
                code: '%module%',
                val: self.container.session.currentPageData.moduleID(),
            });
            audioCodes.push({
                code: '%modulenumber%',
                val: self.container.session.currentPageData.moduleNumber(),
            });
            audioCodes.push({ code: '%blockid%', val: id });
            audioCodes.push({
                code: '%pageid%',
                val: self.container.router.getCurrentPageId(),
            });

            $(audioCodes).each(function (i) {
                if (path.includes(audioCodes[i].code)) {
                    path = path
                        .split(audioCodes[i].code)
                        .join(audioCodes[i].val);
                }
            });

            return path;
        },

        setAudioPath(url) {
            this.audioPath = url;

            return true;
        },

        getAudioPath() {
            return this.audioPath;
        },

        iOSAutoEnable() {
            const self = this;

            $(document).one('touchend', function (e) {
                self.add('blank', 'assets/mp3/blank.mp3');
                self.play('blank');
            });
        },
    },
    {
        TRACKING_VOLUME: 'AudioVolume',
        TRACKING_MUTED: 'AudioMuted',
    }
);

export default AudioManager;
