import TweenMax from 'gsap/TweenMax';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useContainer } from '~modules/componentMounter/componentMounterContext';
import { useRefEvent } from '~hooks/index';

function AdminGreensock() {
    const { container } = useContainer();
    const [open, setOpen] = useState(false);
    const toggle = () => setOpen(!open);
    const inputEl = useRef();
    const [multiplier, setMultiplier] = useState(1);
    const update = (value) => setMultiplier(parseFloat(value));

    useEffect(() => {
        container.on('admin:palette-toggle:greensock', toggle);
    }, []);

    const handleKeyDown = (event) => event.key === 'Escape' && setOpen(false);
    useRefEvent(inputEl, 'keydown', handleKeyDown, [open]);

    useEffect(() => {
        open && setMultiplier(TweenMax.globalTimeScale());
    }, [open]);

    useEffect(() => {
        TweenMax.globalTimeScale(multiplier);
    }, [multiplier]);

    return (
        <Modal show={open} onHide={() => setOpen(false)}>
            <Modal.Header>
                <Modal.Title>Change animation speed</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Change Greensock speed multiplier</p>
                <Form.Control
                    type="number"
                    autoFocus
                    value={multiplier}
                    onChange={(event) => update(event.target.value)}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => update(1)}>
                    Reset
                </Button>
                <Button variant="primary" onClick={() => setOpen(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AdminGreensock;
