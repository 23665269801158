import _ from 'underscore';
import $ from 'jquery';
import Backbone from 'backbone';
import Spinner from 'spin';
import whatInput from 'what-input';
import { ConnectionNotification } from '../component/notifications/connectionnotification';
import WindowHelper from '../utils/window/windowhelper.js';
import URLHelper from '../utils/url/urlhelper.js';
import ManifestHelper from '../utils/manifest/manifesthelper.js';
import BlockData from '../data/blockdata.js';
import Status from '../data/status.js';
import Tracking from '../tracking/scorm/tracking.js';
import CourseData from '../data/coursedata.js';
import PageData from '../data/pagedata.js';
import BrowserDetect from '../utils/browser/browserdetect.js';
import LanguageManager from '../manager/languagemanager.js';
import AssetLoader from '../loader/assetloader.js';
import Session from '../session/session.js';
import LMSAdaptor from '../tracking/adaptors/lmsadaptor.js';
import ScreenRenderer from '../render/screenrenderer.js';
import AudioManager from '../manager/audiomanager.js';
import ApplicationRouter from '../router/applicationrouter.js';
// import ReviewMode from '../component/reviewmode.js';
import Logger from '../utils/log/logger.js';
import { ContentReplacer } from '../loader/contentreplacer';
import Watermark from '../component/watermark.js';
// import AdminPage from '../component/adminpage.js';
import Accessibility from '../component/accessibility.js';
import Storage from '../component/storage/storage.js';

/**
 * @class The main Class for the Saffron HTML container.
 * @name Container
 *
 * @property {AssetLoader} loader
 * @property {ApplicationRouter} router
 * @property {CourseData} courseData
 * @property {AudioManager} audioManager
 * @property {ScreenRenderer} screenRenderer
 * @property {LanguageManager} languageManager
 * @property {Session} session
 * @property {string} language - Defaults to [en] and sets the language used within the course
 * @property {ref} dom - The main stage area for the container to run
 * @property {ref} scroller
 * @property {ref} $window
 * @property {ref} $page
 * @property {Preloader} preloader
 * @property {ref} $loader
 * @property {string} courseXML
 * @property {boolean} isShowIntro
 * @property {boolean} isShowScore
 * @property {string} startPage
 * @property {Component[]} componentList - Accepts an Array of [Component.js] items, that will be initialised during the startup of the container. These can register for events during the startup of the container.
 * @property {LMSAdaptor} lmsAdaptor
 * @property {CourseProperties} lmsProperties
 * @property {number} width
 * @property {number} height
 * @property {number} scrollTop
 * @property {string} orientation
 * @property {} resizeTimer
 * @property {Tracking} tracking - Access to a Tracking interface for communication with an LMS, local-storage or portal
 * @property {boolean} isLocked - Defaults to [true] and enables or disables screen IDs and skip navigation throughout the course
 * @property {boolean} isCached - Defaults to [true] and dictates whether assets loded from the [AssetLoader] are cached or not
 * @property {URLHelper} urlhelper
 * @property {boolean} [forceFullSize] - Resizes the window to fit the screen dimensions
 *
 * @see Backbone.Model
 */
const Container = Backbone.Model.extend(
    /** @lends Container.prototype */ {
        constructor(...args) {
            this.loader = AssetLoader;
            this.router = ApplicationRouter;
            this.courseData = CourseData;
            this.audioManager = AudioManager;
            this.screenManager = ScreenRenderer;
            this.languageManager = LanguageManager;
            this.session = Session;
            this.language = undefined;
            this.dom = document;
            this.scroller = undefined;
            this.$window = undefined;
            this.$page = undefined;
            this.preloader = undefined;
            this.$loader = undefined;
            this.courseXML = undefined;
            this.isShowIntro = undefined;
            this.isShowScore = undefined;

            this.startPage = undefined; // custom start page, if undefined "coursemap" will be used

            this.componentList = [];

            this.lmsAdaptor = undefined;
            this.lmsProperties = undefined; // CourseProperties;

            this.width = window.innerWidth;
            this.height = window.innerHeight;
            this.scrollTop = 0;
            this.orientation = undefined;

            this.resizeTimer = null;

            this.tracking = undefined;
            this.isLocked = true;
            this.isCached = false;
            this.urlhelper = undefined;

            Backbone.Model.apply(this, args);
        },

        /**
         * Initialize the container object
         */
        initialize() {
            this.initializeLogger();

            if (this.attributes.forceFullsize) WindowHelper.setFullscreen();

            this.urlHelper = new URLHelper(window.location);

            // Assign a different DOM just in case you don't want the container to be the whole page.
            this.dom = $(this.attributes.dom || window.document);

            // Scroller element selector
            this.scroller =
                this.attributes.scroller || Container.DEFAULT_SCROLLER;

            this.interactions = this.attributes.interactions || {};

            // Set a start page if supplied
            const startPageFromQueryString =
                this.urlHelper.getQueryVariableByName('startPage');
            this.startPage =
                startPageFromQueryString ||
                this.attributes.startPage ||
                'coursemap';

            // Set the enviroment to locked
            this.isLocked = this.attributes.locked || false;

            this.storeHistory = this.attributes.storeHistory || false;

            this.bookmarkJunctions = this.attributes.bookmarkJunctions || false;

            this.showScreenIds = this.attributes.showScreenIds || false;

            // Set the browser to cache/uncache the files being loaded
            this.isCached = this.attributes.cached !== false;

            // Set default language for the course
            this.language = this.attributes.language || '';
            this.languagePath = this.attributes.languagePath || '';
            this.replacerPath = this.attributes.replacerPath || '';

            this.courseXML = this.attributes.courseXML || '';

            this.isShowIntro = this.attributes.isShowIntro !== false;

            this.isShowScore = this.attributes.isShowScore === true;
            this.showLaunchPage = this.attributes.showLaunchPage || false;

            if (!this.isLocked) {
                $(this.dom).addClass('unlocked');
            }

            this.preloader = new AssetLoader({
                name: 'pre',
            });
            this.initializeTracking();

            // Intialise the browser detection class for reference later on
            BrowserDetect.init();

            if (!this.isLocked && this.logging) this.countCSSRules();
        },

        /**
         * Setting up the container
         */
        setup() {
            this.loader = new AssetLoader({
                name: 'container',
            });
            this.session = new Session(this);
            this.courseData = new CourseData(this);

            if (this.tracking) {
                // Create an interface to manage completion of course/units/pages/blocks
                this.lmsAdaptor = new LMSAdaptor(
                    this,
                    this.courseData,
                    this.session
                );
            }

            // Assign top level elements
            this.$window = $(window);
            this.$page = $(this.dom).find('#scroller');
            this.$footer = $(this.dom).find('#footer');
            this.$subNavigation = $(this.dom).find('#sub-navigation');
            this.$navigation = $(this.dom).find('#navigation');

            // Hack: don't seem to get scroll events on the body.
            this.$scroller =
                this.scroller.toLowerCase() === 'body'
                    ? this.$window
                    : $(this.scroller);

            this.screenManager = new ScreenRenderer(
                this,
                this.session,
                this.$page
            );

            this.audioManager = new AudioManager(this);

            if (this.attributes.audioPath) {
                this.setAudioPath(this.attributes.audioPath);
            }

            if (this.attributes.router) {
                this.router = this.attributes.router;
                this.router.setContainer(this);
            } else {
                this.router = new ApplicationRouter(this);
            }

            this.initializeComponents(this.attributes.components);
            // Add core components
            // if (!this.isLocked) this.addComponent(new ReviewMode());
            // if (SiConfig.enableAdminPage) this.addComponent(new AdminPage());
            if (SiConfig.enableWatermark) this.addComponent(new Watermark());

            this.createUnloadHandler();
            this.createResizeHandler();
            /**
             * onSetupComplete is fired when setting up container is completed
             *
             * @event Container#onSetupComplete
             * @type {object}
             * @property {Container} target
             */
            this.trigger('onSetupComplete', {
                target: this,
            }); // Why was this commented. Because of this Notification event are not getting initialised.

            if (this.showLaunchPage) {
                if (!this.launchPage)
                    Logger.error('LaunchPage is not initialised.');
                this.listenTo(
                    this.launchPage,
                    'onLaunchCourse',
                    this.handleCourseLaunch
                );
            } else {
                this.preloadCourse();
                this.addCssClasses();
                this.showCourse();
            }
        },

        handleCourseLaunch(e) {
            this.language = e.language;
            this.languagePath = this.languagePath.replace(
                'course_en',
                `course_${e.language}`
            );
            this.replacerPath = this.replacerPath.replace(
                'course_en',
                `course_${e.language}`
            );
            this.courseXML = this.courseXML.replace(
                'course_en',
                `course_${e.language}`
            );

            this.preloadCourse();
            this.addCssClasses();

            // Initialise language dependendent components here

            this.showCourse();
        },

        /**
         * Initializing the Logger
         */
        initializeLogger() {
            Logger.initialize({
                enabled: SiConfig.loggerEnabled,
                showPopUp: SiConfig.loggerShowPopup,
                level: SiConfig.loggerLevel,
            });
        },

        /**
         * Initializing the tracking for communication with LMS
         */
        initializeTracking() {
            this.tracking = new Tracking({
                useLocalStorage: SiConfig.useLocalStorage,
                useCompression: SiConfig.useCompression,
            });

            this.listenToOnce(this.tracking, 'onInitComplete', function () {
                this.stopListening(this.tracking, 'onInitFail');
                this.setup();
            });

            this.listenToOnce(this.tracking, 'onInitFail', function () {
                this.showConnectionNotification();
            });

            this.tracking.start();
        },

        /**
         * Initializing the Components passed in as array into the container
         */
        initializeComponents(components) {
            if (!components) return false;

            if (Object.prototype.toString.call(components) !== '[object Array]')
                throw 'Array expected';

            for (let i = 0; i < components.length; i++) {
                this.addComponent(components[i]);
            }
        },

        /**
         * Set the language of the container
         * @param {string} language
         */
        setLanguage(language) {
            this.language = language;
        },

        /**
         * Get the language of the container
         * @returns {string}
         */
        getLanguage() {
            return this.language;
        },

        /**
         * Set the audio path of the course
         * @param {string} url
         */
        setAudioPath(url) {
            this.audioManager.setAudioPath(url);
        },

        /**
         * Get the audio path of the course
         * @returns {string}
         */
        getAudioPath() {
            this.audioManager.getAudioPath();
        },

        /**
         * Get the session reference
         * @returns {Session}
         */
        getSession() {
            return this.session;
        },

        /**
         * Load the course XML
         */
        showCourse() {
            if (!this.courseXML) {
                throw 'No course XML set, you need to set via this.setCourseXML';
            }

            this.attachPreloader();
            this.loadCourse(this.courseXML);
        },

        /**
         * Start the preloader to load all the assets of the course
         */
        preloadCourse() {
            ContentReplacer.initialize(this.replacerPath, this);
            LanguageManager.initialize(this.languagePath, this);
            ManifestHelper.initialize(this);

            this.preloader.start();
        },

        /**
         * Load the course XML or JSON passed as url
         *
         * @param {string} url
         */
        loadCourse(url) {
            if (url.includes('.json')) {
                const self = this;
                $.getJSON(url, function (data) {
                    self.onCourseLoaded(data);
                });
            } else {
                const courseXMLLoader = new AssetLoader({
                    name: 'course',
                });
                courseXMLLoader.addXML(url, null, null, this, 'onCourseLoaded');
                courseXMLLoader.start();
            }
        },

        /**
         * Attach the spinner until course is preloading
         */
        attachPreloader() {
            const spinner = new Spinner(this.getSpinnerPreferences()).spin();
            this.$loader = $(
                "<div id='loader' class='preloader'><div class='spinner-holder' style='height:100px; position:absolute; left:50%; top:50%;' /></div</div>"
            );
            $(this.dom).append(this.$loader);
            this.$loader.find('.spinner-holder').append(spinner.el);
        },

        /**
         * Remove the spinner
         */
        removePreloader() {
            this.$loader.remove();
        },

        /**
         * Add respective classes based on mobile device or not and language selected
         */
        addCssClasses() {
            if (BrowserDetect.isMobileDevice) $('html').addClass('mobile');
            if (BrowserDetect.isiOS) $('html').addClass('ios');

            $(this.scroller).addClass(Container.SCROLLER_CLASS);

            this.dom.addClass(`lang-${this.language}`);
        },

        /**
         * Create unload handler to handle before window unload and complete tracking requirements of LMS
         */
        createUnloadHandler() {
            const self = this;
            let isUnloadHandled = false;

            const unloadHandler = function (e) {
                if (isUnloadHandled) return;
                isUnloadHandled = true;

                try {
                    self.destroy();
                    /**
                     * onDestroy is fired when unload handler is executed
                     *
                     * @event Container#onDestroy
                     * @type {object}
                     * @property {Container} target
                     */
                    self.trigger('onDestroy', {
                        target: this,
                    });
                } catch (error) {
                    Logger.info('Failed on unload:', error);
                } finally {
                    self.tracking.finish(); // make sure this runs.
                }
            };

            const $window = $([window, window.top]);

            $window.bind('unload', unloadHandler);
            $window.bind('beforeunload', unloadHandler);
        },

        /**
         * Create window resize and scroll event handlers
         */
        createResizeHandler() {
            this.$scroller.on(
                'scroll',
                function (event) {
                    this.scroll(event);
                }.bind(this)
            );

            this.$window.on(
                'resize',
                function (event) {
                    this.resize(event);

                    // delay required for older versions of iOS where orientation is updated after resize
                    _.delay(this.updateOrientation.bind(this), 350);
                }.bind(this)
            );

            this.$window.on(
                'wheel',
                function (event) {
                    this.mouseWheel(event);
                }.bind(this)
            );

            this.resize(null);
            this.scroll(null);
            this.updateOrientation(null);
        },

        /**
         * Trigger an event when mouse wheel is used
         *
         * @param {object} event
         */
        mouseWheel(event) {
            /**
             * onMouseWheel is fired when unload handler is executed
             *
             * @event Container#onMouseWheel
             * @type {object}
             * @property {Container} target
             * @property {number} clientX
             * @property {number} clientY
             * @property {object} event
             */
            this.trigger('onMouseWheel', {
                target: this,
                clientX: event.clientX,
                clientY: event.clientY,
                originalEvent: event,
            });
        },

        /**
         * Handle the scroll even of the window
         *
         * @event onScroll
         * @type {object}
         * @param {object} event
         * @property {number} height - New height of the container
         * @property {number} scrollTop - The current scroll top position
         * @property {number} scrollLeft - The current scroll left position
         * @property {object} originalEvent - Original scroll event
         */
        scroll(event) {
            if (!event) return;

            const $scroller = $(event.target);
            /**
             * onScroll is fired when window scroll event get triggered
             *
             * @event Container#onScroll
             * @type {object}
             * @property {Container} target
             * @property {number} height
             * @property {number} scrollTop
             * @property {number} scrollLeft
             * @property {object} event
             */
            this.trigger('onScroll', {
                target: this,
                height: this.height,
                scrollTop: $scroller.scrollTop(),
                scrollLeft: $scroller.scrollLeft(),
                originalEvent: event,
            });
        },

        /**
         * Handle the window resize event
         *
         * @event onResize
         * @type {object}
         * @param {object} event
         */
        resize(event) {
            const self = this;
            const windowWidth = this.dom.width();
            const windowHeight = this.dom.height();

            this.width = windowWidth;
            this.height = windowHeight;

            /**
             * onResize is fired when window is resized
             *
             * @event Container#onResize
             * @type {object}
             * @property {Container} target
             * @property {number} width
             * @property {number} height
             */
            this.trigger('onResize', {
                target: this,
                width: windowWidth,
                height: windowHeight,
            });

            clearTimeout(this.resizeTimer);

            this.resizeTimer = setTimeout(function () {
                /**
                 * onResizeComplete is fired when window resizing is complete
                 *
                 * @event Container#onResizeComplete
                 * @type {object}
                 * @property {Container} target
                 * @property {number} width
                 * @property {number} height
                 */
                self.trigger('onResizeComplete', {
                    target: this,
                    width: windowWidth,
                    height: windowHeight,
                });
            }, 250);
        },

        /**
         * Update the orientation to portrait or landscape
         */
        updateOrientation() {
            const currentOrientation = this.getOrientation();
            if (this.orientation !== currentOrientation) {
                this.orientation = currentOrientation;
                /**
                 * onOrientationChange is fired when orientation is changed to portrait or landscape
                 *
                 * @event Container#onOrientationChange
                 * @type {object}
                 * @property {Container} target
                 * @property {string} orientation
                 */
                this.trigger('onOrientationChange', {
                    target: this,
                    orientation: this.orientation,
                });
            }
        },

        /**
         * Get the current orientation
         */
        getOrientation() {
            // prefer new screen orientation API if available
            if (
                window.screen.orientation != null &&
                window.screen.orientation.angle != null
            ) {
                return window.screen.orientation.angle;
            }

            // window.orientation is deprecated but currently seems to be the only method reliable on iOS
            if (window.orientation != null) {
                return window.orientation;
            }

            // orientation unknown
            return null;
        },

        /**
         * Get the spinner preferences
         */
        getSpinnerPreferences() {
            const options = {
                lines: 13, // The number of lines to draw
                length: 4, // The length of each line
                width: 3, // The line thickness
                radius: 8, // The radius of the inner circle
                rotate: 21, // The rotation offset
                color: '#000', // #rgb or #rrggbb
                speed: 0.9, // Rounds per second
                trail: 39, // Afterglow percentage
                shadow: false, // Whether to render a shadow
                hwaccel: true, // Whether to use hardware acceleration
                className: 'spinner', // The CSS class to assign to the spinner
                zIndex: 2e9, // The z-index (defaults to 2000000000)
                top: '50%', // Top position relative to parent in px
                left: '50%', // Left position relative to parent in px
            };

            return options;
        },

        /**
         * Add all the component passed into container and store it in componentList
         *
         * @param {Component} component
         */
        addComponent(component) {
            const oldComponent = this.componentList[component.name];

            if (!oldComponent) {
                this.componentList[component.name] = component;
                component.setup(this);
            } else {
                Logger.error(
                    `${component.name} COMPONENT ALREADY ADDED TO THE CONTAINER!`
                );
            }
        },

        /**
         * Get reference of component by matching its name from the componentList
         *
         * @param {string} componentName
         */
        getComponent(componentName) {
            const component = this.componentList[componentName];
            if (component) {
                return this.componentList[componentName];
            } else {
                Logger.warn(`${componentName} COMPONENT NOT FOUND!`);
            }
        },

        /**
         * Hides the scroll and switch the browser to fullscreen
         */
        enterFullScreen() {
            this.isFullscreen = true;

            $('html').addClass('hidescroll');
            /**
             * onEnterFullScreen is fired when fullscreen mode is turned on
             *
             * @event Container#onEnterFullScreen
             * @type {object}
             * @property {Container} target
             * @property {boolean} isFullscreen
             */
            this.trigger('onEnterFullScreen', {
                target: this,
                isFullscreen: this.isFullscreen,
            });
        },

        /**
         * Shows the scroll and switch back to normal screen
         */
        exitFullScreen() {
            this.isFullscreen = false;

            $('html').removeClass('hidescroll');
            /**
             * onExitFullScreen is fired when fullscreen mode is turned off
             *
             * @event Container#onExitFullScreen
             * @type {object}
             * @property {Container} target
             * @property {boolean} isFullscreen
             */
            this.trigger('onExitFullScreen', {
                target: this,
                isFullscreen: this.isFullscreen,
            });
        },

        /**
         * set the course XML or JSON path
         *
         * @param {string} url
         */
        setCourseXML(url) {
            this.courseXML = url;
        },

        /**
         * Load blank audio for playing for iphones and ipads to handle their audio policies
         */
        loadDummyAudioForIpad() {
            this.audioManager.add('blank', 'assets/mp3/blank.mp3');
            this.audioManager.play('blank');
        },

        /**
         * Print the stylesheets Rules used in the course
         */
        countCSSRules() {
            let results = '';
            let log = '';
            if (!document.styleSheets) {
                return;
            }
            for (let i = 0; i < document.styleSheets.length; i++) {
                countSheet(document.styleSheets[i]);
            }

            function countSheet(sheet) {
                let count = 0;
                if (sheet && sheet.cssRules) {
                    for (let j = 0, l = sheet.cssRules.length; j < l; j++) {
                        if (!sheet.cssRules[j].selectorText) {
                            continue;
                        }
                        count +=
                            sheet.cssRules[j].selectorText.split(',').length;
                    }

                    log += `\nFile: ${
                        sheet.href ? sheet.href : 'inline <style> tag'
                    }`;
                    log += `\nRules: ${sheet.cssRules.length}`;
                    log += `\nSelectors: ${count}`;
                    log += '\n--------------------------';
                    if (count >= 4096) {
                        results += `\n********************************\nWARNING:\n There are ${count} CSS rules in the stylesheet ${
                            sheet.href
                        } - IE will ignore the last ${count - 4096} rules!\n`;
                    }
                }
            }
            Logger.info(log);
            Logger.info(results);
        },

        /**
         * Show the connection notification if connection is lost
         */
        showConnectionNotification() {
            Logger.error('No LMS detected!');

            new ConnectionNotification().show();
        },

        /**
         * Destroy the container
         */
        destroy() {
            this.screenManager.destroy();
        },

        /**
         * Reset the course by clearing all tracking and courseData values
         */
        resetCourse() {
            this.tracking.clear();
            this.courseData.reset();
        },

        /**
         * Reset a particular module based on moduleNumber passed
         *
         * @param {number} moduleNumber
         */
        resetModule(moduleNumber) {
            this.storage.resetModuleCompletion(moduleNumber);
            this.courseData.resetModule(moduleNumber);
        },

        /**
         * Handle the event when course XML/JSON is loaded
         *
         * @param {object} e
         */
        onCourseLoaded(e) {
            this.listenToOnce(
                this.router.pages,
                'onLoadComplete',
                this.handleCourseLoaded
            );

            if (e.resource) {
                this.courseData.createCourseData(e.resource.getXML(), 'xml');
            } else {
                this.courseData.createCourseData(e, 'json');
            }

            /**
             * onCourseDataCreated is fired when course data is created
             *
             * @event Container#onCourseDataCreated
             * @type {object}
             * @property {Container} target
             * @property {CourseData} courseData
             */
            this.trigger('onCourseDataCreated', {
                target: this,
                courseData: this.courseData,
            });
        },

        /**
         * Start showing the first page once course is loaded
         */
        handleCourseLoaded() {
            this.removePreloader();
            this.lmsAdaptor.setup();

            /**
             * onLoadComplete is fired when course loading is completed
             *
             * @event Container#onLoadComplete
             * @type {object}
             * @property {Container} target
             */
            this.trigger('onLoadComplete', {
                target: this,
            });
            // Clear the current route

            if (!this.locked && this.startPage != 'coursemap') {
                this.router.routeToPage(this.startPage);
                return;
            }

            if (
                this.courseData.isThereIntroductionModule() &&
                this.isShowIntro
            ) {
                this.session.showIntoductionModule();
            } else {
                this.router.changePage(this.startPage);
            }
        },
    },
    {
        DEFAULT_SCROLLER: '#scroller',
        SCROLLER_CLASS: 'si-scroller',
    }
);

export default Container;
