import Backbone from 'backbone';
import _ from 'underscore';
import $ from 'jquery';
import AssetLoader from '../loader/assetloader.js';
import { Logger } from '../../index';

const LanguageManager = Backbone.Model.extend(
    {
        // No instance needed.
    },
    {
        data: undefined,

        initialize(url, container) {
            this.data = {};
            this.container = container;
            if (url.includes('.json')) {
                const self = this;
                $.getJSON(url, function (data) {
                    self.onJSONLoaded(data);
                });
            } else {
                this.container.preloader.addXML(
                    url,
                    null,
                    null,
                    this,
                    'onLoaded'
                );
            }
        },

        onJSONLoaded(e) {
            const self = this;
            e.items.forEach(function (item, index) {
                self.addString(item.key, item.value);
            });
        },

        onLoaded(e) {
            const $xml = $(e.resource.getXML());
            this.setupData($xml);
        },

        setupData($xml) {
            const self = this;

            $xml.find('item').each(function (index, item) {
                self.addString(
                    $(item).find('key').text(),
                    $(item).find('value').text()
                );
            });
        },

        addString(key, value) {
            if (this.data.hasOwnProperty(key)) {
                Logger.warn(
                    `LanguageManager: failed to add key [${key}], it already exists.`
                );
                return false;
            }

            this.data[key] = value;
        },

        getString(key, substituteParams) {
            if (!this.data.hasOwnProperty(key)) {
                Logger.warn(`LanguageManager: failed to get key [${key}]`);
                return '';
            }

            let string = this.data[key];
            // if(substituteParams)
            string = this.substitute(string, substituteParams);

            return string;
        },

        substitute(string, params) {
            _.each(params, function (value, key) {
                string = string.replace(`{{${key}}}`, value);
            });

            return string;
        },
    }
);

export default LanguageManager;
