import { useMemo } from 'react';
import { useDocument } from 'react-firebase-hooks/firestore';
import useRoomSync from './useRoomSync';
import { useDocumentReference } from '~m/Firebase';
import { t } from '~templates/utils/i18n';

function useAccount() {
    const roomSync = useRoomSync();

    /** @type {import('react-firebase-hooks/firestore').DocumentHook<import('../RoomSync.types').User>} */
    // @ts-expect-error types are correct
    const [roomMemberSnapshot] = useDocument(
        useDocumentReference(
            roomSync.userManager.accountDetailsPath,
        ),
    );

    return useMemo(() => {
        if (!roomMemberSnapshot) {
            return [
                { },
                { signedIn: false },
            ];
        }

        const data = roomMemberSnapshot.data();
        const { firstName, lastName } = data ?? {};

        let displayName = [firstName, lastName].filter(Boolean).join(' ');

        if (!displayName && roomSync.isInstructor) {
            displayName = t('instructor');
        }

        return [
            { ...data, displayName },
            { signedIn: true },
        ];
    }, [roomMemberSnapshot]);
}

export default useAccount;
