import { intlFormat } from 'date-fns';
import { customAlphabet } from 'nanoid';

export const compareString = key => (a, b) =>
    a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0;

export const compare = key => (a, b) => a[key] - b[key];

export function getContentSize(element) {
    const { clientHeight, clientWidth } = element;
    const { paddingTop, paddingBottom, paddingLeft, paddingRight } = getComputedStyle(element);

    return [
        clientWidth - parseFloat(paddingTop) - parseFloat(paddingBottom),
        clientHeight - parseFloat(paddingLeft) - parseFloat(paddingRight),
    ];
}

const nanoidWithSafeAlphabet = customAlphabet('6789BCDFGHJKLMNPQRTWbcdfghjkmnpqrtwz', 10);
export const safeId = () => nanoidWithSafeAlphabet();

export const formatDateTime = value => intlFormat(value, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
});

export function joinStrings(delimiter, ...values) {
    return values.flat(Infinity).filter(Boolean).join(delimiter);
}

export function injectStylesIntoSvg(objectElement, styles) {
    const svgElement = objectElement.contentDocument.querySelector('svg');
    const style = document.createElement('style');

    svgElement.appendChild(style);

    style.setAttribute('type', 'text/css');
    const textNode = document.createTextNode(styles);
    style.appendChild(textNode);
}

export function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}
