import PropTypes from 'prop-types';

export default {
    active: PropTypes.bool.isRequired,
    slide: PropTypes.any.isRequired,
    onCompleted: PropTypes.func.isRequired,
    onTimeUpdate: PropTypes.func.isRequired,
    onLoadingChange: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
};
