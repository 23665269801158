import { useEffect, useMemo, useState } from 'react';
import { useBlock } from '~templates/components/blocks';

export function useCarouselData(options = {}) {
    const { defaultIndex = 0 } = options;

    const { data, block } = useBlock();

    const [activeIndex, setActiveIndex] = useState(defaultIndex);
    const [viewedSlides, setViewedSlides] = useState([defaultIndex]);
    const viewedAllSlides = useMemo(() => data.items.length === viewedSlides.length, [data.items, viewedSlides.length]);
    const firstIndex = useMemo(() => activeIndex === 0, [activeIndex]);
    const lastIndex = useMemo(() => activeIndex === data.items.length - 1, [activeIndex, data.items]);

    const progressPoints = useMemo(() => {
        return data.items.map((value, index) => ({
            active: index === activeIndex,
            viewed: viewedSlides.includes(index),
        }));
    }, [data.items, activeIndex, viewedSlides]);

    const changeIndex = (index) => {
        setActiveIndex(index);

        if (!viewedSlides.includes(index)) {
            setViewedSlides([...viewedSlides, index]);
        }
    };

    const nextSlide = () => changeIndex((activeIndex + 1) % data.items.length);
    const prevSlide = () => changeIndex((activeIndex > 0 ? activeIndex : data.items.length) - 1);

    useEffect(() => {
        if (!viewedAllSlides) return;
        block.complete();
    }, [viewedAllSlides]);

    return {
        activeIndex,
        changeIndex,
        viewedSlides,
        nextSlide,
        prevSlide,
        viewedAllSlides,
        firstIndex,
        lastIndex,
        progressPoints,
    };
}
