import React from 'react';
import PropTypes from 'prop-types';
import parseHtml from 'react-html-parser';
import styles from './ScenarioCoverSlide.module.css';

function ScenarioCoverSlide(props) {
    const { item } = props;

    return (
        <div className={styles.wrapper}>
            <div className={styles.noteWrapper}>
                <img src="assets/images/barrickgold/barrickgold_wide.png" alt="" />
                <div className={styles.note}>
                    {parseHtml(item.content)}
                </div>
            </div>
        </div>
    );
}

ScenarioCoverSlide.propTypes = {
    property: PropTypes.string,
};

ScenarioCoverSlide.defaultProps = {
    property: undefined,
};

export default ScenarioCoverSlide;
