import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { MdLockOpen } from 'react-icons/md';
import classNames from 'classnames';
import ReactHtmlParser from 'react-html-parser';
import styles from './UnitCard.module.css';
import { t } from '~templates/utils/i18n';

/**
 * @type {import('react').ForwardRefExoticComponent<import('../CourseMap.types').UnitCardProps>}
 */
const UnitCard = forwardRef((props, ref) => {
    const { unit, disabled } = props;

    const handleClick = () => {
        if (disabled) return;
        unit.actions.enter();
    };

    return (
        <a
            ref={ref}
            className={classNames(styles.wrapper, {
                [styles.disabled]: disabled,
            })}
            onClick={handleClick}
        >
            <div className={styles.content}>
                <span className={styles.unitNumber}>{t('unit_number').replace('{}', unit.number)}</span>
                <h2>{ReactHtmlParser(unit.title)}</h2>
            </div>
            <div className={styles.progressWrapper}>
                <span>{unit.status.label}</span>
                <div className={styles.progressWrapperInner}>
                    <div className={styles.progress}>
                        <MdLockOpen />
                    </div>
                </div>
            </div>
        </a>
    );
});

UnitCard.displayName = 'UnitCard';

UnitCard.propTypes = {
    unit: PropTypes.any.isRequired,
    disabled: PropTypes.bool,
};

UnitCard.defaultProps = {
    disabled: undefined,
};

export default UnitCard;
