import $ from 'jquery';
import { PxLoader } from './PxLoader';

function PxLoaderJSON(url, tags, priority) {
    const self = this;
    let loader = null;

    this.tags = tags;
    this.priority = priority;
    this.url = url;
    this.json = undefined;

    this.start = function (pxLoader) {
        loader = pxLoader;

        $.ajax({
            url,
            dataType: 'json',
            // cache:(container != undefined) ? container.isCached : false,
            success(data) {
                self.json = data;

                loader.onLoad(self);
            },
            error() {
                loader.onError(self);
            },
            timeout() {
                loader.onTimeout(self);
            },
        });
    };

    this.parseText = function () {};

    this.checkStatus = function () {};

    this.onTimeout = function () {};

    this.getName = function () {
        return url;
    };

    this.getJSON = function () {
        return self.json;
    };
}

PxLoader.prototype.addJSON = function (url, tags, priority) {
    const xmlLoader = new PxLoaderJSON(url, tags, priority);
    this.add(xmlLoader);

    return xmlLoader;
};

export { PxLoaderJSON };
