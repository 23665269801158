import Backbone from 'backbone';
import $ from 'jquery';
import _ from 'underscore';
import { LanguageManager, Logger } from '../../index';

var ContentReplacer = Backbone.Model.extend(
    {
        // No instance needed.
    },
    {
        container: undefined,
        data: {},
        helpers: {},
        isReady: false,
        pattern: /\[\[([a-zA-Z0-9_\/-]+)]]/g,

        initialize(url, container) {
            this.container = container;
            if (url.includes('.json')) {
                const self = this;
                $.getJSON(url, function (data) {
                    self.onJSONLoaded(data);
                });
            } else {
                this.container.preloader.addXML(
                    url,
                    null,
                    null,
                    this,
                    'onLoaded'
                );
            }
        },

        onJSONLoaded(e) {
            const self = this;
            e.items.forEach(function (item, index) {
                self.addItem(item.key, item.value);
            });
            this.isReady = true;
        },

        onLoaded(e) {
            const $xml = $(e.resource.getXML());

            this.setupData($xml);
            this.isReady = true;
        },

        setupData($xml) {
            $xml.find('item').each(
                function (index, item) {
                    const value = $(item).find('value').text();
                    this.addItem($(item).find('key').text(), value); // unescape HTML
                }.bind(this)
            );
        },

        addItem(key, value) {
            if (key in this.data) {
                Logger.warn(
                    `ContentReplacer: failed to add key [${key}], it already exists.`
                );
                return false;
            }

            this.data[key] = value;
        },

        execute(content) {
            if (!this.isReady) {
                Logger.warn(
                    "ContentReplacer: couldn't parse content, replacer not ready.",
                    content
                );
                return content;
            }

            content = this.replaceLinks(content);

            const matches = [];
            let result;
            const self = this;
            while ((result = this.pattern.exec(content)) != null) {
                if (result[1] in this.data && !matches.includes(result[1])) {
                    matches.push(result[1]);
                }
            }
            _.each(matches, function (match) {
                let value = self.data[match];
                if (value in ContentReplacer.helpers) {
                    value = ContentReplacer.helpers[value].call(self); // run helper function if available
                }

                content = content.replace(
                    new RegExp(`\\[\\[${match}]]`, 'g'),
                    _.unescape(value)
                );
            });
            return content;
        },

        replaceLinks(data) {
            const previousLinks = [];
            // GG:23/07/2021: This will find all extention files from assets/pdf folder.
            const assetLinks = data.match(
                /(\b(assets)\/\b(pdf)\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi
            );
            var httplinks = data.match(
                /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.; ]*[-A-Z0-9+&@#\/%=~_\s|])/gi
            );
            if (assetLinks !== null) {
                var httplinks = assetLinks.concat(httplinks);
            }

            if (httplinks == null) return data;
            httplinks.map((link) => {
                if (previousLinks.includes(link) == false) {
                    data = data.replaceAll(
                        link,
                        `javascript:WindowHelper.openLink('${link}')`
                    );
                }
                previousLinks.push(link);
            });

            return data;
        },
    }
);

ContentReplacer.helpers.studentName = function () {
    let learnerName = this.container.tracking.getCmiData('STUDENT_NAME');
    if (learnerName === undefined) {
        learnerName = LanguageManager.getString('learner_name');
    } else {
        learnerName = learnerName.split(',')[1];
    }
    return learnerName;
};

window.ContentReplacer = ContentReplacer;
export { ContentReplacer };
