const eventName = () => {
    let hidden, visibilityChange;
    if (typeof document.hidden !== 'undefined') {
        // Opera 12.10 and Firefox 18 and later support
        hidden = 'hidden';
        visibilityChange = 'visibilitychange';
    } else if (typeof document.msHidden !== 'undefined') {
        hidden = 'msHidden';
        visibilityChange = 'msvisibilitychange';
    } else if (typeof document.webkitHidden !== 'undefined') {
        hidden = 'webkitHidden';
        visibilityChange = 'webkitvisibilitychange';
    }
    return { hidden, visibilityChange };
};

/**
 * Wrapper for the Page Visibility API to be used on blocks
 */
class BlockVisibility {
    constructor(context) {
        this.exitFullscreen = true;
        this.context = context;

        const { hidden, visibilityChange } = eventName();

        document.addEventListener(
            visibilityChange,
            () => {
                this.context?.block?.trigger('onPageVisibilityChange', {
                    hidden: document[hidden],
                });
            },
            false
        );
    }
}

export default BlockVisibility;
