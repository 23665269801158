import ScreenRenderer from '../../render/screenrenderer.js';

const JunctionRenderer = ScreenRenderer.extend({
    constructor() {
        this.data;

        ScreenRenderer.apply(this, arguments);
    },

    initialize(container, session, $wrapper, data) {
        this.data = data;

        ScreenRenderer.prototype.initialize.call(
            this,
            container,
            session,
            $wrapper
        );
    },

    suspendPage(pageData) {
        if (!pageData) return;

        this.suspendedPageData = pageData;

        this.trigger('onPageSuspended', {
            target: this,
            pageData,
        });
    },

    restorePage() {
        if (!this.isSuspended()) return;

        this.suspendedPageData = null;

        this.trigger('onPageRestored', {
            target: this,
            pageData: this.currentPageData,
        });
    },

    createTemplate(blockData, pageData, xml) {
        let template = window[blockData.template()];

        if (!template) {
            template = window.Placeholder;
        }

        // console.info('JunctionRenderer.createTemplate', template);
        return new template({
            id: `block-${blockData.blockID()}`,
            app: this.container,
            data: blockData,
            page: pageData,
            xml,
            junctionData: this.data,
            junctionController: this.session,
        });
    },
});

export default JunctionRenderer;
