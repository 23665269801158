import Status from '../../data/status.js';
import JunctionRouter from '../../junction/router/junctionrouter';
import Component from '../../component/component.js';
import { DataItem, StorageData } from './storagedata.js';

const Storage = Component.extend({
    data: undefined,

    initialize() {
        this.name = 'Storage';
    },

    setup(container) {
        this.container = container;
        this.container.storage = this;

        this.load();

        this.listenTo(container, 'onDestroy', this.save);
        this.listenTo(container.tracking, 'onBeforeSave', this.save);
        this.listenTo(container.tracking, 'onClear', this.reset);
        this.listenTo(
            container.session,
            'onChangeSlide',
            this.handleChangeSlide
        );
    },

    setItem(key, value, isGlobal) {
        this.data.add(
            {
                key,
                value,
                reference: isGlobal
                    ? null
                    : this.container.router.getCurrentPageId(),
            },
            { merge: true }
        );
        this.save();
    },

    getItem(key) {
        const item = this.data.get(key);
        if (item === undefined) return -1;

        return item.getValue();
    },

    deleteItem(key) {
        this.data.remove(key);
    },

    deleteItemByReference(reference) {
        const items = this.data.where({ reference });

        for (let i = 0; i < items.length; i++) {
            this.deleteItem(items[i]);
        }
    },

    clearProgress(reference, to) {
        const targetPage = this.container.courseData.getPageById(reference);
        if (!targetPage || targetPage.moduleNumber() == null) return false;

        const toPage = this.container.courseData.getPageById(to);

        const pages = this.container.courseData.pages;
        const fromIndex = targetPage.id;
        const toIndex = toPage ? toPage.id : pages.length - 1;

        for (let i = fromIndex; i <= toIndex; i++) {
            const page = pages.get(i);
            if (page.moduleNumber() == null) continue;

            page.resetCompletion();

            this.resetPageCompletion(page.pageID());
            this.deleteItemByReference(page.pageID());
        }

        this.resetModule(targetPage.moduleNumber());
        this.updateBookmark(pages, fromIndex - 1);

        this.trigger('onClearProgress', {
            data: this.data,
            reference,
        });
    },

    updateBookmark(pages, index) {
        let bookmark = JunctionRouter.COURSEMAP_ID;

        for (let i = index; i >= 0; i--) {
            const page = pages.get(i);
            if (!page.moduleNumber()) continue;

            bookmark = page.pageID();
            break;
        }
        this.setBookmark(bookmark);
    },

    getBookmark() {
        return this.getItem('bookmark');
    },

    setBookmark(bookmark) {
        this.container.tracking.setCmiData('LESSON_LOCATION', bookmark);
        const result = this.setItem('bookmark', bookmark, true);
        this.save();
        this.container.tracking.save();
        return result;
    },

    resetPageCompletion(reference) {
        this.container.tracking.deleteItem(reference);
        this.trigger('onResetPageCompletion', { pageId: reference });

        // Junction specific, can extend and move into a new class.
        const pages = this.container.router.getPages();
        const subpages = pages.getJunctionPages(reference);

        for (let i = 0; i < subpages.length; i++) {
            const subpageId = subpages[i].getId();
            this.container.tracking.deleteItem(subpageId);
            this.trigger('onResetPageCompletion', { pageId: subpageId });
        }
    },

    resetModuleCompletion(moduleNumber) {
        const courseData = this.container.courseData;
        const module = courseData.modules.at(moduleNumber);
        if (!module) return;

        for (let i = 0; i < module.getTotalPages(); i++) {
            const page = module.getPage(i);
            this.resetPageCompletion(page.pageID());
        }
    },

    resetModule(moduleNumber) {
        const courseData = this.container.courseData;

        courseData.modules
            .at(moduleNumber)
            .setModuleStatus(Status.AVAILABLE, true);
        for (let i = moduleNumber + 1; i < courseData.modules.length; i++) {
            courseData.modules.at(i).setModuleStatus(Status.UNAVAILABLE, true);
        }
    },

    save() {
        if (!this.data || !this.data.length) return false;

        this.container.tracking.setItem(this.name, JSON.stringify(this.data));
    },

    load() {
        const data = JSON.parse(this.container.tracking.getItem(this.name));

        if (data && data !== -1) {
            this.data = new StorageData(data);
        } else {
            this.data = new StorageData();
        }

        this.trigger('onLoadComplete', { target: this });
    },

    handleChangeSlide(e) {
        const pageId = e.pageData.pageID();
        const page = this.container.courseData.getPageById(pageId);
        const isInModule = page.moduleNumber() != null;

        if (
            page &&
            isInModule &&
            pageId != JunctionRouter.COURSEMAP_ID &&
            !this.container.bookmarkJunctions
        ) {
            this.setBookmark(pageId);
        }
    },

    reset() {
        this.data = new StorageData();
    },
});

export default Storage;
