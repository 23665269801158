import React, { useEffect, useRef, useState } from 'react';
import Flicking from '@egjs/react-flicking';
import PropTypes from 'prop-types';
import { useCourseMapData } from './courseMapHooks';
import CourseMapCard from './CourseMapCard';
import BuildAnimation from '~components/animations/BuildAnimation';
import { Status } from '~core';
import './CourseMapCards.scss';

/**
 * @param value
 * @param min
 * @param max
 */
function clamp(value, min, max) {
    return Math.min(Math.max(value, min), max);
}

/**
 * @param value
 * @param root0
 * @param root0.0
 * @param root0.1
 * @param root1
 * @param root1.0
 * @param root1.1
 */
function convertRange(value, [srcMin, srcMax], [tgtMin, tgtMax]) {
    const clamped = clamp(Math.abs(value), srcMin, srcMax);
    const valReduced = clamped - srcMin;
    const tgtRange = tgtMax - tgtMin;
    const srcRange = srcMax - srcMin;
    return (valReduced * tgtRange) / srcRange + tgtMin;
}

/**
 * @param panels
 */
function transformPanels(panels) {
    panels.forEach((panel) => {
        const scale = 1 - convertRange(panel.progress, [0, 1], [0, 0.1]);
        panel.element.style.setProperty('transform', `scale(${scale})`);
    });
}

/**
 * @param props
 */
function CourseMapCards(props) {
    const { blockRef } = props;
    const container = useRef(null);
    const flicking = useRef(null);
    const scroller = useRef(null);
    const [moduleToFocus, setModuleToFocus] = useState(0);
    const [ready, setReady] = useState(false);
    const [range, setRange] = useState(null);
    const [flickingDisable, setFlickingDisable] = useState(false);

    useEffect(() => {
        if (!container.current) {
            return;
        }
        const staggerElements = container.current.querySelectorAll('.card');
        BuildAnimation.animateIn(staggerElements);
    }, [ready]);

    const modules = useCourseMapData(blockRef);

    useEffect(() => {
        if (!ready) {
            return;
        }

        const firstIncompleteModuleIndex = modules.findIndex((module) => {
            return [Status.AVAILABLE, Status.STARTED].includes(
                module.statusCode
            );
        });

        const targetIndex =
            firstIncompleteModuleIndex === -1 ? 0 : firstIncompleteModuleIndex;

        setModuleToFocus(targetIndex);
        flicking.current.moveTo(targetIndex);
        setRange(flicking.current.camera.range);
    }, [ready]);

    const setScrollerPos = (pos, force = false) => {
        if (!force && flickingDisable) {
            return;
        }
        const { min, max } = flicking.current.camera.range;
        const percentRearrange = (pos - min) / (max - min);
        const { clientWidth, scrollWidth } = scroller.current;
        const scrollLeftRearrange =
            percentRearrange * (scrollWidth - clientWidth);
        scroller.current.scrollTo(scrollLeftRearrange, 0);
    };

    const moveTo = (position) => {
        flicking.current.camera.lookAt(position);
        transformPanels(flicking.current.panels);
    };

    return (
        <div className="course-map-cards" ref={container}>
            {modules.length && (
                <Flicking
                    ref={flicking}
                    defaultIndex={moduleToFocus}
                    onReady={(e) => {
                        setReady(true);
                        transformPanels(e.currentTarget.panels);
                    }}
                    align="center"
                    moveType="snap"
                    onMove={(e) => {
                        // The onMove event is called when dragging the
                        // cards *and* when dragging the scroll bar

                        moveTo(e.currentTarget.camera.position);

                        // Only dragging the cards is trusted, so this prop can be
                        // used to decide when to update the scroll bar position
                        if (e.isTrusted) {
                            setScrollerPos(e.currentTarget.camera.position);
                        }
                    }}
                >
                    {modules.map((module, index) => (
                        <CourseMapCard
                            key={index.toString()}
                            index={index}
                            blockRef={blockRef}
                            module={{
                                ...module,
                                ...blockRef?.data.items.find(
                                    (i) => i.unitId === module.moduleID
                                ),
                            }}
                        />
                    ))}
                </Flicking>
            )}
            {range && (
                <div
                    className="course-map-cards-scroller"
                    ref={scroller}
                    onMouseDown={() => {
                        setFlickingDisable(true);
                    }}
                    onMouseUp={async () => {
                        setFlickingDisable(false);

                        // Snap to nearest item
                        const pos = flicking.current.camera.position;
                        const nearestAnchor =
                            flicking.current.camera.findNearestAnchor(pos);
                        await flicking.current.control.moveToPosition(pos);
                        setScrollerPos(nearestAnchor.position, true);
                        moveTo(nearestAnchor.position);
                        flicking.current.control.moveToPosition(
                            nearestAnchor.position,
                            200
                        );
                    }}
                    onScroll={(event) => {
                        // TODO: Fix the scroll bar snap back
                        //
                        // If you scroll to a point on the scrollbar too
                        // quickly, the card position will not match.
                        // moveToPosition below is an async function which may
                        // be the cause of it. When scrolling, if the point at
                        // which you let go of the bar is before you have
                        // reached the card the scroll bar lines up with, a
                        // previous call to moveToPosition could still be
                        // processing which will be blocking the final call
                        // made when releasing the scroll bar
                        //
                        // The lower the duration (currently 100ms) the less
                        // apparent the issue.

                        if (!flickingDisable) {
                            return;
                        }

                        const { min, max } = flicking.current.camera.range;
                        const { clientWidth, scrollLeft, scrollWidth } =
                            event.currentTarget;
                        const percent =
                            scrollLeft / (scrollWidth - clientWidth);
                        const targetValue = (max - min) * percent + min;
                        moveTo(targetValue);
                    }}
                >
                    <div />
                </div>
            )}
        </div>
    );
}

CourseMapCards.propTypes = {
    blockRef: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default CourseMapCards;
