import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import './FeedbackModal.scss';

function FeedbackModal(props) {
    const {
        show,
        onHide,
        feedback: { title, content },
    } = props;

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="feedback-modal"
            centered
        >
            <div className="content-wrapper">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>{ReactHtmlParser(content)}</Modal.Body>
            </div>
        </Modal>
    );
}

FeedbackModal.propTypes = {
    show: PropTypes.string.isRequired,
    onHide: PropTypes.string.isRequired,
    feedback: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default FeedbackModal;
