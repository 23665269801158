/**
 * useFollow
 *
 * DOM Interaction hook for floating-ui. Follows the cursor when moving over the reference element.
 *
 * @param {import('@floating-ui/react-dom-interactions').FloatingContext} context
 */
function useFollow(context) {
    const { reference } = context;
    return {
        reference: {
            onMouseMove({ clientX, clientY }) {
                reference({
                    getClientRects() {
                        return {
                            width: 0,
                            height: 0,
                            x: clientX,
                            y: clientY,
                            left: clientX,
                            right: clientX,
                            top: clientY,
                            bottom: clientY,
                        };
                    },
                    getBoundingClientRect() {
                        return {
                            width: 0,
                            height: 0,
                            x: clientX,
                            y: clientY,
                            left: clientX,
                            right: clientX,
                            top: clientY,
                            bottom: clientY,
                        };
                    },
                });
            },
        },
    };
}

export default useFollow;
