import React, { Component } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { MdArrowBack } from 'react-icons/md';
import AdminBase from './AdminBase';

class AdminNavBar extends AdminBase {
    render() {
        return (
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                <Navbar.Brand href="#return-to-course">
                    <MdArrowBack className="navbar-icon" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Link
                            onClick={() => {
                                this.navigateToOverview();
                            }}
                        >
                            Overview
                        </Nav.Link>
                        <Nav.Link
                            onClick={() => {
                                this.navigateToModules();
                            }}
                        >
                            Modules
                        </Nav.Link>
                        <Nav.Link
                            onClick={() => {
                                this.navigateToPages();
                            }}
                        >
                            Pages
                        </Nav.Link>
                        <Nav.Link
                            onClick={() => {
                                this.navigateToTemplates();
                            }}
                        >
                            Templates
                        </Nav.Link>
                        <Nav.Link
                            onClick={() => {
                                this.navigateToImageReview();
                            }}
                        >
                            Image Review
                        </Nav.Link>
                        <Nav.Link
                            onClick={() => {
                                this.navigateToSearchAssets();
                            }}
                        >
                            Search Assets
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}

export default AdminNavBar;
