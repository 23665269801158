import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Dropdown from 'react-bootstrap/Dropdown';
import { CircularProgressbar } from 'react-circular-progressbar';
import {
    MdChevronLeft,
    MdChevronRight,
    MdFolderOpen,
    MdHelpOutline,
    MdHome,
    MdMenu,
    MdVolumeMute,
    MdVolumeUp,
} from 'react-icons/md';
import Block from '~blocks/base/Block';
import './Navigation.scss';
import { Container } from 'react-bootstrap';

/**
 * @extends Block
 * @memberOf ReactBlocks
 */
class Navigation extends Block {
    /**
     * @param props
     */
    constructor(props) {
        super(props);
        this.session = container.session;

        this.labels = {
            home: LanguageManager.getString('home'),
            previous: LanguageManager.getString('previous'),
            next: LanguageManager.getString('next'),
            progress: LanguageManager.getString('progress'),
            menu: LanguageManager.getString('menu'),
            help: LanguageManager.getString('help'),
            resources: LanguageManager.getString('resources'),
            audio: LanguageManager.getString('audio'),
            audioOn: LanguageManager.getString('audio_on'),
            audioOff: LanguageManager.getString('audio_off'),
            audioOnAlert: LanguageManager.getString('audio_on_alert'),
            audioOffAlert: LanguageManager.getString('audio_off_alert'),
        };

        this.state = {
            percentage: 0,
            hideCenterNav: false,
            isNextDisabled: false,
            isAudioOn: container.audioManager.isAudioOn,
        };
    }

    /**
     * @returns {ReactNode}
     */
    render() {
        return (
            <Container>
                <Navbar
                    fixed="top"
                    bg="custom"
                    className="d-flex justify-content-between navigation-wrapper"
                >
                    <Nav className="nav-left">
                        <Nav.Link
                            active={false}
                            aria-label={this.labels.home}
                            title={this.labels.home}
                            href="#page/coursemap"
                            role="button"
                        >
                            <MdHome className="icon home" />
                        </Nav.Link>
                    </Nav>

                    {!this.state.hideCenterNav && (
                        <Nav as="ul" className="nav-center align-items-center">
                            <Nav.Item
                                as="li"
                                ref={(li) => (this.previousButtonLink = li)}
                            >
                                <Nav.Link
                                    active={false}
                                    aria-label={this.labels.previous}
                                    title={this.labels.previous}
                                    className="previous d-flex align-items-center justify-content-center"
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        container.router.previous();
                                    }}
                                    role="button"
                                >
                                    <MdChevronLeft className="icon left" />
                                </Nav.Link>
                            </Nav.Item>

                            <Nav.Item
                                as="li"
                                role="text"
                                aria-label={`${this.labels.progress}: ${this.state.percentage}%`}
                                title={`${this.labels.progress}: ${this.state.percentage}%`}
                                className="progress-wrapper mx-2"
                            >
                                <CircularProgressbar
                                    value={this.state.percentage}
                                />
                            </Nav.Item>

                            <Nav.Item
                                as="li"
                                ref={(li) => (this.nextButtonLink = li)}
                            >
                                <Nav.Link
                                    active={false}
                                    aria-label={this.labels.next}
                                    title={this.labels.next}
                                    role="button"
                                    className="next d-flex align-items-center justify-content-center"
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        container.router.next();
                                    }}
                                    disabled={this.state.isNextDisabled}
                                >
                                    <MdChevronRight className="icon right" />
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    )}

                    <Nav className="nav-right">
                        <Dropdown>
                            <Dropdown.Toggle
                                as="a"
                                href="#!"
                                aria-label={this.labels.menu}
                                title={this.labels.menu}
                                role="button"
                                className="nav-link d-flex align-items-center justify-content-start"
                                id="dropdown-basic"
                            >
                                <MdMenu className="icon menu" />
                            </Dropdown.Toggle>

                            <Dropdown.Menu alignRight>
                                <Dropdown.Item
                                    active={false}
                                    aria-label={`${
                                        this.state.isAudioOn
                                            ? this.labels.audioOn
                                            : this.labels.audioOff
                                    }`}
                                    title={`${
                                        this.state.isAudioOn
                                            ? this.labels.audioOn
                                            : this.labels.audioOff
                                    }`}
                                    href="#audio/toggle"
                                    role="button"
                                    className="nav-link d-flex align-items-center justify-content-start"
                                >
                                    <span className="icon">
                                        {this.state.isAudioOn ? (
                                            <MdVolumeUp className="icon volume-on" />
                                        ) : (
                                            <MdVolumeMute className="icon volumne-off" />
                                        )}
                                    </span>
                                    <span className="label">
                                        {this.labels.audio}
                                    </span>
                                </Dropdown.Item>
                                <Dropdown.Item
                                    active={false}
                                    aria-label={this.labels.resources}
                                    title={this.labels.resources}
                                    href="#global/resources"
                                    className="nav-link d-flex align-items-center justify-content-start"
                                >
                                    <span className="icon">
                                        <MdFolderOpen className="icon folder" />
                                    </span>
                                    <span className="label">
                                        {this.labels.resources}
                                    </span>
                                </Dropdown.Item>
                                <Dropdown.Item
                                    active={false}
                                    aria-label={this.labels.help}
                                    title={this.labels.help}
                                    href="#global/help"
                                    className="nav-link d-flex align-items-center justify-content-start"
                                >
                                    <span>
                                        <MdHelpOutline className="icon" />
                                    </span>
                                    <span className="label">
                                        {this.labels.help}
                                    </span>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <span
                            className="audio-toggle-alert sr-only"
                            role="alert"
                        ></span>
                    </Nav>
                </Navbar>
            </Container>
        );
    }

    /**
     *  Calling all listen events on component mount.
     */
    componentDidMount() {
        this.$el = this.props.blockRef.$el;
        this.addListeners();
    }

    /**
     * Listening to all events.
     */
    addListeners() {
        Backbone.listenTo(
            this.session,
            'onChangeSlide onChangeGlobalSlide',
            this.handleChangeSlide.bind(this)
        );
        Backbone.listenTo(
            this.session,
            'onPageStatusUpdate',
            this.handlePageStatusUpdate.bind(this)
        );

        Backbone.listenTo(this.session, 'onNext', this.handleNext.bind(this));

        Backbone.listenTo(
            this.session,
            'onPrevious',
            this.handlePrevious.bind(this)
        );
        Backbone.listenTo(
            this.session,
            'onToggleAudio',
            this.handleToggleAudio.bind(this)
        );
    }

    /**
     * Set isAudioOn state on toggle
     */
    handleToggleAudio(e) {
        this.setState(
            { isAudioOn: container.audioManager.isAudioOn },
            this.alertAudioToggle
        );
    }

    /**
     * Show alert message when audio is toggle.
     */
    alertAudioToggle() {
        const $audioAlert = this.$el.find('.audio-toggle-alert');
        if (this.state.isAudioOn) {
            $audioAlert.text(this.labels.audioOnAlert);
        } else {
            $audioAlert.text(this.labels.audioOffAlert);
        }
    }

    /**
     * Routing to Next page.
     */
    handleNext(e) {
        this.routeToCoursemap(e);
    }

    /**
     * Show alert message when audio is toggle.
     */
    handlePrevious(e) {
        this.routeToCoursemap(e);
    }

    /**
     * Routing to Previous page.
     */
    routeToCoursemap(e) {
        if (e.executed) return false;
        this.session.goRouteSlide('coursemap');
    }

    /**
     * Set updated percentage state on slide / page change
     * Calling toggleNavigationItems.
     */
    handleChangeSlide(e) {
        const percentage = this.getPercentage(e.pageData);
        this.setState({ percentage });
        this.toggleNavigationItems(e.pageData);
    }

    /**
     * @param page
     * Set hideCenterNav state
     */
    toggleNavigationItems(page) {
        if (!page.moduleID()) {
            this.setState({ hideCenterNav: true });
        } else {
            this.setState({ hideCenterNav: false });
        }
    }

    /**
     * @param currentPage
     * @returns {Number}
     * Calculating completion percentage and return there value
     */
    getPercentage(currentPage) {
        if (currentPage === null || !currentPage.moduleID()) return;
        const module = container.courseData.getModule(
            currentPage.moduleNumber()
        );
        if (module !== undefined) {
            const totalPages = module.getTotalPages();
            const progress = this.session.currentPageData.pageNumber() + 1;
            const percentage = Math.round((progress * 100) / totalPages);
            return percentage;
        }
    }

    /**
     * Set state isNextDisabled according to current page status.
     * Enable next button if current page status is completed.
     */
    handlePageStatusUpdate(e) {
        if (e.pageData.getStatus() >= Status.COMPLETED) {
            this.setState({ isNextDisabled: false });
        } else if (container.isLocked) {
            this.setState({ isNextDisabled: true });
        }
    }
}

export default Navigation;
