import Backbone from 'backbone';
import _ from 'underscore';
import Status from '../data/status.js';

/**
 * @class This holds the data of the current session of the course.
 * @name Session
 *
 * @property {Container} container
 * @property {PageData} currentPageData
 * @property {PageData} previousPageData
 *
 * @augments Backbone.Model
 *
 * @see Backbone.Model
 */
const Session = Backbone.Model.extend(
    /** @lends Session.prototype */ {
        constructor() {
            this.container = null;
            this.currentPageData = undefined;
            this.previousPageData = undefined;

            Backbone.Model.apply(this, arguments);
        },

        /**
         * Initialize the current session of the course
         */
        initialize(container) {
            this.container = container;
            this.currentPageData = null;
            this.previousPageData = null;

            this.listenTo(this, 'onBeforeChangeSlide', function (e) {
                this.checkPageCompletion();
            });

            this.listenTo(this, 'onChangeSlide', function (e) {
                this.checkPageCompletion();
            });

            this.listenTo(this, 'onBlockCompleted', function (e) {
                this.checkPageCompletion();
            });

            this.listenTo(this, 'onSlideCompleted', function (e) {
                this.checkPageCompletion();
            });
            this.listenTo(this, 'onCheckPageCompletion', function (e) {
                this.pageStatusUpdate();
            });
            this.listenTo(this, 'onModuleStarted', function (e) {
                if (e.module < 0) return;

                this.container.courseData
                    .getModule(e.module)
                    .setModuleStatus(Status.STARTED);
                this.checkModuleCompletion();
            });
        },
        /**
         * Get the current module
         *
         * @returns {ModuleData}
         */
        getCurrentModule() {
            if (!this.currentPageData) return false;

            const moduleNumber = this.currentPageData.moduleNumber();
            return this.container.courseData.getModule(moduleNumber);
        },

        next() {
            // Exit out before anything goes wrong in IE
            if (!this.currentPageData) return;

            // Check whether the current page has been completed or not!
            if (this.cancelPageChange()) return;

            const self = this;
            let actionCompleted = false;
            const currentModule = this.currentPageData.moduleNumber();
            const currentPage = this.currentPageData.pageNumber();

            // Exit again before any problems in IE
            if (currentModule == null && currentPage == null) {
                // this.goRouteSlide("coursemap");
                return;
            }

            // check if this is introduction module
            if (currentModule == null && currentPage != null) {
                var nextPage = this.container.courseData
                    .getIntroductionModule()
                    .getPage(currentPage + 1);
            } else {
                var nextPage = this.container.courseData
                    .getModule(currentModule)
                    .getPage(currentPage + 1);
            }

            if (nextPage) {
                actionCompleted = true;
                this.goRouteSlide(nextPage);
            } else {
                actionCompleted = false;
            }

            this.trigger('onNext', {
                target: this,
                executed: actionCompleted,
                pageData: this.currentPageData,
                currentModule,
                currentPage,
            });
        },

        previous() {
            // Exit out before anything goes wrong in IE
            if (!this.currentPageData) return;

            const self = this;
            let actionCompleted = false;
            const currentModule = this.currentPageData.moduleNumber();
            const currentPage = this.currentPageData.pageNumber();

            // Exit again before any problems in IE
            if (currentModule == null && currentPage == null) return;

            // check if this is introduction module
            if (currentModule == null && currentPage != null) {
                var nextPage = this.container.courseData
                    .getIntroductionModule()
                    .getPage(currentPage - 1);
            } else {
                var nextPage = this.container.courseData
                    .getModule(currentModule)
                    .getPage(currentPage - 1);
            }

            if (nextPage) {
                actionCompleted = true;

                this.goRouteSlide(nextPage);
            } else {
                actionCompleted = false;
            }

            this.trigger('onPrevious', {
                target: this,
                executed: actionCompleted,
                pageData: this.currentPageData,
                currentModule,
                currentPage,
            });
        },

        cancelPageChange() {
            if (!this.container.isLocked) return false;

            // if(!this.currentPageData.isComplete())
            if (this.currentPageData.getStatus() < Status.COMPLETED) {
                this.container.router.navigate(
                    `/slide/${this.currentPageData.pageID()}`,
                    {
                        trigger: false,
                        replace: false,
                    }
                );

                // The current page has not been completed!
                return true;
            }

            return false;
        },

        showIntoductionModule() {
            this.startModule(-1);
        },

        startModule(moduleNumber) {
            const pageNumber = 0;
            let nextPage;
            if (moduleNumber == -1) {
                this.container.courseData.checkModuleCompletion(-1);
                const introModule =
                    this.container.courseData.getIntroductionModule();

                if (introModule.getModuleStatus() < Status.COMPLETED) {
                    nextPage = _.find(introModule.pages, function (page) {
                        return page.getStatus() < Status.COMPLETED;
                    });
                } else {
                    nextPage =
                        this.container.courseData.getPageById('coursemap');
                }
            } else {
                nextPage = this.container.courseData
                    .getModule(moduleNumber)
                    .getPage(pageNumber);
            }

            this.trigger('onModuleStarted', {
                target: this,
                module: moduleNumber,
            });

            this.goRouteSlide(nextPage);
        },

        completeCurrentSlide() {
            if (this.currentPageData == null) {
                return;
            }

            this.trigger('onSlideCompleted', {
                target: this,
                pageData: this.currentPageData,
            }); // Being deprecated
        },

        // NP: Not sure if this is required
        resetCurrentSlide() {
            // this.pageStatusUpdate();

            return;

            if (this.currentPageData == null) {
                return;
            }

            this.trigger('onSlideReset', {
                target: this,
                pageData: this.currentPageData,
            });
            // this.trigger("onPageStatusUpdate", event);
        },

        blockCompleted(event) {
            this.trigger('onBlockCompleted', event);
        },

        goChangeSlide(pageID) {
            if (this.currentPageData) {
                this.previousPageData = this.currentPageData;

                // this.previousPageData.unbind("onStatusUpdate", this.checkPageCompletion)
            }

            // Set the current page data
            this.currentPageData =
                this.container.courseData.getPageById(pageID);

            if (this.currentPageData == undefined) {
                this.currentPageData =
                    this.container.courseData.getPageById('coursemap');
                return;
            }

            // this.currentPageData.bind("onStatusUpdate", this.checkPageCompletion)

            let direction;

            if (
                this.currentPageData.pageNumber() &&
                this.previousPageData != null
            ) {
                direction =
                    this.currentPageData.moduleNumber() != null
                        ? this.currentPageData.pageNumber() -
                          this.previousPageData.pageNumber()
                        : undefined;
            } else {
                direction = -1;
            }

            this.trigger('onBeforeChangeSlide', {
                target: this,
                pageData: this.currentPageData,
                previousPageData: this.previousPageData,
                direction,
            });

            const event = new Object();
            event.target = this;
            event.pageData = this.currentPageData;
            event.previousPageData = this.previousPageData;
            event.pageBefore =
                this.currentPageData.moduleNumber() !== null
                    ? this.container.courseData
                          .getModule(this.currentPageData.moduleNumber())
                          .getPage(this.currentPageData.pageNumber() - 1)
                    : undefined;
            event.pageAfter =
                this.currentPageData.moduleNumber() !== null
                    ? this.container.courseData
                          .getModule(this.currentPageData.moduleNumber())
                          .getPage(this.currentPageData.pageNumber() + 1)
                    : undefined;
            event.percentComplete =
                this.currentPageData.moduleNumber() !== null
                    ? this.container.courseData.getTotalCompletedPagesByModule(
                          this.currentPageData.moduleNumber()
                      ).percentage
                    : undefined;

            if (
                this.currentPageData.pageNumber() != null &&
                this.previousPageData != null
            ) {
                event.direction =
                    this.currentPageData.moduleNumber() != null
                        ? this.currentPageData.pageNumber() -
                          this.previousPageData.pageNumber()
                        : undefined;
            } else {
                if (
                    this.previousPageData == undefined ||
                    this.previousPageData.moduleNumber() == null
                ) {
                    event.direction = 'none';
                } else if (this.previousPageData.pageNumber() == 0) {
                    event.direction = -1;
                } else if (
                    this.previousPageData.pageNumber() ==
                    this.container.courseData
                        .getModule(this.previousPageData.moduleNumber())
                        .getTotalPages() -
                        1
                ) {
                    event.direction = 1;
                } else {
                    event.direction = 'error';
                }
            }

            event.pageNumber = this.currentPageData.pageNumber();
            event.totalPages =
                this.currentPageData.moduleNumber() != null
                    ? this.container.courseData
                          .getModule(this.currentPageData.moduleNumber())
                          .getTotalPages(this.currentPageData.moduleNumber())
                    : undefined;

            if (
                this.currentPageData.pageNumber() != null &&
                this.previousPageData != null
            ) {
                event.direction =
                    this.currentPageData.moduleNumber() != null
                        ? this.currentPageData.pageNumber() -
                          this.previousPageData.pageNumber()
                        : undefined;
            } else {
                if (
                    this.previousPageData == undefined ||
                    this.previousPageData.moduleNumber() == null
                ) {
                    event.direction = 'none';
                } else if (this.previousPageData.pageNumber() == 0) {
                    event.direction = -1;
                } else if (
                    this.previousPageData.pageNumber() ==
                    this.container.courseData
                        .getModule(this.previousPageData.moduleNumber())
                        .getTotalPages() -
                        1
                ) {
                    event.direction = 1;
                } else {
                    event.direction = 'error';
                }
            }

            this.currentPageData.setStatus(Status.STARTED);

            event.pageNumber = this.currentPageData.pageNumber();
            event.totalPages =
                this.currentPageData.moduleNumber() != null
                    ? this.container.courseData
                          .getModule(this.currentPageData.moduleNumber())
                          .getTotalPages(this.currentPageData.moduleNumber())
                    : undefined;

            this.trigger('onChangeSlide', event);
        },

        goRouteSlide(page) {
            let pageID;

            if (_.isString(page)) {
                pageID = 'coursemap';
            } else {
                pageID = page.pageID();
            }

            this.container.router.navigate(`/slide/${pageID}`, {
                trigger: true,
                replace: true,
            });
        },

        goChangeGlobalSlide(pageID) {
            if (this.currentPageData) {
                this.previousPageData = this.currentPageData;
            }

            this.currentPageData =
                this.container.courseData.getPageById(pageID);

            const event = new Object();
            event.target = this;
            event.pageData = this.currentPageData;
            event.previousPageData = this.previousPageData;
            event.pageID = event.pageData.pageID();
            event.previousPageID = event.previousPageData.pageID();

            this.trigger('onBeforeChangeGlobalSlide', event);

            this.currentPageData.setStatus(Status.STARTED);

            this.trigger('onChangeGlobalSlide', event);
        },

        /**
         *
         */
        checkPageCompletion() {
            if (this.currentPageData == null) {
                return;
            }

            const self = this;
            const currentPage = this.currentPageData;
            const isComplete = currentPage.isComplete();

            currentPage.checkCompletion();

            if (currentPage.getStatus() >= 3) {
                // this.completeCurrentSlide();
                this.checkModuleCompletion();
            }

            this.trigger('onCheckPageCompletion', {
                target: this,
            });

            this.pageStatusUpdate();

            this.container.courseData.checkCourseCompletion();
        },

        pageStatusUpdate() {
            this.trigger('onPageStatusUpdate', {
                target: this,
                pageData: this.currentPageData,
                pageID: this.currentPageData.pageID(),
                status: this.currentPageData.getStatus(),
            });
        },

        checkModuleCompletion() {
            const progress =
                this.container.courseData.getTotalCompletedPagesByModule(
                    this.currentPageData.moduleNumber()
                ).percentage;

            if (progress >= 100) {
                this.trigger('onModuleCompleted', {
                    target: this,
                    moduleID: this.currentPageData.moduleID(),
                });
                // this.trigger("onModuleExit", event);
            }
        },

        courseReset() {
            this.trigger('onCourseReset', {
                target: this,
            });
        },

        /**
         * @Usage	This gets triggered when the all modules > pages > blocks have been completed
         */
        courseIncomplete() {
            this.trigger('onCourseIncomplete', {
                target: this,
                previousStatus:
                    this.container.tracking.getCmiData('LESSON_STATUS'),
                newStatus: 'incomplete',
            });
        },

        /**
         * @Usage	This gets triggered when the all modules > pages > blocks have been completed
         */
        courseCompleted() {
            this.trigger('onCourseCompleted', {
                target: this,
                previousStatus:
                    this.container.tracking.getCmiData('LESSON_STATUS'),
                newStatus: 'completed',
            });
        },

        setScore(score) {
            const passmark = this.container.courseData.passmark;
            if (passmark != undefined) {
                if (score > passmark) {
                    this.coursePassed();
                } else {
                    this.courseFailed();
                }
            }

            this.trigger('onSetScore', {
                target: this,
                score,
            });
        },

        /**
         * @Usage	Optionally, you can update the status to "passed" if required by the LMS to generate a certificate
         */
        coursePassed() {
            this.trigger('onCoursePassed', {
                target: this,
                previousStatus:
                    this.container.tracking.getCmiData('LESSON_STATUS'),
                newStatus: 'passed',
            });
        },

        /**
         * @Usage	Optionally, you can update the status to "failed" if required by the LMS to generate a certificate
         */
        courseFailed() {
            this.trigger('onCourseFailed', {
                target: this,
                previousStatus:
                    this.container.tracking.getCmiData('LESSON_STATUS'),
                newStatus: 'failed',
            });
        },

        toggleAudio(value) {
            this.trigger('onToggleAudio', {
                target: this,
                value,
            });
        },

        toggleFullscreen() {
            this.trigger('onToggleFullscreen', {
                target: this,
            });
        },

        trackInteraction(e) {
            // TODO:: Need to type check this against a specific format (based on QuestionModel)
            this.trigger('onTrackInteraction', e);
        },
    }
);

export default Session;
