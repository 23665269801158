import { useEffect, useState } from 'react';
import { Status } from '~core';

export function useNavigation(container) {
    const [percentage, setPercentage] = useState(0);
    const [hideCentreNav, setHideCentreNav] = useState(false);
    const [isNextDisabled, setIsNextDisabled] = useState(false);
    const [isAudioOn, setIsAudioOn] = useState(container.audioManager.isAudioOn);

    const getPercentage = (currentPage) => {
        if (currentPage === null || !currentPage.moduleID()) return;
        const module = container.courseData.getModule(
            currentPage.moduleNumber(),
        );
        if (module === undefined) {
            return 0;
        }

        const totalPages = module.getTotalPages();
        const progress = container.session.currentPageData.pageNumber() + 1;
        const percentage = Math.round((progress * 100) / totalPages);
        return percentage;
    };

    const toggleNavigationItems = (page) => {
        setHideCentreNav(!page.moduleID());
    };

    const handleChangeSlide = (e) => {
        setPercentage(getPercentage(e.pageData));
        toggleNavigationItems(e.pageData);
    };

    const handlePageStatusUpdate = (e) => {
        if (e.pageData.getStatus() >= Status.COMPLETED) {
            setIsNextDisabled(false);
        } else if (container.isLocked) {
            setIsNextDisabled(true);
        }
    };

    const routeToCoursemap = (e) => {
        if (e.executed) return false;
        container.session.goRouteSlide('coursemap');
    };

    const handleNext = e => routeToCoursemap(e);
    const handlePrevious = e => routeToCoursemap(e);
    const handleToggleAudio = () => {
        setIsAudioOn(container.audioManager.isAudioOn);
    };

    useEffect(() => {
        container.session.on('onChangeSlide onChangeGlobalSlide', handleChangeSlide);
        container.session.on('onPageStatusUpdate', handlePageStatusUpdate);
        container.session.on('onNext', handleNext);
        container.session.on('onPrevious', handlePrevious);
        container.session.on('onToggleAudio', handleToggleAudio);

        return () => {
            container.session.off('onChangeSlide onChangeGlobalSlide', handleChangeSlide);
            container.session.off('onPageStatusUpdate', handlePageStatusUpdate);
            container.session.off('onNext', handleNext);
            container.session.off('onPrevious', handlePrevious);
            container.session.off('onToggleAudio', handleToggleAudio);
        };
    }, []);

    const prev = (e) => {
        e.preventDefault();
        container.router.previous();
    };

    const next = (e) => {
        e.preventDefault();
        container.router.next();
    };

    return {
        percentage,
        hideCentreNav,
        isNextDisabled,
        isAudioOn,
        prev,
        next,
    };
}
