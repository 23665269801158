import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import ListGroup from 'react-bootstrap/ListGroup';
import { MdClear, MdDone } from 'react-icons/md';
import Listbox from './Listbox';
import './Listbox.scss';
import Feedback from '../feedback/Feedback';
import _ from 'underscore';
import classNames from 'classnames';

/**
 * This is customised to meet for question in scenarios of inline feedback.
 */
class QuestionListbox extends Listbox {
    constructor(props) {
        super(props);
        this.state = {
            isAnswered: this.props.isAnswered,
            items: this.props.items,
            selectedItems: [],
            activeDescendant: undefined,
        };
        this.listId = _.uniqueId('list_');
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.isAnswered === false && nextProps.isAnswered === true) {
            this.gradeSelections();
            this.setState({ isAnswered: nextProps.isAnswered });
        }
    }

    gradeSelections() {
        const totalCorrectSelectedAnswers = _.reduce(
            _.where(this.state.items, { isSelected: true, isCorrect: true }),
            function (memo) {
                return memo + 1;
            },
            0
        );
        const totalCorrectAnswers = _.reduce(
            _.where(this.state.items, { isCorrect: true }),
            function (memo) {
                return memo + 1;
            },
            0
        );

        const userScore = _.reduce(
            _.where(this.state.items, { isSelected: true, isCorrect: true }),
            (memo, num) => {
                return memo + num.score;
            },
            0
        );
        const maxScoreAvailable = _.reduce(
            _.where(this.state.items, { isCorrect: true }),
            function (memo, num) {
                return memo + num.score;
            },
            0
        );

        const selections = this.state.items.map((item, i) => {
            const isCorrect = item.isSelected && item.isCorrect;
            return { ...item, ...isCorrect };
        });

        const result = {
            totalCorrectAnswers,
            totalCorrectSelectedAnswers,
            userScore,
            maxScoreAvailable,
            selections,
            percentage: totalCorrectSelectedAnswers / totalCorrectAnswers,
        };

        if (this.props.onResult) this.props.onResult(result);
    }

    handleItemClick(index) {
        if (this.props.isAnswered) {
            Logger.info('Click.QuestionListbox.Rejected', index);
            return;
        }

        super.handleItemClick(index);
    }

    render() {
        const { instruction, isAnswered, isSingleSelect } = this.props;
        const { items, activeDescendant } = this.state;

        return (
            <div className="listbox">
                {instruction && (
                    <p className="instruction" id={this.listId} role="label">
                        {ReactHtmlParser(instruction)}
                    </p>
                )}

                <ListGroup
                    as="ul"
                    className={`list ${isAnswered ? 'disabled' : ''}`}
                    role="listbox"
                    tabIndex={isAnswered ? -1 : 0}
                    onKeyDown={this.handleOnKeyDown.bind(this)}
                    onFocus={this.handleOnFocus.bind(this)}
                    aria-multiselectable={!isSingleSelect}
                    aria-activedescendant={`${this.listId}_${activeDescendant}`}
                    aria-labelledby={this.listId}
                >
                    {items.map((item, i) => {
                        const isDescendant = i === activeDescendant;
                        const isActive = item.isSelected && !isAnswered;
                        const isCorrect =
                            isAnswered && item.isSelected && item.isCorrect;
                        const isIncorrect =
                            isAnswered && item.isSelected && !item.isCorrect;
                        const showHighlight =
                            !container.isLocked && item.isCorrect;
                        const showFeedback =
                            (item.feedback.title.length > 0 ||
                                item.feedback.content.length > 0) &&
                            this.props.isAnswered &&
                            item.isSelected;
                        return (
                            <li
                                className="list-unstyled my-1 px-0 py-0 border-0"
                                key={i.toString()}
                            >
                                <div
                                    role="option"
                                    id={`${this.listId}_${i}`}
                                    aria-selected={item.isSelected}
                                    className={classNames({
                                        'd-flex option pr-3 py-2': true,
                                        activedescendant: isDescendant,
                                        disabled: isAnswered,
                                        active: isActive,
                                        correct: isCorrect,
                                        incorrect: isIncorrect,
                                        'correct-highlight': showHighlight,
                                    })}
                                    onClick={this.handleItemClick.bind(this, i)}
                                >
                                    <div className="outcome-icon d-flex justify-content-center align-items-center">
                                        {isCorrect && <MdDone fill="#469239" />}
                                        {isIncorrect && (
                                            <MdClear fill="#e34b58" />
                                        )}
                                    </div>

                                    <div className="title">
                                        {ReactHtmlParser(item.title)}
                                    </div>
                                </div>
                                {showFeedback && (
                                    <Feedback
                                        data={item.feedback}
                                        className="mx-4 my-2"
                                    />
                                )}
                            </li>
                        );
                    })}
                </ListGroup>
            </div>
        );
    }
}

export default QuestionListbox;
