import container from '../../externals/core/core/container/container';

class Container extends container {
    handleCourseLaunch(e) {
        this.language = e.language;
        this.division = e.division;
        this.languagePath = this.languagePath.replace(
            'course_en',
            `course_${e.language}`,
        );
        this.replacerPath = this.replacerPath.replace(
            'course_en',
            `course_${e.language}`,
        );
        this.courseXML = this.courseXML.replace(
            'course_en/course.json',
            `course_${e.language}/course_${e.division}.json`,
        );

        this.preloadCourse();
        this.addCssClasses();

        // Initialise language dependendent components here

        this.showCourse();
    }
}

export default Container;
