import Backbone from 'backbone';
const Splitter = Backbone.Model.extend(
    {
        // No instance required //
    },
    {
        HASHES: '##',
        COLONS: '::',
        PIPES: '||',
        EQUALES: '==',
    }
);

export { Splitter };
