import Backbone from 'backbone';

const WindowHelper = Backbone.Model.extend(
    {},
    {
        setFullscreen() {
            window.top.moveTo(0, 0);
            window.top.resizeTo(
                window.screen.availWidth,
                window.screen.availHeight
            );
        },

        openLink(link) {
            const popup = window.open(
                link,
                'link',
                'status=no,menubar=no,location=no'
            );
            popup.moveTo(0, 0);
            popup.resizeTo(window.screen.availWidth, window.screen.availHeight);
            popup.focus();
            return popup;
        },
    }
);

export default WindowHelper;
