import React, { Fragment, useMemo, useRef } from 'react';
import ReactHtmlParser from 'react-html-parser';
import {
    CardColumns,
    CardDeck,
    CardGroup,
    Col,
    Container,
    Row,
} from 'react-bootstrap';
import useBuildAnimation from '../../hooks/useBuildAnimation';
import useRefArray from '../../hooks/useRefArray';
import styles from './Notes.module.css';
import { useBlock } from '~templates/components/blocks';
import usePermitted from '~m/RoomSync/hooks/usePermitted';

const collections = {
    group: CardGroup,
    deck: CardDeck,
    columns: CardColumns,
    row: Row,
};

function RowParent(props) {
    const { children, span, ...rest } = props;

    return (
        <Col md={span} {...rest} className="details">
            {children}
        </Col>
    );
}

function Notes() {
    const { data } = useBlock({ instantComplete: true });
    const [elements, elementsRefProp] = useRefArray();
    const Collection = useMemo(() => collections[data.type ?? 'row'], []);

    useBuildAnimation('staggerAnimateIn', elements);

    const { isInstructor } = usePermitted();

    if (!isInstructor) return null;

    return (
        <div className={styles.background}>
            <div className={styles.border} />
            <Container className={styles.wrapper}>
                <Collection className={styles.text}>
                    {data.columns.map((item, i) => {
                        const child = (
                            <div ref={elementsRefProp}>
                                {item.text && (
                                    <div className={styles.text}>
                                        {ReactHtmlParser(item.text)}
                                    </div>
                                )}
                            </div>
                        );

                        return data.type === 'row'
                            ? <RowParent key={i} span={item.columnSpan}>{child}</RowParent>
                            : <Fragment key={i}>{child}</Fragment>;
                    })}
                </Collection>
            </Container>
        </div>
    );
}

export default Notes;
