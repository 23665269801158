import { useEffect, useState } from 'react';
import useRoomParam from './useRoomParam';
import useRoomSync from './useRoomSync';

function useRoomParamMirror(key, value, setValue) {
    const [param, setParam] = useRoomParam(key);
    const roomSync = useRoomSync();

    useEffect(() => {
        if (roomSync.isInstructor) return;
        setValue(
            param === null ? undefined : param,
        );
    }, [param]);

    useEffect(() => {
        setParam(
            value === undefined ? null : value,
        );
    }, [value]);

    return [value, setValue];
}

export default useRoomParamMirror;
