import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropType from 'prop-types';
import { debounce } from 'lodash';
import { VideoPlayerProvider } from './context.js';

/**
 * @param props
 */
function VideoPlayer(props) {
    const {
        children,
        src,
        poster,
        allowWatchAhead,
        className,
        classes,
        i18n,
        onComplete,
        controlComponent,
        onBeforeHideControls,
        onAfterHideControls,
        tracks,
        context,
        defaultSubtitle,
    } = props;

    /** @type {import('react').MutableRefObject<HTMLDivElement>} */
    const playerElement = useRef();
    /** @type {import('react').MutableRefObject<HTMLVideoElement>} */
    const videoElement = useRef();
    /** @type {import('react').MutableRefObject<HTMLInputElement>} */
    const seekBarElement = useRef();
    /** @type {import('react').MutableRefObject<HTMLDivElement>} */
    const controlsElement = useRef();

    const [isPlaying, setIsPlaying] = useState(false);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [isShowTranscript, setIsShowTranscript] = useState(false);
    const [hasCompleted, setHasCompleted] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [progressedTime, setProgressedTime] = useState(0);
    const [currentVolume, setCurrentVolume] = useState(0.5);
    const [muted, setMuted] = useState(false);

    const setCueLine = (line) => {
        [...(videoElement.current?.textTracks ?? [])].forEach((track) => {
            [...(track?.activeCues ?? [])].forEach((cue) => {
                cue.line = line;
            });
            [...(track?.cues ?? [])].forEach((cue) => {
                cue.line = line;
            });
        });
    };

    const toggleMute = () => {
        videoElement.current.muted = !videoElement.current.muted;
    };

    const isAtStart = useMemo(() => currentTime === 0, [currentTime]);
    const isAtEnd = useMemo(
        () => currentTime > 0 && currentTime === videoElement.current?.duration,
        [currentTime, videoElement.current]
    );

    useEffect(() => {
        setCurrentVolume(videoElement.current?.volume ?? 0.5);
    }, [videoElement.current]);

    useEffect(() => {
        if (!(isAtEnd && !hasCompleted)) {
            return;
        }

        setHasCompleted(true);
        onComplete();
    }, [isAtEnd, hasCompleted]);

    const videoPlay = () => {
        videoElement.current.play();
        setCueLine(-4);
    };
    const videoPause = () => videoElement.current.pause();
    const videoStop = () => {
        videoPause();
        videoElement.current.currentTime = 0;
    };
    const videoReplay = () => {
        videoElement.current.currentTime = 0;
        videoPlay();
    };

    const enterFullscreen = () => playerElement.current.requestFullscreen();
    const exitFullscreen = () => document.exitFullscreen();
    const toggleFullscreen = () =>
        document.fullscreenElement ? exitFullscreen() : enterFullscreen();

    const showTranscript = () => setIsShowTranscript(true);
    const hideTranscript = () => setIsShowTranscript(false);
    const toggleTranscript = () => setIsShowTranscript(!isShowTranscript);

    const showControls = () => {
        controlsElement.current.classList.add('video-controls-show');
    };
    const hideControls = () => {
        // Don't hide the controls if the video is not playing
        if (isAtEnd) {
            return;
        }
        onBeforeHideControls();
        controlsElement.current.classList.remove('video-controls-show');
        onAfterHideControls();
    };

    const [isMoving, setIsMoving] = useState(false);
    const stopMoving = () => setIsMoving(false);
    const debounceMoving = debounce(stopMoving, 2000);
    const [isHoveringControls, setIsHoveringControls] = useState(false);

    const shouldShowControls = useMemo(() => {
        return isMoving || isAtEnd || isAtStart || isHoveringControls;
    }, [isMoving, isAtEnd, isAtStart]);

    useEffect(() => (shouldShowControls ? showControls() : hideControls()), [shouldShowControls]);

    const onFullscreenChange = () => setIsFullscreen(!!document.fullscreenElement);
    const onMouseMove = () => {
        setIsMoving(true);
        debounceMoving();
    };
    const onMouseLeave = () => stopMoving();

    const onMouseEnterControls = () => setIsHoveringControls(true);
    const onMouseLeaveControls = () => setIsHoveringControls(false);

    const [activeSubtitle, setActiveSubtitle] = useState(false);
    const setSubtitle = (lang) => {
        const video = videoElement.current;
        setActiveSubtitle(lang);
        setCueLine(-4);

        for (const textTrack of [...video.textTracks]) {
            textTrack.mode = textTrack.language === lang ? 'showing' : 'hidden';
        }
    };

    const cycleSubtitle = () => {
        const video = videoElement.current;
        const textTracks = [...video.textTracks];
        const currentIndex = textTracks.findIndex((track) => track.language === activeSubtitle);

        if (currentIndex === textTracks.length - 1) {
            setSubtitle(false);
        } else {
            setSubtitle(textTracks[currentIndex + 1].language);
        }
    };

    useEffect(() => {
        playerElement.current.addEventListener('fullscreenchange', onFullscreenChange);
        playerElement.current.addEventListener('mousemove', onMouseMove);
        playerElement.current.addEventListener('mouseleave', onMouseLeave);
        controlsElement.current.addEventListener('mouseenter', onMouseEnterControls);
        controlsElement.current.addEventListener('mouseleave', onMouseLeaveControls);
        setSubtitle(defaultSubtitle);

        return () => {
            playerElement.current.removeEventListener('fullscreenchange', onFullscreenChange);
            playerElement.current.removeEventListener('mousemove', onMouseMove);
            playerElement.current.removeEventListener('mouseleave', onMouseLeave);
            controlsElement.current.removeEventListener('mouseenter', onMouseEnterControls);
            controlsElement.current.removeEventListener('mouseleave', onMouseLeaveControls);
        };
    }, []);

    const ctx = {
        refs: {
            playerElement,
            videoElement,
            seekBarElement,
            controlsElement,
        },
        states: {
            isPlaying,
            isFullscreen,
            isAtStart,
            isAtEnd,
            isShowTranscript,
            hasCompleted,
            activeSubtitle,
        },
        actions: {
            videoPlay,
            videoPause,
            videoStop,
            videoReplay,
            enterFullscreen,
            exitFullscreen,
            toggleFullscreen,
            showTranscript,
            hideTranscript,
            toggleTranscript,
            toggleMute,
            setSubtitle,
            cycleSubtitle,
        },
        time: {
            currentTime,
            progressedTime,
        },
        volume: {
            currentVolume,
            muted,
        },
        options: {
            allowWatchAhead: !!allowWatchAhead,
            classes,
        },
        i18n: {
            t: i18n,
        },
        components: {
            controlComponent,
        },
    };

    useEffect(() => {
        if (!context) {
            return;
        }

        context.current = ctx;
    }, []);

    return (
        <VideoPlayerProvider value={ctx}>
            <div className={className ?? classes?.player} ref={playerElement}>
                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                <video
                    ref={videoElement}
                    src={src}
                    poster={poster}
                    onPlay={() => setIsPlaying(true)}
                    onPause={() => setIsPlaying(false)}
                    onTimeUpdate={({ target }) => {
                        setCurrentTime(target.currentTime);
                        if (target.currentTime > progressedTime) {
                            setProgressedTime(target.currentTime);
                        }
                    }}
                    onVolumeChange={({ target }) => {
                        setCurrentVolume(target.volume);
                        setMuted(target.muted);
                    }}
                >
                    {tracks?.map((track, index) => {
                        const { kind, source, lang, label } = track;
                        return (
                            <track
                                default
                                key={index.toString()}
                                kind={kind}
                                src={source}
                                srcLang={lang}
                                label={label}
                                id={lang}
                            />
                        );
                    })}
                </video>
                {children}
            </div>
        </VideoPlayerProvider>
    );
}

VideoPlayer.propTypes = {
    children: PropType.node,
    src: PropType.string.isRequired,
    poster: PropType.string,
    className: PropType.string,
    allowWatchAhead: PropType.bool,
    classes: PropType.shape({
        player: PropType.string,
        controls: PropType.string,
        control: PropType.string,
        seek: PropType.string,
    }),
    i18n: PropType.func,
    onComplete: PropType.func,
    controlComponent: PropType.func,
    onBeforeHideControls: PropType.func,
    onAfterHideControls: PropType.func,
    tracks: PropType.arrayOf(
        PropType.shape({
            kind: PropType.string,
            src: PropType.string,
            srclang: PropType.string,
            label: PropType.string,
        })
    ),
    context: PropType.shape({ current: PropType.any }),
    defaultSubtitle: PropType.oneOfType([PropType.string, false]),
};

VideoPlayer.defaultProps = {
    children: undefined,
    allowWatchAhead: true,
    poster: undefined,
    className: undefined,
    classes: undefined,
    i18n: (v) => v,
    onComplete: () => {},
    controlComponent: undefined,
    onBeforeHideControls: () => {},
    onAfterHideControls: () => {},
    tracks: undefined,
    context: undefined,
    defaultSubtitle: false,
};

export default VideoPlayer;
