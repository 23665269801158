import useRoomSync from './useRoomSync';

function usePermitted() {
    const roomSync = useRoomSync();
    return {
        isLearner: roomSync.isLearner,
        isInstructor: roomSync.isInstructor,
        accountType: roomSync.accountType,
    };
}

export default usePermitted;
