import React from 'react';
import { Carousel, Col, Container, Image, Jumbotron } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import CarouselControls from './CarouselControls';
import Block from '~blocks/base/Block';
import BuildAnimation from '~components/animations/BuildAnimation';
import './Carousel.scss';

// import { keyFor } from 'core-js/fn/symbol';

// noinspection JSClosureCompilerSyntax
/**
 * @extends Block
 * @memberOf ReactBlocks
 */
class CarouselBlock extends Block {
    /** @type {import('./carousel-types').CarouselSchema} */
    data = this.props.blockRef?.data;

    /**
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            active: 0,
            viewedSlides: [],
        };
        this.element = React.createRef();
    }

    /**
     * Set first slide in viewed state}
     */
    componentDidMount() {
        BuildAnimation.animateIn(this.element.current);
        this.updateViewedSlides(0);
    }

    /**
     * @param prevState
     * Check page compilation
     */
    componentDidUpdate(prevState) {
        if (
            prevState.active !== this.state.active &&
            this.state.viewedSlides.length === this.data.items.length
        ) {
            this.props.blockRef.complete();
        }
    }

    /**
     * @param index
     * @returns {boolean}
     */
    isSlideViewed(index) {
        return _.includes(this.state.viewedSlides, index);
    }

    /**
     * @param index
     * Set active State value
     * Set prevState State value if slide not viewed
     */
    goto(index) {
        this.setState({ active: index });
        if (!this.isSlideViewed(index)) {
            this.updateViewedSlides(index);
        }
    }

    /**
     * @param index
     * Set prevState State value
     */
    updateViewedSlides(index) {
        this.setState((prevState) => ({
            viewedSlides: [...prevState.viewedSlides, index],
        }));
    }

    /**
     * Jump to previous slide
     */
    slidePrevious() {
        this.goto(
            this.state.active > 0
                ? this.state.active - 1
                : this.data.items.length - 1
        );
    }

    /**
     * Jump to next slide
     */
    slideNext() {
        this.goto((this.state.active + 1) % this.data.items.length);
    }

    /**
     * @returns {ReactNode}
     */
    render() {
        return (
            <Container ref={this.element}>
                <CarouselControls
                    reference={this}
                    slidePrevious={this.slidePrevious.bind(this)}
                    slideNext={this.slideNext.bind(this)}
                />
                <Carousel
                    activeIndex={this.state.active}
                    controls={false}
                    slide={false}
                    fade={true}
                    indicators={false}
                    interval={null}
                    wrap={false}
                    onSelect={this.goto}
                    as={Jumbotron}
                    className="px-0 py-4"
                >
                    {this.data.items.map((item, key) => (
                        <Carousel.Item key={key} className="px-sm-4">
                            <div className="row">
                                <Col md={4}>
                                    <Image
                                        className="w-100"
                                        src={item.image}
                                        alt={item.imageDescription}
                                        thumbnail
                                    />
                                </Col>
                                <Col md={8}>
                                    {ReactHtmlParser(item.content)}
                                </Col>
                            </div>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </Container>
        );
    }
}

export default CarouselBlock;
