/** @typedef {import('../Firebase').default} Firebase */
/** @typedef {import('./RoomSync.types').Room} Room */

import ContainerPlugin from '../ContainerPlugin/ContainerPlugin';
import params from '../../utils/params';
import SaffronError from '../../utils/SaffronError';
import mountComponent from '../../utils/mountComponent';
import * as Constants from './RoomSync.contants';
import { AccountType, FirebasePaths } from './RoomSync.contants';
import InstructorSyncManager from './managers/InstructorSyncManager';
import LearnerSyncManager from './managers/LearnerSyncManager';
import RoomManager from './managers/RoomManager';
import LearnerKickModal from './components/LearnerKickModal';

class RoomSync extends ContainerPlugin {
    name = 'RoomSync';
    requires = ['Firebase'];

    defaultConfig = {
        idAlphabet: '123456789',
        idLength: 5,
        roomLinkFormat: roomId => `${location.origin}${location.pathname}?code=${roomId}`,
    };

    _currentLearnerRef;
    _currentLearnerData;
    _currentRoomRef;
    _currentRoomData;
    _ready = false;

    async prepareRoomSync(email = undefined, extraData = {}) {
        const isInstructor = !!email;
        const UserManager = isInstructor ? InstructorSyncManager : LearnerSyncManager;
        this.roomManager = new RoomManager(this, this.firebase, this.container);
        this.userManager = new UserManager(this, this.firebase, this.container, this.roomManager);

        if (isInstructor) {
            if (!extraData.lmsUserId) {
                throw new Error('Instructors must have an ID from the LMS.');
            }
        }

        if (isInstructor) {
            await this.firebase.signOut();
        }

        try {
            await this.userManager.login(email, extraData);
        } catch (err) {
            this.trigger(Constants.EVENT_FAILED, err);
            return;
        }

        if (isInstructor) {
            await this.userManager.updateUserData({
                lmsUserId: extraData.lmsUserId,
            }, true);
        }

        this.trigger(Constants.EVENT_READY);

        if (!isInstructor) {
            const wrapper = document.createElement('div');
            wrapper.setAttribute('id', 'background-manager');
            wrapper.style.display = 'contents';
            this.container.dom.prepend(wrapper);
            mountComponent(LearnerKickModal, wrapper, null, {
                roomSync: this,
            });
        }
    }

    /** @returns {Firebase} */
    get firebase() {
        return this.container.componentList.Firebase;
    }

    /**
     * @deprecated
     */
    get room() {
        if (!this._currentRoomData || !this.roomId) {
            return;
        }

        return {
            id: this.roomId,
            data: this._currentRoomData,
        };
    }

    get roomId() {
        return this.roomManager.roomId;
    }

    /**
     * Returns the account type of the currently authenticated user
     * @returns {AccountType}
     */
    get accountType() {
        if (!this.firebase.isSignedIn) {
            return AccountType.NO_ACCOUNT;
        }

        if (this.firebase.user.isAnonymous) {
            return AccountType.LEARNER;
        }

        if (!this.firebase.user.isAnonymous) {
            return AccountType.INSTRUCTOR;
        }

        throw new SaffronError('room-sync/unknown-account-type', 'RoomSync: This account type is unknown');
    }

    /**
     * The currently authenticated user is an instructor
     * @returns {boolean}
     */
    get isInstructor() {
        return this.accountType === AccountType.INSTRUCTOR;
    }

    /**
     * The currently authenticated user is a learner
     * @returns {boolean}
     */
    get isLearner() {
        return this.accountType === AccountType.LEARNER;
    }

    async instructorCreateRoom(name, language, division) {
        return this.roomManager.create(name, language, division);
    }

    async instructorManageRoom(roomId) {
        return this.userManager.enterRoom(roomId);
    }

    async closeRoom(roomId) {
        return this.roomManager.close(roomId);
    }

    learnerUpdateData(values) {
        return this.userManager.updateUserData(values);
    }

    getRoomLink(roomId) {
        if (!roomId) return;
        return this.config.roomLinkFormat(roomId);
    }

    async learnerJoinRoom(roomId) {
        return this.userManager.enterRoom(roomId);
    }

    async leaveRoom() {
        return this.userManager.leaveRoom();
    }
}

export default RoomSync;
