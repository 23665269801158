import Backbone from 'backbone';

/**
 * @class SuspendDataItem object to hold key value pairs to store in suspend data
 * @name SuspendDataItem
 *
 * @property {string} label
 * @property {string} key
 * @property {string} data
 *
 * @augments Backbone.Model
 *
 * @see Backbone.Model
 */
const SuspendDataItem = Backbone.Model.extend(
    /** @lends SuspendDataItem.prototype */ {
        constructor() {
            this.key = undefined;
            this.data = undefined;

            Backbone.Model.apply(this, arguments);
        },
    }
);

/**
 * @class SuspendDataItems
 * @name SuspendDataItems
 * @augments Backbone.Collection
 *
 * @see Backbone.Collection
 */
const SuspendDataItems = Backbone.Collection.extend(
    /** @lends SuspendDataItems.prototype */ {
        /** @instance */
        model: SuspendDataItem,
    }
);

export { SuspendDataItem, SuspendDataItems };
