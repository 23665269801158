import React from 'react';
import ReactDOM from 'react-dom';
import { Button, Modal } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import { constant } from 'lodash';
import Component from '~coresrc/component/component.js';

class ModalBox extends React.Component {
    constructor(props) {
        super(props);
        this.session = container.session;
        this.state = {
            isShow: true,
            title: LanguageManager.getString('connection_title'),
            message: LanguageManager.getString('no_connection_message'),
            exitButton: LanguageManager.getString('connection_exit_button'),
        };
    }

    handleClose = () => {
        this.setState({ isShow: false });
    };

    handleCloseWindow = () => {
        window.top.close();
    };

    render() {
        return (
            <div>
                <Modal
                    show={this.state.isShow}
                    onHide={() => this.handleClose()}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {ReactHtmlParser(this.state.title)}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {ReactHtmlParser(this.state.message)}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="primary"
                            onClick={() => this.handleCloseWindow()}
                        >
                            {this.state.exitButton}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
const ConnectionNotification = Component.extend({
    initialize() {
        this.name = 'ConnectionNotification';
    },

    setup(container) {
        this.container = container;
        this.container.on('onLoadComplete', this.start, this);
    },

    start() {
        this.checkConnection();

        // uncomment the below line to test
        // this.show();
    },

    checkConnection() {
        const component = <ModalBox></ModalBox>;

        this.$connectionNotification = $(
            "<div id='connection-notification' />"
        );
        this.container.dom.prepend(this.$connectionNotification);

        const self = this;
        setInterval(function () {
            if (!navigator.onLine) {
                ReactDOM.render(component, self.$connectionNotification[0]);
            }
        }, 5000);
    },
});

export default ConnectionNotification;
