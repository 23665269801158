import Backbone from 'backbone';
import { Logger } from '../../../index';
import { ScormInterface } from './scorminterface';

/**
 * @class CmiDataItem object to hold key value pairs of cmi data
 * @name InteractionProperties
 *
 * @property {Scorm} scorm
 * @property {Array} interactions
 *
 * @augments Backbone.Model
 *
 * @see Backbone.Model
 */
var InteractionProperties = Backbone.Model.extend(
    /** @lends InteractionProperties.prototype */ {
        /**
         * Initialize the interaction properties
         */
        initialize() {
            this.scorm = this.attributes.scorm;
            this.interactions = [];
        },

        /**
         * Set an interaction based on data passed
         *
         * @param {object} data
         */
        setInteraction(data) {
            try {
                if (!data || !data.type || !data.response)
                    throw 'no data to set';

                var id = this.createInteractionID(data.blockData);
                if (!id) throw 'invalid id';
            } catch (e) {
                Logger.warn(`CourseProperties - set interaction: ${e}`);
                return;
            }

            const index = this.getCmiData('cmi.interactions._count');

            if (this.scorm.version == '1.2') {
                // SCORM 1.2 doesn't have field like description to store question text.
                this.setInteractionCmi(
                    index,
                    'id',
                    InteractionProperties.CLEAN_TEXT(`${id} ${data.question}`)
                );
            } else {
                this.setInteractionCmi(index, 'id', id);
            }

            this.setInteractionCmi(index, 'type', data.type);
            this.setInteractionCmi(
                index,
                'time',
                ScormInterface.FORMAT_TIMESTAMP(data.startTime)
            );
            this.setInteractionCmi(index, 'weighting', data.weighting);
            this.setInteractionCmi(index, 'description', data.question);
            this.setInteractionReponsesCmi(index, data.correctReponses);
            this.setInteractionCmi(index, 'student_response', data.response);
            this.setInteractionCmi(index, 'result', data.result);
            this.setInteractionCmi(
                index,
                'latency',
                this.scorm.formatTime(data.duration)
            );
        },

        /**
         * Set an interaction CMI item
         *
         * @param {number} index
         * @param {string} label
         * @param {string} value
         */
        setInteractionCmi(index, label, value) {
            if (value === undefined) return;

            const key = `cmi.interactions.${index}.${label}`;
            this.setCmiData(key, value);
        },

        /**
         * Set an interaction correct responses CMI item
         *
         * @param {number} index
         * @param {string} value
         */
        setInteractionReponsesCmi(index, value) {
            if (value === undefined) return;

            for (let i = 0; i < value.length; i++) {
                const key = `cmi.interactions.${index}.correct_responses.${i}.pattern`;
                this.setCmiData(key, value);
            }
        },

        /**
         * Get CMI data item based on key
         *
         * @param {string} key
         */
        getCmiData(key) {
            return this.scorm.get(key);
        },

        /**
         * Set a CMI data item
         *
         * @param {string} key
         * @param {string} value
         */
        setCmiData(key, value) {
            this.scorm.set(key, value);

            if (this.scorm.getLastErrorCode() !== 0) {
                Logger.error(
                    'InteractionProperties - failed to set data:',
                    this.scorm.getInfo()
                );
            }
        },

        /**
         * Create interaction ID using page ID, block ID and block Title
         * @param {BlockData} blockData
         */
        createInteractionID(blockData) {
            if (!blockData) return '';

            let id = `${blockData.pageID()}_${blockData.blockID()}`;
            const title = InteractionProperties.CLEAN_TEXT(blockData.title());
            if (title) id += `_${title}`;

            return id;
        },
    },
    {
        /**
         * Get clean text with the string limit of 255
         *
         * @param {string} s
         * @returns {string}
         */
        CLEAN_TEXT(s) {
            let clean = s.replace(/[\s]+/g, '_');
            clean = clean.replace(/[^a-zA-Z0-9_]/g, '');

            return clean.substring(0, InteractionProperties.STRING_LIMIT);
        },

        STRING_LIMIT: 255,

        TYPE_TRUE_FALSE: 'true-false',
        TYPE_CHOICE: 'choice',
        TYPE_FILL_IN: 'fill-in',
        TYPE_MATCHING: 'matching',
        TYPE_PERFORMANCE: 'performance',
        TYPE_SEQUENCING: 'sequencing',
        TYPE_LIKERT: 'likert',
        TYPE_NUMERIC: 'numeric',
    }
);

export { InteractionProperties };
