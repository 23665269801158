import React from 'react';
import PropTypes from 'prop-types';
import parseHtml from 'react-html-parser';
import styles from './ScenarioContentSlide.module.css';

function ScenarioContentSlide(props) {
    const { item } = props;

    return (
        <div className={styles.wrapper}>
            {parseHtml(item.content)}
        </div>
    );
}

ScenarioContentSlide.propTypes = {
    property: PropTypes.string,
};

ScenarioContentSlide.defaultProps = {
    property: undefined,
};

export default ScenarioContentSlide;
