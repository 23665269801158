import _ from 'underscore';
import Backbone from 'backbone';
import { Logger } from '../../index';
import Status from './status.js';
import Blocks from './blocks.js';

/**
 * @class Create a PageData of a course
 * @name PageData
 * @property {number} id
 * @property {string} moduleID
 * @property {string} pageID
 * @property {string} pageTitle
 * @property {string} pageDescription
 * @property {boolean} pageCompleted
 * @property {number} moduleNumber
 * @property {number} pageNumber
 * @property {number} pageStatus
 * @property {ref} $pageDom
 * @property {string} args
 * @property {boolean} isCompletionRequired
 * @property {Blocks} blocks
 *
 * @augments Backbone.Model
 *
 * @see Backbone.Model
 */
const PageData = Backbone.Model.extend(
    /** @lends PageData.prototype */ {
        defaults: {
            id: 0,
            moduleID: '',
            pageID: '',
            pageTitle: '',
            pageDescription: '',
            pageCompleted: null,
            moduleNumber: null,
            pageNumber: null,
            pageStatus: 0,
            $pageDom: undefined,
            args: undefined,
            isCompletionRequired: false,
            blocks: Blocks,
        },

        /**
         * Initialize the module data object
         */
        initialize() {
            this.blocks = new Blocks();
        },

        /**
         * Add a block into the blocks array in course data
         *
         * @param {import('./blockdata.js').default} block
         */
        addBlock(block) {
            this.listenTo(block, 'onStatusUpdate', this.checkCompletion);

            this.blocks.add(block);
        },

        /**
         * Get a block data of a page from the blocks array based on index
         *
         * @param {number} index
         * @returns {import('./blockdata.js').default}
         */
        getBlock(index) {
            return this.blocks.get(index);
        },

        /**
         * Get total blocks in a page
         *
         * @returns {number}
         */
        getTotalBlocks() {
            return this.blocks.length;
        },

        /**
         * Set or get moduleID in which this page belongs to.
         *
         * @param {string} value
         * @returns {string} moduleID
         */
        moduleID(value) {
            if (value != undefined) {
                this.set({
                    moduleID: value,
                });
            }

            return this.get('moduleID');
        },

        /**
         * Set or get pageID
         *
         * @param {string} value
         * @returns {string} pageID
         */
        pageID(value) {
            if (value) {
                this.set({
                    pageID: value,
                });
            }

            return this.get('pageID');
        },

        /**
         * Set or get page title
         *
         * @param {string} value
         * @returns {string}
         */
        title(value) {
            if (value) {
                this.set({
                    pageTitle: value,
                });
            }

            return this.get('pageTitle');
        },

        /**
         * Set or get description
         *
         * @param {string} value
         * @returns {string}
         */
        description(value) {
            if (value) {
                this.set({
                    pageDescription: value,
                });
            }

            return this.get('pageDescription');
        },

        /**
         * Get the block's template
         *
         * @returns {string}
         */
        getBlockTemplates() {
            const t = [];

            _.each(
                this.blocks,
                function (block, i) {
                    t.push(this.getBlock(i).template());
                },
                this
            );

            return t;
        },

        /**
         * Set the block's complete required state
         *
         * @param {boolean} value
         */
        setCompletionRequired(value) {
            this.set({
                isCompletionRequired: value,
            });

            const event = new Object();
            event.target = this;

            this.trigger('onCompletionRequiredUpdate', event);
        },

        /**
         * Get the block's complete required state
         *
         * @returns {boolean}
         */
        getCompletionRequired() {
            return this.get('isCompletionRequired');
        },

        /**
         * Set or get the completion state of a page
         *
         * @param {boolean} val
         * @param value
         * @returns {boolean} completion state of a page
         */
        isComplete(value) {
            if (value != undefined) {
                this.set({
                    pageCompleted: value,
                });
            }

            return this.get('pageCompleted');
        },

        /**
         * Set the module number
         *
         * @param {number} value
         */
        moduleNumber(value) {
            if (value > -1) {
                this.set({
                    moduleNumber: value,
                });
            }

            return this.get('moduleNumber');
        },

        /**
         * Set the page number
         *
         * @param {number} value
         */
        pageNumber(value) {
            if (value > -1) {
                this.set({
                    pageNumber: value,
                });
            }

            return this.get('pageNumber');
        },

        /**
         * Set status of a page
         *
         * @param {number} status - could be any number from 0 to 7, refer status.js for more details
         * @param {boolean} isForced - true/false to decide setting status forcefully or not
         * @returns {number} returns the page status, any number from 0 to 7, refer status.js for more details
         */
        setStatus(status, isForced) {
            // Exit if setting the same value as before
            if (this.getStatus() == status) return;

            // Exit if setting a lower value that before... Unless status is forced
            if (status <= this.getStatus() && !isForced) return;

            this.set({
                pageStatus: status,
            });

            const event = new Object();
            event.target = this;
            event.status = status;
            event.isForced = isForced;

            /**
             * onStatusUpdate is fired when status of a page is updated
             *
             * @event PageData#onStatusUpdate
             * @type {object}
             * @property {PageData} target
             * @property {number} status
             * @property {boolean} isForced
             */
            this.trigger('onStatusUpdate', event);

            /**
             * onPassed is fired when page status is updated to passed
             *
             * @event PageData#onPassed
             * @type {object}
             * @property {PageData} target
             * @property {number} status
             * @property {boolean} isForced
             */
            if (status == Status.PASSED) this.trigger('onPassed', event);
            /**
             * onCompleted is fired when page status is updated to completed
             *
             * @event PageData#onCompleted
             * @type {object}
             * @property {PageData} target
             * @property {number} status
             * @property {boolean} isForced
             */
            if (status == Status.COMPLETED) this.trigger('onCompleted', event);
            /**
             * onStarted is fired when page status is updated to started
             *
             * @event PageData#onStarted
             * @type {object}
             * @property {PageData} target
             * @property {number} status
             * @property {boolean} isForced
             */
            if (status == Status.STARTED) this.trigger('onStarted', event);
            /**
             * onFailed is fired when page status is updated to failed
             *
             * @event PageData#onFailed
             * @type {object}
             * @property {PageData} target
             * @property {number} status
             * @property {boolean} isForced
             */
            if (status == Status.FAILED) this.trigger('onFailed', event);

            return this.getStatus();
        },

        /**
         * Set or get the page DOM
         *
         * @param {ref} value
         * @returns {ref}
         */
        pageDom(value) {
            if (value) {
                this.set({
                    $pageDom: value,
                });
            }

            return this.get('$pageDom');
        },

        /**
         * Set the arguments of a page
         *
         * @param {string} value
         */
        setArgs(value) {
            this.set({
                args: value,
            });
        },

        /**
         * Get the arguments of a page
         *
         * @returns {string} arguments
         */
        getArgs() {
            return this.get('args');
        },

        /**
         * Get particular argument of a page using its name
         *
         * @param {string} name
         * @returns {string} null if not found | value of particular argument
         */
        getArg(name) {
            let result = null;
            if (this.getArgs() === '') return result;

            const args = this.getArgs().split('|');
            _.each(
                args,
                function (arg) {
                    const parts = arg.split('=');

                    if (parts.length === 2) {
                        if (parts[0] === name) {
                            result = parts[1];
                            return true;
                        }
                    } else {
                        Logger.warn(
                            'Invalid arg for page:',
                            this.pageID(),
                            arg
                        );
                    }
                },
                this
            );

            return result;
        },

        /**
         * Get status of a page
         *
         * @returns {number} returns the page status, any number from 0 to 7, refer status.js for more details
         */
        getStatus() {
            return this.get('pageStatus');
        },

        /**
         * Reset completion of all the blocks inside a page
         */
        resetBlockCompletion() {
            this.blocks.each(function (block) {
                block.resetCompletion();
            });
        },

        /**
         * Reset completion of the page
         */
        resetCompletion() {
            this.resetBlockCompletion();

            this.isComplete(false);
            this.setStatus(Status.AVAILABLE, true);

            /**
             * onResetStatus is fired when page status is reset to available
             *
             * @event PageData#onResetStatus
             * @type {object}
             * @property {PageData} target
             */
            this.trigger('onResetStatus', {
                target: this,
            });
        },

        /**
         * Check the completion of a page
         *
         * @returns {boolean} true/false based on page completion
         */
        checkCompletion() {
            let isCompleted;

            let passedBlocks = 0;
            let completedBlocks = 0;
            let failedBlocks = 0;
            let totalBlocks = 0;
            let startedBlocks = 0;

            let blockStatus;

            if (!this.blocks) return false;

            this.blocks.each(function (block) {
                totalBlocks++;
                blockStatus = block.getStatus();

                switch (blockStatus) {
                    case Status.PASSED:
                        passedBlocks++;
                        completedBlocks++;
                        break;
                    case Status.FAILED:
                        failedBlocks++;
                        completedBlocks++;
                        break;
                    case Status.COMPLETED:
                        completedBlocks++;
                        break;
                    case Status.STARTED:
                        startedBlocks++;
                        break;
                }
            });

            if (completedBlocks === 0) {
                this.setStatus(Status.AVAILABLE);
                isCompleted = false;
            } else if (completedBlocks < totalBlocks) {
                this.setStatus(Status.STARTED);
                isCompleted = false;
            } else if (failedBlocks > 0) {
                this.setStatus(Status.FAILED);
                isCompleted = true;
            } else if (passedBlocks > 0) {
                this.setStatus(Status.PASSED);
                isCompleted = true;
            } else {
                this.setStatus(Status.COMPLETED);
                isCompleted = true;
            }

            this.isComplete(isCompleted);

            return isCompleted;
        },
    }
);

export default PageData;
