import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import {
    Card,
    CardColumns,
    CardDeck,
    CardGroup,
    Col,
    Row,
} from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Block from '~blocks/base/Block';
// import './TextColumnsBlock.scss';
import BuildAnimation from '~components/animations/BuildAnimation';

/**
 * @memberOf ReactBlocks
 * @schema json schema
 */

class TextColumnsBlock extends Block {
    /**
     * @param props
     */
    constructor(props) {
        super(props);
        /** @type {import('./text-types').TextSchema} */
        this.data = this.props.blockRef.data;
        this.data.type = this.data.type ?? 'row';
        this.elements = [];

        this.type = {
            group: CardGroup,
            deck: CardDeck,
            columns: CardColumns,
            row: Row,
        }[this.data.type];
    }

    /**
     * Completing the screen.
     */
    componentDidMount() {
        this.props.blockRef.complete();

        BuildAnimation.staggerAnimateIn(this.elements);
    }

    /**
     * @returns {ReactNode}
     */
    render() {
        const Collection = this.type;

        // NP: the Type method causes the screen to flash/re-renders the screen when state changes

        return (
            <Container>
                <Collection
                    className="text-block"
                    ref={(div) => (this.card = div)}
                >
                    {this.data.columns.map((item, i) => {
                        const Type =
                            this.data.type === 'row'
                                ? ({ children, ...props }) => (
                                      <Col
                                          md={item.columnSpan}
                                          {...props}
                                          className="details"
                                      >
                                          {children}
                                      </Col>
                                  )
                                : React.Fragment;

                        return (
                            <Type key={i}>
                                <Card
                                    ref={(el) => {
                                        this.elements.push(el);
                                    }}
                                >
                                    {item.image !== undefined &&
                                        item.image !== '' && (
                                            <Card.Img
                                                src={item.image}
                                                alt={item.imageDescription}
                                            />
                                        )}
                                    {item.text !== undefined &&
                                        item.text !== '' && (
                                            <Card.Body>
                                                <div className="text">
                                                    {ReactHtmlParser(item.text)}
                                                </div>
                                            </Card.Body>
                                        )}
                                </Card>
                            </Type>
                        );
                    })}
                </Collection>
            </Container>
        );
    }
}

export default TextColumnsBlock;
