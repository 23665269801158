import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import AssetLoader from '../../loader/assetloader.js';
import JunctionCourseData from '../data/junctioncoursedata.js';
import JunctionRouter from './junctionrouter.js';

const JunctionPage = Backbone.Model.extend({
    defaults: {
        id: undefined,
        junctionId: undefined,
        xml: undefined,
    },

    getId() {
        return this.get('id');
    },

    getJunctionId() {
        return this.get('junctionId');
    },

    getXml() {
        return this.get('xml');
    },

    setId(id) {
        return this.set('id', id);
    },

    setJunctionId(junctionId) {
        return this.set('junctionId', junctionId);
    },

    setXml(xml) {
        return this.set('xml', xml);
    },
});

const JunctionPages = Backbone.Collection.extend({
    container: undefined,
    model: JunctionPage,

    initialize() {},

    setup(container) {
        this.container = container;

        this.loadPages();
    },

    loadPages() {
        const self = this;
        const deferreds = [];
        const pages = this.container.courseData.pages;

        for (let i = 0; i < pages.length; i++) {
            const page = pages.get(i);
            if (this.get(page.pageID())) {
                // throw 'Page ID must be unique (1 block per page): ' + page.pageID();
            }

            const block = page.getBlock(0);
            if (!block) {
                throw `No blocks on page: ${page.pageID()}`;
            }

            this.add({
                id: page.pageID(),
                xml: block.blockXML(),
            });

            const loader = new AssetLoader({
                name: 'router',
            });
            deferreds.push(new $.Deferred());
            loader.addXML(
                block.blockXML(),
                null,
                null,
                this,
                'handleBlockXMLLoaded',
                {
                    pageID: page.pageID(),
                    block,
                    deferred: deferreds[i],
                }
            );
            loader.start();
        }

        $.when.apply($, deferreds).done(function () {
            self.trigger('onLoadComplete', {
                target: self,
            });
        });
    },

    handleBlockXMLLoaded(e) {
        const self = this;
        const url = e.resource.url;
        const junctionID = e.parameter.pageID;
        const deferred = e.parameter.deferred;

        if (url.indexOf('.json') <= -1) {
            const $xml = $(e.resource.getXML());
            if ($xml.find('course').length === 1) {
                // Force into the block data before junction.js does this later.
                e.parameter.block.junctionData = new JunctionCourseData(
                    self.container
                );
                e.parameter.block.junctionData.createCourseData(
                    e.resource.getXML(),
                    'xml'
                );
                e.parameter.block.junctionData.score = 1;
                e.parameter.block.junctionData.passmark = 1;

                $xml.find('page').each(function () {
                    const $page = $(this);
                    const subpageID = $page.attr('id');
                    if (!subpageID) return true;

                    if (self.get(subpageID))
                        throw `Page ID must be unique: ${subpageID}`;

                    const $block = $page.find('block');
                    // if($block.length !== 1) throw "Must have 1 block per page! " + subpageID;

                    const xml = $block.attr('xml');

                    if (subpageID === JunctionRouter.HOMEPAGE_ID) {
                        const junction = self.get(junctionID);
                        junction.setXml(xml);
                        return true;
                    }

                    self.add({
                        id: subpageID,
                        junctionId: junctionID,
                        xml,
                    });
                });
            }
            if (deferred) deferred.resolve();
        } else {
            $.getJSON(url, function (data) {
                if (data.units) {
                    // Force into the block data before junction.js does this later.
                    e.parameter.block.junctionData = new JunctionCourseData(
                        self.container
                    );
                    e.parameter.block.junctionData.createCourseData(
                        data,
                        'json'
                    );
                    e.parameter.block.junctionData.score = 1;
                    e.parameter.block.junctionData.passmark = 1;
                    _.each(
                        data.units,
                        function (unit) {
                            _.each(
                                unit.pages,
                                function (page) {
                                    const subpageID = page.pageID;
                                    if (!subpageID) return true;
                                    if (self.get(subpageID))
                                        throw `Page ID must be unique: ${subpageID}`;
                                    _.each(page.blocks, function (block) {
                                        const path = block.jsonPath;

                                        self.add({
                                            id: subpageID,
                                            junctionId: junctionID,
                                            xml: path,
                                        });
                                    });
                                },
                                this
                            );
                        },
                        this
                    );
                }
                if (data.globalPages) {
                    _.each(data.globalPages, function (page) {
                        const subpageID = page.pageID;
                        if (!subpageID) return true;
                        if (self.get(subpageID))
                            throw `Page ID must be unique: ${subpageID}`;
                        _.each(page.blocks, function (block) {
                            const path = block.jsonPath;
                            if (subpageID === JunctionRouter.HOMEPAGE_ID) {
                                const junction = self.get(junctionID);
                                junction.setXml(path);
                                return true;
                            }

                            self.add({
                                id: subpageID,
                                junctionId: junctionID,
                                xml: path,
                            });
                        });
                    });
                }
                if (deferred) deferred.resolve();
            });
        }
    },

    isJunction(pageId) {
        const junction = this.findWhere({
            junctionId: pageId,
        });

        return !!junction;
    },

    getJunctionPages(junctionId) {
        const junctionPages = this.where({
            junctionId,
        });

        return junctionPages;
    },
});

export { JunctionPage, JunctionPages };
