import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import { Card } from 'react-bootstrap';
import DragHandle from './DragHandle';

/**
 * @memberOf ReactBlocks
 * @Param {Object} item Using item order and label value to create option li element.
 * @Param {Number} sortIndex Using index value to create unique li element.
 * @param {String} classNames Using classNames to pass classNames value to DragHandle.
 * @param {Boolean} isLocked Checking course is locked or unlocked and prepending drag label drop target id
 * for testing purpose.
 * @Param {Number} setTabIndexValue Getting and setting tabindex as per useDragHandle value to focus option or drag handle
 * and keyboard support. If useDragHandle is false setting -1 otherwise 0. Also, by using this value
 * to toggle the list option cursor on hover state.
 */
const SortableItem = SortableElement(
    ({ item, sortIndex, classNames, isLocked, setTabIndexValue }) => (
        <Card
            className={`order-${item.order} item-${sortIndex} ${classNames} card-dragdrop`}
            style={{ cursor: setTabIndexValue === true ? 'default' : 'move' }}
            drop-target={item.order}
            tabIndex={setTabIndexValue === true ? -1 : 0}
        >
            <Card.Body className="d-flex flex-row">
                <DragHandle
                    classNames={classNames !== undefined ? classNames : ''}
                    tabIndexValue={setTabIndexValue === true ? '0' : '-1'}
                />
                <span className="drag-label d-flex align-items-center">
                    {isLocked === true
                        ? item.label
                        : `${item.order}. ${item.label}`}
                </span>
            </Card.Body>
        </Card>
    )
);

export default SortableItem;
