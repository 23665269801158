import mountComponent from '../../utils/mountComponent';
import BackgroundHandler from './components/BackgroundHandler';
// import generalStyles from './components/General.module.css';
import ContainerPlugin from '~m/ContainerPlugin/ContainerPlugin';

class BackgroundManager extends ContainerPlugin {
    name = 'BackgroundManager';
    afterSetup() {
        this.mount();
    }

    setBackground(data) {
        this.trigger('change', data);
    }

    mount() {
        const wrapper = document.createElement('div');
        wrapper.setAttribute('id', 'background-manager');
        wrapper.style.display = 'contents';
        this.container.dom.prepend(wrapper);
        mountComponent(BackgroundHandler, wrapper, null, {
            backgroundManager: this,
        });
    }
}

export default BackgroundManager;
