import Backbone from 'backbone';
import { Logger } from '../../index';

const ApplicationRouter = Backbone.Router.extend({
    constructor() {
        this.focusAttempts = undefined;
        this.container = null;

        Backbone.Router.apply(this, arguments);
    },

    routes: {
        'slide/:screen': 'changeSlide',
        'global/:id(/:query)': 'changeGlobalSlide',
        'startmodule/:unit': 'startModule',
        prev: 'previous',
        next: 'next',
        exit: 'exit',
        print: 'printPage',
        'audio/toggle': 'toggleAudio',
        'fullscreen/toggle': 'toggleFullscreen',
        'audio/set/:volume': 'setAudioVolume',
        'accessibility/toggle': 'toggleAccessibility',
        'focus/block/:id': 'focusBlock',
        'return-to-course': 'returnToCourse',
        'window/*url(?*queryString)': 'openWindow',
        '*actions': 'defaultRoute',
    },

    initialize(container) {
        this.container = container;
        this.query = undefined;

        Backbone.history.start({ silent: true });
    },

    exit() {
        Logger.trace('ApplicationRouter.exit');
        window.top.close();
    },

    previous() {
        this.container.session.previous();
    },

    next() {
        this.container.session.next();
    },

    printPage() {
        this.returnToCurrentPage();
        window.print();
    },

    setAudioVolume(val) {
        if (val <= 0) {
            this.toggleAudio(false);
        }

        if (val >= 1) {
            this.toggleAudio(true);
        }

        // don't need this part!
        // this.returnToCurrentPage();
    },

    toggleAudio(isOn) {
        if (isOn != undefined) {
            this.container.session.toggleAudio(isOn);
        } else {
            this.container.session.toggleAudio();
        }

        this.returnToCurrentPage();
    },

    toggleFullscreen() {
        this.container.session.toggleFullscreen();
        this.returnToCurrentPage();
    },

    toggleAccessibility() {
        this.container.session.toggleAccessibility();

        this.returnToCurrentPage();
    },

    returnToCurrentPage() {
        this.navigate(
            `/slide/${this.container.session.currentPageData.pageID()}`,
            {
                trigger: false,
                replace: false,
            }
        );
    },

    returnToCourse() {
        this.container.screenManager.returnToCourse();
        this.navigate(
            `/slide/${this.container.session.currentPageData.pageID()}`,
            {
                trigger: false,
                replace: true,
            }
        );
    },

    openWindow(url, queryString) {
        if (queryString) url += `?${queryString}`;

        window.open(url, '_blank');
        this.returnToCurrentPage();
    },

    changeSlide(slide) {
        const pageData = this.container.courseData.getPageById(slide);

        if (pageData != undefined) {
            this.container.session.goChangeSlide(slide);
        } else {
            this.container.session.goChangeSlide('coursemap');
        }
    },

    changeGlobalSlide(id, query) {
        this.navigate(`/global/${id}${query ? `/${query}` : ''}`, {
            trigger: false,
            replace: true,
        });

        const pageData = this.container.courseData.getPageById(id);

        if (pageData != undefined) {
            this.query = query;
            this.container.session.goChangeGlobalSlide(id);
        } else {
            Logger.warn('No global page found with ID:', id);
        }
    },

    startModule(id) {
        if (
            this.container.courseData.getModule(id).getModuleStatus() > 0 ||
            !this.container.isLocked
        ) {
            this.container.session.startModule(id);
        } else {
            this.returnToCurrentPage();
        }
    },

    getQuery() {
        return this.query;
    },

    navigate(fragment, options) {
        // console.log('fragment----------------------', fragment, Backbone.history, options);
        // Allow to navigate to same URL
        if (
            Backbone.history.getFragment(fragment) ===
                Backbone.history.fragment &&
            options.trigger === true
        ) {
            Backbone.history.loadUrl(fragment);
            // console.log('fragment--if--------------------', fragment, Backbone.history, options);
        } else {
            // console.log('fragment--else--------------------', fragment, Backbone.history, options);
            Backbone.Router.prototype.navigate.call(this, fragment, options);
        }
    },

    defaultRoute(actions) {
        this.changeSlide('slide/coursemap');
    },
});

export default ApplicationRouter;
