import React, { useDebugValue, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TweenMax from 'gsap/TweenMax';
import Avatar from '../Avatar/Avatar';
import styles from './AvatarPillGroup.module.css';

function getPercentage(count = 0, total = 0) {
    return Math.round(count / total * 100) || 0;
}

function AvatarPillGroup(props) {
    const { collection, total, className, percentage, icon } = props;

    // Prevent animating in avatars when the pill group is first rendered
    const [canAnimate, setCanAnimate] = useState(false);
    useEffect(() => {
        setTimeout(() => setCanAnimate(true), 50);
    }, []);

    return (
        <div className={classNames(styles.avatarWrapper, 'not-prose', {
            [styles.avatarWrapperZero]: !collection || collection?.length === 0,
            [styles.noPercentage]: total === undefined,
        }, className)}>
            {icon}
            {total !== undefined && (
                <div className={styles.percentage}>
                    {percentage ?? getPercentage(collection?.length, total)}%
                </div>
            )}
            {Array.isArray(collection) && collection?.length > 0 && (
                <div className={styles.stack}>
                    {collection.map((member, index) => (
                        <Avatar key={index} total={collection?.length} index={index} member={member?.data?.() ?? member} canAnimate={canAnimate} />
                    ))}
                </div>
            )}
        </div>
    );
}

AvatarPillGroup.propTypes = {
    total: PropTypes.number,
    collection: PropTypes.arrayOf(
        PropTypes.shape({
            avatar: PropTypes.number,
        }),
    ),
    className: PropTypes.string,
    percentage: PropTypes.number,
    icon: PropTypes.node,
};

AvatarPillGroup.defaultProps = {
    total: undefined,
    collection: undefined,
    className: undefined,
    percentage: undefined,
    icon: undefined,
};

export default AvatarPillGroup;
