import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Container } from 'react-bootstrap';
import QuestionBlock from './QuestionBlock';
import Listbox from '~components/listbox/QuestionListbox';
import BuildAnimation from '~components/animations/BuildAnimation';

/**
 * To be used if option click triggers submit and feedback is not required.
 *
 * @augments QuestionBlock
 */
class QuickFireQuestionBlock extends QuestionBlock {
    constructor(props) {
        super(props);

        this.junctionController = this.block.junctionController;
        this.initialQuestionData =
            this.junctionController.pageRenderer.currentPageData.getBlock(
                0
            ).view.data;
        this.element = React.createRef();
    }

    componentDidMount() {
        BuildAnimation.animateIn(this.element.current);
    }

    render() {
        const { question, context, instruction } = this.initialQuestionData;
        return (
            <Container ref={this.element}>
                <div className="question-block">
                    <div className="question-wrapper py-4" aria-live="polite">
                        {ReactHtmlParser(question)}
                        {context && ReactHtmlParser(context)}
                        {instruction && (
                            <div className="instruction">
                                {ReactHtmlParser(instruction)}
                            </div>
                        )}
                    </div>
                    <Listbox
                        items={this.state.options}
                        isSingleSelect={this.data.isSingleSelect}
                        isAnswered={this.state.isAnswered}
                        onClick={this.handleOptionClick.bind(this)}
                    />
                </div>
            </Container>
        );
    }

    /**
     * Updates page's status to pass or fail and takes to next page right away.
     *
     * @param {boolean} isSelected option isSelected boolean
     */
    handleOptionClick(isSelected) {
        this.setState({ isOptionSelected: isSelected });
        this.handleConfirmClick();
        this.props.blockRef.app.router.next();
    }
}

export default QuickFireQuestionBlock;
