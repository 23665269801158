// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".infix__322f55{bottom:0;left:0;position:fixed;right:0;top:0;z-index:-10}.fill__fd2387{height:100%;width:100%}", "",{"version":3,"sources":["webpack://./src/modules/BackgroundManager/components/General.module.css"],"names":[],"mappings":"AACI,eAAA,QAA0B,CAA1B,MAA0B,CAA1B,cAA0B,CAA1B,OAA0B,CAA1B,KAA0B,CAA1B,WAA0B,CAI1B,cAAA,WAAoB,CAApB,UAAoB","sourcesContent":[".infix {\n    @apply fixed inset-0 -z-10;\n}\n\n.fill {\n    @apply w-full h-full;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infix": "infix__322f55",
	"fill": "fill__fd2387"
};
export default ___CSS_LOADER_EXPORT___;
