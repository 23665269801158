import Backbone from 'backbone';
const DataItem = Backbone.Model.extend({
    idAttribute: 'key',

    defaults: {
        key: undefined,
        value: undefined,
        reference: undefined,
    },

    getKey() {
        return this.get('key');
    },

    getValue() {
        return this.get('value');
    },

    getReference() {
        return this.get('reference');
    },

    setKey(key) {
        return this.set('key', key);
    },

    setValue(value) {
        return this.set('value', value);
    },

    setReference(reference) {
        return this.set('reference', reference);
    },
});

const StorageData = Backbone.Collection.extend({
    model: DataItem,
});

export { StorageData, DataItem };
