import { useMemo } from 'react';
import { useDocument } from 'react-firebase-hooks/firestore';
import { Routes } from '../RoomSync.contants';
import useRoomSync from './useRoomSync';
import { useDocumentReference } from '~m/Firebase';

/**
 * @returns {import('../RoomSync.types').UseRoomDetailsReturn|undefined}
 */
function useRoomDetails() {
    const roomSync = useRoomSync();
    const id = useMemo(() => roomSync.roomId, [roomSync.roomId]);
    const path = useMemo(() => id ? Routes.rooms.GET(id) : undefined, [id]);
    /** @type {import('react-firebase-hooks/firestore').DocumentHook<import('../RoomSync.types').Room>} */
    // @ts-expect-error types are correct
    const [snapshot] = useDocument(
        useDocumentReference(path),
    );

    const room = useMemo(() => {
        if (!snapshot || !snapshot.exists()) return;

        return {
            ...snapshot.data(),
            id: snapshot.id,
            directLink: roomSync.getRoomLink(id),
        };
    }, [snapshot]);

    return room;
}

export default useRoomDetails;
