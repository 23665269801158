// your-slider.js
import React, { forwardRef } from 'react';
import * as BaseSlider from '@radix-ui/react-slider';
import PropTypes from 'prop-types';
import styles from './Slider.module.css';

function Slider(props, forwardedRef) {
    const { onThumbClick } = props;
    const value = props.value || props.defaultValue;
    return (
        <BaseSlider.Root {...props} ref={forwardedRef} className={styles.root} >
            <BaseSlider.Track className={styles.track} >
                <BaseSlider.Range className={styles.range} />
            </BaseSlider.Track>
            {value.map((_, i) => (
                <BaseSlider.Thumb key={i} className={styles.thumb} onClick={onThumbClick}/>
            ))}
        </BaseSlider.Root>
    );
}

const ForwardRef = forwardRef(Slider);

ForwardRef.displayName = Slider.name;

ForwardRef.propTypes = {
    value: PropTypes.arrayOf(PropTypes.number),
    defaultValue: PropTypes.arrayOf(PropTypes.number),
    onThumbClick: PropTypes.func,
};

ForwardRef.defaultProps = {
    onThumbClick: () => { },
};

export default ForwardRef;
