import Backbone from 'backbone';
import PageData from './pagedata.js';

/**
 * @class Pages
 * @name Pages
 * @augments Backbone.Collection
 *
 * @see Backbone.Collection
 */
const Pages = Backbone.Collection.extend(
    /** @lends Pages.prototype */ {
        /** @instance */
        model: PageData,
    }
);

export { Pages };
