import { useMemo } from 'react';
import usePrevious from '../../../hooks/usePrevious';
import { MUST_BE_INSTRUCTOR } from '../RoomSync.errors';
import useRoomDetails from './useRoomDetails';
import useRoomSync from './useRoomSync';

/**
 * @typedef RoomSyncParamsReturn
 * @type {Object} obj
 * @property {Object.<string, string|number|boolean>} obj.params
 * @property {Object.<string, string|number|boolean>} obj.prevParams
 * @property {(key: string, value: string|number|boolean) => void} obj.update
 */

/**
 * @returns {RoomSyncParamsReturn}
 */
function useRoomSyncParams() {
    const roomSync = useRoomSync();
    const room = useRoomDetails();
    const params = useMemo(() => room?.params ?? {}, [room]);
    const prevParams = usePrevious(params);

    const update = (key, value) => {
        roomSync.roomManager.updateParam(key, value).catch((error) => {
            if (error?.code === MUST_BE_INSTRUCTOR) {
                return;
            }

            throw error;
        });
    };

    return {
        params,
        update,
        prevParams,
    };
}

export default useRoomSyncParams;
