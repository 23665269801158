import React from 'react';
import { useRoomSync } from '../hooks';
import { AccountType } from '../RoomSync.contants';

function createRoomRoutedBlock(InstructorComponent, LearnerComponent) {
    function RoomRoutedBlock() {
        const roomSync = useRoomSync();

        switch (roomSync.accountType) {
            case AccountType.INSTRUCTOR: return <InstructorComponent />;
            case AccountType.LEARNER: return <LearnerComponent />;
            default: throw new Error('You must be signed in to access this page');
        }
    }

    return RoomRoutedBlock;
}

export default createRoomRoutedBlock;
