/** @typedef {import('../RoomSync.types').IManager} IManager */

import SaffronError from '../../../utils/SaffronError';
import { ATTENDEE_LIMIT, ConnectionStatus, EVENT_AFTER_ROOM_LEAVE, EVENT_BEFORE_ROOM_LEAVE, EVENT_LEARNER_KICKED, Routes } from '../RoomSync.contants';
import { ROOM_FULL, ROOM_KICKED } from '../RoomSync.errors';
import AbstractManager from './AbstractManager';
import { Logger } from '~core';

/**
 * @implements {IManager}
 */
class LearnerSyncManager extends AbstractManager {
    roomReference;

    get accountDetailsPath() {
        if (!this.firebase.isSignedIn) return;

        return Routes.rooms.members.GET(this.roomSync.roomId, this.firebase.user.uid);
    }

    async login() {
        if (this.firebase.isSignedIn && !this.firebase.user.isAnonymous) {
            // throw new SaffronError('learner-sync-manager/already-signed-in', 'Cannot log learner in. Someone is already signed in.');
            await this.firebase.signOut();
        }

        return this.firebase.signInAnonymously();
    }

    async updateUserData(values) {
        await this.firebase.setDoc(Routes.learners.GET(this.firebase.user.uid), values);

        // Also update the current room
        if (this.roomManager.roomId) {
            await this.firebase.setDoc(Routes.rooms.members.GET(this.roomManager.roomId, this.firebase.user.uid), values);
        }
    }

    async enterRoom(roomId) {
        const roomMemberPath = Routes.rooms.members.GET(roomId, this.firebase.user.uid);

        const room = await this.roomManager.enter(roomId, {
            extraChecks: async ({ snapshot }) => {
                if (snapshot.data().attendeeCount >= ATTENDEE_LIMIT) {
                    throw new SaffronError(ROOM_FULL, 'This room is full and cannot be joined');
                }

                const roomMemberSnapshot = await this.firebase.getDoc(roomMemberPath);

                const isKicked = roomMemberSnapshot.data()?.kicked;

                if (isKicked) {
                    throw new SaffronError(ROOM_KICKED, 'You have been kicked from this room and cannot be joined');
                }

                return true;
            },
            onRoomUpdate: ({ data }) => {
                const currentPageId = this.container.session.currentPageData?.attributes.pageID;
                if (currentPageId === data.page) {
                    Logger.debug('LearnerSyncManager.enterRoom: Not changing page as the page is already active');
                    return;
                }
                this.container.router.changeSlide(data.page);
            },
        });

        const uid = this.firebase.user.uid;
        this.firebase.createPresenceMonitor(
            `room-statuses/${roomId}/${uid}`,
            ConnectionStatus.CONNECTED,
            ConnectionStatus.DISCONNECTED,
        );

        this.firebase.subscribeToDoc(roomMemberPath, (snapshot) => {
            const { kicked } = snapshot.data() ?? {};

            if (kicked) {
                this.roomSync.trigger(EVENT_LEARNER_KICKED);
                setTimeout(() => {
                    window.location.reload();
                }, 5000);
            }
        });

        return room;
    }

    leaveRoom() {
        if (!this._currentRoomRef) {
            throw new Error('leave-room/not-in-a-room');
        }

        this.roomSync.trigger(EVENT_BEFORE_ROOM_LEAVE);

        this._currentRoomRef();

        this._currentRoomRef = null;
        this._currentRoomData = null;

        this.roomSync.trigger(EVENT_AFTER_ROOM_LEAVE);
    }
}

export default LearnerSyncManager;
