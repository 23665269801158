import React from 'react';
import ReactDOM from 'react-dom';
import Backbone from 'backbone';
import Navigation from '~blocks/navigation/Navigation';
import ScrollDown from '~blocks/scrollerbutton/ScrollDown';

const NavigationManager = Backbone.Model.extend({
    constructor() {
        this.name = 'Navigation';
    },

    setup(container) {
        this.container = container;
        this.listenTo(this.container, 'onLoadComplete', this.start);
    },

    start() {
        const component = <Navigation blockRef={this}></Navigation>;
        // var scrollerbutton = <ScrollDown blockRef={this}></ScrollDown>;

        this.$el = $("<div class='navigation' />");
        // this.$scroll = $("<div id='scroll-button' />");
        this.container.dom.prepend(this.$el);
        // this.container.dom.prepend(this.$scroll);

        ReactDOM.render(component, this.$el[0]);
        // ReactDOM.render(scrollerbutton, this.$scroll[0]);
    },
});

export default NavigationManager;
