import React from 'react';
import Backbone from 'backbone';
import { MdSort } from 'react-icons/md';
import { Card, Table } from 'react-bootstrap';
import AdminBase from './AdminBase';
import AdminNavBar from './AdminNavBar';
import AssetLoader from '~coresrc/loader/assetloader';

/**
 * @memberOf ReactBlocks
 * @schema json schema
 */

class AdminImageReview extends AdminBase {
    /**
     * @param props
     */
    constructor(props) {
        super(props);
        this.data = this.props.blockRef.data;
        this.app = this.props.blockRef.app;
        this.state = {
            images: [],
            totalImageSize: 0,
            sortedByAscending: false,
            widthSortedAscending: true,
            heightSortedAscending: true,
        };

        this.app.courseData.modules.each((module) => {
            Backbone.listenTo(
                module,
                'onStatusUpdate',
                this.handleUpdate.bind(this)
            );
            Backbone.listenTo(module, 'onReset', this.handleUpdate.bind(this));
        });
    }

    handleUpdate() {}

    /**
     * Completes the screen.
     */
    componentDidMount() {
        this.props.blockRef.complete();
        this.preloadCourseImages();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    preloadCourseImages() {
        const self = this;
        let imageData = [];
        const imageWarnings = [];
        let totalBytes = 0;
        const myPreloader = new AssetLoader({
            name: 'imagePreloader',
        });

        myPreloader.bind('onAssetLoaded', (e) => {
            const image = e.loader.resource.getImage();
            const fileBytes = self.getImageSizeInBytes(e.loader.resource.url);
            const fileData = self.formatBytes(fileBytes);
            const resource = {
                url: e.loader.resource.url,
                width: image.width,
                height: image.height,
                size: Number(fileData.size),
                format: fileData.format,
            };

            totalBytes += fileBytes;

            if (resource.size >= AdminImageReview.SINGLE_FILE_LIMIT_MB) {
                imageWarnings.push(resource);
            } else {
                imageData.push(resource);
            }
        });

        myPreloader.bind('onAllAssetsLoaded', () => {
            if (imageWarnings.length > 0) {
            }

            const imageSize = `${Number(
                self.formatBytes(totalBytes).size / 1000
            ).toFixed(2)}MB`;
            console.info(imageData);

            imageData = _.sortBy(imageData, 'size').reverse();

            self.setState({ images: imageData });
            self.setState({ totalImageSize: imageSize });
        });

        const courseXMLLoader = new AssetLoader({
            name: 'course',
        });

        courseXMLLoader.bind('onAllAssetsLoaded', function (e) {
            const xmlDoc = e.loader.resource.getXML();

            $(xmlDoc)
                .find('file')
                .each(function () {
                    const file = $(this).attr('href');
                    if (file.includes('.jpg') || file.includes('.png')) {
                        myPreloader.addImage(file);
                    }
                });

            myPreloader.start();
        });

        courseXMLLoader.start();
    }

    getImageSizeInBytes(imgURL) {
        const request = new XMLHttpRequest();
        request.open('HEAD', imgURL, false);
        request.send(null);
        const headerText = request.getAllResponseHeaders();
        const re = /Content\-Length\s*:\s*(\d+)/i;
        re.exec(headerText);
        return parseInt(RegExp.$1);
    }

    formatBytes(bytes) {
        return {
            size: Number(bytes / 1000).toFixed(2),
            format: 'KB',
        };
    }

    sortByWidth() {
        if (this.state.widthSortedAscending === true) {
            // Sort descending
            const sortedDescending = this.state.images.sort(function (a, b) {
                return parseFloat(b.width) - parseFloat(a.width);
            });

            this.setState({ images: sortedDescending });
        } else {
            // Sort ascending
            const sortedAscending = this.state.images.sort(function (a, b) {
                return parseFloat(a.width) - parseFloat(b.width);
            });

            this.setState({ images: sortedAscending });
        }

        this.state.widthSortedAscending = !this.state.widthSortedAscending;
    }

    sortByHeight() {
        if (this.state.heightSortedAscending === true) {
            // Sort descending
            const sortedDescending = this.state.images.sort(function (a, b) {
                return parseFloat(b.height) - parseFloat(a.height);
            });

            this.setState({ images: sortedDescending });
        } else {
            // Sort ascending
            const sortedAscending = this.state.images.sort(function (a, b) {
                return parseFloat(a.height) - parseFloat(b.height);
            });

            this.setState({ images: sortedAscending });
        }

        this.state.heightSortedAscending = !this.state.heightSortedAscending;
    }

    sortBySize() {
        if (this.state.sortedByAscending === true) {
            // Sort descending
            const sortedDescending = this.state.images.sort(function (a, b) {
                return parseFloat(b.size) - parseFloat(a.size);
            });

            this.setState({ images: sortedDescending });
        } else {
            // Sort ascending
            const sortedAscending = this.state.images.sort(function (a, b) {
                return parseFloat(a.size) - parseFloat(b.size);
            });

            this.setState({ images: sortedAscending });
        }

        this.state.sortedByAscending = !this.state.sortedByAscending;
    }

    /**
     * @returns {ReactNode}
     */
    render() {
        const imageReviewHead = (
            <tr className="text-light bg-dark">
                <th className="border-0 text-center">INDEX</th>
                <th className="border-0">URL</th>
                <th className="border-0 text-center">
                    WIDTH(PX)
                    <MdSort
                        className="mx-2"
                        onClick={() => {
                            this.sortByWidth();
                        }}
                    />
                </th>
                <th className="border-0 text-center">
                    HEIGHT(PX)
                    <MdSort
                        className="mx-2"
                        onClick={() => {
                            this.sortByHeight();
                        }}
                    />
                </th>
                <th className="border-0 text-center">
                    SIZE(KB)
                    <MdSort
                        className="mx-2"
                        onClick={() => {
                            this.sortBySize();
                        }}
                    />
                </th>
            </tr>
        );

        const imageReviewBody = this.state.images.map((image, i) => {
            return (
                <tr key={image.url}>
                    <td className="text-center">{i}</td>
                    <td>
                        <a href={image.url} target="_blank" rel="noreferrer">
                            {image.url}
                        </a>
                    </td>
                    <td className="text-center">{image.width}</td>
                    <td className="text-center">{image.height}</td>
                    <td className="text-center">{image.size}</td>
                </tr>
            );
        });

        return (
            <div className="admin">
                <AdminNavBar />
                {/* <Jumbotron className='d-flex justify-content-center align-items-center'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 text-center'>
                                <Images />
                                <h2>Image Review</h2>
                            </div>
                        </div>
                    </div>
                </Jumbotron> */}

                <Card className="m-5">
                    <Card.Body>
                        <Card.Title>
                            <small>TOTAL SIZE OF IMAGES</small>
                            <br />
                            {this.state.totalImageSize}
                        </Card.Title>
                    </Card.Body>
                </Card>
                <div className="m-5">
                    <Table responsive="sm">
                        <thead>{imageReviewHead}</thead>
                        <tbody>{imageReviewBody}</tbody>
                    </Table>
                </div>
            </div>
        );
    }
}

{
    SINGLE_FILE_LIMIT_MB: 0.4;
}

export default AdminImageReview;
