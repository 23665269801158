import _ from 'underscore';
import $ from 'jquery';
import Backbone from 'backbone';
import { Logger } from '../../index';
import Status from '../data/status.js';

/**
 * @class Create a ModuleData of a course
 * @name ModuleData
 *
 * @property {number} id
 * @property {string} moduleID
 * @property {string} moduleTitle
 * @property {string} moduleDescription
 * @property {number} moduleStatus
 * @property {number} totalCompletionRequired
 * @property {string} args
 * @property {Pages} pages
 * @augments Backbone.Model
 *
 * @see Backbone.Model
 */
const ModuleData = Backbone.Model.extend(
    /** @lends ModuleData.prototype */ {
        defaults: {
            id: 0,
            moduleID: '',
            moduleTitle: '',
            moduleDescription: '',
            moduleStatus: 0,
            totalCompletionRequired: 0,
            args: undefined,
            pages: undefined,
        },

        /**
         * Initialize the module data object
         */
        initialize() {
            this.pages = [];
        },

        /**
         * Add a page data into the pages array in course data
         *
         * @param {PageData} page
         */
        addPage(page) {
            this.pages.push(page);
        },

        /**
         * Get a page data of a page from the pages array based on index
         *
         * @param {number} index
         * @returns {pageData}
         */
        getPage(index) {
            return this.pages[index];
        },

        /**
         * Get total pages in a module
         *
         * @returns {number}
         */
        getTotalPages() {
            return this.pages.length;
        },

        /**
         * Get a page before the index passed
         *
         * @param {number} index
         * @returns {PageData}
         */
        getPageBefore(index) {
            if (!index || index < 0) return undefined;
            return this.pages[index - 1];
        },

        /**
         * Get a page after the index passed
         *
         * @param {number} index
         * @returns {PageData}
         */
        getPageAfter(index) {
            if (!index || index > this.pages.length) return undefined;
            return this.pages[index + 1];
        },

        /**
         * Get complete progress of a module
         *
         * @returns {object} (id, completedPages, totalPages, percentage)
         */
        getCompletionProgress(includeJunctionPages = false) {
            let page;
            let completedPages = 0;
            let completionRequiredPages = 0;
            const pageDatas = [];

            const totalPages = this.getTotalPages();

            for (let i = 0; i < totalPages; i++) {
                page = this.getPage(i);
                pageDatas.push(page);
                if (page.getCompletionRequired()) {
                    completionRequiredPages++;
                    if (page.getStatus() >= Status.COMPLETED) {
                        completedPages++;
                    }

                    // Check for junction pages and their completion
                    if (includeJunctionPages && page.getBlock(0).junctionData) {
                        page.getBlock(0).junctionData.pages.map((jPage) => {
                            pageDatas.push(jPage);
                            if (jPage.getCompletionRequired()) {
                                completionRequiredPages++;
                                if (jPage.getStatus() >= Status.COMPLETED) {
                                    completedPages++;
                                }
                            }
                        });
                    }
                }
            }

            const progress = {
                id: this.getID(),
                completedPages,
                totalPages: completionRequiredPages,
                percentage: Math.round(
                    (completedPages / completionRequiredPages) * 100
                ),
                pages: pageDatas,
            };
            return progress;
        },

        /**
         * Get the id of the module
         *
         * @returns {number}
         */
        getID() {
            return this.get('id');
        },

        /**
         * Set the module id of the module
         *
         * @param {string} value
         */
        setModuleID(value) {
            this.set({
                moduleID: value,
            });
        },

        /**
         * Get the module id of the module
         *
         * @returns {string}
         */
        getModuleID() {
            return this.get('moduleID');
        },

        /**
         * Set the module title
         *
         * @param {string} value
         */
        setModuleTitle(value) {
            this.set({
                moduleTitle: value,
            });
        },

        /**
         * Get the module title
         *
         * @returns {string}
         */
        getModuleTitle() {
            return this.get('moduleTitle');
        },

        /**
         * Set the module description
         *
         * @param {string} value
         */
        setModuleDescription(value) {
            this.set({
                moduleDescription: value,
            });
        },

        /**
         * Get the module description
         *
         * @returns {string}
         */
        getModuleDescription() {
            return this.get('moduleDescription');
        },

        /**
         * Set the total completion required for a module
         *
         * @param {number} value
         */
        setTotalCompletionRequired(value) {
            this.set({
                totalCompletionRequired: value,
            });
        },

        /**
         * Get the total completion required for a module
         *
         * @returns {number}
         */
        getTotalCompletionRequired() {
            return this.get('totalCompletionRequired');
        },

        /**
         * Set the arguments of a module
         *
         * @param {string} value
         */
        setArgs(value) {
            this.set({
                args: value,
            });
        },

        /**
         * Get the arguments of a module
         *
         * @returns {string} arguments
         */
        getArgs() {
            return this.get('args');
        },

        /**
         * Get particular argument of a module using its name
         *
         * @param {string} name
         * @returns {string} null if not found | value of particular argument
         */
        getArg(name) {
            const args = this.get('args').split('|');
            let result = null;

            _.each(
                args,
                function (arg) {
                    const parts = arg.split('=');

                    if (parts.length === 2) {
                        if (parts[0] === name) {
                            result = parts[1];
                            return true;
                        }
                    } else {
                        Logger.warn(
                            'Invalid arg for module:',
                            this.getModuleID(),
                            arg
                        );
                    }
                },
                this
            );

            return result;
        },

        /**
         * Reset the following pages from a particular index
         *
         * @param {number} resetFrom
         */
        resetAllPagesFrom(resetFrom) {
            if (isNaN(resetFrom)) {
                resetFrom = Number(resetFrom);
            }

            const self = this;

            $.each(this.pages, function (index, value) {
                if (index > resetFrom) {
                    self.getPage(value).setStatus(Status.STARTED, true);
                }
            });

            /**
             * onReset is fired when page in a module are reset from a particular index
             *
             * @event ModuleData#onReset
             * @type {object}
             * @property {ModuleData} target
             * @property {number} resetFrom
             */
            this.trigger('onReset', {
                target: this,
                resetFrom,
            });
        },

        /**
         * Set status of a module
         *
         * @param {number} status - could be any number from 0 to 7, refer status.js for more details
         * @param {boolean} isForced isForced - true/false to decide setting status forcefully or not
         */
        setModuleStatus(status, isForced) {
            if (isForced) {
                this.set({
                    moduleStatus: status,
                });

                /**
                 * onStatusUpdate is fired when status of a module is updated
                 *
                 * @event ModuleData#onStatusUpdate
                 * @type {object}
                 * @property {ModuleData} target
                 * @property {number} status
                 * @property {boolean} isForced
                 */
                this.trigger('onStatusUpdate', {
                    target: this,
                    status,
                    isForced,
                });
                return;
            }

            if (status > this.get('moduleStatus')) {
                this.set({
                    moduleStatus: status,
                });

                this.trigger('onStatusUpdate', {
                    target: this,
                    status,
                    isForced,
                });
            }
        },

        /**
         * Get the status of the module
         *
         * @returns {number} could be any number from 0 to 7, refer status.js for more details
         */
        getModuleStatus() {
            return this.get('moduleStatus');
        },

        /**
         * Check if module is in progress or not
         *
         * @returns {boolean} true/false based on status of the module
         */
        isModuleInProgress() {
            if (
                this.getModuleStatus() >= Status.STARTED &&
                this.getModuleStatus() != Status.COMPLETED
            ) {
                return true;
            }

            return false;
        },

        /**
         * Returns the pageID of the furthest page reached in the module
         *
         * @returns {string}
         */
        getReturnPoint() {
            let bm = '';

            const pagesCopyReversed = this.pages.slice();
            pagesCopyReversed.reverse();

            _.each(pagesCopyReversed, function (model, i) {
                if (model.getStatus() >= 2 && !bm) {
                    bm = model.pageID();
                }
            });

            return bm;
        },
    }
);

export default ModuleData;
