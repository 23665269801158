import { Button, Card, ProgressBar } from 'react-bootstrap';
import React from 'react';

export function CourseMapCard(props) {
    const { blockRef, module } = props;

    const handleItemClick = (moduleID) => {
        const router = blockRef.junctionController
            ? blockRef.junctionController
            : blockRef.app.session;
        const { source } = module;

        const status = source.getModuleStatus();

        if (
            status === Status.LOCKED ||
            (status === Status.UNAVAILABLE && blockRef.app.isLocked)
        ) {
            return;
        }

        if (status >= Status.STARTED && status < Status.COMPLETED) {
            const firstIncomplete = source.pages.find(
                (page) => page.getStatus() < Status.COMPLETED
            );

            blockRef.app.router.navigate(`#page/${firstIncomplete.pageID()}`, {
                trigger: true,
                replace: true,
            });
        } else {
            router.startModule(moduleID);
        }
    };

    const buttonText = 'Enter';
    const available =
        module.statusCode >= Status.AVAILABLE &&
        module.statusCode < Status.LOCKED;

    const unavailable =
        module.statusCode === Status.LOCKED ||
        (module.statusCode === Status.UNAVAILABLE && blockRef.app.isLocked);

    return (
        <Card
            data-id={module.id}
            className={`d-flex align-items-center mb-0 ${module.moduleStatus}`}
        >
            {module.image && <Card.Img variant="top" src={module.image} />}
            <Card.Body>
                {module.moduleTitle && (
                    <Card.Title>{module.moduleTitle}</Card.Title>
                )}
                {module.moduleStatus && (
                    <Card.Subtitle className="mb-2 text-muted">
                        {module.moduleStatus}
                    </Card.Subtitle>
                )}
                <Button
                    onClick={() => handleItemClick(module.id)}
                    disabled={unavailable}
                >
                    {buttonText}
                </Button>
            </Card.Body>
            {module.percentage !== undefined && (
                <ProgressBar now={module.percentage} />
            )}
        </Card>
    );
}
