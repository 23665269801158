// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".background__78e0f2{--tw-bg-opacity:1;background-color:rgb(248 247 243/var(--tw-bg-opacity))}.ring__dc8ea8{stroke:var(--brand-primary);height:100vh;opacity:.2;position:absolute}", "",{"version":3,"sources":["webpack://./src/modules/BackgroundManager/components/RingBackground/RingBackground.module.css"],"names":[],"mappings":"AACI,oBAAA,iBAAiB,CAAjB,sDAAiB,CAIjB,cAEA,2BAA4B,CAE5B,YAAa,CADb,UAAY,CAHZ,iBAAe","sourcesContent":[".background {\n    @apply bg-brandbg;\n}\n\n.ring {\n    @apply absolute;\n\n    stroke: var(--brand-primary);\n    opacity: 0.2;\n    height: 100vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"background": "background__78e0f2",
	"ring": "ring__dc8ea8"
};
export default ___CSS_LOADER_EXPORT___;
