import { arrow, autoUpdate, flip, offset, shift, useDismiss, useFloating, useFocus, useHover, useInteractions, useRole } from '@floating-ui/react-dom-interactions';
import classNames from 'classnames';
import TweenMax from 'gsap/TweenMax';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from './Avatar.module.css';

/**
 *
 * @param {{
 *  member: import('~m/RoomSync/RoomSync.types').RoomMember,
 *  total?: number,
 *  index?: number,
 *  className?: string,
 *  canAnimate?: boolean,
 *  wrapperClassName?: string,
 * }} props
 * @returns
 */
function Avatar(props) {
    const { total, index, member, className, canAnimate, wrapperClassName } = props;

    if (!member) return null;

    const avatarPath = useMemo(() => {
        const index = typeof member?.avatar === 'string' ? parseInt(member?.avatar) : member?.avatar;

        return isNaN(index)
            ? 'assets/images/placeholder/placeholder.png'
            : `assets/images/avatars/${index}.png`;
    }, [member.avatar]);

    const style = useMemo(() => {
        if (total === undefined || index === undefined) return;

        return { zIndex: total - index };
    }, [total, index]);

    const [open, setOpen] = useState(false);
    const arrowRef = useRef(null);

    const {
        x, y, reference, floating, strategy, context, placement, refs,
        middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
    } = useFloating({
        strategy: 'fixed',
        placement: 'top',
        open,
        onOpenChange: setOpen,
        middleware: [
            offset(8),
            flip(),
            shift({ padding: 8 }),
            arrow({ element: arrowRef }),
        ],
        whileElementsMounted: autoUpdate,
    });

    const { getReferenceProps, getFloatingProps } = useInteractions([
        useHover(context),
        useFocus(context),
        useRole(context, { role: 'tooltip' }),
        useDismiss(context),
    ]);

    const displayName = useMemo(() => [member.firstName, member.lastName].filter(Boolean).join(' '), [member]);

    const staticSide = {
        top: 'bottom',
        right: 'left',
        bottom: 'top',
        left: 'right',
    }[placement.split('-')[0]];

    useEffect(() => {
        if (canAnimate) {
            TweenMax.from(refs.reference.current, 0.5, { scale: '0', x: -20 });
        }
    }, []);

    return (
        <div className={classNames(wrapperClassName, styles.wrapper)}>
            <img
                {...getReferenceProps({
                    ref: reference,
                    className: classNames(styles.image, className, {
                        [styles.tooltipOpen]: open,
                    }),
                    style,
                    src: avatarPath,
                    alt: '',
                })}
            />
            {open && (
                <div
                    {...getFloatingProps({
                        ref: floating,
                        className: styles.tooltip,
                        style: {
                            position: strategy,
                            top: y ?? 0,
                            left: x ?? 0,
                        },
                    })}
                >
                    {displayName
                        ? <span>{displayName}</span>
                        : <span className={styles.noName}>Name unavailable</span>}
                    <div
                        className={styles.arrow}
                        ref={arrowRef}
                        style={{
                            left: arrowX != null ? `${arrowX}px` : '',
                            top: arrowY != null ? `${arrowY}px` : '',
                            right: '',
                            bottom: '',
                            [staticSide]: '-4px',
                        }}
                    />
                </div>
            )}
        </div>
    );
}

Avatar.propTypes = {
    total: PropTypes.number,
    index: PropTypes.number,
    className: PropTypes.string,
    wrapperClassName: PropTypes.string,
    canAnimate: PropTypes.bool,
    member: PropTypes.shape({
        avatar: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        firstName: PropTypes.string,
        lastName: PropTypes.string,
    }),
};

Avatar.defaultProps = {
    total: undefined,
    index: undefined,
    className: undefined,
    wrapperClassName: undefined,
    canAnimate: false,
    member: undefined,
};

export default Avatar;
