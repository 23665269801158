import Backbone from 'backbone';
import { $ } from 'jquery';
import { Logger } from '../../../index';
import { LMS } from './lms.js';

/**
 * @class Stores the course properties locally in cookies when useLocalStorage is set to true
 * @name LocalStorageInterface
 *
 * @property {string} version
 * @property {Window} win
 * @property {LMS} lms
 * @property {boolean} api
 * @property {string} VERSION_1_2
 *
 * @augments Backbone.Model
 *
 * @see Backbone.Model
 */
const LocalStorageInterface = Backbone.Model.extend(
    /** @lends LocalStorageInterface.prototype */ {
        constructor() {
            this.version = null;
            this.win = window;
            this.lms = LMS;
            this.api = false;
            this.VERSION_1_2 = '1.2';

            this.store = window.localStorage;

            Backbone.Model.apply(this, arguments);
        },

        /**
         * Initialize the local storage
         */
        initialize() {
            if (window.localStorage) {
                this.api = true;
                this.version = this.VERSION_1_2;
                this.start();
            } else {
                Logger.warn('Error local storage is not available!');
            }
        },

        start() {},

        /**
         * Get an item being stored in storage
         *
         * @param {string} key
         */
        get(key) {
            const data = this.store.getItem(key);

            if (!data) {
                return '';
            }

            return data;
        },

        /**
         * Set an item and store it in storage
         *
         * @param {string} key
         * @param {string} value
         */
        set(key, value) {
            this.store.setItem(key, value);
        },

        commit() {
            return window.localStorage;
        },

        finish() {},

        findApi(win) {},

        getInfo(errorCode) {},

        getLastErrorCode() {
            // Previous it was logging errors in local browser as it was not returning anything.
            return 0;
        },

        formatTime(milliseconds) {
            return milliseconds;
        },
    }
);

export { LocalStorageInterface };
