import React from 'react';
import { ButtonGroup, ButtonToolbar, Dropdown } from 'react-bootstrap';
import {
    MdClosedCaption,
    MdFullscreen,
    MdFullscreenExit,
    MdPause,
    MdPlayArrow,
    MdVolumeOff,
    MdVolumeUp,
} from 'react-icons/md';
import { IoMdSquareOutline } from 'react-icons/io';
import { TiArrowMinimise } from 'react-icons/ti';
// eslint-disable-next-line import/no-cycle
import { STATUSES } from '../VideoBlock';
import ControlButton from './ControlButton';

/**
 * Separation of video controls
 */
class VideoControls extends React.Component {
    /**
     * @param props
     */
    constructor(props) {
        super(props);
        this.render = this.render.bind(props.video);
        this.labels = this.props.labels;
    }

    /**
     * @returns {import('react').ReactNode}
     */
    render() {
        return (
            <div className="video-controls d-flex w-100">
                <ButtonToolbar style={{ width: '100%' }}>
                    <ButtonGroup>
                        <ControlButton
                            props={this}
                            onClick={this.handlePlayPause}
                            title={
                                this.state.status === STATUSES.Playing
                                    ? this.labels.pause
                                    : this.labels.play
                            }
                            aria-label={
                                this.state.status === STATUSES.Playing
                                    ? this.labels.pause
                                    : this.labels.play
                            }
                        >
                            {this.state.status === STATUSES.Playing ? (
                                <MdPause />
                            ) : (
                                <MdPlayArrow />
                            )}
                        </ControlButton>
                    </ButtonGroup>
                    <ButtonGroup className="video-progress-wrapper">
                        {this.state.duration !== 0 && (
                            <div className="time-display">
                                <span role="timer">
                                    {Utils.secondsToMMSS(
                                        this.state.currentTime
                                    )}{' '}
                                    / {Utils.secondsToMMSS(this.state.duration)}
                                </span>
                            </div>
                        )}
                        <input
                            id="progress-bar"
                            type="range"
                            min="0"
                            max={this.state.duration}
                            value={this.state.currentTime}
                            onChange={(e) => {
                                this.handleOnChange(e);
                            }}
                            step="0.1"
                        />
                    </ButtonGroup>
                    <Dropdown as={ButtonGroup} alignRight drop={'up'}>
                        <ControlButton
                            onClick={this.handleMute}
                            title={
                                this.state.muted === true
                                    ? this.labels.audioOff
                                    : this.labels.audioOn
                            }
                            aria-label={
                                this.state.muted === true
                                    ? this.labels.audioOff
                                    : this.labels.audioOn
                            }
                        >
                            {this.state.muted ? (
                                <MdVolumeOff />
                            ) : (
                                <MdVolumeUp />
                            )}
                        </ControlButton>

                        {this.hasCaptions && (
                            <Dropdown.Toggle
                                as={ControlButton}
                                title={this.labels.caption}
                                aria-label={this.labels.caption}
                            >
                                <MdClosedCaption />
                            </Dropdown.Toggle>
                        )}

                        <Dropdown.Menu>
                            <Dropdown.Item
                                onClick={() => {
                                    this.setState({ track: null });
                                }}
                            >
                                Turn Off
                            </Dropdown.Item>

                            {this.schema.options?.tracks?.map(
                                (track, index) => {
                                    return (
                                        <Dropdown.Item
                                            onClick={() => {
                                                this.setState({ track });
                                            }}
                                            key={index.toString()}
                                        >
                                            {track.label}
                                        </Dropdown.Item>
                                    );
                                }
                            )}
                        </Dropdown.Menu>

                        <ControlButton
                            onClick={this.fullscreen.toggle}
                            title={
                                this.fullscreen.isFullscreen === true
                                    ? this.labels.exitFullScreen
                                    : this.labels.enterFullScreen
                            }
                            aria-label={
                                this.fullscreen.isFullscreen === true
                                    ? this.labels.exitFullScreen
                                    : this.labels.enterFullScreen
                            }
                        >
                            {this.fullscreen.isFullscreen ? (
                                <MdFullscreenExit />
                            ) : (
                                <MdFullscreen />
                            )}
                        </ControlButton>
                    </Dropdown>
                </ButtonToolbar>
            </div>
        );
    }
}

export default VideoControls;
