import { doc } from 'firebase/firestore';
import { useMemo } from 'react';
import useFirebase from './useFirebase';

function useDocumentReference(path) {
    const firebase = useFirebase();
    return useMemo(() => {
        if (!path) return;
        return doc(firebase.fs, path);
    }, [path]);
}

export default useDocumentReference;
