import React, { useState } from 'react';
import { MdChevronLeft, MdChevronRight, MdMenu } from 'react-icons/md';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { RiHome2Fill } from 'react-icons/ri';
import classNames from 'classnames';
import Button from '../../../components/Button';
import { useNavigation } from '../../../components/Navigation/Navigation.hooks';
import ResizableSidebar from '../../../components/ResizableSidebar';
import styles from './RoomManagementPanel.module.css';
import DetailsPanel from './DetailsPanel';
import ParticipantsPanel from './ParticipantsPanel';
import PagesPanel from './PagesPanel';
import { t } from '~templates/utils/i18n';
import { useBlock } from '~templates/components/blocks';

function RoomManagementPanel() {
    const { container } = useBlock();

    const [collapsed, setCollapsed] = useState(false);

    const {
        percentage,
        hideCentreNav,
        isNextDisabled,
        isAudioOn,
        prev,
        next,
    } = useNavigation(container);

    return (
        <ResizableSidebar
            className={classNames(styles.wrapper, {
                [styles.collapsed]: collapsed,
            })}
            collapsed={collapsed}
        >
            <div className={styles.buttonWrapper}>
                <Button
                    className={styles.button}
                    size="xl"
                    icon
                    aria-label={t('toggle')}
                    title={t('toggle')}
                    onClick={() => setCollapsed(!collapsed)}
                ><MdMenu /></Button>
                <Button className={styles.button} size="xl" icon aria-label={t('home')} title={t('home')} href="#page/coursemap"><RiHome2Fill /></Button>
                {!hideCentreNav && (
                    <>
                        <Button className={styles.button} size="xl" icon aria-label={t('previous')} title={t('previous')} onClick={prev}><MdChevronLeft /></Button>
                        <div
                            className={styles.progressWrapper}
                            role="text"
                            aria-label={`${t('progress')}: ${percentage}%`}
                            title={`${t('progress')}: ${percentage}%`}
                        >
                            <CircularProgressbar
                                value={percentage}
                                strokeWidth={12}
                                styles={buildStyles({
                                    strokeLinecap: 'butt',
                                    pathColor: 'white',
                                    trailColor: '#00679B',
                                })}
                            />
                        </div>
                        <Button className={styles.button} size="xl" icon aria-label={t('next')} title={t('next')} onClick={next} disabled={isNextDisabled}><MdChevronRight /></Button>
                    </>
                )}
            </div>
            <div className={styles.contentWrapper}>
                <div>
                    <h2 className={styles.header}>{t('room')}</h2>
                    <DetailsPanel />
                </div>
                <div>
                    <h2 className={styles.header}>{t('pages')}</h2>
                    <PagesPanel />
                </div>
                <div>
                    <h2 className={styles.header}>{t('participants')}</h2>
                    <ParticipantsPanel/>
                </div>
            </div>
        </ResizableSidebar>
    );
}

export default RoomManagementPanel;
