import _ from 'underscore';
import { Status } from '../../../index';
import JunctionController from '../controller/junctioncontroller';

const QuizJunctionController = JunctionController.extend({
    startQuiz() {
        let firstIncompletePage = _.find(this.data.questionPages, (page) => {
            return page.getStatus() < Status.COMPLETED;
        });

        if (!firstIncompletePage)
            firstIncompletePage = this.data.getModule(0).getPage(0);

        this.routeToPage(firstIncompletePage.pageID());

        this.trigger('onQuizStarted', {
            target: this,
        });
    },

    next() {
        if (this.isDestroyed) return false;

        if (this.currentPageData.pageID() === 'start') {
            this.startQuiz();
            return false;
        }
        if (this.currentPageData.pageID() === 'result') {
            this.app.session.next();
            return false;
        }

        if (!this.currentModuleNumber && this.currentModuleNumber !== 0)
            return false;

        const module = this.data.getModule(this.currentModuleNumber);
        const totalPages = module.getTotalPages();

        if (this.currentPageNumber + 1 < totalPages) {
            const page = this.data
                .getModule(this.currentModuleNumber)
                .getPage(this.currentPageNumber + 1);
            this.routeToPage(page.pageID());
        } else {
            this.endModule('next');
        }
    },

    previous() {
        if (this.isDestroyed) return false;

        this.trigger('onQuizEnded', {
            target: this,
        });

        if (
            this.currentPageData.pageID() === 'start' ||
            this.currentPageData.pageID() === 'result' ||
            typeof this.data.startPageData === 'undefined'
        ) {
            this.app.session.previous();
        } else {
            this.routeToStart();
        }
    },

    endModule(direction) {
        const event = {
            module: this.currentModuleNumber,
            pageData: this.currentPageData,
        };

        this.trigger('onModuleEnded', event);
        this.navigateOut(direction);
    },

    navigateOut(direction) {
        let navigatedModuleId;
        if (direction === 'next') {
            navigatedModuleId = this.currentModuleNumber + 1;
        } else {
            navigatedModuleId = this.currentModuleNumber - 1;
        }

        const navigatedModule = this.data.getModule(navigatedModuleId);

        if (navigatedModule) {
            const pageID = navigatedModule.getPage(0).pageID();
            this.routeToPage(pageID);
            return;
        }

        this.trigger('onQuizEnded', {
            target: this,
        });

        if (direction === 'next') {
            this.routeToPage('result');
        } else {
            this.routeToStart();
        }
    },

    routeToStart() {
        if (this.isDestroyed) return false;

        if (typeof this.data.startPageData === 'undefined') {
            this.routeToPage(this.data.firstPageID);
        } else {
            this.routeToPage('start');
        }

        // this.routeToPage('start');
    },
});

export default QuizJunctionController;
