import LMSAdaptor from '../../tracking/adaptors/lmsadaptor.js';

const JunctionTracking = LMSAdaptor.extend({
    createListeners() {
        this.data.pages.each(function (page) {
            this.addListenersToPage(page);
        }, this);
    },
});

export default JunctionTracking;
