import Backbone from 'backbone';
import LZString from 'lz-string';
import Timer from '../../utils/timer/timer.js';
import { SuspendDataItems } from './suspenddataitems.js';
import { CmiDataItems } from './cmidataitems.js';
import { CourseProperties } from './courseproperties.js';
import { ScormInterface } from './scorminterface.js';
import { LocalStorageInterface } from './localstorageinterface.js';
import { CMI } from './cmi.js';
import { InteractionProperties } from './interactionproperties.js';

/**
 * @class To track the progress of the course and set properties on LMS/Storage
 * @name Tracking
 *
 * @property {boolean} useLocalStorage
 *
 * @augments Backbone.Model
 *
 * @see Backbone.Model
 */
const Tracking = Backbone.Model.extend(
    /** @lends Tracking.prototype */ {
        constructor() {
            this.useLocalStorage = false;

            Backbone.Model.apply(this, arguments);
        },

        /**
         * Initialize the tracking
         */
        initialize() {
            /* Need a conditional fallback option for loading SCORM/LocalStorage/AICC
             * format as "SCORM, LOCAL"
             * Set the local storage fallback if supplied in the constructor
             */

            this.useCompression = this.attributes.useCompression === true;
            this.useLocalStorage = this.attributes.useLocalStorage === true;

            this.courseProperties = new CourseProperties();
            this.scorm = new ScormInterface();

            this.timer = new Timer();

            this.scorm.bind(
                'onScormInitSuccess',
                this.handleScormInitSuccess,
                this
            );

            this.scorm.bind(
                'onScormInitFail',
                function (e) {
                    // Using local storage so don't fire the failure event
                    if (this.useLocalStorage) {
                        this.handleScormInitSuccess(e);
                    } else {
                        /**
                         * onInitFail is fired when SCORM API is not found
                         *
                         * @event Tracking#onInitFail
                         * @type {object}
                         * @property {Tracking} target
                         */
                        this.trigger('onInitFail', {
                            target: this,
                            version: e.version,
                        });
                    }
                },
                this
            );

            this.courseProperties.bind(
                'onSetSuspendData',
                function (item) {
                    // This is the model for the updated suspend-date item.
                    // this.scorm.set(this.cmi.labels["SUSPEND_DATA"], this.courseProperties.getDataAsString());
                },
                this
            );

            this.courseProperties.bind(
                'onSetCmiData',
                function (item) {
                    // This is the model for the updated suspend-date item.
                    this.scorm.set(item.key, item.value);
                },
                this
            );
        },

        /** Handle when SCORM API is found
         *
         * @param {Object} e
         */
        handleScormInitSuccess(e) {
            const version = e.version;
            if (this.useLocalStorage) {
                this.scorm = new LocalStorageInterface();
            }

            this.cmi = new CMI(version);

            this.load();

            this.timer.start();
            this.assignPropertiesIfFirstRun();
            this.setCmiData('EXIT', 'suspend');

            this.interactionProperties = new InteractionProperties({
                scorm: this.scorm,
            });

            /**
             * onInitComplete is fired when SCORM is successfully initialized
             *
             * @event Tracking#onInitComplete
             * @type {object}
             * @property {Tracking} target
             * @property {string} version
             * @property {boolean} useLocalStorage
             */
            this.trigger('onInitComplete', {
                target: this,
                version,
                useLocalStorage: this.useLocalStorage,
            });
        },

        /**
         * Assign the initial properties if the current session is first session
         */
        assignPropertiesIfFirstRun() {
            const lessonStatus = this.courseProperties.getCmiData(
                this.cmi.labels.LESSON_STATUS
            );

            // Update the lesson status if this is the first launch of the content
            if (
                lessonStatus == 'not attempted' ||
                lessonStatus == 'unknown' ||
                lessonStatus == undefined
            ) {
                this.setCmiData('LESSON_STATUS', 'incomplete');
            }
        },

        /**
         * Get an property value from course properties
         * @param {string} label
         */
        getItem(label) {
            if (this.scorm.api) {
                return this.courseProperties.getItem(label);
            }
        },

        /**
         * Set a property value to store
         * @param {string} label
         * @param {string} value
         */
        setItem(label, value) {
            if (this.scorm.api) {
                this.courseProperties.setItem(label, value);
            }
        },

        /**
         * Delete an item
         *
         * @param {string} label
         */
        deleteItem(label) {
            if (this.scorm.api) {
                this.courseProperties.deleteItem(label);
            }
        },

        /**
         * Delete all items from the suspend data
         *
         * @param {string} label
         */
        deleteAllItems(label) {
            if (this.scorm.api) {
                this.courseProperties.clearSuspendData();
            }
        },

        /**
         * Get CMI data item
         *
         * @param {string} label
         */
        getCmiData(label) {
            if (this.scorm.api) {
                return this.courseProperties.getCmiData(this.cmi.labels[label]);
            }
        },

        /**
         * Set CMI data item
         *
         * @param {string} label
         * @param {string} value
         */
        setCmiData(label, value) {
            if (this.scorm.api) {
                this.courseProperties.setCmiData(
                    label,
                    this.cmi.labels[label],
                    value
                );
            }
        },
        /**
         * Delete all the CMI data items
         *
         * @param {string} label
         */
        deleteAllCmiItems(label) {
            if (this.scorm.api) {
                this.courseProperties.clearCmiData();
            }
        },

        /**
         * Set an interaction based on values set in data
         *
         * @param {object} data
         */
        setInteraction(data) {
            if (this.scorm.api) {
                this.interactionProperties.setInteraction(data);
            }
        },

        /**
         * Start the scorm communication
         */
        start() {
            this.scorm.start();

            /**
             * onTrackingStart is fired when SCORM communication is started
             *
             * @event Tracking#onTrackingStart
             * @type {object}
             * @property {Tracking} target
             */
            this.trigger('onTrackingStart', {
                target: this,
            });
        },

        /**
         * Save the progress of the course
         */
        save() {
            const time = this.timer.getTime();
            const formattedTime = this.scorm.formatTime(time);

            this.setCmiData('SESSION_TIME', formattedTime);

            this.setItem('lastSave', Date.now());

            /**
             * onBeforeSave is fired before the current progress is being saved
             *
             * @event Tracking#onBeforeSave
             * @type {object}
             * @property {Tracking} target
             */
            this.trigger('onBeforeSave', {
                target: this,
            });

            // Create the data string based on the [this.useCompression] variable
            const data = this.useCompression
                ? LZString.compressToUTF16(
                      this.courseProperties.getDataAsString()
                  )
                : this.courseProperties.getDataAsString();

            this.scorm.set(this.cmi.labels.SUSPEND_DATA, data);

            if (this.scorm.commit()) {
                /**
                 * onSaveSuccess is fired when current progress is saved
                 *
                 * @event Tracking#onSaveSuccess
                 * @type {object}
                 * @property {Tracking} target
                 */
                this.trigger('onSaveSuccess', {
                    target: this,
                });
            } else {
                /**
                 * onSaveFail is fired when current progress is not saved
                 *
                 * @event Tracking#onSaveFail
                 * @type {object}
                 * @property {Tracking} target
                 */
                this.trigger('onSaveFail', {
                    target: this,
                });
            }
        },

        /**
         * Load CMI and suspend data items
         */
        load() {
            for (const i in this.cmi.labels) {
                this.courseProperties.setCmiData(
                    i,
                    this.cmi.labels[i],
                    this.scorm.get(this.cmi.labels[i])
                );
            }

            // if interaction count > 0... add to course properties

            const suspendData = this.scorm.get(this.cmi.labels.SUSPEND_DATA);

            // Extract the data string based on the [this.useCompression] variable
            const data = this.useCompression
                ? LZString.decompressFromUTF16(suspendData)
                : suspendData;

            this.courseProperties.extractDataAsString(data);
        },

        // [MS]: Not sure if below function is required any more
        reset() {
            // this.setCmiData("SUSPEND_DATA", " ");
            // this.setCmiData("LESSON_STATUS", "incomplete");
        },

        /**
         * Clear the progress of the course
         */
        clear() {
            this.deleteAllCmiItems();
            this.deleteAllItems();
            /**
             * onClear is fired when all CMI and suspend data items are deleted
             *
             * @event Tracking#onClear
             * @type {object}
             * @property {Tracking} target
             */
            this.trigger('onClear', {
                target: this,
            });
        },

        /**
         * Save the progress and end the communication before window unload
         */
        finish() {
            this.save();
            this.scorm.set(this.cmi.labels.EXIT, 'suspend'); // Setting cmi.exit to “suspend” will ensure that the current attempt is preserved and the run-time data is not reset the next time the SCO is launched.
            this.scorm.finish();
            /**
             * onTrackingFinish is fired when communication with SCORM is suspended
             *
             * @event Tracking#onTrackingFinish
             * @type {object}
             * @property {Tracking} target
             */
            this.trigger('onTrackingFinish', {
                target: this,
            });
        },

        /**
         * Get the properties from suspend data
         */
        getDataAsString() {
            return this.courseProperties.getDataAsString();
        },

        /**
         * Get the properties from CMI
         */
        getCmiDataAsString() {
            return this.courseProperties.getCmiDataAsString();
        },

        /**
         * extract the data as string from course properties
         */
        extractDataAsString() {
            return this.courseProperties.extractDataAsString();
        },
    }
);

export default Tracking;
