import React from 'react';
import { Button } from 'react-bootstrap';

/**
 * Create Control button function separately
 */
function ControlButton({ children, onClick, title, ariaLabel }, ref) {
    return (
        <Button
            ref={ref}
            title={title}
            aria-label={ariaLabel}
            size="sm"
            variant="light"
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
        </Button>
    );
}

const forwardControlButton = React.forwardRef(ControlButton);

export default forwardControlButton;
