import { useRef, useState } from 'react';

export const useTimer = (initialState = 0) => {
    const [timer, setTimer] = useState(initialState);
    const [isActive, setIsActive] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const countRef = useRef(null);

    const divide = 10;
    const increment = 1 / divide;

    const handleStart = () => {
        setIsActive(true);
        setIsPaused(true);
        countRef.current = setInterval(
            () => setTimer((t) => t + increment),
            1000 / divide
        );
    };

    const handlePause = () => {
        clearInterval(countRef.current);
        setIsPaused(false);
    };

    const handleResume = () => {
        setIsPaused(true);
        countRef.current = setInterval(
            () => setTimer((t) => t + increment),
            1000 / divide
        );
    };

    const handleReset = () => {
        clearInterval(countRef.current);
        setIsActive(false);
        setIsPaused(false);
        setTimer(0);
    };

    return {
        timer,
        isActive,
        isPaused,
        handleStart,
        handlePause,
        handleResume,
        handleReset,
    };
};

export const makeDefaultObj = (from, of) => {
    const entries = from.map((item, index) => [index, of]);
    return Object.fromEntries(entries);
};
