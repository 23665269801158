import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import {
    Accordion,
    Button,
    Card,
    Col,
    Container,
    Image,
    Row,
} from 'react-bootstrap';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import Block from '~blocks/base/Block';
import BuildAnimation from '~components/animations/BuildAnimation';
import './Accordion.scss';

/**
 * @extends Block
 * @memberOf ReactBlocks
 */
class AccordionBlock extends Block {
    /** @type {import('./accordion-types').AccordionSchema} */
    data = this.props.blockRef?.data;

    /**
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            active: null,
        };
        this.element = React.createRef();
    }

    componentDidMount() {
        BuildAnimation.animateIn(this.element.current);
    }

    /**
     * @returns {ReactNode}
     */
    render() {
        return (
            <Container ref={this.element}>
                <Accordion className="accordion-block">
                    {this.data.items.map((item, i) => (
                        <Card key={i.toString()}>
                            <Card.Header className="p-0">
                                <Accordion.Toggle
                                    as={Button}
                                    className={`d-flex align-items-center text-left w-100 ${
                                        this.state.active === i ? 'active ' : ''
                                    }${item.isViewed === true ? 'viewed' : ''}`}
                                    eventKey={`accordion-item-${i}`}
                                    variant=""
                                    tabIndex="0"
                                    onClick={() => this.handleHeaderClick(i)}
                                >
                                    <h3 className="w-100">{item.heading}</h3>
                                    {this.state.active === i ? (
                                        <MdKeyboardArrowUp />
                                    ) : (
                                        <MdKeyboardArrowDown />
                                    )}
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse
                                eventKey={`accordion-item-${i}`}
                            >
                                <Card.Body>
                                    <Row>
                                        <Col md={item.image ? 6 : 12}>
                                            {ReactHtmlParser(item.content)}
                                        </Col>

                                        {item.image && (
                                            <Col md={6}>
                                                <Image
                                                    className="w-100"
                                                    src={item.image}
                                                    alt={item.imageDescription}
                                                />
                                            </Col>
                                        )}
                                    </Row>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    ))}
                </Accordion>
            </Container>
        );
    }

    /**
     * Set active State value
     * Set isViewed value if it's not true
     */
    handleHeaderClick(i) {
        this.setState({
            active: this.state.active === i ? null : i,
        });
        if (this.data.items[i].isViewed) return;
        this.data.items[i].isViewed = true;
        this.checkPageCompletion();
    }

    /**
     * @return {number}
     */
    getViewedCount() {
        return this.data.items.reduce((carry, item) => {
            return item.isViewed ? carry + 1 : carry;
        }, 0);
    }

    /**
     * Check page status and complete it if all tabs are viewed.
     */
    checkPageCompletion() {
        if (this.data.items.length == this.getViewedCount()) {
            this.props.blockRef.complete();
        }
    }
}

export default AccordionBlock;
