import { useMemo } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { useObject } from 'react-firebase-hooks/database';
import { ConnectionStatus, FirebasePaths } from '../RoomSync.contants';
import { statusToText } from '../RoomSync.utils';
import useRoomSync from './useRoomSync';
import { useCollectionReference, useRealtimeReference } from '~m/Firebase';
/** @typedef {import('react-firebase-hooks/firestore').CollectionHook<T>} CollectionHook @template T */
/** @typedef {import('../RoomSync.types').User} User */
/** @typedef {import('../RoomSync.types').UseRoomMembersReturn} UseRoomMembersReturn */

/**
 * @returns {UseRoomMembersReturn}
 */
function useRoomMembers() {
    const roomSync = useRoomSync();
    const id = useMemo(() => roomSync.roomId, [roomSync.roomId]);

    const statusReference = useRealtimeReference(FirebasePaths.ROOM_MEMBER_STATUSES(id));
    const [statusSnapshot] = useObject(statusReference);

    const membersReference = useCollectionReference(FirebasePaths.ROOM_MEMBERS(id));
    /** @type {CollectionHook<User>} */
    // @ts-expect-error types are correct
    const [membersSnapshot] = useCollection(membersReference);

    return useMemo(() => {
        const getStatus = docId => statusSnapshot?.val()?.[docId] ?? ConnectionStatus.UNKNOWN;

        return membersSnapshot?.docs.map(doc => ({
            ...doc.data(),
            id: doc.id,
            displayName: [doc.data().firstName, doc.data().lastName].filter(Boolean).join(' '),
            connectionStatus: getStatus(doc.id),
            connectionStatusText: statusToText(getStatus(doc.id)),
        })) ?? [];
    }, [membersSnapshot, statusSnapshot]);
}

export default useRoomMembers;
