import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import MessageIdentifier from './components/MessageIdentifier';

function Message(props) {
    const { content, id, sender, isLocked, variant } = props;

    return (
        <Alert variant={variant}>
            <div className="header d-flex justify-content-between">
                <div className="sender-name font-weight-bold">
                    {sender.name}
                </div>
                <MessageIdentifier isLocked={isLocked} id={id} />
            </div>
            <div className="text">{ReactHtmlParser(content)}</div>
        </Alert>
    );
}

Message.propTypes = {
    content: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    sender: PropTypes.objectOf(PropTypes.any).isRequired,
    isLocked: PropTypes.bool.isRequired,
    variant: PropTypes.string,
};

Message.defaultProps = {
    variant: undefined,
};

export default Message;
