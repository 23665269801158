import { ProgressBar } from 'react-bootstrap';
import React from 'react';
import PropTypes from 'prop-types';
import './InterludeProgressBars.scss';

function InterludeProgressBars(props) {
    const {
        slides,
        slideLoading,
        slideProgress,
        slideError,
        currentSlide,
        onBarClick,
    } = props;

    return (
        <div className="interlude-block-progression">
            {slides.map((_slide, index) => {
                const error = slideError[index];
                const loading = slideLoading[index];
                let progress = slideProgress[index];
                let className = 'no-animate';

                if (currentSlide > index) {
                    progress = 100;
                }

                if (currentSlide < index) {
                    progress = 0;
                }

                if (progress > 1 && progress < 100) {
                    className = 'animate';
                }

                // eslint-disable-next-line no-nested-ternary
                const variant = error ? 'danger' : loading ? 'primary' : '';
                const animated = loading && !error;
                const now = loading ? 100 : progress;

                return (
                    // eslint-disable-next-line react/no-array-index-key
                    <a key={index.toString()} onClick={() => onBarClick(index)}>
                        <ProgressBar
                            className={className}
                            variant={variant}
                            now={now}
                            max={100}
                            animated={animated}
                            striped={error}
                        />
                        <div className="interlude-block-progression-overlay">
                            {/* {error && ( */}
                            {/*     <Alert variant='danger'> */}
                            {/*         <MdWarning /> */}
                            {/*     </Alert> */}
                            {/* )} */}
                        </div>
                    </a>
                );
            })}
        </div>
    );
}

InterludeProgressBars.propTypes = {
    slides: PropTypes.arrayOf(PropTypes.any).isRequired,
    slideLoading: PropTypes.objectOf(PropTypes.any).isRequired,
    slideProgress: PropTypes.objectOf(PropTypes.any).isRequired,
    slideError: PropTypes.objectOf(PropTypes.any).isRequired,
    currentSlide: PropTypes.number.isRequired,
    onBarClick: PropTypes.func.isRequired,
};

export default InterludeProgressBars;
