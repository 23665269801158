import React, { Component } from 'react';
import classNames from 'classnames';

const getClasses = ({ height = 'auto', width = 'contained' } = {}) => {
    const heightOptions = {
        auto: 'block-height-auto',
        screen: 'block-height-screen',
        viewport: 'block-height-viewport',
    };

    const widthOptions = {
        contained: 'container',
        screen: 'block-width-screen',
        fluid: 'container-fluid',
    };

    return [
        heightOptions[height] ?? heightOptions.auto,
        widthOptions[width] ?? widthOptions.contained,
    ];
};

/**
 * Handle wrapping a block based on its fluid state
 * Fluid blocks will have no border while non-fluid blocks are wrapped in a card
 * @property {Block} ctx - The context that this wrapper exists in. The context
 * will be used for getting the fluid state if it is not specified
 * @property {boolean} fluid - Override the current fluid state of the block
 * wrapper
 */
class BlockWrapper extends Component {
    render() {
        let { children, fluid, ctx, className } = this.props;
        const options = ctx.props?.blockRef?.data?.options ?? {};

        if (fluid === undefined) {
            fluid = ctx?.state?.isFluid ?? false;
        }

        // Temp. If the template is set to fluid, override the height and width
        // to emulate the old fluid. This doesn't deal with the top padding on
        // the first block and also need the block styles to be tweaked to use
        // the 'block-height-viewport' class instead of 'fluid'
        const dimensions = options.fluid
            ? { height: 'viewport', width: 'screen' }
            : options;

        const classes = classNames(className, getClasses(dimensions), {
            card: fluid,
        });

        // Logger.info('BlockWrapper.render', this.props, fluid);
        return <div className={classes}>{children}</div>;
    }
}

export default BlockWrapper;
