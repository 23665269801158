// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".body__db5e38{display:flex}.wrapper__aa628f{display:contents}", "",{"version":3,"sources":["webpack://./src/modules/RoomSync/managers/InstructorSyncManager.module.css"],"names":[],"mappings":"AACI,cAAA,YAAW,CAIX,iBAAA,gBAAe","sourcesContent":[".body {\n    @apply flex;\n}\n\n.wrapper {\n    @apply contents;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "body__db5e38",
	"wrapper": "wrapper__aa628f"
};
export default ___CSS_LOADER_EXPORT___;
