import { Button, Card, ProgressBar } from 'react-bootstrap';
import React from 'react';
import { MdCheck, MdLockOpen, MdLockOutline } from 'react-icons/md';
import PropTypes from 'prop-types';
import { Status } from '~core';
import { t } from '~utils/i18n';

/**
 * @param props
 */
function CourseMapCard(props) {
    const { blockRef, module, elRef } = props;

    const handleItemClick = (moduleID) => {
        const router = blockRef.junctionController
            ? blockRef.junctionController
            : blockRef.app.session;
        const { source } = module;

        const status = source.getModuleStatus();

        if (
            status === Status.LOCKED ||
            (status === Status.UNAVAILABLE && blockRef.app.isLocked)
        ) {
            return;
        }

        if (status >= Status.STARTED && status < Status.COMPLETED) {
            const firstIncomplete = source.pages.find(
                (page) => page.getStatus() < Status.COMPLETED
            );

            blockRef.app.router.navigate(`#page/${firstIncomplete.pageID()}`, {
                trigger: true,
                replace: true,
            });
        } else {
            router.startModule(moduleID);
        }
    };

    const title = module.title || module.moduleTitle;

    const available =
        module.statusCode >= Status.AVAILABLE &&
        module.statusCode <= Status.STARTED;
    const completed = module.statusCode >= Status.COMPLETED;
    const locked = !available && !completed;
    const clickable = !blockRef.app.isLocked || !locked;

    // [MS]: button labels need to be externalized
    const buttonText = {
        [Status.UNAVAILABLE]: t('unavailable'),
        [Status.AVAILABLE]: t('not_started'),
        [Status.STARTED]: t('inprogess'),
        [Status.COMPLETED]: t('completed'),
        [Status.FAILED]: t('failed'),
        [Status.PASSED]: t('passed'),
        [Status.OPTIONAL]: t('optional'),
        [Status.LOCKED]: t('locked'),
    }[module.statusCode];

    const icon = {
        [Status.UNAVAILABLE]: <MdLockOutline />,
        [Status.AVAILABLE]: <MdLockOpen />,
        [Status.STARTED]: <MdLockOpen />,
        [Status.COMPLETED]: <MdCheck />,
        [Status.FAILED]: <MdCheck />,
        [Status.PASSED]: <MdCheck />,
        [Status.OPTIONAL]: <MdCheck />,
        [Status.LOCKED]: <MdLockOutline />,
    }[module.statusCode];

    return (
        <Card
            data-id={module.id}
            className="d-flex align-items-center mb-0"
            ref={elRef}
        >
            <div className="course-map-unit-header">
                {module.image && <Card.Img variant="top" src={module.image} />}
                <div className="course-map-unit-status">{icon}</div>
            </div>
            <Card.Body>
                {title && <h2 className="mb-2">{title}</h2>}
                {module.moduleDescription && <p>{module.moduleDescription}</p>}
                <div className="course-map-actions">
                    <Button
                        onClick={() => handleItemClick(module.id)}
                        disabled={!clickable}
                        className={{ 'btn-arrow-right': !locked }}
                    >
                        {buttonText}
                    </Button>
                </div>
            </Card.Body>
            {module.percentage !== undefined && (
                <ProgressBar
                    title={`${module.percentage}%`}
                    now={module.percentage}
                />
            )}
        </Card>
    );
}

CourseMapCard.propTypes = {
    blockRef: PropTypes.objectOf(PropTypes.any).isRequired,
    module: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default React.forwardRef((props, ref) => (
    <CourseMapCard elRef={ref} {...props} />
));
