import Backbone from 'backbone';

const Timer = Backbone.Model.extend(
    {
        constructor() {
            this.startTime = null;
            this.endTime = null;
            this.ticker = undefined;
            this.isTimerRunning = false;
            this.isTimerPaused = false;
            this.timerInterval = 90 * 1000;
            this.duration = undefined;
            this.isFinsihed = false;

            Backbone.Model.apply(this, arguments);
        },

        initialize() {
            if (this.attributes.timerInterval)
                this.timerInterval = this.attributes.timerInterval * 1000;
            if (this.attributes.duration)
                this.duration = this.attributes.duration;
        },

        start() {
            this.isTimerRunning = true;
            this.isTimerPaused = false;
            this.startTime = this.getCurrentTime();
            this.endTime = 0;
            this.startTicker();
            this.timerCount = 0;
            this.expected = Date.now() + this.timerInterval;
            this.isFinished = false;
        },

        startTicker() {
            const self = this;
            this.ticker = setTimeout(
                function () {
                    self.step(self);
                },
                this.timerInterval,
                this
            );
        },

        step(self) {
            const dt = Date.now() - self.expected; // the drift (positive for overshooting)
            self.expected += self.timerInterval;

            if (self.isTimerRunning) {
                self.trigger('onTimerTick', {
                    target: self,
                    count: self.timerCount,
                    duration: self.duration,
                });
            }

            if (self.duration !== undefined) {
                if (self.timerCount >= self.duration) {
                    self.duration = 0;

                    self.stop();
                    return;
                }
            }

            self.timerCount++;

            self.ticker = setTimeout(
                function () {
                    self.step(self);
                },
                Math.max(0, self.timerInterval - dt),
                self
            ); // take into account drift
        },

        stopTicker() {
            clearTimeout(this.ticker);

            if (this.isTimerRunning) {
                this.isTimerRunning = false;

                this.trigger('onTimerFinished');

                if (this.timerCount >= this.duration)
                    this.trigger('onTimerCompleted');
            }
        },

        stop() {
            this.stopTicker();

            this.endTime = this.getCurrentTime();
            this.isFinsihed = true;
        },

        pause() {
            this.remainingTime = this.duration - this.timerCount;

            this.stopTicker();
            this.isTimerPaused = true;
        },

        resume() {
            this.duration = this.remainingTime;

            this.start();
        },

        getCurrentTime() {
            return Date.now();
        },

        getStartTime() {
            return this.startTime;
        },

        getEndTime() {
            return this.endTime;
        },

        getTime() {
            if (!this.startTime) return 0;

            const end = this.isFinished ? this.endTime : this.getCurrentTime();
            const difference = end - this.startTime;

            return difference > 0 ? difference : 0;
        },
    },
    {
        // @TODO Depricated
        FORMAT_TIME(time) {
            let minutes = '00';
            let seconds = time % 60;
            time -= seconds;
            const tmp = time % 3600;
            time -= tmp;

            if (tmp % 60 === 0) minutes = (tmp / 60).toString();

            if (minutes.toString().length < 2) minutes = `0${minutes}`;

            if (seconds.toString().length < 2) seconds = `0${seconds}`;

            return `${minutes}:${seconds}`;
        },
    }
);

export default Timer;
