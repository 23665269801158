export const getData = (blockRef) => {
    const { data } = blockRef;
    data.isSingleSelect = true;
    const correctOptions = [];

    data.options.items.forEach((item) => {
        item.isSelected = false;
        if (item.isCorrect) {
            correctOptions.push(item);
        }
    });

    if (correctOptions.length > 1) {
        data.isSingleSelect = false;
    }
    data.correctOptions = correctOptions;

    const initialOptions = data.options.items
        .map((option, index) => ({ id: index, ...option }));

    return { data, initialOptions };
};
