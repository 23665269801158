import Backbone from 'backbone';
import ModuleData from './moduledata.js';

/**
 * @class Modules
 * @name Modules
 * @augments Backbone.Collection
 *
 * @see Backbone.Collection
 */
const Modules = Backbone.Collection.extend(
    /** @lends Modules.prototype */ {
        /** @instance */
        model: ModuleData,
    }
);

export { Modules };
