import _ from 'underscore';
import Backbone from 'backbone';
import { SuspendDataItem, SuspendDataItems } from './suspenddataitems.js';
import { CmiDataItem, CmiDataItems } from './cmidataitems.js';
import { Splitter } from './splitter.js';
/**
 * @class This is to hold the course properties
 * @name CourseProperties
 *
 * @property {SuspendDataItem} suspendData
 * @property {CmiDataItem} cmi
 *
 * @augments Backbone.Model
 *
 * @see Backbone.Model
 */
const CourseProperties = Backbone.Model.extend(
    /** @lends CourseProperties.prototype */ {
        constructor() {
            this.suspendData = new SuspendDataItems();
            this.cmi = new CmiDataItems();

            Backbone.Model.apply(this, arguments);
        },

        /**
         * Get an item based on the key from suspend data
         *
         * @param {string} key
         */
        getItem(key) {
            const items = this.suspendData.pluck('key');
            const itemNumber = _.indexOf(items, key);
            const model = this.suspendData.at(itemNumber);

            if (itemNumber > -1) {
                return this.suspendData.at(itemNumber).get('data');
            } else {
                return -1;
            }
        },

        /**
         * Set an item in suspend data
         *
         * @param {string} key
         * @param {string} value
         */
        setItem(key, value) {
            value = String(value);

            if (key.length > 0 && value.length > 0) {
                const items = this.suspendData.pluck('key');
                const itemNumber = _.indexOf(items, key);

                if (itemNumber >= 0) {
                    const model = this.suspendData.at(itemNumber);
                    model.set({
                        data: value,
                    });
                    /**
                     * onSetSuspendDataUpdated is fired when an item is updated in suspend data
                     *
                     * @event CourseProperties#onSetSuspendDataUpdated
                     * @type {object}
                     * @property {CourseProperties} target
                     * @property {string} key
                     * @property {string} value
                     */
                    this.trigger('onSetSuspendDataUpdated', {
                        target: this,
                        key,
                        value,
                    });
                } else {
                    this.suspendData.add(
                        new SuspendDataItem({
                            key,
                            data: value,
                        })
                    );
                    /**
                     * onSetSuspendDataAdded is fired when a new item is added in suspend data
                     *
                     * @event CourseProperties#onSetSuspendDataAdded
                     * @type {object}
                     * @property {CourseProperties} target
                     * @property {string} key
                     * @property {string} value
                     */
                    this.trigger('onSetSuspendDataAdded', {
                        target: this,
                        key,
                        value,
                    });
                }
                /**
                 * onSetSuspendData is fired when a new item is added or an item is updated in suspend data
                 *
                 * @event CourseProperties#onSetSuspendData
                 * @type {object}
                 * @property {CourseProperties} target
                 * @property {string} key
                 * @property {string} value
                 */
                this.trigger('onSetSuspendData', {
                    target: this,
                    key,
                    value,
                });
            }
        },

        /**
         * Delete an item from suspend data
         *
         * @param {string} key
         */
        deleteItem(key) {
            if (key.length > 0) {
                const items = this.suspendData.pluck('key');
                const itemNumber = _.indexOf(items, key);

                if (itemNumber >= 0) {
                    const model = this.suspendData.at(itemNumber);

                    this.suspendData.remove(model);
                    /**
                     * onDeleteSuspendData is fired when an item is deleted from suspend data
                     *
                     * @event CourseProperties#onDeleteSuspendData
                     * @type {object}
                     * @property {CourseProperties} target
                     * @property {string} key
                     * @property {string} value
                     */
                    this.trigger('onDeleteSuspendData', {
                        target: this,
                        key: model.get('key'),
                        value: model.get('data'),
                    });
                }
            }
        },

        /**
         *  Get CMI data based on the key
         *
         * @param {string} key
         */
        getCmiData(key) {
            const items = this.cmi.pluck('key');
            const itemNumber = _.indexOf(items, key);

            if (itemNumber === -1) return undefined;

            const model = this.cmi.at(itemNumber);

            return model.get('data');
        },

        /**
         * Set a CMI data item
         *
         * @param {string} label
         * @param {string} key
         * @param {string} value
         */
        setCmiData(label, key, value) {
            if (key && key.length > 0 && value.length > 0) {
                const items = this.cmi.pluck('key');
                const itemNumber = _.indexOf(items, key);

                if (itemNumber >= 0) {
                    const model = this.cmi.at(itemNumber);
                    model.set({
                        data: value,
                    });
                } else {
                    this.cmi.add(
                        new CmiDataItem({
                            label,
                            key,
                            data: value,
                        })
                    );
                }

                /**
                 * onSetCmiData is fired when a CMI item is added or updated
                 *
                 * @event CourseProperties#onSetCmiData
                 * @type {object}
                 * @property {string} label
                 * @property {string} key
                 * @property {string} value
                 */
                this.trigger('onSetCmiData', {
                    target: this,
                    label,
                    key,
                    value,
                });
            }
        },

        /**
         * Clear all CMI data items
         */
        clearCmiData() {
            this.cmi.reset();
            /**
             * onClearCmiData is fired when all CMI data is cleared
             *
             * @event CourseProperties#onClearCmiData
             * @type {object}
             * @property {CourseProperties} target
             */
            this.trigger('onClearCmiData', {
                target: this,
            });
        },

        /**
         * Clear suspend data
         */
        clearSuspendData() {
            this.suspendData.reset();
            /**
             * onClearSuspendData is fired when all suspend data is cleared
             *
             * @event CourseProperties#onClearSuspendData
             * @type {object}
             * @property {CourseProperties} target
             */
            this.trigger('onClearSuspendData', {
                target: this,
            });
        },

        /**
         * Get all the CMI dta items as single string
         */
        getCmiDataAsString() {
            const a = [];

            this.cmi.each(function (item) {
                const pair =
                    item.get('label') + Splitter.COLONS + item.get('data');

                a.push(pair);
            });

            return a.join(Splitter.HASHES);
        },

        /**
         * Get suspend data items as a single string
         */
        getDataAsString() {
            const a = [];

            this.suspendData.each(function (item) {
                const pair =
                    item.get('key') + Splitter.EQUALES + item.get('data');

                a.push(pair);
            });

            return a.join(Splitter.PIPES);
        },

        /**
         * Extract suspend data items from a string
         *
         * @param {string} s
         */
        extractDataAsString(s) {
            if (!s) return;

            const pairs = s.split(Splitter.PIPES);
            let item;

            if (pairs.length > 0) {
                for (let i = 0; i < pairs.length; i++) {
                    item = String(pairs[i]).split(Splitter.EQUALES);

                    const key = String(item[0]);
                    const value = String(item[1]);

                    if (key.length > 0 && value.length > 0) {
                        this.suspendData.add(
                            new SuspendDataItem({
                                key,
                                data: value,
                            })
                        );
                    }
                }
            }
        },
    }
);

export { CourseProperties };
