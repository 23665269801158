import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import Card from 'react-bootstrap/Card';

class Feedback extends React.Component {
    render() {
        const feedback = this.props.data;
        const classes = this.props.className || '';
        return (
            <React.Fragment>
                <Card className={`feedback rounded-0 ${classes}`} role="alert">
                    <Card.Body className="p-3">
                        {feedback.title && (
                            <h3 className="feedback-title">
                                {ReactHtmlParser(feedback.title)}
                            </h3>
                        )}
                        {feedback.content && (
                            <div className="feedback-content">
                                {ReactHtmlParser(feedback.content)}
                            </div>
                        )}
                    </Card.Body>
                </Card>
            </React.Fragment>
        );
    }
}

export default Feedback;
