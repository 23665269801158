import React from 'react';
import PropTypes from 'prop-types';

/**
 * @abstract
 * @memberOf ReactBlocks.Conversation
 */
class ConversationType extends React.Component {
    /**
     * @param props
     */
    constructor(props) {
        super(props);

        this.ctx = props.ctx;
        this.message = props.message;
        this.pathItem = props.pathItem;
    }
}

ConversationType.propTypes = {
    ctx: PropTypes.objectOf(PropTypes.any).isRequired,
    message: PropTypes.objectOf(PropTypes.any).isRequired,
    pathItem: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ConversationType;
