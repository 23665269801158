import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';

function MessageIdentifier(props) {
    const { isLocked, id } = props;

    if (isLocked) {
        return null;
    }
    return (
        <Badge variant='dark' pill>
            ID == {id}
        </Badge>
    );
}

MessageIdentifier.propTypes = {
    id: PropTypes.string.isRequired,
    isLocked: PropTypes.bool.isRequired,
};

export default MessageIdentifier;
