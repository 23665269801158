import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { baselineRaiseText } from '../../animations/baselineRaiseText';

function BaselineRaiseText(props) {
    const { as: Component, children, style, delay, duration, stagger, ...rest } = props;
    const element = useRef();

    useEffect(() => {
        baselineRaiseText(element.current, {
            delay,
            duration,
            stagger,
        });
    }, []);

    return (
        <Component ref={element} style={{ opacity: 0, ...style }} {...rest}>
            {ReactHtmlParser(children)}
        </Component>
    );
}

BaselineRaiseText.propTypes = {
    as: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    children: PropTypes.node,
    style: PropTypes.shape({}),
    delay: PropTypes.number,
    duration: PropTypes.number,
    stagger: PropTypes.number,
};

BaselineRaiseText.defaultProps = {
    as: 'h1',
    children: undefined,
    style: {},
    delay: 0,
    duration: 0.5,
    stagger: 0.07,
};

export default BaselineRaiseText;
