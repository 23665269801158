import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import Backbone from 'backbone';
import Navigation from '../../components/Navigation';
import { BlockProvider } from '~templates/components/blocks';

const NavigationManager = Backbone.Model.extend({
    constructor() {
        this.name = 'Navigation';
    },

    setup(container) {
        this.container = container;
        this.listenTo(this.container, 'onLoadComplete', this.start);
    },

    start() {
        const context = {
            block: this,
            container: this.container,
            data: this.data,
            options: this.data?.options ?? {},
        };

        const component = (
            <BlockProvider value={context}>
                <Navigation blockRef={this}></Navigation>
            </BlockProvider>
        );

        this.$el = $('<div class=\'navigation\' />');
        this.container.dom.prepend(this.$el);

        ReactDOM.render(component, this.$el[0]);
    },
});

export default NavigationManager;
