import { useEffect, useMemo } from 'react';
import { useCollection, useDocument } from 'react-firebase-hooks/firestore';
import { Routes } from '../RoomSync.contants';
import useRoomSync from './useRoomSync';
import { useCollectionReference, useDocumentReference, useFirebase } from '~m/Firebase';
import { useBlock } from '~templates/components/blocks';

/**
 * @param {*} value
 * @param {Object} [options]
 * @param {boolean} [options.convertToSingleAnswer]
 * @param {boolean} [options.asPercentage]
 * @returns {import('../RoomSync.types').UseRoomQuizSyncReturn}
 */
function useRoomQuizSync(value, options = {}) {
    const {
        convertToSingleAnswer = false,
        asPercentage = false,
    } = options;
    const { block } = useBlock();
    const roomSync = useRoomSync();
    const firebase = useFirebase();
    const roomId = useMemo(() => roomSync.roomId, [roomSync.roomId]);

    useEffect(() => {
        const newValue = convertToSingleAnswer ? value?.[0] : value;

        if (newValue === undefined || newValue === '') return;

        firebase.updateDoc(Routes.rooms.members.GET(roomId, firebase.user.uid), {
            answers: {
                [block.id]: newValue,
            },
        });
    }, [value]);

    const membersPath = useMemo(() => {
        if (!roomId) return;
        if (!roomSync.isInstructor) return;
        return Routes.rooms.members.INDEX(roomId);
    }, []);

    const [answersSnapshot] = useCollection(
        useCollectionReference(membersPath),
    );

    const [inClassResultsSnapshot] = useDocument(
        useDocumentReference(
            `rooms/${roomId}/inclass/${block.id}`,
        ),
    );

    const updateInClassValue = (answerIndex, value) => {
        if (typeof value !== 'number') return;
        firebase.updateDoc(`rooms/${roomId}/inclass/${block.id}`, {
            [answerIndex]: value,
        });
    };

    const inClassValues = useMemo(() => inClassResultsSnapshot?.data(), [inClassResultsSnapshot]);
    const inClassTotal = useMemo(() => Object.values(inClassValues ?? {}).reduce((a, b) => a + b, 0), [inClassValues]);

    const {
        results,
        totalCount,
        answeredCount,
    } = useMemo(() => {
        const docs = answersSnapshot?.docs ?? [];

        const results = docs.reduce((output, member) => {
            const data = member.data();
            const answer = data.answers?.[block.id];
            if (answer) {
                if (!output[answer]) {
                    output[answer] = [];
                }
                output[answer].push(data);
            } else {
                output.NO_RESPONSE.push(data);
            }
            return output;
        }, { NO_RESPONSE: [] });

        const totalCount = docs.length;
        const answeredCount = docs.length - results.NO_RESPONSE.length;

        return {
            results,
            totalCount,
            answeredCount,
        };
    }, [answersSnapshot]);

    const commitResults = () => {
        if (!roomSync.isInstructor) return;

        const entries = Object.entries(results)
            .reduce((output, [key, responses]) => {
                if (key === 'NO_RESPONSE') return output;
                output[key] = responses.length;
                return output;
            }, {});

        firebase.updateDoc(Routes.rooms.results.GET(roomId, block.id), entries);
    };

    const [committedResultsSnapshot] = useDocument(
        useDocumentReference(
            Routes.rooms.results.GET(roomId, block.id),
        ),
    );

    const committedResults = useMemo(() => {
        return committedResultsSnapshot?.data();
    }, [committedResultsSnapshot]);

    return {
        results,
        totalCount,
        answeredCount,
        commitResults,
        committedResults,
        updateInClassValue,
        inClassValues,
        inClassTotal,
    };
}

export default useRoomQuizSync;
