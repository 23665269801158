import { Combobox } from '@headlessui/react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { MdSearch } from 'react-icons/md';
import { Modal } from 'react-bootstrap';
import styles from './adminGoto.module.css';
import { useContainer } from '~modules/componentMounter/componentMounterContext';
import { Status } from '~core';
import { useRefEvent } from '~hooks/index';

function AdminGoto() {
    const { container } = useContainer();
    const [query, setQuery] = useState('');
    const [open, setOpen] = useState(false);

    const toggle = () => setOpen(!open);

    useEffect(() => {
        container.on('admin:admin:palette-toggle:goto', toggle);
    }, []);

    const handleOnChange = (page) => {
        container.router.navigate(`#page/${page.attributes.pageID}`, {
            trigger: true,
            replace: true,
        });
        setOpen(false);
    };

    const filteredPages = useMemo(() => {
        const pages = container.courseData.pages.models;

        if (query === '') {
            return pages;
        }

        const match = (value) =>
            value.toLowerCase().includes(query.toLowerCase());

        return pages.filter((page) => {
            const { pageID, pageTitle } = page.attributes;
            return (
                match(pageID) ||
                match(pageTitle) ||
                match(pageID.replace('_', ''))
            );
        });
    }, [query]);

    /** @type{import('react').MutableRefObject<HTMLInputElement>} */
    const inputEl = useRef();

    const handleKeyDown = (event) => {
        if (event.key === 'Escape') {
            setOpen(false);
        }
    };

    useRefEvent(inputEl, 'keydown', handleKeyDown, [open]);

    return (
        <Combobox
            as={Modal}
            open={open}
            show={open}
            onExited={() => setQuery('')}
            onHide={() => setOpen(false)}
            onShow={() => inputEl.current?.focus()}
            onChange={handleOnChange}
            value={undefined}
        >
            <div className={styles.searchWrapper}>
                <MdSearch className={styles.searchIcon} aria-hidden="true" />
                <Combobox.Input
                    ref={inputEl}
                    className={styles.searchInput}
                    placeholder="Search for page..."
                    onChange={(event) => setQuery(event.target.value)}
                />
            </div>

            {filteredPages.length > 0 && (
                <Combobox.Options static className={styles.options}>
                    {filteredPages.map((page) => {
                        const { pageID, moduleID, pageStatus } =
                            page.attributes;
                        return (
                            <Combobox.Option
                                key={pageID}
                                value={page}
                                className={({ active }) => {
                                    return classNames(
                                        styles.option,
                                        active && styles.optionActive
                                    );
                                }}
                            >
                                <span>
                                    {moduleID ? `${moduleID} / ` : ''}
                                    {pageID}
                                </span>
                                <span className={styles.secondaryText}>
                                    {Status.numberToStatus(pageStatus)}
                                </span>
                            </Combobox.Option>
                        );
                    })}
                </Combobox.Options>
            )}

            {query !== '' && filteredPages.length === 0 && (
                <p className="p-4 text-sm text-gray-500">No pages found.</p>
            )}

            {query === '' && filteredPages.length === 0 && (
                <p className="p-4 text-sm text-gray-500">
                    No pages found. Pages are only available once a language is
                    chosen.
                </p>
            )}
        </Combobox>
    );
}

export default AdminGoto;
