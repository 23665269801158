import { useRef } from 'react';

/**
 * Create a ref for an array of elements
 *
 * @returns {[
 *  import('react').MutableRefObject,
 *  import('react').RefCallback<T>
 * ]}
 * @template T
 */
function useRefIndexedArray() {
    const elements = useRef([]);
    const refProp = index => element => elements.current[index] = element;
    return [elements, refProp];
}

export default useRefIndexedArray;
