import React, { useEffect, useRef, useState } from 'react';
import TweenMax from 'gsap/TweenMaxBase';
import TimelineMax from 'gsap/TimelineMax';
import Scarf from '../../components/Scarf';
import useRoomParamMirror from '../../modules/RoomSync/hooks/useRoomParamMirror';
import useRefArray from '../../hooks/useRefArray';
import BaselineRaiseText from '../../components/BaselineRaiseText';
import Slider from './components/Slider';
import UnitCard from './components/UnitCard';
import { useCourseMap } from './CourseMap.hooks';
import styles from './CourseMap.module.css';
import { useRoomSync } from '~m/RoomSync';
import { useBlock } from '~templates/components/blocks';

function CourseMap() {
    const { units, nextModuleIndex } = useCourseMap();
    const { data } = useBlock({ instantComplete: true });

    /** @type {React.LegacyRef<import('@egjs/react-flicking').default>} */
    const slider = useRef();
    const roomSync = useRoomSync();

    const [cardRefs, cardRefProp] = useRefArray();
    const logoRef = useRef();

    useEffect(() => {
        const timeline = new TimelineMax()
            .from(logoRef.current, 0.5, { y: 50, opacity: 0 })
            .delay(0.2)
            .staggerFrom(cardRefs.current, 0.5, { y: 50, opacity: 0, stagger: { from: nextModuleIndex, amount: 0.75 } })

            ;
    }, []);

    return (
        <Scarf variant="centred" fullHeight noPadding innerClassName={styles.wrapper}>
            <div className={styles.content}>
                <div ref={logoRef} className={styles.imageWrapper}>
                    <img src="assets/images/barrickgold/barrickgold_wide.png" alt="" />
                </div>
                <BaselineRaiseText className={styles.title} delay={0.2}>{data.title}</BaselineRaiseText>
            </div>
            <Slider
                className={styles.slider}
                ref={slider}
                startingIndex={nextModuleIndex}
                showScrollBar
            >
                {units.map((unit, key) => (
                    <div key={key}>
                        <UnitCard ref={cardRefProp} disabled={!roomSync.isInstructor} unit={unit} />
                    </div>
                ))}
            </Slider>
        </Scarf>
    );
}

export default CourseMap;
