import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import useBackboneEvent from '../../../hooks/useBackboneEvent';
import { useRoomSync } from '../../../modules/RoomSync';
import params from '../../../utils/params';
import LaunchForInstructor from './LaunchForInstructor';
import LaunchForLearner from './LaunchForLearner';
import LaunchError from './LaunchError';
import LaunchLoading from './LaunchLoading';
import LaunchForInstructorLogin from './LaunchForInstructorLogin';
import { EVENT_FAILED, EVENT_READY } from '~m/RoomSync/RoomSync.contants';

function Launch(props) {
    const { onLaunch } = props;
    const roomSync = useRoomSync();

    const [ready, setReady] = useState(roomSync._ready);
    const [error, setError] = useState(false);

    useBackboneEvent(roomSync, EVENT_READY, () => {
        setReady(true);
    });

    useBackboneEvent(roomSync, EVENT_FAILED, (error) => {
        console.error('Err', error);
        setError(error);
    });

    useEffect(() => {
        if (params.instructor !== '') {
            roomSync.prepareRoomSync();
        }
    }, []);

    // The URL has ?instructor on it
    if (params.instructor === '' && ready === false) {
        return <LaunchForInstructorLogin roomSyncError={error} />;
    }

    if (error) {
        return <LaunchError error={error}/>;
    }

    if (!ready) {
        return <LaunchLoading/>;
    }

    if (roomSync.isInstructor) {
        return <LaunchForInstructor onLaunch={onLaunch} />;
    }

    if (roomSync.isLearner) {
        return <LaunchForLearner onLaunch={onLaunch}/>;
    }

    return (
        <LaunchError error={{
            code: 'custom/account-error',
            message: 'Account error',
        }}/>
    );
}

Launch.propTypes = {
    onLaunch: PropTypes.func.isRequired,
};

export default Launch;
