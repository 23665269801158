import { useEffect, useRef } from 'react';

/**
 * Keeps track of the previous value
 * to be used at a later point.
 *
 * @param {T} value
 * @returns {T}
 * @template T
 */
function usePrevious(value) {
    const ref = useRef(value);
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}

export default usePrevious;
