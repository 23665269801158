import Backbone from 'backbone';

const URLHelper = Backbone.Model.extend(
    {
        location: undefined,
        items: undefined,

        initialize(location) {
            this.location = location;
            this.items = undefined;
        },

        decodeArguments() {
            if (!this.location) {
                return false;
            }

            if (this.location.charAt(0) === '?') {
                this.location = this.location.slice(1);
            }

            const pairs = this.location.split('&');

            const items = [];

            for (const all in pairs) {
                items[pairs[all].split('=')[0]] = pairs[all].split('=')[1];
            }

            this.items = items;

            return this.items;
        },

        getQueryVariableByName(name) {
            const query = this.location.search.substring(1);
            const vars = query.split('&');
            for (let i = 0; i < vars.length; i++) {
                const pair = vars[i].split('=');
                if (pair[0] == name) {
                    return pair[1];
                }
            }
            return false;
        },
    },
    {
        getCleanImageUrl(s) {
            return this.getRelativePath(s);
        },

        getRelativePath(path) {
            let url = path.split('\\').join('/');

            const isExternalImageSupplied = !!url.includes('assets/');

            if (isExternalImageSupplied) {
                url = url.substr(url.indexOf('assets/'), url.length);
            }

            return url;
        },
    }
);

export default URLHelper;
