import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { arrow, autoPlacement, autoUpdate, inline, offset, shift, useDismiss, useFloating, useFocus, useHover, useInteractions, useRole } from '@floating-ui/react-dom-interactions';
import classNames from 'classnames';
import useFollow from '../../useFollow';
import styles from './Note.module.css';

/**
 * @param {{
 *   data: import("../../Quote.types").Note
 *   target: HTMLElement
 * }} props
 * @returns
 */
function Note(props) {
    const {
        data,
        target,
    } = props;
    const [open, setOpen] = useState(false);
    const arrowRef = useRef(null);

    const {
        x, y, reference, floating, strategy, context, placement,
        middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
    } = useFloating({
        placement: 'top',
        open: false,
        onOpenChange: setOpen,
        middleware: [
            // offset(({ elements }) => {
            //     const { reference } = elements;
            //     return reference.getClientRects()[0].top - reference.parentElement.getClientRects()[0].top;
            // }),
            offset(16),
            autoPlacement({
                allowedPlacements: ['top', 'bottom'],
            }),
            inline(),
            // flip(),
            shift({ padding: 8 }),
            arrow({ element: arrowRef }),
        ],
        whileElementsMounted: autoUpdate,
    });

    const { getFloatingProps, getReferenceProps } = useInteractions([
        useHover(context),
        useFocus(context),
        useRole(context, { role: 'tooltip' }),
        useDismiss(context),
        useFollow(context),
    ]);

    useEffect(() => {
        const refProps = getReferenceProps();

        target.addEventListener('blur', refProps.onBlur);
        target.addEventListener('focus', refProps.onFocus);
        target.addEventListener('mousemove', refProps.onMouseMove);
        target.addEventListener('pointerdown', refProps.onPointerDown);
        target.addEventListener('pointerenter', refProps.onPointerEnter);

        reference(target);

        return () => {
            target.removeEventListener('blur', refProps.onBlur);
            target.removeEventListener('focus', refProps.onFocus);
            target.removeEventListener('mousemove', refProps.onMouseMove);
            target.removeEventListener('pointerdown', refProps.onPointerDown);
            target.removeEventListener('pointerenter', refProps.onPointerEnter);
        };
    }, [target]);

    // Get the side of the Note to place the arrow on based
    // on which side of the target the Note is appearing
    const staticSide = {
        top: 'bottom',
        right: 'left',
        bottom: 'top',
        left: 'right',
    }[placement.split('-')[0]];

    // This will detect if the current device is a
    // touch device to change how the note opens
    const touchDevice = useMemo(
        () => (('ontouchstart' in window)
            || (navigator.maxTouchPoints > 0)
            || (navigator.msMaxTouchPoints > 0)),
        [open],
    );

    return (
        <li
            {...getFloatingProps({
                ref: floating,
                className: classNames(styles.note, {
                    [styles.md]: data.description.length < 100,
                    [styles.lg]: data.description.length >= 100,
                    [styles.noteSelected]: open,
                    [styles.noteNotSelected]: !open,
                    [styles.touchDevice]: touchDevice,
                }),
                style: {
                    position: strategy,
                    top: y ?? 0,
                    left: x ?? 0,
                    transformOrigin: staticSide,
                },
            })}
        >
            {data.description}
            {!touchDevice && (
                <div
                    className={styles.arrow}
                    ref={arrowRef}
                    style={{
                        left: arrowX != null ? `${arrowX}px` : '',
                        top: arrowY != null ? `${arrowY}px` : '',
                        right: '',
                        bottom: '',
                        [staticSide]: '-4px',
                    }}
                />
            )}
        </li>
    );
}

Note.propTypes = {
    data: PropTypes.shape({
        id: PropTypes.string,
        text: PropTypes.string,
        description: PropTypes.string,
    }).isRequired,
    target: PropTypes.instanceOf(Element),
};

export default Note;
