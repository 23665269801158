import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import { ContainerProvider } from './componentMounterContext.js';
import { Component } from '~core';

window.ReactDOM = ReactDOM;

class ComponentMounter extends Component {
    initialize() {
        this.name = 'ComponentMounter';
    }

    /**
     *
     * @param {Container} container Reference to the container
     */
    setup(container) {
        this.container = container;

        this.context = {
            container: this.container,
        };
    }

    mountComponents(configs) {
        configs.forEach((config) => this.mountComponent(config));
    }

    /**
     * @param {object} config - Config for mounting a component
     * @param {string} config.id - The ID to apply to the wrapper
     * @param {React.Component} config.component - The React component to mount
     * @param {boolean} config.isClassName - Should the ID be applied to the wrapper as a class name (default is as an ID)
     * @param {boolean} config.after - Add this component after the given selector instead of prepending or appending.
     * @param {boolean} config.isPrepended - Should the component be prepended to the container dom (default is append)
     * @param {boolean} config.classes - Extra classes to add to the wrapping element
     */
    mountComponent(config) {
        const {
            id,
            component: CompToMount,
            props = {},
            classes = '',
            after,
        } = config;
        const componentWrapper = config.isClassName
            ? $(`<div class="${id} ${classes}" />`)
            : $(`<div id="${id}" class="${classes}"/>`);
        const element = (
            <ContainerProvider value={this.context}>
                <CompToMount modelRef={this} {...props} />
            </ContainerProvider>
        );

        if (after) {
            const el = this.container.dom[0].querySelector(after);

            if (!el) {
                throw new Error(
                    `Component is meant to mount after ${after} but ${after} couldn't be found.`
                );
            }

            componentWrapper.insertAfter(el);
        } else if (config.isPrepended) {
            this.container.dom.prepend(componentWrapper);
        } else {
            this.container.dom.append(componentWrapper);
        }

        ReactDOM.render(element, componentWrapper[0]);
    }
}

export default ComponentMounter;
