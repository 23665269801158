import Backbone from 'backbone';
import { Status } from '../../../index';

/**
 * @class LMSAdaptor to communicate between course and tracking on various events
 * @name LMSAdaptor
 *
 * @property {Container} app
 * @property {Session} session
 * @property {object} data
 *
 * @augments Backbone.Model
 *
 * @see Backbone.Model
 */
const LMSAdaptor = Backbone.Model.extend(
    /** @lends LMSAdaptor.prototype */ {
        /**
         * Initialize the LMS adaptor
         *
         * @param {Container} container
         * @param {object} data
         * @param {Session} session
         */
        initialize(container, data, session) {
            this.app = container;
            this.session = session;
            this.data = data;
        },

        /**
         * setup the tracking
         */
        setup() {
            if (!this.app.tracking) return;

            this.scoring = this.app.getComponent('Scoring');
            this.createListeners();
            this.extractData();
        },

        /**
         * Create the listeners to update course and tracking
         */
        createListeners() {
            this.listenTo(this.data, 'onStatusUpdated', function (e) {
                this.setLessonStatus(e);
            });

            this.data.pages.each(function (page) {
                this.addListenersToPage(page);
            }, this);

            this.listenTo(this.session, 'onSlideReset', function (e) {
                const pageID = e.pageData.pageID();

                this.app.tracking.deleteItem(pageID);
            });

            this.listenTo(this.session, 'onSlideReset', function (e) {
                const pageID = e.pageData.pageID();

                this.app.tracking.deleteItem(pageID);
            });

            this.listenTo(this.session, 'onCourseReset', function (e) {
                this.app.tracking.setCmiData('SCORE_RAW', '');

                this.app.tracking.setCmiData('LESSON_STATUS', 'incomplete');

                this.app.tracking.setCmiData('LESSON_LOCATION', '');

                // clear suspend data items and set all the pages and blocks to incomplete.
                const totalModules = this.data.getTotalModules();

                for (let i = 0; i < totalModules; i++) {
                    const module = this.data.getModule(i);
                    for (let j = 0; j < module.getTotalPages(); j++) {
                        const pageID = module.getPage(j);
                        const page = this.data.getPageById(pageID);
                        page.resetBlockCompletion();
                        page.isComplete(false);
                        page.setStatus(0, true);

                        this.app.tracking.deleteItem(page.pageID());
                    }

                    if (i > 0) {
                        if (module.getModuleStatus() !== Status.OPTIONAL) {
                            module.setModuleStatus(0, true);
                        }
                    } else {
                        module.setModuleStatus(1, true);
                    }
                }
            });

            if (this.scoring) {
                this.listenTo(
                    this.scoring,
                    'onScoreLoaded onScoreChanged',
                    function (e) {
                        this.setCmiScore();
                    }
                );
            }
        },

        /**
         * Add onStatusUpdate listener to a page
         *
         * @param {PageData} pageData
         */
        addListenersToPage(pageData) {
            if (pageData === null || pageData === undefined) return;

            this.listenTo(pageData, 'onStatusUpdate', function (e) {
                this.handleStatusChange(e);
            });
        },

        /**
         * Set the page status and save the progress
         *
         * @param {object} e
         */
        handleStatusChange(e) {
            const pageID = e.target.pageID();
            const pageStatus = e.target.getStatus();

            this.app.tracking.setItem(pageID, pageStatus);

            if (this.timer) clearTimeout(this.timer);

            const self = this;
            this.timer = setTimeout(function () {
                self.app.tracking.save();
            }, 10);
        },

        /**
         * Set the lesson status of the course
         *
         * @param {object} e
         */
        setLessonStatus(e) {
            const status = e.status;
            const currentStatus = this.app.tracking.getCmiData('LESSON_STATUS');

            // prevent overwriting lesson status
            if (!status || currentStatus != 'incomplete') return;

            const version = this.app.tracking.scorm.version;

            // More specific events for specific block statuses
            switch (status) {
                case Status.PASSED:
                    if (version != '2004') {
                        this.app.tracking.setCmiData('LESSON_STATUS', 'passed');
                    } else {
                        this.app.tracking.setCmiData(
                            'LESSON_STATUS',
                            'completed'
                        );
                        this.app.tracking.setCmiData(
                            'SUCCESS_STATUS',
                            'passed'
                        );
                    }
                    break;
                case Status.COMPLETED:
                    if (version != '2004') {
                        this.app.tracking.setCmiData(
                            'LESSON_STATUS',
                            'completed'
                        );
                    } else {
                        this.app.tracking.setCmiData(
                            'LESSON_STATUS',
                            'completed'
                        );
                        this.app.tracking.setCmiData(
                            'SUCCESS_STATUS',
                            'passed'
                        );
                    }
                    break;
                case Status.FAILED:
                    if (version != '2004') {
                        this.app.tracking.setCmiData('LESSON_STATUS', 'failed');
                    } else {
                        this.app.tracking.setCmiData(
                            'LESSON_STATUS',
                            'completed'
                        );
                        this.app.tracking.setCmiData(
                            'SUCCESS_STATUS',
                            'failed'
                        );
                    }
                    break;
                case Status.STARTED:
                    this.app.tracking.setCmiData('LESSON_STATUS', 'incomplete');
                    break;
                default:
                    this.app.tracking.setCmiData('LESSON_STATUS', 'incomplete');
            }
        },

        setCmiScore() {
            const scoreInPercent = this.scoring.getScorePercent().get('points');
            this.app.tracking.setCmiData(
                'SCORE_RAW',
                scoreInPercent.toString()
            );
            // cmi.core.score.raw actually accepts CMIDecimal but  a condition in setCmiData() of CourseProperties fails if value is number
            this.data.score = scoreInPercent;
        },

        /**
         * Extract the data from tracking (LMS) and updated the course properties
         */
        extractData() {
            const self = this;
            const pairs = self.app.tracking.getDataAsString().split('||');

            // Complete whatever screens are needed
            for (var i = 0; i < pairs.length; i++) {
                const key = String(pairs[i]).split('==')[0];
                const value = String(pairs[i]).split('==')[1];

                const pageData = self.data.getPageById(key);

                if (pageData != undefined) {
                    const pageStatus = parseInt(value);
                    pageData.setStatus(pageStatus, true);
                    pageData.isComplete(pageStatus >= Status.COMPLETED);
                }
            }

            // Update the module statuses
            const totalModules = self.data.getTotalModules();

            for (i = 0; i < totalModules; i++) {
                self.data.checkModuleCompletion(i);
            }

            // Score
            if (this.scoring) {
                self.data.score =
                    parseInt(self.app.tracking.getCmiData('SCORE_RAW')) || 0;
            }
        },

        /**
         * Destroy the LMS adaptor
         */
        destroy() {
            this.stopListening();
        },
    }
);

export default LMSAdaptor;
