import React from 'react';
import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip,
} from 'chart.js';

import { Bar, Pie } from 'react-chartjs-2';

import PropTypes from 'prop-types';
import styles from './Chart.module.css';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
);
export const options = {
    indexAxis: 'y',
    elements: {
        bar: {
            borderWidth: 2,
        },
    },
    responsive: true,
    plugins: {
        legend: {
            display: false,
        },
    },
};

function Chart(props) {
    const chart = getChart(props);
    return (
        <div className={styles.wrapper}>
            {chart}
        </div>
    );
}

function getChart(props) {
    const { type, options, data } = props;
    switch (type) {
        case 'Bar':
            return <Bar options={options} data={data} />;
        case 'Pie':
            return <Pie options={options} data={data} />;
    }
}
Chart.propTypes = {
    type: PropTypes.string,
    options: PropTypes.object,
    data: PropTypes.object,
};

Chart.defaultProps = {
    type: undefined,
    options: undefined,
    data: undefined,
};

export default Chart;
