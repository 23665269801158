import { TimelineMax } from 'gsap/TimelineMax';
import TweenMax, { Power4 } from 'gsap/TweenMaxBase';

class BuildAnimation {
    static staggerAnimateIn(elements) {
        this.tl = new TimelineMax({
            autoRemoveChildren: true,
        });
        TweenMax.set(elements, { opacity: 0, transition: 'none' });
        this.tl.add(
            TweenMax.staggerFromTo(
                elements,
                0.3,
                {
                    opacity: 0,

                    cycle: {
                        y(i) {
                            return i + 1 * 10;
                        },
                    },
                },
                {
                    opacity: 1,
                    y: 0,
                    transform: 'none',
                    ease: Power4.easeOut,
                    immediateRender: true,
                },
                0.15
            )
        );
    }

    // static animateIn(elements, time) {
    //     const TIME = time || 0.5;

    static animateIn(elements) {
        TweenMax.fromTo(
            elements,
            0.3,
            {
                opacity: 0,
                y: 40,
            },
            {
                opacity: 1,
                y: 0,
            }
        );
    }

    static animateOut(elements, _callback) {
        this.tl = new TimelineMax({
            onComplete: () => {
                // can use this callback to change pages after animate out
                _callback();
            },
        });
        this.tl.add(
            TweenMax.fromTo(
                elements,
                0.08,
                {
                    opacity: 1,
                    y: 0,
                },
                {
                    opacity: 0,
                    y: 40,
                }
            )
        );
    }
}

export default BuildAnimation;
