import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Col, Container } from 'react-bootstrap';
import TweenMax from 'gsap/TweenMax';
import Scarf from '../../components/Scarf';
import BaselineRaiseText from '../../components/BaselineRaiseText';
import { readingOpacityChildren } from '../../animations/readingOpacity';
import styles from './TextColumn.module.css';
import TextColumnsBlock from '~templates/components/blocks/text/TextColumnsBlock';

class TextColumn extends TextColumnsBlock {
    componentDidMount() {
        this.props.blockRef.complete();
        readingOpacityChildren(this.card);
        TweenMax.staggerFrom(this.elements, 0.5, { y: 50, opacity: 0 }, 0.2);
    }

    render() {
        const Collection = this.type;
        return (
            <Scarf variant="centred">
                <Container>
                    <BaselineRaiseText className={styles.title} duration={0.7} stagger={0.05}>{this.data.title}</BaselineRaiseText>
                    <Collection
                        className="text-block"
                        ref={div => (this.card = div)}
                    >
                        {this.data.columns.map((item, i) => {
                            const Type =
                                this.data.type === 'row'
                                    ? ({ children, ...props }) => (
                                        <Col
                                            md={item.columnSpan}
                                            {...props}
                                            className="details"
                                        >
                                            {children}
                                        </Col>
                                    )
                                    : React.Fragment;

                            return (
                                <Type key={i}>
                                    <div
                                        ref={(el) => {
                                            this.elements.push(el);
                                        }}
                                    >
                                        {item.image !== undefined
                                            && item.image !== '' && (
                                            <img
                                                src={item.image}
                                                alt={item.imageDescription}
                                            />
                                        )}
                                        {item.text !== undefined
                                            && item.text !== '' && (
                                            <div>
                                                <div className={styles.text}>
                                                    {ReactHtmlParser(item.text)}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </Type>
                            );
                        })}
                    </Collection>
                </Container>
            </Scarf>
        );
    }
}

export default TextColumn;
