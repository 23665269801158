export const ATTENDEE_LIMIT = 30;

/** @type {import('./RoomSync.types').RoomStatusActive} */
export const ROOM_ACTIVE = 'ACTIVE';

/** @type {import('./RoomSync.types').RoomStatusEnded} */
export const ROOM_ENDED = 'ENDED';

export const EVENT_READY = 'room-sync:ready';
export const EVENT_FAILED = 'room-sync:failed';
export const EVENT_ROOM_UPDATE = 'room-sync:room-update';
export const ON_MANAGED_ROOM_UPDATE = 'room-sync:on-managed-room-update';
export const EVENT_BEFORE_ROOM_LEAVE = 'room-sync:before-room-leave';
export const EVENT_AFTER_ROOM_LEAVE = 'room-sync:after-room-leave';
export const EVENT_LEARNER_KICKED = 'room-sync:learner-kicked';

export const ConnectionStatus = {
    CONNECTED: 'CONNECTED',
    DISCONNECTED: 'DISCONNECTED',
    UNKNOWN: 'UNKNOWN',
};

export const FirebasePaths = {
    ROOM: roomId => `rooms/${roomId}`,
    ROOM_MEMBERS: roomId => `rooms/${roomId}/members`,
    ROOM_MEMBER_STATUSES: roomId => `room-statuses/${roomId}`,
    LEARNER: learnerId => `learners/${learnerId}`,
};

export const Routes = {
    rooms: {
        INDEX: () => 'rooms',
        GET: roomId => `rooms/${roomId}`,
        members: {
            INDEX: roomId => `rooms/${roomId}/members`,
            GET: (roomId, memberId) => `rooms/${roomId}/members/${memberId}`,
        },
        results: {
            GET: (roomId, questionId) => `rooms/${roomId}/results/${questionId}`,
        },
    },
    roomStatuses: {
        INDEX: () => 'room-statuses',
        GET: roomId => `room-statuses/${roomId}`,
    },
    learners: {
        INDEX: () => 'learners',
        GET: learnerId => `learners/${learnerId}`,
    },
};

/** @enum {string} */
export const AccountType = {
    NO_ACCOUNT: 'NO_ACCOUNT',
    LEARNER: 'LEARNER',
    INSTRUCTOR: 'INSTRUCTOR',
};
