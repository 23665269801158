import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import Backbone from 'backbone';
import Launch from './components/Launch';
import { BlockProvider } from '~templates/components/blocks';

const LaunchPage = Backbone.View.extend({
    name: 'LaunchPage',

    /**
     * Initializing the launch page
     *
     * @param {object} properties
     */
    initialize(properties) {
        this.enableOnlyEnglish = !!properties.enableOnlyEnglish;
        this.jsonPath = properties.jsonPath;
    },

    /**
     *  Listen to container setup completion before running functions
     *
     * @param {object} container
     */
    setup(container) {
        if (!container.showLaunchPage) return;
        this.container = container;
        this.container.launchPage = this;
        this.listenTo(this.container, 'onSetupComplete', this.start);
    },

    /**
     * Load JSON passed in initalisation
     */
    start() {
        $.getJSON(this.jsonPath, (data) => {
            this.onDataLoad(data);
        });
    },

    /**
     * Sets up data and runs render
     *
     * @param {object} e
     */
    onDataLoad(e) {
        this.data = e;
        this.render();
    },

    /**
     * Launch component is appended
     *
     */
    render() {
        const context = {
            block: this,
            container: this.container,
            data: this.data,
            options: this.data?.options ?? {},
        };

        const component = (
            <BlockProvider value={context}>
                <Launch onLaunch={data => this.handleLaunch(data)} />
            </BlockProvider>
        );
        this.$el = $('<div class=\'Launch\' />');
        this.container.dom.append(this.$el);
        ReactDOM.render(component, this.$el[0]);
    },

    handleLaunch(data) {
        const { language, division, page } = data;
        this.container.startPage = page;
        this.trigger('onLaunchCourse', { language, division });
        this.destroy();
    },

    /**
     * Launch is removed from DOM when the course is to load
     */
    destroy() {
        this.stopListening();
        ReactDOM.unmountComponentAtNode(this.$el[0]);
        this.remove();
    },
});

export default LaunchPage;
