import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import SortableItem from './SortableItem';

/**
 * @memberOf ReactBlocks
 * @Param {Array} items Options array to traverse through and pass each item.
 * @Param {Boolean} isAnswered If value is true, dragging will be disabled.
 * @param {String} classNames Using classNames to pass classNames value to set correct or incorrect class name.
 * @param {Boolean} isLocked Checking course is locked or unlocked and prepending drag label drop target id
 * for testing purpose.
 * @Param {Number} setTabIndexValue Getting and setting tabindex as per useDragHandle value to focus option or drag handle
 * and keyboard support.
 * @returns {ReactNode}
 */

const SortableList = SortableContainer(
    ({ items, isAnswered, classNames, isLocked, setTabIndexValue }) => {
        return (
            <div className="list-wrapper">
                {items.map((item, index) => (
                    <SortableItem
                        key={`item-${index}`}
                        index={index}
                        sortIndex={index}
                        item={item}
                        disabled={isAnswered}
                        classNames={
                            classNames[index] !== undefined
                                ? classNames[index]
                                : ''
                        }
                        isLocked={isLocked}
                        setTabIndexValue={setTabIndexValue}
                    />
                ))}
            </div>
        );
    }
);

export default SortableList;
