import React, { useContext } from 'react';

/**
 * @type {React.Context<{
 *     container: Container
 * }>}
 * @see src/modules/container/Container.js
 */
export const ContainerContext = React.createContext({});
export const ContainerProvider = ContainerContext.Provider;
export function useContainer() {
    return useContext(ContainerContext);
}
