import Backbone from 'backbone';

class AudioCollector {
    constructor(log, container) {
        this.log = log;
        this.type = 'AudioCollector';
        this.events = [
            'onAudioManagerReady',
            'onAudioManagerTimeout',
            'onAudioToggle',
            'onAudioNotInManifest',
            'onAudioAdded',
            'onAudioError',
            'onAudioPlay',
            'onAudioLoad',
            'onAudioFinish',
            'onAudioPlaying',
            'onAudioToggle',
            'onUpdate',
            'onSetVolume',
        ];

        this.events.forEach((event) => {
            Backbone.listenTo(container.audioManager, event, (e) => {
                log.add({
                    type: this.type,
                    event: `AudioManager.${event}`,
                    data: e,
                    time: Date.now(),
                });
            });
        });
    }

    print() {
        return this.log.where({ type: this.type });
    }
}

export default AudioCollector;
