import Backbone from 'backbone';
import $ from 'jquery';
import { Logger } from '../../index';
import PageData from '../data/pagedata.js';
import { Pages } from '../data/pages.js';
import BlockData from '../data/blockdata.js';
import ModuleData from '../data/moduledata.js';
import { Modules } from '../data/modules.js';
import Status from '../data/status.js';

import JSONParser from '../data/jsonparser.js';
import XMLParser from '../data/xmlparser.js';

/**
 * @class Create a CourseData of a course
 * @name CourseData
 *
 * @property {string} courseTitle
 * @property {string} courseDescription
 * @property {string} moduleFilter
 * @property {string} pageFilter
 * @property {string} blockFilter
 * @property {number} score
 * @property {number} passmark
 * @property {Container} container
 * @property {ModuleData} introductionModule
 * @property {Modules} modules
 * @property {Pages} pages
 * @property {number} courseStatus
 *
 * @augments Backbone.Model
 *
 * @see Backbone.Model
 */
const CourseData = Backbone.Model.extend(
    /** @lends CourseData.prototype */ {
        defaults: {
            courseTitle: '',
            courseDescription: undefined,
            moduleFilter: undefined,
            pageFilter: undefined,
            blockFilter: undefined,
            score: undefined,
            passmark: undefined,
            container: null,
            introductionModule: null,
            modules: Modules,
            pages: Pages,
            courseStatus: undefined, // Course status
        },

        /**
         * Initialize the course data object
         *
         * @param {object} container - reference of the container
         */
        initialize(container) {
            this.container = container;
            this.pages = new Pages();
            this.modules = new Modules();
        },

        /**
         * Get status of the course
         *
         * @returns {number} course status, any number from 0 to 7, refer status.js for more information
         */
        getStatus() {
            return this.courseStatus;
        },

        /**
         * Set status of the course
         *
         * @param {number} status any number from 0 to 7, refer status.js for more information
         */
        setStatus(status) {
            if (this.getStatus() == status) {
                return;
            }

            const event = {
                target: this,
                previousStatus: this.courseStatus,
                status,
            };

            this.courseStatus = status;

            /**
             * onStatusUpdated is fired when status of a course is updated
             *
             * @event CourseData#onStarted
             * @type {object}
             * @property {CourseData} target
             * @property {number} status
             */
            this.trigger('onStatusUpdated', event);
        },
        /**
         * Get description of the course
         *
         * @returns {string}
         */
        getCourseDescription() {
            return this.get('courseDescription');
        },

        /**
         * Set description of the course
         *
         * @param {string} description
         */
        setCourseDescription(value) {
            this.set({
                courseDescription: value,
            });
        },

        /**
         * Set description of the course
         *
         * @param {string} description
         */
        // setCourseDescription: function (description) {
        //     if (this.getDescription() == description) {
        //         return;
        //     }

        //     this.courseDescription = description;
        // },

        /**
         * Get pass mark of the course
         *
         * @returns {number} passmark
         */
        getPassmark() {
            return this.get('passmark');
        },

        /**
         * Set pass mark of the course
         *
         * @param {number} passmark
         */
        setPassmark(passmark) {
            if (this.getPassmark() == passmark) {
                return;
            }

            this.set({ passmark });
        },

        /**
         * Add the module filter, this will be used to fetch filtered modules based on variant selection in launch page
         *
         * @param {string} filter
         */
        addModuleFilter(filter) {
            if (this.moduleFilter) {
                Logger.info('Warning, a module filter is already defined!');
            } else {
                this.moduleFilter = filter;
            }
        },

        /**
         * Get the module filter, this will be used to fetch filtered modules based on variant selection in launch page
         *
         * @returns {string}
         */
        getModuleFilter() {
            return this.moduleFilter;
        },

        /**
         * Add the page filter, this will be used to fetch filtered pages based on variant selection in launch page
         *
         * @param {string} filter
         */
        addPageFilter(filter) {
            if (this.pageFilter) {
                Logger.info('Warning, a page filter is already defined!');
            } else {
                this.pageFilter = filter;
            }
        },

        /**
         * Get the page filter, this will be used to fetch filtered pages based on variant selection in launch page
         *
         * @returns {string}
         */
        getPageFilter() {
            return this.pageFilter;
        },

        /**
         * Add the block filter, this will be used to fetch filtered blocks based on variant selection in launch page
         *
         * @param {string}
         */
        addBlockFilter(filter) {
            if (this.blockFilter) {
                Logger.info('Warning, a block filter is already defined!');
            } else {
                this.blockFilter = filter;
            }
        },

        /**
         * Get the block filter, this will be used to fetch filtered blocks based on variant selection in launch page
         *
         * @returns {string}
         */
        getBlockFilter() {
            return this.blockFilter;
        },

        /**
         * Add a page data into the pages array in course data
         *
         * @param {PageData} page
         */
        addPage(page) {
            this.pages.push(page);
        },

        /**
         * Get a page data of a page from the pages array based on index
         *
         * @param {number} index
         * @returns {pageData}
         */
        getPage(index) {
            return this.pages.get(index);
        },

        /**
         * Get a page data of a page from the pages array based on pageID
         *
         * @param {string} id
         * @returns {PageData}
         */
        getPageById(id) {
            const model = this.pages.where({
                pageID: id,
            });

            if (model.length > 1)
                Logger.info(
                    'Warning: Possible error with CourseData.getPageById()'
                );

            return model[0];
        },

        /**
         * Get total pages in a course
         *
         * @returns {number}
         */
        getTotalPages() {
            return this.pages.length;
        },

        /**
         * Add introduction module in a course
         *
         * @param {ModuleData} module
         */
        addIntroductionModule(module) {
            this.introductionModule = module;
        },

        /**
         * Get introduction module in a course
         *
         * @returns {ModuleData}
         */
        getIntroductionModule() {
            return this.introductionModule;
        },

        /**
         * Returns true/false based on course has introduction module or not
         *
         * @returns {boolean}
         */
        isThereIntroductionModule() {
            if (this.introductionModule != null) {
                return true;
            }
            return false;
        },

        /**
         * Add a module in a course
         *
         * @param {ModuleData} module
         */
        addModule(module) {
            this.modules.add(module);
        },

        /**
         * Get a module in a course based on the index
         *
         * @param {number} index
         * @returns {ModuleData}
         */
        getModule(index) {
            return this.modules.get(index);
        },

        /**
         * Get total number of modules in a course
         *
         * @returns {number}
         */
        getTotalModules() {
            return this.modules.length;
        },

        /**
         * Get total completed pages in a module based on index
         *
         * @param {number} n
         * @returns {object} (id, completedPages, totalPages, percentage)
         */
        getTotalCompletedPagesByModule(n) {
            // Exit if no module number is supplied (like the coursemap)
            if (isNaN(n) || n === null) return 0;
            return this.getModule(n).getCompletionProgress();
        },

        /**
         * Set the course title
         *
         * @param {string} value
         */
        setCourseTitle(value) {
            this.set({
                courseTitle: value,
            });
        },

        /**
         * Get the course title
         *
         * @returns {string}
         */
        getCourseTitle() {
            return this.get('courseTitle');
        },

        /**
         * Create a block data
         *
         * @param {ref} $block
         * @param {string} blockID
         * @param {string} pageID
         * @param {string} moduleID
         * @param {number} pageStatus This could be from 0 to 7 refer status.js for more information
         * @returns {BlockData}
         */
        createBlock($block, blockID, pageID, moduleID, pageStatus) {
            // Comment..
            const XML = $block.attr('xml');
            const title = $block.find('title').text();
            const template = $block.attr('type');
            const args = $block.attr('arguments');
            const isVisible = $block.attr('isVisible') === 'true';
            const status = Status.statusToNumber($block.attr('status'));

            const isComplete =
                status >= Status.COMPLETED || pageStatus >= Status.COMPLETED;

            const block = new BlockData({
                id: blockID,
            });
            block.order(blockID);
            block.moduleID(moduleID);
            block.pageID(pageID);
            block.blockID(`${blockID}`);
            block.setStatus(status);
            block.blockXML(XML);
            block.title(title);
            block.template(template);
            block.args(args);
            block.isVisibleFromStart(isVisible);
            block.isComplete(isComplete);

            return block;
        },

        /**
         * Create a page data
         *
         * @param {ref} $page
         * @param {string} pageID
         * @param {string} moduleID
         * @param {number} status This could be from 0 to 7 refer status.js for more information
         * @returns {PageData}
         */
        createPage($page, pageID, moduleID, status) {
            const title = $page.find('title:first').text();
            const description = $page.find('description').text();
            const args = $page.attr('arguments');
            const isCompletionRequired =
                $page.attr('isCompletionRequired') === 'true';

            const page = new PageData({
                id: this.getTotalPages(),
            });
            page.moduleID(moduleID);
            page.pageID(pageID);
            page.title(title);
            page.description(description);
            page.isComplete(Status.statusToNumber(status) >= Status.COMPLETED);
            page.setStatus(Status.statusToNumber(status));
            page.setArgs(args);
            page.setCompletionRequired(isCompletionRequired);

            const self = this;
            $page.find('block').each(function (i) {
                page.addBlock(
                    self.createBlock($(this), i, pageID, moduleID, status)
                );
            });

            return page;
        },

        /**
         * Create a module data
         *
         * @param {ref} $module
         * @param {string} moduleID
         * @param {number} status This could be from 0 to 7 refer status.js for more information
         * @param {number} index
         * @returns {BlockData}
         */
        createModule($module, moduleID, status, index) {
            const title = $module.find('title:first').text();
            const description = $module.find('description').text();
            const args = $module.attr('arguments');
            const totalCompletionRequired =
                Number($module.attr('totalCompletionRequired')) || 0;

            const module = new ModuleData({
                id: index,
            });
            module.setModuleID(moduleID);
            module.setModuleTitle(title);
            module.setModuleDescription(description);
            module.setModuleStatus(status);
            module.setArgs(args);
            module.setTotalCompletionRequired(totalCompletionRequired);

            return module;
        },

        /**
         * Setup the global screens like coursemap, resources etc
         *
         * @param {object} $xml
         */
        setupScreens($xml) {
            const self = this;

            $xml.find('screens').each(function (i) {
                const $module = $(this);
                const moduleID = $module.attr('id');
                const moduleStatus = Status.AVAILABLE;

                self.createModule($module, moduleID, moduleStatus, i);

                $module.find('page').each(function (j) {
                    const $page = $(this);
                    const pageID = $page.attr('id');

                    const page = self.createPage(
                        $page,
                        pageID,
                        moduleID,
                        Status.UNAVAILABLE
                    );

                    self.addPage(page);
                });
            });
        },

        /**
         * Setup the introduction module of a course
         *
         * @param {object} $xml
         */
        setupIntroduction($xml) {
            const self = this;

            $xml.find('introductionmodule').each(function (i) {
                const $module = $(this);
                const moduleID = 'introduction';
                const moduleStatus = Status.statusToNumber(
                    $module.attr('status')
                );

                const module = self.createModule(
                    $module,
                    moduleID,
                    moduleStatus,
                    i
                );

                $module.find('page').each(function (j) {
                    const $page = $(this);
                    const pageID = $page.attr('id');
                    let pageStatus = $page.attr('status');

                    if (
                        moduleStatus >= Status.COMPLETED &&
                        moduleStatus !== Status.OPTIONAL
                    ) {
                        pageStatus = moduleStatus;
                    }

                    const page = self.createPage(
                        $page,
                        pageID,
                        moduleID,
                        pageStatus
                    );
                    page.moduleNumber(-1);
                    page.pageNumber(j);

                    module.addPage(page);
                    self.addPage(page);
                });

                self.addIntroductionModule(module);
            });
        },

        /**
         * Setup the modules of a course
         *
         * @param {object} $xml
         */
        setupModules($xml) {
            const self = this;

            $xml.find('module')
                .filter(function () {
                    const $this = $(this);

                    if (self.getModuleFilter()) {
                        return self.getModuleFilter().run(self, $this);
                    }

                    // If not filter is defined, return true and add the module
                    return true;
                })
                .each(function (i) {
                    const $module = $(this);
                    const moduleID = $module.attr('id');
                    const moduleStatus = Status.statusToNumber(
                        $module.attr('status')
                    );

                    const module = self.createModule(
                        $module,
                        moduleID,
                        moduleStatus,
                        i
                    );

                    $module.find('page').each(function (j) {
                        const $page = $(this);
                        const pageID = $page.attr('id');
                        let pageStatus = $page.attr('status');

                        if (
                            moduleStatus >= Status.COMPLETED &&
                            moduleStatus !== Status.OPTIONAL
                        ) {
                            pageStatus = moduleStatus;
                        }

                        const page = self.createPage(
                            $page,
                            pageID,
                            moduleID,
                            pageStatus
                        );
                        page.moduleNumber(i);
                        page.pageNumber(j);

                        module.addPage(page);
                        self.addPage(page);
                    });

                    self.addModule(module);
                });
        },

        /**
         * Create course data based on type of input (xml/json)
         *
         * @param {object} data
         * @param {string} type
         */
        createCourseData(data, type) {
            this.type = type;
            if (type == 'xml') {
                this.xml = data;
                this.parser = new XMLParser(this, data, this.container);
            } else {
                this.json = data;
                this.parser = new JSONParser(this, data, this.container);
            }
        },

        /**
         * Check the completion of a course
         */
        checkCourseCompletion() {
            const total = this.getTotalModules();
            let stat = '';
            let errors = 0;
            let totalCompletedModules = 0;
            let totalFailedModules = 0;

            const log = [];
            log.push('CourseData.Units = ');

            for (let i = 0; i < total; i++) {
                // Hack to make check whether a module should be changed from completed/failed/passed back down to started

                const percentage =
                    this.getTotalCompletedPagesByModule(i).percentage;

                // if(percentage > 0 && percentage < 100) this.getModule(i).setModuleStatus(2);

                this.checkModuleCompletion(i);

                stat = this.getModule(i).getModuleStatus();

                if (stat < Status.COMPLETED) {
                    errors++;
                }

                if (stat >= Status.COMPLETED) totalCompletedModules++;
                if (stat == Status.FAILED) totalFailedModules++;

                log.push(stat);
            }

            if (totalCompletedModules >= total) {
                if (this.getPassmark() && this.score) {
                    if (
                        this.score >= this.getPassmark() &&
                        totalFailedModules === 0
                    ) {
                        this.setStatus(Status.PASSED);
                    } else {
                        this.setStatus(Status.FAILED);
                    }
                } else {
                    this.setStatus(Status.COMPLETED);
                }
            } else {
                this.setStatus(Status.AVAILABLE);
            }
        },

        /**
         * Check completion of a module based on index passed
         *
         * @param {number} n
         * @param {object} (failedPages, completedPages, totalPages, passedPages, isStarted)
         */
        checkModuleCompletion(n) {
            let page, pageStatus;
            let passedPages = 0;
            let completedPages = 0;
            let completionRequiredPages = 0;
            let failedPages = 0;
            const module =
                n === -1 ? this.getIntroductionModule() : this.getModule(n);
            const totalPages = module.getTotalPages();
            let isStarted = false;

            for (let i = 0; i < totalPages; i++) {
                page = module.getPage(i);
                pageStatus = page.getStatus();
                if (page.getCompletionRequired()) {
                    completionRequiredPages++;

                    switch (pageStatus) {
                        case Status.PASSED:
                            passedPages++;
                            break;
                        case Status.FAILED:
                            failedPages++;
                            break;
                    }

                    if (pageStatus >= Status.COMPLETED) completedPages++;
                    if (pageStatus >= Status.STARTED) isStarted = true;
                }
            }

            if (
                completedPages >= completionRequiredPages &&
                passedPages >= 1 &&
                failedPages === 0
            ) {
                module.setModuleStatus(Status.PASSED);
            } else if (
                completedPages >= completionRequiredPages &&
                failedPages >= 1
            ) {
                module.setModuleStatus(Status.FAILED, true);
            } else if (
                completedPages >= completionRequiredPages &&
                passedPages === 0 &&
                failedPages === 0
            ) {
                module.setModuleStatus(Status.COMPLETED);
            } else if (isStarted) {
                module.setModuleStatus(Status.STARTED);
            }

            return {
                failedPages,
                completedPages,
                totalPages: completionRequiredPages,
                passedPages,
                isStarted,
            };
        },

        /**
         * Reset a particular module based on moduleNumber passed
         *
         * @param {number} moduleNumber
         */
        resetModule(moduleNumber) {
            console.warn(
                'This should be happening from the ModuleData, not CourseData'
            );

            const self = this;
            const $xml = $(this.xml);

            const module = this.modules.at(moduleNumber);
            const $module = $xml.find('module').eq(moduleNumber);
            if (!module || $module.length === 0) return;

            const moduleStatus = Status.statusToNumber($module.attr('status'));
            module.setModuleStatus(moduleStatus, true);

            $module.find('page').each(function (i) {
                const $page = $(this);
                const pageID = $page.attr('id');
                let pageStatus = Status.statusToNumber($page.attr('status'));

                if (
                    moduleStatus >= Status.COMPLETED &&
                    moduleStatus !== Status.OPTIONAL
                ) {
                    pageStatus = moduleStatus;
                }

                const page = self.getPageById(pageID);
                page.isComplete(pageStatus >= Status.COMPLETED);
                page.setStatus(pageStatus, true);
                page.blocks.reset();

                $page.find('block').each(function (j) {
                    page.addBlock(
                        self.createBlock(
                            $(this),
                            j,
                            pageID,
                            moduleNumber,
                            pageStatus
                        )
                    );
                });
            });
        },

        /**
         * Reset the complete course to initial values
         */
        reset() {
            this.pages.reset();
            this.modules.reset();

            if (this.type === 'xml') {
                this.createCourseData(this.xml, this.type);
            } else {
                this.createCourseData(this.json, this.type);
            }
            /**
             * onReset is fired when course is reset
             *
             * @event CourseData#onReset
             * @type {object}
             * @property {CourseData} target
             */
            this.trigger('onReset', {
                target: this,
            });
        },
    }
);

export default CourseData;
