import { useEffect } from 'react';

function useBackboneEvent(target, events, callback) {
    useEffect(() => {
        target.on(events, callback);
        return () => {
            target.off(events, callback);
        };
    }, []);
}

export default useBackboneEvent;
