import $ from 'jquery';
import { Logger } from 'log4javascript';
import bootbox from 'bootbox';
import Component from '../../component/component';
import LanguageManager from '../../manager/languagemanager';

const ConnectionNotification = Component.extend({
    initialize() {
        this.name = 'ConnectionNotification';
    },

    setup(container) {
        this.container = container;

        this.listenTo(this.container, 'onLoadComplete', this.start);
    },

    start() {
        const path = window.location;

        $.ajax({
            url: path,
            cache: false,
            success: function (data) {
                setTimeout(
                    function () {
                        Logger.trace(
                            'ConnectionNotification',
                            'heartbeat success'
                        );
                        this.start();
                    }.bind(this),
                    5000
                );
            }.bind(this),
            error: function () {
                Logger.error(
                    'ConnectionNotification',
                    'the connection was lost'
                );
                this.show();
            }.bind(this),
        });
    },

    show() {
        bootbox.alert({
            message: LanguageManager.getString('no_connection_message'),
            buttons: {
                ok: {
                    className: 'button',
                    label: LanguageManager.getString('exit'),
                },
            },
            callback() {
                window.top.close();
            },
        });
    },
});

export default ConnectionNotification;
export { ConnectionNotification };
