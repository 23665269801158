import React, { forwardRef, useEffect, useMemo, useRef } from 'react';
import TimelineMax from 'gsap/TimelineMax';
import TweenMax from 'gsap/TweenMax';
import classNames from 'classnames';
import HtmlParser from 'react-html-parser';
import Button from '../../components/Button';
import Scarf from '../../components/Scarf';
import { getContentSize } from '../../utils';
import useRefArray from '../../hooks/useRefArray';
import styles from './Diagram.module.css';
import { useBlock } from '~blocks/index';

/** @typedef { import("./Diagram.types").BlockContext } BlockContext */

const getCssVarInt = (el, name) => {
    return parseInt(getComputedStyle(el).getPropertyValue(`--${name}`), 10);
};

const elementCentre = element => [element.clientWidth / 2, element.clientHeight / 2];

// Setting this to true will make the diagram sized to fit it's parent element
const responsiveSize = false;

const Note = forwardRef((props, ref) => {
    const { item } = props;
    return (
        <div
            ref={ref}
            style={{ opacity: 0 }}
            className={classNames(styles.note, {
                [styles.red]: item.color === 'red',
                [styles.green]: item.color === 'green',
                [styles.blue]: item.color === 'blue',
                [styles.purple]: item.color === 'purple',
            })}
        >
            {HtmlParser(item.note.content)}
        </div>
    );
});

Note.displayName = 'Note';

function Diagram() {
    /** @type {BlockContext} */
    const { data } = useBlock({ instantComplete: true });

    /** @type {React.MutableRefObject<{inner: HTMLDivElement, outer: HTMLDivElement}>} */
    const scarf = useRef();

    /** @type {React.MutableRefObject<HTMLDivElement>} */
    const planet = useRef();

    /** @type {React.MutableRefObject<HTMLDivElement>} */
    const ring = useRef();

    /** @type {React.MutableRefObject<HTMLDivElement>} */
    const system = useRef();

    /** @type {React.MutableRefObject<HTMLUListElement>} */
    const cluster = useRef();

    const [notes, notesRefProp] = useRefArray();

    const positionSatellite = () => {
        if (!cluster.current) return;

        if (responsiveSize) {
            const satelliteSize = getCssVarInt(system.current, 'satellite-size');
            const minLength = Math.min(...getContentSize(scarf.current.outer));
            const maxLength = getCssVarInt(system.current, 'max-size');
            const length = Math.min(maxLength, minLength);
            system.current.style.setProperty('--size', `${length}px`);
            system.current.style.setProperty('--radius', `${(length / 2) - satelliteSize / 2}px`);
            system.current.style.setProperty('--ring-multiplier', '1');
        }

        // This gets the --radius CSS variable from the cluster element.
        // Check `Diagram.module.css` .cluster for where this value is set.
        const radius = getCssVarInt(system.current, 'radius');

        /** @type {HTMLLIElement[]} */
        // @ts-expect-error HTMLLIElement[] is the correct type for satellites
        const satellites = Array.from(cluster.current.children);

        const [centreX, centreY] = elementCentre(system.current);

        const slice = 2 * Math.PI / satellites.length;
        for (const [i, satellite] of Object.entries(satellites)) {
            const angle = slice * parseInt(i) - (Math.PI / 2);
            const { clientWidth, clientHeight } = satellite;
            const left = centreX - (clientWidth / 2) + (radius) * Math.cos(angle);
            const top = centreY - (clientHeight / 2) + (radius) * Math.sin(angle);
            TweenMax.set(satellite, { left, top });
        }
    };

    const animateSatellites = () => {
        const radius = getCssVarInt(cluster.current, 'radius');
        const satellites = Array.from(cluster.current.children);
        // The distance from the satellites resting position to translate from
        // Positive values will move towards the plane, negative will move away
        const distanceFromSatellite = radius * -0.2;
        const slice = 2 * Math.PI / satellites.length;
        const calculateAngle = i => slice * parseInt(i) - (Math.PI / 2);
        const cycleX = i => distanceFromSatellite * Math.cos(calculateAngle(i));
        const cycleY = i => distanceFromSatellite * Math.sin(calculateAngle(i));

        new TimelineMax({ autoRemoveChildren: true })
            .fromTo(
                planet.current,
                1,
                // opacity is initially 0 on <li/> to avoid jumping
                { opacity: 0 },
                { opacity: 1 },
            )
            .staggerFromTo(
                satellites,
                1,
                // opacity is initially 0 on <li/> to avoid jumping
                { cycle: { x: cycleX, y: cycleY } },
                { opacity: 1, y: 0, x: 0 },
                0.15,
            )
            .fromTo(
                ring.current,
                1,
                { opacity: 0, scale: 0.95 },
                { opacity: 1, scale: 1 },
                '-=0.80',
            )
            .staggerFromTo(
                notes.current,
                0.7,
                // opacity is initially 0 on <li/> to avoid jumping
                { opacity: 0 },
                { opacity: 1 },
                0.15,
            );
    };

    useEffect(() => {
        // Kept as a separate method in case it needs to be re-run
        positionSatellite();
        animateSatellites();
    }, []);

    const hasNotes = useMemo(() => data.items.some(i => !!i?.note?.content), []);

    return (
        <Scarf variant="centred" innerClassName={classNames(styles.wrapper, {
            [styles.hasNotes]: hasNotes,
        })} ref={scarf} overflowX>
            {hasNotes && (
                <div className={styles.noteWrapper}>
                    {/* Added below line to display the extra item */}
                    {data.items[3]?.note?.content && <Note ref={notesRefProp} item={data.items[3]}/>}
                    {/* -- */}
                    {data.items[2]?.note?.content && <Note ref={notesRefProp} item={data.items[2]}/>}

                </div>
            )}
            <div ref={system} className={styles.system}>
                <div
                    ref={planet}
                    className={classNames(styles.planet, styles.withRing, {
                        [styles.red]: data.color === 'red',
                        [styles.green]: data.color === 'green',
                        [styles.blue]: data.color === 'blue',

                        [styles.purple]: data.color === 'purple',
                    })}
                    style={{ opacity: 0 }}
                >
                    {HtmlParser(data.content)}
                </div>
                <div
                    ref={ring}
                    className={classNames(styles.ring, {
                        [styles.red]: data.color === 'red',
                        [styles.green]: data.color === 'green',
                        [styles.blue]: data.color === 'blue',

                        [styles.purple]: data.color === 'purple',
                    })}
                    style={{ opacity: 0 }}
                />
                <ul className={styles.satelliteCluster} ref={cluster}>
                    {data.items.map(item => (
                        <li
                            key={item.id}
                            className={classNames(styles.satellite, {
                                [styles.red]: item.color === 'red',
                                [styles.green]: item.color === 'green',
                                [styles.blue]: item.color === 'blue',

                                [styles.purple]: item.color === 'purple',
                            })}
                            style={{ opacity: 0 }}
                        >
                            <Button
                                className={styles.satelliteElement}
                                icon
                                href={item.link}
                                clickable={!!item.link}
                            >{item.content}</Button>
                        </li>
                    ))}
                </ul>
            </div>
            {hasNotes && (
                <div className={styles.noteWrapper}>
                    {data.items[0]?.note?.content && <Note ref={notesRefProp} item={data.items[0]} />}
                    {data.items[1]?.note?.content && <Note ref={notesRefProp} item={data.items[1]} />}
                </div>
            )}
        </Scarf>
    );
}

export default Diagram;
