import Backbone from 'backbone';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { ConversationContext } from './context.js';
import { useConversationBlock } from './conversationBlockHooks.jsx';
import './styles/ConversationBlock.scss';

/**
 * @param props
 */
function ConversationBlock(props) {
    const { context, messagesEl, messages, showContinue, handleBlockContinue } =
        useConversationBlock(props, {
            pauseOnOrientationChange: true,
        });

    return (
        <ConversationContext.Provider value={context}>
            <div className="new-message-block-scope">
                <ul ref={messagesEl}>{messages}</ul>
                <div className="message-continue-wrapper">
                    {showContinue && (
                        <Button
                            className="btn-arrow-right"
                            onClick={handleBlockContinue}
                        >
                            Continue
                        </Button>
                    )}
                </div>
            </div>
        </ConversationContext.Provider>
    );
}

ConversationBlock.propTypes = {
    blockRef: PropTypes.shape({
        app: PropTypes.objectOf(PropTypes.any).isRequired,
        complete: PropTypes.func,
        data: PropTypes.shape({
            entry: PropTypes.string,
            options: PropTypes.shape({
                width: PropTypes.string,
            }),
            participants: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.string,
                    name: PropTypes.string,
                    avatar: PropTypes.string,
                })
            ),
            path: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.string,
                    type: PropTypes.oneOf(['message', 'options']),
                    sender: PropTypes.string,
                    content: PropTypes.string,
                    audio: PropTypes.string,
                    goto: PropTypes.string,
                })
            ),
        }).isRequired,
    }).isRequired,
};

export default ConversationBlock;
