import React from 'react';
import { Spinner } from 'react-bootstrap';
import Scarf from '../../../components/Scarf';

function LaunchLoading() {
    return (
        <Scarf variant="centred" fullHeight>
            <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        </Scarf>
    );
}

export default LaunchLoading;
