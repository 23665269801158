import { Alert, Button } from 'react-bootstrap';
import React from 'react';
import PropTypes from 'prop-types';
import './InterludeAction.scss';

function InterludeAction(props) {
    const { msg, icon: Icon, onClick } = props;

    return (
        <div className="interlude-action">
            {msg && (
                <Alert variant="secondary">
                    <span>{msg}</span>
                </Alert>
            )}
            <div>
                <Button
                    type="button"
                    size="lg"
                    className="interlude-action-btn"
                    onClick={() => onClick()}
                >
                    <Icon />
                </Button>
            </div>
        </div>
    );
}

InterludeAction.defaultProps = {
    msg: undefined,
};

InterludeAction.propTypes = {
    msg: PropTypes.string,
    icon: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default InterludeAction;
