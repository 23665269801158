import useContainerPlugin from '../../../hooks/useContainerPlugin';

/**
 * @returns {import('../Firebase').default}
 */
function useFirebase() {
    return useContainerPlugin('Firebase');
}

export default useFirebase;
