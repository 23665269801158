import Backbone from 'backbone';
const JunctionAudio = Backbone.Model.extend({
    initialize(container, renderer) {
        this.container = container;
        this.audioManager = container.audioManager;

        this.listenTo(renderer, 'onPageRemoved', this.handlePageRemoved);
    },

    handlePageRemoved(e) {
        this.audioManager.destroyPageAudio(e.pageData);
    },
});

export default JunctionAudio;
