import Backbone from 'backbone';
/**
 * @class A helper class which contains constants to maintain the statuses
 * @name Status
 * @augments Backbone.Model
 *
 * @see Backbone.Model
 */
var Status = Backbone.Model.extend(
    /** @lends Status.prototype */ {},
    {
        UNAVAILABLE: 0,
        AVAILABLE: 1,
        STARTED: 2,
        COMPLETED: 3,
        FAILED: 4,
        PASSED: 5,
        OPTIONAL: 6,
        LOCKED: 7,

        UNAVAILABLE_LABEL: 'Unavailable',
        AVAILABLE_LABEL: 'Available',
        STARTED_LABEL: 'Started',
        COMPLETED_LABEL: 'Completed',
        FAILED_LABEL: 'Failed',
        PASSED_LABEL: 'Passed',
        OPTIONAL_LABEL: 'Optional',
        LOCKED_LABEL: 'Locked',

        /**
         * Converts the status number to corresponding status label
         * @param {number} index
         * @returns {string}
         */
        numberToStatus(index) {
            switch (index) {
                case Status.AVAILABLE:
                    return Status.AVAILABLE_LABEL;
                case Status.STARTED:
                    return Status.STARTED_LABEL;
                case Status.COMPLETED:
                    return Status.COMPLETED_LABEL;
                case Status.FAILED:
                    return Status.FAILED_LABEL;
                case Status.PASSED:
                    return Status.PASSED_LABEL;
                case Status.OPTIONAL:
                    return Status.OPTIONAL_LABEL;
                case Status.LOCKED:
                    return Status.LOCKED_LABEL;
                default:
                    return Status.UNAVAILABLE_LABEL;
            }
        },

        /**
         * Converts the status label to corresponding status number
         * @param {string} value
         * @returns {number}
         */
        statusToNumber(value) {
            if (/Unavailable/i.exec(value)) return Status.UNAVAILABLE;
            if (/Available/i.exec(value)) return Status.AVAILABLE;
            if (/Started/i.exec(value)) return Status.STARTED;
            if (/Completed/i.exec(value)) return Status.COMPLETED;
            if (/Failed/i.exec(value)) return Status.FAILED;
            if (/Passed/i.exec(value)) return Status.PASSED;
            if (/Optional/i.exec(value)) return Status.OPTIONAL;
            if (/Locked/i.exec(value)) return Status.LOCKED;
            return null;
        },
    }
);

export default Status;
