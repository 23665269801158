import useRoomDetails from './useRoomDetails';
import useRoomMembers from './useRoomMembers';

/**
 * @returns {import('../RoomSync.types').UseRoomReturn}
 */
function useRoom() {
    return {
        room: useRoomDetails(),
        members: useRoomMembers(),
    };
}

export default useRoom;
