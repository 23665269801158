// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper__1c37cc{padding-left:.5rem;padding-right:.5rem}.table__209821,.wrapper__1c37cc{min-width:100%}.row__a0eb14{cursor:pointer}.row__a0eb14:hover{background-color:rgba(30,41,59,.1)}.name__296b2c{border-bottom-left-radius:.25rem;border-top-left-radius:.25rem;color:rgb(107 114 128/var(--tw-text-opacity));font-weight:500}.name__296b2c,.value__241045{--tw-text-opacity:1;padding:.25rem;white-space:nowrap}.value__241045{border-bottom-right-radius:.25rem;border-top-right-radius:.25rem;color:rgb(17 24 39/var(--tw-text-opacity));overflow:hidden;text-overflow:ellipsis}", "",{"version":3,"sources":["webpack://./src/modules/RoomSync/components/DetailsPanel.module.css"],"names":[],"mappings":"AACI,iBAAA,kBAAsB,CAAtB,mBAAsB,CAItB,gCAJA,cAIiB,CAIjB,aAAA,cAA2C,CAA3C,mBAAA,kCAA2C,CAI3C,cAAA,gCAAgE,CAAhE,6BAAgE,CAAhE,6CAAgE,CAAhE,eAAgE,CAIhE,6BAJA,mBAAgE,CAAhE,cAAgE,CAAhE,kBAIkF,CAAlF,eAAA,iCAAkF,CAAlF,8BAAkF,CAAlF,0CAAkF,CAAlF,eAAkF,CAAlF,sBAAkF","sourcesContent":[".wrapper {\n    @apply min-w-full px-2;\n}\n\n.table {\n    @apply min-w-full;\n}\n\n.row {\n    @apply hover:bg-slate-800/10 cursor-pointer;\n}\n\n.name {\n    @apply whitespace-nowrap rounded-l p-1 text-gray-500 font-medium;\n}\n\n.value {\n    @apply whitespace-nowrap rounded-r p-1 text-gray-900 text-ellipsis overflow-hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper__1c37cc",
	"table": "table__209821",
	"row": "row__a0eb14",
	"name": "name__296b2c",
	"value": "value__241045"
};
export default ___CSS_LOADER_EXPORT___;
