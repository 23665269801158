import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { VideoPlayerContext } from './context.js';

const actionMap = {
    play: {
        onClick: ({ actions }) => actions.videoPlay(),
        visible: ({ states }) => !states.isPlaying,
        label: ({ i18n: { t } }) => t('play'),
    },
    pause: {
        onClick: ({ actions }) => actions.videoPause(),
        visible: ({ states }) => states.isPlaying,
        label: ({ i18n: { t } }) => t('pause'),
    },
    stop: {
        onClick: ({ actions }) => actions.videoStop(),
        visible: ({ states }) => states.isPlaying,
        label: ({ i18n: { t } }) => t('stop'),
    },
    replay: {
        onClick: ({ actions }) => actions.videoReplay(),
        visible: ({ states }) => !states.isPlaying,
        label: ({ i18n: { t } }) => t('replay'),
    },
    show_transcript: {
        onClick: ({ actions }) => actions.showTranscript(),
        visible: ({ states }) => !states.isShowTranscript,
        label: ({ i18n: { t } }) => t('show_transcript'),
    },
    hide_transcript: {
        onClick: ({ actions }) => actions.hideTranscript(),
        visible: ({ states }) => states.isShowTranscript,
        label: ({ i18n: { t } }) => t('hide_transcript'),
    },
    enter_fullscreen: {
        onClick: ({ actions }) => actions.enterFullscreen(),
        visible: ({ states }) => !states.isFullscreen,
        label: ({ i18n: { t } }) => t('enter_fullscreen'),
    },
    exit_fullscreen: {
        onClick: ({ actions }) => actions.exitFullscreen(),
        visible: ({ states }) => states.isFullscreen,
        label: ({ i18n: { t } }) => t('exit_fullscreen'),
    },
    toggle_fullscreen: {
        onClick: ({ actions }) => actions.toggleFullscreen(),
        visible: () => true,
        label: ({ i18n: { t } }) => t('toggle_fullscreen'),
    },
    toggle_mute: {
        onClick: ({ actions }) => actions.toggleMute(),
        visible: () => true,
        label: ({ i18n: { t } }) => t('toggle_mute'),
        iconState: ({ volume }) => (volume.muted ? 1 : 0),
    },
    cycle_subtitles: {
        onClick: ({ actions }) => actions.cycleSubtitle(),
        visible: () => true,
        label: ({ i18n: { t } }) => t('cycle_subtitles'),
        iconState: ({ states }) => (states.activeSubtitle === false ? 0 : 1),
    },
};

/**
 * @param props
 */
export default function VideoControl(props) {
    const {
        label,
        action,
        icon,
        iconState,
        className,
        permenant,
        visible,
        component,
        ...rest
    } = props;
    const context = useContext(VideoPlayerContext);
    const { options, components } = context;
    const { classes } = options;
    const { controlComponent } = components;

    const Control = component ?? controlComponent;

    const getAction = (v) => {
        if (typeof v === 'string') {
            return actionMap[action];
        }

        if (typeof v === 'function') {
            return {
                onClick: action,
                visible: () => visible,
                label: () => label,
                iconState: () => (typeof iconState === 'function' ? iconState(context) : iconState),
            };
        }

        return undefined;
    };

    const selected = getAction(action);
    if (!selected) {
        return (
            <div style={{ background: 'red', padding: '10px', color: 'white', fontWeight: 'bold' }}>
                BAD_ACTION
            </div>
        );
    }

    const {
        onClick: defaultOnClick,
        visible: defaultVisible,
        label: defaultLabel,
        iconState: defaultIconState,
    } = selected;

    const tempLabel = label ?? defaultLabel(context);
    const displayedLabel = Array.isArray(tempLabel)
        ? tempLabel[defaultIconState(context)]
        : tempLabel;

    const displayedIcon = Array.isArray(icon) ? icon[defaultIconState(context)] : icon;

    const handleAction = (event) => {
        // Prevent duplicate calls on touch devices
        if (event.type !== 'click') {
            return;
        }

        return defaultOnClick({ ...context, event });
    };

    return (
        <>
            {(permenant || defaultVisible(context)) && (
                <Control
                    type='button'
                    onClick={handleAction}
                    onTouchEnd={handleAction}
                    aria-label={displayedLabel}
                    title={displayedLabel}
                    className={className ?? classes?.control}
                    {...rest}
                >
                    {displayedIcon ?? displayedLabel}
                </Control>
            )}
        </>
    );
}

VideoControl.propTypes = {
    label: PropTypes.string,
    iconState: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
    action: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    icon: PropTypes.node,
    className: PropTypes.string,
    permenant: PropTypes.bool,
    visible: PropTypes.bool,
    component: PropTypes.node,
};

VideoControl.defaultProps = {
    label: undefined,
    iconState: 0,
    className: undefined,
    icon: undefined,
    permenant: false,
    visible: true,
    component: undefined,
};
