import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import { IoMdHome, IoMdVolumeHigh } from 'react-icons/io';
import { MdFolderOpen } from 'react-icons/md';
import { Container } from 'react-bootstrap';
import Block from '~blocks/base/Block';
import './ImageListBlock.scss';
import BuildAnimation from '~components/animations/BuildAnimation';

const filetypes = {
    home: <IoMdHome />,
    audio: <IoMdVolumeHigh />,
    resources: <MdFolderOpen />,
};

/**
 * @memberOf ReactBlocks
 * @schema json schema
 */
class ImageListBlock extends Block {
    /**
     * @param props
     */
    constructor(props) {
        super(props);
        /** @type {import('./imagelist-types').ImageListSchema} */
        this.data = this.props.blockRef.data;
        this.props.blockRef.complete();
        this.element = React.createRef();
    }

    componentDidMount() {
        BuildAnimation.animateIn(this.element.current);
    }

    /**
     * @returns {ReactNode}
     */
    render() {
        return (
            <Container>
                <div className="image-list-block">
                    <ListGroup as="ul" ref={this.element}>
                        {this.data.items.map((item, i) => (
                            <ListGroup.Item
                                as="li"
                                key={i.toString()}
                                style={{ cursor: 'default' }}
                            >
                                <Row>
                                    {item.image.length > 0 && (
                                        <Col md={2}>
                                            <Image
                                                className="w-100"
                                                src={item.image}
                                                alt={item.imageDescription}
                                            />
                                        </Col>
                                    )}
                                    {item.iconClass !== undefined &&
                                        item.iconClass.length > 0 && (
                                            <Col md={1}>
                                                {filetypes[item.iconClass]}
                                            </Col>
                                        )}
                                    <Col md={item.image.length > 0 ? 10 : 11}>
                                        {ReactHtmlParser(item.text)}
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </div>
            </Container>
        );
    }
}

export default ImageListBlock;
