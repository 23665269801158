import $ from 'jquery';
import Backbone from 'backbone';
import _ from 'underscore';
import Status from '../data/status.js';
import PageData from '../data/pagedata.js';
import BlockData from '../data/blockdata.js';
import ModuleData from '../data/moduledata.js';

/**
 * @class Parse the XML course file and update course data
 * @name XMLParser
 * @augments Backbone.Model
 *
 * @see Backbone.Model
 */
const XMLParser = Backbone.Model.extend(
    /** @lends XMLParser.prototype */ {
        /**
         * Initialize the parser
         *
         * @param {ref} reference
         * @param {xml} xml
         */
        initialize(reference, xml, container) {
            this.courseData = reference;
            this.container = container;
            this.$xml = $(xml);

            this.setCourseTitle();
            this.setupScreens();
            this.setupIntroduction();
            this.setupModules();
        },

        /**
         * Set the course title
         */
        setCourseTitle() {
            this.courseData.set({
                courseTitle: this.$xml.find('title:first').text(),
            });
        },

        /**
         * Setup the global screens like coursemap, resources etc.
         */
        setupScreens() {
            _.each(
                this.$xml.find('screens'),
                function (module, i) {
                    const $module = $(module);
                    const moduleID = $module.attr('id');
                    const moduleStatus = Status.AVAILABLE;
                    var module = this.createModule(
                        $module,
                        moduleID,
                        moduleStatus,
                        i
                    );

                    _.each(
                        $module.find('page'),
                        function (page, j) {
                            const $page = $(page);
                            const pageID = $page.attr('id');
                            var page = this.createPage(
                                $page,
                                pageID,
                                moduleID,
                                Status.UNAVAILABLE
                            );

                            this.courseData.addPage(page);
                        },
                        this
                    );
                },
                this
            );
        },

        /**
         * Setup introduction module of a course
         */
        setupIntroduction() {
            _.each(
                this.$xml.find('introductionmodule'),
                function (module, i) {
                    const $module = $(module);
                    const moduleID = 'introduction';
                    const moduleStatus = Status.statusToNumber(
                        $module.attr('status')
                    );
                    var module = this.createModule(
                        $module,
                        moduleID,
                        moduleStatus,
                        i
                    );

                    _.each(
                        $module.find('page'),
                        function (page, j) {
                            const $page = $(page);
                            const pageID = $page.attr('id');
                            const pageStatus = page.status;

                            var page = this.createPage(
                                $page,
                                pageID,
                                moduleID,
                                pageStatus
                            );
                            page.moduleNumber(-1);
                            page.pageNumber(j);

                            module.addPage(page);
                            this.courseData.addPage(page);
                        },
                        this
                    );

                    this.courseData.addIntroductionModule(module);
                },
                this
            );
        },

        /**
         * Setup modules of a course
         */
        setupModules() {
            // [MS]: need to write code for filtering later after catching up with AM

            _.each(
                this.$xml.find('module'),
                function (module, i) {
                    const $module = $(module);
                    const moduleID = 'introduction';
                    const moduleStatus = Status.statusToNumber(
                        $module.attr('status')
                    );
                    var module = this.createModule(
                        $module,
                        moduleID,
                        moduleStatus,
                        i
                    );

                    _.each(
                        $module.find('page'),
                        function (page, j) {
                            const $page = $(page);
                            const pageID = $page.attr('id');
                            const pageStatus = page.status;

                            var page = this.createPage(
                                $page,
                                pageID,
                                moduleID,
                                pageStatus
                            );
                            page.moduleNumber(i);
                            page.pageNumber(j);

                            module.addPage(page);
                            this.courseData.addPage(page);
                        },
                        this
                    );

                    this.courseData.addModule(module);
                },
                this
            );
        },

        /**
         * Create a module data
         *
         * @param {ref} module
         * @param {string} moduleID
         * @param {number} status This could be from 0 to 7 refer status.js for more information
         * @param {number} index
         * @returns {BlockData}
         */
        createModule($module, moduleID, status, index) {
            const title = $module.find('title:first').text();
            const description = $module.find('description').text();
            const args = $module.attr('arguments');
            const totalCompletionRequired =
                Number($module.attr('totalCompletionRequired')) || 0;

            const module = new ModuleData({
                id: index,
            });
            module.setModuleID(moduleID);
            module.setModuleTitle(title);
            module.setModuleDescription(description);
            module.setModuleStatus(status);
            module.setArgs(args);
            module.setTotalCompletionRequired(totalCompletionRequired);

            return module;
        },

        /**
         * Create a page data
         *
         * @param {ref} page
         * @param {string} pageID
         * @param {string} moduleID
         * @param {number} status This could be from 0 to 7 refer status.js for more information
         * @returns {PageData}
         */
        createPage($page, pageID, moduleID, status) {
            const title = $page.find('title:first').text();
            const description = $page.find('description').text();
            const args = $page.attr('arguments');
            const isCompletionRequired =
                $page.attr('isCompletionRequired') === 'true';

            const page = new PageData({
                id: this.courseData.getTotalPages(),
            });
            page.moduleID(moduleID);
            page.pageID(pageID);
            page.title(title);
            page.description(description);
            page.isComplete(Status.statusToNumber(status) >= Status.COMPLETED);
            page.setStatus(Status.statusToNumber(status));

            if (
                this.container?.tracking &&
                Number(this.container.tracking.getItem(pageID)) > -1
            ) {
                page.setStatus(Number(this.container.tracking.getItem(pageID)));
            }

            page.setArgs(args);
            page.setCompletionRequired(isCompletionRequired);

            _.each(
                $page.find('block'),
                function (block, i) {
                    page.addBlock(
                        this.createBlock($(block), i, pageID, moduleID, status)
                    );
                },
                this
            );

            return page;
        },

        /**
         * Create a block data
         *
         * @param {ref} block
         * @param {string} blockID
         * @param {string} pageID
         * @param {string} moduleID
         * @param {number} pageStatus This could be from 0 to 7 refer status.js for more information
         * @returns {BlockData}
         */
        createBlock($block, blockID, pageID, moduleID, pageStatus) {
            const XML = $block.attr('xml');
            const title = $block.find('title').text();
            const template = $block.attr('type');
            const args = $block.attr('arguments');
            const isVisible = $block.attr('isVisible') === 'true';
            const status = Status.statusToNumber($block.attr('status'));

            const isComplete =
                status >= Status.COMPLETED || pageStatus >= Status.COMPLETED;

            const block = new BlockData({
                id: blockID,
            });

            block.order(blockID);
            block.moduleID(moduleID);
            block.pageID(pageID);
            block.blockID(`${blockID}`);
            block.setStatus(status);
            block.blockXML(XML);
            block.title(title);
            block.template(template);
            block.args(args);
            block.isVisibleFromStart(isVisible);
            block.isComplete(isComplete);

            return block;
        },
    }
);

export default XMLParser;
