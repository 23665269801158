import { ref } from 'firebase/database';
import { useMemo } from 'react';
import useFirebase from './useFirebase';

function useRealtimeReference(path) {
    const firebase = useFirebase();
    return useMemo(() => {
        if (!path) return;
        return ref(firebase.db, path);
    }, [path]);
}

export default useRealtimeReference;
