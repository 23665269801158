import $ from 'jquery';
import Block from '~blocks/base/Block';
import { Status } from '~core';

/**
 * @abstract
 */
class AdminBase extends Block {
    constructor(props) {
        super(props);
    }

    componentDidMount() {}

    getCourseData() {
        const course = {
            name: this.app.courseData.getCourseTitle(),
            description: this.app.courseData.getCourseDescription()
                ? this.app.courseData.getCourseDescription()
                : 'None',
            totalModules: this.app.courseData.getTotalModules(),
            totalPages: this.app.courseData.getTotalPages(),
            score: this.app.courseData.score
                ? this.app.courseData.score
                : 'None',
            passmark: this.app.courseData.getPassmark()
                ? this.app.courseData.getPassmark()
                : 'None',
            status: Status.numberToStatus(this.app.courseData.getStatus()),
            modules: [],
            pages: [],
            totalBlocks: 0,
            totalTemplates: 0,
            sortedByAscending: true,
            templates: [],
        };

        this.getPageData(course);
        this.getModuleData(course);
        this.getTemplateData(course);

        return {
            course,
            shortcuts: this.app.getComponent('ReviewMode').combinations,
        };
    }

    getPageData(course) {
        this.app.courseData.pages.each((page, i) => {
            const moduleNumber = page.attributes.moduleID
                ? `${page.attributes.moduleID}`
                : 'global';
            const pageId = page.attributes.pageID;
            const interaction = '';
            const wordCount = '';
            const completionRequired = page.getCompletionRequired();
            const title = page.attributes.pageTitle
                ? page.attributes.pageTitle
                : 'None';
            const args = page.attributes.args ? page.attributes.args : 'None';
            const numberStatus = page.attributes.pageStatus;
            const stringStatus = Status.numberToStatus(
                page.attributes.pageStatus
            );
            const description = page.attributes.pageDescription;
            const blockNumber = page.blocks.length;
            const template = page.getBlockTemplates();
            // page.blocks.forEach((block) => {
            //     template.push(block.template());
            // });

            course.pages.push({
                id: course.pages.length,
                pageModuleNumber: moduleNumber,

                pageId,
                pageInteraction: interaction,
                pageWordCount: wordCount,
                pageCompletionRequired: completionRequired,
                pageTitle: title,
                pageArguments: args,
                pageStatus: stringStatus,
                pageDescription: description,
                pageStatusClass: this.getStatusAsCssClass(numberStatus),
                pageBlocks: page.blocks,
                pageTemplate: template.join(', '),
                isInJunction: false,
                isJunction: !!container.router.pages.findWhere({
                    junctionId: pageId,
                }),
            });

            /// // Look for junctions

            _.each(
                page.blocks,
                function (model, i) {
                    if (page.getBlock(i).junctionData !== undefined) {
                        // console.warn('Page.Breakdown.has.junction', page.pageID());
                        const junctionData = page.getBlock(i).junctionData;

                        junctionData.pages.each((jPage, i) => {
                            const jModuleNumber = jPage.moduleNumber()
                                ? `${moduleNumber}/${jPage.moduleNumber()}`
                                : `${moduleNumber}/screens`;
                            const pageId = jPage.pageID();
                            const interaction = '';
                            const wordCount = '';
                            const completionRequired =
                                jPage.getCompletionRequired();
                            const title = jPage.title()
                                ? jPage.title()
                                : 'None';
                            const args = jPage.getArgs()
                                ? jPage.getArgs()
                                : 'None';
                            const numberStatus = jPage.getStatus();
                            const stringStatus = Status.numberToStatus(
                                jPage.getStatus()
                            );
                            const description = jPage.description();
                            const blockNumber = jPage.blocks;
                            const template = jPage.getBlockTemplates();

                            course.pages.push({
                                id: course.pages.length,
                                pageModuleNumber: jModuleNumber,
                                pageId,
                                pageInteraction: interaction,
                                pageWordCount: wordCount,
                                pageCompletionRequired: completionRequired,
                                pageTitle: title,
                                pageArguments: args,
                                pageStatus: stringStatus,
                                pageDescription: description,
                                pageStatusClass:
                                    this.getStatusAsCssClass(numberStatus),
                                pageBlocks: jPage.blocks,
                                pageTemplate: template.join(', '),
                                isInJunction: true,
                                parentJunction: page.pageID(),
                            });
                        });

                        // _.each(
                        //     page.getBlock(i).junctionData.modules,
                        //     function (page, i) {
                        //         this.getPagesFromModule(
                        //             pages,
                        //             templates,
                        //             junctionData,
                        //             junctionData.getModule(i),
                        //             true
                        //         );
                        //     },
                        //     this
                        // );
                    } else {
                        // Logger.info('Page.Breakdown.no.', page.pageID());
                    }
                },
                this
            );
            /// End junction looking..
            course.totalBlocks += blockNumber;
        });
    }

    getModuleData(course) {
        this.app.courseData.modules.each((module, i) => {
            const title = this.app.courseData.getModule(i).getModuleTitle();
            const description = this.app.courseData
                .getModule(i)
                .getModuleDescription()
                ? this.app.courseData.getModule(i).getModuleDescription()
                : 'None';
            const status = this.app.courseData.getModule(i).getModuleStatus();
            const progress =
                this.app.courseData.getTotalCompletedPagesByModule(i);
            const moduleStatus = Status.numberToStatus(status);

            course.modules.push({
                id: i,
                module: i + 1,
                moduleTitle: title,
                moduleDescription: description,
                moduleStatus,
                statusClass: this.getStatusAsCssClass(status),
                progress,
            });
        });
    }

    getTemplateData(course) {
        const pageTemplates = [];

        this.app.courseData.pages.each((page, i) => {
            const pageID = page.pageID();
            page.blocks.forEach((block) => {
                pageTemplates.push({ name: block.template(), pageID });
            });
        });

        const templateCounts = Object.create(null);

        pageTemplates.forEach((template) => {
            templateCounts[template.name] = {
                name: templateCounts[template.name]
                    ? template.name
                    : template.name,
                quantity: templateCounts[template.name]
                    ? templateCounts[template.name].quantity + 1
                    : 1,
                pageID: templateCounts[template.name]
                    ? (templateCounts[template.name].pageID = [
                          ...templateCounts[template.name].pageID,
                          template.pageID,
                      ])
                    : [template.pageID],
            };
        });

        const templateCountsToArray = Object.entries(templateCounts);

        let finalTemplateCounts = [];

        templateCountsToArray.forEach((template) => {
            finalTemplateCounts.push({
                name: template[1].name,
                quantity: template[1].quantity,
                pageID: template[1].pageID,
            });
        });

        finalTemplateCounts = _.sortBy(finalTemplateCounts, 'name');

        course.templates.pageTemplateCounts = finalTemplateCounts;
    }

    getStatusAsCssClass(status) {
        switch (status) {
            case Status.AVAILABLE:
                var cssClass = 'alert-secondary';
                break;
            case Status.STARTED:
                var cssClass = 'alert-warning';
                break;
            case Status.COMPLETED:
                var cssClass = 'alert-success';
                break;
            case Status.FAILED:
                var cssClass = 'alert-danger';
                break;
            case Status.PASSED:
                var cssClass = 'admin-pass';
                break;
            case Status.OPTIONAL:
                var cssClass = 'alert-info';
                break;
            case Status.LOCKED:
                var cssClass = 'alert-dark';
                break;
            default:
                cssClass = '';
        }

        return cssClass;
    }

    // TOFIX [JM : Swapping window.container to this.app causes issues in AdminNavBar component]
    navigateToOverview() {
        window.container.router.navigate('#global/admin', {
            trigger: true,
            replace: true,
        });

        $(".nav-link:contains('Overview')").addClass('active');
    }

    navigateToModules() {
        window.container.router.navigate('#global/admin-modules', {
            trigger: true,
            replace: true,
        });
        // .then(() => {
        //     $(".nav-link:contains('Modules')").addClass('active');
        // });
    }

    makeActive() {
        const target = event.target;
        $(target).addClass('active');
    }

    navigateToPages() {
        window.container.router.navigate('#global/admin-pages', {
            trigger: true,
            replace: true,
        });

        $(".nav-link:contains('Pages')").addClass('active');
    }

    navigateToTemplates() {
        window.container.router.navigate('#global/admin-templates', {
            trigger: true,
            replace: true,
        });

        $(".nav-link:contains('Templates')").addClass('active');
    }

    navigateToImageReview() {
        window.container.router.navigate('#global/admin-image-review', {
            trigger: true,
            replace: true,
        });

        $(".nav-link:contains('Image Review')").addClass('active');
    }

    navigateToSearchAssets() {
        window.container.router.navigate('#global/admin-search-assets', {
            trigger: true,
            replace: true,
        });

        $(".nav-link:contains('Search Assets')").addClass('active');
    }
}

export default AdminBase;
