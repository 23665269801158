import { useDebugValue, useEffect, useState } from 'react';
import { LanguageManager, Status } from '~core';

const getUnitStatusLabel = (index) => {
    switch (index) {
        case 1:
            return LanguageManager.getString('not_started');
        case 2:
            return LanguageManager.getString('inprogess');
        case 3:
            return LanguageManager.getString('completed');
        case 4:
            return LanguageManager.getString('failed');
        case 5:
            return LanguageManager.getString('passed');
        case 6:
            return LanguageManager.getString('optional');
        case 7:
            return LanguageManager.getString('locked');
        default:
            return LanguageManager.getString('unavailable');
    }
};

export function useCourseMapData(blockRef) {
    const [data, setData] = useState(null);

    useEffect(() => {
        const courseData = blockRef?.junctionData
            ? blockRef.junctionData
            : blockRef.app.courseData;

        const modules = Array.from(
            { length: courseData.getTotalModules() },
            (v, i) => {
                const module = courseData.getModule(i);

                return {
                    id: i,
                    module: i + 1,
                    moduleID: module.getModuleID(),
                    moduleTitle: module.getModuleTitle(),
                    moduleDescription: module.getModuleDescription(),
                    moduleStatus: getUnitStatusLabel(module.getModuleStatus()),
                    cssClassAsStatus: String(
                        Status.numberToStatus(module.getModuleStatus())
                    ).toLowerCase(),
                    percentage:
                        courseData.getTotalCompletedPagesByModule(i).percentage,
                    source: module,
                    statusCode: module.getModuleStatus(),
                };
            }
        );

        setData({ modules });
    }, []);

    useDebugValue(data);

    return data;
}
