import React, { useCallback, useEffect, useMemo, useState } from 'react';
import BoxBackground from '../BoxBackground';
import ColorBackground from '../ColorBackground';
import RingBackground from '../RingBackground';
import generalStyles from '../General.module.css';

const backgroundComponents = {
    box: BoxBackground,
    ring: RingBackground,
};

function BackgroundManager(props) {
    const { backgroundManager } = props;

    const defaultBackground = useMemo(() => <ColorBackground data={{ value: 'var(--brand-bg)' }} />, []);

    const [backgroundComponent, setBackgroundComponent] = useState(defaultBackground);

    const handleChange = useCallback((event) => {
        if (typeof event === 'string') {
            const hexMatch = event.match(/^#?[a-fA-F0-9]{6}$/);

            if (hexMatch) {
                setBackgroundComponent(<ColorBackground data={{ value: event }} />);
                return;
            }
        }

        for (const [name, Component] of Object.entries(backgroundComponents)) {
            if (event !== name && event?.type !== name) {
                continue;
            }

            setBackgroundComponent(<Component data={typeof event === 'string' ? { type: event } : event} />);
            return;
        }

        if (typeof event?.src === 'string') {
            // TODO: image background
            setBackgroundComponent(<ColorBackground data={event} />);
            return;
        }

        setBackgroundComponent(defaultBackground);
    }, []);

    useEffect(() => {
        backgroundManager.on('change', handleChange);

        return () => {
            backgroundManager.off('change', handleChange);
        };
    }, []);

    return (
        <div className={generalStyles.infix}>
            {backgroundComponent}
        </div>
    );
}

export default BackgroundManager;
