import Backbone from 'backbone';
import { Logger } from 'log4javascript';
import _ from 'underscore';

/**
 * @classdesc These plugins are used to add scoring functionality to the course pages. This is
 * the base plugin and should be extended.
 *
 * See {@link Scoring} for more details.
 *
 * @class
 * @abstract
 * @property {Container} container Reference to the container
 * @property {PageData} pageData Page data for this scoring page
 * @property {array} options Possible options/outcomes
 * @property {array} result IDs of resulting options/outcomes
 */
const ScoringPlugin = Backbone.Model.extend({
    /**
     * Initialise plugin.
     *
     * @memberof ScoringPlugin#
     * @param {import('../../../container/container').default} container Reference to the container
     * @param {import('../../../data/pagedata').default} pageData Page data for this scoring page
     * @param {array} options Possible options/outcomes
     */
    initialize(container, pageData, options) {
        this.container = container;
        this.pageData = pageData;
        this.options = options;
        this.result = [];

        Logger.trace('ScoringPlugin: initialize', pageData);
    },

    /**
     * Callback after block initialize. Use this to attach listeners to the template
     * (blockData.view).
     *
     * @memberof ScoringPlugin#
     * @param {import('../../../data/blockdata').default} blockData Block data for the initialised block.
     */
    blockInitialize(blockData) {
        // bind on any required block specific events here
        Logger.trace('ScoringPlugin: blockInitialize', blockData);
    },

    /**
     * Returns an array of IDs of the resulting options/outcomes.
     *
     * @memberof ScoringPlugin#
     * @returns {array} IDs of resulting options/outcomes
     */
    getResult() {
        return this.result;
    },

    /**
     * Add option with given optionId to the results array.
     *
     * @memberof ScoringPlugin#
     * @fires ScoringPlugin~onResultUpdated
     * @param {import('../../../data/pagedata').default} pageData Page data for this scoring page
     */
    addResult(optionId) {
        if (
            !_.findWhere(this.options, {
                id: optionId,
            })
        ) {
            Logger.warn(`ID not found in options: ${optionId}`);
            return false;
        }

        if (_.indexOf(this.result, optionId) !== -1) {
            Logger.warn(`Option already in results: ${optionId}`);
            return false;
        }

        this.result.push(optionId);
        this.trigger('onResultUpdated', {
            target: this,
            result: this.result,
        });
    },

    /**
     * Callback on destruction of a page. Perform any clean up code here.
     *
     * @memberof ScoringPlugin#
     */
    destroy() {
        Logger.trace('ScoringPlugin: destroy', this.pageData);
        this.stopListening();
        this.off();
    },
});

export default ScoringPlugin;
