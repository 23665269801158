import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { TweenMax } from 'gsap/TweenMaxBase';
import ReactHtmlParser from 'react-html-parser';
import Button from '../../components/Button';
import BaselineRaiseText from '../../components/BaselineRaiseText';
import Scarf from '../../components/Scarf';
import { baselineRaiseTextChildren } from '../../animations/baselineRaiseText';
import styles from './TextCircle.module.css';
import { useBlock } from '~templates/components/blocks';

function Chapter() {
    const { data } = useBlock({ instantComplete: true });

    const title = data.title;
    const content = data.columns[0].text;
    const srcImage = data.columns[0].image;

    const imageWrapper = useRef();
    const image = useRef();
    const contentRef = useRef();
    const textContentRef = useRef();

    useEffect(() => {
        TweenMax.set(contentRef.current.querySelectorAll('li'), { opacity: 0 });
    }, []);

    const startAnimation = () => {
        TweenMax.fromTo(
            imageWrapper.current,
            0.5,
            { x: -25, rotation: -45, transformOrigin: 'center center' },
            { x: 0, opacity: 1, rotation: 0 },
        );
        TweenMax.fromTo(
            image.current,
            0.5,
            { scale: 0.95, rotation: 45, transformOrigin: 'center center' },
            { scale: 1, opacity: 1, rotation: 0 },
        );
        TweenMax.staggerFromTo(
            contentRef.current.querySelectorAll('li'),
            0.2,
            { y: 25, opacity: 0 },
            { y: 0, opacity: 1, clearProps: 'all' },
            0.1,
        );
        baselineRaiseTextChildren(textContentRef.current);
    };

    return (
        <Scarf variant="centred" noPadding innerClassName={styles.wrapper} background="box">
            <div className={styles.contentSide}>
                <div ref={contentRef} className={styles.content}>
                    <BaselineRaiseText duration={0.7} stagger={0.05} style ={{ width: 'fit-content' }} >{title}</BaselineRaiseText>
                    <div ref={textContentRef}>{ReactHtmlParser(content)}</div>
                </div>
            </div>
            <div className={styles.imageSide}>
                <div
                    ref={imageWrapper}
                    style={{ opacity: 0 }}
                    className={styles.imageWrapper}
                >
                    <img
                        ref={image}
                        src={srcImage}
                        alt=""
                        onLoad={startAnimation}
                    />
                </div>
            </div>
        </Scarf>
    );
}

Chapter.propTypes = {
    property: PropTypes.string,
};

Chapter.defaultProps = {
    property: undefined,
};

export default Chapter;
