import { intervalToDuration } from 'date-fns';

export function clamp(value, min, max) {
    return Math.min(Math.max(value, min), max);
}

/**
 * Map a value within a range to the equivalent position on a new range
 *
 * @example Doubles the value
 * const input = 9;
 * const output = convertRange(input, [0, 10], [0, 20]);
 * console.log(output) // 18
 *
 * @example Convert a score to a percentage
 * // 17 questions and 14 were correct
 * const input = 14;
 * const output = convertRange(input, [0, 17], [0, 100]);
 * console.log(output) // 82.3529411764706
 *
 * @param {number} value - Input value
 * @param {number} src - Bounds of the input value
 * @param {number} tgt - New bounds for output
 *
 * @returns {number} - New ranged value
 */
export function convertRange(value, [srcMin, srcMax], [tgtMin, tgtMax]) {
    const clamped = clamp(Math.abs(value), srcMin, srcMax);
    const valReduced = clamped - srcMin;
    const tgtRange = tgtMax - tgtMin;
    const srcRange = srcMax - srcMin;
    return (valReduced * tgtRange) / srcRange + tgtMin;
}

/**
 * Convert seconds to MM:SS format
 *
 * If a falsy value is provided, `00:00` will be returned.
 *
 * @param {number} value - Seconds
 * @returns {string} - String in MM:SS format
 */
export const formatTime = (value) => {
    if (!value) {
        return '00:00';
    }
    const { minutes, seconds } = intervalToDuration({ start: 0, end: value * 1000 });
    return [minutes.toString().padStart(2, '0'), seconds.toString().padStart(2, '0')].join(':');
};

/**
 * Get the letter for a given index.
 *
 * Index is modulo 26 so will wrap around if >25
 *
 * @param {number} index - Input index
 * @returns {string} - Lowercase letter
 */
export const indexToLetter = (index) => {
    const letters = 'abcdefghijklmnopqrstuvwxyz';
    return letters.charAt(index % 26);
};

/**
 * More readable way of using Promise.all on a map of promises
 *
 * @param {any[]} array
 * @param {function} callback
 * @returns {Promise<Awaited<unknown>[]>}
 */
export const promiseMap = (array, callback) => Promise.all(array.map(callback));

export const arraySort = (property) => {
    let sortOrder = 1;
    if (property[0] === '-') {
        sortOrder = -1;
        property = property.substr(1);
    }
    return (a, b) => {
        let result = 0;
        if (a[property] < b[property]) {
            result = -1;
        } else if (a[property] > b[property]) {
            result = 1;
        }
        return result * sortOrder;
    };
};

export const fetchJson = (url) => fetch(url).then((response) => response.json());
