import classNames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Carousel as BootstrapCarousel } from 'react-bootstrap';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import TweenMax, { Power4, TimelineMax } from 'gsap/TweenMax';
import Button from '../../components/Button';
import Scarf from '../../components/Scarf';
import useRoomParamMirror from '../../modules/RoomSync/hooks/useRoomParamMirror';
import useBuildAnimation from '../../hooks/useBuildAnimation';
import { useCarouselData } from './Carousel.hooks';
import styles from './Carousel.module.css';
import StandardSlide from './components/StandardSlide';
import ChartSlide from './components/ChartSlide';
import ScenarioCoverSlide from './components/ScenarioCoverSlide';
import ScenarioContentSlide from './components/ScenarioContentSlide';
import { t } from '~templates/utils/i18n';
import { useBlock } from '~templates/components/blocks';
import usePermitted from '~m/RoomSync/hooks/usePermitted';

const slideComponents = {
    standard: StandardSlide,
    chart: ChartSlide,
    scenarioCover: ScenarioCoverSlide,
    scenarioContent: ScenarioContentSlide,

    // fileMid: FileMidSlide,
    // fileEnd: FileEndSlide,
};

function Carousel() {
    const { data } = useBlock({ instantComplete: true });
    const buttonPreviousRef = useRef();
    const buttonNextRef = useRef();
    const optionsRef = useRef([]);
    const {
        nextSlide,
        prevSlide,
        activeIndex,
        changeIndex,
        firstIndex,
        lastIndex,
        progressPoints,
    } = useCarouselData();
    const { isInstructor } = usePermitted();

    const isScenario = useMemo(() => {
        return data.items.some((item) => {
            return item.slide?.startsWith('scenario');
        });
    }, []);

    const [firstAnimate, setFirstAnimate] = useState(true);
    const timeline = useMemo(() => new TimelineMax(), []);
    const folderRef = useRef();

    const animateThings = (targetIndex) => {
        const maxCardsToShow = 2;

        timeline.clear().restart();

        if (firstAnimate) {
            setFirstAnimate(false);

            if (isInstructor) {
                if (firstIndex) {
                    TweenMax.set(buttonPreviousRef.current, { scale: 0, opacity: 0 });
                }

                if (lastIndex) {
                    TweenMax.set(buttonNextRef.current, { scale: 0, opacity: 0 });
                }
            }

            timeline
                .addLabel('start')
                .from(
                    folderRef.current.element,
                    0.5,
                    { y: '20vh', opacity: 0, ease: Power4.easeOut, rotation: 10 },
                    'start+=0',
                )
                .staggerFromTo(
                    [...optionsRef.current].reverse(),
                    0.9,
                    {
                        cycle: {
                            zIndex: (index, el, all) => 10 + index,
                        },
                        opacity: 1,
                        y: '90vh',
                    },
                    {
                        cycle: {
                            zIndex: (index, el, all) => 10 + index,
                            rotation: (index, el, all) => ((all.length - index) - 1 - targetIndex) * 3,
                            opacity: (index, el, all) => 1,
                        },
                        // opacity: 1,
                        y: 0,
                        ease: Power4.easeOut,
                    },
                    0.1,
                    'start+=0',
                )
                .staggerTo(
                    [...optionsRef.current].reverse(),
                    0.5,
                    {
                        cycle: {
                            opacity: (index, el, all) => ((all.length - 1 - index) - targetIndex) > maxCardsToShow ? 0 : ((all.length - 1 - index) >= targetIndex ? 1 : 0),
                        },
                    },
                    0.1,
                    'start+=0.4',
                );
        }

        timeline.staggerTo(
            optionsRef.current,
            0.9,
            {
                cycle: {
                    y: index => index >= targetIndex ? 0 : 500,
                    rotation: index => (index - targetIndex) * 3,
                    opacity: index => (index - targetIndex) > maxCardsToShow ? 0 : (index >= targetIndex ? 1 : 0),
                    zIndex: (index, el, all) => 10 + (all.length - index),
                },
                ease: Power4.easeInOut,
            },
            0,
        );

        // The rest on the animations apply to elements
        // that are only available to instructors
        if (!isInstructor) return;

        // Prev button
        if (firstIndex) {
            TweenMax.to(buttonPreviousRef.current, 0.9, { scale: 0, opacity: 0, ease: Power4.easeOut });
        } else {
            TweenMax.to(buttonPreviousRef.current, 0.5, { scale: 1, opacity: 1, ease: Power4.easeOut });
        }

        // Next button
        if (lastIndex) {
            TweenMax.to(buttonNextRef.current, 0.9, { scale: 0, opacity: 0, ease: Power4.easeOut });
        } else {
            TweenMax.to(buttonNextRef.current, 0.5, { scale: 1, opacity: 1, ease: Power4.easeOut });
        }
    };

    useEffect(() => {
        animateThings(0);

        return () => {
            timeline.kill();
        };
    }, []);

    // const element = useBuildAnimation('animateIn', undefined, 'element');

    // Decide which slide component to use for each slide, but only when data.items updates
    const slides = useMemo(() => {
        return data.items.map((item, key) => {
            const SlideComponent = slideComponents[item.slide ?? 'standard'];

            return (
                <BootstrapCarousel.Item key={key} ref={el => optionsRef.current[key] = el}>
                    <SlideComponent item={item} />
                </BootstrapCarousel.Item>
            );
        });
    }, [data.items]);

    useRoomParamMirror('activeCarouselIndex', activeIndex, changeIndex);

    return (
        <Scarf variant="centred" className={styles.outer} innerClassName={styles.inner}>
            <div className={styles.progressDotWrapper}>
                {progressPoints.map(({ viewed, active }, index) => (
                    <button
                        key={index}
                        role="button"
                        className={classNames(styles.progressDot, {
                            [styles.viewed]: viewed,
                            [styles.active]: isInstructor && active,
                        })}
                        onClick={() => {
                            if (!isInstructor) return;
                            changeIndex(index);
                        }}
                        disabled={!isInstructor}
                    >
                        &nbsp;
                    </button>
                ))}
            </div>
            <BootstrapCarousel
                ref={folderRef}
                activeIndex={activeIndex}
                controls={false}
                slide={false}
                touch={false}
                fade={true}
                indicators={false}
                interval={null}
                wrap={false}
                onSlide={(e) => {
                    // changeIndex(e);
                    animateThings(e);
                    console.info('Animate.Slide', e);
                }}
                onSelect={(e) => {
                    // changeIndex(e);
                    // animateThings(e);
                    console.info('Animate.Moved', e);
                }}
                className={classNames(styles.carousel, {
                    [styles.scenario]: isScenario,
                })}
            >
                {slides}
            </BootstrapCarousel>
            {isInstructor && (
                <>
                    <Button
                        ref={buttonPreviousRef}
                        aria-label={t('previous')}
                        title={t('previous')}
                        onClick={prevSlide}
                        disabled={firstIndex}
                        icon={true}
                        className={styles.buttonLeft}
                    >
                        <MdChevronLeft size="40" />
                    </Button>
                    <Button
                        ref={buttonNextRef}
                        aria-label={t('next')}
                        title={t('next')}
                        onClick={nextSlide}
                        disabled={lastIndex}
                        icon={true}
                        className={styles.buttonRight}
                    >
                        <MdChevronRight size="40" />
                    </Button>
                </>
            )}
        </Scarf>
    );
}

export default Carousel;
