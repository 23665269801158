import { TimelineMax } from 'gsap';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { ConversationContext } from './context.js';
import Message from './Message.jsx';
import { useAnimatedElement } from '~utils/helpers.js';
import './styles/ConversationTypeStandard.scss';

function ConversationTypeStandard(props) {
    const { message } = props;
    const { element } = useAnimatedElement();
    /** @type {TimelineMax|undefined} */
    const timeline = useRef();
    const { duration, goto } = message;
    const context = useContext(ConversationContext);
    const { blockRef, options } = context;
    const { pauseOnOrientationChange } = options;

    const delay = duration ? parseInt(duration, 10) / 1000 : 5;

    const currSenderId = useMemo(() => message.sender.id, [message]);
    const side = currSenderId === 'user' ? 'right' : 'left';

    const onPageSuspended = () => timeline.current?.pause();
    const onPageRestored = () => timeline.current?.play();
    blockRef.app.screenManager.on('onPageSuspended', onPageSuspended);
    blockRef.app.screenManager.on('onPageRestored', onPageRestored);

    const orientationHandler = (event) => {
        const isPortrait = event.orientation % 180 === 0;

        if (isPortrait) {
            onPageSuspended();
        } else {
            onPageRestored();
        }
    };

    useEffect(() => {
        if (!pauseOnOrientationChange) {
            return;
        }

        blockRef.app.on('onOrientationChange', orientationHandler);

        return () => {
            blockRef.app.off('onOrientationChange', orientationHandler);
        };
    }, []);

    const onComplete = () => {
        timeline.current?.pause();
        timeline.current?.kill();
        timeline.current = null;
        blockRef.app.screenManager.off('onPageSuspended', onPageSuspended);
        blockRef.app.screenManager.off('onPageRestored', onPageRestored);
        blockRef.app.off('onOrientationChange', orientationHandler);
        context.showMessage(goto);
    };

    useEffect(() => {
        timeline.current = new TimelineMax({ delay, onComplete });

        return () => {
            timeline.current?.pause();
            timeline.current?.kill();
        };
    }, []);

    return (
        <div
            ref={element}
            style={{ opacity: 0 }}
            className={`message-wrapper message-wrapper-${side}`}
        >
            <Message
                isLocked={false}
                message={message}
                onClickSkip={onComplete}
            />
        </div>
    );
}

ConversationTypeStandard.propTypes = {
    message: PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.oneOf(['message']),
        duration: PropTypes.number,
        sender: PropTypes.objectOf(PropTypes.any).isRequired,
        content: PropTypes.string,
        audio: PropTypes.string,
        goto: PropTypes.string,
        nextMessage: PropTypes.shape({
            id: PropTypes.string,
            sender: PropTypes.objectOf(PropTypes.any),
            content: PropTypes.string,
        }),
    }).isRequired,
};

export default ConversationTypeStandard;
