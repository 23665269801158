import { useMemo } from 'react';
import { useBlock } from '~templates/components/blocks';

/**
 * Access a plugin from the container within a react component
 * @param {string} name
 * @param {any} [container=undefined]
 * @returns
 */
function useContainerPlugin(name, container = useBlock().container) {
    return useMemo(() => container.componentList[name], []);
}

export default useContainerPlugin;
