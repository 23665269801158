import TimelineMax from 'gsap/TimelineMax';
import TweenMax from 'gsap/TweenMax';
import { Power4 } from 'gsap/TweenMaxBase';

export function baselineRaiseText(parent, options = {}) {
    const {
        delay = 0,
        duration = 0.5,
        stagger = 0.07,
        onComplete = () => {},
    } = options;
    if (!parent) {
        return;
    }

    const newChildren = [...parent.childNodes].flatMap((/** @type {Node} */ node) => {
        if (node.nodeName !== '#text') {
            return node.textContent.split(/(\s)/g).map((word) => {
                /** @type {HTMLSpanElement} */
                const newElement = node.cloneNode();
                newElement.innerText = word;

                if (word.trim() === '') {
                    return newElement;
                }

                newElement.setAttribute('animatable', 'true');
                newElement.style.display = 'inline-block';
                return newElement;
            });
        }

        const words = node.textContent.split(/(\s)/g);

        return words.map((word) => {
            const newElement = document.createElement('span');
            newElement.innerText = word;

            if (word.trim() === '') {
                return newElement;
            }

            newElement.setAttribute('animatable', 'true');
            newElement.style.display = 'inline-block';
            return newElement;
        });
    });

    parent.innerHTML = '';

    for (const child of newChildren) {
        parent.appendChild(child);
    }

    const animatableSpans = newChildren.filter(span => span.getAttribute('animatable'));

    new TimelineMax({ autoRemoveChildren: true, delay, onComplete })
        .set(parent, { opacity: 1, overflowY: 'hidden' })
        .set(animatableSpans, { opacity: 0 })
        .staggerFromTo(
            animatableSpans,
            duration,
            {
                clipPath: 'polygon(0 0, 100% 0%, 100% 0, 0 0)',
                opacity: 1,
                cycle: { y: (index, element) => element.offsetHeight },
                ease: Power4.easeOut,
            },
            {
                clipPath: 'polygon(0 0, 100% 0%, 100% 120%, 0 120%)',
                y: '0',
                clearProps: 'all',
            },
            stagger,
        )
        .set(parent, { clearProps: 'overflowY' });
}

export function baselineRaiseTextChildren(parent, selector = 'h1,h2,h3', options = {}) {
    if (!parent) return;

    const elements = [...parent.querySelectorAll(selector)];
    for (const element of elements) {
        baselineRaiseText(element, options);
    }
}
