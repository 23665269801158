import classNames from 'classnames';
import React, { cloneElement, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Button from '../../../components/Button';
import { useRoom, useRoomSync } from '../hooks';
import { ConnectionStatus, Routes } from '../RoomSync.contants';
import styles from './ParticipantsPanel.module.css';
import { useFirebase } from '~m/Firebase';
import { t } from '~templates/utils/i18n';

function KickConfirmModal(props) {
    const { children, onConfirm, user } = props;
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            {cloneElement(children, { onClick: () => handleShow() })}
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Body>
                    <p>{t('kick_message').replace('{}', user.displayName)}</p>
                    <p>{t('are_you_sure')}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleClose}>{t('cancel')}</Button>
                    <Button variant="danger" onClick={onConfirm}>{t('kick')}</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

function ParticipantsPanel() {
    const { members } = useRoom();
    const roomSync = useRoomSync();
    const firebase = useFirebase();

    const kickUser = (user) => {
        const roomGetPath = Routes.rooms.members.GET(roomSync.roomId, user.id);
        firebase.updateDoc(roomGetPath, { kicked: true });
        firebase.update(
            `room-statuses/${roomSync.roomId}`,
            { [user.id]: ConnectionStatus.DISCONNECTED },
        );
    };

    const unKickUser = (user) => {
        const roomGetPath = Routes.rooms.members.GET(roomSync.roomId, user.id);
        firebase.updateDoc(roomGetPath, { kicked: false });
    };

    return (
        <ul className={styles.wrapper}>
            {members.map(user => (
                <li key={user.id} className={styles.item}>
                    <div className={styles.avatarWrapper}>
                        <img
                            className={styles.avatar}
                            src={`assets/images/avatars/${user.avatar}.png`}
                            alt=""
                        />
                        <span className={styles.connection}>
                            <span className={classNames({
                                [styles.kicked]: user.kicked,
                                [styles.connected]: !user.kicked && user.connectionStatus === ConnectionStatus.CONNECTED,
                                [styles.disconnected]: !user.kicked && user.connectionStatus === ConnectionStatus.DISCONNECTED,
                                [styles.unknown]: !user.kicked && ![ConnectionStatus.CONNECTED, ConnectionStatus.DISCONNECTED].includes(user.connectionStatus),
                            })} />
                        </span>
                    </div>
                    <div
                        className={styles.details}
                    >
                        <span className={styles.name}>
                            {user.firstName} {user.lastName}
                        </span>
                        <span className={styles.status}>
                            {user.connectionStatusText}
                        </span>
                    </div>
                    <div className={styles.buttonWrapper}>
                        {
                            user.kicked
                                ? (<Button size="sm" onClick={() => unKickUser(user)}>{t('unkick')}</Button>)
                                : (
                                    <KickConfirmModal onConfirm={() => kickUser(user)} user={user}>
                                        <Button size="sm">{t('kick')}</Button>
                                    </KickConfirmModal>
                                )
                        }
                    </div>
                </li>
            ))}
        </ul>
    );
}

export default ParticipantsPanel;
