import copy from 'copy-text-to-clipboard';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormControl } from 'react-bootstrap';
import { MdMoreVert } from 'react-icons/md';
import { where } from 'firebase/firestore';
import Button from '../../../components/Button';
import Scarf from '../../../components/Scarf';
import useBinding from '../../../hooks/useBinding';
import { RoomSyncConstants, useRoomSync, useRooms } from '../../../modules/RoomSync';
import { joinStrings } from '../../../utils';
import useAsyncMemo from '../../../hooks/useAsyncMemo';
import styles from './LaunchForInstructor.module.css';
import { useBlock } from '~templates/components/blocks';
import { ROOM_ACTIVE } from '~m/RoomSync/RoomSync.contants';

function LaunchForInstructor(props) {
    const { onLaunch } = props;
    const { data } = useBlock();
    const { languages } = data;
    const [allDivisions, setAllDivisions] = useState([...languages[0].divisions]);

    const roomSync = useRoomSync();
    const [roomName, , onRoomNameChange, resetRoomName] = useBinding('');
    const [language, setLanguage] = useState(languages[0].code);
    const [division, setDivision] = useState(allDivisions[0].code);
    const [error, setError] = useState('');

    const langMap = useMemo(() => {
        return Object.fromEntries(
            languages.map(lang => [lang.code, lang.text]),
        );
    }, [languages]);
    const languageUrl = useMemo(() => `assets/data/course_${language}/global/language.json`, [language]);

    const [languageData] = useAsyncMemo(async () => fetch(languageUrl).then(r => r.json()), [languageUrl]);

    const t = useCallback(key => languageData?.items.find(item => item.key === key)?.value, [languageData]);

    const [roomNameTextBox, setRoomNameTextBox] = useState('');
    const [creationNote, setCreationNote] = useState('');
    const [createRoomText, setCreateRoomText] = useState('');
    const [manageRoomText, setManageRoomText] = useState('');
    const [allRoomsText, setAllRoomsText] = useState('');
    const [closeRoomText, setCloseRoomText] = useState('');
    const [copyLinkText, setCopyLinkText] = useState('');
    const [copyCodeText, setCopyCodeText] = useState('');
    const [activeText, setActiveText] = useState('');
    const [endedText, setEndedText] = useState('');

    const createRoom = async () => {
        if (!roomName) return;
        const note = t('room_created')?.replace('{}', roomName);
        setCreationNote(note);
        console.info('Creating room ', roomName, language, division);
        await roomSync.instructorCreateRoom(roomName, language, division);
        resetRoomName();
    };

    const setValues = useMemo(() => {
        setAllDivisions(languages.find(lang => lang.code === language).divisions);
        setActiveText(t('active_text'));
        setEndedText(t('ended_text'));
        setRoomNameTextBox(t('room_name_textbox'));
        setCreateRoomText(t('room_create'));
        setCloseRoomText(t('room_close'));
        setManageRoomText(t('room_manage'));
        setAllRoomsText(t('rooms_all'));
        setCopyLinkText(t('copy_link'));
        setCopyCodeText(t('copy_code'));
    }, [languageData]);

    const [pending, setPending] = useState(false);

    const manageRoom = async (roomId) => {
        if (pending) return;

        setPending(true);
        setError('');
        try {
            const room = await roomSync.instructorManageRoom(roomId);
            const data = { language: room.language, page: room.page, division: room.division };
            onLaunch(data);
        } catch (error) {
            console.dir(error);
            setError(error.code);
            setPending(false);
        }
    };

    const [rooms] = useRooms({
        query: () => where('status', '==', ROOM_ACTIVE),
    });

    return (
        <Scarf variant="centred" fullHeight innerClassName={styles.wrapper}>
            <div className={styles.card}>
                <h1>{createRoomText}</h1>
                <div className={styles.inputGroup}>
                    <input style ={{ width: '160px' }} className={styles.input} type="text" placeholder={roomNameTextBox} value={roomName} onChange={onRoomNameChange} />
                    <FormControl
                        className={styles.input}
                        as="select"
                        style ={{ width: '238px' }}
                        custom
                        value={language}
                        onChange={(event) => {
                            setLanguage(event.target.value);
                        }}
                    >
                        {languages.map(({ code, text }) => (
                            <option key={code} value={code}>{text}</option>
                        ))}
                    </FormControl>
                    <FormControl
                        className={styles.input}
                        as="select"
                        custom
                        value={division}
                        onChange={event => setDivision(event.target.value)}
                    >
                        {allDivisions.map(({ code, text }) => (
                            <option key={code} value={code}>{text}</option>
                        ))}
                    </FormControl>
                    <Button onClick={createRoom} disabled={pending}>{createRoomText}
                    </Button>
                </div>
                <span>{creationNote}</span>
            </div>
            <div className={styles.card}>
                <h1>{allRoomsText}</h1>
                {error}
                <ul role="list" className={classNames(styles.rooms, 'divide-y')}>
                    {/* {loadingRooms && <div className="text-center">Loading</div>} */}
                    {/* {rooms.length === 0 && !loadingRooms && <div className="text-center">No rooms</div>} */}
                    {rooms?.map((room) => {
                        let roomStatus;
                        if (room.status === 'ACTIVE') {
                            roomStatus = activeText;
                        }
                        if (room.status === 'ENDED') {
                            roomStatus = endedText;
                        }
                        const roomDetails = joinStrings(' • ', [
                            room.id,
                            roomStatus,
                            langMap[room.language] ?? room.language,
                        ]);

                        return (
                            <li key={room.id} className={styles.room}>
                                <div>
                                    <p>{room.name}</p>
                                    <p>{roomDetails}</p>
                                </div>
                                <div className={styles.inputGroup}>
                                    <Button onClick={() => manageRoom(room.id)} disabled={pending}>{manageRoomText}</Button>
                                    <DropdownMenu.Root modal={false}>
                                        <DropdownMenu.Trigger asChild>
                                            <Button icon><MdMoreVert /></Button>
                                        </DropdownMenu.Trigger>

                                        <DropdownMenu.Content className={styles.dropdownContent} align="end">
                                            {room.status === RoomSyncConstants.ROOM_ACTIVE && (
                                                <DropdownMenu.Item asChild className={styles.dropdownItem}>
                                                    <button onClick={() => roomSync.closeRoom(room.id)}>{closeRoomText}</button>
                                                </DropdownMenu.Item>
                                            )}
                                            <DropdownMenu.Item asChild className={styles.dropdownItem}>
                                                <button onClick={() => copy(room.directLink)}>{copyLinkText}</button>
                                            </DropdownMenu.Item>
                                            <DropdownMenu.Item asChild className={styles.dropdownItem}>
                                                <button onClick={() => copy(room.id)}>{copyCodeText}</button>
                                            </DropdownMenu.Item>
                                        </DropdownMenu.Content>
                                    </DropdownMenu.Root>
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </Scarf>
    );
}

LaunchForInstructor.propTypes = {
    onLaunch: PropTypes.func.isRequired,
};

export default LaunchForInstructor;
