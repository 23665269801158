import React from 'react';
import ReactDOM from 'react-dom';
import { BlockProvider } from '~templates/components/blocks';

export default function mountComponent(Component, container, context, props = {}) {
    ReactDOM.render(
        (
            <BlockProvider value={context}>
                <Component {...props} />
            </BlockProvider>
        ),
        container,
    );
}
