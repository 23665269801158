import Backbone from 'backbone';

const WidgetRoles = Backbone.Model.extend(
    {},
    {
        ALERT: 'alert',
        ALERTDIALOG: 'alertdialog',
        BUTTON: 'button',
        CHECKBOX: 'checkbox',
        COLUMNHEADER: 'columnheader',
        COMBOBOX: 'combobox',
        DEFINITION: 'definition',
        DIALOG: 'dialog',
        DIRECTORY: 'directory',
        GRID: 'grid',
        GRIDCELL: 'gridcell',
        GROUP: 'group',
        HEADING: 'heading',
        IMG: 'img',
        LINK: 'link',
        LIST: 'list',
        LISTBOX: 'listbox',
        LISTITEM: 'listitem',
        MARQUEE: 'marquee',
        MATH: 'math',
        MENU: 'menu',
        MENUBAR: 'menubar',
        MENUITEM: 'menuitem',
        MENUITEMCHECKBOX: 'menuitemcheckbox',
        MENUITEMRADIO: 'menuitemradio',
        OPTION: 'option',
        PRESENTATION: 'presentation',
        PROGRESSBAR: 'progressbar',
        RADIO: 'radio',
        RADIOGROUP: 'radiogroup',
        ROW: 'row',
        ROWGROUP: 'rowgroup',
        ROWHEADER: 'rowheader',
        SCROLLBAR: 'scrollbar',
        SEPARATOR: 'separator',
        SLIDER: 'slider',
        SPINBUTTON: 'spinbutton',
        STATUS: 'status',
        TAB: 'tab',
        TABLIST: 'tablist',
        TABPANEL: 'tabpanel',
        TEXTBOX: 'textbox',
        TIMER: 'timer',
        TOOLBAR: 'toolbar',
        TOOLTIP: 'tooltip',
        TREE: 'tree',
        TREEGRID: 'treegrid',
        TREEITEM: 'treeitem',
    }
);

export default WidgetRoles;
export { WidgetRoles };
