import Backbone from 'backbone';
import _ from 'underscore';
import $ from 'jquery';
import { PxLoader } from '../../libraries/pxloader/PxLoader.js';

const AssetLoader = Backbone.Model.extend({
    constructor(...args) {
        this.pxloader = PxLoader;
        this.counter = 0;
        this.loadCompleteCounter = 0;
        this.name = undefined;
        this.assetList = undefined;
        this.chunks = 10000;
        this.useCache = true;
        this.noProgressTimeout = 20000;

        Backbone.Model.apply(this, args);
    },

    initialize() {
        if (this.attributes.name) this.name = this.attributes.name;

        if (this.attributes.noProgressTimeout) {
            this.noProgressTimeout = this.attributes.noProgressTimeout;
        }

        if (this.attributes.useCache === false) {
            this.useCache = this.attributes.useCache;
        }

        if (
            this.attributes.chunks &&
            this.attributes.chunks > 0 &&
            !isNaN(this.attributes.chunks)
        )
            this.chunks = this.attributes.chunks;

        this.pxloader = new PxLoader({
            noProgressTimeout: this.noProgressTimeout,
        });

        this.assetList = [];
    },

    addXML(url, tag, priority, scope, callback, parameter) {
        const assetObj = {};
        assetObj.url = this.ifCacheEnabledMakeUnique(url);
        assetObj.tag = tag;
        assetObj.priority = priority;
        assetObj.scope = scope;
        assetObj.callback = callback;
        assetObj.parameter = parameter;
        assetObj.type = 'xml';
        assetObj.resource = undefined;

        this.assetList.push(assetObj);
    },

    addJS(url, tag, priority, scope, callback, parameter) {
        const assetObj = {};
        assetObj.url = this.ifCacheEnabledMakeUnique(url);
        assetObj.tag = tag;
        assetObj.priority = priority;
        assetObj.scope = scope;
        assetObj.callback = callback;
        assetObj.parameter = parameter;
        assetObj.type = 'js';
        assetObj.resource = undefined;

        this.assetList.push(assetObj);
    },

    addCSS(url, tag, priority, scope, callback, parameter) {
        const assetObj = {};
        assetObj.url = this.ifCacheEnabledMakeUnique(url);
        assetObj.tag = tag;
        assetObj.priority = priority;
        assetObj.scope = scope;
        assetObj.callback = callback;
        assetObj.parameter = parameter;
        assetObj.type = 'css';
        assetObj.resource = undefined;

        this.assetList.push(assetObj);
    },

    addImage(url, tag, priority, scope, callback, parameter) {
        const assetObj = {};
        assetObj.url = this.ifCacheEnabledMakeUnique(url);
        assetObj.tag = tag;
        assetObj.priority = priority;
        assetObj.scope = scope;
        assetObj.callback = callback;
        assetObj.parameter = parameter;
        assetObj.type = 'img';
        assetObj.resource = undefined;

        this.assetList.push(assetObj);
    },

    /**
     * @Error	Sounds do not give correct onLoadcomplete events. Need to look at this again. Will mess things up if you useCache this.
     */

    addSound(url, tag, priority, scope, callback, parameter) {
        const assetObj = {};
        assetObj.url = this.ifCacheEnabledMakeUnique(url);
        assetObj.tag = tag;
        assetObj.priority = priority;
        assetObj.scope = scope;
        assetObj.callback = callback;
        assetObj.parameter = parameter;
        assetObj.type = 'sound';
        assetObj.resource = undefined;

        this.assetList.push(assetObj);
    },

    addHTML(url, tag, priority, scope, callback, parameter) {
        const assetObj = {};
        assetObj.url = this.ifCacheEnabledMakeUnique(url);
        assetObj.tag = tag;
        assetObj.priority = priority;
        assetObj.scope = scope;
        assetObj.callback = callback;
        assetObj.parameter = parameter;
        assetObj.type = 'html';
        assetObj.resource = undefined;

        this.assetList.push(assetObj);
    },

    addJSON(url, tag, priority, scope, callback, parameter) {
        const assetObj = {};
        assetObj.url = this.ifCacheEnabledMakeUnique(url);
        assetObj.tag = tag;
        assetObj.priority = priority;
        assetObj.scope = scope;
        assetObj.callback = callback;
        assetObj.parameter = parameter;
        assetObj.type = 'json';
        assetObj.resource = undefined;

        this.assetList.push(assetObj);
    },

    reset() {
        this.pxloader = new PxLoader({
            noProgressTimeout: this.noProgressTimeout,
        });
        this.handleLoadProgressEvent();
    },

    start() {
        this.handleLoadProgressEvent();
        this.loadAssetsSequentially();
    },

    loadAssetsSequentially() {
        for (let i = 0; i < this.chunks; i++) {
            if (this.counter >= this.assetList.length) break;

            const url = String(this.assetList[this.counter].url);
            const type = String(this.assetList[this.counter].type);

            if (type === 'js') {
                this.pxloader.addJS(url);
            }

            if (type === 'css') {
                this.pxloader.addCSS(url);
            }

            if (type === 'xml') {
                this.pxloader.addXML(url);
            }

            if (type === 'html') {
                this.pxloader.addHTML(url);
            }

            if (type === 'sound') {
                const filename = url.substring(url.lastIndexOf('/') + 1);
                this.pxloader.addSound(filename, url);
            }

            if (type === 'img') {
                this.pxloader.addImage(url);
            }

            if (type === 'json') {
                this.pxloader.addJSON(url);
            }

            // var filename = url.substring(url.lastIndexOf('/') + 1);
            // console.log('file being loaded: ' + filename);

            this.counter++;
        }

        this.pxloader.start();
    },

    checkQueueComplete(e) {
        if (this.counter <= this.assetList.length) {
            this.loadAssetsSequentially();
        } else {
            this.trigger('onAllDone', {
                target: this,
                loader: e,
            });
        }
    },

    handleLoadProgressEvent() {
        const self = this;

        this.pxloader.addProgressListener(function (e) {
            // console.info(
            //     'AssetLoader.inner',
            //     self.loadCompleteCounter,
            //     e.completedCount,
            //     e.totalCount,
            //     e.error,
            //     e.timeout,
            //     e.resource.url
            // );
            if (e.loaded || e.error || e.timeout) {
                const asset = _.findWhere(self.assetList, {
                    url: e.resource.url,
                });
                let scope;
                let callback;
                let parameter;

                if (asset) {
                    asset.resource = e.resource;
                    scope = asset.scope;
                    callback = asset.callback;
                    parameter = asset.parameter;
                }

                const eventObject = {
                    target: self,
                    loader: e,
                    resource: e.resource,
                    parameter,
                    progress: self.loadCompleteCounter + 1,
                    total: self.assetList.length,
                    percentageComplete:
                        ((self.loadCompleteCounter + 1) /
                            self.assetList.length) *
                        100,
                };

                if (callback) {
                    scope[callback](eventObject);
                }

                self.loadCompleteCounter++;

                self.trigger('onAssetLoaded', eventObject);
            }

            // if (e.timeout) {
            //     self.handleTimeout(e);
            //     return true;
            // }
            // if (e.error) {
            //     self.handleError(e);
            //     return true;
            // }

            if (e.completedCount === e.totalCount) {
                self.reset();
                self.checkQueueComplete(e);

                self.trigger('onAllAssetsLoaded', {
                    target: self,
                    loader: e,
                });

                self.stopListening();
                self.off();
            }
        });
    },

    handleTimeout() {
        // Logger.warn('Timeout loading', e.resource.url, e.progress, e.total);
    },

    handleError() {
        // Logger.error("Error loading", e.resource.url );
    },

    ifCacheEnabledMakeUnique(url) {
        if (this.useCache) {
            return url;
        } else {
            return `${url}?=${_.uniqueId(new Date().getTime().toString())}`;
        }
    },

    get(url) {
        let object;

        $.each(this.assetList, function () {
            if (this.url === url) {
                object = this;

                return false;
            } else {
                object = 'file was not in assetList';
            }
        });

        if (!object) {
            return;
        }

        if (object.type === 'js') {
            return object.resource.getJS(url);
        }

        if (object.type === 'css') {
            return object.resource.getCSS(url);
        }

        if (object.type === 'xml') {
            return object.resource.getXML();
        }

        if (object.type === 'html') {
            return object.resource.getHTML(url);
        }

        if (object.type === 'sound') {
            const filename = url.substring(url.lastIndexOf('/') + 1);
            return object.resource.getSound(filename, url);
        }

        if (object.type === 'img') {
            return object.resource.getImage(url);
        }
    },

    cleanupSounds() {
        _.each(this.assetList, function (asset) {
            if (asset.type === 'sound') {
                const soundId = asset.url.substring(
                    asset.url.lastIndexOf('/') + 1
                );
                soundManager.destroySound(soundId);
            }
        });
    },

    /**
     * Use to destroy all loaded assets
     * @return {Void} Not tested yet,
     */
    destroy() {
        this.cleanupSounds();
        this.stopListening();
    },
});

export default AssetLoader;
