import React from 'react';
import Badge from 'react-bootstrap/Badge';
import PropTypes from 'prop-types';

/**
 * Provides email address text with Badge style
 */
const EmailAddressBadge = ({ email }) => {
    return (
        <Badge className="font-weight-normal mx-1" variant="light">
            {email}
        </Badge>
    );
};

EmailAddressBadge.propTypes = {
    email: PropTypes.string.isRequired,
};

export default EmailAddressBadge;
