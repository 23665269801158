import React from 'react';
import Backbone from 'backbone';
import { Button, Table } from 'react-bootstrap';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { ClockHistory } from 'react-bootstrap-icons';
import AdminNavBar from './AdminNavBar';
import AdminBase from './AdminBase';
import { Status } from '~core';

/**
 * @memberOf ReactBlocks
 * @schema json schema
 */

class AdminModules extends AdminBase {
    /**
     * @param props
     */
    constructor(props) {
        super(props);
        this.data = this.props.blockRef.data;
        this.app = this.props.blockRef.app;
        this.state = this.getCourseData();

        this.app.courseData.modules.each((module, i) => {
            Backbone.listenTo(
                module,
                'onStatusUpdate',
                this.handleUpdate.bind(this)
            );
            Backbone.listenTo(module, 'onReset', this.handleUpdate.bind(this));
        });
    }

    handleUpdate() {
        this.setState(this.getCourseData());
    }

    /**
     * Completes the screen.
     */
    componentDidMount() {
        this.props.blockRef.complete();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleReset(e) {
        // debugger;
        const moduleNumber = event.target.value;

        if (moduleNumber !== '') {
            const module = window.container.courseData.getModule(moduleNumber);
            if (module !== undefined) {
                const startId = module.pages[0].pageID();
                const endId = module.pages[module.pages.length - 1].pageID();

                window.container.storage.clearProgress(startId, endId);
            }
        }

        this.navigateToModules(); // [JM: Hacky fix, table rows not rendering correctly until page revisited]
    }

    // [JM: Needs fix, pages completed for selected module aren't being adjusted]
    handleSetStatus(status) {
        const moduleNumber = event.target.value;
        const module = this.app.courseData.getModule(moduleNumber);

        const adjustedState = this.state.course;

        // Manually set targeted modules status to PASSED
        // adjustedState.modules[moduleNumber].moduleStatus = Status.numberToStatus(status);

        // Manually set targeted modules class
        // adjustedState.modules[moduleNumber].statusClass = this.getStatusAsCssClass(status);

        // For every page of a module complete all blocks, this in turn will change getTotalCompletedPagesByModule.progress to 100
        this.completeModuleBlocks(status, moduleNumber);
        module.setModuleStatus(status, true); // On status update the page is automatically rendered
    }

    completeModuleBlocks(status, moduleNumber) {
        const pages = this.app.courseData.getModule(moduleNumber).pages;
        pages.forEach(function (page) {
            page.blocks.forEach(function (block) {
                block.setStatus(status, true);
                block.isComplete(true);
            });
        });
    }

    /**
     * @returns {ReactNode}
     */
    render() {
        const modulesTableHead = (
            <tr className="text-light bg-dark">
                <th className="border-0 text-center">ID</th>
                <th className="border-0">STATUS</th>
                <th className="border-0">TITLE</th>
                <th className="border-0">DESCRIPTION</th>
                <th className="border-0 text-center">% COMPLETED</th>
                <th className="border-0 text-center">RESET</th>
                <th className="border-0 text-center">SET STATUS AS</th>
            </tr>
        );

        const modulesTableRows = this.state.course.modules.map((module) => {
            const link = `#startmodule/${module.id}`;

            return (
                <tr key={module.id}>
                    <td className="_text-center align-middle">{module.id}</td>
                    <td className={`align-middle ${module.statusClass}`}>
                        {module.moduleStatus}
                    </td>
                    <td className="align-middle">
                        <a href={link}>{module.moduleTitle}</a>
                    </td>
                    <td className="align-middle">{module.moduleDescription}</td>
                    <td
                        title={`${module.progress.completedPages} / ${module.progress.totalPages} completed`}
                        className="text-center align-middle"
                    >
                        {module.progress.percentage}%
                    </td>
                    <td className="text-center align-middle">
                        <Button
                            style={{ textAlign: 'center' }}
                            onClick={() => {
                                this.handleReset();
                            }}
                            title="Reset"
                            value={module.id}
                        >
                            <ClockHistory size={20} />
                        </Button>
                    </td>
                    <td className="text-center align-middle">
                        <ButtonToolbar aria-label="Toolbar with button groups">
                            <ButtonGroup
                                className="mr-2"
                                aria-label="First group"
                            >
                                <Button
                                    style={{
                                        backgroundColor:
                                            'rgba(2, 166, 205, 0.01)',
                                    }}
                                    variant="light"
                                    onClick={() => {
                                        this.handleSetStatus(
                                            Status.UNAVAILABLE
                                        );
                                    }}
                                    title={Status.UNAVAILABLE_LABEL}
                                    value={module.id}
                                >
                                    {Status.UNAVAILABLE}
                                </Button>
                                <Button
                                    style={{
                                        backgroundColor:
                                            'rgba(2, 166, 205, 0.1)',
                                    }}
                                    variant="light"
                                    onClick={() => {
                                        this.handleSetStatus(Status.AVAILABLE);
                                    }}
                                    title={Status.AVAILABLE_LABEL}
                                    value={module.id}
                                >
                                    {Status.AVAILABLE}
                                </Button>
                                <Button
                                    style={{
                                        backgroundColor:
                                            'rgba(2, 166, 205, 0.3)',
                                    }}
                                    onClick={() => {
                                        this.handleSetStatus(Status.STARTED);
                                    }}
                                    // className='m-2 alert alert-success'
                                    // role='alert'
                                    title={Status.STARTED_LABEL}
                                    value={module.id}
                                >
                                    {Status.STARTED}
                                </Button>
                                <Button
                                    style={{
                                        backgroundColor:
                                            'rgba(2, 166, 205, 0.6)',
                                    }}
                                    onClick={() => {
                                        this.handleSetStatus(Status.COMPLETED);
                                    }}
                                    // className='m-2 alert alert-danger'
                                    // role='alert'
                                    title={Status.COMPLETED_LABEL}
                                    value={module.id}
                                >
                                    {Status.COMPLETED}
                                </Button>
                            </ButtonGroup>
                            <ButtonGroup
                                className="mr-2"
                                aria-label="Second group"
                            >
                                <Button
                                    onClick={() => {
                                        this.handleSetStatus(Status.FAILED);
                                    }}
                                    // className='m-2 alert alert-danger'
                                    // role='alert'
                                    variant="danger"
                                    title={Status.FAILED_LABEL}
                                    value={module.id}
                                >
                                    {Status.FAILED}
                                </Button>
                                <Button
                                    onClick={() => {
                                        this.handleSetStatus(Status.PASSED);
                                    }}
                                    // className='m-2 alert alert-danger'
                                    // role='alert'
                                    variant="success"
                                    title={Status.PASSED_LABEL}
                                    value={module.id}
                                >
                                    {Status.PASSED}
                                </Button>
                            </ButtonGroup>
                        </ButtonToolbar>
                    </td>
                </tr>
            );
        });

        return (
            <div className="admin">
                <AdminNavBar />
                <div className="m-5">
                    <Table responsive="sm">
                        <thead>{modulesTableHead}</thead>
                        <tbody>{modulesTableRows}</tbody>
                    </Table>
                </div>
            </div>
        );
    }
}

export default AdminModules;
