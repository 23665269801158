import Backbone from 'backbone';
/**
 * @class Setting up CMI label constants to use them in other classes
 * @name CMI
 * @property {Array} labels
 *
 * @augments Backbone.Model
 *
 * @see Backbone.Model
 */
const CMI = Backbone.Model.extend(
    /** @lends CMI.prototype */ {
        constructor() {
            this.labels = [];
            Backbone.Model.apply(this, arguments);
        },

        /**
         * Add a CMI key value pair into labels array
         * @param {string} label
         * @param {string} value
         */
        addCmiLables(label, value) {
            this.labels[label] = value;
        },

        /**
         * Initialize the key value pairs on CMI based on version
         *
         * @param {string} version
         */
        initialize(version) {
            if (version == '1.2') {
                this.addCmiLables(
                    'LESSON_LOCATION',
                    'cmi.core.lesson_location'
                );
                this.addCmiLables('STUDENT_NAME', 'cmi.core.student_name');
                this.addCmiLables('LESSON_STATUS', 'cmi.core.lesson_status');
                this.addCmiLables('SUSPEND_DATA', 'cmi.suspend_data');
                this.addCmiLables('SESSION_TIME', 'cmi.core.session_time');
                this.addCmiLables('SCORE_RAW', 'cmi.core.score.raw');
                this.addCmiLables('SCORE_MIN', 'cmi.core.score.min');
                this.addCmiLables('SCORE_MAX', 'cmi.core.score.max');
                this.addCmiLables('EXIT', 'cmi.core.exit');

                /*
			this.addCmiLables("CHILDREN", 									"cmi.core._children");
			this.addCmiLables("STUDENT_ID", 								"cmi.core.student_id");
			this.addCmiLables("CREDIT", 									"cmi.core.credit");
			this.addCmiLables("ENTRY", 										"cmi.core.entry");
			this.addCmiLables("SCORE", 										"cmi.core.score");
			this.addCmiLables("SCORE_CHILDREN", 							"cmi.core.score._children");
			this.addCmiLables("TOTAL_TIME", 								"cmi.core.total_time");
			this.addCmiLables("LESSON_MODE", 								"cmi.core.lesson_mode");
			this.addCmiLables("LAUNCH_DATA", 								"cmi.launch_data");
			this.addCmiLables("COMMENTS", 									"cmi.comments");
			this.addCmiLables("COMMENTS_FROM_LMS", 							"cmi.comments_from_lms");
			this.addCmiLables("OBJECTIVES_CHILDREN", 						"cmi.objectives._children");
			this.addCmiLables("OBJECTIVES_COUNT", 							"cmi.objectives._count");
			this.addCmiLables("OBJECTIVES_N_ID", 							"cmi.objectives.n.id");
			this.addCmiLables("OBJECTIVES_N_SCORE_CHILDREN", 				"cmi.objectives.n.score._children");
			this.addCmiLables("OBJECTIVES_N_SCORE_RAW", 					"cmi.objectives.n.score.raw");
			this.addCmiLables("OBJECTIVES_N_SCORE_MAX", 					"cmi.objectives.n.score.max");
			this.addCmiLables("OBJECTIVES_N_SCORE_MIN", 					"cmi.objectives.n.score.min");
			this.addCmiLables("OBJECTIVES__N_STATUS", 						"cmi.objectives.n.status");
			this.addCmiLables("STUDENT_DATA_CHILDREN", 						"cmi.student_data._children");
			this.addCmiLables("STUDENT_DATA_MASTERY_SCORE", 				"cmi.student_data.mastery_score");
			this.addCmiLables("STUDENT_DATA_MAX_TIME_ALLOWED", 				"cmi.student_data.max_time_allowed");
			this.addCmiLables("STUDENT_DATA_TIME_LIMIT_ACTION", 			"cmi.student_data.time_limit_action");
			this.addCmiLables("STUDENT_PREFERENCE_CHILDREN", 				"cmi.student_preference._children");
			this.addCmiLables("STUDENT_PREFERENCE_AUDIO", 					"cmi.student_preference.audio");
			this.addCmiLables("STUDENT_PREFERENCE_LANGUAGE", 				"cmi.student_preference.language");
			this.addCmiLables("STUDENT_PREFERENCE_SPEED", 					"cmi.student_preference.speed");
			this.addCmiLables("STUDENT_PREFERENCE_TEXT", 					"cmi.student_preference.text");
			this.addCmiLables("INTERACTIONS_CHILDREN", 						"cmi.interactions._children");
			this.addCmiLables("INTERACTIONS_COUNT", 						"cmi.interactions._count");
			this.addCmiLables("INTERACTIONS_N_ID", 							"cmi.interactions.n.id");
			this.addCmiLables("INTERACTIONS_N_OBJECTIVES_COUNT",			"cmi.interactions.n.objectives._count");
			this.addCmiLables("INTERACTIONS_N_OBJECTIVES_N_ID", 			"cmi.interactions.n.objectives.n.id");
			this.addCmiLables("INTERACTIONS_N_TIME", 						"cmi.interactions.n.time");
			this.addCmiLables("INTERACTIONS_N_TYPE", 						"cmi.interactions.n.type");
			this.addCmiLables("INTERACTIONS_N_CORRECT_RESPONSES_COUNT", 	"cmi.interactions.n.correct_responses._count");
			this.addCmiLables("INTERACTIONS_N_CORRECT_RESPONSES_N_PATTERN", "cmi.interactions.n.correct_responses.0.pattern");
			this.addCmiLables("INTERACTIONS_N_WEIGHTING", 					"cmi.interactions.n.weighting");
			this.addCmiLables("INTERACTIONS_N_STUDENT_RESPONSE", 			"cmi.interactions.n.student_response");
			this.addCmiLables("INTERACTIONS_N_RESULT", 						"cmi.interactions.n.result");
			this.addCmiLables("INTERACTIONS_N_LATENCY",						"cmi.interactions.n.latency");
			*/
            } else {
                this.addCmiLables('LESSON_LOCATION', 'cmi.location');
                this.addCmiLables('STUDENT_NAME', 'cmi.learner_name');
                this.addCmiLables('LESSON_STATUS', 'cmi.completion_status');
                this.addCmiLables('SUCCESS_STATUS', 'cmi.success_status');
                this.addCmiLables('SUSPEND_DATA', 'cmi.suspend_data');
                this.addCmiLables('SESSION_TIME', 'cmi.session_time');
                this.addCmiLables('SCORE_RAW', 'cmi.score.raw');
                this.addCmiLables('SCORE_MIN', 'cmi.score.min');
                this.addCmiLables('SCORE_MAX', 'cmi.score.max');
                this.addCmiLables('SCORE_SCALED', 'cmi.score.scaled');
                this.addCmiLables('EXIT', 'cmi.exit');

                /*
			this.addCmiLables("CREDIT", 									"cmi.credit");
			this.addCmiLables("ENTRY", 										"cmi.entry");
			this.addCmiLables("VERSION", 									"cmi._version");
			this.addCmiLables("COMMENTS_FROM_LEARNER_CHILDREN", 			"cmi.comments_from_learner._children");
			this.addCmiLables("COMMENTS_FROM_LEARNER_COUNT", 				"cmi.comments_from_learner._count");
			this.addCmiLables("COMMENTS_FROM_LEARNER_N_COMMENT",			"cmi.comments_from_learner.n.comment");
			this.addCmiLables("COMMENTS_FROM_LEARNER_N_LOCATION",			"cmi.comments_from_learner.n.location");
			this.addCmiLables("COMMENTS_FROM_LEARNER_N_TIMESTAMP", 			"cmi.comments_from_learner.n.timestamp");
			this.addCmiLables("COMMENTS_FROM_LMS_CHILDREN", 				"cmi.comments_from_lms._children");
			this.addCmiLables("COMMENTS_FROM_LMS_COUNT", 					"cmi.comments_from_lms._count");
			this.addCmiLables("COMMENTS_FROM_LMS_N_COMMENT", 				"cmi.comments_from_lms.n.comment");
			this.addCmiLables("COMMENTS_FROM_LMS_N_LOCATION", 				"cmi.comments_from_lms.n.location");
			this.addCmiLables("COMMENTS_FROM_LMS_N_TIMESTAMP", 				"cmi.comments_from_lms.n.timestamp");
			this.addCmiLables("COMPLETION_THRESHOLD", 						"cmi.completion_threshold");
			this.addCmiLables("INTERACTIONS_CHILDREN", 						"cmi.interactions._children");
			this.addCmiLables("INTERACTIONS_COUNT", 						"cmi.interactions._count");
			this.addCmiLables("INTERACTIONS_N_ID", 							"cmi.interactions.n.id");
			this.addCmiLables("INTERACTIONS_N_TYPE", 						"cmi.interactions.n.type");
			this.addCmiLables("INTERACTIONS_N_OBJECTIVES_COUNT", 			"cmi.interactions.n.objectives._count");
			this.addCmiLables("INTERACTIONS_N_OBJECTIVES_N_ID", 			"cmi.interactions.n.objectives.n.id");
			this.addCmiLables("INTERACTIONS_N_TIME", 						"cmi.interactions.n.timestamp");
			this.addCmiLables("INTERACTIONS_N_CORRECT_RESPONSES_COUNT", 	"cmi.interactions.n.correct_responses._count");
			this.addCmiLables("INTERACTIONS_N_CORRECT_RESPONSES_N_PATTERN", "cmi.interactions.n.correct_responses.0.pattern");
			this.addCmiLables("INTERACTIONS_N_WEIGHTING", 					"cmi.interactions.n.weighting");
			this.addCmiLables("INTERACTIONS_N_STUDENT_RESPONSE", 			"cmi.interactions.n.learner_response");
			this.addCmiLables("INTERACTIONS_N_RESULT", 						"cmi.interactions.n.result");
			this.addCmiLables("INTERACTIONS_N_LATENCY", 					"cmi.interactions.n.latency");
			this.addCmiLables("INTERACTIONS_N_DESCRIPTION", 				"cmi.interactions.n.description");
			this.addCmiLables("LAUNCH_DATA", 								"cmi.launch_data");
			this.addCmiLables("STUDENT_ID", 								"cmi.learner_id");
			this.addCmiLables("LEARNER_PREFERENCE_CHILDREN", 				"cmi.learner_preference._children");
			this.addCmiLables("LEARNER_PREFERENCE_AUDIO_LEVEL", 			"cmi.learner_preference.audio_level");
			this.addCmiLables("LEARNER_PREFERENCE_LANGUAGE", 				"cmi.learner_preference.language");
			this.addCmiLables("LEARNER_PREFERENCE_DELIVERY_SPEED", 			"cmi.learner_preference.delivery_speed");
			this.addCmiLables("LEARNER_PREFERENCE_AUDIO_CAPTIONING", 		"cmi.learner_preference.audio_captioning");
			this.addCmiLables("MAX_TIME_ALLOWED", 							"cmi.max_time_allowed");
			this.addCmiLables("LESSON_MODE", 								"cmi.mode");
			this.addCmiLables("OBJECTIVES_", 								"cmi.objectives._children");
			this.addCmiLables("OBJECTIVES_COUNT", 							"cmi.objectives._count");
			this.addCmiLables("OBJECTIVES_N_ID", 							"cmi.objectives.n.id");
			this.addCmiLables("OBJECTIVES_N_SCORE_CHILDREN", 				"cmi.objectives.n.score._children");
			this.addCmiLables("OBJECTIVES_N_SCORE_SCALED", 					"cmi.objectives.n.score.scaled");
			this.addCmiLables("OBJECTIVES_N_SCORE_RAW", 					"cmi.objectives.n.score.raw");
			this.addCmiLables("OBJECTIVES_N_SCORE_MIN", 					"cmi.objectives.n.score.min");
			this.addCmiLables("OBJECTIVES_N_SCORE_MAX", 					"cmi.objectives.n.score.max");
			this.addCmiLables("OBJECTIVES_N_SUCCESS_STATUS", 				"cmi.objectives.n.success_status");
			this.addCmiLables("OBJECTIVES_N_COMPLETION_STATUS", 			"cmi.objectives.n.completion_status");
			this.addCmiLables("OBJECTIVES_N_PROGRESS_MEASURE", 				"cmi.objectives.n.progress_measure");
			this.addCmiLables("OBJECTIVES_N_DESCRIPTION", 					"cmi.objectives.n.description");
			this.addCmiLables("PROGRESS_MEASURE", 							"cmi.progress_measure");
			this.addCmiLables("SCALED_PASSING_SCORE", 						"cmi.scaled_passing_score");
			this.addCmiLables("SCORE_CHILDREN", 							"cmi.score._children");
			this.addCmiLables("TIME_LIMIT_ACTION", 							"cmi.time_limit_action");
			this.addCmiLables("TOTAL_TIME", 								"cmi.total_time"); */
            }
        },
    }
);

export { CMI };
